import React, {useEffect, useState} from "react"
import {ErrorMessage, Field, Form, Formik} from "formik";
import {observer} from "mobx-react-lite"
import {useStore} from "../../../helpers/helpers";
import * as Yup from 'yup';
import {
    AttachmentsList,
    htmlValidation,
    makeid_alpa_numeric,
    remove_item_from_array,
    TextError
} from "../../../helpers/utils";
import {uploadBlob, uploadFile} from "../../../helpers/file-upload";
import {toast} from "react-toastify";
import VoterReachoutAudioPlayer from "./voter-reachout-audioplayer";
import {useParams} from "react-router-dom";
import {FormButtons, VoiceRecorder} from "../../../components";

const AddEditVoiceCall = observer((props: any) => {
        const rootStore = useStore();
        const {projectid} = useParams()
        const [voiceSubmit, setVoiceSubmit] = useState(false)
        const [audio_response, setAudioResponse] = useState(null)
        const [inputKey, setInputKey] = useState('');
        const [file, setFile] = useState({});
        const [buffer, setBuffer] = useState([]);
        const {voterReachOutStore} = rootStore
        const {formType, voiceMessageFormValue, messageType} = voterReachOutStore
        const {formSubmit} = props
        const path = `project-${projectid}/voterreachout/voicemessage`

        const voiceMessageFormInitialValues = {
            name: voiceMessageFormValue.name
        }

        const voiceMessageValidationSchema = Yup.object().shape({
            name: Yup.string().required('Name is required')
        })

        const onRecorderSubmit = async (recordedOutput) => {
            const path = `project-${projectid}/voterreachout/voicemessages`
            if (recordedOutput) {
                try {
                    const id = makeid_alpa_numeric(8)
                    const medianame = `${id}.mp4`
                    const audio_response = await uploadBlob(path, medianame, recordedOutput)
                    setAudioResponse(audio_response.key)
                    toast.success('recording uploaded successfully')
                } catch (e) {
                    console.log('something went wrong', e)
                }
            }
        }

        const deleteAudioResponse = () => {
            setAudioResponse(null)
            setVoiceSubmit(false)
        }

        const voiceMessageFormSubmit = async (values) => {
            if (!voiceSubmit) {
                return false
            }
            if (buffer.length <= 0 && !audio_response) {
                return false
            }
            let voicePayload = {
                projectid: projectid,
                channel: "voicecall",
                more: {},
                ds: {
                    name: values.name,
                    text: "",
                    attachements: []
                }
            }
            if (audio_response) {
                voicePayload.ds.text = audio_response
            }
            if (buffer.length > 0) {
                let Attachments = []

                for (let ele of buffer) {
                    Attachments.push({path: ele.path, id: ele.id, type: ele.type})
                }
                voicePayload.ds.attachements = Attachments

            }
            formSubmit(voicePayload, voiceMessageFormValue.id)
        }

        const delete_file = name => {
            const updated_values = remove_item_from_array(buffer, "path", name)
            setBuffer(updated_values)
        }

        useEffect(() => {
            if (messageType === "Voice Messages" && formType === "update") {
                setAudioResponse(voiceMessageFormValue.message)
                setBuffer(voiceMessageFormValue.attachements)
            }

        }, [messageType, formType])


        return <>
            <Formik
                initialValues={voiceMessageFormInitialValues}
                onSubmit={voiceMessageFormSubmit}
                validationSchema={voiceMessageValidationSchema}
            >
                <Form className="rounded-lg">
                    <div className={"form-control sm:grid sm:grid-cols-  sm:items-start d-flex flex-column text-left"}>
                        <label htmlFor="name"
                               className="block text-gray-700  rounded-lg text-left font-semibold">Name</label>

                        <div className='flex flex-row items-center'>
                            <Field
                                type="text"
                                id="name"
                                name="name"
                                className="px-4 py-2 rounded-lg border border-gray-300 w-full"
                            />
                        </div>
                        <div className="flex flex-row">
                            <ErrorMessage name="name" component={TextError}/>
                        </div>
                    </div>

                    {
                        (formType !== "update" || !audio_response) && <div>
                            <VoiceRecorder
                                handleAudioUpload={onRecorderSubmit}
                                handleReset={deleteAudioResponse}
                            />
                            {voiceSubmit && !audio_response && buffer.length <= 0 &&
                            <div className="error text-sm text-red-600">
                                Please Record a Audio
                            </div>}
                        </div>
                    }

                    {formType === "update" && audio_response &&
                    <label className="block text-gray-700 pt-4 rounded-lg text-left ml-3 font-semibold">Uploaded
                        Audio</label>}

                    {formType === "update" && audio_response && <div className="ml-3 mt-3 mb-3 flex items-center w-full">
                        <div style={{flex: 10}}>
                            <VoterReachoutAudioPlayer data={audio_response}/>
                        </div>
                        <div style={{flex: 2}}>
                            <span onClick={deleteAudioResponse} className="text-red-600"><i className="fa-solid fa-trash"/></span>
                        </div>
                    </div>}

                    <div className=" flex flex-row justify-between items-end flex-wrap ml-1 mt-7 pt-7">
                        <div className="flex flex-col">
                            <label htmlFor="project-name"
                                   className="block text-gray-700 rounded-lg text-left font-semibold">Upload existing
                                audio</label>
                            <input
                                key={inputKey}
                                type="file"
                                name="attachments"
                                id="attachments"
                                className="py-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                onChange={e => {
                                    const file = e.target.files[0];
                                    setFile({...file, file})
                                }}
                                accept="audio/mp3,audio/*;capture=microphone"
                            />
                        </div>
                        <button
                            className="justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                            onClick={(e) => {
                                htmlValidation(e)
                                if (!file["file"]) return false;
                                const s3Path = uploadFile(path, file, "urn-assets");
                                if (s3Path) {
                                    setFile({})
                                    const randomString = Math.random().toString(6);
                                    setInputKey(randomString);
                                }
                                const file_type = file["file"]["type"]
                                setBuffer([...buffer, {path: s3Path, type: file_type, id: makeid_alpa_numeric(8)}])
                            }}
                        >
                            Upload
                        </button>
                    </div>
                    {voiceSubmit && buffer.length <= 0 && !audio_response && <div className="error text-sm text-red-600">
                        Please upload a file
                    </div>}

                    <div className="flex flex-col justify-between itesm-center">
                        {buffer && buffer.length > 0 &&
                        <div className="font-bold flex justify-center">Uploaded Audio</div>}
                        {buffer && buffer.length > 0 && buffer.map((file) => {
                            return <AttachmentsList file={file} onDelete={delete_file}/>
                        })}
                    </div>

                    <FormButtons
                        onSave={() => setVoiceSubmit(true)}
                        onCancel={() => {
                            voterReachOutStore.set_isSideOverlay(false)
                        }}
                    />
                    <div>
                    </div>
                </Form>
            </Formik>
        </>
    }
)

export default AddEditVoiceCall;