import React, {useEffect} from "react";
import {useTable, usePagination, useSortBy} from "react-table";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/24/solid";
import {useTranslation} from "react-i18next";
import {IconButton} from "@mui/material";
import {DeleteRounded, EditRounded} from '@material-ui/icons'
import {htmlValidation} from "../helpers/utils";


type CMTableProps = {
    data: any[],
    columns: {}[],
    pagesize?: number,
    onRowClick?: (row: any) => void,
    hideFooter?: boolean,
    onEdit?: (row: any) => void
    onDelete?: (row: any) => void
    show_action_buttons?: boolean,
    highlightRow?: boolean,
    activeSurveyResponseId?: string
}

export const CMTable = (props: CMTableProps) => {

    const {data, columns, pagesize , onRowClick, hideFooter = false, onEdit, onDelete, show_action_buttons = false, highlightRow = false, activeSurveyResponseId = ""} = props;

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        getTrProps,
        page,
        rows,
        prepareRow,
        canPreviousPage,
        canNextPage,
        setPageSize,
        pageOptions,
        nextPage,
        previousPage,
        state: {pageIndex, pageSize},
    } = useTable({columns, data, initialState: {pageSize: pagesize}}, useSortBy, usePagination)
    const {t} = useTranslation("survey_management");


    useEffect(() => {
        setPageSize(pagesize)
    }, [pagesize])


    return (
        <div className="flex flex-col">
            <div className="mt-4 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                            <table {...getTableProps()} className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <th scope="col" className="capitalize px-3 py-3.5 text-left font-bold text-gray-900" {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render('Header')}
                                                <span>
                                                        {column.isSorted
                                                            ? column.isSortedDesc
                                                                ? ' 🔽'
                                                                : ' 🔼'
                                                            : ""}
                                                    </span>
                                            </th>
                                        ))}
                                        {show_action_buttons ? <th scope="col" className="px-3 py-3.5 text-left font-bold text-gray-900">{t("actions")}
                                        </th> : null}
                                    </tr>
                                ))}
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                {page.map((row, i) => {
                                    prepareRow(row)
                                    let classNameHighlight = 'cursor-pointer'
                                    if (highlightRow) {
                                        if (row['original']['id'] == activeSurveyResponseId)
                                            classNameHighlight += 'active';
                                    }

                                    return (
                                        <tr key={i} className={`${classNameHighlight} `} {...row.getRowProps()}
                                            onClick={() => onRowClick(row.original)}>
                                            {row.cells.map((cell, i) => {
                                                return <td key={i} className="max-w-sm p-2 text-gray-800" {...cell.getCellProps({})}>{cell.render('Cell')}</td>
                                            })}

                                            {show_action_buttons ? <td className="max-w-sm p-2 text-gray-800">

                                                <IconButton
                                                    aria-label="edit"
                                                    onClick={(e) => {
                                                        // @ts-ignore
                                                        onEdit(row.original)
                                                        htmlValidation(e)
                                                    }}
                                                    color={"info"}
                                                    size={"small"}
                                                    className={"mx-4"}
                                                    sx={{marginRight: 2}}
                                                >
                                                    <i className={"fas fa-edit"}/>
                                                </IconButton>
                                                <IconButton
                                                    aria-label="delete"
                                                    onClick={(e) => {
                                                        // @ts-ignore
                                                        onDelete(row.original)
                                                        htmlValidation(e)
                                                    }}
                                                    color={"error"}
                                                    size={"small"}
                                                    sx={{marginLeft: 2}}
                                                >
                                                    <i className={"fas fa-trash"}/>
                                                </IconButton>

                                            </td> : null}
                                        </tr>
                                    )
                                })}
                                </tbody>
                                {hideFooter ? null : (
                                    <tfoot
                                        className="p-2 text-gray-800"
                                        style={{
                                            fontWeight: "bold",
                                            borderTop: "1.2px solid black",
                                            borderBottom: "1.5px solid black"
                                        }}>
                                    {footerGroups.map(group => (
                                        <tr {...group.getFooterGroupProps()}>
                                            {group.headers.map(column => (
                                                <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                                            ))}
                                        </tr>
                                    ))}
                                    </tfoot>
                                )}
                            </table>
                        </div>
                    </div>
                </div>
            </div>


            <div className="pagination my-1 flex ">
                <span
                    className='pl-4'>{t("page")}{' '}<strong>{pageIndex + 1} of {pageOptions.length}</strong>{' '}</span>
                <span className='ml-auto pr-4'>
                    <button
                        type="button"
                        className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2"
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                    >
                        <ChevronLeftIcon className="h-5 w-5" aria-hidden="true"/>
                    </button>
                    {' '}
                    <button
                        type="button"
                        className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2"
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                    >
                        <ChevronRightIcon className="h-5 w-5" aria-hidden="true"/>
                    </button>
                </span>
            </div>
        </div>
    )
}