import React, {useLayoutEffect, useRef} from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import {ColumnSeries, ValueAxis, XYChart, LabelBullet } from "@amcharts/amcharts4/charts";

am4core.useTheme(am4themes_animated);

export const VerticalStackedBarChart = ({data, id, seriesColors }) => {

    const bar_chart = useRef(null);
    const chart_div = `chart_${id}`;

    useLayoutEffect(() => {

        // Create the chart instance
        const chart = am4core.create(chart_div, XYChart);
        chart.data = data;

        chart.hiddenState.properties.opacity = 0;

        //set outline color for series
        chart.plotContainer.stroke =  am4core.color('#fffff');
        chart.plotContainer.strokeWidth = 0;

        // Create axes
        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = id;
        categoryAxis.renderer.grid.template.location = 0;

        const valueAxis = chart.yAxes.push(new ValueAxis());
        valueAxis.renderer.minWidth = 35;
        valueAxis.renderer.inside = true;
        valueAxis.renderer.labels.template.disabled = true;
        valueAxis.min = 0;


        // Create series
        const createSeries = (field, name, color) => {
            const series = chart.series.push(new ColumnSeries());
            series.dataFields.valueY = field;
            series.dataFields.categoryX =`${id}`;
            series.name = name;
            series.sequencedInterpolation = true;
            series.columns.template.tooltipText = '{name}: [bold]{valueY}[/]';
            series.stacked = true;
            series.fill = color;

            // Display values inside each stack
            const labelBullet = series.bullets.push(new LabelBullet());
            labelBullet.label.text = '{valueY}';
            labelBullet.label.dy = 50;
            labelBullet.label.fontSize = 14;
            labelBullet.label.fill = am4core.color('white'); // Set the label color

            // Hide labels for zero values
            labelBullet.label.adapter.add('text', (text, target) => {
                if (target.dataItem && target.dataItem["valueY"] === 0) {
                    return '';
                }
                return text;
            });

            return series;
        };

        Object.keys(seriesColors).forEach((fieldName) => {
            createSeries(fieldName, fieldName, am4core.color(seriesColors[fieldName]));
        });


        // Legend
        chart.legend = new am4charts.Legend();
        bar_chart.current = chart;

    }, [data]);

    return <div id={chart_div} style={{ width: '100%', height: '600px' }}/>;

}