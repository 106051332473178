import React from "react";
import {useIsMobile} from "../../../custom-hooks/useIsMobile";

type eventProps = {
    event: {
        id: string
        title: string,
        date: string
    },
    onEdit: Function,
    onDelete: Function
}

export const EventCard = (props:eventProps) => {
    const {event, onEdit, onDelete} = props;
    const {title, date, id} = event;
    const isMobile = useIsMobile();
    return (
        <>
            <div key={id} className="rounded-lg shadow-md bg-yellow-100 p-2 sm:p-4 mx-2 group">
                <div className="flex flex-row items-center justify-between">
                    <div className="font-bold text-lg">{title}</div>
                    <div className="flex flex-row items-center justify-between">
                        <i className={`fas fa-pencil text-gray-600 pr-2 cursor-pointer transition-opacity duration-800 ${!isMobile ? "invisible group-hover:visible" :"" }`} onClick={ e => onEdit(event)}/>
                        <i className={`fas fa-trash text-red-600 pl-2 cursor-pointer transition-opacity duration-800 ${!isMobile ? "invisible group-hover:visible" :"" }`} onClick={e => onDelete(id)}/>
                    </div>
                </div>
                <div className="flex flex-row justify-between items-center">
                    <div className="flex flex-row items-center justify-between">
                        <i className="fas fa-calendar-alt text-gray-600 px-2" />
                        <div>{date}</div>
                    </div>
                </div>
            </div>
        </>
    )
}