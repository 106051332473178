import React, {useState} from 'react';
import {Fragment} from 'react'
import {Dialog, Transition} from '@headlessui/react'

function JSONModal(props) {

    const {open, handleUpdate, handleCancel, data} = props;
    const [jsonTxtData, setJsonTxtData] = useState(data)

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 overflow-auto" onClick={(event) => {
                event.stopPropagation();
            }} style={{zIndex: 9999,}} onClose={() => {
            }}>
                <div

                    className="flex items-end justify-center min-h-screen min-w-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div
                            className="inline-block align-bottom bg-white w-full rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6">
                            <div>
                            <textarea
                                className='text-code w-full bg-gray-50 px-4 py-2'
                                value={jsonTxtData}
                                onChange={(evt) => {
                                    setJsonTxtData(evt.target.value);
                                }}
                                style={{height: '550px'}}
                            />
                            </div>
                            <div className="mt-5 sm:mt-6 flex flex-row justify-between items-center">
                                <button
                                    type="button"
                                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-red-500 sm:text-sm mx-2"
                                    onClick={(evt) => {
                                        handleCancel();

                                    }}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-indigo-500 sm:text-sm mx-2"
                                    onClick={(evt) => {
                                        let updated_json = JSON.parse(data);
                                        try {
                                            updated_json = JSON.parse(jsonTxtData);
                                        } catch (e) {
                                        }

                                        handleUpdate(updated_json);

                                    }}
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default React.memo(JSONModal)