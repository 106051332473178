import {observer} from "mobx-react-lite";
import {HomeIcon} from "@heroicons/react/24/solid";
import React from "react";
import {useStore} from "../../helpers/helpers";


export const FolderBreadCrumbs = observer((props:any) => {

    const rootStore = useStore();
    const {digitalArchiveStore} = rootStore;
    const {folderBreadCrumbs} = digitalArchiveStore;
    const {onFolderClick} = props;

    return(
        <>
            <nav className="flex px-4" aria-label="Breadcrumb">
                <ol role="list" className="flex items-center space-x-4">
                    <li className={"cursor-pointer"}>
                        <div>
                            <p className="text-gray-400 hover:text-gray-500" onClick={() => {
                                digitalArchiveStore.update_DAStoreProp("activeParentId", "");
                                digitalArchiveStore.update_folder_breadcrumbs([{
                                    id: "",
                                    parent: "",
                                    title: "Home"
                                }]);
                            }}>
                                <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true"/>
                                <span className="sr-only">Home</span>
                            </p>
                        </div>
                    </li>
                    {folderBreadCrumbs.map((page) => {
                        if(page.id === "") return
                        return (
                            <li className={"cursor-pointer"} key={page.name}>
                                <div className="flex items-center">
                                    <svg
                                        className="h-5 w-5 flex-shrink-0 text-gray-300"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        aria-hidden="true"
                                    >
                                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                                    </svg>
                                    <p
                                        className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                                        onClick={e => onFolderClick(e, page)}
                                    > {page["title"]}</p>
                                </div>
                            </li>
                        )
                    })}
                </ol>
            </nav>
        {/*<nav className="bg-white border-b border-gray-200 flex nop min-h-[45px]" aria-label="Breadcrumb">*/}
        {/*    <ol role="list" className="flex space-x-4 rounded-md bg-white px-6 shadow">*/}
        {/*        <li className="flex cursor-pointer">*/}
        {/*            <div className="flex items-center">*/}
        {/*                <p className="text-gray-400 hover:text-gray-500" onClick={() => {*/}
        {/*                    digitalArchiveStore.update_DAStoreProp("activeParentId", "");*/}
        {/*                    digitalArchiveStore.update_folder_breadcrumbs([{*/}
        {/*                        id: "",*/}
        {/*                        parent: "",*/}
        {/*                        title: "Home"*/}
        {/*                    }]);*/}
        {/*                }}>*/}
        {/*                    <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true"/>*/}
        {/*                    <span className="sr-only">Home</span>*/}
        {/*                </p>*/}
        {/*            </div>*/}
        {/*        </li>*/}
        {/*        {folderBreadCrumbs.map((page) => {*/}
        {/*            if(page.id === "") return*/}
        {/*            return (*/}
        {/*                <li key={page["id"]} className="flex cursor-pointer">*/}
        {/*                    <div className="flex items-center">*/}
        {/*                        <svg*/}
        {/*                            className="h-full w-6 flex-shrink-0 text-gray-200"*/}
        {/*                            viewBox="0 0 24 44"*/}
        {/*                            preserveAspectRatio="none"*/}
        {/*                            fill="currentColor"*/}
        {/*                            aria-hidden="true"*/}
        {/*                        >*/}
        {/*                            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z"/>*/}
        {/*                        </svg>*/}
        {/*                        <p*/}
        {/*                            className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"*/}
        {/*                            onClick={e => onFolderClick(e, page)}*/}
        {/*                        > {page["title"]}</p>*/}

        {/*                    </div>*/}
        {/*                </li>*/}
        {/*            )*/}
        {/*        })}*/}
        {/*    </ol>*/}
        {/*</nav>*/}
            </>
    )
})