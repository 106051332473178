import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Alert from "@mui/material/Alert";

const DeleteUserPage = () => {
  const phone_number_pattern_regex = /^[6-9]\d{9}$/;

  // Validation schema
  const validationSchema = yup.object().shape({
    mobile_number: yup
      .string()
      .matches(phone_number_pattern_regex, "Enter a valid phone number")
      .required("Phone number is required"),
    terms: yup
      .boolean()
      .oneOf([true], "You must accept the terms and conditions"),
    delete: yup.boolean().oneOf([true], "You must confirm account deletion"),
  });

  // Form hook setup
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { isSubmitting, errors, isSubmitSuccessful },
    trigger,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      mobile_number: "",
      terms: false,
      delete: false,
    },
  });

  const isTermsChecked = watch("terms", false);
  const isDeleteChecked = watch("delete", false);

  // Form submission
  async function onSubmit(data) {
    await new Promise((resolve) => setTimeout(resolve, 5000));
    reset();
  }

  return (
    <div>
      <div className="flex login-bg" style={{ height: "100vh" }}>
        <div className="w-full flex flex-col relative justify-evenly">
          <div className={"flex flex-col gap-y-4"}>
            <div
              className={"flex flex-row items-center justify-center gap-x-4"}
            >
              <img src={"/img/ftp_ai.png"} className={"w-12 h-12"} />
              <h2 className={"text-gray-700"}>For The People</h2>
            </div>
            <p className={"text-center text-gray-600"}>
              Empowering Voices, Amplifying Change
            </p>
          </div>

          {/* Delete user card */}
          <div className="w-full sm:w-7/12 min-w-lg overflow-hidden flex flex-row mx-auto items-center justify-between gap-y-3">
            <Box
              component="form"
              onSubmit={handleSubmit(onSubmit)}
              className="bg-white flex flex-col gap-2 w-auto m-auto p-4 rounded-md shadow-lg"
            >
              {isSubmitSuccessful && (
                <Alert severity="success">
                  Deleted your account, sad to see you go 😔
                </Alert>
              )}
              <h1 className="m-2">Delete Account</h1>
              <TextField
                {...register("mobile_number")}
                id="textbox"
                error={errors.mobile_number ? true : false}
                onBlur={() => trigger("mobile_number")}
                type="number"
                label="Phone Number"
                variant="outlined"
                fullWidth
                helperText={errors.mobile_number?.message || ""}
                // explicitly trimming the length of the input to 10
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 10);
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox {...register("terms")} checked={isTermsChecked} />
                }
                label="Accept Terms and Conditions"
              />
              <FormControlLabel
                control={
                  <Checkbox {...register("delete")} checked={isDeleteChecked} />
                }
                label="By checking this your account will be permanently deleted"
              />
              <Button
                variant="contained"
                color="error"
                type="submit"
                startIcon={<i className="fa-solid fa-user-xmark"></i>}
                disabled={!(isDeleteChecked && isTermsChecked)}
              >
                {isSubmitting ? (
                  <CircularProgress sx={{ color: "white" }} />
                ) : (
                  "Delete Account"
                )}
              </Button>
            </Box>
          </div>

          <h5 className={"text-gray-700 text-center"}>
            © 2024 forthepeople.ai
          </h5>
        </div>
      </div>
    </div>
  );
};

export default DeleteUserPage;
