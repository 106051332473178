import React, {useEffect, useImperativeHandle, useRef, useState} from 'react';
import _ from 'lodash'
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions.min'
import TimelinePlugin from "wavesurfer.js/dist/plugin/wavesurfer.timeline.min";
import {AudioRegions} from "./audio-regions";
import {useStore} from "../../helpers/helpers";
import {toJS} from "mobx";
import {observer} from "mobx-react-lite";
import {Disclosure} from "@headlessui/react";
import {ChevronDownIcon} from "@heroicons/react/24/outline";
import {classNames} from "../../helpers/utils";
import Select from 'react-select';
import {safe_get} from "../../report-visuals/report-utils";
import { useTranslation } from 'react-i18next';

const playback_speed_values = [1, 1.25, 1.5, 1.75, 2 ]

export const AudioPlayer = observer((props: any, ref) => {

    const {surveyResponseStore, userStore} = useStore()
    const {user} = userStore;
    const {data, onQuestionClick} = props
    const {s3uri, waveform, sr} = data
    const url = s3uri
    const wavefromRef = useRef(null)
    const wavesurfer = useRef(null);
    const {t} = useTranslation("survey_management");

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState(null), []);

    useImperativeHandle(ref, () => ({
        seek(timestamp) {
            wavesurfer.current.play(timestamp)
        }
    }));

    const dl_audio_setup_wf = async () => {
        await fetch(url, {
            //method: 'GET',
            mode: 'cors',
            headers: {
                //'Content-Type': 'application/json',
                "cache-control": "no-cache",
                "pragma": "no-cache",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "POST",
                "Access-Control-Allow-Headers": "Content-Type, Authorization",
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
        })
            .then(function (response) {
                return response.blob()
            }).then(function (blob) {

                wavesurfer.current = WaveSurfer.create({
                    container: wavefromRef.current,
                    backend: 'WebAudio',
                    mediaControls: true,
                    plugins: [
                        RegionsPlugin.create({
                            regions: surveyResponseStore.regions,
                            dragSelection: {
                                slop: 1
                            }
                        }),
                        TimelinePlugin.create({
                            container: "#wave-timeline"
                        })

                        // WaveSurfer.playhead.create({ returnOnPause: true, moveOnSeek: true, draw: true }),
                        // WaveSurfer.cursor.create({ showTime: true, opacity: 1, customShowTimeStyle: { 'background-color': '#000', color: '#fff', padding: '2px', 'font-size': '10px' } })

                    ],
                    waveColor: '#D9DCFF',
                    progressColor: '#4353FF',
                    cursorColor: '#4353FF',
                    //splitChannels: true,
                    splitChannelsOptions: {
                        overlay: false,
                        channelColors: {
                            0: {
                                progressColor: '#4353FF',
                                waveColor: '#D9DCFF'
                            },
                            1: {
                                progressColor: 'orange',
                                waveColor: 'purple'
                            }
                        }
                    },
                    mediaType: 'audio',
                    responsive: true,
                    hideScrollbar: true,
                    scrollParent: false,
                    autoCenter: true,
                    fillParent: true,
                    minPxPerSec: 10,
                    // pixelRatio: window.devicePixelRatio || window.screen.deviceXDPI / window.screen.logicalXDPI,
                    normalize: true,
                    xhr: {
                        cache: 'default',
                        mode: 'no-cors',
                        method: 'GET',
                        credentials: 'omit',
                        redirect: 'follow',
                        referrer: 'client',
                        requestHeaders: [
                            {key: "cache-control", value: "no-cache"},
                            {key: "pragma", value: "no-cache"},
                            {key: "Access-Control-Allow-Origin", value: "*"},
                            {key: "Access-Control-Allow-Methods", value: "POST"},
                            {key: "Access-Control-Allow-Headers", value: "Content-Type, Authorization"},
                        ]
                    },
                    barWidth: 1,
                })
                wavesurfer.current.loadBlob(blob);

                wavesurfer.current.on('region-update-end', (region) => {

                    const reg = {
                        id: region.id,
                        start: region.start,
                        end: region.end,
                        data: {
                            username: user.name,
                            userid: user.id,
                            tags: safe_get(region.data, "tags", []),
                            notes: safe_get(region.data, "notes", ""),
                            timestamp: Date.now(),
                        }
                    }

                    const tmp = _.clone(toJS(surveyResponseStore.regions));
                    const index = _.findIndex(tmp, {id: region.id})
                    if (index == -1) {
                        reg.data.tags = []
                        tmp.push(reg)
                    } else {
                        tmp[index] = reg
                    }

                    surveyResponseStore.setRegions(tmp)
                    surveyResponseStore.updateRegion(reg)
                })
                wavesurfer.current.on('region-click', (region, e) => {
                    e.stopPropagation();
                    wavesurfer.current.play(region.start, region.end)
                })
            })
    }

    /*
    useEffect(() => {
        dl_audio_setup_wf()
    }, [url, surveyResponseStore.regions]);
    */

    useEffect(() => {
       try{
           dl_audio_setup_wf();
       }catch (e) {
           console.log(e)
       }
        return () => {
            wavesurfer.current.destroy()
        };
    }, [url]);

    const regionClickCB = (region, play) => {
        wavesurfer.current.play(region.start, region.end)
    }

    const regionUpdatedCB = (region) => {
        wavesurfer.current.on('region-update', (region) => {
        })
    }

    const regionDeleteCB = (region) => {
        if (wavesurfer.current.regions.list[region["id"]]) {
            wavesurfer.current.regions.list[region["id"]].remove();
        }
    }

    const question_and_answer_accordion = () => {
        return (
            <div className="bg-gray-50 mb-4">
                <div className="mx-auto p-4">
                    <dl className="divide-y divide-gray-200">
                        <Disclosure as="div">
                            {({open}) => (
                                <>
                                    <dt className="text-lg">
                                        <Disclosure.Button
                                            className="text-left w-full flex justify-between items-center text-gray-400">
                                            <div className='flex flex-row items-center'>
                                                <span
                                                    className="block text-base font-medium text-indigo-500 font-bold uppercase">{t("highlights")}</span>
                                                {/*<span*/}
                                                {/*    className="block text-base font-small font-bold m-2">({surveyResponseStore.regions.length} highlights found)</span>*/}
                                            </div>
                                            <ChevronDownIcon
                                                className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                                                aria-hidden="true"
                                            />
                                        </Disclosure.Button>
                                    </dt>
                                    <Disclosure.Panel as="dd" className="mt-2">
                                        <AudioRegions regions={surveyResponseStore.regions} regionClick={regionClickCB}
                                                      regionUpdate={regionUpdatedCB} regionDelete={regionDeleteCB}/>
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>
                    </dl>
                </div>
            </div>
        )
    }

    const [isPlaying, setIsPlaying] = useState(false);
    const [openPlayBackSpeed, setOpenPlayBackSpeed] = useState(false);
    const [playbackSpeed, setPlaybackSpeed] = useState(1);

    return (
        <div className="">
            <label className="block text-base font-medium text-indigo-500 font-bold uppercase">{t("audio")}</label>
            <div className="" ref={wavefromRef} />
            <div id="wave-timeline" />

            <div className="pl-1 flex flex-row space-x-6 mt-2 mb-8 text-lg">

                <div className="cursor-pointer" onClick={(e) => {
                    if (wavesurfer && wavesurfer.current) {
                        wavesurfer.current.playPause();
                        setIsPlaying(wavesurfer.current && wavesurfer.current.isPlaying())
                    }
                }}>
                    {isPlaying ? <i className="fa-solid fa-play-pause text-green-600"/> :
                        <i className="fa-solid fa-play-pause "/>}
                </div>

                <div className="cursor-pointer" onClick={() => {
                    wavesurfer.current.skipBackward()
                }}>
                    <i className="fa fa-step-backward"/>
                </div>


                <div className="cursor-pointer" onClick={() => {
                    wavesurfer && wavesurfer.current && wavesurfer.current.skipForward()
                }}>
                    <i className="fa fa-step-forward"/>
                </div>

                <div className="cursor-pointer" onClick={() => {
                    wavesurfer && wavesurfer.current && wavesurfer.current.toggleMute()
                }}>
                    {
                        wavesurfer.current && wavesurfer.current.getMute() ?
                            <i className="fa fa-volume-on"/> : <i className="fa fa-volume-off"/>}
                </div>

                {userStore.isRoot() && <div className="cursor-pointer">
                    <a href={s3uri} target="_blank">
                        <i className="fa-solid fa-share-nodes"/>
                    </a>
                </div>}

                <div className="cursor-pointer" onClick={() => {
                    setOpenPlayBackSpeed(!openPlayBackSpeed)
                }}>
                    <i className="fa-solid fa-grip-dots-vertical"/>
                </div>

                <div className="cursor-pointer">
                    {playbackSpeed !== 1 && <p className="font-bold">x{playbackSpeed}</p>}
                </div>

                {openPlayBackSpeed &&  <Select
                    defaultValue={playbackSpeed}
                    name="speed"
                    //@ts-ignore
                    options={playback_speed_values.map(x => {
                        return {"value": x, "label": x};
                    })}
                    onChange={(val) => {
                        setPlaybackSpeed(val["value"]);
                        wavesurfer && wavesurfer.current && wavesurfer.current.setPlaybackRate(val["value"])
                        setOpenPlayBackSpeed(!openPlayBackSpeed)
                    }}
                    placeholder="Select Playback Speed..."
                    className=" w-full sm:w-40 h-10 rounded z-9999 focus:shadow outline-none"
                    classNamePrefix="select"
                />}
            </div>

            {question_and_answer_accordion()}
        </div>
    );

}, {forwardRef: true})
