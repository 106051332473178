import {observer} from "mobx-react-lite";
import {math} from "@amcharts/amcharts4/core";
import {useTranslation} from "react-i18next";
import * as React from "react";
import {useStore} from "../../helpers/helpers";

export const TaskPagination = observer(() => {

    const rootStore = useStore();
    const {taskManagerStore} = rootStore;

    const start = (taskManagerStore.task_list_page_current * taskManagerStore.task_list_page_size) + 1
    const end = math.min((taskManagerStore.task_list_page_current + 1) * taskManagerStore.task_list_page_size, taskManagerStore.filtered_task_list.length)
    const [t] = useTranslation("task_manager");

    const handlePrevClick = () => {
        taskManagerStore.task_list_prev_page();
        localStorage.setItem('currentPage', taskManagerStore.task_list_page_current.toString());
    };


    const handleNextClick = () => {
        taskManagerStore.task_list_next_page();
        localStorage.setItem('currentPage', taskManagerStore.task_list_page_current.toString());
    };

    return (
        <nav
            className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
            aria-label="Pagination"
        >
            <div className="hidden sm:block">
                <p className="text-sm text-gray-700">
                    {t("showing")} <span className="font-medium">{start}</span> to <span
                    className="font-medium">{end}</span> of{' '}
                    <span className="font-medium">{taskManagerStore.filtered_task_list.length}</span> results
                </p>
            </div>
            <div className="flex-1 flex justify-between sm:justify-end">
                <a onClick={handlePrevClick}
                   className="cursor-pointer relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                    {t("previous")}
                </a>
                <a onClick={handleNextClick}
                   className="cursor-pointer ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                    {t("next")}
                </a>
            </div>
        </nav>
    )
})