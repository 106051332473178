import React from "react";
import {useTranslation} from "react-i18next";

export class GridCustomCellRenderer {

    percent_renderer(params) {
        const percent = Math.round(params.value * 10000, 2) / 100;
        return percent.toString() + '%';

    }

    audio_link_renderer(params) {

        const link = params.value;

        return (
            <a href={link} target="_blank" className='text-blue-500'>Link</a>
        );
    }
}