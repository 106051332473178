import React from "react";
import {Avatar, Divider, Stack, Typography} from "@mui/material";
import {FSDPermissionButton} from "../../fsd-permissions";
import {observer} from "mobx-react-lite";
import {useStore} from "../../../helpers";
import {safe_get} from "../../../report-visuals/report-utils";
import Chip from '@mui/material/Chip';


//TODO: TO display the summary of a assembly constituency
export const DistrictTitleCard = observer(({summary}: any) => {

    const {userStore} = useStore();
    const acname = summary["acname"] || "demo"
    const acnumber = summary["ac3"] || "000"
    const district = summary["district"] || "demo"
    const region = summary["region"] || "demo"
    const pc = safe_get(summary, "pc", "N/A")
    const rural_urban = safe_get(summary, "rural_urban", "Rural")
    const incumbent_mla = safe_get(summary, "incumbent_mla", "N/A")

    return (
        <div className="p-4 bg-white">
            <div className={"w-full sm:max-w-7xl mx-auto flex flex-col sm:flex-row items-center justify-between flex-wrap"}>
                <Stack direction={"row"} alignItems={"center"}>
                    <Avatar sx={{
                        width: 72,
                        height: 72,
                        backgroundColor: "#DBEAFE",
                        color: "black",
                        fontWeight: "bold"
                    }}>AC-{acnumber}</Avatar>
                    <Stack direction={"column"} className={"px-4"} gap={1}>
                        <Stack direction={"row"} alignItems={"center"} gap={2}>
                            <Typography className={"rounded-md bg-white w-fit"} color={"primary"} variant={"h5"}>
                                {acname}
                            </Typography>
                            <Chip sx={{padding: "10px"}} size={"small"} color={"warning"} label={rural_urban}/>
                        </Stack>
                        <Stack direction={"row"} gap={2} className={"flex-wrap"} >
                            <RenderValue label={"Incumbent MLA"} value={incumbent_mla}/>
                            <RDivider/>
                            <RenderValue label={"District"} value={district}/>
                            <RDivider/>
                            <RenderValue label={"Parliament"} value={pc}/>
                            <RDivider/>
                            <RenderValue label={"Region"} value={region}/>
                        </Stack>
                    </Stack>
                </Stack>

                {userStore.isRoot() ? < FSDPermissionButton/> : null}
            </div>
        </div>
    );
})

const RDivider = () => {
    return <Divider sx={{opacity: 1}} orientation="vertical" flexItem variant={"fullWidth"}/>
}

const RenderValue = ({label, value}) => {
    return (
        <Typography variant={"body1"}><span className={"font-bold text-gray-700"}>{label}:</span> {value}</Typography>
    )
}