import React, { memo } from "react";
import { useStore } from "../../helpers/helpers";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { safe_get } from "../../report-visuals/report-utils";
import { ViewAttachment } from "../../components";
import { useIsMobile } from "../../custom-hooks";

export const RenderRequestStatus = ({feature, status}) => {
    const rootStore = useStore();
    const { projectStore } = rootStore;
    const grievance_config = projectStore.getProjectProperty("grievances", {})
    const beneficiary_config = projectStore.getProjectProperty("beneficiary", {})
    const projectworks_config = projectStore.getProjectProperty("project_works", {})

    const getConfig = (feature) => {
        let config = {};
        switch (feature) {
            case "beneficiary":
                config = beneficiary_config;
                break;
            case "grievances":
                config = grievance_config;
                break;
            case "project_works":
                config = projectworks_config;
                break;
        }
        return config
    }
    const status_config = safe_get(getConfig(feature), "status_config", [])
    const match = status_config.length > 0 && status_config.find(x => x.status === status);
    const color = safe_get(match, "color", "#ffffff")
    return <p className={`p-2 text-white font-bold rounded-lg text-sm w-24 text-center `} style={{ backgroundColor: color }}>{status}</p>
}

export default memo(RenderRequestStatus)

export const RenderBeneficiaryRequests = observer((props: any) => {
    const isMobile = useIsMobile();
    const { beneficiaryStore } = useStore();
    const { t } = useTranslation("beneficiary");
    const { requests, header = false, edit = false, deleteRequest } = props;
    if (requests === undefined || requests.length === 0) return null;

    return (
        <div className="my-2 bg-white py-2 rounded-lg">
            {
                header && <div className="flex flex-row items-center px-2">
                    <i className="fas fa-hand-holding-seedling text-xl pr-4 text-purple-500" />
                    <h2 className='text-xl text-purple-500 font-bold py-2'>{t("requests")}</h2>
                </div>
            }

            <ul role="list">
                {requests.map((request) => {
                    const assigned_to = safe_get(request.assigned_to, "name", "")
                    return (
                        <li key={request.id}>
                            <div className="py-2 flex flex-col rounded-md bg-gray-100 mx-2 px-2 mb-2 group">
                                <div className="flex justify-between items-center">
                                    <p className="text-indigo-600 font-bold flex-1">{request.type}</p>
                                    <div className="flex items-center justify-between">
                                        <RenderRequestStatus feature={"beneficiary"} status={request.status}  />
                                        {edit && <div className="flex flex-row items-center justify-between">
                                            <i className={`fas fa-pencil text-gray-600 px-2 cursor-pointer transition-opacity duration-800 ${!isMobile ? "invisible group-hover:visible" : ""}`}
                                                onClick={() => {
                                                    beneficiaryStore.update_add_request_modal(true);
                                                    beneficiaryStore.setActiveRequest(request)
                                                }} />
                                            <i className={`fas fa-trash text-red-600 px-2 cursor-pointer transition-opacity duration-800 ${!isMobile ? "invisible group-hover:visible" : ""}`} onClick={() => deleteRequest(request.id)} />
                                        </div>}
                                    </div>
                                </div>
                                <p>{request.additional}</p>
                                {assigned_to ? <div>
                                    <div className={"font-bold text-gray-600"}>{t("this_request_assigned")}</div>
                                    <p className="text-indigo-600">{safe_get(request.assigned_to, "name", "N/A")}</p>
                                    <p className="text-indigo-600">{safe_get(request.assigned_to, "phone", "N/A")}</p>
                                    <p className="text-indigo-600">{safe_get(request.assigned_to, "designation", "N/A")}</p>
                                </div> : <p className="text-gray-400 italic">{t("request_to_concerned_person")}</p>}

                                {request.documents.length > 0 && request.documents.map(doc => {
                                    return <ViewAttachment label={false} key={doc.id} attachment={doc} backgroundColor={""} />
                                })}
                            </div>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
})