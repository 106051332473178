import React from "react";
import {Controller, useFormContext} from "react-hook-form";
import Select from "react-select";
import {FormHelperText, Typography} from "@mui/material";


export const RNSelect = ({name, options, label}) => {

    const {control, formState: {errors}} = useFormContext();

    return (
        <div>
            <Typography variant={"caption"} color={"primary"}>{label}</Typography>
            <Controller
                name={name}
                control={control}
                render={({field: {onChange, value}, fieldState: {error}}) => (
                    <>
                        <Select
                            defaultValue={value}
                            options={options}
                            onChange={(val) => onChange(val["value"])}
                        />
                        {error && (
                            <FormHelperText error>{error?.message}</FormHelperText>
                        )}
                    </>
                )}
            />
        </div>
    )
}