import React, { useEffect } from "react"; // Import React and useEffect hook for managing side effects.
import "./App.css"; // Import the global CSS file for the application.
import { MainRoutes } from "./routes/all"; // Import the main routes configuration for the application.
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"; // Import React Query for managing server state.
import { ToastContainer } from "react-toastify"; // Import ToastContainer for displaying toast notifications.
import "react-toastify/dist/ReactToastify.css"; // Import the CSS for react-toastify to style notifications.
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"; // Import Devtools for React Query to help with debugging queries.
import { createTheme, ThemeProvider } from "@mui/material/styles"; // Import MUI components for theming.

import ReactGA from "react-ga4"; // Import Google Analytics (GA4) for tracking page views and user interactions.

const TRACKING_ID = "G-Q3LXGR7Q86"; // Google Analytics tracking ID for the application.

export const Theme = createTheme({
  // Create a custom MUI theme for consistent styling across the app.
  palette: {
    primary: {
      main: "#009EA6", // Primary color for the theme.
    },
    secondary: {
      main: "#8590a5", // Secondary color for the theme.
    },
    success: {
      main: "#38c786", // Success color (e.g., for success messages).
    },
    info: {
      main: "#4e9def", // Info color (e.g., for informational messages).
    },
    warning: {
      main: "#f4ba40", // Warning color (e.g., for warnings).
    },
    error: {
      main: "#ed5e49", // Error color (e.g., for error messages).
    },
    background: {
      default: "#ffffff", // Default background color for the app.
    },
  },
  typography: {
    // Define typography styles for different heading levels.
    fontFamily: "MukthaVani", // Default font family for the app.
    h1: {
      fontFamily: "Palanquin",
      fontWeight: "bold", // Bold font weight for heading level 1 (h1).
    },
    h2: {
      fontFamily: "Palanquin",
      fontWeight: "bold", // Bold font weight for heading level 2 (h2).
    },
    h3: {
      fontFamily: "Palanquin",
      fontWeight: "bold", // Bold font weight for heading level 3 (h3).
    },
    h4: {
      fontFamily: "Palanquin",
      fontWeight: "bold", // Bold font weight for heading level 4 (h4).
    },
    h5: {
      fontFamily: "Palanquin",
      fontWeight: 700, // Semi-bold font weight for heading level 5 (h5).
    },
    h6: {
      fontFamily: "Palanquin",
      fontWeight: "bold", // Bold font weight for heading level 6 (h6).
    },
  },
});

const queryClient = new QueryClient({
  // Initialize a QueryClient instance with default options for React Query.
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // Disable refetching data when the window regains focus.
    },
  },
});

function App() {
  useEffect(() => {
    // Initialize Google Analytics with the provided tracking ID.
    ReactGA.initialize(TRACKING_ID);
    // Send a pageview event to Google Analytics with custom parameters.
    ReactGA.send({
      hitType: "pageview",
      page: "/landing/BYRBJP",
      title: "BYR Landing Page",
    });
  }, []); // Empty dependency array ensures this effect runs only once after the component mounts.

  return (
    <ThemeProvider theme={Theme}>
      {/* Wrap the app with MUI's ThemeProvider to apply the custom theme. */}
      <div className="App">
        <QueryClientProvider client={queryClient}>
          {/* Provide the QueryClient instance to the app, enabling React Query. */}
          <MainRoutes />
          {/* Render the main routes for the application. */}
          <ToastContainer />
          {/* Container for displaying toast notifications throughout the app. */}
          <ReactQueryDevtools initialIsOpen={false} position={"bottom-right"} />
          {/* Render React Query Devtools for debugging purposes. */}
        </QueryClientProvider>
      </div>
    </ThemeProvider>
  );
}

export default App; // Export the App component as the default export.
