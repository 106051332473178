import { observer } from "mobx-react-lite";
import React from "react";
import { useStore } from "../../../helpers/helpers";
import { htmlValidation } from "../../../helpers/utils";

const VoterReachOutListTarget = observer((props: any) => {
  const { voterReachOutStore } = useStore();
  const { reachOutTargetList } = voterReachOutStore;
  const { deleteMessage,setPopOver } = props;


  const editvoterReachOutTarget = (e, item) => {
    detailsReachOutTarget(item.id,"edit")
    htmlValidation(e);

  };
  const deleteVoterReachOutTarget = (e, id) => {
    htmlValidation(e);
    deleteMessage(id);
  };

  const detailsReachOutTarget =async (id,mode) => {
    const detailsTargetPayload = {
      "id": id
    };
  const response= await voterReachOutStore.details_reachout_target(detailsTargetPayload);
    if (response.status === 200) {
      mode=="edit"&&voterReachOutStore.set_isEditOverlay(true);
    }
  };

  return (
    <div>
      <div className="overflow-hidden my-2">
        <ul
          role="list"
          className=" grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 gap-4"
        >
          {reachOutTargetList && reachOutTargetList.length > 0 ? (
            reachOutTargetList.map((item) => (
              <li key={item.id}>
                <div className="flex items-center p-4 sm:px-6 cursor-pointer bg-white hover:bg-gray-50 rounded-lg shadow-lg" onClick={(e) => {
                                    htmlValidation(e)
                                    setPopOver(false)
                                    detailsReachOutTarget(item.id,"view")
                                    voterReachOutStore.set_IsMessageOverviewOpen(true)
                                    voterReachOutStore.set_selected_overview_item(item)

                                }}>
                  <div className="min-w-0 flex-1 flex items-center">
                    <div className="flex-shrink-0">
                      <div className="rounded-full bg-blue-200 text-4xl h-20 w-20 flex items-center justify-center text-white">
                        <i className="fa fa-check-to-slot" aria-hidden="true" />
                      </div>
                    </div>
                    <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-1 md:gap-2">
                      <div>
                        <p className="text-md font-medium text-indigo-600  font-bold pb-3">
                          {item.name}
                        </p>

                        <div className="flex flex-row overflow-hidden text-truncate text-nowrap">
                          <p className="flex items-center text-sm text-gray-500 mr-2 ">
                            <span className="truncate d-inline-block tooltip font-medium">
                              {item.count}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="flex flex-row justify-between pb-11"
                    >
                      <span
                        className="text-indigo-800"
                        onClick={(e) => editvoterReachOutTarget(e, item)}
                      >
                        <i className="fa-solid fa-pen-to-square mx-2" />
                      </span>
                      <span
                        className="text-red-600 pl-2"
                        onClick={(e) => deleteVoterReachOutTarget(e, item.id)}
                      >
                        <i className="fa-solid fa-trash" />
                      </span>
                    </div>
                  </div>
                </div>
              </li>
            ))
          ) : (
            <div className="italic text-gray-500 text-md p-4">
              No messages found....
            </div>
          )}
        </ul>
      </div>
    </div>
  );
});
export default VoterReachOutListTarget;
