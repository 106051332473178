import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import {ArrowPathIcon} from "@heroicons/react/24/solid";
import React from "react";
import {useStore} from "../../helpers/helpers";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTasks } from '@fortawesome/free-solid-svg-icons';


export const TaskSearch = () => {

    const rootStore = useStore();
    const [t]=useTranslation("task_manager");
    const {taskManagerStore,} = rootStore;
    const {projectid} = useParams();

    const refresh = async() => {
        await taskManagerStore.refresh_project_tasks(parseInt(projectid));
        await taskManagerStore.get_task_list(projectid);
    }


    return (
        <div className="flex flex-row items-center justify-between flex-1 sticky top-0">
            <FormControl sx={{ m: 1, width: '30ch', backgroundColor: "white" }} variant="outlined">
                <InputLabel htmlFor="search" style={{zIndex: 0}}>{t("search")}</InputLabel>
                <OutlinedInput
                    onChange={(evt) => taskManagerStore.set_task_list_query(evt.target.value)}
                    id="search"
                    type={'text'}
                    size={"small"}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="search tasks"
                                onClick={() => {}}
                                edge="end"
                                style={{zIndex: 0}}
                            >
                                <i className="fa fa-search text-gray-400 z-20 hover:text-gray-500"/>
                            </IconButton>
                        </InputAdornment>
                    }
                    label="Search"
                />
            </FormControl>
            <div className="flex flex">
                <button
                    type="button"
                    className="items-center p-2 m-4 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 "
                    onClick={() => {
                        taskManagerStore.update_task_filter_modal(true)
                    }}
                >
                    {/* <FilmIcon
                        name="Task Filters"
                        className=" h-5 w-5 text-white-400"
                        aria-hidden="true"/> */}
                 <FontAwesomeIcon icon={faTasks} style={{ fontSize: '20px' }} />

                </button>
                <button
                    type="button"
                    className="items-center p-2 m-4 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 "
                    onClick={() => refresh()}
                >
                    <ArrowPathIcon
                        name="Refresh Tasks"
                        className=" h-5 w-5 text-white-400"
                        aria-hidden="true"/>
                </button>
            </div>
        </div>
    )
}