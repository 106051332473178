import {observer} from "mobx-react-lite";
import React from 'react'
import {FormProvider, useForm} from "react-hook-form";
import {PWDefaultMedia} from "../pw-v2-types";
import {RNInput} from "../../../components/form-components";
import {FormButtons, SideOverlay} from "../../../components";
import {useStore} from "../../../helpers";


export const AddMediaV2 = observer ((props: any) => {

    const { projectWorkStoreV2 } = useStore()
    const { add_media_modal} = projectWorkStoreV2;


    const onSubmit = (data) =>{
        console.log(data)
    }


    const methods = useForm({
        defaultValues: PWDefaultMedia,
        mode: 'onSubmit'

    })

    const close_modal = () => {
        projectWorkStoreV2.update_project_work_property("add_media_modal",false);
    }
    return(
        <>
        <div>
            <SideOverlay
            onClose = {close_modal}
            show={add_media_modal}
            title = {"Add Media"}
            >
                <FormProvider{...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)} className="bg-white px-4 py-2 rounded-sm flex flex-col gap-y-4">
                        <RNInput
                            label={"Name"}
                            name={"name"}
                        />
                        <RNInput
                            label={"Description"}
                            name={"description"}
                            multiline
                            rows={3}
                            fullWidth
                        />
                        <FormButtons
                            onSave={() => {
                            }}
                            onCancel={close_modal}
                        />
                    </form>
                </FormProvider>
            </SideOverlay>
        </div>
        </>
    )
});