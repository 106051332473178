import React from "react";
import styled from "@emotion/styled";

export const label_style = "block font-bold text-gray-600 flex-2 w-48 sm:mt-px sm:py-1 text-sm";

export type initialValues = {
    name: string,
    description: string,
    file: any
    date: any,
    tags: string[]
}

export const Field = ({label, children, htmlFor, error}) => {
    const id = htmlFor || getChildId(children);
    // @ts-ignore
    return <Container errorState={!!error}>
        {label && <Label htmlFor={id}>{label}</Label>}
        {children}
        {!!error && <ErrorMessage role="alert">{error.message}</ErrorMessage>}
    </Container>;
};

export const getChildId = (children) => {
    const child = React.Children.only(children);
    if ("id" in child?.props) {
        return child.props.id;
    }
};


export const FieldSet = ({label, children}) => {
    return (
        <ContainerFC>
            {label && <Legend>{label}</Legend>}
            <Wrapper>{children}</Wrapper>
        </ContainerFC>
    );
};

export interface Tag {
    readonly label: string;
    readonly value: string;
}

export const createTag = (label: string) => ({
    label,
    value: label.toLowerCase().replace(/\W/g, ''),
});

const ContainerFC = styled.fieldset`
  margin: 8px 0;
  padding: 0;
  border: none;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: self-start;
`;

const Legend = styled.legend`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
  margin: 16px 0;
  padding: 0;
  border: none;
  width: 100%;

  input,
  textarea {
    border-color: ${x => {
    return (x["errorState"] ? "red" : "#d9d9d9");
}};
  }
`;

const Label = styled.label`
  margin-bottom: 2px;
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 14px;
`;

export const Input = styled.input`
  padding: 10px;
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
`;

export const TextArea = styled.textarea`
  padding: 4px 11px;
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
`;