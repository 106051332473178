import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import {FsFilterPane} from "../../../fsFilterPane";
import Select from 'react-select';
import {PlusIcon as PlusIconOutline} from "@heroicons/react/24/outline";
import {useStore} from "../../../../helpers";
import {ACBankDAO} from "../acbank_dao";
import {TimeLineCardView} from "./timeline-card";
import {LoadingState} from "../../../../components";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router";
import {useQuery} from "@tanstack/react-query";
import {safe_get} from "../../../../report-visuals/report-utils";
import {toast} from "react-toastify";
import {Pagination, Stack} from "@mui/material";

const allTags = ["MLA", "MP", "BJP", "INC", "JDS"];

const NoData = () => {
    const [t] = useTranslation(["timeline"]);
    return (
        <div>
            <p className="p-4 text-xl color-gray-600 font-bold">{t("no_data_available")}</p>
        </div>
    )
}

const ITEMS_PER_PAGE = 9;

export const ACBankTimeLine = observer((props: any) => {

    const rootStore = useStore();
    const {flexiStore} = rootStore;
    const {timeline_search_query} = flexiStore;
    const navigate = useNavigate();
    const {key, id} = useParams();
    const [t] = useTranslation(["timeline"]);
    const [selectedTag, setSelectedTag] = useState("");
    const dao = new ACBankDAO(rootStore)
    const [page, setPage] = useState(1);

    const startIndex = (page - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;

    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const fetch_timeline = async () => {
        const payload = dao.get_timeline_payload()
        return await flexiStore.get_timeline(payload);
    }

    const {data, isLoading, isSuccess, refetch} = useQuery(['timeline', timeline_search_query], fetch_timeline);

    const timeline_data = isSuccess ? safe_get(data, "data", []) : [];

    const handleSearch = (query) => {
        flexiStore.update_timeline_property("timeline_search_query", query)
    }

    const onTimelineDelete = async (id) => {
        const response = await flexiStore.delete_timeline(id);
        if (response.status === 200) {
            const payload = dao.get_timeline_payload()
            await flexiStore.get_timeline(payload);
            toast.warning("Timeline Deleted successfully..")
            await refetch();
        }
    }

    if (isLoading) return <LoadingState/>
    const paginatedTimelineData = timeline_data.slice(startIndex, endIndex);
    return (
        <div className="p-4">
            <div className="flex flex-row justify-between items-center flex-wrap">
                <FsFilterPane onSearch={handleSearch}/>
                <div className=' w-full lg:w-1/2'>
                    <Select
                        defaultValue={selectedTag}
                        isMulti
                        name="tags"
                        // @ts-ignore
                        options={allTags.map(x => {
                            return {"value": x, "label": x};
                        })}
                        onChange={(val) => {
                            // @ts-ignore
                            setSelectedTag(val);
                        }}
                        placeholder="Filter by tags..."
                        className="w-full h-10 rounded z-0 focus:shadow outline-none basic-multi-select"
                        classNamePrefix="select"
                    />
                </div>
            </div>
            <div className="flex flex-row items-center">
                <div className="font-bold text-purple-500 text-xl">{t("add_new_update")}</div>
                <button
                    type="button"
                    className="items-center self-start p-2 m-4 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 "
                    onClick={(event) => {
                        flexiStore.update_timeline_property("timeline_edit_mode", false)
                        navigate(`/databank/repo/${key}/${id}/timeline/new`, {
                            state: {
                                timeline: {},
                                edit: false
                            }
                        })
                    }}
                >
                    <PlusIconOutline className="h-5 w-5" aria-hidden="true"/>
                </button>
            </div>
            {timeline_data.length <= 0 && <NoData/>}
            <div className="grid grid-cols-3 gap-4">
                {paginatedTimelineData.map((timeline) => (
                    <TimeLineCardView key={timeline.id} card={timeline} onDelete={onTimelineDelete}/>
                ))}
            </div>
            <div>
                <Stack spacing={2} mt={4} justifyContent="center" alignItems="center">
                    <Pagination
                        count={Math.ceil(timeline_data.length / ITEMS_PER_PAGE)}
                        page={page}
                        onChange={handleChangePage}
                        shape="rounded"
                        size="large"
                        color="primary"
                    />
                </Stack>
            </div>
        </div>
    )
});