import {useEffect, useState} from "react";
import {useKey} from "react-use";
import {toast} from "react-toastify";

const PASSKEY = "arakere"

export const useArekere = () => {

    const [passkey, setPassKey] = useState("");
    const [isKeyPressed, setIsKeyPressed] = useState(false)

    useEffect(() => {

        if (isKeyPressed) {
            setPassKey("")
            const handleKeyPress = e => {
                setPassKey(prev => prev + e.key)
            }
            window.addEventListener("keypress", handleKeyPress)
            return () => {
                window.removeEventListener("keypress", handleKeyPress);
            }
        }
    }, [isKeyPressed])

    const increment = (e) => {
        if (e.ctrlKey) {
            setIsKeyPressed(true)
            toast.warning("enter pass key", {autoClose: 1000, theme:"dark"})
            setPassKey("")
        }
    };
    useKey('[', increment);

    return PASSKEY === passkey

}