import React, {useMemo} from "react";
import {observer} from "mobx-react-lite";
import {useStore} from "../../../helpers/helpers";
import _ from "lodash";
import {Table} from "./table";
import {toJS} from "mobx";
import {TableHeader} from "./survey-summary";
import { useTranslation } from "react-i18next";

export const SurveysByUserHL = observer(props => {
    const {surveyResponseStore, userStore} = useStore();
    const {allSurveyResponsesSummary} = surveyResponseStore;
    const {t} = useTranslation("survey_management")

    const data = useMemo(() => toJS(allSurveyResponsesSummary)["by_surveyor_hl"], [allSurveyResponsesSummary]);

    const columns = useMemo(() => [

        {
            Header: t("name"),
            accessor: 'surveyor_name',
            Footer: t("total")
        },
        {
            Header: t("highlights"),
            accessor: 'has-hl',
            Footer: surveyResponseStore.get_total_surveys(data, "has-hl")
        },
        {
            Header: t("no_highlights"),
            accessor: 'no-hl',
            Footer: surveyResponseStore.get_total_surveys(data, "no-hl")
        }, {
            Header: t("not_checked"),
            accessor: 'not-checked',
            Footer: surveyResponseStore.get_total_surveys(data, "not-checked")
        }, {
            Header: t("total"),
            accessor: 'total',
            Footer: surveyResponseStore.get_total_surveys(data, "total")
        }

    ], [allSurveyResponsesSummary]);

    if (_.isEmpty(allSurveyResponsesSummary["by_qalog_user_status"]) || userStore.is_field_worker()) return null;

    const row_click_handler = (x) => {
        surveyResponseStore.update_new_filters("surveyor_id", x["surveyor_id"], `Has Highlights: ${x['surveyor_name']}`);
    }

    return (
        <div className="flex flex-col">
            <TableHeader title={t("interviews_by_audio")}/>
            <Table data={data} columns={columns} onRowClick={row_click_handler}/>
        </div>
    )
})

