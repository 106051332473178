import React, { useState } from "react";
import { Recorder } from 'react-voice-recorder'
import 'react-voice-recorder/dist/index.css'

export const VoiceRecorder = props => {

    const audio_object = {
        url: null,
        blob: null,
        chunks: null,
        duration: {
            h: 0,
            m: 0,
            s: 0
        }
    }

    const [blob, setBlob] = useState(null)

    const handleAudioStop = (data) => {
        setAudioDetails({ ...audioDetails, url: data })
        setBlob(data.blob)
    }

    const handleAudioUpload = (data) => {
        props.handleAudioUpload(blob)
    }

    const handleCountDown = data => {
        console.log(data);
    }

    const handleReset = () => {
        setAudioDetails(audio_object)
        setBlob(null)
        props.handleReset()
    }

    const [audioDetails, setAudioDetails] = useState(audio_object);

    return (
        <>
            <div className='audiorecorder'>
                <Recorder
                    record={true}
                    audioURL={audioDetails.url}
                    showUIAudio
                    handleAudioStop={data => handleAudioStop(data)}
                    handleAudioUpload={data => handleAudioUpload(data)}
                    handleCountDown={data => handleCountDown(data)}
                    handleReset={() => handleReset()}
                    mimeTypeToUseWhenRecording={`audio/webm`} // For specific mimetype.
                    hideHeader
                />
            </div>
        </>
    )
}