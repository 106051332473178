import React, {useState} from "react";
import {contact} from "./contact-card";
import {useStore} from "../../../helpers/helpers";
import {useForm} from "react-hook-form";
import {makeid_alpa_numeric} from "../../../helpers/utils";
import { useTranslation } from "react-i18next";
import {ErrorMeaasge, label_style, ProfileFormField} from "../../beneficiary-management";
import {FormButtons} from "../../../components/cm-components";
import {safe_get} from "../../../report-visuals/report-utils";
import Select from "react-select";
import {colourStyles} from "../../../components";

type addContactProps = {
    onAdd: Function,
    contact: contact
}

export const AddContact = (props:addContactProps) => {

    const {onAdd, contact} = props;
    const {projectWorksStore, projectStore} = useStore()
    const [addContact, setAddContact] = useState(contact);
    const {t} = useTranslation("project_work")
    const {register, handleSubmit, formState: {errors}} = useForm({defaultValues: addContact, mode: 'onSubmit', reValidateMode: 'onBlur'});

    const addContactChangeHandler = (val, filed_name) => {
        setAddContact({...addContact, [filed_name]: val});
    }

    const project_works_property = projectStore.getProjectProperty("project_works", {})
    const key_contact_tags = safe_get(project_works_property, "key_contacts_tags", [])

    const onSubmit = async (values) => {
        if(values.id){
            onAdd({...addContact})
        } else {
            onAdd({...addContact, id: makeid_alpa_numeric(6)})
        }
        projectWorksStore.update_project_work_property("add_contact_modal", false);
    }

    return(
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <ProfileFormField
                        register={register}
                        field_name="name"
                        value={addContact.name}
                        label={t("name")}
                        onChangeHandler={addContactChangeHandler}
                        required={true}
                        error={errors}
                        error_msg={t("name_required")}
                    />
                    <ProfileFormField
                        register={register}
                        field_name="phone"
                        value={addContact.phone}
                        label={t("phone_number")}
                        onChangeHandler={addContactChangeHandler}
                        required={true}
                        error={errors}
                        error_msg={t("phonenumber_required")}
                    />
                    <ProfileFormField
                        register={register}
                        field_name="alternate_phone"
                        value={addContact.alternate_phone}
                        label={t("alternate_phone_number")}
                        onChangeHandler={addContactChangeHandler}
                        required={false}
                        error={errors}
                        error_msg={''}
                    />
                    <ProfileFormField
                        register={register}
                        field_name="email"
                        value={addContact.email}
                        label={t("email")}
                        onChangeHandler={addContactChangeHandler}
                        required={false}
                        error={errors}
                        error_msg={''}
                        type={'email'}
                    />
                    <ProfileFormField
                        register={register}
                        field_name="address"
                        value={addContact.address}
                        label={t("address")}
                        onChangeHandler={addContactChangeHandler}
                        required={true}
                        error={errors}
                        error_msg={t("address_required")}
                    />
                    <div className="flex flex-row items-center justify-center mb-2">
                        <label htmlFor="designation" className={label_style}>Designation</label>
                        <Select
                            value={safe_get(addContact, "designation", {label: "", value:""})}
                            //@ts-ignore
                            options={key_contact_tags}
                            getOptionLabel={(option) => option["label"]}
                            getOptionValue={(option) => option["value"]}
                            onChange={(e) => {
                                setAddContact({...addContact, designation:e})
                            }}
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            menuPortalTarget={document.querySelector('body')}
                            styles={colourStyles}
                        />
                    </div>

                    <div className="mb-2">
                        <label htmlFor="comments" className={label_style}>{t("comments")}</label>
                        <textarea
                            {...register("comments", {required: true})}
                            name="comments"
                            className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md border p-2"
                            rows={3}
                            value={addContact.comments}
                            onChange={event => {
                                setAddContact({...addContact, comments: event.target.value})
                            }}
                        />
                        <ErrorMeaasge val={errors["comments"]} error={"Required"}/>
                    </div>
                </div>

                <FormButtons
                    onSave={()=> {}}
                    onCancel={(e) => {
                        projectWorksStore.update_project_work_property("add_contact_modal", false);
                    }}
                />
            </form>
        </>
    )
}