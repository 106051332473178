import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import Select from 'react-select';
import {classNames} from "../../helpers/utils";
import {useStore} from "../../helpers/helpers";
import {safe_get} from "../../report-visuals/report-utils";
import {toast} from "react-toastify";
import { useTranslation } from "react-i18next";
import {LoadingState} from "../../components";

export const UpdateApplicationStatus = observer((props: any) => {

    const {config} = props;
    const {status} = config;

    const {surveyResponseStore, userStore} = useStore();
    const {activeSurveyResponseId, activeSurveyResponse} = surveyResponseStore;
    const {user} = userStore;
    const [selectedOption, setSelectedOption] = useState(null);
    const [comments, setComments] = useState("");
    const [loading, setLoading] = useState(false);
    const [t] = useTranslation(["new_voter_registration" ,"beneficiary"]);

    return (
        <div className="py-4 flex flex-col sm:w-1/2">
            {loading && <LoadingState/>}
            <div className="flex flex-col">
                <div className="font-bold text-purple-600">{t("registration_status")} :</div>
                <Select
                    className="py-2"
                    defaultValue={selectedOption}
                    onChange={setSelectedOption}
                    options={status.map(x => {
                        return {
                            value: x,
                            label: x
                        }
                    })}
                />
            </div>

            <div className="flex flex-col">
                <div className="font-bold text-purple-600">{t("comments")} :</div>
                <textarea className='w-full bg-gray-50 px-4 py-2 h-24'
                          value={comments}
                          onChange={(event) => {
                              const val = event.target.value;
                              setComments(val)
                          }}
                />
            </div>

            <div className="flex flex-row justify-end my-2">
                <div className="flex flex-row justify-between sm:w-40 my-2">
                    <button onClick={async () => {
                        setLoading(true);
                        const stub = {
                            "status": selectedOption["value"],
                            "comments": comments,
                            "user": {
                                "name": user["name"],
                                "id": user["id"],
                                "phonenum": user["phonenum"],
                            },
                            "time": Date.now()
                        }

                        const update_arr = safe_get(activeSurveyResponse['sr'],'more.voter_registration_tracker.updates', [])
                        update_arr.push(stub)

                        const payload = {
                            "clientid": activeSurveyResponseId,
                            "prop": "voter_registration_tracker",
                            "status": selectedOption["value"],
                            "valuemap": {
                                "updates": update_arr,
                            }
                        }

                        const res = await surveyResponseStore.update_voter_reg_status(payload);
                        if (res.status === 204) {
                            setLoading(false);
                            toast.success(t("status_updated_successfully"));
                        } else {
                            setLoading(false);
                            toast.warning(t("something_went_wrong"));
                        }
                    }}
                            className={classNames(comments == "" ? "pointer-events-none opacity-50" : "pointer-events-auto", "rounded-lg w-20 mx-2 font-bold shadow-lg bg-green-600 p-2 text-white")}>
                        {t("save",{ns:"beneficiary"})}
                    </button>

                    <button onClick={() => {
                    }}
                            className={classNames(comments == "" ? "pointer-events-none opacity-50" : "pointer-events-auto", "rounded-lg w-20 shadow-lg font-bold bg-red-600 p-2 text-white")}>
                        {t("cancel",{ns:"beneficiary"})}
                    </button>
                </div>

            </div>
        </div>
    )
})