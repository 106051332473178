import React, {useEffect, useRef, useState} from "react";
import {FormButtons, TailwindModal} from "../../components";
import Select from "react-select";
import {useStore} from "../../helpers";
import {safe_get} from "../../report-visuals/report-utils";
import _ from "lodash";
import {observer} from "mobx-react-lite";

const roles = [
    {label: "Root", value: "root"},
    {label: "Client", value: "client"},
    {label: "Party worker", value: "partyworker"},
    {label: "Field Worker", value: "surveyworker"},
    {label: "QA Worker", value: "qaworker"},
];

export const EditUser = observer((props: any) => {

  const rootStore = useStore();
  const {projectStore, userStore} = rootStore;
  const {activeUser, edit_user_modal} = userStore;
  const defaultTags = projectStore.getProjectProperty("default_tags", {});
  let user_tags = safe_get(defaultTags, "user_tags", []);
  user_tags = user_tags.map((tag) => ({label: tag, value: tag}));

  const [selectedRole, setSelectedRole] = useState(
      roles.find((role) => role.value === activeUser.role) || null
  );
  const [errors, setErrors] = useState({field: "", message: ""});
  const [selectedTags, setSelectedTags] = useState([
    {label: "root", value: "Root"},
  ]);
  const new_name = useRef(null);
  const new_number = useRef(null);

  useEffect(() => {
    const current_user_tags =
        activeUser?.tags?.map((tag) => ({
          label: tag,
          value: tag,
        })) || [];
    setErrors({field: "", message: ""});
    setSelectedTags(current_user_tags);
    setSelectedRole(
        roles.find((role) => role.value === activeUser.role) || null
    );
  }, [activeUser]);

  const handleRoleChange = (selectedOption) => {
    setSelectedRole(selectedOption);
  };
  const handleTagsChange = (selectedTag) => {
    setSelectedTags(selectedTag);
  };
  const handleSave = () => {
    let updatedUser = _.cloneDeep(activeUser);

    const user = {
      id: activeUser.id,
      name: new_name.current.value,
      phone: new_number.current.value,
      role: selectedRole.value,
      tag: [...selectedTags.map((tag) => tag.value)],
    };
    if (user.name === "" || user.name.length < 3) {
      new_name.current.focus();
      setErrors({
        field: "name",
        message: "Name of min 3 characters is required",
      });
      // alert("User cannot be empty");
    } else if (user.phone.length < 13) {
      new_number.current.focus();
      setErrors({
        field: "phone",
        message: "Phone number must be 10 digits!",
      });
    } else {
      setErrors({
        field: "",
        message: "",
      });
      _.set(updatedUser, "name", user.name);
      _.set(updatedUser, "profile.name", user.name);
      _.set(updatedUser, "phonenum", user.phone);
      _.set(updatedUser, "role", user.role);
      _.set(updatedUser, "tags", user.tag);
    }
    userStore.update_user_store_prop("edit_user_modal", false);
    console.log(updatedUser);
  };

  return (
      <TailwindModal isOpen={edit_user_modal}>
        <div
            className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full p-2 h-screen">
          <div className="flex-1 m-2">
            <h1 className="text-xl font-semibold">Edit {activeUser.name}</h1>
            <div className="mt-5 flex flex-col space-y-4">
              {/* Name Input */}
              <div>
                <label
                    htmlFor="user_name"
                    className="block text-gray-700 font-medium"
                >
                  Name
                </label>
                <input
                    type="text"
                    id="user_name"
                    name="user_name"
                    className={`mt-1 block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-2  ${
                        errors.field === "name"
                            ? "focus:ring-red-500 focus:border-red-500 "
                            : "focus:ring-blue-500 focus:border-blue-500"
                    }  sm:text-md`}
                    ref={new_name}
                    defaultValue={activeUser.name}
                />
                <p className="mt-2 text-xs text-red-500">
                  {errors.field === "name" && errors.message}
                </p>
              </div>

              {/* Phone Number Input */}
              <div>
                <label
                    htmlFor="phone_number"
                    className="block text-gray-700 font-medium"
                >
                  Phone Number
                </label>
                <input
                    type="tel"
                    id="phone_number"
                    name="phone_number"
                    typeof="number"
                    maxLength={13}
                    className={`mt-1 block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-2  ${
                        errors.field === "phone"
                            ? "focus:ring-red-500 focus:border-red-500 "
                            : "focus:ring-blue-500 focus:border-blue-500"
                    }  sm:text-md`}
                    ref={new_number}
                    defaultValue={activeUser.phonenum}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const firstTwoChars = e.target.value.slice(0, 3);
                      const expectedPrefix = "+91"; // Replace this with the two characters you want to keep intact

                      // Ensure the first two characters cannot be deleted
                      if (firstTwoChars !== expectedPrefix) {
                        e.target.value = expectedPrefix + e.target.value.slice(3);
                      }
                      e.target.value =
                          expectedPrefix +
                          Math.max(0, parseInt(e.target.value.slice(3)) || 0)
                              .toString()
                              .slice(0, 10);
                    }}
                />
                <p className="mt-2 text-xs text-red-500">
                  {errors.field === "phone" && errors.message}
                </p>
              </div>
              {/* role select */}
              <div>
                <label htmlFor="role" className="block text-gray-700 font-medium">
                  Role
                </label>
                <Select
                    id="role"
                    options={roles}
                    value={selectedRole}
                    onChange={handleRoleChange}
                    className="mt-1"
                    classNamePrefix="react-select"
                />
              </div>
              {/* Tags Select Box */}
              <div>
                <label htmlFor="tags" className="block text-gray-700 font-medium">
                  Tags
                </label>
                <Select
                    id="tags"
                    isMulti
                    value={selectedTags}
                    options={user_tags}
                    onChange={handleTagsChange}
                    className="mt-1"
                    classNamePrefix="react-select"
                />
              </div>
            </div>
          </div>
          <br/>
          <br/>
          <br/>
          <br/>
          <div className="flex-1">
            <FormButtons
                onSave={() => {
                  handleSave();
                }}
                onCancel={() => {
                  userStore.update_user_store_prop("edit_user_modal", false);
                }}
            />
          </div>
        </div>
      </TailwindModal>
  );
})

