import { ReportTransformers } from "./report-transformers"; // Import custom report transformers for data manipulation.
import * as am4core from "@amcharts/amcharts4/core"; // Import amCharts core module for working with charts.

// Function to reduce a dataset based on given reducers and filters.
export const reduce_for_visual = (ds, reducers, filters) => {
  const transformer = new ReportTransformers(); // Create an instance of ReportTransformers for data operations.

  let current = ds; // Initialize the current dataset with the input dataset.

  // Apply filters to the dataset using the transformer.
  current = transformer["filter"](current, filters);

  // Iterate over each reducer and apply the corresponding transformation.
  for (const reducer of reducers) {
    const { op, cols, out } = reducer; // Destructure the reducer to get operation, columns, and output field.
    if (transformer[op])
      // Check if the operation exists in the transformer.
      current = transformer[op](current, reducer); // Apply the transformation operation to the dataset.
  }

  return current; // Return the transformed dataset.
};

// Function to safely access a property in a nested object structure.
export const safe_get = (map_object, key, defaultValue) => {
  try {
    let current = map_object; // Initialize the current object with the input object.
    const parts = key.split("."); // Split the key into parts by '.' to handle nested keys.
    for (const part of parts) {
      if (part.startsWith("$")) {
        // Handle special cases if the part starts with '$' (custom logic can be added here).
      } else {
        if (Object.keys(current).includes(part)) {
          current = current[part]; // Navigate to the next level of the object.
        } else {
          return defaultValue; // Return the default value if the key does not exist.
        }
      }
    }
    return current; // Return the found value.
  } catch (e) {
    // Handle errors if any part of the object navigation fails.
  }

  return defaultValue; // Return the default value in case of an error.
};

// Color palettes for charts and visualizations.
const materialDesignColors = [
  "#f44336",
  "#e91e63",
  "#9c27b0",
  "#673ab7",
  "#3f51b5",
  "#2196f3",
  "#03a9f4",
  "#00bcd4",
  "#009688",
  "#4caf50",
  "#8bc34a",
  "#cddc39",
  "#ffeb3b",
  "#ffc107",
  "#ff9800",
  "#ff5722",
  "#795548",
  "#9e9e9e",
  "#607d8b",
];

const flatUiColors = [
  "#1abc9c",
  "#2ecc71",
  "#3498db",
  "#9b59b6",
  "#34495e",
  "#16a085",
  "#27ae60",
  "#2980b9",
  "#8e44ad",
  "#2c3e50",
  "#f1c40f",
  "#e67e22",
  "#e74c3c",
  "#ecf0f1",
  "#95a5a6",
  "#f39c12",
  "#d35400",
  "#c0392b",
  "#bdc3c7",
  "#7f8c8d",
];

const pastelColors = [
  "#ff6961",
  "#ffcbf2",
  "#cb99c9",
  "#aec6cf",
  "#77dd77",
  "#fdfd96",
  "#ffb347",
  "#cd9575",
  "#cfcfc4",
];

const materialDesign2014Colors = [
  "#f44336",
  "#e91e63",
  "#9c27b0",
  "#673ab7",
  "#3f51b5",
  "#2196f3",
  "#03a9f4",
  "#00bcd4",
  "#009688",
  "#4caf50",
  "#8bc34a",
  "#cddc39",
  "#ffeb3b",
  "#ffc107",
  "#ff9800",
  "#ff5722",
  "#795548",
  "#9e9e9e",
  "#607d8b",
];

const flatDesignColors = [
  "#e74c3c",
  "#e91e63",
  "#9b59b6",
  "#8e44ad",
  "#4a69bd",
  "#3498db",
  "#5eb9f7",
  "#00bcd4",
  "#16a085",
  "#2ecc71",
  "#2ed573",
  "#a5c63b",
  "#f1c40f",
  "#ffa502",
  "#ff793f",
  "#ff6600",
  "#d35400",
  "#95a5a6",
  "#607d8b",
];

// Combine all color palettes into a single array for easy access.
const allColors = materialDesignColors.concat(
  flatUiColors,
  pastelColors,
  materialDesign2014Colors,
  flatDesignColors
);

// Function to get a color from the combined palette based on an index.
export const get_color = (index) => {
  const colorIndex = index % allColors.length; // Calculate the index within the color palette array.
  return am4core.color(allColors[colorIndex]); // Return the color using amCharts' color utility.
};
