import {Button} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";

export const SearchVotersButton = ({searchVotersHandler, ...props}) => {

    const { t } = useTranslation("beneficiary");

    return(
        <Button
            onClick={searchVotersHandler}
            variant={"contained"}
            endIcon={<i className="fas fa-search"/>}
            size={"small"}
            {...props}
        >
            {t("search_voters")}
        </Button>
    )
}