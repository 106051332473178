import React from "react"
import {observer} from "mobx-react-lite";
import {Button} from "@mui/material";
import {useStore} from "../../helpers";


export const FSDPermissionButton = observer((props:any) => {

    const {flexiStore} = useStore();

    return (
        <Button
            variant={"contained"}
            onClick={() => {
                flexiStore.update_flexistore_property("manage_fsd_permissions_overlay", true)
            }}
            color={"warning"}
            startIcon={<i className={"fas fa-key"} />}
        >
            Permissions
        </Button>
    )
})