import React from "react";
import {DashBoard} from "./dashboard-template";

export const KaraikudiDashBoard = () => {

    const frame = `<iframe title="AC 184 Karaikudi Dashboard" width="100%" height="100%" src="https://app.powerbi.com/view?r=eyJrIjoiYzI2OGJkNjUtY2UyMi00ZGRhLWJlZWYtNTgwNDgzNDUxOTk4IiwidCI6IjAzZjhjZWI2LTdhMGUtNDcwMy05ZGE0LWUyYTBkYmE1NmM4YyJ9" frameborder="0" allowFullScreen="true"></iframe>`

    return (
        <>
            <DashBoard passCode={"Prabhu"} iframe={frame} />
        </>
    )
}