import {action, computed, observable, makeAutoObservable} from "mobx"
import {RootStore} from "./root";

export class UserStore {

    hasCheckedAuth: boolean = false;
    user: any = null;
    isAuthorized: boolean = false;
    activeUser: any;
    userPermissionsAndParts: any = null;
    userDetailsMode: boolean = false;
    addTeam: boolean = false;
    editTeam: boolean = false;
    userId: any = null;
    userList = []
    userTag = [];
    userProjects = [];
    profile_update: boolean = false;
    add_new_users_modal: boolean = false;
    remove_user_modal: boolean = false;
    ban_user_modal: boolean = false;
    edit_user_modal: boolean = false;

    rootStore: any;

    constructor(p: RootStore) {
        this.rootStore = p;
        this.isAuthorized = false;
        this.hasCheckedAuth = false;
        this.activeUser = {};
        this.userPermissionsAndParts = {};
         makeAutoObservable(this);
    }


    @action
    checkLogin = () => {
        this.hasCheckedAuth = true;
        const userInfoInLocalStorage = window.localStorage.getItem("__userprofile");
        if (userInfoInLocalStorage && userInfoInLocalStorage != '') {
            this.user = JSON.parse(userInfoInLocalStorage);
            this.isAuthorized = true;
            return true;
        } else {
            this.user = null;
            this.isAuthorized = false;
            return false;
        }
    };

    @action
    getToken() {
        const userInfoInLocalStorage = window.localStorage.getItem("__userprofile");
        if (userInfoInLocalStorage != null) {
            const userInfo = JSON.parse(userInfoInLocalStorage);
            return userInfo.token;
        }
    }

    @action
   async sendOTP (phone: string){
        const payload = {
            "phonenum": phone,
            "deviceid": "web:00:browser"
        };
        return await this.rootStore.api.post('user/login', payload);
    };

    @action
    validateOTP = async (phone: string, otp: string) => {
        const payload = {
            "phonenum": phone,
            "deviceid": "web:00:browser",
            "otp": otp
        };
        try {
            const response = await this.rootStore.api.post('user/validateotp', payload);
            const profile = response.data;
            profile.projects = profile.projects.sort((a: { id: number; }, b: { id: number; }) => {
                return a.id - b.id;
            });
            if (response.ok) {
                this.saveProfile(profile);
                return true;
            } else {
                this.checkLogin();
                return false;
            }
        } catch (e) {
            this.checkLogin();
            return false;
        }
    };

    @action
    saveProfile(profile: any) {
        const userInfo = JSON.stringify(profile);
        window.localStorage.setItem("__userprofile", userInfo);
        this.checkLogin();
    }
    @computed
    get isLoggedIn(): boolean {
        return this.isAuthorized;
    }

    @action
    getProjects = async () => {
        const response = await this.rootStore.api.post('project/myprojects', {});
        if (response.status == 200) {
            this.userProjects = response.data;
            return response.data;
        }

       else return []
    };

    @action
    getDisplayName = () => {
        try {
            if (this.isLoggedIn) return this.user['name'];
        } catch (e) {

        }
        return '';
    }

    getLoggedinUserId = () => {
        try {
            if (this.isLoggedIn) return this.user['id'];
        } catch (e) {
            console.log(e)
        }
        return -1;
    }

    @action
    getDisplayRole = () => {
        try {
            if (this.isLoggedIn) {
                const role = this.user['role'];
                switch (role) {
                    case "root":
                        return "Root";
                    case "client":
                        return "Administrator";
                    case "partyworker":
                        return "Party Worker";
                    case "surveyworker":
                        return "Field Worker";
                    case "qaworker":
                        return "QA Specialist";
                }
            }
        } catch (e) {

        }
        return '';
    }

    @action
    logout = async() => {
        const items_to_remove = ["__userprofile", "__active_project", "pageIndex", "currentPage", "userpartsandperms", "header", "timeline", "isSigned"]
        items_to_remove.forEach(async(item) => {
            await window.localStorage.removeItem(item)
        })
        // window.localStorage.removeItem("__active_project");
        this.isAuthorized = false;
    };

    @action
    isRoot = () => {
        try {
            if (this.isLoggedIn) return this.user['role'] === "root";
        } catch (e) { }
        return false;
    }

    @action
    isClient = () => {
        try {
            if (this.isLoggedIn) return this.user['role'] === "client";
        } catch (e) {
            console.log(e)
        }
        return false;
    }

    @action
    setActiveUser = user => {
        this.activeUser = user;
    }

    @action
    getUserRolesDefinitions = async () => {
        return await this.rootStore.api.post("user/roledefs")
    }

    @action
    set_profile_update(val){
        this.profile_update = val;
    }

    @action
    async updateProfile(payload) {
        try {
            const response = await this.rootStore.api.post('user/updateprofile', payload);
            return response;
        } catch (error) {
            console.log(error)
        }
    }

    @action
    async getProfile() {
        try {
            const secondresponse = this.rootStore.api.post('user/me');
            return secondresponse;
        }
        catch (error) {
            console.log(error)
        }
    }

    @action
    setUserList = (val) => {
        this.userList = val
    }

    @action
    setUserTag = (val) => {
        this.userTag = val
    }

    @action
    setEditTeamStatus = (val) => {
        this.editTeam = val
    }

    @action
    setAddTeamStatus = (val) => {
        this.addTeam = val
    }

    @action
    async add_Team(payload) {
        try {
            return await this.rootStore.api.post("/project/editprojectproperties", payload)
        } catch (e) {
        }
    }

    @action
    updateUserHierarchy = async (payload) => {
        return await this.rootStore.api.post("user/updateuserhierarchy", payload)
    }

    @action
    updateUserPermissions = async (payload) => {
        return await this.rootStore.api.post("user/updateuserperms", payload)
    }

    @action
    setUserDetailsMode = mode => {
        this.userDetailsMode = mode
    }

    @action
    setUserId = val => {
        this.userId = val
    }

    @action
    async ban_user(payload) {
        //return await this.rootStore.api.post("project/removeuser", payload);
        const req_pl = {
            'userid': payload['userid'],
            'userrole': '',
            'issupervisor': false,
        }
        return await this.rootStore.api.post("user/archiveuser", req_pl);
    }

    @action
    async remove_user_from_project(payload) {
        return await this.rootStore.api.post("user/removeuser", payload);
    }

    @action
    is_field_worker() {
        try {
            const role = this.user["role"];
            if (role === "surveyworker" || role === "partyworker") return true
        } catch (e) {
            return false;
        }
        return false
    }

    @action
    get_user_permissions_and_parts = async (payload) => {
        try {
            const res = await this.rootStore.api.post("user/userrichinfo", payload);
            this.userPermissionsAndParts = res.data;
            localStorage.setItem("userpartsandperms", JSON.stringify(res.data))
            return res
        } catch (e) {
            console.log(e)
        }
    }

    @action
    update_user_store_prop(prop, value) {
        switch (prop) {
            case "add_new_users_modal":
                this.add_new_users_modal = value;
                break;
            case "remove_user_modal":
                this.remove_user_modal = value;
                break;
            case "ban_user_modal":
                this.ban_user_modal = value;
                break;
            case "edit_user_modal":
                this.edit_user_modal = value;
                break;
        }
    }


}