import React, { useState} from "react";
import {LoadingState, TabView} from "../../../components";
import {RootStore} from "../../../stores/root";
import {useStore} from "../../../helpers";
import {safe_get} from "../../../report-visuals/report-utils";
import {LeafletACMap} from "../../../components";
import {useQuery} from "@tanstack/react-query";
import {observer} from "mobx-react-lite";
import _ from "lodash"
import {FieldSamplesData} from "./field-samples-data";
import {Stack, Typography} from "@mui/material";
import {DistrictTitleCard} from "./district-title-card";
import {AddUsersToFSD, ManageFSDPermissions} from "../../fsd-permissions";
import {HistoricalData} from "./historical-data";
import {useArekere} from "./useArekere";
import {MH2024Crumbs} from "./mh-2024-crumbs";
import {mh_map_config, tabOptions} from "./utils";
import {MHDemographics} from "./mh-demographics";

export const MH2024FieldViewMainPage = observer((props: any) => {

    const rootStore: RootStore = useStore();
    const {flexiStore} = rootStore;
    const isAllowed = useArekere();
    const fst = safe_get(JSON.parse(sessionStorage.getItem("fst")), "summary.name", "Repositories");
    const [activeTab, setActiveTab] = useState(tabOptions[0].name)
    // @ts-ignore
    const fsd = flexiStore.current_fsd;
    const r1 = safe_get(fsd, "r1", {})
    const r2 = safe_get(fsd, "r2", {})
    const r12 = safe_get(fsd, "r12", {})
    const rfield = isAllowed ? "r12" : "r1"

    const field_data = safe_get(fsd, `${rfield}.field.analytics`, {});
    const samples = safe_get(fsd, `${rfield}.field.summary.s1`, {});
    const geojson = safe_get(fsd, `${rfield}.field.summary.acmap`, {});
    const location_records = safe_get(fsd, `${rfield}.field.location_records`, [])

    const {data, isLoading} = useQuery({
        queryKey: ["ac-map"],
        queryFn: async () => {
            return rootStore.flexiStore.load_s3_json_resource(
                geojson?.bucket,
                geojson?.path,
                ""
            );
        },
        enabled: isAllowed
    });

    if (isLoading) <LoadingState/>;

    return (
        <>
            <MH2024Crumbs fst={fst}/>
            <DistrictTitleCard summary={fsd?.summary}/>
            <div className={"w-full sm:max-w-7xl mx-auto"}>
                <div className={"pt-4 px-4 sm:px-0"}>
                    <TabView
                        tabs={tabOptions}
                        onChange={(name) => {
                            setActiveTab(name)
                        }}
                    />
                </div>
                {!_.isEmpty(isAllowed ? r12 : r1) ?
                    <>
                        {activeTab === "Field Summary" && <FieldSamplesData data={field_data} samples={samples}/> }
                        {activeTab === "Sample Distribution" && (
                            <div className={"py-4 px-4 sm:px-0"}>
                                <LeafletACMap
                                    config={mh_map_config}
                                    geojson={data}
                                    data={location_records}
                                />
                            </div>
                        )}
                    </> : ["Field Summary", "Sample Distribution"].includes(activeTab) ? <DataNotAvailable />: null
                }
                {activeTab === "Historical" && <HistoricalData data={r2}/>}
                {activeTab === "Demographics" && <MHDemographics data={{}}/>}
            </div>
            <ManageFSDPermissions/>
            <AddUsersToFSD/>
        </>
    );
})

export const DataNotAvailable = () => {
    return (
        <Stack direction={"row"} gap={2} className={"h-[75vh] flex items-center justify-center p-4"}>
            <i className="fas fa-exclamation-circle text-4xl text-[#ed5e49]"/>
            <Typography variant={"h5"} color={"error"}>Data not available</Typography>
        </Stack>
    )
}
