import {action, makeAutoObservable, observable} from "mobx";
import {voterType} from "../features/beneficiary-management/beneficiary-utils";

const voterProfile = {
    additional: "",
    address: "",
    age: 0,
    gender: "",
    house: "",
    name_eng: "",
    name_reg: "",
    phone: "",
    relation_name_eng: "",
    relation_name_reg: "",
    relationship_eng: "",
    relationship_reg: "",
    serialno: 0,
    voter_roll_page: 0,
    voterid: "",
    aadharid: "",
    dob: "",
    profile_photo: ""
}

const voter: voterType = {
    ac: "",
    id: "",
    part: "",
    phone: "",
    famcode: "",
    aadhar: "",
    age: 0,
    dob: "",
    profile: voterProfile
}

type filterTypes = {
    dept: string[],
    piv_level_2: string[],
    current_status: string[],
    priority: string[],
    reported_by: string[]
}

const defaultFilters = {
    dept: [],
    piv_level_2: [],
    current_status: [],
    priority: [],
    reported_by: []
}


export class BeneficiaryStore {

    rootStore: any;
    beneficiaries;
    activeBeneficiary = {
        id: 0,
        key: "",
        summary: {
            profile: {
                name: "",
                phone: "",
                whatsapp: "",
                address: "",
                voter_id: "",
                aadhar_id: "",
                family_members: "",
                occupation: "",
                additional: "",
            }
        },
        r1: {
            requests: [
                {
                    type: "",
                    additional: "",
                    status: "",
                    dates: "",
                    documents: [],
                    richmedia: [],
                }
            ],
            family: [
                {
                    id: "",
                    name: "",
                    additional: "" as string,
                    phone: "",
                    profile_photo: [],
                    role: "",
                }
            ]
        }
    }
    show_benefic: boolean = false;
    add_beneficiary_modal: boolean = false;
    FilterStatus: boolean = false;
    add_request_modal: boolean = false;
    add_familymember_modal: boolean = false;
    beneficiary_edit_mode: boolean = false;
    beneficiary_active_scheme: any = {};
    edit_benficiary_scheme: boolean = false;
    beneficiary_delete: boolean = false;
    scheme_delete: boolean = false;
    status_delete: boolean = false;
    priority_delete: boolean = false;
    showVoterlist: boolean = false;
    selectedVoterData: any = null;
    SearchBooth: any = [];
    activeScheme: any = {};
    activeStatus: any = [];
    schemesList: any = [];
    statusList: any = [];
    addscheme: boolean = false;
    addstatus: boolean = false;
    activePriority: any = {};
    prioritiesList: any = [];
    addpriorities: boolean = false;
    addFamilyFromVoterListModal: boolean = false;
    activeRequest = {
        id: "",
        type: "",
        additional: "",
        status: "",
        department: "",
        documents: [],
        richmedia: [],
        assigned_to:{}
    }

    activeFamilyMember: voterType = voter;
    add_official_modal: boolean = false;
    users: any = [];

    BeneficiariesFilter:boolean = false;

    filters:filterTypes = defaultFilters;

    constructor(rootStore: any) {
        makeAutoObservable(this)
        this.rootStore = rootStore;
        this.beneficiaries = [];
    }

    @action
    resetFilters() {
        this.filters = defaultFilters
    }

    @action
    setActiveFamilyMember(val) {
        this.activeFamilyMember = val
    }

    @action
    setActiveRequest(val) {
        this.activeRequest = val
    }

    @action
    set_beneficiries(beneficiaries) {
        this.beneficiaries = beneficiaries
    }

    @action
    set_active_beneficiary = benefic => {
        this.activeBeneficiary = benefic
    }

    @action
    resetActiveFamily() {
        this.activeFamilyMember = voter;
    }



    @action
    resetActiveRequest() {
        this.activeRequest = {
            id: "",
            type: "",
            additional: "",
            status: "",
            department: "",
            documents: [],
            richmedia: [],
            assigned_to: {}
        }
    }

    // code for fetching users data
    @action
    set_users(users) {
        this.users = users;
    }

    @action
     createBeneficiary = async (payload) => {
        return await this.rootStore.api.post("fs/fsdnew", payload);
    }

    @action
    updateBeneficiary = (benefic) => {
        this.rootStore.api.post("project/editbeneficiaries", benefic)
    }

    @action
    deleteBeneficiary = (benefic) => {
        return this.rootStore.api.post("project/removebeneficiaries", benefic)
    }

    @action
    update_show_benefic(status) {
        this.show_benefic = status;
    }

    @action
    update_add_beneficiary_modal(status) {
        this.add_beneficiary_modal = status
    }

    @action
    setFilterStatus(status) {
        this.FilterStatus = status
    }
    
    setBeneficiarieFilter(status){
        this.BeneficiariesFilter = status;
    }

    @action
    update_add_request_modal(status) {
        this.add_request_modal = status
    }

    @action
    update_add_familymember_modal(status) {
        this.add_familymember_modal = status
    }

    @action
    update_edit_beneficiary_mode(status) {
        this.beneficiary_edit_mode = status
    }

    update_active_beneficiary_scheme(status) {
        this.beneficiary_active_scheme = status
    }

    @action
        update_edit_beneficiary_scheme (status) {
        this.edit_benficiary_scheme = status
     }

    @action
    setShowVoterlist(status) {
        this.showVoterlist = status
    }

    @action
    setSelectedBooths(val) {
        this.SearchBooth = val
    }

    @action
    setActiveScheme(val) {
        this.activeScheme = val
    }
    @action
    setActiveStatus(val) {
        this.activeStatus = val
    }

    @action
    setSchemesList(schemes) {
        this.schemesList = schemes
    }

    @action
    setStatusList(status) {
        this.statusList = status
    }

    @action
    setAddSchemes = (val) => {
        this.addscheme = val
    }

    @action
    setAddStatus = (val) => {
        this.addstatus = val
    }

    @action
    setSelectedVoterData(data) {
        this.selectedVoterData = data
    }

    @action
    update_beneficiary_delete(status) {
        this.beneficiary_delete = status
    }

    @action
    update_scheme_delete(status) {
        this.scheme_delete = status
    }

    @action
    update_status_delete(status) {
        this.status_delete = status
    }

    @action
    update_addFamilyFromVoterListModal(status) {
        this.addFamilyFromVoterListModal = status
    }

    @action
    update_add_official_modal(status) {
        this.add_official_modal = status
    }

    @action
    updateFilters(filters:filterTypes){
       this.filters = filters;       
    }

    @action
    setPrioritiesList(schemes) {
        this.prioritiesList = schemes
    }

    @action
    setAddPriorities = (val) => {
        this.addpriorities = val
    }

    @action
    update_priority_delete(status) {
        this.priority_delete = status
    }

    @action
    setActivePriority(val) {
        this.activePriority = val
    }


    @action
    async fetch_beneficiarytypes( projectid:string) {
        const payload = {
            'projectid' : parseInt(projectid)
        };
        return await this.rootStore.api.post("beneficiary_v2/get_all_beneficiary_types", payload);
    }

    @action
    async fetch_beneficiarystatus(projectid:string) {
        const payload = {
            'projectid': parseInt(projectid)
        };
        return await this.rootStore.api.post("beneficiary_v2/get_all_beneficiary_status", payload);
    }
    
    async add_beneficiary_scheme(payload){
        return await this.rootStore.api.post("beneficiary_v2/create_beneficiary_type", payload)
    }

    async add_beneficiary_status(payload){
        return await this.rootStore.api.post("beneficiary_v2/create_beneficiary_status", payload)
    }

    @action
    async remove_beneficiary_scheme(payload) {
        return await this.rootStore.api.post("beneficiary_v2/delete_beneficiary_type", payload)
    }

    @action
    async remove_beneficiary_status(payload) {
        return await this.rootStore.api.post("beneficiary_v2/delete_beneficiary_status", payload)
    }

    @action
    async edit_beneficiary_scheme(payload) {
        return await this.rootStore.api.post("beneficiary_v2/update_beneficiary_type", payload)
    }
    @action
    async edit_beneficiary_status(payload) {
        return await this.rootStore.api.post("beneficiary_v2/update_beneficiary_status", payload)
    }

    //priorities//
    async fetch_beneficiaryprioritys( projectid:string ) {
        const payload = {
            'projectid' : parseInt(projectid)
        };
        return await this.rootStore.api.post("beneficiary_v2/get_all_beneficiary_priorities", payload);
    }

    async add_beneficiary_priority(payload){
        return await this.rootStore.api.post("beneficiary_v2/create_beneficiary_priority", payload)
    }

    @action
    async remove_beneficiary_priority(payload) {
        return await this.rootStore.api.post("beneficiary_v2/delete_beneficiary_priority", payload)
    }

    @action
    async edit_beneficiary_priority(payload) {
        return await this.rootStore.api.post("beneficiary_v2/update_beneficiary_priority", payload)
    }

}

