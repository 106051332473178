import {observer} from "mobx-react-lite";
import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import {useStore} from "../../helpers/helpers";
import {toJS} from "mobx";
import {get_color, reduce_for_visual, safe_get} from "../report-utils";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4plugins_sliceGrouper from "@amcharts/amcharts4/plugins/sliceGrouper";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import _ from "lodash";
import {ClearFiltersForVisual} from "./clearfilters";

am4core.useTheme(am4themes_animated);

export const Donut3D = observer((props: any) => {

    const rootStore = useStore();
    const {reportStore} = rootStore;
    const {currentReportData} = reportStore;

    const {section, depth} = props;
    const {id, type, settings, args} = section;
    const {title} = settings;

    const [dt, setdt] = useState([]);
    const [transformed, setTransformed] = useState(false);
    const [loading, setLoading] = useState(false);

    const donut_chart = useRef(null);

    const {reducers, ds, headers, display} = args;


    const _args = toJS(args);
    const rootStyles = safe_get(_args, "display.rootStyles", {"width": "100%", "height": "500px"});
    const rootClasses = safe_get(_args, "display.rootStyles", '');

    const showTitle = safe_get(_args, "display.showtitle", true);
    const titleLocation = safe_get(_args, "display.titlelocation", "donut-center");

    const labelField = safe_get(_args, "headers.label", '');
    const valueField = safe_get(_args, "headers.value", '');
    const chart_div = `chart_${id}_container`;

    const defaultFilter = {"enabled": false, "emit": []};
    const filterSettings = safe_get(toJS(args), "filter", defaultFilter);
    const {enabled, emit} = filterSettings;

    useEffect(() => {
        refresh_data()
    }, [reportStore.currentReportFilters]);

    const refresh_data = () => {
        setLoading(true);
        let current = reduce_for_visual(toJS(currentReportData[ds]), reducers, reportStore.getSubscribedFilters(emit));
        setdt(current);
        setTransformed(true);
        setLoading(false);
    };

    useLayoutEffect(() => {
        let chart_data = dt.map((row) => {
            return {
                'label': safe_get(row, labelField, ''),
                'value': safe_get(row, valueField, 0)
            };
        });
        chart_data = _.orderBy(chart_data, ['value'], ['desc']);

        const chartColors = safe_get(_args, 'graphSettings.colors', {});
        const colorsList = chart_data.map((x, ind) => {
            const key = x.label;
            if(Object.keys(chartColors).includes(key)){
                const specifiedColor = chartColors[key];
                return am4core.color(specifiedColor);
            }else{
                return get_color(ind);
            }
            // return get_color(ind);
        })

        let x = am4core.create(chart_div, am4charts.PieChart);
        x.data = chart_data;

        const pieSeries = x.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = 'value';
        pieSeries.dataFields.category = 'label';
        pieSeries.colors.list = colorsList;
        pieSeries.slices.template.propertyFields.fill = 'color';
        pieSeries.slices.template.stroke = am4core.color('#fff');
        pieSeries.slices.template.strokeWidth = 1;
        pieSeries.slices.template.strokeOpacity = 0.05;

        pieSeries.innerRadius = am4core.percent(50);
        let rgm = new am4core.RadialGradientModifier();
        rgm.brightnesses.push(-0.85, -0.85, -0.15, 0, -0.15);
        pieSeries.slices.template.fillModifier = rgm;
        pieSeries.slices.template.strokeModifier = rgm;
        pieSeries.slices.template.strokeOpacity = 0.3;
        pieSeries.slices.template.strokeWidth = 0;

        x.hiddenState.properties.opacity = 0; // this creates initial fade-in

        pieSeries.hiddenState.properties.opacity = 1;
        pieSeries.hiddenState.properties.endAngle = -90;
        pieSeries.hiddenState.properties.startAngle = -90;

        const defaultGraphSettings = {
            "labels": false,
            "legend": true,
            "mergeOthers": {
                "enabled": true,
                "threshold": 2,
                "groupName": "Others",
                "clickBehavior": "break"
            },
            "tooltip": false,
            "title": {
                "location": "donut-center",
                "text": "Title of the chart",
                "fontSize": 30,
            }
        };

        const graphSettings = safe_get(args, "graphSettings", defaultGraphSettings);

        if (safe_get (graphSettings, "labels", false)) {
            //Disable Labels
            pieSeries.labels.template.disabled = true;
            pieSeries.ticks.template.disabled = true;
        }

        if (safe_get(graphSettings, "legend", true)) {
            //Legends
            x.legend = new am4charts.Legend();
            //Disabled
            pieSeries.tooltip.disabled = true;
        }

        if (safe_get(graphSettings, "title.location", "donut-center") === 'donut-center') {
            let label = pieSeries.createChild(am4core.Label);
            label.text = safe_get(graphSettings, "title.text", "");
            label.horizontalCenter = "middle";
            label.verticalCenter = "middle";
            label.fontSize = safe_get(graphSettings, "title.fontSize", 30);
        }

        if (safe_get(graphSettings, "mergeOthers.enabled", true)) {
            //Group Others
            let grouper = pieSeries.plugins.push(new am4plugins_sliceGrouper.SliceGrouper());
            grouper.threshold = safe_get(graphSettings, "mergeOthers.threshold", 2);
            grouper.groupName = safe_get(graphSettings, "mergeOthers.groupName", "");
            grouper.clickBehavior = safe_get(graphSettings, "mergeOthers.clickBehavior", "");
        }

        // @ts-ignore
        donut_chart.current = x;

    }, [dt]);

    return (
        <>
            <div
                style={rootStyles}
                className={`flex flex-col ${rootClasses}`}
            >
                <div key={`chart_${id}_title`} className='font-bold'>{title}</div>
                <div key={`${chart_div}`} id={`${chart_div}`} className='w-full h-full'
                     style={{marginTop: '-15px',}}/>
                <ClearFiltersForVisual />
            </div>
        </>
    );

});