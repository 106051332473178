import {useParams} from "react-router-dom";
import {BreadCrumbs} from "../../components";
import React from "react";

export const PWHomeCrumbs = () => {
    const {projectid} = useParams();
    const crumbs = [
        {name: "DashBoard", href: `/project/${projectid}`, current: false},
        {name: 'Project Works', href: '/project/' + projectid + '/projectworks_v2', current: true},
    ];
    return (
        <BreadCrumbs crumbs={crumbs}/>
    );
};

export const PWViewCrumbs = ({ name }) => {
    const { projectid } = useParams();
    const crumbs = [
        { name: "DashBoard", href: `/project/${projectid}`, current: false },
        { name: 'Project Works', href: `/project/${projectid}/projectworks_v2`, current: false },
        { name, href: '#', current: true },
    ];
    return <BreadCrumbs crumbs={crumbs} />;
};