import React from "react";
import {FlexiStoreData_Abridged} from "../../../stores/fs/fst";

export const CMXSearchCard = (props) => {

    const fsd:FlexiStoreData_Abridged = props.data

    // @ts-ignore
    const {name, expected_date} = fsd.summary;

    return (
        <div key={`fsd_${fsd.id}`}
             className="flex flex-row rounded-lg shadow-lg overflow-hidden cursor-pointer bg-white p-3"
             style={{borderLeft: `4px solid`, borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
        >
            <div className="flex-1 flex flex-col ml-2 justify-between">
                <div className="font-bold text-gray-500">{name}</div>
                <div className="font-bold text-gray-500">{expected_date}</div>
            </div>
        </div>
    )
};