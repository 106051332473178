import {observer} from "mobx-react-lite";
import React, {useEffect, useState} from "react";
import {useStore} from "../../../helpers/helpers";
import {safe_get} from "../../../report-visuals/report-utils";
import {library} from '@fortawesome/fontawesome-svg-core'
import {faCheckSquare} from '@fortawesome/free-solid-svg-icons'
import {RNCheckBoxTree} from "../../../components";
import {get_booth_node_by_boothnumber} from "../../../custom-hooks/useBoothNumber";
import {Typography} from "@mui/material";

library.add(faCheckSquare)

export const C3BoothFilter = observer((props: any) => {

    const {onBoothSelection, value} = props;
    console.log({value})

    const {projectStore, c3Store} = useStore();
    const [hierarchy, setHierarchy] = useState([]);
    const [checked, setChecked] = useState(safe_get(c3Store, "selected_booths", []));

    useEffect(() => {
        setHierarchy(projectStore.getProjectProperty("hierarchy", []));
    }, []);

    return (
        <div className={"flex flex-col gap-y-2"}>
            <Typography color={"primary"}>Select a specific village or booth to view relevant data.</Typography>
            <RNCheckBoxTree
                data={hierarchy}
                on_node_selection={(nodes) => {
                   const final = nodes.map(node => get_booth_node_by_boothnumber(node))
                    console.log(final)
                    setChecked(final)
                    onBoothSelection(final)
                }}
                checked_nodes={Array.isArray(value) ? value.map(val => val.id) : value}
            />
        </div>
    )
})