import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {safe_get} from "../../report-visuals/report-utils";
import {Controller} from "react-hook-form";
import {useStore} from "../../helpers/helpers";
import {useParams} from "react-router";
import {createTag, VortexSpinner} from "../digital-repository";
import {toast} from "react-toastify";
import CreatableSelect from "react-select/creatable";
import {createOption} from "./il-filters";


export const ILRoles = observer(({control, options, onUpdateRole, ...props}: any) => {

    const rootStore = useStore();
    const {projectid} = useParams();
    const [properties, setProperties] = useState({});
    const [opts, setOpts] = useState(options);
    const [loading, setLoading] = useState(false)
    const [main, setMain] = useState([])

    let activeProject ;

    useEffect(() => {
        activeProject = JSON.parse(localStorage.getItem("__active_project"));
        const x = safe_get(activeProject, "properties", {});
        setMain(safe_get(x, "influential_leaders.value.party_roles", []))
        setProperties(x);
    }, []);

    const updatePropjectProperty = async (data) =>  {
        setOpts([...opts, data])
        const cl = [...main]
        const updateed = [...cl, data]
        setMain(updateed)

        const payload = {
            id: parseInt(projectid),
            properties: {
                ...properties,
                influential_leaders: {
                    type: "json",
                    value: {
                        ...properties["influential_leaders"].value,
                        party_roles: updateed
                    }
                }
            }
        }

        console.log(payload)
        setLoading(true)
        const res = await rootStore.projectStore.editProjectProperties(payload);
        if(!res.ok) {
            toast.error("Something went wrong")
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    return (
        <>
            <Controller
                name="volunteer.roles"
                control={control}
                render={({field: {onChange, onBlur, value} }) => (
                    <CreatableSelect
                        {...props}
                        isMulti
                        isClearable
                        isSearchable
                        options={opts.map(x=>createOption(x))}
                        onChange={(newValue) => {
                            onChange(newValue)
                        }}
                        onCreateOption={(newValue) => {
                            updatePropjectProperty(newValue)
                            onChange(newValue)
                        }}
                        onBlur={onBlur}
                        value={opts.find((option) => option?.value?.replace(/\W/g, '') === value)}
                        placeholder="Select Roles"
                    />
                )}
            />
            <VortexSpinner visible={loading}/>
        </>
    )
})