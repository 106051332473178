import {observer} from "mobx-react-lite";
import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import {useStore} from "../../helpers/helpers";
import {toJS} from "mobx";
import {reduce_for_visual, safe_get} from "../report-utils";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import _ from "lodash";

am4core.useTheme(am4themes_animated);

export const BarChart = observer((props: any) => {

    const rootStore = useStore();
    const {reportStore} = rootStore;
    const {currentReportData} = reportStore;

    const {section, depth, visualType} = props;
    const {id, type, settings, args} = section;
    const {title} = settings;

    const [dt, setdt] = useState([]);
    const [transformed, setTransformed] = useState(false);
    const [loading, setLoading] = useState(false);

    const bar_chart = useRef(null);

    const {reducers, ds, headers, display} = args;

    const _args = toJS(args);
    const rootStyles = safe_get(_args, "display.rootStyles", {"width": "100%", "height": "auto"});
    const rootClasses = safe_get(_args, "display.rootStyles", '');

    const showTitle = safe_get(_args, "display.showtitle", true);
    const titleLocation = safe_get(_args, "display.titlelocation", "barchart-center");

    const labelField = safe_get(_args, "headers.label", '');
    const valueField = safe_get(_args, "headers.value", '');
    const chart_div = `chart_${id}_container`;

    const defaultFilter = {"enabled": false, "emit": []};
    const filterSettings = safe_get(toJS(args), "filter", defaultFilter);
    const {enabled, emit} = filterSettings;

    useEffect(() => {
        refresh_data()
    }, [reportStore.currentReportFilters]);

    const refresh_data = () => {
        setLoading(true);
        let current = reduce_for_visual(toJS(currentReportData[ds]), reducers, reportStore.getSubscribedFilters(emit))
        setdt(current);
        setTransformed(true);
        setLoading(false);
    };

    useLayoutEffect(() => {
        let chart_data = dt.map((row) => {
            return {
                'label': safe_get(row, labelField, ''),
                'value': safe_get(row, valueField, 0)
            };
        });
        chart_data = _.orderBy(chart_data, ['value'], ['asc']);

        let chart = am4core.create(chart_div, am4charts.XYChart);
        chart.hiddenState.properties.opacity = 0;

        //set outline color for series
        chart.plotContainer.stroke =  am4core.color('#fffff');
        chart.plotContainer.strokeWidth = 0;

        //set chart data
        chart.data = chart_data;

        const defaultGraphSettings = {
            "labels": {
                "xaxis": false,
                "yaxis": false
            },
            "legend": true,
            "tooltip": false,
            "title": {
                "location": "donut-center",
                "text": "Title of the chart",
                "fontSize": 30,
            },
            "fill": "#8c48f5",
            "columnWidth": "50",
            "fontSize": 16,
            "colors": {
                "SSLC": "#8c48f5",
                "PUC": "#8c48f5",
                "High School":"#8c48f5"
            }
        };

        const graphSettings = safe_get(args, "graphSettings", defaultGraphSettings);


        if(visualType === "vertical-bar-chart") {
            // Create axes
            let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());

            categoryAxis.dataFields.category = "label";
            categoryAxis.renderer.grid.template.disabled = false;
            categoryAxis.renderer.grid.template.location = 0;
            categoryAxis.renderer.labels.template.rotation = 290;
            categoryAxis.renderer.labels.template.dx = -50;
            categoryAxis.renderer.labels.template.dy = -10;
            categoryAxis.renderer.minGridDistance = 10;

            let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

            // Create series
            let series = chart.series.push(new am4charts.ColumnSeries());
            series.dataFields.valueY = "value";
            series.dataFields.categoryX = "label";
            series.name = "Voters %";
            series.sequencedInterpolation = true;
            // series.columns.template.fillOpacity = .8;
            // series.columns.template.column.fillOpacity = 0.8;
            series.columns.template.width = safe_get (graphSettings, "columnWidth", '');
            series.columns.template.fill = safe_get (graphSettings, "fill", '');
            series.columns.template.height = am4core.percent(100)

            if(safe_get (graphSettings, "colors", '')) {
                // const getColors  = colors.
            }

            if (safe_get (graphSettings, "labels.xaxis", false)) {
                //Disable Labels
                categoryAxis.renderer.labels.template.disabled = true;
            }
            if (safe_get (graphSettings, "labels.yaxis", false)) {
                //Disable Labels
                valueAxis.renderer.labels.template.disabled = true;
            }
            if (safe_get (graphSettings, "tooltip", true)){
                //enable tooltip
                series.tooltip.disabled = false;
                series.tooltip.pointerOrientation = "vertical";
                series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
            }

            let hoverState = series.columns.template.column.states.create("hover");
            hoverState.properties.cornerRadiusTopLeft = 0;
            hoverState.properties.cornerRadiusTopRight = 0;
            hoverState.properties.fillOpacity = 1;

        }

        if(visualType === "horizontal-bar-chart"){

            let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "label";

            categoryAxis.fontSize = safe_get (graphSettings, "fontSize", '');
            categoryAxis.renderer.grid.template.location = 0;
            categoryAxis.renderer.grid.template.disabled = true;
            categoryAxis.renderer.minGridDistance = 15;
            categoryAxis.renderer.minHeight = 5;

            let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
            valueAxis.min = 0;
            valueAxis.fontSize = safe_get (graphSettings, "fontSize", '');

            let series = chart.series.push(new am4charts.ColumnSeries());
            series.dataFields.valueX = "value";
            series.dataFields.categoryY = "label";
            series.columns.template.tooltipText = "{categoryY}: [bold]{valueX}[/]";
            series.columns.template.fill = safe_get (graphSettings, "fill", '');
            series.columns.template.height = safe_get (graphSettings, "columnWidth", '');


            let valueLabel = series.bullets.push(new am4charts.LabelBullet());
            valueLabel.label.text = "{value}";
            valueLabel.label.fontSize = safe_get (graphSettings, "fontSize", '');
            valueLabel.label.horizontalCenter = "left";
            valueLabel.label.dx = 10;
            // valueLabel.label.text.width = am4core.percent(50);
            //
            let hoverState = series.columns.template.column.states.create("hover");
            hoverState.properties.cornerRadiusTopLeft = 0;
            hoverState.properties.cornerRadiusTopRight = 0;
            hoverState.properties.fillOpacity = 1;

            if (safe_get (graphSettings, "labels.xaxis", false)) {
                //Disable Labels
                categoryAxis.renderer.labels.template.disabled = true;
            }
            if (safe_get (graphSettings, "labels.yaxis", false)) {
                //Disable Labels
                valueAxis.renderer.labels.template.disabled = true;
            }

            chart.maskBullets = false;
        }

        bar_chart.current = chart;

    }, [dt]);

    return (
        <>
            <div
                style={rootStyles}
                className={`flex flex-col ${rootClasses}`}
            >
                <div key={`chart_${id}_title`}>{title}</div>
                <div key={`${chart_div}`} id={`${chart_div}`} className='w-full h-full'/>
            </div>
        </>
    );

});