import {FormHelperText, TextField} from "@mui/material";
import {Controller, useFormContext} from "react-hook-form";
import React from "react";


export const RNInput = ({ name, label, ...props }) => {

    const { control, formState:{errors} } = useFormContext();

    return(
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <div>
                    <TextField
                        sx={{marginTop:0, marginBottom: 0}}
                        {...props}
                        {...field}
                        label= {label}
                        variant="filled"
                        fullWidth
                        margin="normal"
                        error={!!errors[`${name}`]}
                    />
                    {error && (
                        <FormHelperText error>{error?.message}</FormHelperText>
                    )}
                </div>
            )}
        />
    )
}