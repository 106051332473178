import React, {ReactNode} from "react";
import { SubHeading } from "../../helpers";

type AddNewProps = {
    heading: string,
    title: string,
    onAddClick: (args) => void,
    children: ReactNode
}

export const AddNew = (props: AddNewProps) => {

    const {heading, title, onAddClick} = props;

    return (
        <div className="flex flex-row justify-between items-center my-2">
            <div className="flex flex-row items-center px-2 ">
                {props.children}
                <SubHeading title={heading}/>
            </div>

            <button className="cursor-pointer" onClick={onAddClick}>
                <i className="fas fa-plus-circle text-purple-600 px-2 text-lg"/>
                <span>{title}</span>
            </button>
        </div>
    )
}
