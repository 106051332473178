import {observer} from "mobx-react-lite";
import React from 'react';
import {useIsMobile} from "../../../custom-hooks";


type fundsProps = {
    fund: {
        id: string
        title: string,
        description: string,
        amount: string,
        date: string
    },
    onEdit: Function,
    onDelete: Function
}


export const fundsCardV2 = observer ((props: fundsProps) => {


    const {fund, onEdit, onDelete} = props;
    const {title, description, amount, date, id} = fund;
    const isMobile = useIsMobile();
    return (
        <div key={id} className="rounded-lg shadow-md bg-yellow-100 p-2 sm:p-4 mx-2 group">
            <div className="flex flex-row items-center justify-between">
                <div className="font-bold text-lg">{title}</div>
                <div className="flex flex-row items-center justify-between">
                    <i className={`fas fa-pencil text-gray-600 pr-2 cursor-pointer transition-opacity duration-800 ${!isMobile ? "invisible group-hover:visible" : ""}`}
                       onClick={e => onEdit(fund)}/>
                    <i className={`fas fa-trash text-red-600 pl-2 cursor-pointer transition-opacity duration-800 ${!isMobile ? "invisible group-hover:visible" : ""}`}
                       onClick={e => onDelete(id)}/>
                </div>
            </div>
            <div>{description}</div>
            <div className="flex flex-row justify-between items-center">
                <p className="text-green-600">₹ {amount}</p>
                <div className="flex flex-row items-center justify-between">
                    <i className="fas fa-calendar-alt text-gray-600 px-2"/>
                    <div>{date}</div>
                </div>
            </div>
        </div>
    )
});