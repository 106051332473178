import {observer} from "mobx-react-lite";
import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CheckboxTree from "react-checkbox-tree";
import {useStore} from "../../../helpers/helpers";
import {safe_get} from "../../../report-visuals/report-utils";


export const C3ConcernedPerson = observer((props:any) => {

    const { projectStore, c3Store } = useStore();
    const [beneficiary, setBeneficiary] = useState([]);
    const [checked, setChecked]= useState(safe_get(c3Store, "selected_office_persons" ,[]));
    const [expanded, setExpanded] = useState([]);

    useEffect(() => {
        const beneficiary_project_property = projectStore.getProjectProperty("beneficiary", {})
        const persons_options_for_dropdown = safe_get(beneficiary_project_property, "owners", []);
        const transformed_values = persons_options_for_dropdown.map(x => {
            return {
                ...x,
                label:`${x.name} - ${x.designation}`,
                value:x.name,
                name:x.name,
                designation: x.designation,
            }
        })
        setBeneficiary(transformed_values);
    }, []);

    return(
        <>
            <CheckboxTree
                nodes={beneficiary}
                checked={checked}
                expanded={expanded}
                checkModel={"leaf"}
                onCheck={async(check, targetnode) => {
                    setChecked(check)
                    await c3Store.update_selected_office_persons(check);
                }}
                onExpand={(expand, targetnode) => {
                    setExpanded(expand);
                }}

                // iconsClass="fa6"
                icons={{
                    check: (
                        <FontAwesomeIcon
                            className="rct-icon rct-icon-check"
                            icon="check-square"
                        />
                    ),
                    uncheck: (
                        <FontAwesomeIcon
                            className="rct-icon rct-icon-uncheck"
                            icon={["fas", "square"]}
                        />
                    ),
                    halfCheck: (
                        <FontAwesomeIcon
                            className="rct-icon rct-icon-half-check"
                            icon="check-square"
                        />
                    ),
                    expandClose: (
                        <FontAwesomeIcon
                            className="rct-icon rct-icon-expand-close"
                            icon="chevron-right"
                        />
                    ),
                    expandOpen: (
                        <FontAwesomeIcon
                            className="rct-icon rct-icon-expand-open"
                            icon="chevron-down"
                        />
                    ),
                    expandAll: (
                        <FontAwesomeIcon
                            className="rct-icon rct-icon-expand-all"
                            icon="plus-square"
                        />
                    ),
                    collapseAll: (
                        <FontAwesomeIcon
                            className="rct-icon rct-icon-collapse-all"
                            icon="minus-square"
                        />
                    ),
                    parentClose: (
                        <FontAwesomeIcon
                            className="rct-icon rct-icon-parent-close"
                            icon="folder"
                        />
                    ),
                    parentOpen: (
                        <FontAwesomeIcon
                            className="rct-icon rct-icon-parent-open"
                            icon="folder-open"
                        />
                    ),
                    leaf: (
                        <FontAwesomeIcon
                            className="rct-icon rct-icon-leaf-close"
                            icon="file"
                        />
                    ),
                }}
            />
        </>
    )
})