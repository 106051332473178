import React from "react";
import {ACBankDAO} from "./acbank_dao";
import {useStore} from "../../../helpers";
import {RootStore} from "../../../stores/root";
import {ACBankCardsNav} from "./ac_bank_home";
import {observer} from "mobx-react-lite";
import "./acbank.css";
import { useTranslation } from "react-i18next";
import {FSDPermissionButton, ManageFSDPermissions} from "../../fsd-permissions";
import {ACBankCrumbs} from "./ac-bank-crumbs";
import {safe_get} from "../../../report-visuals/report-utils";

export const ACBankView = observer((props) => {

    const {t} = useTranslation("ac_bank");
    const rootStore: RootStore = useStore()
    const dao = new ACBankDAO(rootStore)
    const acno = dao.get_summary_field('acno', 0);
    const fst = safe_get(JSON.parse(sessionStorage.getItem("fst")), "summary.name", "AC Bank");
    
    return (
        <>
            <ACBankCrumbs fst={fst}/>
            <div>
                <div className='flex flex-row items-center justify-between p-4 bg-white'>
                    <div className='flex flex-row p-4 items-center justify-between'>
                        <div className='flex flex-row flex-wrap justify-center text-center rounded-full text-lg font-bold items-center bg-blue-100'
                             style={{
                                 width: 58,
                                 height: 58,
                                 lineHeight: '18px',
                             }}>{t("ac")} {String("00000" + acno).slice(-3)}</div>
                        <div className='flex flex-col ml-4'>
                            <div className='text-lg font-bold uppercase'
                                 style={{}}>{dao.get_summary_field('acname', 'Name Missing')}</div>
                            <div className=''
                                 style={{}}>{dao.get_summary_field('district', '')}, {dao.get_summary_field('region', '')}</div>
                        </div>
                    </div>
                    {rootStore.userStore.isRoot() ? <FSDPermissionButton /> : null}
                </div>
                <ACBankCardsNav/>
                <ManageFSDPermissions />
            </div>
        </>
    )
})