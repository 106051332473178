import React, {useMemo, useState} from "react";
import {observer} from "mobx-react-lite";
import {useStore} from "../../helpers/helpers";
import {toJS} from "mobx";
import _ from "lodash";
import {safe_get} from "../../report-visuals/report-utils";
import { useTranslation } from "react-i18next";
import {RenderFilters, Table} from "../survey-management/survey-summary";

export const VoterRegistrationTable = observer((props: any) => {

    const {surveyResponseStore} = useStore();
    const {allSurveyResponses, activeSurveyResponseId, activeSurveyResponse} = surveyResponseStore;
    const [selectedSurveyId, setSelectedSurveyId] = useState(activeSurveyResponseId);
    const {t} = useTranslation("new_voter_registration")

    if(_.isEmpty(allSurveyResponses)) return <div>{t("no_data_found")}</div>;

    const data = useMemo(() => toJS(allSurveyResponses), [allSurveyResponses]);

    const Filter = props => {
        const {display_key, data, filter_key} = props;
        const cell_value = data.cell.value;
        const filter_value = data.row.original[filter_key]
        return(
            <div className="py-4 px-2" onClick={e => {
                surveyResponseStore.update_new_filters(`${filter_key}`,filter_value,`${display_key}: ${cell_value}`);
            }}>
                {cell_value}
            </div>
        )
    }

    const columns = useMemo(() => [
        {
            Header: t("survey_id"),
            accessor: 'id',
            Cell: props => {
                const value = props.cell.value;
                return(
                    <div className="py-4 px-2" onClick={e => {
                        surveyResponseStore.setActiveSurveyResponseId(value);
                        setSelectedSurveyId(value);
                    }}>
                        {value}
                    </div>
                )
            }
        },
        {
            Header: t("surveyor_name"),
            accessor: 'surveyor_name',
            Cell: props => {
                return <Filter data={props} display_key="Interviews By" filter_key="surveyor_id" />
            }
        },
        {
            Header: t("date"),
            accessor: 'dt_month_date',
            Cell: props => {
                return <Filter data={props} display_key="Date" filter_key="dt_month_date" />
            }
        },
        {
            Header: t("ward/hobli"),
            accessor: 'piv_level1',
            Cell: props => {
                return <Filter data={props} display_key="Ward/Hobli" filter_key="piv_level1" />
            }
        },
        {
            Header: t("booth"),
            accessor: 'piv_level2',
            Cell: props => {
                return <Filter data={props} display_key="Booth" filter_key="piv_level2" />
            }
        },
        {
            Header: t("status"),
            accessor: (row) => {
                const updates = safe_get(row["more"], "voter_registration_tracker.updates", []);
                const last_index = updates.length - 1 ;
                let default_status = '';
                if(last_index === -1) default_status = "Not Applied" ;
                return safe_get(updates[last_index], "status", default_status)
            },
            Cell: props => {
                const value = props.cell.value;
                return(
                   <div className="m-2" onClick={() => {
                       surveyResponseStore.update_new_filters(`more.voter_registration_tracker.status`,`${value}`,`Status: ${value}`);
                   }}>
                        <span className="rounded-lg bg-red-200 p-2 font-bold ">{value}</span>
                    </div>
                )
            }
        }
    ], [allSurveyResponses, activeSurveyResponseId]);

    const row_click_handler = (x) => {}

    return (
        <div className="flex flex-col my-4 all_surveys">
            <div className="font-bold text-indigo-600 mb-4">{t("list_of_surveys")}</div>
            <RenderFilters />
            <Table data={data} columns={columns} pagesize={20} onRowClick={row_click_handler} hideFooter={true} highlightRow={true} activeSurveyResponseId={selectedSurveyId}/>
        </div>
    )
})