import React, {useEffect, useState} from 'react';
import {useStore} from "../../../helpers/helpers";
import {useParams} from "react-router-dom";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {DeleteModal, LoadingState, SideOverlay} from "../../../components";
import {PencilIcon, PlusIcon as PlusIconOutline, TrashIcon} from "@heroicons/react/24/outline";
import {observer} from "mobx-react-lite";
import AddBeneficiaryPriority from "./add-beneficiary-priority";
import {toast} from "react-toastify";
import {safe_get} from "../../../report-visuals/report-utils";
import {descriptionstyle} from "../beneficiary-schemes";

const BeneficiaryPriority = observer(() => {

    const {beneficiaryStore} = useStore();
    const {projectid} = useParams();
    const {prioritiesList, addpriorities,priority_delete} = beneficiaryStore;
    const [selectedPriority, setSelectedPriority] = useState(prioritiesList[0] || null);
    const queryClient = useQueryClient();




    const editPriority = async(id) => {
        beneficiaryStore.setAddPriorities(true);
        beneficiaryStore.setActivePriority(selectedPriority);

    }

    const fetchBeneficiaryPriorities = async () => {
      return await beneficiaryStore.fetch_beneficiaryprioritys(projectid);
    }


    const  handleSchemeClose = () => {
        beneficiaryStore.setAddPriorities(false);
        beneficiaryStore.setActivePriority(null);
    }

    const handlePriorityClick = (priority) => {
        setSelectedPriority(priority);
    };


    const {mutate} = useMutation({
        mutationFn: async (id: any) => {
            await beneficiaryStore.remove_beneficiary_priority({id});
        },
        onSuccess: async () => {
            setTimeout(async () => {
                await queryClient.invalidateQueries(["beneficiaray-priorities", projectid])
            }, 1000);
            toast.success("priority Deleted Successfully");
            beneficiaryStore.update_priority_delete(false);
        },
        onError: async (error) => {
            toast.error("Something went wrong");
        }

    })

    const onCancel = () => {
        beneficiaryStore.update_priority_delete(false);
    };

    const { isLoading, error, isSuccess } = useQuery(
        {
            queryKey: ["beneficiaray-priorities", projectid],
            queryFn: fetchBeneficiaryPriorities,
            refetchOnWindowFocus: false,
            onSuccess: data => {
                if (data.data.length > 0) {
                    beneficiaryStore.setPrioritiesList(data.data);

                }
            }
        }
    );

    useEffect(() => {
        if (prioritiesList.length > 0 && !selectedPriority) {
            setSelectedPriority(prioritiesList[0]);
        }
    }, [prioritiesList, selectedPriority]);

    if (isLoading) return <LoadingState />;

    return (
        <>
            <div className="flex flex-row w-full">
                <div className="flex flex-row w-full">
                    <div className="py-4 w-1/3 flex-shrink-0">
                        <div className="flex flex-row justify-between gap-4">
                            <h4 className="m-2 text-xl text-cyan-600 font-bold border-b border-gray-300 pb-2">
                                Priorities List
                            </h4>
                            <button
                                type="button"
                                className="items-center px-2 py-2 m-2 border border-transparent rounded-full shadow-sm text-white text-xl bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                onClick={() => {
                                    beneficiaryStore.setAddPriorities(true);
                                }}
                            >
                                <PlusIconOutline className="h-5 w-5" aria-hidden="true"/>
                            </button>
                        </div>
                        {prioritiesList.length > 0 ? <ul>
                            {prioritiesList.map((priority, index) =>{
                                const name = safe_get(priority,"priority.name", null)
                                if(!name) return null
                                return (
                                    <li
                                        key={index}
                                        className={`text-md font-semibold text-black-600 p-4 cursor-pointer hover:bg-white ${selectedPriority === priority ? 'bg-white' : ''}`}
                                        onClick={() => handlePriorityClick(priority)}
                                    >
                                        {name}
                                    </li>
                                )
                            })}
                        </ul> : <p className="p-4 text-xl text-gray-500 italic">No Priorities are available !!</p>}
                    </div>

                    <div className="sm:w-2/3 py-4 bg-white min-h-[75vh]">
                        {selectedPriority && selectedPriority.priority &&
                            <div className=" p-3 rounded-lg w-full">
                                <div className="flex flex-row justify-between">
                                    <h2 className="text-xl font-bold mb-4">
                                        {selectedPriority.priority.name}
                                    </h2>
                                    <div className="flex flex-row gap-x-2">
                                        <PencilIcon
                                            className="h-5 w-5 text-blue-500 cursor-pointer"
                                            onClick={editPriority}
                                        />
                                        <TrashIcon
                                            className="h-5 w-5 text-red-500 cursor-pointer"
                                            onClick={() => {
                                                beneficiaryStore.update_priority_delete(true);
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="mb-4">
                                    <p className={descriptionstyle}>Name : {selectedPriority.priority.name}</p>
                                    <p className={descriptionstyle}>Type : {selectedPriority.priority.type}</p>
                                    <p className={descriptionstyle}>Display 1 : {selectedPriority.priority.display1}</p>
                                    <p className={descriptionstyle}>Display 2 : {selectedPriority.priority.display2}</p>
                                    <p className={descriptionstyle}>Default Display
                                        : {selectedPriority.priority.default_display}</p>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <SideOverlay
                onClose={handleSchemeClose}
                show={addpriorities}
                children={<AddBeneficiaryPriority/>}
                title={"ADD NEW PRIORITY"}
            />
            {priority_delete && (
                <DeleteModal
                    onDeleteClick={() => mutate(selectedPriority.id)}
                    onCancelClick={onCancel}
                    isOpen={priority_delete}
                    id={selectedPriority?.id}
                    desc={"Do you want to delete scheme"}
                />
            )}
        </>
    );
});


export default BeneficiaryPriority