import React, {useState} from 'react'
import {observer} from "mobx-react-lite";
import {classNames} from "../helpers";
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import {tab} from "@testing-library/user-event/dist/tab";

export const TabView = observer((props: any) => {

    const {tabs, onChange} = props

    const [activeTab, setActiveTab] = useState(tabs[0]["name"]);
    const {t} = useTranslation("components");

    return (
        <div>
            <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">
                    {t("select_tab")}
                </label>

                {/*<Select*/}
                {/*    options={tabs.map(tab => ({label: tab.name, value: tab.name}))}*/}
                {/*    onChange={(e) => {*/}
                {/*        console.log(e)*/}
                {/*    }}*/}
                {/*/>*/}


                <select
                    id="tabs"
                    name="tabs"
                    className="p-4 block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-lg border border-gray-600 "
                    defaultValue={activeTab}
                    value={activeTab}
                    onChange={(eve) => {
                        setActiveTab(eve.target.value)
                        onChange(eve.target.value)
                    }}
                >
                    {tabs.map((tab) => (
                        <option key={tab.name}>{tab.name}</option>
                    ))}
                </select>
            </div>
            <div className="hidden sm:block">
                <div className="border-b-2 border-gray-400">
                    <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                        {tabs.map((tab) => {
                                const isActiveTab = (activeTab === tab.name);
                                return (
                                    <a
                                        key={tab.name}
                                        className={classNames(
                                            isActiveTab
                                                ? 'border-indigo-500 text-indigo-600'
                                                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                            'group inline-flex items-center py-2 px-1 border-b-2 cursor-pointer'
                                        )}
                                        aria-current={isActiveTab ? 'page' : undefined}
                                        onClick={(e) => {
                                            setActiveTab(tab.name)
                                            onChange(tab.name)
                                        }}
                                    >
                                        <tab.icon
                                            className={classNames(
                                                isActiveTab ? 'text-indigo-500' : 'text-gray-400 group-hover:text-gray-500',
                                                '-ml-0.5 mr-2 h-8 w-8'
                                            )}
                                            aria-hidden="true"
                                        />
                                        <span className='text-lg font-medium'>{tab.name}</span>
                                    </a>
                                );
                            }
                        )}
                    </nav>
                </div>
            </div>
        </div>
    )

})