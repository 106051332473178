import React, { useEffect, useState,Fragment } from "react";
import { observer } from "mobx-react-lite";
import { toast } from "react-toastify";
import { AddEditTarget } from "./add-edit-target";
import VoterReachOutListTarget from "./voter-reachoutListTarget";
import VoterReachOutTargetOverview from "./voter-reactout-target-overview";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { useStore } from "../../../helpers/helpers";
import { classNames } from "../../../helpers/utils";
import SearchBar from "../../../components/SearchBar";
import { SideOverlay } from "../../../components/side-overlay";
import { DeleteModal } from "../../../components/DeleteModal";
import { CreateModel } from "../../../components/CreateModel";
import {useParams} from "react-router";

export const VoterReachOutTarget = observer((props: any) => {
  const [query, setQuery] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [IsCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const [popOver, setPopOver] = useState(false);
  const { voterReachOutStore } = useStore();
  const { isMessageOverviewOpen, selectedOverViewItem ,isEditOverlayOpen} = voterReachOutStore
  const sideLayname=selectedOverViewItem?.name
  const {projectid} = useParams();
  const datasetTypes = [
    {name: 'Create Static', description: 'Create a static target', key: 'static'},
    {name: 'Create Dynamic ', description: 'Create a dynamic target', key: 'dynamic'},
];



  const getReachOutTarget = () => {
    const getTargetListPayload = {
      projectid: projectid,
      query: query,
    };

    voterReachOutStore.get_reachout_target(getTargetListPayload);
  };

  useEffect(() => {
    getReachOutTarget();
  }, [selectedOverViewItem]);

  const addNewReachOut = async () => {
    const getTargetListPayload = {
      projectid: projectid,
      name: "Vokkaliga Voters",
      type: "static",
      text: "9990009990,9990009992,9990009993,9990009994,9990009995,9990009996",
      src: {},
      more: {},
    };

    const result = await voterReachOutStore.create_reachout_target(
      getTargetListPayload
    );
    if (result.status === 200) {
      toast.success("created Successfully");
      getReachOutTarget();
      setIsCreateModalOpen(false);
    }
  };

  const handleOverlayClose = () => {
    voterReachOutStore.set_isEditOverlay(false);
    voterReachOutStore.set_formType("new");
  };

  const deleteHandler = async () => {
    const result = await voterReachOutStore.delete_reachout_target({
      id: deleteItem,
    });
    if (result.status === 200) {
      toast.success("Deleted Successfully");
      getReachOutTarget();
    }
    setIsDeleteModalOpen(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      getReachOutTarget();
    }
  };

  const handleSearchBarChange = (value) => {
    setQuery(value);
  };

  const onDeleteHandler = (deleteItem) => {
    setIsDeleteModalOpen(true);
    setDeleteItem(deleteItem);
  };

  const handlePopOverState = (bool) => {
    setPopOver(bool)
}


  const handleTargetOverviewClose = () => {
    voterReachOutStore.set_IsMessageOverviewOpen(false)
}

const header =()=>{
  return(
    <div className='flex flex-row items-center mb-4 justify-between'>
    <h2 className='font-bold text-2xl text-indigo-500'>Voter Reach Out Target</h2>
    <Popover className="relative">
        {({open}) => (
            <>
                <Popover.Button
                    onClick={() => setPopOver(!popOver)}
                    className="inline-flex mx-2 items-center py-1 px-2 border border-transparent rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    <span>Add New</span>
                    <ChevronDownIcon
                        className={classNames(open ? 'text-gray-600' : 'text-gray-400', 'ml-2 h-5 w-5 group-hover:text-gray-500')}
                        aria-hidden="true"
                    />
                </Popover.Button>

                <Transition
                    show={popOver}
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                >
                    <Popover.Panel
                        static
                        className="absolute  z-10  transform -translate-x-1/2 mt-3 px-2 w-64 sm:px-0"
                    >
                        <div
                            className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                            <div className="relative grid gap-6 bg-white px-3 py-2 sm:gap-8 sm:p-3">
                                {datasetTypes.map((item) => (
                                    <div
                                        key={item.name}
                                        onClick={(evt) => {
                                       { item.key ==="static" &&setIsCreateModalOpen(true)}
                                            setPopOver(false)
                                        }}
                                        className="-m-3 p-3 block rounded-sm cursor-pointer hover:bg-blue-50 transition ease-in-out duration-150"
                                    >
                                        <p className="text-base font-bold text-gray-900">{item.name}</p>
                                        <p className="text-sm text-gray-500">{item.description}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Popover.Panel>
                </Transition>
            </>
        )}
    </Popover>
</div>
  )
}

  return (
    <main>
      <div className="p-4">
        {header()}
        <SearchBar
          query={query}
          handleChange={handleSearchBarChange}
          handleKeyDown={handleKeyDown}
          getFilteredData={getReachOutTarget}
          placeholder={"Search Target..."}
        />
        <SideOverlay
          children={
            <AddEditTarget
              projectId={projectid}
              getReachOutTarget={getReachOutTarget}
              data={selectedOverViewItem}
            />
          }
          show={isEditOverlayOpen}
          onClose={handleOverlayClose}
          title={"Edit Target"}
        />
        <SideOverlay children={<VoterReachOutTargetOverview data={selectedOverViewItem} />} show={isMessageOverviewOpen} onClose={handleTargetOverviewClose} title={sideLayname} />
        <VoterReachOutListTarget setPopOver={handlePopOverState} deleteMessage={onDeleteHandler} />
        {isDeleteModalOpen && (
          <DeleteModal
            isOpen={isDeleteModalOpen}
            desc={`Are you sure you want to delete ?`}
            onDeleteClick={deleteHandler}
            onCancelClick={() => setIsDeleteModalOpen(false)}
          />
        )}

        {IsCreateModalOpen && (
          <CreateModel
            isOpen={IsCreateModalOpen}
            desc={`Are you sure you want to Create Static Target ?`}
            onCreateClick={addNewReachOut}
            onCancelClick={() => setIsCreateModalOpen(false)}
          />
        )}
      </div>
    </main>
  );
});
