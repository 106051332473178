import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import {useStore} from "../../../helpers/helpers";
import {makeid_alpa_numeric} from "../../../helpers/utils";
import { useTranslation } from 'react-i18next';
import {ErrorMeaasge, input_style, label_style, ProfileFormField} from "../../beneficiary-management";
import {FormButtons} from "../../../components/cm-components";

type FundModel = {
    id: string,
    date: string,
    title: string,
    amount: string,
    description: string
}

type AddFundsProps = {
    onAdd: Function,
    fund: FundModel
}
export const AddFunds = (props: AddFundsProps) => {

    const {onAdd, fund} = props;
    console.log("AddFunds")

    const {projectWorksStore} = useStore()
    const [addFund, setAddFund] = useState(fund);
    const {t} = useTranslation("project_work");
    const {register, handleSubmit, formState: {errors}} = useForm({defaultValues: addFund, mode: 'onSubmit', reValidateMode: 'onBlur'});

    const addFundChangeHandler = (val, filed_name) => {
        setAddFund({...addFund, [filed_name]: val});
    }

    const onSubmit = async (values) => {
        if(values.id){
            onAdd({...addFund})
        } else {
            onAdd({...addFund, id: makeid_alpa_numeric(6)})
        }
        projectWorksStore.update_project_work_property("add_fund_modal", false);
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <ProfileFormField
                        register={register}
                        field_name="title"
                        value={addFund.title}
                        label={t("title")}
                        onChangeHandler={addFundChangeHandler}
                        required={true}
                        error={errors}
                        error_msg={t("title_required")}
                    />
                    <ProfileFormField
                        register={register}
                        field_name="amount"
                        value={addFund.amount}
                        label={t("amount")}
                        onChangeHandler={addFundChangeHandler}
                        required={true}
                        error={errors}
                        error_msg={t("amount_required")}
                    />
                    <div className="mb-2 flex flex-row items-center justify-between">
                        <label htmlFor="date" className={label_style}>{t("date")}</label>
                        <input
                            type="date"
                            {...register("date", {required: true})}
                            name="date"
                            className={input_style}
                            value={addFund.date}
                            onChange={event => {
                                setAddFund({...addFund, date: event.target.value})
                            }}
                        />
                    </div>
                    <ErrorMeaasge val={errors["date"]} error={"Date is required"}/>
                    <div className="mb-2">
                        <label htmlFor="description" className={label_style}>{t("additional_information")}</label>
                        <textarea
                            {...register("description", {required: true})}
                            name="description"
                            className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md border p-2"
                            rows={3}
                            value={addFund.description}
                            onChange={event => {
                                setAddFund({...addFund, description: event.target.value})
                            }}
                        />
                        <ErrorMeaasge val={errors["description"]} error={"Required"}/>
                    </div>
                </div>

                <FormButtons
                    onSave={()=> {}}
                    onCancel={(e) => {
                        projectWorksStore.update_project_work_property("add_fund_modal", false);
                    }}
                />
            </form>
        </>
    )
}