import { action, makeAutoObservable } from "mobx";
import _ from "lodash";
export class QuestionStore {
  questions = {};
  tabs = [];
  activeTab = { title: "", data: [] };
  constructor() {
    makeAutoObservable(this);
  }
  @action
  initializeStore(data) {
    this.questions = { ...data };
    this.tabs = _.keys(this.questions);
    this.activeTab = {
      title: this.tabs[0],
      data: [...this.questions[this.tabs[0]]],
    };
  }
  @action
  showOptions(question) {
    this.activeTab = { title: question, data: [...this.questions[question]] };
  }

  @action
  addOption(question, added_arr) {
    this.questions[question] = [...this.questions[question], ...added_arr];
    this.showOptions(question);
  }
  @action
  updateOption(question, updated_arr) {
    this.questions[question] = [...updated_arr];
    this.showOptions(question);
  }
  @action
  deleteOption(question, option) {
    this.questions[question] = _.filter(
      this.questions[question],
      ({ label, value }) => label !== option
    );
    this.showOptions(question);
  }
  get getQuestions() {
    return this.questions;
  }
}

// const questionStore = new QuestionStore();
// export default questionStore;
