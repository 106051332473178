import React, {useMemo} from "react";
import {observer} from "mobx-react-lite";
import {useStore} from "../../../helpers/helpers";
import _ from "lodash";
import {Table} from "./table";
import {toJS} from "mobx";
import {TableHeader} from "./survey-summary";
import { useTranslation } from "react-i18next";

export const SurveysByDateHour = observer(props => {
    const {surveyResponseStore, userStore} = useStore();
    const {allSurveyResponsesSummary} = surveyResponseStore;
    const [t] = useTranslation(["survey_management", "analytical_summary"])

    const data = useMemo(() => toJS(allSurveyResponsesSummary)["by_dt_month_hour"], [allSurveyResponsesSummary])

    const columns = useMemo(() => [
        {
            Header: t("date",{ns:"analytical_summary"}),
            accessor: 'dt_month_date',
            Footer: t("total")
        },
        {
            Header: '07',
            accessor: 'hr_07',
            Footer: surveyResponseStore.get_total_surveys(data, "hr_07")
        },
        {
            Header: '08',
            accessor: 'hr_08',
            Footer: surveyResponseStore.get_total_surveys(data, "hr_08")
        },
        {
            Header: '09',
            accessor: 'hr_09',
            Footer: surveyResponseStore.get_total_surveys(data, "hr_09")
        },
        {
            Header: '10',
            accessor: 'hr_10',
            Footer: surveyResponseStore.get_total_surveys(data, "hr_10")
        },
        {
            Header: '11',
            accessor: 'hr_11',
            Footer: surveyResponseStore.get_total_surveys(data, "hr_11")
        },
        {
            Header: '12',
            accessor: 'hr_12',
            Footer: surveyResponseStore.get_total_surveys(data, "hr_12")
        },
        {
            Header: '13',
            accessor: 'hr_13',
            Footer: surveyResponseStore.get_total_surveys(data, "hr_13")
        },
        {
            Header: '14',
            accessor: 'hr_14',
            Footer: surveyResponseStore.get_total_surveys(data, "hr_14")
        },
        {
            Header: '15',
            accessor: 'hr_15',
            Footer: surveyResponseStore.get_total_surveys(data, "hr_15")
        },
        {
            Header: '16',
            accessor: 'hr_16',
            Footer: surveyResponseStore.get_total_surveys(data, "hr_16")
        },
        {
            Header: '17',
            accessor: 'hr_17',
            Footer: surveyResponseStore.get_total_surveys(data, "hr_17")
        },
        {
            Header: '18',
            accessor: 'hr_18',
            Footer: surveyResponseStore.get_total_surveys(data, "hr_18")
        },
        {
            Header: '19',
            accessor: 'hr_19',
            Footer: surveyResponseStore.get_total_surveys(data, "hr_19")
        },
        {
            Header: '20',
            accessor: 'hr_20',
            Footer: surveyResponseStore.get_total_surveys(data, "hr_20")
        }
    ], [allSurveyResponsesSummary]);

    if(_.isEmpty(allSurveyResponsesSummary["by_date_qa"]) || userStore.is_field_worker()) return null;

    const row_click_handler = (x) => {
        console.log(x);
        surveyResponseStore.update_new_filters("dt_month_date",x["dt_month_date"],`Date: ${x['dt_month_date']}`);
    }

    return(
        <div className="flex flex-col">
            <TableHeader  title={t("by_hour")}/>
            <Table data={data} columns={columns} onRowClick={row_click_handler} />
        </div>
    )
})