import React, {useEffect, useState} from "react"
import {useStore} from "../../../helpers/helpers";

const ViewAttachment = props => {
    const rootStore = useStore();
    const fetch_s3uri = async (path) => {
        if (!path) return false;
        const url = await rootStore.get_s3_url("urn-assets", path);
        return url
    }
    const {path} = props
    const [fetched, set_fetched] = useState(false)
    const [resuri, set_resuri] = useState('')

    const resolve_uri = async () => {
        const uri = await fetch_s3uri(path)
        set_resuri(uri)
        set_fetched(true)
    }
    useEffect(() => {
        resolve_uri()
    }, [])

    if (!fetched) return null;

    return (
        <div>
            <audio controls className="w-11/12 h-7">
                <source
                    src={resuri}/>
                Your browser does not support the audio element.
            </audio>
        </div>
    )
}

const VoterReachoutAudioPlayer = props => {
    const {data} = props;
    return (
        <>
            <ViewAttachment path={data}/>
        </>
    )
}

export default VoterReachoutAudioPlayer;

