import {useMutation, useQueryClient} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {useStore} from "../../../helpers";
import { useParams } from "react-router-dom";

export const useEditProjectWork = () => {

    const {projectWorkStoreV2} = useStore();
    const { projectid } = useParams();
    const queryClient = useQueryClient();

    const onEditProjectWork = async (payload: any) => {
        return await projectWorkStoreV2.edit_project_work(payload);
    }

    const {mutate: editProjectWork, isLoading} = useMutation({
        mutationFn: onEditProjectWork,
        onSuccess: async () => {
            setTimeout(async () => {
                await queryClient.invalidateQueries(["project-works", projectid])
            }, 1000);
            toast.success("Project updated Successfully")
            projectWorkStoreV2.update_add_project_modal(false);
        },
        onError: (error) => {
            toast.error("Something went wrong");
        }
    })

    return {
        editProjectWork, isLoading
    }

}