import {observer} from "mobx-react-lite";
import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import {useStore} from "../../helpers/helpers";
import _ from "lodash";
import {toJS} from "mobx";
import {reduce_for_visual, safe_get} from "../report-utils";
import {ClearFiltersForVisual} from "./clearfilters";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";

export const GeoMap = observer((props: any) => {

    const rootStore = useStore();
    const {reportStore} = rootStore;
    const {currentReportData} = reportStore;

    const {section, depth} = props;
    const {id, type, settings, args} = section;
    const {title} = settings;

    const [dt, setdt] = useState([]);
    const [transformed, setTransformed] = useState(false);
    const [loading, setLoading] = useState(false);

    const {reducers, ds, headers, display} = args;
    const renderType = args['render'];
    const _args = toJS(args);

    const chart_div = `chart_${id}_container`;
    const rootStyles = safe_get(_args, "display.rootStyles", {"width": "100%", "height": "500px"});
    const rootClasses = safe_get(_args, "display.rootStyles", '');

    const geo_map = useRef(null);


    useEffect(() => {
        refresh_data()
    }, [reportStore.currentReportFilters]);

    const refresh_data = () => {
        setLoading(true);
        let current = reduce_for_visual(toJS(currentReportData[ds]), reducers, [])
        setdt(current);
        setTransformed(true);
        setLoading(false);
    };

    useLayoutEffect(() => {
        const chart = am4core.create(chart_div, am4maps.MapChart);
        chart.parsingStepDuration = 1000;
        //chart.reverseGeodata = true;
        chart.projection = new am4maps.projections.Miller();
        //chart.geodata = am4geodata_worldLow;
        chart.geodata = _.clone(dt);

        // Create map polygon series
        var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());

        // Make map load polygon (like country names) data from GeoJSON
        polygonSeries.useGeodata = true;

        // Configure series
        const polygonTemplate = polygonSeries.mapPolygons.template;
        polygonTemplate.tooltipText = "{name}";
        polygonTemplate.fill = am4core.color("#74B266");

        // Create hover state and set alternative fill color
        var hs = polygonTemplate.states.create("hover");
        hs.properties.fill = am4core.color("#367B25");

        // @ts-ignore
        geo_map.current = chart;
    }, [dt]);


    return (
        <>
            <div
                style={rootStyles}
                className={`flex flex-col ${rootClasses}`}
            >
                <div key={`chart_${id}_title`}>{title}</div>
                <div key={`${chart_div}`} id={`${chart_div}`} className='w-full h-full'
                     style={{marginTop: '-15px',}}/>
                <ClearFiltersForVisual/>
            </div>
        </>
    );

});