import React, {useState, useEffect} from "react";
import {useStore} from "../../../helpers/helpers";
import {PlusIcon as PlusIconOutline, TrashIcon, PencilIcon} from "@heroicons/react/24/outline";
import {DeleteModal, SideOverlay} from "../../../components";
import AddBeneficiaryScheme from "./add-beneficiary-scheme";
import {observer} from "mobx-react-lite";
import {toast} from "react-toastify";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useParams} from "react-router-dom";
import {safe_get} from "../../../report-visuals/report-utils";

export  const descriptionstyle = "mb-2 text-gray-700 font-medium";

const BeneficiaryScheme = observer(() => {

    const {beneficiaryStore} = useStore();
    const {schemesList, addscheme, scheme_delete} = beneficiaryStore;
    const [selectedScheme, setSelectedScheme] = useState(schemesList[0] || null);
    const queryClient = useQueryClient();
    const {projectid} = useParams();
    const required_docs = safe_get(selectedScheme, "type.required_docs", [])

    const handleSchemeClick = (scheme) => {
        setSelectedScheme(scheme);
    };

    const {mutate} = useMutation({
        mutationFn: async (id: any) => {
            await beneficiaryStore.remove_beneficiary_scheme({id});
        },
        onSuccess: async () => {
            setTimeout(async () => {
                await queryClient.invalidateQueries(["beneficiaray-schemes", projectid])
            }, 1000);
            toast.success("Scheme Deleted Successfully");
            beneficiaryStore.update_scheme_delete(false);
        },
        onError: async (error) => {
            toast.error("Something went wrong");
        }
    })

    const editScheme = async(id) => {
        beneficiaryStore.setAddSchemes(true);
        beneficiaryStore.setActiveScheme(selectedScheme);
    }

    const onCancel = () => {
        beneficiaryStore.update_scheme_delete(false);
    };


    const  handleSchemeClose = () => {
        beneficiaryStore.setAddSchemes(false);
        beneficiaryStore.setActiveScheme(null);
    }

    useEffect(() => {
        if (schemesList.length > 0 && !selectedScheme) {
            setSelectedScheme(schemesList[0]);
        }
    }, [schemesList, selectedScheme]);


    return (
        <>
            <div className="flex flex-row w-full">
                <div className="flex flex-row w-full">
                    <div className="py-4 w-1/3 flex-shrink-0">
                        <div className="flex flex-row justify-between gap-4">
                            <h4 className="m-2 text-xl text-cyan-600 font-bold border-b border-gray-300 pb-2">
                                Schemes List
                            </h4>
                            <button
                                type="button"
                                className="items-center px-2 py-2 m-2 border border-transparent rounded-full shadow-sm text-white text-xl bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                onClick={() => {
                                    beneficiaryStore.setAddSchemes(true);
                                }}
                            >
                                <PlusIconOutline className="h-5 w-5" aria-hidden="true"/>
                            </button>
                        </div>
                        {schemesList.length > 0 ? <ul>
                            {schemesList.map((scheme, index) => {
                                return (
                                    <li
                                        key={index}
                                        className={`text-md font-semibold text-black-600 p-4 cursor-pointer hover:bg-white ${selectedScheme === scheme ? 'bg-white' : ''}`}
                                        onClick={() => handleSchemeClick(scheme)}
                                    >
                                        {scheme.type?.name}
                                    </li>
                                )}
                            )}
                        </ul> : <p className="p-4 text-xl text-gray-500 italic">No Schemes are available !!</p>}
                    </div>

                    <div className="sm:w-2/3 py-4 bg-white min-h-[75vh]">
                        {selectedScheme && selectedScheme.type &&
                            <div className=" p-3 rounded-lg w-full">
                                <div className="flex flex-row justify-between">
                                    <h2 className="text-xl font-bold mb-4">
                                        {selectedScheme.type.name}
                                    </h2>
                                    <div className="flex flex-row gap-x-2">
                                        <PencilIcon
                                            className="h-5 w-5 text-blue-500 cursor-pointer"
                                            onClick={editScheme}
                                        />
                                        <TrashIcon
                                            className="h-5 w-5 text-red-500 cursor-pointer"
                                            onClick={() => {
                                                beneficiaryStore.update_scheme_delete(true);
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="mb-4">
                                    <p className={descriptionstyle}>Category : {selectedScheme.type.category}</p>
                                    <p className={descriptionstyle}>Sub-Category : {selectedScheme.type.subcategory}</p>
                                    <p className={descriptionstyle}>Display 1 : {selectedScheme.type.display1}</p>
                                    <p className={descriptionstyle}>Display 2 : {selectedScheme.type.display2}</p>
                                    <p className={descriptionstyle}>Default Display : {selectedScheme.type.default_display}</p>
                                    <div className={descriptionstyle}>Required Documents :
                                        <ul className="list-disc ml-5 pt-1 placeholder-accent/20 pl-4 pb-4">
                                            {required_docs.length >0 ? required_docs.map((doc, index) => (
                                                <li key={index}>
                                                    {doc.name} {doc.required && (
                                                    <span className="text-red-500">*</span>
                                                )}
                                                </li>
                                            )): <p>null</p>}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <SideOverlay
                onClose={handleSchemeClose}
                show={addscheme}
                children={<AddBeneficiaryScheme/>}
                title={"ADD NEW SCHEME"}
            />
            {scheme_delete && (
                <DeleteModal
                    onDeleteClick={() => mutate(selectedScheme.id)}
                    onCancelClick={onCancel}
                    isOpen={scheme_delete}
                    id={selectedScheme?.id}
                    desc={"Do you want to delete scheme"}
                />
            )}
        </>
    );
});

export default BeneficiaryScheme;
