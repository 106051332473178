import {observer} from "mobx-react-lite";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useStore} from "../../helpers/helpers";
import {ViewAttachment} from "../../components";


export const RenderFile = observer( (props:any) => {

    const rootStore = useStore();
    const [resuri, set_resuri] = useState('')
    const {key} = useParams();
    const fetch_s3uri = async (path) => {
        if (!path) return '';
        return await rootStore.get_s3_url("urn-assets", path)
    }
    const resolve_uri = async () => {
        const uri = await fetch_s3uri(key)
        set_resuri(uri)
    }
    useEffect(() => {
        resolve_uri()
    }, [])

    // if(activeFileorFolder["is_folder"] === true) return ;

    return(
        <>
            <p>View File</p>
            <img src={resuri} />
            {/*<ViewAttachment attachment={{type: "img/jpeg", path:key}} s3_bucket={"urn-assets"}/>*/}
        </>
    )
})