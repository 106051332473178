import React, { useState, useEffect } from "react";
import { useStore } from "../../helpers/helpers";
import _ from "lodash"
import AgeFilter from "./filters/ageFilter";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import {GendersFilter, RichSearch} from "./filters";
import {Booths} from "../user-management";
import {FormButtons} from "../../components/cm-components";
import { DateFilter } from "../constituency-command-center";
import { DateSearch } from "./filters/date-searchFilter";
import moment from "moment";

export const FilterOverLay = observer((props) => {

    const rootStore = useStore();
    const {projectid} = useParams();
    const { beneficiaryStore, voterListStore, userStore } = rootStore;
    const [t] = useTranslation("voter_list");
    const { voter_filters } = voterListStore;
    const filterTypes = ["Rich Search", "Gender", "Age", "Booths","Date of Birth"];
    const [activeFilter, setActiveFilter] = useState(filterTypes[0]);
    const { serialno, name, relation, voterid, page, house } = voter_filters;
    const [richSearch, setRichSearch] = useState({
        serialno: serialno,
        name: name,
        relation: relation,
        voterid: voterid,
        page: page,
        house: house,
    });
    const [genders, setGenders] = useState(voter_filters["genders"]);
    const [ageFilter, setAgeFilter] = useState(voter_filters["age"]);

    const [booths, setbooths] = useState([]);
    const [birthDate,setDateofBirth] = useState(voter_filters["dob"]);

    // Callback function to receive data from child
    const handleDateSearch= (date) => {
            setDateofBirth(moment(date).format('DD-MM-YYYY'));
    };

    useEffect(() => {
        const activeUserId = JSON.parse(localStorage.getItem("__userprofile"));
        const UserId = activeUserId?.id;
        userStore.get_user_permissions_and_parts({
            projectid: Number(projectid),
            userid: UserId,
        });
    }, []);

    const handleBoothFilter = (e) => {
        const { value, checked } = e.target;
        console.log(e.target)
        if (checked) {
            setGenders([...genders, value]);
        } else {
            setGenders(genders.filter((e) => e !== value));
        }
    };

    const handleCancel = () => {
        voterListStore.setShowVoterlist(true);
        voterListStore.setFilterStatus(false);
        voterListStore.setFilterVal(null);
        voterListStore.setSelectedBooths([]);
    };

    const setBoothVal = (data: any, name) => {
        beneficiaryStore.setSelectedBooths(data)
        // voterListStore.update_filters((prev) => {
        //     return { ...prev, [name]: data };
        // });
        setbooths(data)
    }

    const handleRichSearch = (key, value) => {
        setRichSearch({ ...richSearch, [key]: value })
    }

   
    const handleAgeFilter = (event) => {
        const { value, name } = event.target;
        if (name === "reset") {
            setAgeFilter([18, 120])
        } else {
            const age = value.split(",").map(c => {
                return Number(c)
            })
            setAgeFilter(age)
        }
    };

    //TODO: update local filter variables to central store
    const apply_filters = async() => {
        const clone = _.clone(voter_filters);
        clone["serialno"] = richSearch.serialno
        clone["name"] = richSearch.name
        clone["relation"] = richSearch.relation
        clone["voterid"] = richSearch.voterid
        clone["page"] = richSearch.page
        clone["house"] = richSearch.house
        clone["genders"] = genders
        clone["age"] = ageFilter
        clone["booths"] = booths
        clone["projectid"] = parseInt(projectid)
        clone["dob"] = birthDate;
        

        voterListStore.update_filters(clone);
        voterListStore.setShowVoterlist(true);
        voterListStore.setFilterStatus(false);
    }

    return (
        <div className="w-full h-full flex">
            <div className="p-2 w-1/4 h-full border-black border-r-2">
                {filterTypes.map((tab) => (
                    <p className="pt-5" onClick={() => {
                        setActiveFilter(tab)
                    }}>
                        <span className={activeFilter == tab ? "text-purple-600 text-lg font-medium" : "text-lg font-medium"}>{tab}</span>
                    </p>
                ))}
            </div>

            <div className="p-2 w-3/4 h-full">
                {activeFilter == "Rich Search" && (
                    <div className="p-2 px-2">
                        <RichSearch handleRichSearch={handleRichSearch} />
                    </div>
                )}

                {activeFilter == "Gender" && (
                    <div className="p-2 px-2">
                        <GendersFilter genders={genders} handleBoothFilter={handleBoothFilter} />
                    </div>
                )}

                {activeFilter == "Age" && (
                    <div className="p-2 px-2">
                        <AgeFilter handleAgeFilter={handleAgeFilter} />
                    </div>
                )}

                {activeFilter == "Booths" && <Booths filter={true} booths={setBoothVal} />}

                {activeFilter == "Date of Birth" && (
                    <div className="p-2 px-2">
                        <DateSearch handleDateSearch = {handleDateSearch} />
                    </div>
                )}

                {activeFilter && <FormButtons onSave={apply_filters} onCancel={handleCancel} />}
            </div>
        </div>
    );
});
