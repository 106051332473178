
export type IlType = {
    profile: {
        name: string,
        phonenumber: string,
        whatsapp_number: string,
        rating: number,
        photo_path: {
            path: string,
            type: string,
            actual: any
        },
        age: number
    },
    node: any,
    more: {
        primary_occupation:string,
        additional_information: string,
        local_elections_contested: string,
    },
    influence: {
        caste: string,
        voters: number
    },
    affiliation : {
        party: string,
        candidate: string,
        strength_of_support: string
    },
    voter_card_linked: boolean | string,
    voter_card: any,
    is_volunteer: boolean | string,
    volunteer: {
        position: string,
        roles: Array<string>,
    },
    is_app_user: boolean | string,
    userid: number
}

export type IlFilters = {
    party_support: Array<string>,
    party_roles: Array<string>,
    support_strength: Array<string>,
    candidate_support: Array<string>,
    nodes: Array<any>,
    caste: Array<string>
}

export const defaultILValues:IlType = {
    profile: {
        name: "",
        phonenumber: "",
        whatsapp_number: "",
        rating: 0,
        photo_path: {
            path: "",
            type: "",
            actual: {}
        },
        age: 0
    },
    node: {},
    more: {
        primary_occupation: "",
        additional_information: "",
        local_elections_contested: "",
    },
    influence: {
        caste: "",
        voters: 0
    },
    affiliation : {
        party: "",
        candidate: "",
        strength_of_support: ""
    },
    voter_card_linked: false,
    voter_card: {},
    is_volunteer: false,
    volunteer: {
        position: "",
        roles: [""],
    },
    is_app_user: false,
    userid: 0
}

export const defaultIlFilters = {
    party_support: [],
    party_roles: [],
    support_strength: [],
    candidate_support: [],
    nodes: [],
    caste: []
}