import React from "react";
import { safe_get } from "../../report-visuals/report-utils";
import { useStore, classNames } from "../../helpers";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import {RenderImageFromS3} from "../../components";

export const QuestionAndAnswerResponseView = observer((props: any) => {

    const { surveyResponseStore } = useStore();
    const { activeQuestion } = surveyResponseStore;

    const { question, response, qno, seek, onClickHandler } = props
    const { type } = question
    const lang = Object.keys(question["text"])[0];
    const { t } = useTranslation("survey_management");

    console.log("props", props)
    const questioStyles = "flex flex-col text-md p-4"
    return (
        <div onClick={() => {
            onClickHandler(seek, question);
        }}
            className={classNames(activeQuestion["id"] === question.id) ? 'bg-blue-100 rounded-md' : "flex flex-col cursor-pointer"}>
            <div className='font-bold text-lg mt-2'>
                Q{qno + 1}). {question["text"][lang]}
            </div>
            {
                (type === "hierarchy-selector-2-level") ? (
                    <div className={questioStyles}>{response["level1"]}/{response["level2"]}</div>
                ) : null
            }
            {
                (type === "single-select") ? (
                    <div className={questioStyles}>{response["selected"][lang]}</div>
                ) : null
            }
            {
                (type === "single-select-other") ? (
                    <div className={questioStyles}>
                        {response["selected"][lang]}
                        <br />
                        {response["extras"]}
                    </div>
                ) : null
            }
            {
                (type === "simple-div") ? (
                    <div className={questioStyles}>{response["selected"]}</div>
                ) : null
            }
            {
                (type === "multi-select") ? <div className={questioStyles}>{response["selected"][lang].join(", ")}</div> : null
            }
            {
                (type === "multi-select-other") ? (
                    <div className={questioStyles}>
                        {
                            Array.isArray(response["selected"][lang]) ?
                                response["selected"][lang].join(", ") :
                                response["selected"][lang]
                        }
                        {response["extras"] === '' ? null : <div>({response["extras"]})</div>}

                    </div>
                ) : null
            }
            {
                (type === "name-phone") ? (
                    <div className={questioStyles}>
                        {response["name"]}
                        {"\n"}
                        {response["phone"]}
                    </div>
                ) : null
            }
            {
                (type === "profile") ? (
                    <div className={questioStyles}>
                        {Object.keys(response["profile"]).map((x, index) => {
                            if (!response["profile"][x]) return;
                            return <p className='capitalize p-2' key={index}><span
                                className='font-bold'>{x}</span> : {response["profile"][x]}</p>
                        })}
                    </div>
                ) : null
            }
            {
                (type === "phone-whatsapp") ? (
                    <div className={questioStyles}>
                        <p className='capitalize p-2'><span
                            className='font-bold'>{t("phone")}</span> : {response["phone_whatsapp"]["phone"]}</p>
                        <p className='capitalize p-2'><span
                            className='font-bold'>{t("whats_app")}</span> : {response["phone_whatsapp"]["whatsapp"]}</p>
                    </div>
                ) : null
            }
            {
                (type === "simple-text") ? (
                    <div className={questioStyles}>
                        <p className='capitalize p-2'>{safe_get(response, "selected", "")}</p>
                    </div>
                ) : null
            }
            {
                (type === "caste" && response["caste"]) ? (
                    <div className={questioStyles}>
                        <p className='capitalize p-2'>{safe_get(response["caste"], "display", "")}</p>
                    </div>
                ) : null
            }
            {
                (type === "photo") ? (
                    <div className="flex flex-row text-md p-4" style={{ width: "20%", height: "auto", overflow: "auto" }}>
                        {
                            response["photos"] != undefined && response["photos"].map(photo => {
                                return <RenderImageFromS3 data={photo} />
                            })}
                    </div>
                ) : null
            }
            {
                (type === "form6-partA" || type === "form6-partB" || type === "form6-partC") ? (
                    <div className={questioStyles}>
                        {/*<RenderForm6 data={response[map_form6_type(type)]} />*/}
                        <p className='capitalize p-2'>{safe_get(response["caste"], "display", "")}</p>
                    </div>
                ) : null
            }
            {
                (type === "influential-person") ? (
                    <div className={questioStyles}>
                        {/*<p className='capitalize p-2'>{safe_get(response["caste"], "display", "")}</p>*/}
                        {
                            response["influential_people"].map(person => {
                                const person_keys = Object.keys(person);
                                const RenderPeople = person_keys.map(key => {
                                    if (key === "id" || !person[key]) return;
                                    return (
                                        <div key={person[key]} className={"py-1"} >
                                            <p className="capitalize"><span className="font-bold">{key}</span> : {person[key]}</p>
                                        </div>
                                    )
                                })
                                return (
                                    <div key={person.id}>
                                        {RenderPeople}
                                    </div>
                                )
                            })
                        }
                    </div>
                ) : null
            }
        </div>
    )
})