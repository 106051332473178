import React from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../helpers/helpers";
import { toast } from "react-toastify";
import { AddEditSms } from "./add-edit-sms";
import AddEditVoiceCall from "./add-edit-voicecall";
import AddEditWhatsapp from "./add-edit-whatsApp-message";

export const MessageView = observer((props: any) => {

    const rootStore = useStore();
    const { voterReachOutStore } = rootStore
    const { messageType, formType } = voterReachOutStore

    const formSubmit = async (payload, id) => {
        if (formType !== "update") {
            const res = await voterReachOutStore.create_reachout_message(payload)
            if (res.status === 200) {
                toast.success('Text message added successfully')
                props.getReachOutMessages()
                voterReachOutStore.isSideOverlayOpen = false
            }
        } else {
            const res = await voterReachOutStore.update_reachout_message({ ...payload, id: id })
            if (res.status === 200) {
                toast.success('Text message added successfully')
                props.getReachOutMessages()
                voterReachOutStore.isSideOverlayOpen = false
            }
        }
    }

    return <>
        {messageType === "Text Messages" &&
            <AddEditSms formSubmit={formSubmit} />
        }
        {messageType === "Voice Messages" &&
            <AddEditVoiceCall formSubmit={formSubmit}  />
        }
        {messageType === "whatsapp Messages" || messageType === "Social Media Post" ?
             <AddEditWhatsapp type={messageType === "whatsapp Messages" ? "wa" : "sm"} formSubmit={formSubmit} />
             : null
        } 

{/* {messageType === "whatsapp Messages" &&
           <AddEditSocialMessage type = "wa" formSubmit={formSubmit} />
        }
        { messageType === "Social Media Post" &&
           <AddEditSocialMessage type = "sm" formSubmit={formSubmit} />
        }
       */}
    </>
})

