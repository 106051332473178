import {useWindowSize} from "react-use";
import {useEffect, useState} from "react";


export const useIsMobile = () => {

    const [isMobile, setIsMobile] = useState(false);

    const {width, height} = useWindowSize();

    useEffect(() => {
      setIsMobile(width <= 766)
    }, []);

    return isMobile;
}