import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import {useStore} from "../../../../helpers";
import {useLocation, useParams} from "react-router";
import {PlusIcon as PlusIconOutline} from "@heroicons/react/24/outline";
import {toast} from "react-toastify";
import {TrashIcon} from "@heroicons/react/24/solid";
import {FormButtons} from "../../../../components/cm-components";
import {LoadingState, TailwindModal} from "../../../../components";
import {ACBankDAO} from "../acbank_dao";
import {useFSDPermissions} from "../../../../custom-hooks/useFSDPermissions";
import {useIsMobile} from "../../../../custom-hooks";

export const ACBankPermissions = observer((props) => {

    const rootStore = useStore();
    const {flexiStore} = rootStore;
    const dao = new ACBankDAO(rootStore)
    const {id} = useParams();
    const isMobile = useIsMobile();
    const [open, setOpen] = useState(false);
    const [phonenumList, setPhonenumList] = useState([]);
    const [submit, setSubmit] = useState(false);
    const {users, isLoading, refetch:get_permissions} = useFSDPermissions();
    const {state} = useLocation();

    const revoke_permissions = async (user_id) => {
        const deletePermissions = {
            userid: parseInt(user_id),
            fsdid: id ? parseInt(id) : state.fsdid,
            fstid: dao.fsd.fst.id,
        };
        const res = await flexiStore.revoke_fsd_permissions(deletePermissions);
        if (res.status === 200) {
            toast.success("User deleted from the project");
            get_permissions();
        }
    };

    const handleSave = async () => {
        const add_permissions = {
            phonenumlist: phonenumList,
            fstid: dao.fsd.fst.id,
            fsdid: id ? parseInt(id) : state.fsdid,
        };
        const res = await flexiStore.add_fsd_permissions(add_permissions);
        if (res.status === 200) {
            toast.success("New User added successfully");
            setOpen(false);
            setSubmit(false);
            get_permissions();
        } else {
            toast.warning("Unable to add user");
        }
    };

    return (
        <>
            {isLoading && <LoadingState />}
            <div className="pl-4 font-bold text-2xl pb-2 uppercase">
                <h1>AC Bank Permissions</h1>
                <div className="flex flex-row justify-between items-center">
                    <h2 className="-ml-4 font-bold text-2xl text-indigo-500 p-4">Users</h2>
                    <button
                        type="button"
                        className="inline-flex items-center p-2 m-4 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={() => setOpen(true)}
                    >
                        <PlusIconOutline className="h-6 w-6" aria-hidden="true"/>
                    </button>
                </div>
            </div>
            <div
                className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 px-4 mb-2 sm:mb-4">
                {users.length > 0 && users.map((user) => (
                    <div
                        key={user.id}
                        className="cursor-pointer group relative rounded-lg border border-gray-300 bg-gray-100 p-2 sm:px-4 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 bg-gray-50"
                    >
                        <div className="flex-shrink-0">
                            <img className="h-16 w-16 rounded-md" src={user.profileimageuri}/>
                        </div>
                        <div className="flex flex-row items-center justify-between">
                            <div className="flex flex-col">
                                {user.name && (
                                    <p className="mt-1 font-bold flex items-center text-md text-purple-600 mr-2 flex-nowrap">
                                        {user.name}
                                    </p>
                                )}
                                <p className="mt-1 font-bold flex items-center text-sm text-gray-500 mr-2 flex-nowrap">
                                    {user.phonenum}
                                </p>
                                <div>{user.role}</div>
                            </div>
                            <div className="cursor-pointer text-red-500"
                                 title="Delete User"
                                 onClick={(e) => {
                                     revoke_permissions(user.id);
                                 }}
                            >
                                <TrashIcon
                                    className={`flex-shrink-0 mr-1.5 h-5 w-5 text-red-400 transition-opacity duration-800 ${!isMobile ? "invisible group-hover:visible" :"" }`}
                                    aria-hidden="true"
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <TailwindModal
                isOpen={open}
                children={
                    <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-lg w-full">
                        <div className="p-4">
                            <div className="font-bold text-2xl text-indigo-500">Add New User</div>
                            <div>
                                <label
                                    htmlFor="newProjectDescription"
                                    className="block text-sm font-medium text-gray-700 pt-4"
                                >
                                    Phone Numbers
                                </label>
                                <textarea
                                    className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md border p-2"
                                    cols={10}
                                    rows={5}
                                    wrap="hard"
                                    value={phonenumList.join("\n")}
                                    onChange={(event) => {
                                        const list = event.target.value;
                                        setPhonenumList(list.split("\n"));
                                    }}
                                />
                            </div>
                            {submit && phonenumList.length === 0 && (
                                <div className="flex flex-row items-center px-2 pb-2 justify-end">
                                    <i className="text-sm fa-solid fa-triangle-exclamation text-red-600 text-sm pr-2"/>
                                    <p className="text-sm text-red-600">Required</p>
                                </div>
                            )}
                        </div>
                        <FormButtons
                            onSave={handleSave}
                            onCancel={() => {
                                setOpen(false);
                                setSubmit(false);
                            }}
                            type={"button"}
                        />
                    </div>
                }/>
        </>
    );
});
