import React from 'react';
import {observer} from "mobx-react-lite";
import {useNavigate} from "react-router-dom"
import {Typography} from "@mui/material";

const features = [
    {
        name: "Bulk Task Assign",
        icon: "fas fa-cubes",
        path: "bulktaskassign"
    },
    {
        name: "Rajasthan Analytical Report",
        icon: "fas fa-chart-pie",
        path: "analyticalreport"
    },
    {
        name: "Field Summary",
        icon: "fas fa-snowflake",
        path: "fieldsummary"
    },
    {
        name: "Social Media Tracker",
        icon: "fa-solid fa-chart-column",
        path: "socialmediatracker"
    }
]

export const AdHocHome = observer(props => {

    const navigate = useNavigate();

    return (
        <>
            <div className="flex flex-col p-4">
                <Typography color={"primary"} variant={"h4"}>Adhoc Features</Typography>
                <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 gap-2">
                    {
                        features.map(feature => {
                            return (
                                <div
                                    key={feature.path}
                                    className="p-2 cursor-pointer"
                                    onClick={(e) => navigate(feature.path)}
                                >
                                    <div
                                        className="p-4 flex flex-nowrap flex-row shadow-md rounded-lg bg-white hover:shadow-xl items-center">
                                        <div
                                            className="rounded-full bg-blue-200 text-4xl h-20 w-20 flex items-center justify-center">
                                            <i className={feature.icon}/>
                                        </div>
                                        <div className="text-lg ml-4 font-bold">{feature.name}</div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
})