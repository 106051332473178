import 'reflect-metadata';
import 'es6-shim';
import {plainToInstance} from "class-transformer";


export class FlexiStoreTemplate_Abridged {
    public id: number;
    public key: string;
    public renderkey: string;
    public summary: Map<any, any>;

}

export class FlexiStoreTemplate {
    public id: number;
    public key: string;
    public renderkey: string;
    public summary: Map<any, any>;
    public shared: Map<any, any>;
    public options: Map<any, any>;
    public r1: Map<any, any>;
}

export class FlexiStoreData_Abridged {
    public id: number;
    public key: string;
    public renderkey: string;
    public summary: Map<any, any>;
    public created: string;
    public modified: string;
}

export class FlexiStoreData {
    public id: number;
    public projectid: number;
    public key: string;
    public renderkey: string;
    public summary: Map<any, any>;
    public created: string;
    public modified: string;
    public r1: Map<any, any>;
    public r2: Map<any, any>;
    public r3: Map<any, any>;
    public r4: Map<any, any>;
    public r5: Map<any, any>;
    public r6: Map<any, any>;
    public r7: Map<any, any>;
    public r8: Map<any, any>;
    public r9: Map<any, any>;
    public r10: Map<any, any>;
    public r11: Map<any, any>;
    public r12: Map<any, any>;
    public fst: FlexiStoreTemplate;

    setFST(fst_json_to_set) {
        this.fst = plainToInstance(FlexiStoreTemplate, fst_json_to_set);
    }
}
