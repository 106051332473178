import {observer} from "mobx-react-lite";
import React from "react";
import {useStore} from "../../../helpers/helpers";
import {htmlValidation} from "../../../helpers/utils";


const VoterReachOutListing = observer((props: any) => {
    const rootStore = useStore();
    const {voterReachOutStore} = rootStore
    const {reachOutList} = voterReachOutStore
    const {setPopOver, deleteMessage} = props
    const editMessage = (message) => {
        if (message.channel === "sms") {
            voterReachOutStore.set_messageType('Text Messages')
            voterReachOutStore.set_formType('update')
            voterReachOutStore.set_isSideOverlay(true)
            voterReachOutStore.set_textMessageFormValue({...message.ds, id: message.id})
            setPopOver(false)
        } else if (message.channel === "voicecall") {
            voterReachOutStore.set_messageType('Voice Messages')
            voterReachOutStore.set_formType('update')
            voterReachOutStore.set_isSideOverlay(true)
            voterReachOutStore.set_voiceMessageFormValue({...message.ds, id: message.id})
            setPopOver(false)
        } else if (message.channel === "wa") {
            voterReachOutStore.set_messageType('whatsapp Messages')
            voterReachOutStore.set_formType('update')
            voterReachOutStore.set_isSideOverlay(true)
            voterReachOutStore.set_whatsappMessageFormValue({...message.ds, id: message.id})
            setPopOver(false)
        }
    }

    return (
        <div>
            <div className="overflow-hidden my-2">
                <ul role="list" className=" grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 gap-4">
                    {reachOutList.length > 0 ?
                        reachOutList.map((item) => (
                            <li key={item.id}>

                                <div className="flex items-center p-4 sm:px-6 cursor-pointer bg-white hover:bg-gray-50 rounded-lg shadow-lg" onClick={(e) => {
                                    htmlValidation(e)
                                    setPopOver(false)
                                    voterReachOutStore.set_IsMessageOverviewOpen(true)
                                    voterReachOutStore.set_selected_overview_item(item)
                                }}>
                                    <div className="min-w-0 flex-1 flex items-center">
                                        <div className="flex-shrink-0">
                                            {item.channel === "sms" &&
                                            <div className='rounded-full bg-blue-200 text-4xl h-20 w-20 flex items-center justify-center text-white'>
                                                <i className="fa fa-message" aria-hidden="true"/>
                                            </div>
                                            }
                                            {item.channel === "voicecall" &&
                                            <div className='rounded-full bg-blue-200 text-4xl h-20 w-20 flex items-center justify-center' style={{color: "mediumvioletred"}}>
                                                <i className="fa fa-microphone" aria-hidden="true"/>
                                            </div>
                                            }
                                            {item.channel === "wa" &&
                                            <div className='rounded-full bg-blue-200 text-4xl h-20 w-20 flex items-center justify-center text-green-600'>
                                                <img src="/images/whatsapp.png" style={{width: 40}}/>

                                            </div>
                                            }

                                           {item.channel === "sm" &&
                                            <div className='rounded-full bg-blue-200 text-4xl h-20 w-20 flex items-center justify-center text-green-600'>
                                                <img src="/images/facebook.png " style={{width: 40}}/>

                                            </div>
                                            }
                                        </div>
                                        <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-1 md:gap-2">
                                            <div>
                                                <p className="text-md font-medium text-indigo-600  font-bold pb-3">
                                                    {item.ds.name}
                                                </p>
                                                {item.channel !== "voicecall" && <div className="flex flex-row overflow-hidden text-truncate text-nowrap"
                                                >
                                                    <p className="flex items-center text-sm text-gray-500 mr-2 ">

                                                        <span className="truncate d-inline-block tooltip font-medium">
                                                            {item.ds.text}
                                                        </span>
                                                    </p>
                                                </div>}
                                                {
                                                    item.channel === "voicecall" && <div className="w-11/12 h-5">
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="flex flex-row justify-between pb-11" style={{zIndex: 0}}>
                                            <span className="text-indigo-800" onClick={(e) => {
                                                htmlValidation(e)
                                                editMessage(item)
                                            }}>
                                                <i className="fa-solid fa-pen-to-square mx-2"/>
                                            </span>
                                            <span className="text-red-600 pl-2" onClick={(e) => {
                                                htmlValidation(e)
                                                deleteMessage(item.id)
                                            }}>
                                                <i className="fa-solid fa-trash"/>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </li>

                        ))
                        : <div className='italic text-gray-500 text-md p-4'>No messages found....</div>}
                </ul>
            </div>
        </div>
    )
})
export default VoterReachOutListing;