export const excel_user_col_def = [
    {
        label: "User ID",
        key: "id",
    },
    {
        label: "Phone Number",
        key: "phonenum",
    },
    {
        label: "Name",
        key: "name",
    },
    {
        label: "Role",
        key: "role",
    },
];

export const map_roles_by_key = {
    "": "Select role",
    root: "Root",
    client: "Client",
    partyworker: "Party worker",
    surveyworker: "Field Worker",
    qaworker: "QA Worker",
};

export const groupObjectsByKey = (users) => {
    if(users && users.length <= 0) return null;
    return users?.reduce(function (r, a) {
        r[a.role] = r[a.role] || [];
        r[a.role].push(a);
        return r;
    }, Object.create(null));
}