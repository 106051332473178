import React, {useState, useEffect} from "react";
import {useStore} from "../../../helpers/helpers";
import {PlusIcon as PlusIconOutline, TrashIcon, PencilIcon} from "@heroicons/react/24/outline";
import {DeleteModal, LoadingState, SideOverlay} from "../../../components";
import AddBeneficiaryStatus from "./add-beneficiary-status";
import {observer} from "mobx-react-lite";
import {toast} from "react-toastify";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import { useParams } from "react-router-dom";

const BeneficiaryStatus = observer(() => {

    const { beneficiaryStore, flexiStore} = useStore();
    const {statusList, addstatus, status_delete} = beneficiaryStore;
    const [selectedStatus, setSelectedStatus] = useState(statusList[0] || null);
    const queryClient = useQueryClient();
    const { projectid } = useParams();
    const { loading } = flexiStore;

    const fetchBeneficiaryStatus = async () => {
        return await beneficiaryStore.fetch_beneficiarystatus(projectid);
    };

    const { isLoading } = useQuery(
        {
            queryKey: ["beneficiaray-status", projectid],
            queryFn: fetchBeneficiaryStatus,
            refetchOnWindowFocus: false,
            onSuccess: data => {
                if (data?.data?.length > 0) {
                    beneficiaryStore.setStatusList(data.data);
                }
            }
        }
    );

    const handleStatusClick = (status) => {
        setSelectedStatus(status);
    };

    const {mutate} = useMutation({
        mutationFn: async (id: any) => {
            await beneficiaryStore.remove_beneficiary_status({id});
        },
        onSuccess: async () => {
            setTimeout(async () => {
                await queryClient.invalidateQueries(["beneficiaray-status", projectid])
            }, 1000);
            toast.success("Status Deleted Successfully");
            beneficiaryStore.update_status_delete(false);
        },
        onError: async (error) => {
            toast.error("Something went wrong");
        }
    })

    const editStatus = async(id) => {
        beneficiaryStore.setAddStatus(true);
        beneficiaryStore.setActiveStatus(selectedStatus);
    }

    const onCancel = () => {
        beneficiaryStore.update_status_delete(false);
    };

    const handleAddStatus = () => {
        setSelectedStatus(null); 
        beneficiaryStore.setActiveStatus(null); 
        beneficiaryStore.setAddStatus(true);
    };

    useEffect(() => {
        if (statusList.length > 0 && !selectedStatus) {
            setSelectedStatus(statusList[0]);
        }
    }, [statusList, selectedStatus]);

    if (isLoading) return <LoadingState />;

    const descriptionstyle = "flex flex-col items-start p-4 bg-white rounded-lg";

    return (
        <>
            {loading && <LoadingState/>}
            <div className="flex flex-row w-full">
                <div className="flex flex-row w-full">
                    <div className="py-4 w-1/3 flex-shrink-0">
                        <div className="flex flex-row justify-between gap-4">
                            <h4 className="m-2 text-xl text-cyan-600 font-bold border-b border-gray-300 pb-2">
                                Status List
                            </h4>
                            <button
                                type="button"
                                className="items-center px-2 py-2 m-2 border border-transparent rounded-full shadow-sm text-white text-xl bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                onClick={handleAddStatus}
                            >
                                <PlusIconOutline className="h-5 w-5" aria-hidden="true"/>
                            </button>
                        </div>
                        {statusList.length > 0 ? <ul>
                            {statusList.map((status, index) => {
                                return (
                                    <li
                                        key={index}
                                        className={`text-md font-semibold text-black-600 p-4 cursor-pointer hover:bg-white ${selectedStatus === status ? 'bg-white' : ''}`}
                                        onClick={() => handleStatusClick(status)}
                                    >
                                        {status.status?.name}
                                    </li>
                                )
                            })}
                        </ul> : <p className="p-4 text-xl text-gray-500 italic">No Status are available !!</p>}
                    </div>

                    <div className="sm:w-2/3 py-4 bg-white min-h-[75vh]">
                        {selectedStatus && selectedStatus.status &&
                            <div className=" p-3 rounded-lg w-full">
                                <div className="flex flex-row justify-between">
                                    <h2 className="text-xl font-bold mb-4 mx-4">
                                        {selectedStatus.status.name}
                                    </h2>
                                    <div className="flex flex-row gap-x-2">
                                        <PencilIcon
                                            className="h-5 w-5 text-blue-500 cursor-pointer"
                                            onClick={editStatus}
                                        />
                                        <TrashIcon
                                            className="h-5 w-5 text-red-500 cursor-pointer"
                                            onClick={() => {
                                                beneficiaryStore.update_status_delete(true);
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="mb-4">
                                    <p className={descriptionstyle}>Name : {selectedStatus.status.name}</p>
                                    <p className={descriptionstyle}>Type : {selectedStatus.status.type}</p>
                                    <p className={descriptionstyle}>Display 1 : {selectedStatus.status.display1}</p>
                                    <p className={descriptionstyle}>Display 2 : {selectedStatus.status.display2}</p>
                                    <p className={descriptionstyle}>Default : {selectedStatus.status.default ? 'True' : 'False'}</p>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <SideOverlay
                onClose={() => beneficiaryStore.setAddStatus(false)}
                show={addstatus}
                children={<AddBeneficiaryStatus/>}
                title={"ADD NEW STATUS"}
            />
            {status_delete && (
                <DeleteModal
                    onDeleteClick={() => mutate(selectedStatus.id)}
                    onCancelClick={onCancel}
                    isOpen={status_delete}
                    id={selectedStatus?.id}
                    desc={"Do you want to delete status"}
                />
            )}
        </>
    );
});

export default BeneficiaryStatus;
