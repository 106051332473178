import * as Yup from 'yup';

export const addProjectWorkValidations = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    description: Yup.string().required('Description is required'),
    startDate: Yup.string().required('Start Date is required'),
    estimatedCompletionDate: Yup.string().required('Estimated Completion Date is required'),
    sublocation: Yup.string(),
    booth: Yup.array().required("Please select Booth/Village"),
    status: Yup.string().required('Status is required'),
    latitude: Yup.number().required('Latitude is required'),
    longitude: Yup.number().required('Longitude is required'),
    coverPhotoPath: Yup.mixed().test("file", "Please select a photo", (value) => {
        console.log(value)
        return value["length"] > 0;
    })
})