import React, {useState} from 'react'
import {Avatar} from "@mui/material";
import {useWindowSize} from "react-use";
import ReactGA from 'react-ga4';

export const BYRBJP = () => {

    const {width} = useWindowSize();
    const isMobile = width <= 480;

    const [osName, setOsName] = useState("Not detected")

    React.useEffect(() => {
        if (navigator.platform.indexOf("Android") != -1) setOsName("Android")
        if (navigator.platform.indexOf("iPhone") != -1) setOsName("IOS")
    }, []);

    const handleClick = (platform) => {
        ReactGA.event({
            category: 'Social Links',
            action: 'Click',
            label: platform,
        });
    };


    return (
        <>
            <div className="relative h-screen">
                <div className="absolute inset-0 bg-gradient-to-r from-amber-300 via-orange-400 to-orange-400">
                    <img
                        src="/images/byr-collage.png"
                        alt="BYR"
                        className="object-cover w-full h-full"
                        style={{
                            opacity: "0.1"
                        }}
                    />
                </div>
                <div className="absolute inset-0 flex justify-center">

                    <div className={'absolute top-0 right-0 p-2'}>
                        <Avatar
                            className={'border-gray-200 border-2 rounded-full shadow-2xl'}
                            variant={"circular"}
                            alt="BYR"
                            src="/images/BJP.png"
                            sx={{width: isMobile ? 60 : 120, height: isMobile ? 60 : 120}}
                        />

                    </div>
                    <div className="mx-auto flex flex-col justify-center items-center">
                        <Avatar
                            className={'border-gray-200 border-2 rounded-full shadow-2xl '}
                            variant={"circular"}
                            alt="BYR"
                            src="/images/byr.png"
                            sx={{width: isMobile ? 180 : 280, height: isMobile ? 180 : 280}}
                        />
                        <div className="mt-3 sm:mt-6 max-w-2xl lg:mt-0 xl:col-end-1 xl:row-start-1">
                            <p className={'text-4xl sm:text-5xl p-2 sm:pt-8 sm:px-4 sm:pb-2 font-black text-center text-gray-800 uppercase font-anek'}>ಬಿ.ವೈ.
                                ರಾಘವೇಂದ್ರ</p>
                            <p className="text-lg leading-8 px-2 text-center font-bold text-gray-800 font-anek">
                                <span className={'uppercase'}>ಸಂಸದರು <br/> ಶಿವಮೊಗ್ಗ ಲೋಕಸಭಾ ಕ್ಷೇತ್ರ</span>
                                {/*<span className={'uppercase'}>Member of Parliament <br/> Shivamogga Lok Sabha Constituency </span>*/}
                            </p>


                            <div className="mt-4 sm:mt-8 flex items-center justify-evenly flex-wrap px-2 sm:px-3">
                                <p className="text-lg leading-8 p-2 text-center font-bold text-gray-800 font-anek">
                                    ಈ ಐಕಾನ್‌ಗಳನ್ನು ಕ್ಲಿಕ್ ಮಾಡಿ ನನ್ನನ್ನು ಫಾಲೋ ಮಾಡಿ
                                </p>
                                <div className="flex items-center justify-evenly flex-wrap gap-2 sm:gap-6 px-2 sm:px-3">
                                    <a href={osName === "IOS" ? "fb://profile/652130624882978" : "https://www.facebook.com/BYRBJP"}
                                       target={'_blank'} title={'Facebook'}
                                       onClick={() => handleClick("Facebook")}
                                    >
                                        <img src={'/images/social/facebook.png'} className={'h-16 w-16 sm:h-20 sm:w-20'}/>
                                    </a>
                                    <a href={'https://whatsapp.com/channel/0029Va5sVt6HAdNXwAHeHs3y'} target={'_blank'}
                                       title={'WhatsApp Channel'}
                                       onClick={() => handleClick("Whatsapp")}
                                    >
                                        <img src={'/images/social/whatsapp.png'} className={'h-16 w-16 sm:h-20 sm:w-20'}/>
                                    </a>
                                    <a href={'https://www.youtube.com/@BYRaghavendraBJP'} target={'_blank'}
                                       title={'YouTube'}
                                       onClick={() => handleClick("Youtube")}
                                    >
                                        <img src={'/images/social/youtube.png'} className={'h-16 w-16 sm:h-20 sm:w-20'}/>
                                    </a>
                                    <a href={'https://www.instagram.com/byrbjp/'} target={'_blank'}
                                       title={'Instagram'}
                                       onClick={() => handleClick("Instagram")}
                                    >
                                        <img src={'/images/social/instagram.png'} className={'h-16 w-16 sm:h-20 sm:w-20'}/>
                                    </a>
                                    <a href={'https://twitter.com/BYRBJP'} target={'_blank'}
                                       title={'Twitter'}
                                       onClick={() => handleClick("Twitter")}
                                    >
                                        <img src={'/images/social/twitter1.png'} className={'h-16 w-16 sm:h-20 sm:w-20'}/>
                                    </a>
                                    <a href={'https://n-m4.in/d14j'} target={'_blank'}
                                       title={'ನಮೋ ಆಪ್ - ಭಾರತವನ್ನು ಪರಿವರ್ತಿಸುವ ಪ್ರಯಾಣದಲ್ಲಿ ನಮೋ ಆಪ್‌ನಲ್ಲಿ ನನ್ನೊಂದಿಗೆ ಸೇರಿ. Playstore ಅಥವಾ AppStore ನಿಂದ ಅಪ್ಲಿಕೇಶನ್ ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ'}
                                       onClick={() => handleClick("Modi App")}
                                    >
                                        <img src={'/images/namo_app.png'} className={'mx-2 h-20 w-20 sm:h-20 sm:w-20'}/>
                                    </a>
                                </div>

                            </div>

                            {/*<div className='flex flex-col py-2 sm:py-4 mx-auto justify-center items-center'>
                                <p className="text-lg leading-8 p-2 text-center font-bold text-gray-800 font-anek">ಭಾರತವನ್ನು ಪರಿವರ್ತಿಸುವ ಪ್ರಯಾಣದಲ್ಲಿ ನಮೋ ಆಪ್‌ನಲ್ಲಿ ನನ್ನೊಂದಿಗೆ ಸೇರಿ. <br/>
                                    Playstore ಅಥವಾ AppStore ನಿಂದ ಅಪ್ಲಿಕೇಶನ್ ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ</p>
                                <div className="mt-4 flex items-center justify-around flex-wrap gap-4 sm:gap-6 px-3">
                                    <a href={'https://play.google.com/store/apps/details?id=com.narendramodiapp'} target={'_blank'}>
                                        <img src={'/images/social/playstore.png'} className={'h-10 w-32 sm:h-18 sm:w-full'}/>
                                    </a>
                                    <a href={'https://apps.apple.com/in/app/narendra-modi/id1016781909'} target={'_blank'}>
                                        <img src={'/images/social/appstore.png'} className={'h-10 w-32 sm:h-18 sm:w-full'}/>
                                    </a>
                                </div>
                            </div>*/}

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}