import React from "react";
import {useStore} from "../../helpers/helpers";
import {observer} from "mobx-react-lite";
import {useWindowSize} from "@withvoid/melting-pot";
import {useTranslation} from "react-i18next";
import {VoterCard} from "../manage-voters";
import {FormButtons} from "../../components/cm-components";

export const VoterListWithCheckBox = observer((props:any) => {

    const {voterListStore} = useStore()
    const {Voterlist} = voterListStore;
    const {height} = useWindowSize();
    const {onSelection, onSave, onCancel, selected} = props;
    const[t]=useTranslation("voter_list");

    const isChecked = data => {
        if(selected && selected.length > 0){
            const index = selected.findIndex(x => x.id === data.id)
            return index !== -1
        }
    }
    return (
        <section>
            <div className="overflow-x-hidden overflow-y-auto" style={{height: height * 0.75}}>
                {Voterlist && Voterlist.length > 0 && Voterlist.map((data) => {
                    return (
                        <div key={data["id"]} className="relative mb-2 voter-list">
                            <input
                                type="checkbox"
                                onChange={() =>
                                    { onSelection(data)

                                    }}
                                className={"absolute top-8 right-2 p-2 w-8 h-8 bg-red-600 chkbox"}
                                defaultChecked={isChecked(data)}

                                // checked={isChecked(data)}
                            />
                            <VoterCard voter={data}/>
                        </div>
                    );
                })}
            </div>

            <FormButtons
                onSave={onSave}
                onCancel={onCancel}
            />

            {/*<div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">*/}
            {/*    <div className="flex justify-end space-x-3">*/}
            {/*        <button*/}
            {/*            type="button"*/}
            {/*            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"*/}
            {/*            onClick={onCancel}*/}
            {/*        >*/}
            {/*            {t("cancel")}*/}
            {/*        </button>*/}
            {/*        <button*/}
            {/*            type="submit"*/}
            {/*            className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"*/}
            {/*            onClick={onSave}*/}
            {/*        >*/}
            {/*            {t("save")}*/}
            {/*        </button>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </section>
    )
})