import {action, makeAutoObservable} from "mobx";


export class FieldIntelligenceStore {
    rootStore: any;

    addNewModal: boolean = false;
    activeNode: any = {};
    activeRow: any = {};

    constructor(rootStore: any) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    @action
    update_FiStoreProp(prop, value){
        switch (prop) {
            case "addNewModal":
                this.addNewModal = value;
                break;
            case "activeNode":
                this.activeNode = value;
                break;
            case "activeRow":
                this.activeRow = value;
                break;
        }
    }


}