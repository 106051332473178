import React, {useState} from "react"
import {useStore} from "../helpers";
import {useTranslation} from "react-i18next";
import {useQuery} from "@tanstack/react-query";
import {VortexSpinner} from "../features/digital-repository";

type ViewAttachementsProps = {
    attachment: any,
    s3_bucket?: string
    label?: boolean,
    backgroundColor?: string,
    styles?: Object
}

export const ViewAttachment = (props: ViewAttachementsProps) => {
    const rootStore = useStore();
    const {attachment, label = true, s3_bucket = "urn-assets"} = props
    const fetch_s3uri = async (path) => {
        if (!path) return '';
        return await rootStore.get_s3_url(s3_bucket, path)
    }
    const type = attachment.type?.split('/')[0]
    const name = attachment.path?.split('/')[attachment.path.split('/').length - 1]
    const [resuri, set_resuri] = useState('')
    const {t} = useTranslation("components");

    const title_style = "font-bold py-2 text-black";

    const resolve_uri = async () => {
        const uri = await fetch_s3uri(attachment.path)
        set_resuri(uri)
        return uri
    }

    const {isLoading} = useQuery({
        queryKey: ['attachments', attachment.path],
        queryFn: resolve_uri,
        onSuccess: data => {
            set_resuri(data)
        }
    })

    return (
        <>
            <VortexSpinner visible={isLoading}/>
            <div key={attachment.path}>
                {type === "image" && <>
                    <img style={props.styles} src={resuri}/>
                    {label && <p className={title_style}>{name}</p>}
                </>}
                {type === "audio" &&
                <audio controls>
                    <source src={resuri}/>
                    {label && <p className={title_style}>{name}</p>}
                    {t("not_support_audio")}
                </audio>}
                {type === "video" && <video width={320} height={240} controls>
                    <source
                        src={resuri}/>
                    {label && <p className={title_style}>{name}</p>}
                    {t("not_support_audio")}
                </video>}
                {(type === "application") && <div className={`flex flex-row items-center ${title_style} cursor-pointer`}
                                                  onClick={() => window.open(resuri, "_blank")}>
                    <div><i className="fa-solid fa-file text-red-600"/></div>
                    <div className="ml-2">{name}</div>
                </div>}
            </div>
        </>
    )
}

export const ViewAttachments = props => {
    const {data} = props;
    return (
        <>
            {
                data.map((x) => {
                    return (
                        <ViewAttachment key={x.path} attachment={x}/>
                    )
                })
            }
        </>
    )
}

