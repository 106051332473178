import React from "react"

const VoterReachOutCampaignOverview = (props) => {

    const {data} = props

    return (
        <>
            <div className="flex flex-col items-center cursor-pointer rounded-lg">
                <div className="flex flex-col w-full justify-between">
                    <div className="w-1/2 mt-2">
                        <p className="text-md font-medium text-indigo-600  font-bold pb-3 ">Name </p>
                    </div>
                    <div className="w-100">
                        <p className="text-md font-medium text-black-600  font-normal pb-3">{data.name}</p>
                    </div>
                </div>
                <div className="flex flex-col w-full justify-between">
                    <div className="w-1/2  ">
                        <p className="text-md font-medium text-indigo-600  font-bold pb-3">Messages</p>
                    </div>
                    <div className="w-1/2">
                        <p className="text-md font-medium text-black-600  font-normal pb-3 break-words">{data?.msg?.ds?.text}</p>
                    </div>
                </div>
               <div className="flex flex-col w-full justify-between">
                    <div className="w-1/2  ">
                        <p className="text-md font-medium text-indigo-600  font-bold pb-3">Target</p>
                    </div>
                    <div className="w-1/2">
                        <p className="text-md font-medium text-black-600  font-normal pb-3 break-words">{data?.vrot?.name}</p>
                    </div>
                </div>

                <div className="flex flex-col w-full justify-between">
                    <div className="w-1/2  ">
                        <p className="text-md font-medium text-indigo-600  font-bold pb-3">Status</p>
                    </div>
                    <div className="w-full">
                        <p className="text-md font-medium text-black-600  font-normal pb-3 break-words">{data?.status}</p>
                    </div>
                </div>
                
            </div>
        </>
    )
}

export default VoterReachOutCampaignOverview;