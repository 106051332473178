import React, {useMemo} from "react";
import {observer} from "mobx-react-lite";
import {useStore} from "../../../helpers/helpers";
import _ from "lodash";
import {Table} from "./table";
import {toJS} from "mobx";
import {TableHeader} from "./survey-summary";
import { useTranslation } from "react-i18next";
import {DownloadAsExcelSheet} from "../../../components";


export const FieldSummaryForRohan = observer((props:any) => {

    const [t] = useTranslation(["survey_management" , "analytical_summary"])

    const excel_col_def = [
        {
            "label":t("name"),
            "key":"surveyor_name"
        },{
            "label":t("booth #"),
            "key":"piv_level2"
        },{
            "label":t("date"),
            "key":"dt_month_date"
        },{
            "label":t("valid"),
            "key":"valid"
        }, {
            "label":t("invalid"),
            "key":"invalid"
        },{
            "label":t("not_checked"),
            "key":"not-checked"
        },{
            "label":t("total"),
            "key":"total"
        }
    ]

    const {surveyResponseStore, userStore} = useStore();
    const {allSurveyResponsesSummary} = surveyResponseStore;


    const data = useMemo(() => toJS(allSurveyResponsesSummary)["by_piv_level2_surveyor_id_qa_status"], [allSurveyResponsesSummary]);

    const columns = useMemo(() => [
        {
            Header: t("name"),
            accessor: 'surveyor_name',
            Footer: t("total")
        }, {
            Header: t("booth_#"),
            accessor: 'piv_level2'
        },{
            Header: t("date",{ns:"analytical_summary"}),
            accessor: 'dt_month_date'
        },{
            Header: t("valid"),
            accessor: 'valid',
            Footer: surveyResponseStore.get_total_surveys(data, "valid")
        }, {
            Header: t("invalid"),
            accessor: 'invalid',
            Footer: surveyResponseStore.get_total_surveys(data, "invalid")
        }, {
            Header: t("n_c"),
            accessor: 'not-checked',
            Footer: surveyResponseStore.get_total_surveys(data, "not-checked")
        },{
            Header: t("total"),
            accessor: 'total',
            Footer: surveyResponseStore.get_total_surveys(data, "total")
        }

    ], [allSurveyResponsesSummary]);

    if (_.isEmpty(allSurveyResponsesSummary["by_piv_level2_surveyor_id_qa_status"]) || userStore.is_field_worker()) return null;

    const row_click_handler = (x) => {
        surveyResponseStore.update_new_filters("piv_level2", x["piv_level2"], `Booth : ${x['piv_level2']}`);
    }

    return (
        <div className="flex flex-col">
            <div className="flex flex-row items-center justify-between">
                <TableHeader title={t("rohan")}/>
                {(userStore.isRoot()||userStore.isClient()) && <DownloadAsExcelSheet data={data} cols={excel_col_def} name="Field-Summary"/>}
            </div>

            <Table data={data} columns={columns} onRowClick={row_click_handler}/>
        </div>
    )
})