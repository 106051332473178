import {observer} from "mobx-react-lite";
import React, {useEffect, useState} from "react";
import {htmlValidation, TextError} from "../../helpers/utils";
import Autocomplete from "@mui/material/Autocomplete";
import {safe_get} from "../../report-visuals/report-utils";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {useStore} from "../../helpers/helpers";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {error_toast} from "../../helpers/file-upload";
import {useTranslation} from "react-i18next";

export const AddTeam = observer((props: any) => {
    const rootStore = useStore();
    const params = useParams();
    const {userFilterData, edit} = props;
    const EditedData = userFilterData?.data;
    const EditerName = EditedData?.owner;
    const EditerMembers = EditedData?.members;
    const {userStore, projectStore} = rootStore;
    const {userList} = userStore;
    const [leaderAutoCompleteOptions, setLeaderAutoCompleteOptions] = useState([]);
    const [memberAutoCompleteOptions, setMemberAutoCompleteOptions] = useState([]);
    const [selectedTeamLeader, setSelectedTeamLeader] = useState({} as any);
    const [selectedTeamList, setSelectedTeamList] = useState([]);
    const [EditTeamLeader, setEditTeamLeader] = useState(EditerName);
    const [EditTeamList, setEditTeamList] = useState(EditerMembers);
    const [isSubmit, setSubmit] = useState(false);
    const [t] = useTranslation("user_management");

    const TeamList = projectStore.getProjectProperty("user_hierarchy", {});

    useEffect(() => {
        if (userList && userList.length > 0) {
            setLeaderAutoCompleteOptions(userList);
        }
    }, []);

    useEffect(() => {
        const teamMemberOptions = userList.filter(
            (user) => user.id !== selectedTeamLeader.id
        );
        setMemberAutoCompleteOptions(teamMemberOptions);
    }, [selectedTeamLeader]);

    const sendData = (data: object) => {
        props.setTeamData(data);
    };

    const handleSubmit = async () => {
        setSubmit(true);
        const activeProjectProperties = JSON.parse(
            localStorage.getItem("__active_project")
        );
        const teamLeaderId = EditerName?.id;


        let teamsListObj = {...TeamList};
        edit && delete teamsListObj[teamLeaderId];
        {
            edit
                ? (teamsListObj[EditTeamLeader["id"]] = EditTeamList.map(
                (mem) => mem.id.toString()
                ))
                : (teamsListObj[selectedTeamLeader["id"]] = selectedTeamList.map(
                (mem) => mem.id.toString()
                ));
        }

        if (
            (Object.keys(selectedTeamLeader).length > 0 &&
                selectedTeamList.length > 0) ||
            (edit && EditTeamLeader && EditTeamList.length > 0)
        ) {
            const payload = {
                id: params.projectid,
                properties: {
                    ...activeProjectProperties.properties,
                    user_hierarchy: {
                        type: "json",
                        value: {
                            ...teamsListObj,
                        },
                    },
                },
            };

            const result = await userStore.add_Team(payload);
            if (result.status === 200) {
                sendData(payload.properties.user_hierarchy.value)
                toast.success(t("team_added_successfully"));
                userStore.setAddTeamStatus(false);
            } else {
                error_toast();
                userStore.setAddTeamStatus(false);
            }
        }
    };

    return (
        <>
            <div className="rounded-lg bg-white text-center p-4">
                <div className="form-control p-2 sm:grid sm:grid-cols  sm:items-start d-flex flex-column text-left">
                    <label
                        htmlFor="name"
                        className="block text-gray-700 pt-4 rounded-lg text-left font-semibold"
                    >
                        {t("select_team_lead")}
                    </label>
                    <Autocomplete
                        id="select-user"
                        value={edit ? EditTeamLeader : selectedTeamLeader}
                        options={leaderAutoCompleteOptions}
                        autoHighlight={true}
                        // filterOptions={(options, { inputValue }) => {
                        //   const filteredOptions = options.filter(
                        //     (item) =>
                        //       item["name"].includes(inputValue) ||
                        //       item["phone"].includes(inputValue)
                        //   );
                        // if (filteredOptions.length <= 0) {
                        //     setUserExist(false)
                        // } else {
                        //     setUserExist(true)
                        //   // }
                        //   return filteredOptions;
                        // }}
                        getOptionLabel={(option) => safe_get(option, "name", "")}
                        onChange={(event, v) => {
                            htmlValidation(event);
                            if (v) {
                                setSelectedTeamLeader({...v});
                                setEditTeamLeader({...v});
                            } else {
                                setSelectedTeamLeader({});
                            }
                            // const clone = _.clone(activeTask);
                            // clone["user"] = v;
                            // clone["userid"] = safe_get(v, "id", -1);
                            // setActiveTask(clone);
                        }}
                        renderOption={(props, option) => (
                            <Box
                                key={option["id"]}
                                component="li"
                                sx={{"& > img": {mr: 2, flexShrink: 0}}}
                                {...props}
                            >
                                <img
                                    loading="lazy"
                                    width="40"
                                    src={option["profileimageuri"]}
                                />
                                <div>
                                    <p>{option["name"]}</p>
                                    <p>{option["phonenum"]}</p>
                                </div>
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Select User"
                                onChange={(event) => {
                                    htmlValidation(event);

                                    console.log("event", event.target.value);
                                }}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: t("np"), // disable autocomplete and autofill
                                }}
                            />
                            // <Field component={TextField} name="assignedTo" {...params} className={fieldStyle} />
                        )}
                    />
                    {(!EditedData
                        ? Object.keys(selectedTeamLeader).length <= 0
                        : EditTeamLeader.length <= 0) &&
                    isSubmit && (
                        <div className="flex flex-row">
                            <TextError error={t("select_team_leader")}/>
                        </div>
                    )}
                </div>
                <div className="p-2  sm:grid sm:grid-cols-1 sm:items-start d-flex flex-column text-left">
                    <label
                        htmlFor="message"
                        className="block text-gray-700  rounded-lg text-left font-semibold"
                    >
                        {t("select_team_member")}
                    </label>
                    <Autocomplete
                        multiple={true}
                        id="select-user"
                        value={edit ? EditTeamList : selectedTeamList}
                        options={memberAutoCompleteOptions}
                        autoHighlight={true}
                        // filterOptions={(options, { inputValue }) => {
                        //   const filteredOptions = options.filter(
                        //     (item) =>
                        //       item["name"].includes(inputValue) ||
                        //       item["phone"].includes(inputValue)
                        //   );
                        //   // if (filteredOptions.length <= 0) {
                        //   //     setUserExist(false)
                        //   // } else {
                        //   //     setUserExist(true)
                        //   // }
                        //   return filteredOptions;
                        // }}
                        getOptionLabel={(option) => safe_get(option, "name", "")}
                        onChange={(event, v) => {
                            htmlValidation(event);

                            setSelectedTeamList([...v]);
                            setEditTeamList([...v]);
                        }}
                        renderOption={(props, option) => (
                            <Box
                                key={option["id"]}
                                component="li"
                                sx={{"& > img": {mr: 2, flexShrink: 0}}}
                                {...props}
                            >
                                <img
                                    loading="lazy"
                                    width="40"
                                    src={option["profileimageuri"]}
                                />
                                <div>
                                    <p>{option["name"]}</p>
                                    <p>{option["phonenum"]}</p>
                                </div>
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("select_u")}
                                onChange={(event) => {
                                    htmlValidation(event);
                                    console.log("event", event.target.value);
                                }}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: t("np"), // disable autocomplete and autofill
                                }}
                            />
                            // <Field component={TextField} name="assignedTo" {...params} className={fieldStyle} />
                        )}
                    />
                    {isSubmit &&
                    (edit
                        ? EditTeamList.length <= 0
                        : selectedTeamList.length <= 0) && (
                        <div className="flex flex-row">
                            <TextError error={t("select_m")}/>
                        </div>
                    )}
                </div>
                <div className="mt-5 sm:mt-4 text-center flex flex-row justify-end mx-2">

                    <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                        onClick={() => {
                            userStore.setAddTeamStatus(false);
                        }}
                    >
                        {t("cancel")}
                    </button>

                    <button
                        type="submit"
                        onClick={handleSubmit}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm mx-1 px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                    >
                        {t("save")}
                    </button>
                </div>
            </div>
        </>
    );
});
