import { observer } from "mobx-react-lite";
import React from "react";
import { SideOverlay } from "./side-overlay";
import { useStore } from "../helpers/helpers";
import { useTranslation } from "react-i18next";

const languages = [
    { key: "en", val: "English" },
    { key: "kn", val: "ಕನ್ನಡ" },
    { key: "hi", val: "हिंदी" },
    { key: "tl", val: "తెలుగు" },
    { key: "mr", val: "मराठी" },
    { key: "ta", val: "Tamil" },
    { key: "ml", val: "Malayalam" },
    { key: "gj", val: "Gujarathi" },
]

export const LanguageChange = observer(props => {

    const rootStore = useStore();
    const { i18n } = useTranslation();

    return (
        <>
            <SideOverlay
                children={<>
                    <div className="grid grid-cols-3 gap-4">
                        {languages.map(lang => <button onClick={() => {
                            i18n.changeLanguage(lang.key)
                            rootStore.update_language_Change(false)
                        }} className="btn btn-primary" key={lang.key}>{lang.val}</button>)}
                    </div>
                </>}
                onClose={() => rootStore.update_language_Change(false)}
                show={rootStore.languageChange}
                title="Choose Language"
            />

        </>
    )
})

