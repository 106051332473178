import React, {useEffect, useState} from "react";
import {useStore} from "../../../helpers/helpers";
import _ from "lodash";
import {useIsMobile} from "../../../custom-hooks";
import {IconButton} from "@mui/material";


export const MediaCard = (props: any) => {
    const rootStore = useStore();
    const {media, onMediaEdit, onMediaDelete} = props
    const [imagePath, setImagePath] = useState('')
    const isMobile = useIsMobile();

    const getPath = async () => {
        const imagePath = await rootStore.get_s3_url("urn-assets", media.mediapath)
        setImagePath(imagePath)
    }

    useEffect(() => {
        if (!_.isEmpty(media)) {
            getPath()
        }
    }, []);

    return (
        <>
            <div key={media.id} className="px-4 group relative">
                <img style={{height: 260}} src={imagePath !== '' ? imagePath : ''} />
                {/*</div>*/}
                <div className="media-carousel-action-btn-pane flex flex-row items-center justify-end ">
                    <IconButton
                        onClick={e => onMediaEdit(media)}
                        color={"primary"}
                        size={"small"}
                    >
                        <i className={"fas fa-edit"} />
                    </IconButton>
                    <IconButton
                        onClick={e => onMediaDelete(media.id)}
                        color={"error"}
                        size={"small"}
                    >
                        <i className="fas fa-trash"/>
                    </IconButton>
                </div>
                <div className={"bg-gray-300 opacity-50 rounded-sm"}>
                    <p>{media.title}</p>
                    <p>{media.description}</p>
                </div>
            </div>
        </>
    )
}