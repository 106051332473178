import React from "react";
import {observer} from "mobx-react-lite";
import {useStore} from "../../helpers/helpers";
import {useWindowSize} from "@withvoid/melting-pot";
import ManageVoters from "./manage-voters";
import {useParams} from "react-router";
import {BreadCrumbs, LoadingState, ProjectHeader} from "../../components";

export const ManageVotersdex = observer((props: any) => {

    const {width} = useWindowSize();
    const {projectid} = useParams();
    const {voterReachOutStore} = useStore();
    const {isLoading} = voterReachOutStore;

    const isMobile = width <= 766;

    const crumbs = [
        {name: "DashBoard", href: `/project/${projectid}`, current: true},
        {name: "Manage Voters", href: "#", current: true},
    ];

    return (
        <>
            {isLoading && <LoadingState/>}
            {!isMobile &&
            <>
                <BreadCrumbs crumbs={crumbs}/>
                <ProjectHeader/>
            </>
            }
            <ManageVoters/>
        </>
    );
});
