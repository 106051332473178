import React from "react";
import {safe_get} from "../../report-visuals/report-utils";
import {toJS} from "mobx";
import moment from "moment";
import { useTranslation } from "react-i18next";

export const ApplicationSummary = (props) => {

    const {survey} = props;

    const summary = safe_get(toJS(survey)["sr"], "more.voter_registration_tracker.updates", []);
    const {t} = useTranslation("new_voter_registration");

    if (!summary) return null;

    return (
        <div className="flex flex-col py-4">
            <div className="font-bold text-purple-600">{t("application_summary")}:</div>
            <table className="voter-staus-table rounded-md sm:w-1/2">
                <thead>
                <tr>
                    <th>{t("status")}</th>
                    <th>{t("comments")}</th>
                    <th>{t("updated_by")}</th>
                    <th>{t("time")}</th>
                </tr>
                </thead>
                <tbody>

                {summary.map(x => {
                    return (
                        <tr>
                            <td>{x["status"]}</td>
                            <td>{x["comments"]}</td>
                            <td>{safe_get(x["user"], "name", "")}</td>
                            <td>{moment(x["time"]).format('LLL')}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    )
}