import {observer} from "mobx-react-lite";
import React from "react";
import {RootStore} from "../../../stores/root";
import {useStore} from "../../../helpers/helpers";
import {ACBankDAO} from "../ac-bank/acbank_dao";
import {safe_get} from "../../../report-visuals/report-utils";
import {RenderACName} from "../booth-bank/booth-reports-utils";
import PCTable from "./PCTable";
import PCHistoricalTable from "./PCHistoricalTable";
import {LeafletACMap} from "../../../components";
import _ from 'lodash'

const a4_conatiner = {
    width: "21cm",
    minHeight: "29.7cm"
}

export const LandscapeOrientation = () => (
    <style type="text/css">
        {"@media print{@page {size: landscape}}"}
    </style>
);

export const PortraitOrientation = () => (
    <style type="text/css">
        {"@media print{@page {size: portrait}}"}
    </style>
);

export const PCBankView = observer(props => {
    const rootStore: RootStore = useStore();
    const dao = new ACBankDAO(rootStore);
    const title = safe_get(dao.fsd.summary, "config.title", "")
    const sub_title = safe_get(dao.fsd.summary, "config.subtitle", "");

    const tables = safe_get(dao.fsd.r1, "tables", {})
    const historical_ac_wise = safe_get(dao.fsd.r1, "historical_ac_wise", {})

    return (
        <>
            <div className='print-parent section-to-print'>
                <PortraitOrientation />
                <RenderACName title={title} sub_title={sub_title}/>
                <div className='page-container bg-white mx-auto p-6'>
                    {/*Tables*/}
                    {
                        Object.keys(tables).length > 0 && Object.keys(tables).map(table_key => <PCTable data={dao.fsd.r1["tables"][table_key]}/>)
                    }
                </div>
                {/*Caste Tables*/}
                <div className='my-4 bg-white mx-auto p-6' style={a4_conatiner}>
                    <div className='font-bold text-2xl pb-2 uppercase' style={{pageBreakAfter:"always"}}>Caste Summary</div>
                    {Object.keys(dao.fsd.r1["caste_summary"]).map((key, index) => {
                        return (
                            <div className="my-4 " key={index} style={{pageBreakBefore: "always"}}>
                                <div className={"font-bold text-purple-600 text-xl"}>{key}</div>
                                <table className='fsd-caste-details'>
                                    <thead>
                                    <tr className='font-bold bg-gray-200'>
                                        <th className='text-left first'>Caste</th>
                                        <th className='text-right second'>Voters</th>
                                        <th className='text-right third'>Voters%</th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    {
                                        dao.fsd.r1["caste_summary"][key].map(row => {
                                            const {level, display, voters, voters_percent} = row
                                            return (
                                                <tr className={`level-${level}`}>
                                                    <td className='text-left first'>{display}</td>
                                                    <td className='text-right second'>{voters}</td>
                                                    <td className='text-right third'>{voters_percent}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </table>
                            </div>
                        )
                    })}
                </div>
                {/*Maps*/}
                <div className='bg-white mx-auto p-6' style={a4_conatiner}>
                    <div className='font-bold text-2xl pb-2 uppercase' style={{pageBreakAfter:"always"}}>Maps</div>
                    {Object.keys(dao.fsd.r2["maps"]).length > 0 &&
                    Object.keys(dao.fsd.r2["maps"]).map((key,index) => {
                        const map_data = safe_get(dao.fsd.r2["maps"], `${key}`, {});
                        const map_config = {
                            height: '850px',
                            show_booth_numbers: false,
                            show_color_mapping: false
                        }
                        const color_mapping = map_data["color_mapping"];
                        return (
                            <div style={{pageBreakBefore: "always"}} key={index}>
                                <div className='font-bold text-purple-600 text-xl'>{key}</div>
                                <LeafletACMap geojson={map_data} config={map_config}/>
                                <div className='p-2 legend flex flex-row flex-wrap justify-center'>
                                    {color_mapping &&
                                    Object.keys(color_mapping).map(x => {
                                        const clr = color_mapping[x]
                                        return (
                                            <div className='flex flex-col items-center mx-2'>
                                                <div style={{backgroundColor: clr, width: 40, height: 20}}/>
                                                <div className='ml-2'>{x}</div>
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                            </div>
                        )
                    })
                    }
                </div>
                {/*AC Wise Historical Data */}
                <div className='bg-white mx-auto p-6 print-landscape' >
                   {/*<LandscapeOrientation />*/}
                    {!_.isEmpty(historical_ac_wise) && Object.keys(historical_ac_wise).length > 0 && Object.keys(historical_ac_wise).map((table_key, index) => {
                        return(
                            <div className=" ">
                                <PCHistoricalTable key={index} data={historical_ac_wise[table_key]}/>
                            </div>
                        )
                    })}
                </div>

                <>
                    {Object.keys(dao.fsd.r3).map(ac => {
                        const ds = dao.fsd.r3[ac];
                        if (ds.length == 0) return null;
                        console.log('Rendering ', ac)


                        return (
                            <>
                                <div className='font-bold text-lg text-purple-600 pt-2'>{ac}</div>

                                {ds.map(village=>{
                                    const caste = village['caste']
                                    const education = village['education']
                                    const lang = village['lang']
                                    const income = village['income']

                                    return (
                                        <div className='my-4 bg-white mx-auto p-6' style={a4_conatiner}>
                                            <div className='font-bold text-md text-black-400 pt-2'>{village['title']}</div>

                                            <div className='font-bold text-md pb-2' style={{pageBreakAfter:"always"}}>Caste</div>
                                            <div className="my-4 " style={{pageBreakBefore: "always"}}>
                                                <table className='fsd-caste-details'>
                                                    <thead>
                                                    <tr className='font-bold bg-gray-200'>
                                                        <th className='text-left first'>Caste</th>
                                                        <th className='text-right second'>Voters</th>
                                                        <th className='text-right third'>Voters%</th>
                                                    </tr>
                                                    </thead>

                                                    <tbody>
                                                    {
                                                        caste.map(row => {
                                                            const {level, display, voters, voters_percent} = row
                                                            return (
                                                                <tr className={`level-${level}`}>
                                                                    <td className='text-left first'>{display}</td>
                                                                    <td className='text-right second'>{voters}</td>
                                                                    <td className='text-right third'>{voters_percent}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className='font-bold text-md pb-2' style={{pageBreakAfter:"always"}}>Education</div>
                                            <div className="my-4 " style={{pageBreakBefore: "always"}}>
                                                <table className='fsd-caste-details'>
                                                    <thead>
                                                    <tr className='font-bold bg-gray-200'>
                                                        <th className='text-left first'>Education</th>
                                                        <th className='text-right second'>Voters</th>
                                                        <th className='text-right third'>Voters%</th>
                                                    </tr>
                                                    </thead>

                                                    <tbody>
                                                    {
                                                        education.map(row => {
                                                            return (
                                                                <tr>
                                                                    <td className='text-left first'>{row[0]}</td>
                                                                    <td className='text-right second'>{row[1]}</td>
                                                                    <td className='text-right third'>{row[2]}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className='font-bold text-md pb-2' style={{pageBreakAfter:"always"}}>Income</div>
                                            <div className="my-4 " style={{pageBreakBefore: "always"}}>
                                                <table className='fsd-caste-details'>
                                                    <thead>
                                                    <tr className='font-bold bg-gray-200'>
                                                        <th className='text-left first'>Income</th>
                                                        <th className='text-right second'>Voters</th>
                                                        <th className='text-right third'>Voters%</th>
                                                    </tr>
                                                    </thead>

                                                    <tbody>
                                                    {
                                                        income.map(row => {
                                                            return (
                                                                <tr>
                                                                    <td className='text-left first'>{row[0]}</td>
                                                                    <td className='text-right second'>{row[1]}</td>
                                                                    <td className='text-right third'>{row[2]}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className='font-bold text-md pb-2' style={{pageBreakAfter:"always"}}>Language</div>
                                            <div className="my-4 " style={{pageBreakBefore: "always"}}>
                                                <table className='fsd-caste-details'>
                                                    <thead>
                                                    <tr className='font-bold bg-gray-200'>
                                                        <th className='text-left first'>Language</th>
                                                        <th className='text-right second'>Voters</th>
                                                        <th className='text-right third'>Voters%</th>
                                                    </tr>
                                                    </thead>

                                                    <tbody>
                                                    {
                                                        lang.map(row => {
                                                            return (
                                                                <tr>
                                                                    <td className='text-left first'>{row[0]}</td>
                                                                    <td className='text-right second'>{row[1]}</td>
                                                                    <td className='text-right third'>{row[2]}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    )

                                })}
                            </>
                        )
                    })}
                </>
            </div>

        </>
    )
})