import {useStore} from "../helpers/helpers";

export const useHasAnalyticalPermission = () => {

    const {projectStore, userStore} = useStore();
    const isFieldWorker = userStore.is_field_worker();

    if (isFieldWorker) return false;

    else if (userStore.isRoot() || userStore.isClient()) {
        return true
    } else {
        return projectStore.getPermission("view_analytical_summary", false);
    }
}