import React from "react";
import {useTranslation} from "react-i18next";
import {Checkbox, FormControl, FormControlLabel} from "@mui/material";

export const PriorityFilter = (props:any) => {

    const {t} = useTranslation("grievance_management");
    const {data, value, setValues} = props;

    return (
        <div className={"flex flex-col"}>
            <p className={"text-lg text-blue-500"}>{t("Select Priority")}</p>
            <div>
                {
                    data.map((priorityData, index) => (
                        <div key={index} className={"task-filter flex flex-row items-center"}>
                            <FormControl>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={value.includes(priorityData)}
                                            value={priorityData}
                                            onChange={(event) => setValues(event)}
                                        />
                                    }
                                    label={priorityData}
                                />
                            </FormControl>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}