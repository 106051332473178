import { Field, Formik } from "formik";
import React from "react";

const VoterReachOutTargetOverview = (props) => {
  const { data } = props;

  const textMessageFormInitialValues = {
    text: data.text,
  };
  const handleSubmit = () => {};

  return (
    <>
      <div className="flex flex-col items-center cursor-pointer rounded-lg ">
        <div className="flex flex-col w-full justify-around">
          <div className="w-1/2 mt-2">
            <p className="text-md font-medium text-indigo-600  font-bold pb-3 ">
              Name
            </p>
          </div>
          <div className="w-100">
            <p className="text-md font-medium text-black-600  font-normal pb-3">
              {data.name}
            </p>
          </div>
        </div>
        <div className="flex flex-col w-full justify-around">
          <div className="w-1/2  ">
            <p className="text-md font-medium text-indigo-600  font-bold pb-3">
              Count
            </p>
          </div>
          <div className="w-1/2">
            <p className="text-md font-medium text-black-600  font-normal pb-3 break-words">
              {data.count}
            </p>
          </div>
        </div>
        <div className="flex flex-col w-full justify-around">
          <div className="w-1/2  ">
            <p className="text-md font-medium text-indigo-600  font-bold pb-3">
              Phone Numbers
            </p>
          </div>
          <div className="h-40">
            <Formik
              initialValues={textMessageFormInitialValues}
              onSubmit={handleSubmit}
            >
              <Field
                as="textarea"
                id="text"
                name="text"
                value={data.text}
                className="px-4 py-2 rounded-lg border border-gray-300 w-full min-h-full"
                disabled
              />
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default VoterReachOutTargetOverview;
