import {LexicalComposer} from '@lexical/react/LexicalComposer';
import * as React from 'react';
import EditorNodes from './nodes/EditorNodes';
import PlaygroundEditorTheme from './themes/PlaygroundEditorTheme';
import {SharedHistoryContext} from './context/SharedHistoryContext';
import {TableContext} from './plugins/TablePlugin';
import {SharedAutocompleteContext} from './context/SharedAutocompleteContext';
import Editor from './Editor';
import './editor-shell.css';
import ToolbarPlugin from './plugins/ToolbarPlugin';
import {useSettings} from './context/SettingsContext';
import {LexicalEditor} from "lexical";
import _ from "lodash"

type editorPropType = {
  editorData: any,
  editorOnChange? : (args) => void,
  editable: boolean
}

export function BrihaspathiRichEditor(props:editorPropType) {

  const {editable,editorData,editorOnChange} = props;

  const {
    settings: {
      isCollab,
      isAutocomplete,
      isMaxLength,
      isCharLimit,
      isCharLimitUtf8,
      isRichText,
      showTreeView,
      showTableOfContents,
      shouldUseLexicalContextMenu,
      tableCellMerge,
      tableCellBackgroundColor
    }
  } = useSettings();

  if(_.isEmpty(editorData)) return ;

  const saveEditorState = (editor: LexicalEditor) => {
    const editorState = editor.getEditorState().toJSON()
    editorOnChange(editorState)
  }

  const initialConfig = {
    editorState: JSON.stringify(editorData),
    namespace: 'Brihaspathi',
    nodes: [...EditorNodes],
    onError: (error: Error) => {
      throw error;
    },
    theme: PlaygroundEditorTheme,
    editable: editable
  };



  return (
    <>
      <div>
        <div className='editor-shell-container'>
          {/*<div className='editor-header flex items-center'>*/}
          {/*  <div className='hamburger cursor-pointer rounded-full flex flex-row items-center justify-center'>*/}
          {/*    <i className="fa-regular fa-bars"/>*/}
          {/*  </div>*/}

          {/*  <div className='ml-6 flex flex-col'>*/}
          {/*    <div className='doc-title'>New Document</div>*/}
          {/*    <div className='flex flex-row items-center mt-2'>*/}
          {/*      <i className="folder-icon fa-light fa-folder-open"/>*/}
          {/*      <div className='doc-sub-title mx-2'>Birmingham University</div>*/}
          {/*      <i className="edit-icon cursor-pointer fa-solid fa-pen" title='Move to folder'/>*/}
          {/*    </div>*/}
          {/*  </div>*/}

          {/*</div>*/}
          <LexicalComposer initialConfig={initialConfig}>
            <SharedHistoryContext>
              <TableContext>
                <SharedAutocompleteContext>
                  {/*<header>
                    <a href="/" target="_blank" rel="noreferrer">
                      <img src="/cambri-logo-color.png" alt="Brihaspathi"/>
                    </a>
                  </header>*/}
                  {isRichText && <ToolbarPlugin onSave={saveEditorState}/>}
                  <div className="editor-shell">
                    <Editor/>
                    {/*<InitialEditorState editorData={editorData}/>*/}
                  </div>
                  {/*<Settings/>*/}
                  {/*<DocsPlugin/>*/}
                  {/*<PasteLogPlugin/>*/}
                  {/*<TestRecorderPlugin/>*/}
                  {/*<TypingPerfPlugin/>*/}
                </SharedAutocompleteContext>
              </TableContext>
            </SharedHistoryContext>
          </LexicalComposer>
        </div>
      </div>
    </>
  );
}
