import {safe_get} from "../report-visuals/report-utils";


//TODO: It will return the booth "node" object.

export const get_booth_node_by_boothnumber = (booth_number) => {

    if (booth_number === "N/A") return;

    const active_project = JSON.parse(localStorage.getItem("__active_project"));
    const hierarchy = safe_get(active_project["properties"], "hierarchy.value", {})

    const findObjectById = (array, id) => {
        for (let i = 0; i < array.length; i++) {
            if (array[i].id === id) {
                return array[i];
            }
            if (array[i].children) {
                const found = findObjectById(array[i].children, id);
                if (found) {
                    return found;
                }
            }
        }
        return null;
    };

    return findObjectById(hierarchy, booth_number)

}