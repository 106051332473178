import React from "react";
import {
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import GetIcons from "./GetIcons";
// import questionStore from "../store/QuestionStore";
import { useStore } from "../../helpers";
const ProfileQuestions = () => {
  const rootStore = useStore();
  const { questionStore } = rootStore;
  const title = questionStore.activeTab.title;
  const tabs = questionStore.tabs;

  return (
    <>
      <List>
        {tabs.map((question) => {
          let value_field: string = question;

          // Replace underscores with spaces
          value_field = value_field.replace(/_/g, " ");

          // Capitalize the first letter of each word
          value_field = value_field.replace(/\b\w/g, (match) =>
            match.toUpperCase()
          );
          return (
            <ListItemButton
              onClick={() => {
                questionStore.showOptions(question);
              }}
              alignItems="flex-start"
              selected={title === question}
            >
              <ListItemIcon>
                <GetIcons icon_name={value_field} />
              </ListItemIcon>
              <ListItemText>
                <h5>{value_field}</h5>
              </ListItemText>
            </ListItemButton>
          );
        })}
      </List>
    </>
  );
};

export default ProfileQuestions;
