import React, {useEffect, useReducer, useState} from 'react';
import {useForm} from 'react-hook-form';
import {FormButtons , LoadingState } from "../../../components";
import {useStore} from "../../../helpers/helpers";
import {input_style, label_style} from "../../beneficiary-management/add-beneficiary";
import {observer} from "mobx-react-lite";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import { safe_get } from "../../../report-visuals/report-utils";
import { Switch } from '@headlessui/react';

//@ts-ignore
const AddBeneficiaryStatus = observer((props: any) => {

    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const { beneficiaryStore, flexiStore } = useStore();
    const { activeStatus } = beneficiaryStore;
    const { loading } = flexiStore
    const {projectid} = useParams();
    const queryClient = useQueryClient();
    const statusId = safe_get(activeStatus, "id", null)
    const [enabled, setEnabled] = useState(safe_get(activeStatus?.status, "default", false));
    const { } = props;


    const handleSwitchChange = (isChecked) => {
        setEnabled(isChecked);
    };

    useEffect(() => {
        if (statusId) {
            setValue("name", safe_get(activeStatus?.status, "name", ""))
            setValue("type", safe_get(activeStatus?.status, "type", ""))
            setValue("display1", safe_get(activeStatus?.status, "display1", ""))
            setValue("display2", safe_get(activeStatus?.status, "display2", ""))
            setValue("default", safe_get(activeStatus?.status, "default",""))
        }
    }, [activeStatus, statusId])

    const editStatus = async (payload: any) => {
        return await beneficiaryStore.edit_beneficiary_status(payload);
    }

    const resetModal = () => {
        beneficiaryStore.setAddStatus(false)
    }

    const {mutate: editMutate} = useMutation({
        mutationFn: editStatus,
        onSuccess: async () => {
            setTimeout(async () => {
                await queryClient.invalidateQueries(["beneficiaray-status", projectid])
            }, 1000);
            toast.success("Updated Successfully")
            resetModal();
        },
        onError: error => {
            toast.error("Something went wrong")
        }
    })

    //TODO: Add Status
    const {mutate} = useMutation({
        mutationFn: async (data: any) => {
            const payload = {
                projectid: parseInt(projectid),
            status: {
                ...data,
                default: enabled
            },
                more: {}
        }
            await beneficiaryStore.add_beneficiary_status(payload)
        },
        onSuccess: async () => {
            setTimeout(async () => {
                await queryClient.invalidateQueries(["beneficiaray-status", projectid])
            }, 1000);
            toast.success("Status added successfully")
            resetModal();
        },
        onError: async (error) => {
            toast.error("Something went wrong");
        }
    })


    const onSubmit = async (data) => {
        const final = {
            ...data,
        };
        
        if(statusId){
            editMutate({
                ...activeStatus, status: {
                    ...activeStatus.status,
                    name: data.name,
                    type: data.type,
                    display1: data.display1,
                    display2: data.display2,
                    default: enabled
                }
            })
        } else {
            mutate(final)
        }
    };

    const flexrow = "flex flex-row items-center justify-between"

    return (
        <>
            {loading && <LoadingState />}
            <form onSubmit={handleSubmit(onSubmit)} className="w-full h-screen overflow-y-auto flex flex-col gap-y-4 ">
            <div className={flexrow}>
                <label htmlFor="name" className={label_style}>Name :</label>
                    <div className="w-full">
                        <input type="text" id="name" {...register('name', { required: true })} className={input_style}/>
                        {errors.name && <span className="text-red-500 text-sm">Name is Required</span>}
                    </div>
            </div>

            <div className={flexrow}>
                    <label htmlFor="type" className={label_style}>Type :</label>
                    <div className="w-full">
                        <input type="text" id="type" {...register('type', { required: true })}className={input_style}/>
                        {errors.type && <span className="text-red-500 text-sm">Type is Required</span>}
                    </div>
                </div>

            <div className={flexrow}>
                <label htmlFor="display1" className={label_style}>Display 1 :</label>
                <input type="text" id="display1" {...register('display1')}
                       className={input_style}/>
            </div>

            <div className={flexrow}>
                <label htmlFor="display2" className={label_style}>Display 2 :</label>
                <input type="text" id="display2" {...register('display2')}
                       className={input_style}/>
            </div>

            <div className={"flex flex-row items-center"}>
            <label htmlFor="default" className={label_style}>Default : </label>
                <Switch
                    checked={enabled}
                    onChange={handleSwitchChange}
                        className={`relative inline-flex mx-4 h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 transition-colors duration-200 ease-in-out
                         ${enabled ? 'bg-indigo-600' : 'bg-gray-200'}`}
                >
                 <span className={`${enabled ? 'translate-x-5' : 'translate-x-0'}
                     inline-block w-5 h-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`} />
                    </Switch>
            </div>

            <div className="flex-grow"></div>
                <div className="sticky bottom-0">
                    <FormButtons
                        onSave={() => {
                        }}
                        onCancel={resetModal}
                        type={"submit"}
                    />
                </div>
            </form>
        </>
    );
})

export default AddBeneficiaryStatus;
