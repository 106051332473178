import React from "react";
import { observer } from "mobx-react-lite";
import _ from "lodash"
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { useStore } from "../../../helpers";
import { FieldSummaryForRohan } from "./field-summary-for-rohan"
import { SurveysByUser } from "./surveys-by-user";
import { SurveysByDate } from "./surveys-by-date";
import { SurveysLevel1 } from "./surveys-by-level1";
import { SurveysLevel2 } from "./surveys-by-level2";
import { SurveysByAudio } from "./surveys-by-audio";
import { SurveysByDateHour } from "./surveys-by-date-hour";
import { SurveysByLevel1Audio } from "./surveys-by-level1-audio";
import { SurveysByLevel2Audio } from "./surveys-by-level2-audio";
import { SurveysByQaCheck } from "./surveys-by-qa-check";
import { SurveysByUserHL } from "./surveys-by-user-hl"
import { SurveysBylevel1HL } from "./survey-by-level1-hl"
import { SurveysBylevel2HL } from "./surveys-by-level2-hl"
import { SurveysByDateHL } from "./surveys-by-date-hl"
import { SurveysByAudioOnly } from "./survey-by-audio-only"
import { SurveysByQaCheckOly } from "./survey-by-qa-check-only"
import {Typography} from "@mui/material";

export const TableHeader = ({ title }) => {
    return (
        <Typography variant={"h6"} sx={{fontFamily: 'MukthaVani', fontWeight: "bold"}}>{title}</Typography>
    )
}

export const SurveySummary = observer(props => {

    const { surveyResponseStore } = useStore();

    const { allSurveyResponsesSummary } = surveyResponseStore;

    if (_.isEmpty(allSurveyResponsesSummary)) return null;

    return (
        <ResponsiveMasonry columnsCountBreakPoints={{ 650:1, 800:2, 1200: 3 }}>
            <Masonry gutter={"20px"}>
                <SurveysByUser />
                <SurveysByDate />
                <SurveysByDateHour />

                <SurveysLevel1 />
                <SurveysLevel2 />
                <SurveysByAudio />

                <SurveysByLevel1Audio />
                <SurveysByLevel2Audio />
                <SurveysByUserHL />

                <SurveysByQaCheck />
                <SurveysByQaCheckOly />
                <SurveysByAudioOnly />

                <SurveysBylevel1HL />
                <SurveysBylevel2HL />
                <SurveysByDateHL />

                <FieldSummaryForRohan />
            </Masonry>
        </ResponsiveMasonry>
    )
})
