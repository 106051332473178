import {observer} from "mobx-react-lite";
import React, {useEffect, useState} from "react";
import {useStore} from "../../helpers/helpers";
import Modal from 'react-modal';
import _ from "lodash"
import {useTranslation} from "react-i18next";
import {useLocation, useParams} from "react-router";
import {BrihaspathiRichEditor} from "../../features/editor/editor-root";
import {safe_get} from "../../report-visuals/report-utils";
import {default_node} from "../../flexistore/renderkeys/ac-bank";
import {BreadCrumbs, LoadingState} from "../../components";
import { HotTable } from '@handsontable/react';
import {ReportViewRegularTemplate} from "./templates/regular/ReportViewRegularTemplate";

Modal.setAppElement('#root');
// @ts-ignore
Modal.defaultStyles.content.inset = '150px';

const Actions = (innerprops) => {
    return (
        <div className="ml-auto">

        </div>
    )
};

export const ReportViewPage = observer(props => {
    const rootStore = useStore();
    const {userStore, reportStore} = rootStore;
    const {reportid, token} = useParams();

    const [t] = useTranslation("ReportEdit");
    const crumbs = [
        {name: t("reports"), href: '/reports', current: false},
        {name: t("view"), href: '#', current: true},
    ];
    const [reportData, setReportData] = useState({})
    const [error, setError] = useState(false);
    const [fetched, setFetched] = useState(false);
    const [loading, setLoading] = useState(false);
    const {state} = useLocation();
    const {data} = state || {};

    const [viewTemplate, setViewTemplate] = useState('regular');

    const fetchReportById = async () => {
        setLoading(true);
        const response = await reportStore.viewReportWithData(parseInt(reportid));
        if (response.status !== 200) {
            setError(true);
            setLoading(false);
            return;
        }
        const {meta, data} = response.data;
        reportStore.setCurrentReport(meta);
        setReportData(meta)
        reportStore.setCurrentReportData(data);
        setFetched(true);
        setLoading(false);
    };

    const fetchReportByToken = async () => {
        setLoading(true);
        const response = await reportStore.viewReportTokenWithData(token.trim());
        if (response.status !== 200) {
            setError(true);
            setLoading(false);
            return;
        }
        const {meta, data} = response.data;
        reportStore.setCurrentReport(meta);
        setReportData(meta)
        reportStore.setCurrentReportData(data);
        setFetched(true);
        setLoading(false);
    };

    useEffect(() => {
        if (reportid) {
            fetchReportById()
        } else if (token) {
            fetchReportByToken()
        } else {
            setError(true);
        }
    }, []);

    const editor_data = !_.isEmpty(reportData) ? safe_get(reportData, `narratives.${Object.keys(reportData["narratives"])[0]}`, default_node) : null

    const addEmptyReportAndNav = async () => {
        setLoading(true);
        const response = await reportStore.createNewReport();
        if (response.status !== 200) {
            setError(true);
            setLoading(false);
            return;
        }
        setLoading(false);
    };

    return (
        <>

            {loading ? (<LoadingState/>) : null}
            {reportid ? (<BreadCrumbs crumbs={crumbs}/>) : null}
            <section className={'max-w-[1400px] mx-auto p-4'}>
                <div className='p-4'>
                    <div className='flex flex-row flex-nowrap items-center '>
                        {userStore.isRoot() ? (<Actions/>) : null}
                    </div>
                    {/*{error ? <Error/> : null}*/}
                    {fetched ? viewTemplate === 'regular' ? <ReportViewRegularTemplate/> : null : null}

                    {/*{!loading && <BrihaspathiRichEditor editorData={editor_data} editable={false} editorOnChange={() => {}}/>}*/}
                </div>
            </section>
        </>
    );
});
