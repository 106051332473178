import React, {useRef} from "react";
import {observer} from "mobx-react-lite";
import {FormButtons, TailwindModal} from "../../components";
import {useStore} from "../../helpers";
import {safe_get} from "../../report-visuals/report-utils";
import Select from "react-select";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {useForm, Controller} from "react-hook-form";
import {FieldError} from "react-hook-form/dist/types/errors";
import {toast} from "react-toastify";

const roles = [
    {
        label: "Root",
        value: "root",
    },
    {
        label: "Client",
        value: "client",
    },
    {
        label: "Party worker",
        value: "partyworker",
    },
    {
        label: "Field Worker",
        value: "surveyworker",
    },
    {
        label: "QA Worker",
        value: "qaworker",
    },
];

type userType = {
    role: any;
    tags: any[];
    phonenumlist: string;
};

export const AddNewUsers = observer((props: any) => {
    const rootStore = useStore();
    const {userStore, projectStore} = rootStore;
    const {t} = useTranslation(["user_management", "survey_management"]);
    const {projectid} = useParams();
    const user_tags = useRef([]);
    const defaultTags = projectStore.getProjectProperty("default_tags", {});
    user_tags.current = safe_get(defaultTags, "user_tags", []);
    const {user} = userStore;
    const role = safe_get(user, "role", "");
    const {onUserAdded} = props;

    const {
        handleSubmit,
        control,
        reset,
        formState: {errors},
    } = useForm<userType>();

    const onSubmit = async (data: userType) => {

        const phonenumlist = data?.phonenumlist.split("\n");
        const role = data?.role["value"];
        const tags = data?.tags.map((tag) => tag.value);
        const payload = {
            phonenumlist,
            projectid: parseInt(projectid),
            role,
            tags,
        };
        const response = await projectStore.addNewUser(payload);
        if (response.status === 200) {
            toast.success("Users added successfully");
            reset();
            await onUserAdded();
        } else {
          toast.error("Something went wrong, user haven't added. Please try again.")
        }
        closeModal();
    };

    const closeModal = () => {
        userStore.update_user_store_prop("add_new_users_modal", false);
    };

    return (
        <>
            <TailwindModal
                isOpen={userStore.add_new_users_modal}
            >
                <div
                    className={
                        "inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-lg w-full p-4"
                    }
                >
                    <div className={"text-xl font-bold text-purple-500"}>
                        Add New Users
                    </div>
                    <form
                        className={"flex flex-col gap-y-3"}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <div>
                            <label className={"font-bold text-purple-500"}>Role</label>
                            <Controller
                                control={control}
                                name={"role"}
                                defaultValue={""}
                                rules={{required: "Please select the Role"}}
                                render={({field}) => (
                                    <Select
                                        {...field}
                                        options={roles.filter((r) => {
                                            if (role === "client" && r.value === "root") return;
                                            return r;
                                        })}
                                        placeholder="Filter by User_Tags..."
                                        className="w-full h-10 rounded focus:shadow outline-none basic-multi-select"
                                        classNamePrefix="select"
                                    />
                                )}
                            />
                            {errors.role && (
                                <p className={"text-red-600 text-sm text-right py-2"}>
                                    {(errors.role as FieldError).message}
                                </p>
                            )}
                        </div>
                        <div>
                            <label className={"font-bold text-purple-500"}>User Tag</label>
                            <Controller
                                control={control}
                                name={"tags"}
                                defaultValue={[]}
                                render={({field}) => (
                                    <Select
                                        {...field}
                                        isMulti={true}
                                        options={user_tags.current?.map((x) => {
                                            return {value: x, label: x};
                                        })}
                                        placeholder="Filter by User_Tags..."
                                        className="w-full h-10 rounded focus:shadow outline-none basic-multi-select"
                                        classNamePrefix="select"
                                    />
                                )}
                            />
                        </div>
                        <div>
                            <label className={"font-bold text-purple-500"}>
                                Phone Numbers
                            </label>
                            <Controller
                                control={control}
                                name={"phonenumlist"}
                                defaultValue={""}
                                rules={{required: "Please Enter Phone Number"}}
                                render={({field}) => (
                                    <textarea
                                        {...field}
                                        className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md border p-2"
                                        cols={10}
                                        rows={5}
                                        wrap="hard"
                                    />
                                )}
                            />
                            {errors.phonenumlist && (
                                <p className={"text-red-600 text-sm text-right py-2"}>
                                    {errors.phonenumlist.message}
                                </p>
                            )}
                        </div>

                        <FormButtons
                            onSave={() => {
                            }}
                            onCancel={closeModal}
                            type={"submit"}
                        />
                    </form>
                </div>
            </TailwindModal>
        </>
    );
});
