import React from "react";
import {zeroPad} from "../../../helpers";
import {FlexiStoreData_Abridged} from "../../../stores/fs/fst";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

export const ACBankSearchCard = (props) => {

    const fsd: FlexiStoreData_Abridged = props.data;
    const navigate = useNavigate();
    // @ts-ignore
    const {acno, acname, res, region, district} = fsd.summary;
    const {t} = useTranslation("ac_bank");
    const {fstid} = useParams();

    return (
        <div
            className="flex flex-row items-center rounded-lg shadow-lg overflow-hidden cursor-pointer bg-white p-3"
            style={{
                borderLeft: `4px solid`,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
            }}
            onClick={(evt) => {
                navigate(`/databank/repo/${fstid}/${fsd.key}/${fsd.id}`);
            }}
        >
            <div
                className="rounded-full flex flex-col items-center justify-center bg-gray-200"
                style={{width: 60, height: 60}}
            >
                <i className={`text-3xl `} style={{color: `green`}}/>
                <div className="font-bold text-gray-800">{t("ac")}</div>
                <div className="font-bold text-gray-800">
                    {zeroPad(parseInt(acno), 3)}
                </div>
            </div>
            <div className="flex-1 flex flex-col ml-2">
                <div className="font-bold text-gray-700">
                    {acname} ({res})
                </div>
                <div className="text-gray-600 text-md">
                    {district}, {region}
                </div>
            </div>
        </div>
    );
};
