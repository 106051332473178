import {action, makeAutoObservable} from "mobx";
import moment from "moment/moment";

type filterTypes = {
    villages: string[],
    status: string[],
    start_date: string,
    end_date: string
}

export class ProjectWorksStore {

    rootStore: any;
    projectWorksList = []
    projWorkDetails = {}
    add_fund_modal: boolean = false;
    add_contact_modal: boolean = false;
    add_media_modal: boolean = false;
    add_date_modal: boolean = false;
    edit_media_modal: boolean = false;
    edit_pw_modal: boolean = false;
    add_pw_modal: boolean = false;
    isDeleteMode: boolean = false;
    add_timeline_modal: boolean = false;
    cover_photo_path: string = "";
    projectWorkFilter: boolean = false;

    filters: filterTypes = {
        villages: [],
        status: [],
        start_date: "",
        end_date: ""
    };

    constructor(rootStore: any) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    @action
    resetFilters() {
        this.filters = {
            villages: [],
            status: [],
            start_date: "",
            end_date: ""
        }
    }

    @action
    update_filters(start_date, end_date = moment().format("YYYY-MM-DD")){
        this.filters.start_date = start_date;
        this.filters.end_date = end_date;
    }

    @action
    updateFilters(filters: filterTypes) {
        this.filters = filters;
    }

    @action
    get_project_works() {
        return this.projectWorksList
    }

    @action
    update_project_work_property(key: string, value: any) {
        switch (key) {
            case "delete_modal":
                this.isDeleteMode = value;
                break;
            case "set_project_works":
                this.projectWorksList = value;
                break;
            case "project_work_details" :
                this.projWorkDetails = value;
                break;
            case "add_fund_modal" :
                this.add_fund_modal = value;
                break;
            case "add_media_modal" :
                this.add_media_modal = value;
                break;
            case "add_contact_modal" :
                this.add_contact_modal = value;
                break;
            case "add_date_modal" :
                this.add_date_modal = value;
                break;
            case "edit_media_modal" :
                this.edit_media_modal = value;
                break;
            case "add_pw_modal" :
                this.add_pw_modal = value;
                break;
            case "edit_pw_modal" :
                this.edit_pw_modal = value;
                break;
            case "add_timeline_modal" :
                this.add_timeline_modal = value;
                break;
            case "cover_photo" :
                this.cover_photo_path = value;
                break;

        }
    }


    @action
    setProjectFilterStatus(status) {
        this.projectWorkFilter = status
    }

}
