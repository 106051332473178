import React from "react";
import {DashBoard} from "./dashboard-template";

export const IndiaContactsDashBoard = () => {

    const frame = `<iframe title="India Contacts" width="100%" height="100%" src="https://app.powerbi.com/view?r=eyJrIjoiYzUyMzc3ZGQtOGVmMy00YmQzLWJiZjUtNWE3MDk5OTkzY2U0IiwidCI6IjAzZjhjZWI2LTdhMGUtNDcwMy05ZGE0LWUyYTBkYmE1NmM4YyJ9" frameborder="0" allowFullScreen="true"></iframe>`

    return (
        <>
            <DashBoard passCode={"vinay_am"} iframe={frame} />
        </>
    )
}