import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../helpers/helpers";
import _ from "lodash";
import { toJS } from "mobx";
import { reduce_for_visual, safe_get } from "../../report-visuals/report-utils";
import { RNConstants } from "../../helpers/constants";
import { useWindowSize } from "@withvoid/melting-pot";
import { classNames, get_color_code } from "../../helpers/utils";
import { GridToolbar, DataGridPro, LicenseInfo } from "@mui/x-data-grid-pro";
import { Switch } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { LoadingState } from "../../components";

export const SurveySummaryView = observer((props) => {
  LicenseInfo.setLicenseKey(RNConstants.XGRID_KEY);
  const SURVEY_LIST_THRESHOLD = 300;

  const { width, height } = useWindowSize();
  const isMobile = width <= 766;

  const w1 = 260;
  const w2 = 150;

  const { surveyResponseStore } = useStore();
  const { activeSurveyResponseId } = surveyResponseStore;
  const [loading, setLoading] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [show_caste_breakdown, set_show_caste_breakdown] = useState(false);

  const [surveysByUser, setSurveysByUser] = useState([]);
  const [surveysByDate, setSurveysByDate] = useState([]);
  const [surveysByHour, setSurveysByHour] = useState([]);
  const [surveysByCaste, setSurveysByCaste] = useState([]);
  const [surveysByLevel1, setSurveysByLevel1] = useState([]);
  const [surveysByLevel2, setSurveysByLevel2] = useState([]);
  const [surveysByQaStatus, setSurveysByQaStatus] = useState([]);
  const [surveysByAudioCount, setSurveysByAudioCount] = useState([]);
  const [surveysList, setSurveysList] = useState([]);
  const [t] = useTranslation([
    "survey_management",
    "task_manager",
    "analytical_summary",
    "beneficiary",
  ]);

  useEffect(() => {
    update_data();
  }, [
    surveyResponseStore.allSurveyResponses,
    surveyResponseStore.surveyResponseFilters,
  ]);

  const update_data = () => {
    setLoading(true);
    const start = Date.now();

    const allResponses = toJS(surveyResponseStore.allSurveyResponses);
    const filters = toJS(surveyResponseStore.surveyResponseFilters);
    const surveys_by_user = [
      {
        op: "emit",
        cols: ["surveyor_name", "id"],
        out: ["surveyor_name", "id"],
      },
      {
        op: "count",
        cols: ["surveyor_name"],
        out: ["surveyor_name", "surveys"],
        sumcol: "surveyor_name",
        setcol: "surveys",
      },
    ];
    const surveys_by_date = [
      {
        op: "emit",
        cols: ["dt_month_date", "id"],
        out: ["dt_month_date", "id"],
      },
      {
        op: "count",
        cols: ["dt_month_date"],
        out: ["dt_month_date", "surveys"],
        sumcol: "dt_month_date",
        setcol: "surveys",
      },
    ];
    const surveys_by_hour = [
      {
        op: "emit",
        cols: ["dt_hour", "id"],
        out: ["dt_hour", "id"],
      },
      {
        op: "count",
        cols: ["dt_hour"],
        out: ["dt_hour", "surveys"],
        sumcol: "dt_hour",
        setcol: "surveys",
      },
    ];
    const surveys_by_caste = [
      {
        op: "emit",
        cols: ["profile_caste", "id"],
        out: ["profile_caste", "id"],
      },
      {
        op: "count",
        cols: ["profile_caste"],
        out: ["profile_caste", "surveys"],
        sumcol: "profile_caste",
        setcol: "surveys",
      },
    ];
    const surveys_by_level1 = [
      {
        op: "emit",
        cols: ["piv_level1", "id"],
        out: ["piv_level1", "id"],
      },
      {
        op: "count",
        cols: ["piv_level1"],
        out: ["piv_level1", "surveys"],
        sumcol: "piv_level1",
        setcol: "surveys",
      },
    ];
    const surveys_by_level2 = [
      {
        op: "emit",
        cols: ["piv_level2", "id"],
        out: ["piv_level2", "id"],
      },
      {
        op: "count",
        cols: ["piv_level2"],
        out: ["piv_level2", "surveys"],
        sumcol: "piv_level2",
        setcol: "surveys",
      },
    ];
    const surveys_by_qa_status = [
      {
        op: "emit",
        cols: ["qa_status", "id"],
        out: ["qa_status", "id"],
      },
      {
        op: "count",
        cols: ["qa_status"],
        out: ["qa_status", "surveys"],
        sumcol: "qa_status",
        setcol: "surveys",
      },
    ];
    const surveys_list = [
      {
        op: "emit",
        cols: [
          "id",
          "dt_full_date",
          "dt_start_time",
          "dt_duration_in_str",
          "qa_status",
          "hasaudiofile",
          "hashighlights",
          "surveyor_name",
          "lat",
          "long",
        ],
        out: [
          "clientid",
          "dt_full_date",
          "dt_start_time",
          "dt_duration_in_str",
          "qa_status",
          "hasaudiofile",
          "hashighlights",
          "surveyor_name",
          "lat",
          "long",
        ],
      },
    ];

    const surveys_by_audio = [
      {
        op: "emit",
        cols: ["hasaudiofile", "id"],
        out: ["hasaudiofile", "id"],
      },
      {
        op: "count",
        cols: ["hasaudiofile"],
        out: ["hasaudiofile", "surveys"],
        sumcol: "hasaudiofile",
        setcol: "surveys",
      },
    ];

    setSurveysByUser(reduce_for_visual(allResponses, surveys_by_user, filters));
    setSurveysByDate(reduce_for_visual(allResponses, surveys_by_date, filters));
    setSurveysByHour(reduce_for_visual(allResponses, surveys_by_hour, filters));
    try {
      setSurveysByCaste(
        reduce_for_visual(allResponses, surveys_by_caste, filters)
      );
    } catch (e) {}

    setSurveysByAudioCount(
      reduce_for_visual(allResponses, surveys_by_audio, filters)
    );

    setSurveysByLevel1(
      reduce_for_visual(allResponses, surveys_by_level1, filters)
    );
    setSurveysByLevel2(
      reduce_for_visual(allResponses, surveys_by_level2, filters)
    );
    setSurveysByQaStatus(
      reduce_for_visual(allResponses, surveys_by_qa_status, filters)
    );

    setSurveysList(reduce_for_visual(allResponses, surveys_list, filters));

    const sample_tuple = surveyResponseStore.allSurveyResponses[0];
    if (Object.keys(sample_tuple).includes("profile_caste")) {
      set_show_caste_breakdown(true);
    } else {
      set_show_caste_breakdown(false);
    }

    setLoading(false);
  };

  const SurveysByUser = (innerprops) => {
    const columnDefinition = [
      {
        field: "surveyor_name",
        headerName: t("field_worker"),
        hide: false,
        width: w1,
        renderCell: (params) => {
          const color = get_color_code(params.id);
          surveyResponseStore.set_colors({
            name: params.value,
            color: color,
          });
          const index = _.findIndex(surveyResponseStore.colors, {
            name: params.value,
          });
          return (
            <>
              <i
                className="fa-solid fa-circle text-xl p-2"
                style={{ color: surveyResponseStore.colors[index]["color"] }}
              />
              <p>{params.value}</p>
            </>
          );
        },
      },
      {
        field: "surveys",
        headerName: t("interviews", { ns: "task_manager" }),
        hide: false,
        width: w2,
      },
    ];

    const row_click = (param, event) => {
      const emitKey = "surveyor_name";
      const { row } = param;
      if (Object.keys(row).includes(emitKey)) {
        //Set filter in central store
        const filterValue = row[emitKey];
        surveyResponseStore.updateFilter(emitKey, [filterValue]);
      }
    };

    const xprops = {
      title: t("interviews_by_field_worker"),
      data: surveysByUser,
      onRowClick: row_click,
      coldef: columnDefinition,
    };

    return (
      <>
        <div className="flex flex-col">
          <label className="block text-base font-medium text-gray-700 font-bold">
            {t("interviews_by_field_worker")}
          </label>
          <div
            className={`bg-white rounded-tl-lg rounded-tr-lg overflow-x :hidden`}
            style={{ width: isMobile ? "100%" : "auto", height: "500px" }}
          >
            <DataGridPro
              className="rnr-data-grid "
              rows={surveysByUser.map((x, ind) => {
                // @ts-ignore
                x["id"] = ind;
                return x;
              })}
              columns={columnDefinition}
              rowCount={surveysByUser.length}
              paginationMode="client"
              filterMode="client"
              sortingMode="client"
              loading={loading}
              initialState={{
                sorting: { sortModel: [{ field: "surveys", sort: "desc" }] },
              }}
              disableMultipleColumnsFiltering={false}
              disableMultipleColumnsSorting
              // @ts-ignore
              disableMultipleSelection
              pagination
              density={"compact"}
              autoPageSize={true}
              components={
                false
                  ? {
                      Toolbar: GridToolbar,
                    }
                  : {}
              }
              rowHeight={64}
              onRowClick={row_click}
            />
          </div>
          <table className="mb-10 bg-white font-bold rounded-bl-lg rounded-br-lg border-t-2 border-gray-500">
            <tr>
              <td
                className="px-2 py-2"
                style={{ width: "330px", height: "50px" }}
              >
                {t("total")}
              </td>
              <td
                className="px-2 py-2"
                style={{ width: "170px", height: "50px" }}
              >
                {_.sumBy(surveysByUser, "surveys")}
              </td>
            </tr>
          </table>
        </div>

        {/*{surveysByUser.length > 0 ? <XgridTable {...xprops}/> : null}*/}
      </>
    );
  };

  const SurveysByDate = (innerprops) => {
    const columnDefinition = [
      {
        field: "dt_month_date",
        headerName: t("date", { ns: "analytical_summary" }),
        hide: false,
        width: w1,
      },
      {
        field: "surveys",
        headerName: t("interviews", { ns: "task_manager" }),
        hide: false,
        width: w2,
      },
    ];

    const row_click = (param, event) => {
      const emitKey = "dt_month_date";
      const { row } = param;
      if (Object.keys(row).includes(emitKey)) {
        //Set filter in central store
        const filterValue = row[emitKey];
        surveyResponseStore.updateFilter(emitKey, [filterValue]);
      }
    };

    const xprops = {
      title: t("interviews_by_date"),
      data: surveysByDate,
      onRowClick: row_click,
      coldef: columnDefinition,
    };

    return (
      <>
        <div className="flex flex-col w-full overflow-x :hidden">
          <label className="block text-base font-medium text-gray-700 font-bold">
            {t("interviews_by_date")}
          </label>
          <div
            className={`bg-white rounded-tl-lg rounded-tr-lg`}
            style={{ width: isMobile ? "100%" : "auto", height: "500px" }}
          >
            <DataGridPro
              className="rnr-data-grid"
              rows={surveysByDate.map((x, ind) => {
                // @ts-ignore
                x["id"] = ind;
                return x;
              })}
              columns={columnDefinition}
              rowCount={surveysByDate.length}
              paginationMode="client"
              initialState={{
                sorting: {
                  sortModel: [{ field: "dt_month_date", sort: "desc" }],
                },
              }}
              filterMode="client"
              sortingMode="client"
              loading={loading}
              disableMultipleColumnsFiltering={false}
              disableMultipleColumnsSorting
              // @ts-ignore
              disableMultipleSelection
              pagination
              density={"compact"}
              autoPageSize={true}
              // pageSize={10}
              components={
                false
                  ? {
                      Toolbar: GridToolbar,
                    }
                  : {}
              }
              rowHeight={64}
              onRowClick={row_click}
            />
          </div>
          <table className="mb-10 bg-white font-bold rounded-bl-lg rounded-br-lg border-t-2 border-gray-500">
            <tr>
              <td
                className="px-2 py-2"
                style={{ width: "330px", height: "50px" }}
              >
                {t("total")}
              </td>
              <td
                className="px-2 py-2"
                style={{ width: "170px", height: "50px" }}
              >
                {_.sumBy(surveysByDate, "surveys")}
              </td>
            </tr>
          </table>
        </div>
        {/*{surveysByDate.length > 0 ? <XgridTable {...xprops}/> : null}*/}
      </>
    );
  };

  const SurveysByHour = (innerprops) => {
    const columnDefinition = [
      {
        field: "dt_hour",
        headerName: t("hour"),
        hide: false,
        width: w1,
      },
      {
        field: "surveys",
        headerName: t("interviews", { ns: "task_manager" }),
        hide: false,
        width: w2,
      },
    ];

    const row_click = (param, event) => {
      const emitKey = "dt_hour";
      const { row } = param;
      if (Object.keys(row).includes(emitKey)) {
        //Set filter in central store
        const filterValue = row[emitKey];
        surveyResponseStore.updateFilter(emitKey, [filterValue]);
      }
    };

    const xprops = {
      title: t("interviews_by_hour"),
      data: surveysByHour,
      onRowClick: row_click,
      coldef: columnDefinition,
    };

    return (
      <>
        <div className="flex flex-col w-full overflow-x :hidden">
          <label className="block text-base font-medium text-gray-700 font-bold">
            {t("interviews_by_hour")}
          </label>
          <div
            className={`bg-white rounded-tl-lg rounded-tr-lg`}
            style={{ width: isMobile ? "100%" : "auto", height: "500px" }}
          >
            <DataGridPro
              className="rnr-data-grid"
              rows={surveysByHour.map((x, ind) => {
                // @ts-ignore
                x["id"] = ind;
                return x;
              })}
              columns={columnDefinition}
              rowCount={surveysByHour.length}
              paginationMode="client"
              filterMode="client"
              initialState={{
                sorting: { sortModel: [{ field: "dt_hour", sort: "desc" }] },
              }}
              sortingMode="client"
              loading={loading}
              disableMultipleColumnsFiltering={false}
              disableMultipleColumnsSorting
              // @ts-ignore
              disableMultipleSelection
              pagination
              density={"compact"}
              autoPageSize={true}
              // pageSize={10}
              components={
                false
                  ? {
                      Toolbar: GridToolbar,
                    }
                  : {}
              }
              rowHeight={64}
              onRowClick={row_click}
            />
          </div>
          <table className="mb-10 bg-white font-bold rounded-bl-lg rounded-br-lg border-t-2 border-gray-500">
            <tr>
              <td
                className="px-2 py-2"
                style={{ width: "330px", height: "50px" }}
              >
                {t("total")}
              </td>
              <td
                className="px-2 py-2"
                style={{ width: "170px", height: "50px" }}
              >
                {_.sumBy(surveysByDate, "surveys")}
              </td>
            </tr>
          </table>
        </div>

        {/*{surveysByHour.length > 0 ? <XgridTable {...xprops}/> : null}*/}
      </>
    );
  };

  const SurveysByCaste = (innerprops) => {
    const columnDefinition = [
      {
        field: "profile_caste",
        headerName: t("caste", { ns: "beneficiary" }),
        hide: false,
        width: w1,
      },
      {
        field: "surveys",
        headerName: t("interviews", { ns: "task_manager" }),
        hide: false,
        width: w2,
      },
    ];

    const row_click = (param, event) => {
      const emitKey = "profile_caste";
      const { row } = param;
      if (Object.keys(row).includes(emitKey)) {
        //Set filter in central store
        const filterValue = row[emitKey];
        surveyResponseStore.updateFilter(emitKey, [filterValue]);
      }
    };

    const xprops = {
      title: t("interviews_by_caste"),
      data: surveysByCaste,
      onRowClick: row_click,
      coldef: columnDefinition,
    };

    return (
      <>
        <div className="flex flex-col w-full overflow-x :hidden">
          <label className="block text-base font-medium text-gray-700 font-bold">
            {t("interviews_by_caste")}
          </label>
          <div
            className={`bg-white rounded-tl-lg rounded-tr-lg`}
            style={{ width: isMobile ? "100%" : "auto", height: "500px" }}
          >
            <DataGridPro
              className="rnr-data-grid"
              rows={surveysByCaste.map((x, ind) => {
                // @ts-ignore
                x["id"] = ind;
                return x;
              })}
              columns={columnDefinition}
              rowCount={surveysByCaste.length}
              paginationMode="client"
              filterMode="client"
              initialState={{
                sorting: { sortModel: [{ field: "dt_hour", sort: "desc" }] },
              }}
              sortingMode="client"
              loading={loading}
              disableMultipleColumnsFiltering={false}
              disableMultipleColumnsSorting
              // @ts-ignore
              disableMultipleSelection
              pagination
              density={"compact"}
              autoPageSize={true}
              // pageSize={10}
              components={
                false
                  ? {
                      Toolbar: GridToolbar,
                    }
                  : {}
              }
              rowHeight={64}
              onRowClick={row_click}
            />
          </div>
          <table className="mb-10 bg-white font-bold rounded-bl-lg rounded-br-lg border-t-2 border-gray-500">
            <tr>
              <td
                className="px-2 py-2"
                style={{ width: "330px", height: "50px" }}
              >
                {t("total")}
              </td>
              <td
                className="px-2 py-2"
                style={{ width: "170px", height: "50px" }}
              >
                {_.sumBy(surveysByDate, "surveys")}
              </td>
            </tr>
          </table>
        </div>

        {/*{surveysByHour.length > 0 ? <XgridTable {...xprops}/> : null}*/}
      </>
    );
  };

  const SurveysByAudio = (innerprops) => {
    const columnDefinition = [
      {
        field: "hasaudiofile",
        headerName: t("audio"),
        hide: false,
        width: w1,
        renderCell: (params) => {
          const hasaudiofile = params.row["hasaudiofile"];

          return (
            <div className="flex flex-row">
              {hasaudiofile ? (
                <div>{t("available")}</div>
              ) : (
                <div>{t("not_available")}</div>
              )}
            </div>
          );
        },
      },
      {
        field: "surveys",
        headerName: t("interviews", { ns: "task_manager" }),
        hide: false,
        width: w2,
      },
    ];

    const row_click = (param, event) => {
      const emitKey = "hasaudiofile";
      const { row } = param;
      if (Object.keys(row).includes(emitKey)) {
        //Set filter in central store
        const filterValue = row[emitKey];
        surveyResponseStore.updateFilter(emitKey, [filterValue]);
      }
    };

    return (
      <>
        <div className="flex flex-col w-full overflow-x :hidden">
          <label className="block text-base font-medium text-gray-700 font-bold">
            {t("interviews_by_audio")}
          </label>
          <div
            className={`bg-white rounded-tl-lg rounded-tr-lg`}
            style={{ width: isMobile ? "100%" : "auto", height: "500px" }}
          >
            <DataGridPro
              className="rnr-data-grid"
              rows={surveysByAudioCount.map((x, ind) => {
                // @ts-ignore
                x["id"] = ind;
                return x;
              })}
              columns={columnDefinition}
              rowCount={surveysByAudioCount.length}
              paginationMode="client"
              filterMode="client"
              initialState={{
                sorting: { sortModel: [{ field: "dt_hour", sort: "desc" }] },
              }}
              sortingMode="client"
              loading={loading}
              disableMultipleColumnsFiltering={false}
              disableMultipleColumnsSorting
              // @ts-ignore
              disableMultipleSelection
              pagination
              density={"compact"}
              autoPageSize={true}
              // pageSize={10}
              components={
                false
                  ? {
                      Toolbar: GridToolbar,
                    }
                  : {}
              }
              rowHeight={64}
              onRowClick={row_click}
            />
          </div>
          <table className="mb-10 bg-white font-bold rounded-bl-lg rounded-br-lg border-t-2 border-gray-500">
            <tr>
              <td
                className="px-2 py-2"
                style={{ width: "330px", height: "50px" }}
              >
                {t("total")}
              </td>
              <td
                className="px-2 py-2"
                style={{ width: "170px", height: "50px" }}
              >
                {_.sumBy(surveysByAudioCount, "surveys")}
              </td>
            </tr>
          </table>
        </div>

        {/*{surveysByHour.length > 0 ? <XgridTable {...xprops}/> : null}*/}
      </>
    );
  };

  const SurveysByLevel1 = (innerprops) => {
    const columnDefinition = [
      {
        field: "piv_level1",
        headerName: "Level 1",
        hide: false,
        width: w1,
      },
      {
        field: "surveys",
        headerName: t("interviews", { ns: "task_manager" }),
        hide: false,
        width: w2,
      },
    ];

    const row_click = (param, event) => {
      const emitKey = "piv_level1";
      const { row } = param;
      if (Object.keys(row).includes(emitKey)) {
        //Set filter in central store
        const filterValue = row[emitKey];
        surveyResponseStore.updateFilter(emitKey, [filterValue]);
      }
    };

    const xprops = {
      title: t("interviews_by_level_1"),
      data: surveysByLevel1,
      onRowClick: row_click,
      coldef: columnDefinition,
    };

    return (
      <>
        <div className="flex flex-col w-full overflow-x :hidden">
          <label className="block text-base font-medium text-gray-700 font-bold">
            {t("Interviews_by_level_1")}
          </label>
          <div
            className={`bg-white rounded-tl-lg rounded-tr-lg`}
            style={{ width: isMobile ? "100%" : "auto", height: "500px" }}
          >
            <DataGridPro
              className="rnr-data-grid"
              rows={surveysByLevel1.map((x, ind) => {
                // @ts-ignore
                x["id"] = ind;
                return x;
              })}
              columns={columnDefinition}
              rowCount={surveysByLevel1.length}
              paginationMode="client"
              filterMode="client"
              sortingMode="client"
              loading={loading}
              initialState={{
                sorting: { sortModel: [{ field: "surveys", sort: "desc" }] },
              }}
              disableMultipleColumnsFiltering={false}
              disableMultipleColumnsSorting
              // @ts-ignore
              disableMultipleSelection
              pagination
              density={"compact"}
              autoPageSize={true}
              // pageSize={10}
              components={
                false
                  ? {
                      Toolbar: GridToolbar,
                    }
                  : {}
              }
              rowHeight={64}
              onRowClick={row_click}
            />
          </div>
          <table className="mb-10 bg-white font-bold rounded-bl-lg rounded-br-lg border-t-2 border-gray-500">
            <tr>
              <td
                className="px-2 py-2"
                style={{ width: "330px", height: "50px" }}
              >
                {t("total")}
              </td>
              <td
                className="px-2 py-2"
                style={{ width: "170px", height: "50px" }}
              >
                {_.sumBy(surveysByLevel1, "surveys")}
              </td>
            </tr>
          </table>
        </div>

        {/*{surveysByLevel1.length > 0 ? <XgridTable {...xprops}/> : null}*/}
      </>
    );
  };

  const SurveysByLevel2 = (innerprops) => {
    const columnDefinition = [
      {
        field: "piv_level2",
        headerName: "Level 2",
        hide: false,
        width: w1,
      },
      {
        field: "surveys",
        headerName: t("interviews", { ns: "task_manager" }),
        hide: false,
        width: w2,
      },
    ];

    const row_click = (param, event) => {
      const emitKey = "piv_level2";
      const { row } = param;
      if (Object.keys(row).includes(emitKey)) {
        //Set filter in central store
        const filterValue = row[emitKey];
        surveyResponseStore.updateFilter(emitKey, [filterValue]);
      }
    };

    const xprops = {
      title: t("interviews_by_level_2"),
      data: surveysByLevel2,
      onRowClick: row_click,
      coldef: columnDefinition,
    };

    return (
      <>
        <div className="flex flex-col w-full overflow-x :hidden">
          <label className="block text-base font-medium text-gray-700 font-bold">
            {t("interviews_by_level_2")}
          </label>
          <div
            className={`bg-white rounded-tl-lg rounded-tr-lg`}
            style={{ width: isMobile ? "100%" : "auto", height: "500px" }}
          >
            <DataGridPro
              className="rnr-data-grid"
              rows={surveysByLevel2.map((x, ind) => {
                // @ts-ignore
                x["id"] = ind;
                return x;
              })}
              columns={columnDefinition}
              rowCount={surveysByLevel2.length}
              initialState={{
                sorting: { sortModel: [{ field: "surveys", sort: "desc" }] },
              }}
              paginationMode="client"
              filterMode="client"
              sortingMode="client"
              loading={loading}
              disableMultipleColumnsFiltering={false}
              disableMultipleColumnsSorting
              // @ts-ignore
              disableMultipleSelection
              pagination
              density={"compact"}
              autoPageSize={true}
              // pageSize={10}
              components={
                false
                  ? {
                      Toolbar: GridToolbar,
                    }
                  : {}
              }
              rowHeight={64}
              onRowClick={row_click}
            />
          </div>
          <table className="mb-10 bg-white font-bold rounded-bl-lg rounded-br-lg border-t-2 border-gray-500">
            <tr>
              <td
                className="px-2 py-2"
                style={{ width: "330px", height: "50px" }}
              >
                {t("total")}
              </td>
              <td
                className="px-2 py-2"
                style={{ width: "170px", height: "50px" }}
              >
                {_.sumBy(surveysByLevel2, "surveys")}
              </td>
            </tr>
          </table>
        </div>

        {/*{surveysByLevel2.length > 0 ? <XgridTable {...xprops}/> : null}*/}
      </>
    );
  };

  const SurveysByQaStatus = (innerprops) => {
    const columnDefinition = [
      {
        field: "qa_status",
        headerName: t("status", { ns: "analytical_summary" }),
        hide: false,
        width: w1,
      },
      {
        field: "surveys",
        headerName: t("interviews", { ns: "task_manager" }),
        hide: false,
        width: w2,
      },
    ];

    const row_click = (param, event) => {
      const emitKey = "qa_status";
      const { row } = param;
      if (Object.keys(row).includes(emitKey)) {
        //Set filter in central store
        const filterValue = row[emitKey];
        surveyResponseStore.updateFilter(emitKey, [filterValue]);
      }
    };

    const xprops = {
      title: t("interviews_by_qa_status"),
      data: surveysByQaStatus,
      onRowClick: row_click,
      coldef: columnDefinition,
    };

    return (
      <>
        <div className="flex flex-col w-full overflow-x :hidden">
          <label className="block text-base font-medium text-gray-700 font-bold">
            {t("interviews_by_qa_status")}
          </label>
          <div
            className={`bg-white rounded-tl-lg rounded-tr-lg`}
            style={{ width: isMobile ? "100%" : "auto", height: "500px" }}
          >
            <DataGridPro
              className="rnr-data-grid"
              rows={surveysByQaStatus.map((x, ind) => {
                // @ts-ignore
                x["id"] = ind;
                return x;
              })}
              columns={columnDefinition}
              rowCount={surveysByQaStatus.length}
              paginationMode="client"
              initialState={{
                sorting: { sortModel: [{ field: "surveys", sort: "desc" }] },
              }}
              filterMode="client"
              sortingMode="client"
              loading={loading}
              disableMultipleColumnsFiltering={false}
              disableMultipleColumnsSorting
              // @ts-ignore
              disableMultipleSelection
              pagination
              density={"compact"}
              autoPageSize={true}
              // pageSize={20}
              components={
                false
                  ? {
                      Toolbar: GridToolbar,
                    }
                  : {}
              }
              rowHeight={64}
              onRowClick={row_click}
            />
          </div>
          <table className="mb-10 bg-white font-bold rounded-bl-lg rounded-br-lg border-t-2 border-gray-500">
            <tr>
              <td
                className="px-2 py-2"
                style={{ width: "330px", height: "50px" }}
              >
                {t("total")}
              </td>
              <td
                className="px-2 py-2"
                style={{ width: "170px", height: "50px" }}
              >
                {_.sumBy(surveysByUser, "surveys")}
              </td>
            </tr>
          </table>
        </div>

        {/*{surveysByLevel2.length > 0 ? <XgridTable {...xprops}/> : null}*/}
      </>
    );
  };

  const SurveyFilters = (innerprops) => {
    return (
      <>
        <div className="mb-4">
          <div className="flex flex-row">
            <label className="block text-base font-medium text-gray-700 font-bold mr-2">
              {t("filters")}
            </label>
            <a
              className="cursor-pointer text-indigo-500"
              onClick={() => {
                surveyResponseStore.clearAllFilters();
              }}
            >
              {t("clear_all_filters")}
            </a>
          </div>
          {Object.keys(surveyResponseStore.surveyResponseFilters).map(
            (key, index) => {
              return (
                <>
                  <div key={index} className="flex flex-row ml-4">
                    <div className="">{key}</div>
                    <div className="mx-2">:</div>
                    <div className="">
                      {surveyResponseStore.surveyResponseFilters[key]}
                    </div>
                  </div>
                </>
              );
            }
          )}
        </div>
      </>
    );
  };

  const SurveysList = () => {
    const columnDefinition = [
      {
        field: "hashighlights",
        headerName: t("summary", { ns: "task_manager" }),
        hide: false,
        width: 150,
        renderCell: (params) => {
          const hasaudiofile = params.row["hasaudiofile"];
          const hashighlights = params.row["hashighlights"];
          const hasCoordinates = params.row["lat"] & params.row["long"];
          return (
            <div className="flex flex-row">
              {hashighlights === true ? (
                <i
                  className="fa-solid fa-star text-yellow-300 text-2xl"
                  title="Contains Important Highlights"
                />
              ) : null}
              {hasaudiofile === false ? (
                <i
                  className="fa-solid fa-microphone-slash text-red-300 text-2xl"
                  title="Audio not yet synced"
                />
              ) : null}
              {hasCoordinates === 0 ? (
                <div className="" title="No Location">
                  <img
                    src="/images/map-marker-slash.png"
                    style={{ width: 30 }}
                  />
                </div>
              ) : null}
            </div>
          );
        },
      },
      {
        field: "clientid",
        headerName: t("survey_id", { ns: "analytical_summary" }),
        hide: false,
        width: 300,
        renderCell: (params) => {
          const index = _.findIndex(surveyResponseStore.colors, {
            name: params.row.surveyor_name,
          });
          return (
            <>
              {params.value === activeSurveyResponseId ? (
                <i className="fa-solid fa-arrow-right text-green-600" />
              ) : null}

              {
                <i
                  className="fa-solid fa-circle text-xl p-2"
                  style={{
                    color: safe_get(
                      surveyResponseStore.colors[index],
                      "color",
                      "#F0F600"
                    ),
                  }}
                  title={params.row.surveyor_name}
                />
              }
              <span>{params.value}</span>
            </>
          );
        },
      },
      {
        field: "dt_full_date",
        headerName: t("date", { ns: "analytical_summary" }),
        hide: false,
        width: 120,
      },
      {
        field: "dt_start_time",
        headerName: t("time", { ns: "analytical_summary" }),
        hide: false,
        width: 120,
      },
      {
        field: "dt_duration_in_str",
        headerName: t("duration"),
        hide: false,
        width: 120,
      },
      {
        field: "qa_status",
        headerName: t("qa"),
        hide: false,
        width: w1,
        renderCell: (params) => {
          return (
            <div>
              {params.value === "valid" ? (
                <i
                  className="fa-solid fa-check-circle text-green-600 text-2xl"
                  title="valid"
                ></i>
              ) : null}
              {params.value === "invalid" ? (
                <i
                  className="fa-solid fa-exclamation-circle text-red-600 text-2xl"
                  title="in-valid"
                ></i>
              ) : null}
              {params.value === "not-checked" ? (
                <i
                  className="fa-solid fa-minus-circle text-yellow-500 text-2xl"
                  title="not-checked"
                ></i>
              ) : null}
              {params.value === "onhold" ? (
                <i
                  className="fa-solid fa-pause-circle text-indigo-500 text-2xl"
                  title="not-checked"
                ></i>
              ) : null}
            </div>
          );
        },
      },
    ];

    const row_click = (param, event) => {
      const { row } = param;
      const { clientid } = row;
      if (clientid) {
        surveyResponseStore.setActiveSurveyResponseId(clientid);
      }
    };

    return (
      <>
        <div className="flex flex-col">
          <label className="block text-base font-medium text-gray-700 font-bold">
            {t("list_of_interviews")}
          </label>

          {surveysList.length > SURVEY_LIST_THRESHOLD ? (
            <div className="italic">
              {t("selecting_filters")} {SURVEY_LIST_THRESHOLD},{" "}
              {t("survey_list_will_be_displayed")}.
            </div>
          ) : (
            <div
              className={`bg-white rounded-tl-lg rounded-tr-lg`}
              style={{ height: "1000px" }}
            >
              <DataGridPro
                className="rnr-data-grid"
                rows={surveysList.map((x, ind) => {
                  // @ts-ignore
                  x["id"] = ind;
                  return x;
                })}
                columns={columnDefinition}
                rowCount={surveysList.length}
                paginationMode="client"
                filterMode="client"
                sortingMode="client"
                // loading={surveyResponseStore.loading}
                disableMultipleColumnsFiltering={false}
                disableMultipleColumnsSorting
                // @ts-ignore
                disableMultipleSelection
                pagination
                density={"compact"}
                autoPageSize={true}
                pageSize={20}
                components={
                  false
                    ? {
                        Toolbar: GridToolbar,
                      }
                    : {}
                }
                rowHeight={64}
                onRowClick={row_click}
                getRowClassName={(params) => {
                  const classes = [];
                  try {
                    const { row } = params;
                    const { clientid } = row;
                    if (
                      clientid === surveyResponseStore.activeSurveyResponseId
                    ) {
                      classes.push("grid_currrent_row");
                    }
                  } catch (e) {}
                  return classes.join(" ");
                }}
                onPageChange={(pg, dt) => {
                  surveyResponseStore.setCurrentPage(pg);
                }}
              />
            </div>
          )}
        </div>
        <div className="flex items-center py-2">
          <label className="font-bold">{t("show_map")}: </label>
          <Switch
            checked={enabled}
            onChange={setEnabled}
            className={classNames(
              enabled ? "bg-indigo-600" : "bg-gray-200",
              "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            )}
          >
            <span className="sr-only">{t("use_setting")}</span>
            <span
              aria-hidden="true"
              className={classNames(
                enabled ? "translate-x-5" : "translate-x-0",
                "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
              )}
            />
          </Switch>
        </div>
        {/*{enabled ? <Maps isMarkerShown={true} data={surveysList} /> : null}*/}
      </>
    );
  };

  return (
    <div className="">
      {loading ? <LoadingState /> : null}

      {surveyResponseStore.hasFilters() ? <SurveyFilters /> : null}

      <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 gap-4">
        <SurveysByUser />
        <SurveysByDate />
        <SurveysByHour />
        {surveyResponseStore.hasKey("profile_caste") ? (
          <SurveysByCaste />
        ) : null}
        {surveyResponseStore.hasKey("piv_level1") ? <SurveysByLevel1 /> : null}
        {surveyResponseStore.hasKey("piv_level2") ? <SurveysByLevel2 /> : null}
        <SurveysByQaStatus />
        {surveyResponseStore.hasKey("hasaudiofile") ? <SurveysByAudio /> : null}
      </div>
      <SurveysList />
    </div>
  );
});
