import React, { useEffect, useState } from "react"
import { ErrorMessage, Field, Form, Formik } from "formik";
import { observer } from "mobx-react-lite"
import { useStore } from "../../../helpers/helpers";
import * as Yup from 'yup';
import { uploadFile } from "../../../helpers/file-upload";
import { AttachmentsList, htmlValidation, makeid_alpa_numeric, remove_item_from_array } from "../../../helpers/utils";
import { useParams } from "react-router-dom";
import {TextError} from "../../../components/formik";
import {FormButtons} from "../../../components/cm-components";

const AddEditWhatsapp = observer((props: any) => {
    const {projectid} = useParams()
    const rootStore = useStore();
    const { voterReachOutStore } = rootStore
    const { formType, whatsappMessageFormValue,messageType } = voterReachOutStore
    const [inputKey, setInputKey] = useState('');
    const [file, setFile] = useState({});
    const [buffer, setBuffer] = useState([]);
    const [whatsappSubmit, setWhatsappSubmit] = useState(false)

    const { formSubmit,type } = props

    const path = `project-${projectid}/voterreachout/whatsapp`
    const smsModel = {
        "projectid": projectid,
        "channel": type,
        "more": {}
    }
    const whatsappMessageFormInitialValues = {
        name: whatsappMessageFormValue.name,
        message: whatsappMessageFormValue.message,
    }
    const whatsappMessageFormSubmit = async (values) => {
        if (buffer.length > 0 && whatsappSubmit) {
            let Attachments = []

            for (let ele of buffer) {
                Attachments.push({ path: ele.path, id: ele.id, type: ele.type })
            }

            const smsPayload = {
                ...smsModel,
                ds: {
                    name: values.name,
                    text: values.message,
                    attachements: Attachments
                }
            }
            formSubmit(smsPayload, whatsappMessageFormValue.id)
        }
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        message: Yup.string().required('Message is required')
    })

    const delete_file = name => {
        const updated_values = remove_item_from_array(buffer, "path", name)
        setBuffer(updated_values)
    }

    useEffect(() => {
        if (messageType === "whatsapp Messages" && formType === "update") {
            setBuffer(whatsappMessageFormValue.attachements)
        }
    }, [messageType, formType])


    return (
        <>
            <Formik
                initialValues={whatsappMessageFormInitialValues}
                onSubmit={whatsappMessageFormSubmit}
                validationSchema={validationSchema}
            >
                <Form className="rounded-lg text-center">
                    <div className={"form-control  sm:grid sm:grid-cols-  sm:items-start d-flex flex-column text-left"}>
                        <label htmlFor="name" className="block text-gray-700 rounded-lg text-left font-semibold">Name</label>
                        <div className='flex flex-row items-center'>
                            <Field
                                type="text"
                                id="name"
                                name="name"
                                className="px-4 py-2 rounded-lg border border-gray-300 w-full"
                            />
                        </div>
                        <div className="flex flex-row">
                            <ErrorMessage name="name" component={TextError} />
                        </div>
                    </div>
                    <div className=" sm:grid sm:grid-cols-1  sm:items-start d-flex flex-column text-left">
                        <label htmlFor="message" className="block text-gray-700  rounded-lg text-left font-semibold">Message</label>
                        <div className='flex flex-row items-center min-h-80 max-h-full'>
                            <Field
                                as="textarea"
                                id="message"
                                name="message"
                                className="px-4 py-2 rounded-lg border border-gray-300 w-full h-40"
                            />
                        </div>
                        <div className="flex flex-row">
                            <ErrorMessage name="message" component={TextError} />
                        </div>
                    </div>
                    <div className="flex flex-row justify-between items-end flex-wrap ml-1">
                        <div className="flex flex-col">
                            <label htmlFor="project-name" className="block text-gray-700 pt-4 rounded-lg text-left font-semibold">Attachments</label>
                            <input
                                key={inputKey}
                                type="file"
                                name="attachments"
                                id="attachments"
                                className="py-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                onChange={e => {
                                    const file = e.target.files[0];
                                    setFile({ ...file, file })
                                }}
                            />
                        </div>
                        <button
                            className="justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                            onClick={(e) => {
                                htmlValidation(e)
                                if (!file["file"]) return false;

                                const s3Path = uploadFile(path, file, "urn-assets");
                                if (s3Path) {
                                    setFile({})
                                    const randomString = Math.random().toString(6);
                                    setInputKey(randomString);
                                }
                                const file_type = file["file"]["type"]
                                setBuffer([...buffer, { path: s3Path, type: file_type, id: makeid_alpa_numeric(8) }])
                            }}
                        >
                            Upload
                        </button>
                    </div>
                    {buffer.length <= 0 && whatsappSubmit && <div className="error text-sm text-red-600">
                        Please upload a file
                    </div>}

                    <div className="p-2 flex flex-col justify-between itesm-center">
                        {buffer.length > 0 &&
                            <div className="font-bold">Uploaded Documents</div>}
                        {buffer.map((file) => {
                            return <AttachmentsList file={file} onDelete={delete_file} />
                        })}
                    </div>
                    <FormButtons
                        onSave={() => setWhatsappSubmit(true)}
                        onCancel={() => {
                            voterReachOutStore.set_isSideOverlay(false)
                        }}
                    />
                </Form>
            </Formik>
        </>
    )
}
)

export default AddEditWhatsapp;