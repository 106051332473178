import {observer} from "mobx-react-lite";
import React, {useEffect, useState} from "react";
import {useStore} from "../../helpers/helpers";
import {toJS} from "mobx";
import {Loading} from "./loading";
import {RNConstants} from "../../helpers/constants";
import {GridDensityTypes, GridToolbar, LicenseInfo, XGrid} from '@material-ui/x-grid';
import {GridCustomCellRenderer} from "../grid-custom-cell-renderer";
import {reduce_for_visual, safe_get} from "../report-utils";
import {ClearFiltersForVisual} from "./clearfilters";

export const SimpleTable = observer((props: any) => {
    LicenseInfo.setLicenseKey(RNConstants.XGRID_KEY);

    const rootStore = useStore();
    const {reportStore} = rootStore;
    const {currentReportData} = reportStore;

    const {section, depth} = props;
    const {id, type, settings, args} = section;
    const {title} = settings;

    const [dt, setdt] = useState([]);
    const [transformed, setTransformed] = useState(false);
    const [loading, setLoading] = useState(false);

    const {reducers, ds, headers, display} = args;
    const renderType = args['render'];

    const defaultFilter = {"enabled": false, "emit": []};
    const filterSettings = safe_get(toJS(args), "filter", defaultFilter);
    const {enabled, emit} = filterSettings;

    useEffect(() => {
        refresh_data()
    }, [reportStore.currentReportFilters]);

    const refresh_data = () => {
        setLoading(true);
        let current = reduce_for_visual(toJS(currentReportData[ds]), reducers, reportStore.getSubscribedFilters(emit));
        setdt(current);
        setTransformed(true);
        setLoading(false);
    };

    const render_simple_table = () => {
        return (
            <>
                <div
                    style={{
                        maxWidth: safe_get(display, 'width', '100%'),
                        maxHeight: safe_get(display, 'height', '100%')
                    }}
                    className={`flex flex-col ${safe_get(display, 'rootclasses')}`}
                >
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div
                                className="shadow-lg rounded-lg overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                    <tr>
                                        {
                                            Object.keys(headers).map(headerKey => {
                                                const headerTitle = headers[headerKey];
                                                return (
                                                    <th
                                                        scope="col"
                                                        className="font-bold px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        {headerTitle}
                                                    </th>
                                                );
                                            })
                                        }
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {dt.map((tuple, tupleIdx) => (
                                        <tr className={tupleIdx % 2 === 0 ? 'cursor-pointer bg-white' : 'cursor-pointer bg-gray-50'}>
                                            {
                                                Object.keys(headers).map((headerKey) => {
                                                    return (
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                            {tuple[headerKey]}
                                                        </td>
                                                    );
                                                })
                                            }
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const render_xgrid = () => {
        const defaultXGridSettings = {
            "density": GridDensityTypes.Compact, //'compact' | 'standard' | 'comfortable'
            "autoPageSize": false,
            "pageSize": 10,
            "topToolBar": true,
            "rowHeight": 64
        };

        let xgrid_setttings = defaultXGridSettings;
        if (args['xgrid_setttings']) {
            xgrid_setttings = args['xgrid_setttings'];
        }

        const {density, autoPageSize, pageSize, topToolBar, rowHeight} = xgrid_setttings;

        let custom_render = {};
        if (args['custom_render']) {
            custom_render = args['custom_render'];
        }

        let column_def = {};
        if (args['column_def']) {
            column_def = args['column_def'];
        }

        const getDefaultCol = (field, display) => {
            return {
                field: field,
                headerName: display,
                resizable: true,
                sortable: true,
                filterable: true,
                width: 140
            };
        }

        const idcol = {
            field: 'id',
            headerName: 'ID',
            hide: true,
        };

        const CustomRenderer = new GridCustomCellRenderer()

        const defaultColumnDef = [idcol];
        Object.keys(headers).map((field, index) => {
            const display = headers[field];
            let col = getDefaultCol(field, display)
            if (Object.keys(column_def).includes(field)) {
                col = column_def[field];
            }

            if (Object.keys(custom_render).includes(field)) {
                const configured_renderer = custom_render[field];
                col["renderCell"] = CustomRenderer[configured_renderer];
            }
            // @ts-ignore
            defaultColumnDef.push(col);
        });

        let showTitle = true;

        return (
            <>
                <div
                    className={`mb-10 bg-white shadow-lg rounded-lg ${safe_get(display, 'rootclasses')}`}
                    style={{width: safe_get(display, 'width', '100%'), height: safe_get(display, 'height', '100%')}}
                >
                    <div className='flex flex-row mx-auto'>
                        {showTitle ? (<div className='font-bold'>{title}</div>) : null}
                        {loading ? (<Loading/>) : null}
                    </div>

                    <XGrid
                        className='rnr-data-grid'
                        rows={dt.map((x, ind) => {
                            // @ts-ignore
                            x['id'] = ind;
                            return x;
                        })}
                        columns={defaultColumnDef}
                        rowCount={dt.length}
                        paginationMode="client"
                        filterMode="client"
                        sortingMode="client"
                        loading={loading}
                        disableMultipleColumnsFiltering={false}
                        disableMultipleColumnsSorting
                        disableMultipleSelection
                        pagination
                        density={density}
                        autoPageSize={autoPageSize}
                        pageSize={pageSize}
                        components={topToolBar ? {
                            Toolbar: GridToolbar,
                        } : {}}
                        rowHeight={rowHeight}
                        onRowClick={row_click}
                    />

                    <ClearFiltersForVisual/>

                </div>
            </>
        );
    }

    const row_click = (param, event) => {
        const filterSettings = safe_get(toJS(args), "filter", defaultFilter);
        const {enabled, emit} = filterSettings;
        if (!enabled) return;

        //Check if row contains any of the emit parameters
        //Right now assume only 1 parameter which is emit[0]
        if (emit.length != 1) return;
        const emitKey = emit[0];
        const {row} = param;
        if (Object.keys(row).includes(emitKey)) {
            //Set filter in central store
            const filterValue = row[emitKey];
            reportStore.updateFilter(emitKey, [filterValue]);
        }
    };

    return (
        <>
            {(transformed && renderType === 'simple-table') ? (render_simple_table()) : null}
            {(transformed && renderType === 'xgrid') ? (render_xgrid()) : null}
        </>
    );

});