import * as React from 'react';
import {observer} from "mobx-react-lite";
import {useEffect, useState} from "react";
import {useStore} from "../../helpers/helpers";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {TaskRowRender} from "./task-row-renderer";
import {Pagination, Stack} from "@mui/material";


export const TaskList = observer((props: any) => {

    const rootStore = useStore();
    const {taskManagerStore} = rootStore;
    const {task_filters:{userList,status,pivLevel1}, task_count_per_page, tasklist_current_page_number} = taskManagerStore;
    const params = useParams();
    const [t] = useTranslation("task_manager");
    const projectid = params.projectid;
    const [total, setTotal] = useState(0);
    const totalPages = Math.ceil( total/ task_count_per_page );

    const handlePageChange = async(event: React.ChangeEvent<unknown>, value: number) => {
        taskManagerStore.update_tm_store_prop("tasklist_current_page_number", value);
        window.sessionStorage.setItem("pageno", value.toString());
    };

    useEffect(() => {
        fetch_task_list();
        // const current = window.sessionStorage.getItem("pageno");
        // if(current !== "0"){
        //     taskManagerStore.update_tm_store_prop("tasklist_current_page_number", current);
        // }
        // //TODO: reset the currentPage value when you leave task manager.
        // return () => {
        //     window.sessionStorage.setItem("pageno", "0");
        // }
    }, [projectid,userList,status,pivLevel1,tasklist_current_page_number]);

    const fetch_task_list = async () => {
        const res = await taskManagerStore.get_task_list(projectid);
        if (res) {
            setTotal(res.total)
        }
    }

    return (
        <>
            <div className="-mx-4 mt-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
                <Stack sx={{backgroundColor: "white", padding:"10px"}} spacing={2} direction={"row"} alignItems={"center"} >
                    <div className="font-bold text-gray-500">Page: {tasklist_current_page_number}</div>
                    <Pagination color={"primary"} size={"medium"} variant={"outlined"} count={totalPages} page={tasklist_current_page_number} onChange={handlePageChange} />
                </Stack>
                {/*<TaskPagination />*/}
                <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                    <tr>
                        <th scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">{t("task_name")}</th>
                        <th scope="col"
                            className="hidden py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 sm:table-cell">{t("region")}</th>
                        <th scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">{t("assigned_to")}</th>
                        <th scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">{t("no.of_interviews")}</th>
                        <th scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">{t("field_work_progress")}</th>
                        <th scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">{t("qa_progress")}</th>
                        <th scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">{t("summary")}</th>
                        {/*<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6"><span className="sr-only">Edit</span></th>*/}
                    </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white cursor-pointer">
                    {taskManagerStore.filtered_task_list_display.map((task) => <TaskRowRender key={`task_root_${task.id}`} task={task}/>)}
                    </tbody>
                </table>
                <Stack sx={{backgroundColor: "white", padding:"10px"}} spacing={2} direction={"row"} alignItems={"center"} >
                    <div className="font-bold text-gray-500">Page: {tasklist_current_page_number}</div>
                    <Pagination color={"primary"} size={"medium"} variant={"outlined"} count={totalPages} page={tasklist_current_page_number} onChange={handlePageChange} />
                </Stack>
                {/*<TaskPagination />*/}
            </div>
        </>
    )
})