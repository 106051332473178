import React, { useEffect, useState } from "react";
import { ACBankDAO } from "./acbank_dao";
import { useStore } from "../../../helpers";
import { safe_get } from "../../../report-visuals/report-utils";
import { useNavigate } from "react-router-dom";

const cards = [
  {
    name: "Caste Summary",
    value: "caste",
    icon: "fa-solid fa-file-alt text-green-500",
    bgcolor: "#ABEBC6",
    to: "caste_summary",
  },
  {
    name: "Constituency Maps",
    value: "maps",
    icon: "fa-solid fa-map text-yellow-500",
    bgcolor: "#EDBB99",
    to: "constituency_map",
  },
  {
    name: "Past Election Results",
    value: "election_results",
    icon: "fa-solid fa-layer-group text-blue-500",
    bgcolor: "#AED6F1",
    to: "past_results",
  },
  {
    name: "Influential People",
    value: "influential_people",
    icon: "fa-solid fa-user-friends text-purple-500",
    bgcolor: "#BB8FCE",
    to: "influential_people",
  },
  {
    name: "Timeline",
    value: "timeline",
    icon: "fa-solid fa-clock text-red-500",
    bgcolor: "#F5B7B1",
    to: "timeline",
  }
];

export const ACBankCardsNav = (props) => {

  const rootStore = useStore();
  const { flexiStore } = rootStore;
  const dao = new ACBankDAO(rootStore);
  const acno = dao.get_summary_field("acno", 0);
  const state = safe_get(dao, "fsd.key", "");
  const navigate = useNavigate();

  const [primary_image_uri, set_primary_image_uri] = useState("");

  useEffect(() => {
    load_primary_image();
  }, []);

  const load_primary_image = async () => {
    const img_uri = await dao.get_primary_map_image();
    set_primary_image_uri(img_uri);
    console.log(img_uri);
  };

  return (
    <>
      <div
        className="acbank"
        style={
          {
            //     background: `linear-gradient(0deg, rgba(86, 101, 115, 0.3), rgba(86, 101, 115, 0.6)), url(${primary_image_uri})`,
          }
        }
      >
        <div className="grid grid-cols-1 gap-4 xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-2 cursor-pointer p-4">
          {cards.map((card, index) => {
            return (
              <div key={`card_${index}`}>
                <div
                  className="p-4 rounded-lg bg-blue-200 shadow-md hover:shadow-xl flex flex-row items-center"
                  style={{ backgroundColor: card.bgcolor }}
                  onClick={(evt) => {
                    flexiStore.set_ac_number(acno);
                    flexiStore.set_fsd_search_key(state);
                    navigate(`${card.to}`);
                  }}
                >
                  <i className={`${card.icon} text-4xl pr-2`} />
                  <div className="font-bold text-xl">{card.name}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
