import React, { useEffect, useState } from "react";
import _ from "lodash";
import ProfileQuestions from "./components/ProfileQuestions";
import OptionsTab from "./components/OptionsTab";
import { observer } from "mobx-react-lite";
// import questionStore from "./store/QuestionStore";
import { toJS } from "mobx";
import { useStore } from "../helpers";
// this tabs renders questions and its options side by side

interface QuestionsWindowProps {
  onSave: (questions: object) => void;
}
const QuestionsWindow: React.FC<QuestionsWindowProps> = observer(
  ({ onSave }) => {
    const rootStore = useStore();
    const { questionStore } = rootStore;
    // watches the current tab to highlight it on clicked
    const activeTab = questionStore.activeTab;
    // called from Options tab when changes are finalized
    function handleSave() {
      console.log("clicked handlesave");
      onSave(toJS(questionStore.getQuestions));
    }
    return (
      <div className=" flex flex-wrap h-screen">
        <div className="w-full sm:w-[30%] ">
          <ProfileQuestions />
        </div>
        <div className=" sm:w-[70%] ">
          <OptionsTab handleSave={handleSave} />
        </div>
      </div>
    );
  }
);

export default QuestionsWindow;
