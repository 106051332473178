import React, {useMemo, useState} from "react";
import {observer} from "mobx-react-lite";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {useParams} from "react-router";
import {CMTable, DeleteModal, LoadingState, ViewAttachment} from "../../components";
import {toast} from "react-toastify";
import {Avatar, IconButton, Stack} from "@mui/material";
import {C3StarRating} from "../constituency-command-center";
import {InfluentialLeaderCard} from "./il-card";
import {useWindowSize} from "react-use";
import {useStore, htmlValidation} from "../../helpers";
import {ILPagination} from "./il-paination";
import {safe_get} from "../../report-visuals/report-utils";

export const InfluentialLeadersList = observer((props: any) => {

    const rootStore = useStore();
    const {influentialLeadersStore, userStore} = rootStore;
    const {searchQuery, delteILModal, offset, count} = influentialLeadersStore;
    const {projectid} = useParams();
    const queryClient = useQueryClient();
    const {width} = useWindowSize();
    const isMobile = width <= 766;

    const isAllowed = userStore.isClient() || userStore.isRoot();

    const [state, setState] = useState({});

    const payload = {
        projectid: parseInt(projectid),
        query: searchQuery,
        offset,
        count,
        filters: influentialLeadersStore.ilFilters,
        query_criteria: "or"
    }

    const getInfluentialLeaders = async () => {
        const res = await influentialLeadersStore.getInfluentialLeaders(payload);
        if (!res.ok) {
            throw new Error('Failed to fetch Influential Leaders data');
        }
        return res;
    }

    const {data, isLoading, isError, error} = useQuery({
            queryKey: ["influential-leaders", projectid, payload.filters, searchQuery, offset, count],
            queryFn: getInfluentialLeaders
        }
    )

    const onDeleteInfluentialLeader = async (id) => {
        const res = await influentialLeadersStore.deleteInfluentialLeader({id})
        if (res.ok) {
            return res;
        } else {
            toast.error("Something went wrong")
        }
    }

    const {mutate, isLoading: isDeleting,} = useMutation({
        mutationFn: onDeleteInfluentialLeader,
        onSuccess: async () => {
            setTimeout(async () => {
                await queryClient.invalidateQueries(["influential-leaders", projectid, influentialLeadersStore.ilFilters])
            }, 1000);
            closeDeleteModal();
            toast.success("Deleted Successfully")
        }
    })

    const closeDeleteModal = () => {
        influentialLeadersStore.updateIlStoreProperty("delteILModal", false)
    }

    if (isError) { // @ts-ignore
        return <p className={"p-4 italic text-red-700 flex items-center"}>{error.message}</p>
    }

    const onILEditClickHandler = (value) => {
        influentialLeadersStore.updateIlStoreProperty("activeInfluentialLeader", value)
        influentialLeadersStore.updateIlStoreProperty("addNewILModal", true)
    }

    const onILDeleteClickHandler = (value) => {
        influentialLeadersStore.updateIlStoreProperty("delteILModal", true)
        setState(value)
    }

    const onILRowClickHandler = (value) => {
        influentialLeadersStore.updateIlStoreProperty("activeInfluentialLeader", value)
        influentialLeadersStore.updateIlStoreProperty("viewILModal", true)
    }


    if(isError) toast.error("Something went wrong...")
    if (isLoading || isDeleting) return <LoadingState/>
    if(data?.data && data?.data?.results?.length <= 0) return <p>Data not found</p>

    return (
        <>
            <ILPagination count={safe_get(data?.data, "total_count", 0)}/>
            {!isMobile ? <InfluentialLeadersTable
                data={data.data?.results}
                editCallBack={onILEditClickHandler}
                deleteCallBack={onILDeleteClickHandler}
                isAllowed={isAllowed}
                onRowClick={onILRowClickHandler}
            /> : <ILMobileView
                data={data.data?.results}
                onRowClick={onILRowClickHandler}
                editCallBack={onILEditClickHandler}
                deleteCallBack={onILDeleteClickHandler}
            />
            }
            {delteILModal ? <DeleteModal
                isOpen={delteILModal}
                onDeleteClick={() => mutate(state["id"])}
                onCancelClick={closeDeleteModal}
                desc={`Do you want to delete ${state["profile"]["name"]}`}
            /> : null}
            <InfluentialLeaderCard/>
        </>
    )
})

const InfluentialLeadersTable = ({data, editCallBack, deleteCallBack, isAllowed, onRowClick}) => {

    const columns = useMemo(() => [
        {
            Header: "Name",
            accessor: 'profile.photo_path',
            Cell: props => {
                return (
                    <Stack direction={"row"} alignItems={"center"} gap={2}>
                        <Avatar
                            sx={{width: 100, height: 100}}
                            title={props.cell.row?.original?.profile.name}
                        >
                            {typeof props.cell.value !== "string" ?
                                <ViewAttachment label={false} attachment={props.cell.value}/> : null}
                        </Avatar>
                        <p className={"font-bold"}>{props.cell.row?.original?.profile.name}</p>
                    </Stack>
                )
            }
        },
        {
            Header: "Phone Number",
            accessor: 'profile.phonenumber'
        }, {
            Header: "Whatsapp Number",
            accessor: 'profile.whatsapp_number'
        },
        {
            Header: "Party Support",
            accessor: 'affiliation.party'
        }, {
            Header: "Position",
            accessor: 'volunteer.position'
        }, {
            Header: "Caste",
            accessor: 'influence.caste'
        },
        {
            Header: "Booth/Village",
            accessor: 'node.display'
        }, {
            Header: "Occupation",
            accessor: 'more.primary_occupation'
        },
        {
            Header: "Rating",
            accessor: 'profile.rating',
            show: isAllowed,
            Cell: props => {
                return (
                    <Stack direction={"row"} alignItems={"center"} gap={2}>
                        <C3StarRating
                            value={parseFloat(props.cell.value)}
                            isEditable={false}
                            onStarClickHandler={(nextValue) => {
                            }}
                        />
                    </Stack>
                )
            }
        }
    ], [data])

    return (
        <CMTable
            data={data}
            columns={columns}
            pagesize={20}
            onRowClick={onRowClick}
            hideFooter={true}
            highlightRow={true}
            show_action_buttons={true}
            onEdit={editCallBack}
            onDelete={deleteCallBack}
        />
    )
}

const ILMobileView = ({data, editCallBack, deleteCallBack, onRowClick}) => {
    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="-mx-4 mt-8 flow-root sm:mx-0">
                <table className="min-w-full">
                    <colgroup>
                        <col className="w-full sm:w-1/2"/>
                        <col className="sm:w-1/6"/>
                        <col className="sm:w-1/6"/>
                        <col className="sm:w-1/6"/>
                    </colgroup>
                    <thead className="border-b border-gray-300 text-gray-900">
                    <tr>
                        <th scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                            Leader
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {data.map((project) => (
                        <tr key={project.id} className="border-b border-gray-200">
                            <td onClick={() => onRowClick(project)}
                                className="max-w-0 py-5 pl-4 pr-3 text-sm sm:pl-0">
                                <Stack direction={"row"} className={"gap-x-4"} justifyContent={"space-between"}>
                                    <Stack direction={"row"} alignItems={"center"} className={"gap-x-4"}>
                                        <Avatar
                                            sx={{width: 80, height: 80}}
                                            title={project?.profile?.name}
                                        >
                                            {typeof project?.profile?.photo_path !== "string" ?
                                                <ViewAttachment label={false}
                                                                attachment={project?.profile?.photo_path}/> : null}
                                        </Avatar>
                                        <Stack>
                                            <div className="font-bold text-gray-900">{project?.profile?.name}</div>
                                            <div className=" text-gray-900">{project?.profile?.phonenumber}</div>
                                            <div className=" text-gray-900">{project.influence?.caste}</div>
                                            <div className="text-gray-900">{project.affiliation?.party}</div>
                                        </Stack>
                                    </Stack>
                                    <Stack direction={"row"} alignItems={"center"} className={"gap-x-4"}>
                                        <IconButton
                                            aria-label="edit"
                                            onClick={(e) => {
                                                htmlValidation(e);
                                                editCallBack(project)
                                            }}
                                            color={"info"}
                                            size={"small"}
                                            sx={{marginRight: 1}}
                                        >
                                            <i className={"fas fa-edit"}/>
                                        </IconButton>
                                        <IconButton
                                            aria-label="delete"
                                            onClick={(e) => {
                                                htmlValidation(e);
                                                deleteCallBack(project)
                                            }}
                                            color={"error"}
                                            size={"small"}
                                            sx={{marginLeft: 1}}
                                        >
                                            <i className={"fas fa-trash"}/>
                                        </IconButton>
                                    </Stack>
                                </Stack>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}