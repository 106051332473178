import { observer } from "mobx-react-lite";
import React from "react";
import {FormButtons, SideOverlay} from "../../../components";
import { useStore } from "../../../helpers";
import {FormProvider, useForm} from "react-hook-form";
import {RNInput} from "../../../components/form-components";
import {ProjectWorkFundDefaultValues} from "../pw-v2-types";


export const AddFundsV2 = observer((props: any) => {

    const { projectWorkStoreV2 } = useStore()
    const { add_fund_modal} = projectWorkStoreV2;


    const close_modal = async () => {
        projectWorkStoreV2.update_project_work_property("add_fund_modal",false);
    }

    const onSubmit = (data) => {
        console.log(data)
    }
    const methods = useForm({
        defaultValues : ProjectWorkFundDefaultValues,
        mode: 'onSubmit'
    })

    return (
        <div>
            <SideOverlay
                onClose={close_modal}
                show={add_fund_modal}
                title={"Add Funds"}
            >
                <FormProvider{...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)} className="bg-white px-4 py-2 rounded-sm flex flex-col gap-y-4">
                        <RNInput
                          label={"Title"}
                          name = {"title"}
                        />
                        <RNInput
                        label={"Amount"}
                        name = {"amount"}
                        type={"number"}
                        />
                        <RNInput
                        label={"Date"}
                        name={"date"}
                        type={"date"}
                        />
                        <RNInput
                            label={"Additional Information"}
                            name={"additional information"}
                            multiline
                            rows={3}
                            fullWidth
                        />
                        <FormButtons
                            onSave={() => {
                            }}
                            onCancel={close_modal}
                        />
                    </form>
                </FormProvider>
            </SideOverlay>
        </div>
    );
});
