import React from "react"
import {isEmpty} from "lodash";
import {DataNotAvailable} from "./mh-2024-fieldview-main-page";

export const MHDemographics = ({data}) => {

    if(isEmpty(data)) return <DataNotAvailable />

    return (
        <>

        </>
    )
}