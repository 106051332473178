import React from "react";


export const ErrorBoundaryFallBack = ({ error, resetErrorBoundary }) => {
    console.log(error)
    return (
        <div className="flex flex-col items-center bg-white justify-center h-[100vh]">
            <h1 className="text-4xl font-bold mb-4 text-red-600">Oops!</h1>
            <p className="text-lg text-gray-700 mb-8">Something went wrong.</p>
            <div className="max-w-5xl max-h-96">
                <img src="/images/error.png" alt="Error Icon" className="mb-4 h-full w-full" />
            </div>
            <button
                className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 focus:outline-none"
                onClick={() => window.location.reload()}
            >
                Refresh Page
            </button>
        </div>
    );
}