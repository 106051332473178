import {action, makeAutoObservable} from "mobx";
import {defaultIlFilters, defaultILValues, IlFilters, IlType} from "../../features/influential-leaders/il-types";
import {IlStorePropertyKeys} from "./il-types";


export class InfluentialLeadersStore {

    rootStore: any;

    constructor(rootStore: any) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    searchQuery: string = "";
    viewILModal: boolean = false;
    addNewILModal: boolean = false;
    ilFiltersModal: boolean = false;
    delteILModal: boolean = false;
    activeInfluentialLeader: IlType = defaultILValues;
    ilFilters: IlFilters = defaultIlFilters;

    //ToDO: Pagination
    offset: number = 0;
    count: number = 15;

    //TODO: It is to update any store variable, instead of writing individual functions to just update the value, this is the best approach I beleive.
    @action
    updateIlStoreProperty(key: IlStorePropertyKeys, value: any) {
        switch (key) {
            case "addNewILModal":
                this.addNewILModal = value;
                break;
            case "searchQuery":
                this.searchQuery = value;
                break;
            case "activeInfluentialLeader":
                this.activeInfluentialLeader = value;
                break;
            case "ilFiltersModal":
                this.ilFiltersModal = value;
                break;
            case "ilFilters":
                this.ilFilters = value;
                break;
            case "viewILModal":
                this.viewILModal = value;
                break;
            case "offset":
                this.offset = (value - 1) * this.count
                break;
            case "delteILModal":
                this.delteILModal = value;
                break;
        }
    }

    async getInfluentialLeaders(payload) {
        return await this.rootStore.api.post("influencersV2/influencer_search", payload)
    }

    async createInfluentialLeader(payload) {
        return await this.rootStore.api.post("influencersV2/add_influencer_leader", payload)
    }

    async updateInfluentialLeader(payload) {
        return await this.rootStore.api.post("influencersV2/influencers_update", payload)
    }

    async deleteInfluentialLeader(payload) {
        return await this.rootStore.api.post("influencersV2/influencers_delete", payload)
    }
}