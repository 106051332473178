import React, {useState} from "react";
import {VillageFilter} from "../../components/cm-filters";
import {FormButtons, SideOverlay} from "../../components";
import {useStore} from "../../helpers";
import {observer} from "mobx-react-lite";
import Select from "react-select";
import {safe_get} from "../../report-visuals/report-utils";
import {Button, Stack, Typography} from "@mui/material";
import {defaultIlFilters, IlFilters} from "./il-types";

export const createOption = (label: string) => ({
    label,
    value: label.replace(/\W/g, ''),
});

const IlFilterSelect = ({label, options, value, onFiltersChange}) => {
    let opts = options.map(option => typeof option === "string" ? createOption(option) : option);
    return(
        <Stack>
            <Typography color={"primary"} variant={"subtitle1"}>{label}</Typography>
            <Select
                isClearable
                isMulti
                options={opts}
                value={value}
                onChange={(selectedItem) => onFiltersChange(selectedItem)}
            />
        </Stack>
    )
}

export const ILFilters = observer((props:any) => {

    const rootStore = useStore();
    const {influentialLeadersStore, projectStore} = rootStore;
    const {ilFilters} = influentialLeadersStore;
    const il_properties = projectStore.getProjectProperty("influential_leaders", {});
    const profile_properties = projectStore.getProjectProperty("profile_questions", {});
    const caste_options = safe_get(profile_properties, "caste", [])
    const party_options = safe_get(il_properties, "party_support", [])
    const party_roles_options = safe_get(il_properties, "party_roles", [])
    const candidate_options = safe_get(il_properties, "candidate_support", [])
    const support_strength_options = safe_get(il_properties, "support_strength", []);

    const closeFilterModal = () => {
        influentialLeadersStore.updateIlStoreProperty("ilFiltersModal", false)
    }

    const [filters, setFilters] = useState<IlFilters>({
        party_support: safe_get(ilFilters, "party_support", []),
        party_roles: safe_get(ilFilters, "party_roles", []),
        support_strength: safe_get(ilFilters, "support_strength", []),
        candidate_support: safe_get(ilFilters, "candidate_support", []),
        nodes: safe_get(ilFilters, "nodes", []),
        caste: safe_get(ilFilters, "caste", []),
    })

    const onFiltersChange = (key, value) => {

        setFilters((prev) => ({...prev, [key]: value}))
    }

    const filtersSaveHandler = () => {
        const payload = {
            party_support: filters.party_support.map(opt => opt["value"]),
            support_strength: filters.support_strength.map(opt => opt["value"]),
            candidate_support: filters.candidate_support.map(opt => opt["value"]),
            party_roles: filters.party_roles.map(opt => opt["value"]),
            nodes: filters.nodes,
            caste: filters.caste.map(opt => opt["value"]),
        }
        closeFilterModal()
        influentialLeadersStore.updateIlStoreProperty("ilFilters", payload)
    }

    return(
        <>
            <SideOverlay
                onClose={closeFilterModal}
                show={influentialLeadersStore.ilFiltersModal}
                title={"Influential Leaders Filters"}
            >
                <Stack direction={"column"} gap={2}>
                    <IlFilterSelect
                        label={"Party Support"}
                        value={filters.party_support}
                        options={party_options}
                        onFiltersChange={(selectedItem) => onFiltersChange("party_support", selectedItem)}
                    />
                    <IlFilterSelect
                        label={"Support Strength"}
                        value={filters.support_strength}
                        options={support_strength_options}
                        onFiltersChange={(selectedItem) => onFiltersChange("support_strength", selectedItem)}
                    />
                    <IlFilterSelect
                        label={"Candidate Support"}
                        value={filters.candidate_support}
                        options={candidate_options}
                        onFiltersChange={(selectedItem) => onFiltersChange("candidate_support", selectedItem)}
                    />
                    <IlFilterSelect
                        label={"Caste"}
                        value={filters.caste}
                        options={caste_options}
                        onFiltersChange={(selectedItem) => onFiltersChange("caste", selectedItem)}
                    />
                    <IlFilterSelect
                        label={"Party Roles"}
                        value={filters.party_roles}
                        options={party_roles_options}
                        onFiltersChange={(selectedItem) => onFiltersChange("party_roles", selectedItem)}
                    />

                    <VillageFilter
                        value={filters.nodes}
                        villageSelector={"piv_level2"}
                        setValues={(villages) => onFiltersChange("nodes", villages)}
                    />

                    <Stack direction={"row"} alignItems={"center"}>
                        <Button
                            color={"warning"}
                            variant={"contained"}
                            onClick={() => setFilters(defaultIlFilters)}
                        >
                            Reset
                        </Button>
                        <FormButtons
                            onSave={filtersSaveHandler}
                            type={"button"}
                            onCancel={closeFilterModal}
                        />
                    </Stack>
                </Stack>
            </SideOverlay>
        </>
    )
})