import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { toast } from "react-toastify";
import { Popover } from "@headlessui/react";
import { AddEditCampaign } from "./add-edit-Compaign";
import VoterReachOutCampaignOverview from "./voter-reachoutCompaignoverview";
import VoterReachOutListCampaign from "./voter-reachoutListCompaign";
import { useStore } from "../../../helpers/helpers";
import SearchBar from "../../../components/SearchBar";
import {useParams} from "react-router";
import {DeleteModal, SideOverlay} from "../../../components";

export const VoterReachOutCampaigns = observer((props: any) => {
  const [query, setQuery] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const [popOver, setPopOver] = useState(false);
  const { voterReachOutStore } = useStore();
  const {addCampaign, editCampaign, isMessageOverviewOpen, selectedOverViewItem} = voterReachOutStore;
  const {projectid} = useParams();

  const sideLayName = selectedOverViewItem?.name;

  const getReachOutCampaign = () => {
    const getCampaignListPayload = {
      projectid: projectid,
      query: query,
    };

    voterReachOutStore.get_reachout_Campaign(getCampaignListPayload);
  };

  const getReachOutTarget = () => {
    const getTargetListPayload = {
      projectid: projectid,
      query: query,
    };

    voterReachOutStore.get_reachout_target(getTargetListPayload);
  };

  const getReachOutMessages = () => {
    const getMessageListPayload = {
      projectid: projectid,
      query: query,
      channel: "",
      offset: 0,
      count: 100,
    };
    voterReachOutStore.get_reachout_message(getMessageListPayload);
  };

  useEffect(() => {
    getReachOutCampaign();
    getReachOutMessages();
    getReachOutTarget();
  }, [selectedOverViewItem]);

  const handleOverlayClose = () => {
    voterReachOutStore.setAddCampaignStatus(false);
    voterReachOutStore.set_formType("new");
  };

  const deleteHandler = async () => {
    const result = await voterReachOutStore.delete_reachout_campaign({
      id: deleteItem,
    });
    if (result.status === 200) {
      toast.success("Deleted Successfully");
      getReachOutCampaign();
    }
    setIsDeleteModalOpen(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      getReachOutCampaign();
    }
  };

  const handleSearchBarChange = (value) => {
    setQuery(value);
  };

  const onDeleteHandler = (deleteItem) => {
    setIsDeleteModalOpen(true);
    setDeleteItem(deleteItem);
  };

  const handlePopOverState = (bool) => {
    setPopOver(bool);
  };

  const handleCampaignOverviewClose = () => {
    voterReachOutStore.set_IsMessageOverviewOpen(false);
  };

  const header = () => {
    return (
      <div className="flex flex-row items-center mb-4 justify-between">
        <h2 className="font-bold text-2xl text-indigo-500">
          Voter Reach Out Campaigns
        </h2>
        <Popover className="relative">
          <button
            className="inline-flex mx-2 items-center py-1 px-2 border border-transparent rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => {
              voterReachOutStore.setAddCampaignStatus(true);
              voterReachOutStore.setEditCampaignStatus(false);
            }}
          >
            Create Campaign
          </button>
        </Popover>
      </div>
    );
  };

  return (
    <main>
      <div className="p-4">
        {header()}
        <SearchBar
          query={query}
          handleChange={handleSearchBarChange}
          handleKeyDown={handleKeyDown}
          getFilteredData={getReachOutCampaign}
          placeholder={"Search Campaign..."}
        />
        <SideOverlay
          children={
            <AddEditCampaign
              projectId={projectid}
              getReachOutCampaign={getReachOutCampaign}
            />
          }
          show={addCampaign}
          onClose={handleOverlayClose}
          title={editCampaign ? "Edit Campaign" : "Add Campaign"}
        />
        <SideOverlay
          children={
            <VoterReachOutCampaignOverview data={selectedOverViewItem} />
          }
          show={isMessageOverviewOpen}
          onClose={handleCampaignOverviewClose}
          title={sideLayName}
        />
        <VoterReachOutListCampaign
          setPopOver={handlePopOverState}
          deleteMessage={onDeleteHandler}
        />
        {isDeleteModalOpen && (
          <DeleteModal
            isOpen={isDeleteModalOpen}
            desc={`Are you sure you want to delete ?`}
            onDeleteClick={deleteHandler}
            onCancelClick={() => setIsDeleteModalOpen(false)}
          />
        )}
      </div>
    </main>
  );
});
