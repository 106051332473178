import {observer} from "mobx-react-lite";
import React, {useEffect} from "react"
import SearchBar from "../../components/SearchBar";
import {useStore} from "../../helpers/helpers";
import {C3PartyWorkersAddEdit, defaultC3ActivePersonObj} from "./c3-party-workers-add-edit";
import {C3StarRating} from "./c3StarRating";
import {useTranslation} from "react-i18next";
import {SideOverlay} from "../../components";

export const C3PartyWorkers = observer((props: any) => {
    const {c3Store} = useStore()
    const [t]=useTranslation(["constituency_command_center","beneficiary"]);
    const {party_workers_master, party_workers_filtered_by_search} = c3Store

    useEffect(() => {
        //c3Store.set_party_workers_master(data)
        c3Store.get_party_workers()
    }, [])

    useEffect(() => {
        if (c3Store.party_workers_master.length > 0) {
            c3Store.party_workers_refresh()
        }
    }, [c3Store.selected_booths, c3Store.party_workers_master])


    const editHandler = (id) => {
        c3Store.toggle_party_worker_edit_overlay()
        c3Store.update_party_worker_overlay_mode('edit')
        party_workers_master.map((obj) => {
            if (obj['id'] === id) {
                c3Store.set_party_worker_active({...obj})
            }
        })
    }

    const addHandler = () => {
        c3Store.toggle_party_worker_edit_overlay()
        c3Store.update_party_worker_overlay_mode('add')
    }

    const sideOverlayCloseHandler = () => {
        c3Store.toggle_party_worker_edit_overlay()
        c3Store.update_party_worker_overlay_mode('')
        c3Store.set_party_worker_active(defaultC3ActivePersonObj)
    }

    return <>
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-xl font-semibold text-gray-900">{t("party_workers")}</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        {t("list")}
                    </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    <div
                        className="inline-flex items-center justify-center px-4 py-2 mx-2 sm:w-auto">
                        <SearchBar
                            handleChange={(value) => c3Store.setQuery(value)}
                            query={c3Store.query}
                            placeholder={t("search")}
                            getFilteredData={() => c3Store.party_workers_search_update()}
                            /*handleKeyDown={(value)=>c3Store.setQuery(value)}*/
                        />
                    </div>
                    <button
                        type="button"
                        onClick={addHandler}
                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                    >
                        {t("add_party_worker")}
                    </button>
                </div>
            </div>
            <div className="mt-8 flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                <tr>
                                    <th
                                        scope="col"
                                        className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-6"
                                    >
                                        {t("name")}
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                                    >
                                        {t("phone")}
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                                    >
                                        {t("comments")}
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                                    >
                                        {t("profession")}
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                                    >
                                        {t("booth")}
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                                    >
                                        {t("rating")}
                                    </th>
                                    <th scope="col" className="relative py-3 pl-3 pr-4 sm:pr-6">
                                        <span className="sr-only">{t("edit", {ns: "beneficiary"})}</span>
                                    </th>
                                </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                {party_workers_filtered_by_search.map((obj) => (
                                    <tr key={obj['id']}>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{obj['name']}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-blue-500 cursor-pointer">
                                            <a href={'tel:' + obj['phone']}/>{obj['phone']}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{obj['comments']}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{obj['profession']}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{obj['booth']}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            <C3StarRating value={parseFloat(obj['rating'])} isEditable={false}
                                                          onStarClickHandler={() => {
                                                          }}/>
                                        </td>
                                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                            <a href="#" className="text-indigo-600 hover:text-indigo-900"
                                               onClick={() => editHandler(obj['id'])}>
                                                {t("edit")}<span className="sr-only">, {obj['name']}</span>
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <SideOverlay
                children={<C3PartyWorkersAddEdit/>}
                onClose={sideOverlayCloseHandler}
                show={c3Store.party_worker_edit_overlay}
                title={t("party_workers")}
            />
        </div>
    </>
})