import React from 'react'
import {observer} from "mobx-react-lite";
import {FormButtons} from "../../components/cm-components";
import {LoadingState, TailwindModal} from "../../components";
import {toast} from "react-toastify";
import {useStore} from "../../helpers";
import {useParams} from "react-router";
import {FormProvider, useForm} from "react-hook-form";
import {RNInput} from "../../components/form-components";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {Typography} from "@mui/material";
import {useMutation, useQueryClient} from "@tanstack/react-query";


export const AddUsersToFSD = observer((props) => {

    const {flexiStore} = useStore();
    const {fstid, id} = useParams();
    const queryClient = useQueryClient();
    const methods = useForm({
        defaultValues: {
            phoneNumbers: ""
        },
        resolver: yupResolver( Yup.object().shape({
            phoneNumbers:Yup.string().required('This field is required').test('valid-phone-numbers',
                'Please enter valid phone numbers, one per line',(val) => {
                    const phoneNumbers = val.split('\n');
                    return phoneNumbers.every((number) => /^[6-9]\d{9}$/.test(number));
            })
        })),
        mode: 'onSubmit'
    });

    const {mutate, isLoading} = useMutation({
        mutationFn: async(data) => {
            return await flexiStore.add_fsd_permissions(data);
        },
        onSuccess: (data) => {
            setTimeout(async () => {
                await queryClient.invalidateQueries([
                    "fsd-permissions", id
                ]);
            }, 1000);
            toast.success("Users added successfully...");
        },
        onError: ({message}) => {
            toast.error(message);
        },
    })

    const onSubmit = async (data) => {
        const add_permissions = {
            phonenumlist: data.phoneNumbers.split("\n"),
            fstid: parseInt(fstid),
            fsdid: parseInt(id),
        };
        // @ts-ignore
        mutate(add_permissions)
        flexiStore.update_flexistore_property("add_users_to_fsd_overlay", false)
    };

    if(isLoading) <LoadingState />
    return (
        <>
            <TailwindModal
                isOpen={flexiStore.add_users_to_fsd_overlay}
            >
                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-lg w-full">
                    <FormProvider {...methods} >
                        <form onSubmit={methods.handleSubmit(onSubmit)}
                              className="bg-white px-4 py-2 rounded-sm flex flex-col gap-y-4">
                            <Typography variant={"h5"} color={"primary"}>Add Users</Typography>
                            <div>
                               <RNInput
                                   label={"Phone Numbers"}
                                   name={"phoneNumbers"}
                                   multiline
                                   rows={8}
                                   fullWidth
                               />
                            </div>
                            <Typography variant={"caption"} >** Add Mulitple users by separating the phone numebrs by pressing enter button.</Typography>
                            <FormButtons
                                onSave={() =>{}}
                                onCancel={() => {
                                    flexiStore.update_flexistore_property("add_users_to_fsd_overlay", false)
                                }}
                                type={"submit"}
                            />
                        </form>
                    </FormProvider>
                </div>
            </TailwindModal>
        </>
    )
})