import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton } from "@mui/material";
import _ from "lodash";
import { FormButtons, TailwindModal, Toggle } from "../../components";
import { refineText } from "../../helpers/utils";

const CreateANDEditOptionsDialog = ({
  type,
  show,
  setShow,
  title,
  currentValue,
  onSubmit,
  id,
}) => {
  const updated_value_ref = React.useRef(null);
  const add_value_ref = React.useRef(null);

  const handleSubmit = () => {
    if (type === "Update") {
      let updated_value = updated_value_ref.current.value;
      if (updated_value !== "") {
        try {
          onSubmit(updated_value, id);
        } catch (error) {
          console.log(error);
        }

        // handleClose();
      } else {
        alert("Changes cannot be empty !!!");
      }
    }
    if (type === "Add") {
      let newly_added_value = add_value_ref.current.value;
      let converted_to_options = [];
      if (newly_added_value !== "") {
        try {
          let refined_text = refineText(newly_added_value);
          refined_text.map((curr_text) => {
            converted_to_options.push({ label: curr_text, value: curr_text });
          });
          onSubmit(converted_to_options);
        } catch (error) {
          console.log(error);
        }
      } else {
        alert("Changes cannot be empty !!!");
      }
    }
  };
  return (
    <TailwindModal isOpen={show}>
      <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full p-2">
        <div className="m-2">
          <h1 className="align-middle">
            {type} option to {title}
          </h1>
          <div className="mt-5 flex flex-wrap justify-between items-center">
            {type === "Add" ? (
              <TextField
                id="outlined-multiline-static"
                label="Add Options"
                multiline
                inputRef={add_value_ref}
                rows={5}
                fullWidth
              />
            ) : (
              <TextField
                autoFocus
                required
                margin="dense"
                id="name"
                name="new_option"
                label={type === "Add" ? "Add option" : "Update Current option"}
                type="text"
                inputRef={updated_value_ref}
                fullWidth
                variant="standard"
                defaultValue={currentValue}
              />
            )}
          </div>
        </div>
        <FormButtons
          onSave={() => {
            handleSubmit();
            setShow(false);
          }}
          onCancel={() => {
            setShow(false);
          }}
        />
      </div>
    </TailwindModal>
  );
};

export default CreateANDEditOptionsDialog;

// old modal
{
  /* <React.Fragment>
      {type === "Add" && (
        <Button
          variant="contained"
          startIcon={<i className="fa-regular fa-plus-circle" />}
          sx={{ color: "white" }}
          color="warning"
          onClick={handleClickOpen}
        >
          {title}
        </Button>
      )}
      {type === "Update" && (
        <IconButton size="small" aria-label="edit" onClick={handleClickOpen}>
          <i className="text-yellow-500 fa-solid fa-pen-to-square"></i>
        </IconButton>
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {type} option to {title}
        </DialogTitle>
        <DialogContent>
          {type === "Add" ? (
            <TextField
              id="outlined-multiline-static"
              label="Add Options"
              multiline
              inputRef={add_value_ref}
              rows={5}
              fullWidth
            />
          ) : (
            <TextField
              autoFocus
              required
              margin="dense"
              id="name"
              name="new_option"
              label={type === "Add" ? "Add option" : "Update Current option"}
              type="text"
              inputRef={updated_value_ref}
              fullWidth
              variant="standard"
              defaultValue={currentValue}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>{type}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment> */
}
