import React, {useEffect, useState} from "react";
import {useStore} from "../helpers";

export const RenderImageFromS3 = (props) => {

    const rootStore = useStore();

    const {data} = props;
    const [fetched, set_fetched] = useState(false)
    const [resuri, set_resuri] = useState('');

    const resolve_uri = async () => {
        const uri = await fetch_image_from_S3(data.key)
        set_resuri(uri)
        set_fetched(true)
    };

    const fetch_image_from_S3 = async (key) => {
        if (!key) return false;
        const url = await rootStore.get_s3_url("urn-assets", key);
        return url
    }

    useEffect(() => {
        resolve_uri();

        return(() => {
            set_resuri("");
            set_fetched(false)
        });
    }, []);

    return (
        <>
            {fetched && <a href={resuri} className="px-2" target="_blank"><img src={resuri} /></a>}
        </>
    )
}