import React from "react"

const SearchBar = (props) => {
    return (
        <div className='flex flex-row flex-wrap space-y-2 lg:space-y-0 justify-between items-center'>
            <div className="flex flex-row w-full">
                <input
                    type="search"
                    value={props.query}
                    onChange={(evt) => {
                        props.handleChange(evt.target.value);
                    }}
                    className="w-full h-10 pr-10 pl-4 rounded z-0 focus:shadow focus:outline-none"
                    placeholder={props.placeholder}
                    onKeyDown={props.handleKeyDown}
                />
                <div
                    className="relative cursor-pointer"
                    style={{top: 10, right: 30}}
                    onClick={props.getFilteredData}
                >
                    <i className="fa fa-search text-gray-400 z-20 hover:text-purple-500"/>
                </div>
            </div>
        </div>
    )
}

export default SearchBar
