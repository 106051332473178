import React, {useEffect, useState} from "react";

export const profileQuestionOptions = {
    name: {
        label: "Name",
        enabled: true,
        required: true
    },
    phone: {
        label: "Phone",
        enabled: true,
        required: true
    },
    gender: {
        label: "Gender",
        enabled: true,
        required: true
    },
    age: {
        label: "Age",
        enabled: true,
        required: true
    },
    caste: {
        label: "Caste",
        enabled: true,
        required: true
    },
    education: {
        label: "Education",
        enabled: true,
        required: true
    },
    occupation: {
        label: "Occupation",
        enabled: true,
        required: true
    },
    economic_status: {
        label: "Economic Status",
        enabled: true,
        required: true
    },
    ward_boothno: {
        label: "Ward & Booth No",
        enabled: false,
        required: false
    },
    sur_name: {
        label: "SurName",
        enabled: false,
        required: false
    },
    house_no: {
        label: "House Number",
        enabled: false,
        required: false
    },
    fhrelation: {
        label: "Father's / Husband's Name",
        enabled: false,
        required: false
    },
    fhead: {
        label: "Head of the Family",
        enabled: false,
        required: false
    },
    epicid: {
        label: "Voter ID",
        enabled: false,
        required: false
    },
};

export const ProfileQuestion = (props:any) => {

    const [optionsState, setOptionsState] = useState(props.options);

    useEffect(() => {
       props.optionChange(optionsState)
    }, [optionsState])

    const handleCheckboxChange = (option, type) => {
        setOptionsState((prevState) => ({
            ...prevState,
            [option]: {
                ...prevState[option],
                [type]: !prevState[option][type],
            },
        }));
    };


    return(
        <div className="w-96">
            <div className="grid grid-cols-3 gap-2 mb-2 font-bold">
                <div className="">Options</div>
                <div className="text-center">Enabled</div>
                <div className="text-center">Required</div>
            </div>

            {Object.entries(optionsState).map(
                // @ts-ignore
                ([option, { label, enabled, required }]) => (
                    <div key={option} className="grid grid-cols-3 gap-2">
                        <div className="p-2">{label}</div>
                        <div className={"p-2 text-center"}>
                            <input
                                type="checkbox"
                                className="h-5 w-5 text-green-600"
                                checked={enabled}
                                onChange={() => handleCheckboxChange(option, 'enabled')}
                            />
                        </div>
                        <div className={"p-2 text-center"}>
                            <input
                                type="checkbox"
                                className="h-5 w-5 text-green-600"
                                checked={required}
                                onChange={() => handleCheckboxChange(option, 'required')}
                            />
                        </div>

                    </div>
                ))}
        </div>
    )
}