import React, {useState} from 'react'
import {observer} from "mobx-react-lite";
import {useStore} from "../../helpers";
import {safe_get} from "../../report-visuals/report-utils";
import { useTranslation } from 'react-i18next';
import {useParams} from "react-router";
import {LoadingState} from "../../components";

export const SurveyActionView = observer(props => {

    const {surveyResponseStore, userStore} = useStore()
    const [loading, setLoading] = useState(false);
    const role = safe_get(userStore, "user.role", "")
    const {t} = useTranslation("survey_management");
    const {projectid} = useParams();

    const Actions = () => {
        const survey_records_file_name = `survey_${surveyResponseStore.activeSurveyId}_records.json`;

        // @ts-ignore
        return (
            <div className='flex flex-col'>

                <a onClick={checkAudioHandler}
                   className="text-indigo-600 m-2 font-bold cursor-pointer">{t("check_audio")} 
                </a>

                <a onClick={rebuildSurveyIndexHandler}
                   className="text-indigo-600 m-2 font-bold cursor-pointer">{t("rebuild_survey_index")}
                </a>

                <a className="text-indigo-600 m-2 font-bold cursor-pointer"
                   href={`data:text/json;charset=utf-8,${encodeURIComponent(
                       JSON.stringify(surveyResponseStore.allSurveyResponses)
                   )}`}
                   download={survey_records_file_name}
                >
                    {t("export_records")}
                </a>
            </div>
        )
    }

    const rebuildSurveyIndexHandler = async () => {
        setLoading(true)

        const payload = {
            "projectid": parseInt(projectid),
            "surveyid": surveyResponseStore.activeSurveyId,
            "incremental": false
        }
        await surveyResponseStore.rebuild_survey_index(payload)
        setLoading(false)
    }

    const checkAudioHandler = async () => {
        setLoading(true)
        const payload = {
            "projectid": parseInt(projectid),
            "surveyid": surveyResponseStore.activeSurveyId
        }
        await surveyResponseStore.check_audio_availability(payload)
        setLoading(false)
    }

    return (
        <>
            {loading ? <LoadingState/> : null}
            <div className="py-2">
                {role === "root" &&
                    <>
                        <label className="block text-base font-medium text-indigo-500 font-bold uppercase">{t("actions")}</label>
                        <Actions/>
                    </>
                }
            </div>
        </>
    )
})