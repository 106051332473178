import React from "react";
import data from "./social_media_tracker.json";
import {faInstagram, faYoutube, faTwitter, faFacebook} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";


export const SocialMediaTracker = () => {
    return (
        <div className="text-center p-4">
            <h1 className="text-4xl font-bold mb-6">Social Media Tracker</h1>
            {data.map((candidate, index) => (
                <div key={index} className="mb-8">
                    <h4 className="text-2xl font-semibold text-start">Candidate Name - {candidate.candidate_name}</h4>
                    <div className="h-full w-full grid md:grid-cols-4 gap-20">
                        <div className="md:col-span-1">
                            <div className="bg-white p-6 rounded-md">
                                <h2 className="text-2xl font-semibold mb-2">
                                    <FontAwesomeIcon
                                        icon={faInstagram as IconProp}
                                        size="xl"
                                        style={{color: "#ee2a7b"}}/>
                                    <span className="icon-text p-2">Instagram</span>
                                </h2>
                                <ul>
                                    <li>Followers: {candidate.instagram_followers}</li>
                                </ul>
                            </div>
                        </div>
                        <div className="md:col-span-1">
                            <div className="bg-white p-6 rounded-lg">
                                <h2 className="text-2xl font-semibold mb-2">
                                    <FontAwesomeIcon icon={faYoutube as IconProp} size="xl" style={{color: "#ff0000"}}/>
                                    <span className="icon-text p-2">Youtube</span>
                                </h2>
                                <ul>
                                    <li>Subscribers: {candidate.youtube_followers}</li>
                                </ul>
                            </div>
                        </div>
                        <div className="md:col-span-1">
                            <div className="bg-white p-6 rounded-lg">
                                <h2 className="text-2xl font-semibold mb-2">
                                    <FontAwesomeIcon icon={faTwitter as IconProp} size="xl" style={{color: "#000000"}}/>
                                    <span className="icon-text p-2">Twitter</span>
                                </h2>
                                <ul>
                                    <li>Followers: {candidate.twitter_followers}</li>
                                </ul>
                            </div>
                        </div>
                        <div className="md:col-span-1">
                            <div className="bg-white p-6 rounded-lg">
                                <h2 className="text-2xl font-semibold mb-2 ">
                                    <FontAwesomeIcon icon={faFacebook as IconProp} size="xl"
                                                     style={{color: "#1d55c3"}}/>
                                    <span className="icon-text p-2">Facebook</span>
                                </h2>
                                <ul>
                                    <li>Followers: {candidate.facebook_followers}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

