import React from "react";
import {safe_get} from "../../../report-visuals/report-utils";

type PCTableProps = {
    data: {
        rows: any;
        headers: any;
        total: any;
    }
}


const colSpan = {
    border: "1px solid aqua !important",
    borderStyle: "dotted !important",
    textAlign: "center",
}

const PCHistoricalTable = (props: PCTableProps) => {

    const {data} = props;
    const title = safe_get(data, "title", "");
    const show_footer = safe_get(data, "total_row_display", false)

    return (
        <div className="pctable historical_table" >
            <p className="font-bold text-lg text-purple-600">{title}</p>
            <table >
                <thead className="bg-gray-300">
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colSpan={4}>2018 Assembly</td>
                    <td colSpan={4}>2019 Parliament</td>
                    <td colSpan={4}>2013 Assembly</td>
                    <td colSpan={4}>2014 Parliament</td>
                </tr>
                <tr>
                    {data?.headers.map((column, index) => (
                        <th key={index}>{column}</th>
                    ))}
                </tr>
                </thead>
                <tbody className="bg-white">
                {data?.rows.map((row, index) => (
                    <tr key={index}>
                        {row.map((cell, index) => (
                            <td key={index}>{cell}</td>
                        ))}
                    </tr>
                ))}
                </tbody>
                {show_footer && <tfoot>
                <tr>
                    {data?.total.map(x => <td key={x}>{x}</td>)}
                </tr>
                </tfoot>}
            </table>
        </div>
    );
};

export default PCHistoricalTable;
