import React from "react"
import { Field, ErrorMessage } from "formik"
import { TextError } from "./formik-controller"

function Input(props) {
    const { name, label, labelStyle, fieldStyle, ...rest } = props
    return (
        <>
            <div className="flex flex-row items-center justify-center mb-2">
                <label htmlFor={name} className={`${labelStyle} font-bold`}> {label}</label>
                <Field name={name} {...rest} className={fieldStyle} />
            </div>
            <div className="flex justify-self-end">
                <ErrorMessage name={name} component={TextError} />
            </div>
        </>
    )
}
export default Input