import React from "react";
import {Avatar} from "@mui/material";

export const UserTable = (props) => {

    return (
        <div className={'flex flex-row justify-between w-full'}>
            <div className="px-4 sm:px-6 lg:px-8 w-full sm:w-3/4">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-base font-semibold leading-6 text-gray-900">Users</h1>
                        <p className="mt-2 text-sm text-gray-700">
                            A list of all the users in your account including their name, title, email and role.
                        </p>
                    </div>
                    <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                        <button
                            type="button"
                            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Add user
                        </button>
                    </div>
                </div>
                <div className="-mx-4 mt-8 sm:-mx-0">
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                        <tr>
                            <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                            >
                                User ID
                            </th>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:px-3">
                                Photo
                            </th>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:px-3">
                                Name
                            </th>
                            <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                            >
                                Phone Number
                            </th>
                            <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Role
                            </th>
                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0 text-left text-sm font-semibold">
                                Actions<span className="sr-only">Edit</span>
                            </th>
                        </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                        {props.users.map((user) => (
                            <tr key={user.id}>
                                <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{user.id}</td>
                                <td className="max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:px-3">
                                    <Avatar
                                        src={user.profileimageuri}
                                        alt={user.name}
                                        sx={{width: 56, height: 56}}
                                    />
                                </td>
                                <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:px-3">
                                    {user.name}
                                    <dl className="font-normal lg:hidden">
                                        <dt className="sr-only">Role</dt>
                                        <dd className="mt-1 truncate text-gray-700">{user.role}</dd>
                                        <dt className="sr-only sm:hidden">Phone Number</dt>
                                        <dd className="mt-1 truncate text-gray-500 sm:hidden">{user.phonenum}</dd>
                                    </dl>
                                </td>
                                <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">{user.phonenum}</td>
                                <td className="hidden px-3 py-4 text-sm text-gray-500">{user.role}</td>
                                <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0 flex items-center justify-evenly flex-row">
                                    <a href="#" className="text-indigo-600 hover:text-indigo-900">
                                        Edit<span className="sr-only">, {user.name}</span>
                                    </a>
                                    <a href="#" className="text-indigo-600 hover:text-indigo-900">
                                        Delete<span className="sr-only">, {user.name}</span>
                                    </a>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="px-4 sm:px-6 lg:px-8 max-w-3xl ">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-base font-semibold leading-6 text-gray-900">Users</h1>
                        <p className="mt-2 text-sm text-gray-700">
                            A list of all the users in your account including their name, title, email and role.
                        </p>
                    </div>
                    <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                        <button
                            type="button"
                            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Add user
                        </button>
                    </div>
                </div>
                <div className="-mx-4 mt-8 sm:-mx-0">
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                        <tr>
                            <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                            >
                                User ID
                            </th>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:px-3">
                                Photo
                            </th>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:px-3">
                                Name
                            </th>
                            <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                            >
                                Phone Number
                            </th>
                            <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Role
                            </th>
                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0 text-left text-sm font-semibold">
                                Actions<span className="sr-only">Edit</span>
                            </th>
                        </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                        {props.users.map((user) => (
                            <tr key={user.id}>
                                <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{user.id}</td>
                                <td className="max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:px-3">
                                    <Avatar
                                        src={user.profileimageuri}
                                        alt={user.name}
                                        sx={{width: 56, height: 56}}
                                    />
                                </td>
                                <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:px-3">
                                    {user.name}
                                    <dl className="font-normal lg:hidden">
                                        <dt className="sr-only">Role</dt>
                                        <dd className="mt-1 truncate text-gray-700">{user.role}</dd>
                                        <dt className="sr-only sm:hidden">Phone Number</dt>
                                        <dd className="mt-1 truncate text-gray-500 sm:hidden">{user.phonenum}</dd>
                                    </dl>
                                </td>
                                <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">{user.phonenum}</td>
                                <td className="hidden px-3 py-4 text-sm text-gray-500">{user.role}</td>
                                <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0 flex items-center justify-evenly flex-row">
                                    <a href="#" className="text-indigo-600 hover:text-indigo-900">
                                        Edit<span className="sr-only">, {user.name}</span>
                                    </a>
                                    <a href="#" className="text-indigo-600 hover:text-indigo-900">
                                        Delete<span className="sr-only">, {user.name}</span>
                                    </a>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
            </div>
    )
}
