import React from "react"
import {ViewAttachments} from "../../../components";

const VoterReachOutMessageOverview = (props) => {

    const {channel, ds} = props.data
    
    return (
        <>
            <div className="flex flex-col items-center cursor-pointer rounded-lg">
                <div className="flex flex-row w-full justify-between">
                    <div className="w-1/2 ">
                        <p className="text-md font-medium text-indigo-600  font-bold pb-3 ">Name</p>
                    </div>
                    <div className="w-1/2   ">
                        <p className="text-md font-medium text-black-600  font-normal pb-3">{ds.name}</p>
                    </div>
                </div>
                {channel !== "voicecall" && <div className="flex flex-row w-full justify-between">
                    <div className="w-1/2  ">
                        <p className="text-md font-medium text-indigo-600  font-bold pb-3">Message</p>
                    </div>
                    <div className="w-1/2">
                        <p className="text-md font-medium text-black-600  font-normal pb-3 break-words">{ds.text}</p>
                    </div>
                </div>}
            </div>
            {
                channel === "voicecall" && <div className="flex flex-col items-center cursor-pointer rounded-lg ">
                    <div className="flex flex-row text-left w-full">
                        <p className="text-md font-medium text-indigo-600  font-bold pb-3">Recorded Audio</p>
                    </div>
                    <div className="flex flex-col">
                        {
                            ds.text !== "" ? <ViewAttachments data={[{path: ds.text, type: "audio"}]}/> : <p>No Recording is available</p>
                        }
                    </div>
                </div>
            }
            {
                ds.attachements && ds.attachements.length > 0 && <div className="flex flex-col items-cente cursor-pointer rounded-lg ">
                    <div className="flex flex-row text-left w-full">
                        <p className="text-md font-medium text-indigo-600  font-bold pb-3">Uploaded Attachements</p>
                    </div>
                    <div className="flex flex-col">
                        <ViewAttachments data={ds.attachements}/>
                    </div>
                </div>
            }
        </>
    )
}

export default VoterReachOutMessageOverview;