import {cooked_grader, grader, SectionTitle} from "../booth-reports-utils";
import {safe_get} from "../../../../report-visuals/report-utils";
import React from "react";
import {observer} from "mobx-react-lite";
import {useStore} from "../../../../helpers/helpers";
import {useCurrentFSD} from "../../../../custom-hooks/useCurrentFSD";
import {grading} from "./render-analytical-dashboard";

export const BoothGradingSummary = observer((props:any) => {

    const {flexiStore} = useStore();
    const {current_fsd, group_by_booth} = flexiStore;
    const {r1, r3, r5, r6} = current_fsd;
    const config = useCurrentFSD();

    const {
        booth_details,
        booth_key_prefix ,
        skip_transform ,
        filter_booths ,
        filter_booths_whitelist ,
        f20_enabled,
        analytical_dashboard
    } = config
    return (
        <>
            <SectionTitle text={'Boothwise Grading Summary'}/>
            <div
                className='grid grid-cols-2'>
                {
                    Object.keys(booth_details).sort().map(booth_key => {
                        let display = {};
                        if(!analytical_dashboard){
                            display = skip_transform ? cooked_grader(booth_key,r3,config) : grader(booth_key,config,group_by_booth);
                        } else {
                            display = grading(flexiStore.current_fsd.r6[`${booth_key}`])
                        }

                        if (!display) return null;
                        if (display['display'] === 'IN PROGRESS') return null;

                        let f20_category = ''

                        if (f20_enabled) {
                            const f20_ds = safe_get(r5, `f20.${booth_key}`, null)
                            const f20_party_key = safe_get(r1, "config.f20.our_party_key", "")
                            const facet = safe_get(r1, "config.f20.ref_facet", "")
                            f20_category = safe_get(f20_ds, `${facet}.SWOT.${f20_party_key}`, '')
                        }

                        if (filter_booths) {
                            const check_filter_key = booth_key.replace(booth_key_prefix, '');
                            if (!filter_booths_whitelist.includes(check_filter_key)) return null;
                        }

                        return (
                            <div className='flex flex-row bg-gray-50 rounded-md mt-1 mx-1 px-1' style={{}}>
                                <div className="flex-1 flex flex-row justify-between items-center">
                                    <p>{`${booth_key_prefix}${booth_key}`}</p>
                                    <p>{display["display"]}</p>
                                    {
                                        (f20_enabled && (f20_category !== display["display"])) ? (
                                            <i className="no-print fa-solid fa-circle-exclamation text-red-500 text-bold" />
                                        ) : null
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
})