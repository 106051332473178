import {action, makeAutoObservable, toJS} from "mobx";

export class AiGentoolsStore {
    id: number;
    tags: any = [];
    title: string;
    tool_search_query: string = "";
    tool_search_results: any = "";
    rootStore:any;

    constructor(rootStore: any) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }


    @action
    async get_tool_search(query: string) {
        const payload = {
            "keyword": query,
            "tags": [],
            "published_only": false,
            "offset": 0,
            "count": 100
        }
        return await this.rootStore.api.post('ai/gentool/search', payload);
    }

    async generate(instruction, text, options = {}){
        const payload = {
            'text': text,
            'display': instruction,
            'options': options
        };
        const response = await this.rootStore.api.post('gen/ask-ai', payload);
        return response.data;
    }

}