import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";

type SideOverlayProps = {
  children: React.ReactNode;
  onClose: Function;
  show: boolean;
  title?: string;
};

export const SideOverlay = (props: SideOverlayProps) => {
  const { children, onClose, show, title = "" } = props;
  const { t } = useTranslation("components");

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden z-[999]"
        onClose={() => {}}
      >
        <div className="absolute inset-0 overflow-hidden font-medium">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-lg sm:max-w-full pl-10">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="pointer-events-auto w-screen max-w-xl">
                <div className="flex h-full flex-col overflow-y-scroll py-4 shadow-xl bg-gray-100 rounded-l-lg">
                  <div className="px-2 pt-12 sm:px-4 sm:pt-0">
                    <div className="flex items-center justify-between">
                      <Dialog.Title className="text-2xl py-4 font-bold text-purple-600 uppercase">
                        {title}
                      </Dialog.Title>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          className="rounded-xl text-xl text-gray-400 hover:text-gray-500 focus:outline-none hover:bg-white"
                          onClick={() => onClose()}
                        >
                          <span className="sr-only">{t("close_panel")}</span>
                          <XCircleIcon className="h-8 w-8" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="relative flex-1 pl-4 sm:pl-6">
                    {/*<div className="text-lg font-bold text-purple-600 uppercase">{title}</div>*/}
                    <div className="flex flex-col flex-1 h-full align-bottom text-left overflow-auto transform transition-all sm:my-2 sm:align-middle max-w-lg w-full">
                      {children}
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
