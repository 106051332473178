import React from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import styled from "styled-components";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";

export const Styles = styled.div`
  background-color: white;
  border-radius: 8px;
  
  tfoot tr td:last-child {
       padding: 2px
  }
  
  table {
        border-collapse: collapse;
        width: 100%;       
        background-color: white;
    }

    table td, table th {
      /*border: 1px solid #ddd;*/
        padding: 4px;
    }
    table th{
        padding-top: 3px;
        padding-bottom: 3px;
    }

    table tr:nth-child(even){background-color: #F4F6F7;}
    
    table tr:hover {background-color: #ddd;}
    
    table th {
      background-color: #D5DBDB;
      color: black;
      font-weight: bold;      
    }
    
    table th,table td {text-align: right;}
    table th:first-child,table td:first-child {text-align: left;}
    table th:last-child, table td:last-child {padding-right: 20px !important;}
    
    
    tfoot {
        font-weight: bold;
        border-top: 1.2px solid black;
        border-bottom: 1.5px solid black;
    }
`

export const Table = ({ data, columns, pagesize = 10, onRowClick, hideFooter = false, highlightRow = false, activeSurveyResponseId = '' }) => {
    // const [initialPageIndex, setInitialPageIndex] = useState(getInitialPageIndex());
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        getTrProps,
        page,
        rows,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        state: { pageIndex, pageSize },
    } = useTable({
        columns,
        data,
            initialState: { pageSize: pagesize },
        autoResetPage: false,

    },
        useSortBy,
        usePagination
    );

    const { t } = useTranslation("survey_management");

    // useEffect(() => {
    //     localStorage.setItem("pageIndex", pageIndex.toString());
    // }, [pageIndex]);
    //
    // function getInitialPageIndex() {
    //     const storedPageIndex = localStorage.getItem("pageIndex");
    //     return storedPageIndex ? parseInt(storedPageIndex, 10) : 0;
    // }

    return (
        <Styles>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render('Header')}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? ' 🔽'
                                                : ' 🔼'
                                            : ""}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        let classNameHighlight = ''
                        if (highlightRow) {
                            if (row['original']['id'] == activeSurveyResponseId)
                                classNameHighlight += 'active';
                        }

                        return (
                            <tr className={classNameHighlight} {...row.getRowProps()} onClick={() => onRowClick(row.original)}>
                                {row.cells.map(cell => {
                                    // console.log(cell)                                  
                                    // let style = {};
                                    // if(cell.value === activeSurveyResponseId){
                                    //     style={background: "red"}
                                    // }
                                    return <td {...cell.getCellProps({})}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>

                {hideFooter ? null : (
                    <tfoot>
                        {footerGroups.map(group => (
                            <tr {...group.getFooterGroupProps()}>
                                {group.headers.map(column => (
                                    <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                                ))}
                            </tr>
                        ))}
                    </tfoot>
                )}

            </table>
            <div className="pagination my-1 flex ">
                <span className='pl-4'>{t("page")}{' '}<strong>{pageIndex + 1} of {pageOptions.length}</strong>{' '}</span>
                <span className='ml-auto pr-4'>
                    <button
                        type="button"
                        className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2"
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                    >
                        <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                    {' '}
                    <button
                        type="button"
                        className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2"
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                    >
                        <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                </span>
            </div>
        </Styles>
    )
}