import React from "react";
import {DashBoard} from "./dashboard-template";

export const HCPDashBoard = (props) => {

    const frame = `<iframe title="Hyperlocal_Communication_Platform" width="100%" height="100%" src="https://app.powerbi.com/view?r=eyJrIjoiZWIwMzgyNTQtOGE0ZS00NzM1LWI0YzItYjNjOTBjYTk1YzcyIiwidCI6IjAzZjhjZWI2LTdhMGUtNDcwMy05ZGE0LWUyYTBkYmE1NmM4YyJ9" frameborder="0" allowFullScreen="true"></iframe>`

    return (
        <>
            <DashBoard passCode={"HyperLocal"} iframe={frame} />
        </>
    )
}