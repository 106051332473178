import {action, makeAutoObservable, toJS} from "mobx";
import _ from "lodash";
import {ReportTransformers} from "../report-visuals/report-transformers";
import {safe_get} from "../report-visuals/report-utils";


export class SurveyResponseStore {
   hasCheckedAuth: boolean = false;
   user: any = null;
   isAuthorized: boolean = false;

   regions: any = [];
   activeSurveyId: any = null;
   activeSurveyResponseId: any = null;

   allSurveyResponses: any;
   allSurveyResponsesSummary: any;
   analytical_meta: any;
   analytical_data: any;
   surveyResponseFilters: any;
   tags: any;
   activeSurveyResponse: any;
   colors: any;

   currentPageTracker: any;
   survey_list_api: any;
   loading: boolean;
   query: any;
   searchHighlights: boolean;
   allTags: any;

   activeQuestion: any = {};

   new_filters: any;

   rootStore: any;

    constructor(rootStore: any) {
        makeAutoObservable(this)
        this.rootStore = rootStore;
        this.isAuthorized = false;
        this.hasCheckedAuth = false;
        this.currentPageTracker = 0;
        this.regions = [];
        this.activeSurveyId = -1;
        this.activeSurveyResponseId = null;
        this.allSurveyResponses = [];
        this.allSurveyResponsesSummary = {};
        this.analytical_meta = [];
        this.analytical_data = {};
        this.surveyResponseFilters = {};
        this.tags = []
        this.activeSurveyResponse = {};
        this.colors = [];
        this.query = '';
        this.searchHighlights = false;
        this.new_filters = [];
    }

    @action
    getActiveSurveyResponse = async () => {
        try {
            //const response = await this.rootStore.api.post('survey/getbyidwithaudio', {"clientid": this.activeSurveyResponseId})
            const response = await this.rootStore.api.post('survey/getbyidwithoutaudio', {"clientid": this.activeSurveyResponseId})
            this.activeSurveyResponse = response.data
            this.tags = response.data["alltags"]
            this.regions = response.data["sr"]["regions"]
            return response
        } catch (e) {
            // console.log("response", e)
        }
    }

    @action
    update_new_filters(filterkey, filterval, display = '') {
        const filters = _.clone(this.new_filters)
        const temp = _.remove(filters, {'key': filterkey})
        const tuple = {
            'key': filterkey,
            'val': filterval,
            'display': display,
        }
        filters.push(tuple)
        this.new_filters = filters
        console.log("filters", toJS(filters))
        return this.new_filters
    }

    @action
    remove_new_filters(tuple) {
        const {key, val, display} = tuple
        const filters = _.clone(this.new_filters)
        const temp = _.remove(filters, {'key': key})
        this.new_filters = filters
        return this.new_filters
    }

    @action
    reset_new_filters(){
        this.new_filters = [];
    }

    @action
    setRegions = async (regions) => {
        this.regions = regions
    }

    @action
    updateRegion = (region) => {
        const tmp = _.clone(this.regions)
        const index = _.findIndex(tmp, {id: region.id})
        tmp[index] = region
        this.regions = tmp
        return this.regions
    }

    @action
    deleteRegion = (region) => {
        const tmp = _.clone(this.regions)
        const index = _.findIndex(tmp, {id: region.id})
        tmp.splice(index, 1)
        this.regions = tmp
        return this.regions
    }

    @action
    setActiveSurveyId = id => {
        this.activeSurveyId = id
    }

    @action
    setSurveyListAPI = api => {
        this.survey_list_api = api
    }

    @action
    setCurrentPage = (pagenum) => {
        this.currentPageTracker = pagenum
    }

    @action
    getFieldReport = async (payload) => {
        this.loading = true;
        if (payload['surveyid'] == -1) {
            this.loading = false;
            return
        }

        payload['filters'] = {}
        this.new_filters.map(x => {
            const {key, val} = x
            payload['filters'][key] = val
        })

        const response = await this.rootStore.api.post("/project/esfieldteamreport3", payload)
        this.allSurveyResponses = response.data["srs"];
        this.allSurveyResponsesSummary = response.data["summary"];
        try {
            this.analytical_meta = response.data["analytical_meta"];
            this.analytical_data = response.data["analytical"];
            this.allTags = safe_get(response.data, "alltags",[])
        } catch (e) {
            console.log(e)
        }
        console.log("Sample Survey", response.data[0])
        this.loading = false;
        return response
    }

    @action
    get_esdata = async (payload) => {
        if (payload === "") {
            return
        }
        const response = await this.rootStore.api.post("/fs/esdata", payload)
        this.allSurveyResponses = response.data
        return response.data
    }

    @action
    setActiveSurveyResponseId = id => {
        this.activeSurveyResponseId = id
        // localStorage.setItem("activeSurveyResponseId", id);
    }

    @action
    updateFilter = (filterKey, filterValue) => {
        const newFilters = _.clone(this.surveyResponseFilters);
        newFilters[filterKey] = filterValue;
        this.surveyResponseFilters = newFilters;
        return this.surveyResponseFilters;
    }

    @action
    clearAllFilters = () => {
        this.new_filters = [];
        return [];
    }

    hasKey = (keyname) => {
        if (this.allSurveyResponses.length < 1) {
            return false
        }
        const sampleRow = toJS(this.allSurveyResponses)[0];
        const keyList = Object.keys(sampleRow);
        return keyList.includes(keyname);
    }

    hasFilters = () => {
        return Object.keys(this.surveyResponseFilters).length > 0;
    }

    @action
    updateRegionsToServer = async () => {
        const _payload = {
            "clientid": this.activeSurveyResponseId,
            "regions": this.regions
        }
        const templist = _.clone(this.allSurveyResponses)
        const current = _.filter(templist, {"id": this.activeSurveyResponseId})[0]
        current['hashighlights'] = (this.regions.length > 0)
        current['highlight_regions'] = this.regions
        this.allSurveyResponses = templist
        await this.rootStore.api.post("survey/syncregions", _payload)
        if (this.survey_list_api) {
            this.survey_list_api.setPage(this.currentPageTracker)
        }
        return
    }

    @action
    survey_qa_report = async (clientid, status, user, ts) => {
        const response = await this.rootStore.api.post(`survey/qareport`, {
            clientid, status, user, ts
        })

        const templist = _.clone(this.allSurveyResponses)
        const current = _.filter(templist, {"id": this.activeSurveyResponseId})[0]
        current['qa_status'] = response.data['qa_status']
        current['qalog'] = response.data['qalog']
        this.allSurveyResponses = templist

        return response
    }

    @action
    rebuild_survey_index = async (payload) => {
        return await this.rootStore.api.post("survey/rebuildindex", payload)
    }

    @action
    check_audio_availability = async (payload) => {
        return await this.rootStore.api.post("survey/recheckaudio", payload)
    }

    @action
    profile_question_options = async (payload) => {
        return await this.rootStore.api.post("profile/options", payload)
    }

    @action
    set_colors = (color) => {
        const clone = _.clone(this.colors)
        clone.push(color)
        this.colors = clone
    }

    @action
    set_loading = (loading) => {
        this.loading = loading
    }

    @action
    set_query = (query) => {
        this.query = query
    }

    @action
    set_search_highlights = (status) => {
        this.searchHighlights = status
    }

    @action
    reset_active_survey = () => {
        this.activeSurveyResponseId = null;
        localStorage.setItem("activeSurveyResponseId", '');
    }

    @action
    set_active_question = (question) => {
        this.activeQuestion = question;
    }

    @action
    async update_voter_reg_status(payload) {
        return await this.rootStore.api.post("survey/updatesr", payload)
    }

    @action
    rebuild_summary = () => {
        //WORK IN PROGRESS
        const summary = {
            by_user: {},
            by_date: {},
            by_hour: [],
            by_lev1: [],
            by_lev2: [],
            by_qa: [],
        }
        const transformer = new ReportTransformers();
        const filtered = transformer['filter'](toJS(this.allSurveyResponses), toJS(this.surveyResponseFilters));
        filtered.map(x => {

        })
    }

    get_total_surveys(data, key) {
        try {
            let totalSurveys = 0
            if (data.length > 0) {
                for (let i = 0; i < data.length; i++) {
                    totalSurveys += data[i][key];
                }
            }
            return totalSurveys
        } catch (e) {
            return 0
        }
    }

}

