import {observer} from 'mobx-react-lite'
import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {safe_get} from "../report-visuals/report-utils";


export const Carousel = observer((props: any) => {


    const settings = {
        // customPaging: function (i) {
        //     return (
        //         <a>
        //             <img src={`/images/default_image.jpeg`}/>
        //         </a>
        //     );
        // },
        autoplay: false,
        cssEase: "linear",
        className: "center",
        centerMode: true,
        dots: true,
        dotsClass: "slick-dots slick-thumb",
        infinite: safe_get(props, "infinite", false),
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: safe_get(props, "infinite", false),
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <div className="mx-6 my-2 sm:my-4">
                <Slider {...settings}>
                    {props.items}
                </Slider>
            </div>
        </>)
})