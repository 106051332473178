import React, {useRef, useState} from "react";
import {htmlValidation} from "../helpers/utils";
import {FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField} from "@mui/material";
import {Visibility, VisibilityOff, Search} from "@material-ui/icons";

export const FsFilterPane = (props) => {
    const {onSearch} = props
    const queryRef = useRef(null);
    const [query, setQuery] = useState("")

    return (
        <div className='py-4 flex flex-row flex-wrap space-y-2 lg:space-y-0 flex-1'>
            <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-adornment-search">Search</InputLabel>
                <OutlinedInput
                    label="Search"
                    sx={{backgroundColor: "white"}}
                    id="outlined-size-small"
                    size="small"
                    type={'text'}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setQuery(event.target.value);
                    }}
                    onKeyUp={event => {
                        if (event.key === "Enter" && event.keyCode === 13) {
                            htmlValidation(event)
                            onSearch(query)
                            console.log(query)
                        }
                    }
                    }
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => onSearch(query)}
                                onMouseDown={() => onSearch(query)}
                                edge="end"
                            >
                                <Search/>
                            </IconButton>
                        </InputAdornment>
                    }

                />
            </FormControl>
            {/*<div className="flex flex-row w-full">*/}
            {/*    <input*/}
            {/*        ref={queryRef}*/}
            {/*        type="search"*/}
            {/*        onKeyUp={event => {*/}
            {/*                if (event.key === "Enter" && event.keyCode === 13) {*/}
            {/*                    htmlValidation(event)*/}
            {/*                    onSearch(queryRef.current.value)*/}
            {/*                }*/}
            {/*            }*/}
            {/*        }*/}
            {/*        className="w-full h-9 pr-8 pl-5 rounded z-0 focus:shadow focus:outline-none"*/}
            {/*        placeholder="Search"*/}
            {/*    />*/}
            {/*    <div className="relative" style={{top: 4, right: 30}}>*/}
            {/*        <i className="fa fa-search text-gray-400 z-20 hover:text-gray-500 text-2xl cursor-pointer"  onClick={e => {*/}
            {/*            onSearch(queryRef.current.value)*/}
            {/*        }}/>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>

    )
};