import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {RootStore} from "../../../../stores/root";
import {useStore} from "../../../../helpers";
import {ACBankDAO} from "../acbank_dao";
import {LoadingState} from "../../../../components";
import Select from 'react-select';
import _ from "lodash";
import {CasteMatrix} from "./caste-matrix";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import {CasteTable} from "./caste-table";

export const CasteSummary = observer(props => {

    const rootStore: RootStore = useStore()
    const {flexiStore} = rootStore;
    const [active_section, set_active_section] = useState('')
    const [region_wise_active_section, setRegion_wise_active_section] = useState({label: "", value: ""});
    const dao = new ACBankDAO(rootStore)
    const [t] = useTranslation(["caste_summary", "beneficiary"]);

    useEffect(() => {
        dao.load_caste_details();
    }, [])

    useEffect(() => {
        if (flexiStore.external_resource && Object.keys(flexiStore.external_resource).length > 0) {
            const elections = Object.keys(flexiStore.external_resource)
            set_active_section(elections[0])
        }
    }, [flexiStore.external_resource_key])

    if (flexiStore.loading_external_resource) return (<LoadingState/>);
    if (flexiStore.external_resource_key !== 'caste') return (<NoDataState/>);

    const caste_details_keys = Object.keys(flexiStore.external_resource);

    let region_wise_dropdown = [];

    if (active_section === 'region_wise') {
        region_wise_dropdown = Object.keys(flexiStore.external_resource[active_section]).map(key => {
            return {
                "value": key,
                "label": key
            }
        })
    }

    const old_render = (
        <div>
            <div className='pl-4'>
                <div className='font-bold text-2xl pb-2 uppercase'>{t("caste_summary")}</div>
                <div className='flex flex-row flex-wrap items-center'>
                    {caste_details_keys.map(x => {
                        const bg = (active_section === x) ? 'bg-indigo-500 text-white font-bold' : 'bg-gray-200 border border-black'
                        const name = x.split('_').join(" ");
                        return (
                            <>
                                <div className={`py-2 px-4 text-sm ${bg} cursor-pointer mr-4 rounded-2xl capitalize`}
                                     onClick={() => {
                                         set_active_section(x)
                                     }}
                                >{name}</div>
                            </>
                        )
                    })}
                    <div className="flex-1">
                        {active_section === "region_wise" &&
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            placeholder={"Select Region..."}
                            name="caste"
                            defaultValue={region_wise_active_section.value}
                            options={region_wise_dropdown}
                            onChange={e => {
                                // @ts-ignore
                                setRegion_wise_active_section(e);
                            }}
                        />
                        }
                    </div>
                </div>

                {active_section !== '' && active_section !== 'region_wise' ? (
                    <CasteTable ds={flexiStore.external_resource[active_section]}/>) : null}
                {active_section === 'region_wise' && region_wise_active_section.value !== "" ? (
                    <CasteTable region={region_wise_active_section.value}
                                ds={flexiStore.external_resource[active_section][region_wise_active_section.value]}/>) : null}
                {active_section === 'region_wise' && region_wise_active_section.value === "" && <SelectRegion/>}
            </div>
        </div>
    )

    const overall = flexiStore.external_resource["overall"]

    return (
        <div className='bg-white pb-4'>
            <div className='pl-4'>
                <div className='font-bold text-2xl pb-2 uppercase'>{t("caste_summary")}</div>

                <div className='font-bold text-lg pb-2 uppercase'>{t("overall")}</div>
                <table className='fsd-caste-details'>
                    <thead>
                        <tr className='font-bold bg-gray-200'>
                            <th className='text-left first'>{t("caste", {ns: "beneficiary"})}</th>
                            <th className='text-right second'>{t("voters")}</th>
                            <th className='text-right third'>{t("voters")}%</th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            overall && overall.length > 0 ? overall.map(row => {
                                const {level, display, voters, voters_percent} = row

                                return (
                                    <tr className={`level-${level}`}>
                                        <td className='text-left first'>{display}</td>
                                        <td className='text-right second'>{voters}</td>
                                        <td className='text-right third'>{voters_percent}</td>
                                    </tr>
                                )
                            }) : <p>Caste data not available</p>
                        }
                    </tbody>
                </table>

                <CasteMatrix lookup_key={'by_zp'} display={'Zilla Panchayat'}/>
                <CasteMatrix lookup_key={'by_hobli'} display={'Hobli'}/>
                <CasteMatrix lookup_key={'by_ward'} display={'Ward'}/>
            </div>
        </div>
    )
})

const NoDataState = (innerprops) => {
    return (
        <>
            <div className='italic'>{t("not_available")}</div>
        </>
    )
}

const SelectRegion = () => {
    return (
        <>
            <div className='italic font-bold py-4'>{t("select_region")}</div>
        </>
    )
}