import React, { useState, useRef } from "react";
import { FormButtons, TailwindModal, Toggle } from "../components";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useStore } from "../helpers";

// Defining the Features component that takes `json` (an object) and `title` (a string) as props
const Features = ({ json, title, onSave }) => {
  const rootStore = useStore();
  const { userStore } = rootStore;
  const isRootOrClient: boolean = userStore.isRoot();
  const [settings, ChangeSettings] = useState<object>(json);
  const [showModal, setShowModal] = useState<boolean>(false);
  const new_setting_name = useRef(null);
  const [isNewSettingEnabled, setEnabled] = useState<boolean>(true);

  return (
    // The main container div with styling classes applied
    <div className="border-slate-400 bg-white rounded-md border-3 p-4 shadow-xl w-full">
      {/* Title of the features section */}
      {showModal && (
        <TailwindModal isOpen={showModal}>
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full p-4">
            <div className="p-4 m-4">
              <h1 className="align-middle">Add New Feature</h1>
              <div className="mt-5 flex flex-wrap justify-between items-center">
                <TextField
                  id="outlined-basic"
                  label="New Feature"
                  variant="outlined"
                  inputRef={new_setting_name}
                />
                <Toggle
                  val={isNewSettingEnabled}
                  onToggle={() => {
                    setEnabled(!isNewSettingEnabled);
                  }}
                />
              </div>
            </div>
            <FormButtons
              onSave={() => {
                if (new_setting_name.current.value !== "") {
                  console.log(
                    new_setting_name.current.value + " " + isNewSettingEnabled
                  );
                  let newSetting: string = new_setting_name.current.value;

                  ChangeSettings({
                    ...settings,
                    [newSetting]: isNewSettingEnabled ? "enabled" : "disabled",
                  });
                  setShowModal(false);
                }
              }}
              onCancel={() => {
                setShowModal(false);
              }}
            />
          </div>
        </TailwindModal>
      )}

      <div className="flex flex-wrap justify-between">
        <div>
          <h1 className="font-extrabold">{title}</h1>
        </div>
        <div className="flex flex-wrap gap-x-2 justify-between">
          <div hidden={!isRootOrClient}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setShowModal(!showModal);
              }}
              endIcon={<i className="fas fa-plus-circle" />}
            >
              Add New Feature
            </Button>
          </div>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                onSave(settings, title);
              }}
              endIcon={<i className="fa-solid fa-floppy-disk" />}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
      {/* List of features laid out in a grid with 2 columns */}
      <ul className="grid grid-cols-1 sm:grid-cols-2 md lg:grid-cols-3 xl:grid-cols-4 gap-2 w-full mt-4">
        {/* Iterating over the entries of the `json` object */}
        {Object.entries(settings).map(([key, value]: any) => {
          // Initialize `value_field` with the key
          let value_field: string = key;

          // Replace underscores with spaces
          value_field = value_field.replace(/_/g, " ");

          // Capitalize the first letter of each word
          value_field = value_field.replace(/\b\w/g, (match) =>
            match.toUpperCase()
          );

          return (
            <>
              {/* List item with alternating background colors based on odd/even */}
              <li
                key={key}
                className={`border-2 ${
                  value === "enabled"
                    ? "bg-blue-200 border-blue-300"
                    : "bg-slate-200 border-slate-300"
                }   rounded-lg m-2 p-4 `}
              >
                <div className="flex flex-wrap justify-between">
                  {/* Displaying the formatted key as a feature name */}
                  <p className="font-bold">{value_field}</p>

                  {/* Displaying the Toggle component */}
                  <p>
                    <Toggle
                      // Setting the Toggle component's `val` prop based on the value being "enabled" or not
                      val={value === "enabled" ? true : false}
                      onToggle={() => {
                        ChangeSettings((prevValues) => {
                          return {
                            ...prevValues,
                            [key]: value === "enabled" ? "disabled" : "enabled",
                          };
                        });
                      }}
                    />
                  </p>
                </div>
              </li>
            </>
          );
        })}
      </ul>
    </div>
  );
};

export default Features;
