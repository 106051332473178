import {observer} from "mobx-react-lite";
import React, {useEffect, useState} from "react";
import {useStore} from "../../helpers/helpers";
import 'react-dropdown-tree-select/dist/styles.css'
import {useProjectIdFromURL} from "../../custom-hooks/get_projectid_from_url";
import {safe_get} from "../../report-visuals/report-utils";
import {useTranslation} from "react-i18next";
import _ from "lodash"
import Select from "react-select";
import {LoadingState} from "../../components";

export const SurveyProgress = observer((props: any) => {

        const {surveyResponseStore, projectStore, api} = useStore()
        const projectid = useProjectIdFromURL()
        const [t]=useTranslation("field_payment");
        const {activeSurveyId} = surveyResponseStore;

        const [allSurveys, setAllSurveys] = useState([]);

        const [loaded, set_loaded] = useState(false)
        const [loading, set_loading] = useState(false)
        const [data, set_data] = useState({})

        const get_survey_progress = async () => {
            if (activeSurveyId == -1) return;
            set_loading(true)
            set_loaded(false)
            const payload = {
                'projectid': projectid,
                'surveyid': activeSurveyId
            }
            const response = await api.post('projectshelf/fieldprogress', payload)
            set_data(response.data)
            set_loaded(true)
            set_loading(false)
        }


        useEffect(() => {
            getSurveyList()
        }, []);

        useEffect(() => {
            get_survey_progress()
        }, [activeSurveyId]);

        const getSurveyList = async() => {
            const response = await projectStore.getFullProject(projectid.toString());
            setAllSurveys(response.data['surveys']);
            surveyResponseStore.setActiveSurveyId(parseInt(response.data['surveys'][0]["id"]))
        }

        const survey_options = () => {
           return allSurveys.map(survey => {
                return({
                    label:survey["name"],
                    value: survey["id"]
                })
            })
        }

        const get_default = () => {
            const surveys = survey_options();
            return surveys.filter(survey => survey.value === surveyResponseStore.activeSurveyId);
        }

        if (loading) return (
            <LoadingState/>
        )

        if (!loaded) return (
            <div className='italic'>{t("configure")}.</div>
        )

        const OverallStatus = (innerprops) => {

            const o_ns_no = safe_get(data['overall_status'], `raw.Not Started`, 0)
            const o_ns_pc = safe_get(data['overall_status'], `percent.Not Started`, '')

            const o_pb_no = safe_get(data['overall_status'], `raw.Partial Booth`, 0)
            const o_pb_pc = safe_get(data['overall_status'], `percent.Partial Booth`, '')

            const o_qc_no = safe_get(data['overall_status'], `raw.QC Pending`, 0)
            const o_qc_pc = safe_get(data['overall_status'], `percent.QC Pending`, '')

            const o_ac_no = safe_get(data['overall_status'], `raw.Accepted`, 0)
            const o_ac_pc = safe_get(data['overall_status'], `percent.Accepted`, '')

            return (
                <>
                    <div className='font-bold text-lg py-2 text-gray-600'>{t("summary")}</div>
                    <table className="divide-y divide-gray-300 bg-white">
                        <tr className='bg-gray-100 font-lg'>
                            <th scope="col" className="py-3.5 pl-6 pr-3 text-left font-semibold text-gray-900">{t("region")}</th>
                            <th scope="col" className="px-3 py-3.5 text-left font-semibold text-gray-900 text-right">{t("not_started")}
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left font-semibold text-gray-900 text-right">{t("partial_booth")}
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left font-semibold text-gray-900 text-right">{t("qc_pending")}
                            </th>
                            <th scope="col"
                                className="px-3 py-3.5 text-left font-semibold text-gray-900 text-right">{t("accepted")}
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left font-semibold text-gray-900 text-right">{t("total")}
                            </th>
                        </tr>
                        {
                            !_.isEmpty(data['region_wise_booths_status']) && Object.keys(data['region_wise_booths_status']).map(region => {

                                const ns_no = safe_get(data['region_wise_booths_status'], `${region}.raw.Not Started`, 0)
                                const ns_pc = safe_get(data['region_wise_booths_status'], `${region}.percent.Not Started`, '')

                                const pb_no = safe_get(data['region_wise_booths_status'], `${region}.raw.Partial Booth`, 0)
                                const pb_pc = safe_get(data['region_wise_booths_status'], `${region}.percent.Partial Booth`, '')

                                const qc_no = safe_get(data['region_wise_booths_status'], `${region}.raw.QC Pending`, 0)
                                const qc_pc = safe_get(data['region_wise_booths_status'], `${region}.percent.QC Pending`, '')

                                const ac_no = safe_get(data['region_wise_booths_status'], `${region}.raw.Accepted`, 0)
                                const ac_pc = safe_get(data['region_wise_booths_status'], `${region}.percent.Accepted`, '')


                                return (
                                    <>
                                        <tr>
                                            <td scope="col"
                                                className="py-3.5 pl-6 pr-3 text-left text-sm font-semibold text-gray-900">{region}</td>
                                            <td scope="col" className="px-3 py-3.5 text-sm text-gray-900 text-right"><span
                                                className='font-bold'>{ns_pc}</span>&nbsp;&nbsp;({ns_no})
                                            </td>
                                            <td scope="col" className="px-3 py-3.5 text-sm text-gray-900 text-right"><span
                                                className='font-bold'>{pb_pc}</span>&nbsp;&nbsp;({pb_no})
                                            </td>
                                            <td scope="col" className="px-3 py-3.5 text-sm text-gray-900 text-right"><span
                                                className='font-bold'>{qc_pc}</span>&nbsp;&nbsp;({qc_no})
                                            </td>
                                            <td scope="col" className="px-3 py-3.5 text-sm text-gray-900 text-right"><span
                                                className='font-bold'>{ac_pc}</span>&nbsp;&nbsp;({ac_no})
                                            </td>
                                            <td scope="col" className="px-3 py-3.5 text-sm text-gray-900 text-right"><span
                                                className='font-bold'>{ns_no + pb_no + qc_no + ac_no}</span></td>
                                        </tr>
                                    </>
                                )
                            })
                        }

                        <tr className='bg-gray-50 font-lg'>
                            <td scope="col"
                                className="py-3.5 pl-6 pr-3 text-left text-sm font-semibold text-gray-900">{t("total")}
                            </td>
                            <td scope="col" className="px-3 py-3.5 text-gray-900 text-right"><span
                                className='font-bold'>{o_ns_pc}</span>&nbsp;&nbsp;({o_ns_no})
                            </td>
                            <td scope="col" className="px-3 py-3.5 text-gray-900 text-right"><span
                                className='font-bold'>{o_pb_pc}</span>&nbsp;&nbsp;({o_pb_no})
                            </td>
                            <td scope="col" className="px-3 py-3.5 text-gray-900 text-right"><span
                                className='font-bold'>{o_qc_pc}</span>&nbsp;&nbsp;({o_qc_no})
                            </td>
                            <td scope="col" className="px-3 py-3.5 text-gray-900 text-right"><span
                                className='font-bold'>{o_ac_pc}</span>&nbsp;&nbsp;({o_ac_no})
                            </td>
                            <td scope="col" className="px-3 py-3.5 text-gray-900 text-right"><span
                                className='font-bold'>{o_ns_no + o_pb_no + o_qc_no + o_ac_no}</span></td>
                        </tr>
                    </table>


                </>
            )
        }

        // const DetailedBoothStatus = (innerprops) => {
        //     const tableRef = useRef(null);
        //
        //     const proj = projectStore.activeProject['name'].replace('/', ' ')
        //     const dt = (new Date().toJSON().slice(0, 10))
        //
        //     const {onDownload} = useDownloadExcel({
        //         currentTableRef: tableRef.current,
        //         filename: `${proj}-${dt}`,
        //         sheet: 'Booth Details'
        //     })
        //
        //     return (
        //         <>
        //             <div className='flex flex-row items-center'>
        //                 <div className='font-bold text-lg py-2 text-gray-600'>Details</div>
        //                 <div className='cursor-pointer mx-4' onClick={onDownload} title='Download Booth Details'>
        //                     <i className="fa-solid fa-download font-lg text-blue-500"/></div>
        //             </div>
        //
        //             <table className="divide-y divide-gray-300 bg-white" ref={tableRef}>
        //                 <thead>
        //                 <tr className='bg-gray-100 font-lg'>
        //                     <th scope="col" className="py-3.5 pl-6 pr-3 text-left font-semibold text-gray-900">Booth</th>
        //                     <th scope="col" className="py-3.5 pl-6 pr-3 text-left font-semibold text-gray-900">Region</th>
        //                     <th scope="col" className="py-3.5 pl-6 pr-3 text-left font-semibold text-gray-900">Status</th>
        //                     <th scope="col" className="py-3.5 pl-6 pr-3 text-left font-semibold text-gray-900">Sub-Status
        //                     </th>
        //                     <th scope="col" className="py-3.5 pl-6 pr-3 text-left font-semibold text-gray-900">Valid</th>
        //                     <th scope="col" className="py-3.5 pl-6 pr-3 text-left font-semibold text-gray-900">Invalid</th>
        //                     <th scope="col" className="py-3.5 pl-6 pr-3 text-left font-semibold text-gray-900">Not Checked
        //                     </th>
        //                     <th scope="col" className="py-3.5 pl-6 pr-3 text-left font-semibold text-gray-900">Target</th>
        //                     <th scope="col" className="py-3.5 pl-6 pr-3 text-left font-semibold text-gray-900">By User</th>
        //                     <th scope="col" className="py-3.5 pl-6 pr-3 text-left font-semibold text-gray-900">By Date</th>
        //                 </tr>
        //                 </thead>
        //                 <tbody>
        //                 {
        //                     Object.keys(data['booth_wise_status']).map(booth => {
        //
        //                         const obj = data['booth_wise_status'][booth]
        //
        //                         const region = safe_get(obj, 'region', '')
        //                         const s1 = safe_get(obj, 's1', '')
        //                         const s2 = safe_get(obj, 's2', '')
        //
        //                         const valid = safe_get(obj, 'details.valid', '')
        //                         const invalid = safe_get(obj, 'details.invalid', '')
        //                         const nc = safe_get(obj, 'details.not-checked', '')
        //                         const target = safe_get(obj, 'details.target', '')
        //
        //                         const user_wise = safe_get(obj, 'user_wise', [])
        //                         const date_wise = Object.keys(safe_get(obj, 'date_wise', {}))
        //
        //                         return (
        //                             <>
        //                                 <tr className=''>
        //                                     <td className="py-3.5 pl-6 pr-3 text-left text-gray-900">{booth}</td>
        //                                     <td className="py-3.5 pl-6 pr-3 text-left text-gray-900">{region}</td>
        //                                     <td className="py-3.5 pl-6 pr-3 text-left text-gray-900">{s1}</td>
        //                                     <td className="py-3.5 pl-6 pr-3 text-left text-gray-900">{s2}</td>
        //                                     <td className="py-3.5 pl-6 pr-3 text-right text-gray-900">{valid}</td>
        //                                     <td className="py-3.5 pl-6 pr-3 text-right text-gray-900">{invalid}</td>
        //                                     <td className="py-3.5 pl-6 pr-3 text-right text-gray-900">{nc}</td>
        //                                     <td className="py-3.5 pl-6 pr-3 text-right text-gray-900">{target}</td>
        //                                     <td className="py-3.5 pl-6 pr-3 text-gray-900 text-sm">
        //                                         {
        //                                             user_wise.map(uo => {
        //
        //                                                 const name = safe_get(uo, 'user.name', '')
        //                                                 const id = safe_get(uo, 'user.id', '')
        //                                                 const va = safe_get(uo, 'vinct.valid', '-')
        //                                                 const inv = safe_get(uo, 'vinct.invalid', '-')
        //                                                 const nc = safe_get(uo, 'vinct.not-checked', '-')
        //                                                 const tgt = safe_get(uo, 'vinct.target', '-')
        //
        //                                                 return (
        //                                                     <>
        //                                                         <span>{name}: {va}v {inv}i {nc}nc {tgt}t</span>&nbsp;&nbsp;&nbsp;
        //                                                     </>
        //                                                 )
        //                                             })
        //
        //                                         }
        //                                     </td>
        //                                     <td className="py-3.5 pl-6 pr-3 text-gray-900 text-sm">
        //                                         {
        //                                             date_wise.map(dt => {
        //                                                 const count = safe_get(obj, `date_wise.${dt}`, '')
        //                                                 return (
        //                                                     <>
        //                                                         <span>{dt}: {count}</span>&nbsp;&nbsp;&nbsp;
        //                                                     </>
        //                                                 )
        //                                             })
        //
        //                                         }
        //                                     </td>
        //                                 </tr>
        //                             </>
        //                         )
        //
        //
        //                     })
        //                 }
        //                 </tbody>
        //             </table>
        //
        //         </>)
        // }


        return (
            <>
                <div className='p-4'>
                    <div className='flex flex-row-reverse items-center'>
                        <div className='w-full lg:w-4/12 z-50 bg-white'>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                defaultValue={get_default()}
                                name="survey"
                                options={survey_options()}
                                onChange={(e) => surveyResponseStore.setActiveSurveyId(e.value)}
                            />
                            {/*<select*/}
                            {/*    className='w-full rounded z-0 focus:shadow outline-none p-2 sm:p-4 '*/}
                            {/*    onChange={(eve) => {*/}
                            {/*        const id = parseInt(eve.target.value)*/}
                            {/*        surveyResponseStore.setActiveSurveyId(id)*/}
                            {/*    }}*/}
                            {/*    value={get_active_survey(surveyResponseStore.activeSurveyId)}*/}
                            {/*>*/}
                            {/*    {allSurveys.map(survey => {*/}
                            {/*        if (!survey) return;*/}
                            {/*        return <option className='p-2 text-md' value={survey.id}>{survey.name}</option>*/}
                            {/*    })}*/}
                            {/*</select>*/}
                        </div>
                        <div className='mx-4 font-bold text-gray-600'>{t("select_survey")}:</div>
                    </div>

                    <OverallStatus/>
                    {/*<DetailedBoothStatus/>*/}

                </div>
            </>
        )
    }
)