import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';

export default function RNAccordion(props) {

    const {title, subtitle} = props;

    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    return (

        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary
                expandIcon={<i className="fas fa-chevron-down"/>}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                <Typography sx={{width: '33%', flexShrink: 0}}>
                    <h3 className="font-bold text-indigo-600">{title}</h3>
                </Typography>
                <Typography sx={{color: 'text.secondary'}}>{subtitle}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {props.children}
            </AccordionDetails>
        </Accordion>

    );
}
