import React, {useState} from "react"
import {observer} from "mobx-react-lite";
import {LoadingState, SideOverlay} from "../../components";
import {useStore} from "../../helpers";
import {useParams} from "react-router";
import {useFSDPermissions} from "../../custom-hooks/useFSDPermissions";
import {useWindowSize} from "react-use";
import {Avatar, Button, IconButton, Stack} from "@mui/material";
import {toast} from "react-toastify";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useFsdUsersSearch} from "./useFsdUsersSearch";

export const ManageFSDPermissions = observer((props: any) => {

    const {flexiStore, userStore} = useStore();
    const {fstid, id} = useParams();
    const {users, isLoading: s} = useFSDPermissions();
    const {width} = useWindowSize();
    const isMobile = width <= 766;
    const queryClient = useQueryClient();
    const [searchQuery, setSearchQUery] = useState('');

    const revoke_permissions = async (user_id) => {
        const deletePermissions = {
            userid: parseInt(user_id),
            fsdid: parseInt(id),
            fstid: parseInt(fstid),
        };
        return await flexiStore.revoke_fsd_permissions(deletePermissions);
    };

    const {mutate: deleteUser, isLoading} = useMutation({
        mutationFn: revoke_permissions,
        onSuccess: (data) => {
            setTimeout(async () => {
                await queryClient.invalidateQueries([
                    "fsd-permissions", id
                ]);
            }, 1000);
            toast.success("User has been deleted...");
        },
        onError: ({message}) => {
            toast.error(message);
        },
    })

    if (isLoading || s) <LoadingState/>

    const {filteredUsers} = useFsdUsersSearch({users, searchQuery})

    return (
        <>
            <SideOverlay
                onClose={() => flexiStore.update_flexistore_property("manage_fsd_permissions_overlay", false)}
                show={flexiStore.manage_fsd_permissions_overlay}
                title={"Permissions"}
            >
                <Stack direction={"row"} className={"flex flex-row items-center justify-between gap-4 mb-4"}>
                    <input
                        value={searchQuery}
                        onChange={(evt) => {
                            setSearchQUery(evt.target.value);
                        }}
                        className="border-2 border-gray-300 bg-white h-8 sm:h-12 px-5 rounded-lg focus:outline-none "
                        type="search" name="search" placeholder="Search Users"
                    />
                    <Button
                        variant={"contained"}
                        onClick={() => flexiStore.update_flexistore_property("add_users_to_fsd_overlay", true)}
                        color={"primary"}
                        startIcon={<i className={"fas fa-plus-circle"}/>}
                        className={"w-fit"}
                    >
                        Add Users
                    </Button>
                </Stack>
                {filteredUsers.map(user =>
                    <div
                        key={user.id}
                        className="cursor-pointer group relative rounded-lg border border-gray-300 bg-gray-100 p-2 sm:px-4 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 bg-gray-50 mb-2 "
                    >
                        <Avatar
                            src={user.profileimageuri}
                            alt={user.name}
                            sx={{height: 72, width: 72}}
                        />

                        <div className="flex flex-row items-center justify-between flex-1">
                            <div className="flex flex-col flex-1">
                                <div className={"flex flex-row items-center justify-between flex-1"}>
                                    <p className="mt-1 font-bold flex items-center text-md text-purple-600 mr-2 flex-nowrap">
                                        {user.name}
                                    </p>
                                    {userStore.isRoot() ? <IconButton
                                        className={`${!isMobile ? "invisible group-hover:visible" : ""}`}
                                        size={"small"}
                                        onClick={(e) => {
                                            deleteUser(user.id)
                                        }}>
                                        <i className={"fas fa-trash text-red-600"}/>
                                    </IconButton> : null}
                                </div>

                                <p className="mt-1 font-bold flex items-center text-sm text-gray-500 mr-2 flex-nowrap">
                                    {user.phonenum}
                                </p>
                                <div>{user.role}</div>
                            </div>
                        </div>
                    </div>
                )
                }
            </SideOverlay>
        </>
    )
})