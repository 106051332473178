import React, {useEffect, useState} from "react"
import {observer} from "mobx-react-lite";
import {useStore} from "../../helpers/helpers";
import {classNames, htmlValidation, update_item_in_array} from "../../helpers/utils";
import _ from "lodash"
import {safe_get} from "../../report-visuals/report-utils";
import {toJS} from "mobx";
import {toast} from "react-toastify";
import Select from "react-select";
import {Switch} from "@headlessui/react";
import {useTranslation} from "react-i18next";
import {LoadingState} from "../../components";
import moment from "moment";

export const EditVoter = observer((props) => {
    const {t} = useTranslation("beneficiary");
    const rootStore = useStore();
    const {voterListStore, projectStore} = rootStore;
    const {activeVoter, edit_voter_modal, Voterlist} = voterListStore;
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState(activeVoter);

    const profile_questions_property = projectStore.getProjectProperty("profile_questions", {});
    const voter_availability_property = projectStore.getProjectProperty("voter_availibility", {});
    const voterrecords_v2_property = projectStore.getProjectProperty("voterrecords_v2",{});
    const caste_options = safe_get(profile_questions_property, "caste", []);
    const outsdie_city_options = safe_get(voter_availability_property, "outside_voter_cities", [])
    const party_options = safe_get(voterrecords_v2_property, "party_support", []);

    const handleCancel = () => {
        voterListStore.update_edit_voter_modal(!edit_voter_modal)
    };

    const handleSubmit = async (e) => {
        htmlValidation(e)

        voterListStore.update_edit_voter_modal(!edit_voter_modal);
        voterListStore.update_active_voter(formData);

        const list = _.clone(Voterlist);
        const updated = await update_item_in_array(list, "id", formData);
        voterListStore.setVoterList(updated);

        const es = projectStore.getProjectProperty("voterrecords_v2", "");
        const esindex = safe_get(es, "esindex", "");

        if (esindex) {
            const record = {
                "_op_type": "update",
                "_index": esindex,
                "_id": activeVoter["id"],
                "_source": {"doc": toJS(formData)}
            }
            setLoading(true)
            const res = await voterListStore.update_voter_data(record)
            if (res.status === 200) {
                toast.success("Voter Updated Successfully...")
            } else {
                toast.error("Something went wrong")
            }
            setLoading(false)
        }
    };

    return (
        <>
            {loading && <LoadingState />}
            <form onSubmit={handleSubmit} className="w-full overflow-y-auto">
                <div className="flex flex-col ">
                    <div className="  w-full mb-2 flex flex-row items-center">
                        <label htmlFor="name" className="block text-gray-700 w-1/3">
                            Voter Id
                        </label>
                        <input
                            type="text"
                            id="id"
                            name="id"
                            value={formData["id"]}
                            disabled
                            className="form-input mt-1 w-2/3 px-1 py-1  border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        />
                    </div>
                    <div className=" w-full mb-2 flex flex-row items-center">
                        <label htmlFor="name" className="block text-gray-700 w-1/3">
                            Serial #
                        </label>
                        <input
                            type="text"
                            id="serial"
                            name="serial"
                            value={formData["profile"]["serialno"]}
                            disabled
                            className="form-input mt-1 w-2/3 px-1 py-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        />
                    </div>
                    <div className=" w-full mb-2 flex flex-row items-center ">
                        <label htmlFor="name" className="block text-gray-700 w-1/3">
                            Booth #
                        </label>
                        <input
                            id="booth"
                            name="part"
                            value={formData["part"]}
                            onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    "part": e.target.value
                                })
                            }}
                            className="form-input mt-1 w-2/3 px-1 py-1   border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        />
                    </div>
                    <div className=" w-full mb-2 flex flex-row items-center">
                        <label htmlFor="name" className="block text-gray-700  w-1/3">
                           Reg-Name
                        </label>
                        <input
                            type="text"
                            id="name"
                            name="reg"
                            value={formData["profile"]["name_reg"]}
                            onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    "profile": {...formData["profile"], "name_reg": e.target.value}
                                })
                            }}
                            className="form-input mt-1 w-2/3 px-1 py-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        />
                    </div>
                    <div className=" w-full mb-2 flex flex-row items-center">
                        <label htmlFor="name" className="block text-gray-700 w-1/3">
                            Eng-Name
                        </label>
                        <input
                            type="text"
                            id="eng"
                            name="eng"
                            value={formData["profile"]["name_eng"]}
                            onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    "profile": {...formData["profile"], "name_eng": e.target.value}
                                })
                            }}
                            className="form-input mt-1 w-2/3 px-1 py-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        />
                    </div>
                    <div className=" w-full mb-2 flex flex-row items-center">
                        <label htmlFor="address" className="block text-gray-700 w-1/3">
                            Phone Number:
                        </label>
                        <input
                            id="phone"
                            name="phone"
                            value={formData["phone"]}
                            onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    "phone": e.target.value
                                })
                            }}
                            className="form-input mt-1 w-2/3 px-1 py-1  border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        />
                    </div>
                    <div className="w-full mb-2 flex flex-row items-center">
                        <label htmlFor="dob" className="block text-gray-700 w-1/3">
                            Date of Birth:
                        </label>
                        <input
                            id="dob"
                            name="dob"
                            type="date"
                            defaultValue={moment(formData["profile"]["dob"], ["DD-MM-YYYY"]).format('YYYY-MM-DD')}
                            onChange={(e) => {
                                const formattedDate = moment(e.target.value).format('DD-MM-YYYY');
                                setFormData({
                                    ...formData,
                                    "profile": {...formData["profile"], "dob": formattedDate}
                                });
                            }}
                            className="form-input mt-1 w-2/3 px-1 py-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        />
                    </div>

                    <div className=" w-full mb-2 flex flex-row items-center">
                        <label htmlFor="age" className="block text-gray-700 w-1/3">
                            Age:
                        </label>
                        <input
                            type="number"
                            id="age"
                            name="age"
                            value={formData["profile"]["age"]}
                            onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    "profile": {...formData["profile"], "age": e.target.value}
                                })
                            }}
                            className="form-input mt-1 w-2/3 px-1 py-1  border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        />
                    </div>

                    <div className=" w-full mb-2 flex flex-row items-center">
                        <label htmlFor="address" className="block text-gray-700 w-1/3">
                            House:
                        </label>
                        <input
                            id="house"
                            name="house"
                            value={formData["profile"]["house"]}
                            onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    "profile": {...formData["profile"], "house": e.target.value}
                                })
                            }}
                            className="form-input mt-1 w-2/3 px-1 py-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        />
                    </div>

                    <div className="w-full mb-2 flex flex-row items-center">
                        <label htmlFor="gender" className="block text-gray-700 w-1/3">
                            Gender:
                        </label>
                        <input
                            id="gender"
                            name="gender"
                            value={formData["profile"]["gender"]}
                            onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    "profile": { ...formData["profile"], "gender": e.target.value }
                                });
                            }}
                            className="form-select mt-1 w-2/3 px-1 py-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        />
                    </div>
                    <div className=" w-full mb-2 flex flex-row items-center">
                        <label htmlFor="address" className="block text-gray-700 w-1/3">
                            Caste:
                        </label>
                        <Select
                            options={caste_options}
                            id="caste"
                            name="caste"
                            value={caste_options.find(x => x.value === safe_get(formData,"caste", ""))}
                            onChange={(val) => {
                                setFormData({...formData, "caste": val["value"]})
                            }}
                            className="form-input mt-1 w-2/3 px-1 py-1  border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        />
                    </div>

                    <div className=" w-full mb-2 flex flex-row items-center">
                        <label htmlFor="address" className="block text-gray-700 w-1/3">
                            Relation-name-reg
                        </label>
                        <input
                            id="relation"
                            name="relation_name_reg"
                            value={formData["profile"]["relation_name_reg"]}
                            onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    "profile": {...formData["profile"], "relation_name_reg": e.target.value}
                                })
                            }}
                            className="form-input mt-1 w-2/3 px-1 py-1  border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        />
                    </div>
                    <div className=" w-full mb-2 flex flex-row items-center">
                        <label htmlFor="address" className="block text-gray-700 w-1/3">
                            Relation-name-eng
                        </label>
                        <input
                            id="relation"
                            name="relation_name_eng"
                            value={formData["profile"]["relation_name_eng"]}
                            onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    "profile": {...formData["profile"], "relation_name_eng": e.target.value}
                                })
                            }}
                            className="form-input mt-1 w-2/3 px-1 py-1  border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        />
                    </div>
                    <div className=" w-full mb-2 flex flex-row items-center">
                        <label htmlFor="address" className="block text-gray-700 w-1/3">
                            Address:
                        </label>
                        <input
                            id="address"
                            name="address"
                            value={formData["profile"]["address"]}
                            onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    "profile": {...formData["profile"], "address": e.target.value}
                                })
                            }}
                            className="form-input mt-1 w-2/3 px-1 py-1  border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        />
                    </div>
                    <div className=" w-full mb-2 flex flex-row items-center">
                        <label htmlFor="address" className="block text-gray-700 w-1/3">
                            Party:
                        </label>
                        <Select
                            options={party_options.map(value => {
                                return{
                                    label:value,
                                    value:value
                                }
                            })}
                            menuPlacement={"auto"}
                            id="party"
                            name="party"
                            defaultValue={() => {
                                return {
                                    label: safe_get(formData, "party", ""),
                                    value: safe_get(formData, "party", ""),
                                }
                            }}
                            onChange={(val) => {
                                setFormData({...formData, "party": val["value"]})
                            }}
                            className="form-input mt-1 w-2/3 px-1 py-1  border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        />
                    </div>

                    <div className=" w-full mb-2 flex flex-row items-center ">
                        <label htmlFor="name" className="block text-gray-700 w-1/3">
                           Influential Leaders
                        </label>
                        <input
                            id="influential"
                            name="leaders"
                            value={formData["leaders"]}
                            onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    "leaders": e.target.value
                                })
                            }}
                            className="form-input mt-1 w-2/3 px-1 py-1   border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        />
                    </div>

                    <div className="my-2">
                        <Switch.Group as="div" className="flex items-center">
                            <Switch.Label as="span" className="font-medium text-gray-900" passive>
                                Is this voter living outside this assembly constituency ?
                            </Switch.Label>
                            <Switch
                                checked={safe_get(formData, "is_living_outside_ac", false)}
                                onChange={() => {
                                    setFormData({...formData, is_living_outside_ac: !formData.is_living_outside_ac})
                                }}
                                className={classNames(
                                    formData.is_living_outside_ac ? 'bg-indigo-600' : 'bg-gray-200',
                                    'relative inline-flex mx-4 h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out '
                                )}
                            >
                                <span
                                    aria-hidden="true"
                                    className={classNames(
                                        formData.is_living_outside_ac ? 'translate-x-5' : 'translate-x-0',
                                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                    )}
                                />
                            </Switch>

                        </Switch.Group>
                    </div>

                    {formData.is_living_outside_ac && <div className=" w-full mb-2 flex flex-row items-center">
                        <label htmlFor="address" className="block text-gray-700 w-1/3">
                            Select the town/city
                        </label>
                        <Select
                            options={outsdie_city_options}
                            id="oac"
                            name="oac"
                            value={outsdie_city_options.find(x => x.value === safe_get(formData, "which_city", ""))}
                            onChange={(val) => {
                                setFormData({...formData, "city": val["value"]})
                            }}
                            className="form-input mt-1 w-2/3 px-1 py-1  border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        />
                    </div>}

                    <div className="flex justify-end space-x-3">
                        <button
                            type="button"
                            onClick={handleCancel}
                            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-4"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            onClick={handleSubmit}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </form>

        </>
    )
})