import React, {useState} from 'react';
import { observer } from "mobx-react-lite";
import { safe_get } from "../../report-visuals/report-utils";
import {
    DeleteButton,
    EditButton,
    ViewAttachment
} from "../../components";
import { useIsMobile } from "../../custom-hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { useStore } from "../../helpers";
import { useTranslation } from "react-i18next";
import {AddNew} from "./add-new";import {AddProjectWork} from "./add-project-work-v2";
import {PWViewCrumbs} from "./project-work-bread-crumbs";
import {AddFundsV2} from "./funds";
import {AddMediaV2} from "./media";
import {AddEventV2} from "./events/add-event-v2";
import {AddKeyContacts} from "./key-contacts/add-key-contacts-v2";

export const ProjectWorkInfo = observer((props) => {

    const isMobile = useIsMobile();
    const location = useLocation();
    const navigate = useNavigate();
    const {info} =  location.state || {};
    const { projectWorkStoreV2 } = useStore();
    const { t } = useTranslation("project_work");
    // const [active_project, setActiveProject] = useState(projectWorkStoreV2.activeProjectWork);
    const [active_project, setActiveProject] = useState(JSON.parse(info));
    const {summary} = active_project;

    const editPW = () => {
        projectWorkStoreV2.update_add_project_modal(true);
        projectWorkStoreV2.update_project_work_property("activeProjectWork", active_project)
    };

    const deletePW = async () => {
        const res = await projectWorkStoreV2.delete_project_work(active_project["id"]);
        if (res.status === 200) {
            projectWorkStoreV2.update_project_work_property("delete_modal", false);
            navigate(-1);
        }
    };

    const coverPhoto = safe_get(active_project, "summary.coverPhotoPath", {})
    const latitude = safe_get(active_project, "summary.latitude", 0);
    const longitude = safe_get(active_project, "summary.longitude", 0);

    return (
        <>

            {!isMobile ? <PWViewCrumbs name={safe_get(active_project, "summary.name", "Project Details")}/> : null}
            <div className='flex flex-wrap sm:flex-no-wrap px-4'>
                <div className='w-full'>
                    <div className="flex justify-between">
                        <div
                            className='text-xl font-bold py-4'>{safe_get(active_project, "summary.name", t("manage_project_works"))}</div>
                        <div className='flex flex-row items-center gap-x-2'>
                            <DeleteButton
                                OnDeleteClickHandler={() => {
                                    projectWorkStoreV2.update_project_work_property("delete_modal", true);
                                }}
                            />
                            <EditButton
                                OnEditClickHandler={editPW}
                            />
                        </div>
                    </div>
                    <div className="overflow-hidden rounded-lg shadow-lg">
                        {/*<img className="object-cover w-full max-h-96" src={cover_photo} alt="" loading={"lazy"}/>*/}
                        <div className="w-full max-h-[450px] object-cover" style={{width: "100%"}}>
                            <ViewAttachment
                                attachment={coverPhoto}
                                label={false}
                            />
                        </div>
                    </div>

                    <div className="flex flex-col pt-2">
                        <div className="text-black text-md font-bold">
                            Estimated Completion Date
                            - {safe_get(summary, "estimatedCompletionDate", "")}
                        </div>
                    </div>

                    <div className="py-4">
                        {/*<h4 className="font-semibold tracking-tight">{!_.isEmpty(current_fsd) ? current_fsd.summary['name'] : 'Project Name'}</h4>*/}
                        {latitude && longitude ?
                            <a href={`http://maps.google.com/maps?q=${latitude},${longitude}`} target={"_blank"}
                               className='flex items-center underline text-blue-500 cursor-pointer'>
                                <i className="fa-solid fa-location-dot"/>
                                <p className="leading-normal p-2">{safe_get(active_project, "summary.location", "Project Location")}</p>
                            </a> : null}
                        <div
                            className=''>{safe_get(active_project, "summary.description", "Project Descripton")}</div>
                    </div>

                    <div className="rounded-lg shadow-md bg-white p-2 sm:p-4 my-2">
                        <AddNew heading={"Media"} title={t("add_media")} onAddClick={e => {
                            projectWorkStoreV2.update_project_work_property("add_media_modal", true);
                        }}>
                            <i className="far fa-wallet text-xl pr-4 text-purple-500"/>
                        </AddNew>

                    </div>

                    <div className="rounded-lg shadow-md bg-white p-2 sm:p-4 my-2">
                        <AddNew heading={"Funds"} title={t("add_fund")} onAddClick={e => {
                            projectWorkStoreV2.update_project_work_property("add_fund_modal", true);
                        }}>
                            <i className="far fa-wallet text-xl pr-4 text-purple-500"/>
                        </AddNew>

                    </div>

                    <div className="rounded-lg shadow-md bg-white p-2 sm:p-4 my-2">
                        <AddNew heading={"Events"} title={t("Add Event")} onAddClick={e => {
                            projectWorkStoreV2.update_project_work_property("add_event_modal", true);
                        }}>
                            <i className="far fa-wallet text-xl pr-4 text-purple-500"/>
                        </AddNew>

                    </div>

                    <div className="rounded-lg shadow-md bg-white p-2 sm:p-4 my-2">
                        <AddNew heading={"Key Contacts"} title={t("Add Key Contacts")} onAddClick={e => {
                            projectWorkStoreV2.update_project_work_property("add_key_contacts", true);
                        }}>
                            <i className="far fa-wallet text-xl pr-4 text-purple-500"/>
                        </AddNew>

                    </div>
                    <AddProjectWork onAdd={(data) => setActiveProject(data)}/>

                </div>
            </div>
            <AddFundsV2/>
            <AddMediaV2/>
            <AddEventV2/>
            <AddKeyContacts/>
        </>
    );
});
