import {useQuery} from "@tanstack/react-query";
import {useStore} from "../../../helpers";
import {useParams} from "react-router-dom";

export const useProjectWorksList = () => {

    const {projectWorkStoreV2} = useStore();
    const {searchQuery} = projectWorkStoreV2;
    const {projectid} = useParams();

    const payload = {
        projectid: parseInt(projectid),
        query: searchQuery,
        filters: projectWorkStoreV2.filters,
        count: 100,
        offset: 0
    };

    const getallProjectWorks = async () => {
        const res = await projectWorkStoreV2.getProjectWorks(payload,projectid);
        if (!res.ok) {
            throw new Error('Failed to fetch Project Works data');
        }
        return res;
    };

    const {isLoading} = useQuery({
        queryKey: ["project-works", projectid, (payload)],
        queryFn: getallProjectWorks,
        onSuccess: data => {
            projectWorkStoreV2.update_project_work_property("set_project_works", data["data"]);
        }
    });

    return {isLoading}

}