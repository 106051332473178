import React, {useEffect, useState} from "react";
import {ACBankDAO} from "./acbank_dao";
import {useStore} from "../../../helpers";
import {RootStore} from "../../../stores/root";
import {LoadingState} from "../../../components";
import {observer} from "mobx-react-lite";
import {ElectionResults} from "../../shared/election_results";
import { useTranslation } from "react-i18next";

export const ACBankElectionResults = observer((props) => {

    const rootStore: RootStore = useStore()
    const {flexiStore} = rootStore;
    const [active_election, set_active_election] = useState('')
    const dao = new ACBankDAO(rootStore)
    const {t} = useTranslation("ac_bank");

    useEffect(() => {
        dao.load_election_results()
    }, [])

    useEffect(() => {
        if (flexiStore.external_resource && Object.keys(flexiStore.external_resource).length > 0) {
            const elections = Object.keys(flexiStore.external_resource)
            set_active_election(elections[0])
        }
    }, [flexiStore.external_resource_key])

    if (flexiStore.loading_external_resource) return (<LoadingState/>);
    if (flexiStore.external_resource_key !== 'election_results') return <div className='italic'>{t("results_not_available")}</div>;

    const elections = Object.keys(flexiStore.external_resource)

    return (
        <>
            <div className='pl-4'>
                <div className='font-bold text-2xl pb-2 uppercase'>{t("past_election_results")}</div>
                <div className='flex flex-row flex-wrap'>
                    {elections.map(x => {
                        const bg = (active_election === x) ? 'bg-indigo-500 text-white font-bold' : 'bg-gray-200 border border-black'
                        return (
                            <>
                                <div className={`mt-2 py-2 px-4 text-sm ${bg} cursor-pointer mr-4 rounded-2xl`}
                                     onClick={() => {
                                         set_active_election(x)
                                     }}
                                >{x}</div>
                            </>
                        )
                    })}
                </div>
                {active_election !== '' ? (<ElectionResults name={active_election} ds={flexiStore.external_resource[active_election]}/>) : null}
            </div>
        </>
    )
})