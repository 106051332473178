import { action, makeAutoObservable } from "mobx"; // Import MobX utilities for state management.
import { safe_get } from "../report-visuals/report-utils"; // Import utility function for safely getting properties from objects.
import _ from "lodash"; // Import lodash for utility functions like filtering.

// Define the ProjectStore class for managing project-related state and actions.
export class ProjectStore {
  // Define properties of the ProjectStore.
  rootStore: any; // Reference to the root store (providing access to other stores).
  hasCheckedAuth: boolean = false; // Boolean indicating if authorization has been checked.
  isAuthorized: boolean = false; // Boolean indicating if the user is authorized.
  activeProject: any = null; // Holds the active project data.
  voter_reg_tracker_config: any = {}; // Configuration for the voter registration tracker.
  active_project_id: any = -1; // ID of the active project.

  constructor(RootStore: any) {
    // Automatically make all properties and methods observable actions.
    makeAutoObservable(this);
    this.rootStore = RootStore; // Store a reference to the root store.
    this.isAuthorized = false;
    this.hasCheckedAuth = false;
    this.hydrate_project(); // Load the project data from localStorage when initializing.
  }

  // Action to set the active project ID.
  @action
  set_active_project_id(id) {
    this.active_project_id = id;
  }

  // Action to fetch the full project data from the server.
  @action
  getFullProject = async (projectid: string) => {
    const payload = {
      projectid: parseInt(projectid),
    };
    const response = await this.rootStore.api.post(
      "project/getfullproject",
      payload
    ); // Send a POST request to get the full project data.
    this.dehydrate_project(response.data); // Save the project data to localStorage.
    return response; // Return the server response.
  };

  // Function to get a survey by its ID from the active project stored in localStorage.
  getSurveyByID(surveyid) {
    const all_surveys = JSON.parse(localStorage.getItem("__active_project"))[
      "surveys"
    ];
    const matches = _.filter(all_surveys, { id: surveyid }); // Filter the surveys to find the one with the matching ID.
    if (matches.length === 1) {
      return matches[0]; // Return the matching survey if found.
    } else {
      return null; // Return null if no matching survey is found.
    }
  }

  // Action to get the status of a specific feature in the project.
  @action
  getFeatureStatus(featureName) {
    const features = this.getProjectProperty("features"); // Get the features property from the project.
    return safe_get(features, featureName, "disabled"); // Safely retrieve the status of the feature.
  }

  // Action to get a specific property of the project from localStorage.
  @action
  getProjectProperty(propName, defaultValue = {}) {
    const ls_project = JSON.parse(localStorage.getItem("__active_project"));
    if (ls_project && ls_project["id"]) {
      return safe_get(
        ls_project["properties"],
        `${propName}.value`,
        defaultValue
      ); // Safely get the project property.
    } else return defaultValue; // Return default value if the project is not found.
  }

  // Action to get a permission status for the current user in the active project.
  @action
  getPermission(permission, defaultValue) {
    try {
      const __activeProject = JSON.parse(
        localStorage.getItem("__active_project")
      );
      if (__activeProject !== null || __activeProject !== "null") {
        return safe_get(
          __activeProject["user_permissions"],
          `${permission}`,
          defaultValue
        ); // Safely get the permission status.
      } else return defaultValue; // Return default value if the project is not found.
    } catch (e) {
      console.log(e);
    }
  }

  // Action to fetch a list of all surveys available in the project.
  @action
  getAllSurveyList = async () => {
    const response = await this.rootStore.api.post(
      "project/projectsandsurveys",
      {}
    );
    return response.data; // Return the list of surveys.
  };

  // Action to translate a given text to a specified language.
  @action
  getTranslation = async (text, tolang) => {
    const payload = { text, tolang };
    const response = await this.rootStore.api.post("utils/translate", payload); // Send a POST request to translate the text.
    return response.data; // Return the translated text.
  };

  // Action to clone an existing survey in the project.
  @action
  cloneSurvey = async (projectid, surveyid) => {
    const payload = { projectid, surveyid };
    await this.rootStore.api.post("project/newsurvey-clone", payload); // Send a POST request to clone the survey.
  };

  // Action to create a new blank survey in the project.
  @action
  blankSurvey = async (projectid) => {
    const payload = { projectid };
    await this.rootStore.api.post("project/newsurvey-blank", payload); // Send a POST request to create a blank survey.
  };

  // Action to delete a survey from the project.
  @action
  deleteSurvey = async (projectid, surveyid) => {
    const payload = { projectid, surveyid };
    await this.rootStore.api.post("project/deletesurvey", payload); // Send a POST request to delete the survey.
  };

  // Action to either edit an existing survey or create a new one.
  @action
  editOrNewSurvey = async (payload) => {
    await this.rootStore.api.post("project/editornewsurvey", payload); // Send a POST request to edit or create the survey.
  };

  // Action to add a new project to the system.
  @action
  addNewProject = async (payload) => {
    return await this.rootStore.api.post("project/addproject", payload); // Send a POST request to add the new project.
  };

  // Action to edit the metadata of an existing project.
  @action
  editProject = async (payload) => {
    return await this.rootStore.api.post("project/editmeta", payload); // Send a POST request to edit the project metadata.
  };

  // Action to delete an existing project.
  @action
  deleteProject = async (payload, id) => {
    payload["id"] = id;
    return await this.rootStore.api.post("project/delete", payload); // Send a POST request to delete the project.
  };

  // Action to edit the properties of an existing project.
  @action
  editProjectProperties = async (payload) => {
    return await this.rootStore.api.post(
      "project/editprojectproperties",
      payload
    ); // Send a POST request to edit the project properties.
  };

  // Action to add a new user to the project.
  @action
  addNewUser = (payload) => {
    return this.rootStore.api.post("user/inviteuserbulk", payload); // Send a POST request to invite a new user.
  };

  // Action to fetch all users associated with the project.
  @action
  getAllUsers = (payload) => {
    return this.rootStore.api.post("projectshelf/searchusers", payload); // Send a POST request to fetch all users.
  };

  // Action to fetch all user tags associated with the project.
  @action
  getAllUserTags = (payload) => {
    return this.rootStore.api.post("projectshelf/allusertags", payload); // Send a POST request to fetch all user tags.
  };

  // Action to load the project data from localStorage.
  @action
  hydrate_project = () => {
    try {
      const project_info_in_storage =
        window.localStorage.getItem("__active_project");
      this.activeProject = JSON.parse(project_info_in_storage); // Load the active project data from localStorage.
    } catch (e) {
      this.activeProject = {}; // Set an empty object if there's an error.
    }
  };

  // Action to save the project data to localStorage.
  @action
  dehydrate_project = async (full_project_payload) => {
    try {
      await window.localStorage.setItem(
        "__active_project",
        JSON.stringify(full_project_payload)
      ); // Save the project data to localStorage.
      this.activeProject = full_project_payload; // Update the active project in the store.
    } catch (e) {
      this.activeProject = {}; // Set an empty object if there's an error.
    }
  };

  // Action to fetch GOTV (Get Out The Vote) data for a project.
  @action
  get_GoTV_data = (projectid) => {
    return this.rootStore.api.post("project/gotvsummary", projectid); // Send a POST request to fetch GOTV data.
  };

  // Action to set the voter registration tracker configuration.
  @action
  set_voter_reg_tracker_config(config) {
    this.voter_reg_tracker_config = config; // Update the voter registration tracker configuration in the store.
  }

  // Action to update the active project in the store.
  @action
  update_active_project(project) {
    this.activeProject = project; // Update the active project with new data.
  }
}

// MobX Store (makeAutoObservable):

// The ProjectStore class is made observable with MobX's makeAutoObservable, allowing for automatic management of state, actions, and computed properties.
// Root Store Reference:

// The ProjectStore holds a reference to the RootStore, giving it access to other stores and shared functionality.
// Local Storage Management:

// The store loads (hydrate_project) and saves (dehydrate_project) the active project data to and from localStorage, ensuring persistence across sessions.
// Actions:

// The store defines various actions using the @action decorator to manage project data, interact with APIs, and update the application state.
// API Interactions:

// The store interacts with an API (via the root store) to fetch, create, update, and delete project-related data, as well as to manage surveys, user permissions, and project properties.
// Utility Functions:

// Functions like getSurveyByID, getFeatureStatus, and getPermission help retrieve specific pieces of data from the project in a safe and structured way.
