import React from "react";
import { zeroPad } from "../../../helpers/helpers";
import { FlexiStoreData_Abridged } from "../../../stores/fs/fst";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const WardBankSearchCard = (props) => {
  console.log(props);
  const fsd: FlexiStoreData_Abridged = props.data;
  const navigate = useNavigate();
  const [t] = useTranslation(["ward_bank", "ac_bank"]);

  // @ts-ignore
  const { acno, acname, res, region, district } = fsd.summary;

  return (
    // <div key={`fsd_${fsd.id}`}
    //      className="flex flex-row rounded-lg shadow-lg overflow-hidden cursor-pointer bg-white p-3"
    //      style={{borderLeft: `4px solid`, borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
    //      onClick={(evt) => {
    //          // navigate('/repo/' + fsd.key);
    //      }}
    // >
    //     <div className="rounded-full flex flex-col items-center justify-center" style={{backgroundColor: `yellow`,width: 60, height: 60}}>
    //         <i className={`text-3xl `} style={{color: `green`}}/>
    //         <div className="font-bold text-gray-500">AC</div>
    //         <div className="font-bold text-gray-500">{zeroPad(parseInt(acno), 3)}</div>
    //     </div>
    //     <div className="flex-1 flex flex-col ml-2 justify-between">
    //
    //         <div className="font-bold text-gray-700">{acname} ({res})</div>
    //         <div className="text-gray-600 text-md">{district}, {region}</div>
    //
    //     </div>
    // </div>
    <div
      key={`fsd_${fsd.id}`}
      className="flex flex-row rounded-lg shadow-lg overflow-hidden cursor-pointer bg-white p-3"
      style={{
        borderLeft: `4px solid`,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      }}
      onClick={(evt) => {
        navigate("/repo/" + fsd.key + "/" + fsd.id);
      }}
    >
      <div
        className="rounded-full flex flex-col items-center justify-center"
        style={{ backgroundColor: `green`, width: 60, height: 60 }}
      >
        <i className={`text-3xl `} style={{ color: `green` }} />
        <div className="font-bold text-gray-500">
          {t("ac", { ns: "ac_bank" })}
        </div>
        <div className="font-bold text-gray-500">{zeroPad(1, 3)}</div>
      </div>
      <div className="flex-1 flex flex-col ml-2 justify-between">
        <div className="font-bold text-gray-700">{t("ward")}</div>
        <div className="text-gray-600 text-md">{t("bbmp")}</div>
      </div>
    </div>
  );
};
