import React, {useState} from 'react'
import {useStore} from "../helpers";
import {useQuery} from "@tanstack/react-query";
import {useParams} from "react-router";

export const useFSDPermissions = () => {

    const rootStore = useStore();
    const {flexiStore} = rootStore;
    const [users, setUsers] = useState([]);
    const {fstid, id} = useParams();

    const get_permissions = async () => {
        const res = await flexiStore.get_fsd_permissions({fstid: parseInt(fstid), fsdid: parseInt(id)});
        if (!res.ok) {
            throw new Error("Something went wrong...");
        }
        return res;
    };

    const {isLoading, refetch} = useQuery({
        queryFn: get_permissions,
        queryKey: ["fsd-permissions", id],
        onSuccess: data => {
            setUsers(data?.data?.perms.fsd)
        }
    })

    return {users, isLoading, refetch};
}