import React from "react";
import {Dna} from "react-loader-spinner";

export const LoadingState = () => {
    return (
        <div className="w-full h-full fixed block top-0 left-0 bg-gray-50 opacity-90 " style={{zIndex: 99999}}>
                <span className="text-green-500 top-1/2 right-8 my-0 mx-auto block relative w-0 h-0"
                      style={{top: '48%'}}>
                     <Dna
                         visible={true}
                         height="100"
                         width="100"
                         ariaLabel="dna-loading"
                         wrapperStyle={{}}
                         wrapperClass="dna-wrapper"
                     />
                    {/*<i className="fa-solid fa-spinner fa-spin fa-3x"/>*/}
                </span>
        </div>
    )
}