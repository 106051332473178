import {observer} from "mobx-react-lite";
import React from "react";
import {useStore} from "../../helpers/helpers";
import _ from "lodash";
import {useTranslation} from "react-i18next";

export const ClearFiltersForVisual = observer((props: any) => {

    const rootStore = useStore();
    const {reportStore} = rootStore;
    const [t]=useTranslation("clear_filters");
    return (
        <>
            {!_.isEmpty(reportStore.currentReportFilters) ? <div className='font-sm text-blue-500 mt-4 hover:text-blue-700 cursor-pointer' onClick={() => {
                    reportStore.clearAllActiveFilters();
                }}>{t("clear_filters")}
                </div> : null}
        </>
    );

});