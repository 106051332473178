import React, {Fragment, useEffect, useRef, useState} from "react";
import {observer} from "mobx-react-lite";
import {Menu, Transition} from "@headlessui/react";
import {ChevronDownIcon} from "@heroicons/react/24/solid";
import _ from "lodash";
import {safe_get} from "../../report-visuals/report-utils";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {classNames, htmlValidation} from "../../helpers/utils";
import {useStore} from "../../helpers/helpers";
import { useParams } from "react-router-dom";
import {toJS} from "mobx";
import {useTranslation} from "react-i18next";
import {BoothTree, SideOverlay, Toggle} from "../../components";

export const EditTask = observer(props => {

    const rootStore = useStore();
    const {taskManagerStore} = rootStore;
    const {active_task, users, surveys} = taskManagerStore;
    const params = useParams();
    const projectid = params.projectid;
    const cancelButtonRef = useRef(null);
    const [activeTask, setActiveTask] = useState({});
    const [t]=useTranslation("task_manager");
    const survey = surveys.find(x => {
        return x.id === active_task.surveyid
    })
    useEffect(() => {
        setActiveTask(toJS(active_task))
    }, [active_task]);

    const [taskStatusPayload, setTaskStatusPayload] = useState({
        "payment_done":false,
        "field_work_completed":false,
        "validation_completed":false
    });

    const fetch_task_list = async () => {
        await taskManagerStore.get_task_list(projectid);
    }



    const TaskStatus = () => {


        const handleSwitch = evt => {
            setTaskStatusPayload({...taskStatusPayload, [evt.target.name]: evt.target.checked})
        }

        return(
            <>
                {Object.keys(taskStatusPayload).map(x => {
                    return(
                        <div key={x} className="grid grid-cols-2 gap-2 my-2 sm:my-4">
                            <div className="capitalize">{x.split('_').join(" ")}</div>
                            <Toggle
                                val={taskStatusPayload[x]}
                                onToggle={() => {
                                    // setActiveToggle(role.key)
                                 }}
                            />
                        </div>

                    )
                })}
            </>
        )
    }

    const close_task_edit_modal = () => {
        taskManagerStore.set_task_edit_modal(false)
    }

    const set_selected_booth = (booth) => {
        setActiveTask({...activeTask, node: booth})
    }

    return(
        <SideOverlay
            children={
                <>
                    <div className="relative flex-1">
                        <div className="inline-block align-bottom text-left transform transition-all sm:my-2 sm:align-middle max-w-lg w-full">
                            <>
                                <div className="w-full">
                                    <label htmlFor="title" className="block text-gray-700 pt-4 rounded-lg">{t("name")}</label>
                                    <input type="text"
                                           className="px-4 py-2 rounded-lg border border-gray-300 w-full"
                                           value={_.isEmpty(activeTask["more"]) ? "" : activeTask["more"]["name"]}
                                           onChange={e => {
                                               const clone = _.clone(activeTask)
                                               clone["more"]["name"] = e.target.value
                                               setActiveTask(clone)
                                           }}
                                    />
                                </div>
                                <Menu as="div" className="relative inline-block text-left w-full">
                                    <div>
                                        <label htmlFor="role" className="block text-gray-700 pt-4">{t("select_survey")}</label>
                                        <Menu.Button
                                            className="w-full inline-flex justify-between rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                                            {safe_get(survey, "name", "")}
                                            <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true"/>
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items
                                            className="origin-top-right right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none px-2">
                                            {surveys.map(survey => {
                                                return (
                                                    <div className="py-1">
                                                        <Menu.Item>
                                                            <a
                                                                href="#"
                                                                className='text-gray-900 '
                                                                onClick={(e) => {
                                                                    setActiveTask({...active_task, "surveyid": survey["id"] })
                                                                }}
                                                            >
                                                                {survey.name}
                                                            </a>
                                                        </Menu.Item>
                                                    </div>
                                                )
                                            })}
                                        </Menu.Items>
                                    </Transition>
                                </Menu>

                                <div className="w-full">
                                    <label htmlFor="count" className="block text-gray-700 pt-4 rounded-lg">{t("surveys_per_booth")}</label>
                                    <input type="number"
                                           className="px-4 py-2 rounded-lg border border-gray-300 w-full"
                                           value={_.isEmpty(activeTask["count"]) ? 0 : activeTask["count"]["count"]}
                                           onChange={e => {
                                               const clone = _.clone(activeTask)
                                               clone["count"]["count"] = e.target.value
                                               setActiveTask(clone);
                                           }}
                                    />
                                </div>

                                <div className="flex flex-col pb-4">
                                    <label className="block text-gray-700 pt-4 rounded-lg">Select Booth</label>
                                    {/*<BoothTreeView onBoothSelection={set_selected_booth} selected_booth={safe_get(activeTask, "node", {})}/>*/}
                                    <BoothTree
                                        onBoothSelection={(data) => {
                                            setActiveTask({...activeTask, node: data})
                                        }}
                                        selected_booth={safe_get(activeTask, "node.id", "")}
                                    />
                                </div>

                                <div className="bg-white rounded-lg mb-2">
                                    <Autocomplete
                                        ref={cancelButtonRef}
                                        id="select-user"
                                        value={activeTask["user"]}
                                        options={users}
                                        autoHighlight = {true}
                                        filterOptions={(options, {inputValue}) => options.filter(item =>safe_get(item,'name','').includes(inputValue) || safe_get(item,'phonenum','').includes(inputValue))}
                                        getOptionLabel={(option) => safe_get(option, "name", "")}
                                        onChange={(event,v) => {
                                            htmlValidation(event);
                                            const clone = _.clone(activeTask);
                                            clone["user"] = v;
                                            clone["userid"] = safe_get(v, "id", -1);
                                            setActiveTask(clone);
                                        }}
                                        renderOption={(props, option) => (
                                            <Box key={option["id"]} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                <img
                                                    loading="lazy"
                                                    width="40"
                                                    src={option["profileimageuri"]}
                                                />
                                                <div>
                                                    <p>{option["name"]}</p>
                                                    <p>{option["phonenum"]}</p>
                                                </div>
                                            </Box>
                                        )
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Select User"
                                                onChange={event => {
                                                    htmlValidation(event);
                                                    console.log("event", event.target.value)
                                                }}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    />
                                </div>

                                {/*<TaskStatus />*/}

                            </>

                        </div>
                        {/* /End replace */}
                    </div>
                    <div className="py-3 flex flex-row-reverse justify-start divide-y divide-gray-200">
                        <button
                            type="button"
                            className={classNames(
                                "ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500")}
                            onClick={async(evt) => {
                                taskManagerStore.set_active_task(activeTask);
                                const res = await taskManagerStore.update_task();
                                fetch_task_list();
                                taskManagerStore.set_task_edit_modal(false)
                            }}
                        >
                            {t("save")}
                        </button>
                        <button
                            type="button"
                            className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-gray-700 hover:bg-gray-50"
                            onClick={() => {
                                taskManagerStore.set_task_edit_modal(false)
                            }}
                            ref={cancelButtonRef}
                        >
                            {t("cancel")}
                        </button>
                    </div>
                </>
            }
            onClose={close_task_edit_modal}
            show={taskManagerStore.task_edit_modal}
            title="Edit Task"
        />
    )
})