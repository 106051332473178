import React, {useLayoutEffect, useRef} from 'react'
import {get_color, safe_get} from "../../report-visuals/report-utils";
import _ from "lodash";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import {observer} from "mobx-react-lite";

export const BarChart = observer((props: any) => {

    const {data, _label, value, id = "barchart"} = props
    const barchart = useRef(null);

    useLayoutEffect(() => {
        let chart_data = data
        const colorsList = chart_data.map((x, ind) => {
            return get_color(ind);
        })

        chart_data = _.orderBy(chart_data, value, "desc");
        let chart = am4core.create(id, am4charts.XYChart);
        chart.hiddenState.properties.opacity = 0;

        //set outline color for series
        chart.plotContainer.stroke = am4core.color('#fffff');
        chart.plotContainer.strokeWidth = 0;

        //set chart data
        chart.data = chart_data;


        // Create axes
        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());

        categoryAxis.dataFields.category = "label";
        categoryAxis.renderer.grid.template.disabled = false;
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.labels.template.rotation = 290;
        categoryAxis.renderer.labels.template.dx = -50;
        categoryAxis.renderer.labels.template.dy = -10;
        categoryAxis.renderer.minGridDistance = 10;

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

        // Create series
        let series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = "value";
        series.dataFields.categoryX = "label";
        series.name = "Voters %";
        series.sequencedInterpolation = true;
        // series.columns.template.fillOpacity = .8;
        // series.columns.template.column.fillOpacity = 0.8;
        series.columns.template.width = 50;
        series.columns.template.fill = am4core.color('#fff');
        series.columns.template.height = am4core.percent(100)

        categoryAxis.renderer.labels.template.disabled = true;
        valueAxis.renderer.labels.template.disabled = true;

        series.tooltip.disabled = false;
        series.tooltip.pointerOrientation = "vertical";
        series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";

        let hoverState = series.columns.template.column.states.create("hover");
        hoverState.properties.cornerRadiusTopLeft = 0;
        hoverState.properties.cornerRadiusTopRight = 0;
        hoverState.properties.fillOpacity = 1;

        // @ts-ignore
        barchart.current = x;

    }, [data]);
    return (
        <div style={{"width": "100%", "height": "600px"}} className={"flex flex-col mx-auto"}>
            <div id={id} className='w-full h-full'/>
        </div>
    )
})