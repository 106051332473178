import React, { useState } from "react";
import { CSVLink } from "react-csv"
import moment from "moment";
import { useTranslation } from "react-i18next";

type ExcelProps = {
    data: {}[],
    cols: {}[],
    name: string,
    classes?: string
}

export const DownloadAsExcelSheet = (props: ExcelProps) => {

    const { data, cols, name, classes } = props;
    const name_with_timestamp = `${name} - ${moment().format('LLL')}`;
    const [showDownload, setShowDownload] = useState(false);
    const { t } = useTranslation("downloadasexcelsheet");

    return (
        <div className={`flex flex-row items-center justify-between ${classes}`}>
            <i className="fa-solid fa-grip-dots cursor-pointer" onClick={e => setShowDownload(!showDownload)} />
            {showDownload &&
                <CSVLink
                    data={data}
                    headers={cols}
                    filename={name_with_timestamp}
                >
                    <i className="text-blue-600 ml-4">Download</i>
                </CSVLink>
                // <ReactExport.ExcelFile
                //     filename={name_with_timestamp}
                //     element={<button onClick={e => setShowDownload(false)} className="text-blue-600 underline pt-4 ml-4">Download</button>}>
                //     <ReactExport.ExcelSheet data={data} name="Interviews By User">
                //         {cols.map((col, index) =>  <ReactExport.ExcelColumn key={index} label={col["label"]} value={col["value"]}/> )}
                //     </ReactExport.ExcelSheet>
                // </ReactExport.ExcelFile>
            }
        </div>
    )
}