import React, {useState} from "react"
import {DeleteModal, LoadingState} from "../../components";
import {toast} from "react-toastify";
import {useParams} from "react-router";
import {useStore} from "../../helpers";
import {observer} from "mobx-react-lite";
import {useMutation, useQueryClient} from "@tanstack/react-query";

export const BanUser = observer(() => {

    const {projectid} = useParams();
    const {userStore} = useStore();
    const {ban_user_modal, activeUser} = userStore;
    const queryClient = useQueryClient();

    const ban_user_from_platform = async (id) => {
        const payload = {
            projectid: parseInt(projectid),
            userid: parseInt(id),
        };
        return await userStore.ban_user(payload);
    };

    const {mutate, isLoading} = useMutation({
        mutationFn: ban_user_from_platform,
        onSuccess: async () => {
            setTimeout(async () => {
                await queryClient.invalidateQueries(["users", projectid])
            }, 1000);
            toast.success("User Banned from this platform")
            closeDeleteModal()
        },
        onError: (error) => {
            toast.error("Something went wrong");
        }
    })


    const closeDeleteModal = () => {
        userStore.update_user_store_prop("ban_user_modal", false);
    }

    if(isLoading) return <LoadingState />

    return (
        <>
            <DeleteModal
                isOpen={ban_user_modal}
                onDeleteClick={() => {
                    mutate(activeUser.id)
                }}
                onCancelClick={() => {
                    closeDeleteModal()
                }}
                desc={`Do you want permenently ban ${activeUser.name} from this platform ?`}
                id={activeUser.id}
            />
        </>
    )
})