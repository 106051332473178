import {observer} from "mobx-react-lite";
import React, {useEffect, useState} from "react";
import "react-circular-progressbar/dist/styles.css";
import {safe_get} from "../../report-visuals/report-utils";
import _ from "lodash";
import {useStore} from "../../helpers/helpers";
import { useTranslation } from "react-i18next";


export const ElectionDayCardSmall = observer((props: any) => {
    const {data, ourParty, name} = props;
    const [totalVoters, setTotalVoters] = useState(0);
    const [support, setSupport] = useState(0);
    const [polledVotes, setPolledVotes] = useState(0);
    const {electionDayDashboardStore} = useStore();
    const {t} = useTranslation("election_dashboard");

    useEffect(() => {
        fetchElectionDayCardData();
    }, []);

    const fetchElectionDayCardData = () => {
        if (!_.isEmpty(data)) {
            const totvoters = safe_get(data, 'total_voters', 0)
            setTotalVoters(totvoters)
            const parties = safe_get(data, 'support_strength', {})
            if (!_.isEmpty(parties)) {
                const ourpartycount = safe_get(parties, ourParty, 0)
                setSupport(ourpartycount)
            }
            const gotvVote = safe_get(data, 'gotv_voted', {})
            if (!_.isEmpty(gotvVote)) {
                const voted = safe_get(gotvVote, 'voted', 0)
                if (totvoters > 0 && voted > 0) {
                    setPolledVotes(voted)
                }
            }
        }
    }

    const label_style = ''
    const value_style = 'text-center'

    return (
        <>
            <div onClick={() => {
                electionDayDashboardStore.setShowDataInModal(true)
                electionDayDashboardStore.setDataInModal(data)
                electionDayDashboardStore.setNameInModal(name)
            }}>
                <div key={name} className='bg-white p-2 lg:p-4 shadow-lg rounded-lg m-1 cursor-pointer'>
                    <div className='font-bold'> {name}</div>
                    <div className='flex flex-row'>
                        <div className=''>
                            <div className={label_style}>{t("voters")}</div>
                            <div className={value_style}>{totalVoters}</div>
                        </div>

                        <div className='mx-4'>
                            <div className={label_style}>{t("polled")}</div>
                            <div className={value_style}>{polledVotes}</div>
                        </div>

                        <div className=''>
                            <div className={label_style}>{ourParty}</div>
                            <div className={value_style}>{support}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
})