import React, { useEffect, useState } from 'react'
import { observer } from "mobx-react-lite";
import { useStore } from "../../helpers";
import { SurveyResponseView } from "./survey-response-view";
import { FTRFilterPane } from "./FTRFilterPane";
import { SurveyActionView } from "./survey-actions-view";
import _ from "lodash";
import { useTranslation } from 'react-i18next';
import {useParams} from "react-router";
import {useIsMobile} from "../../custom-hooks";
import {AnalyticalSummary} from "./analytical-summary";
import {AllSurveys, RenderFilters, FieldTeamReportMaps, SurveySummary} from "./survey-summary";
import {BreadCrumbs, LoadingState, ProjectHeader} from "../../components";

export const SurveyDetails = observer((props: any) => {

    const { surveyResponseStore, projectStore, userStore } = useStore();
    const { new_filters, activeSurveyId, searchHighlights, activeSurveyResponseId, allSurveyResponses, allSurveyResponsesSummary, allTags, analytical_data } = surveyResponseStore;
    const isFieldWorker = userStore.is_field_worker();
    const hasPermission = projectStore.getPermission("view_analytical_summary", false);
    const [allSurveys, setAllSurveys] = useState([]);
    const [t] = useTranslation(["survey_management", "new_voter_registration", "analytical_summary"]);
    const {projectid} = useParams();
    const [query, setQuery] = useState('');
    const isMobile = useIsMobile();

    const crumbs = [
        {name: "DashBoard", href: `/project/${projectid}`, current: true},
        { name: 'Field Team Report', href: '#', current: true },
    ];

    useEffect(() => {
        getFullReport()
    }, [activeSurveyId, searchHighlights, query, new_filters, projectid]);

    useEffect(() => {
        getSurveyList()
    }, [projectid]);

    const getSurveyList = async () => {
        // return await projectStore.getFullProject(projectid.toString());
        const response = await projectStore.getFullProject(projectid.toString());
        if (response.status === 200) {
            const sorted_surveys = _.sortBy(response.data['surveys'], [function(x){return x.id}]).reverse();
            setAllSurveys(sorted_surveys);
            surveyResponseStore.setActiveSurveyId(parseInt(sorted_surveys[0]["id"]))
        }
    }

    const getFullReport = async () => {
        const payload = {
            "projectid": parseInt(projectid),
            "surveyid": activeSurveyId,
            "search": query,
            "only_highlights": searchHighlights,
        }
        return await surveyResponseStore.getFieldReport(payload)
    }

    // const full_report_query = useQuery({
    //     queryFn: getFullReport,
    //     queryKey: ['field-team-report', projectid, activeSurveyId, query, searchHighlights, new_filters]
    // })
    //
    // const get_surveys_list = useQuery({
    //     queryFn: getSurveyList,
    //     queryKey: ['surveys', projectid],
    //     onSuccess: async(data) => {
    //         console.log(data)
    //         setAllSurveys(data["data"]['surveys']);
    //         await surveyResponseStore.setActiveSurveyId(parseInt(data["data"]['surveys'][0]["id"]))
    //     }
    // })

    const set_query = query => {
        setQuery(query)
    }

    const fpProps = {
        surveys: allSurveys,
        onSearch: set_query,
        highlights: searchHighlights,
        tags: allTags
    }

    const has_permission = () => {
        if(isFieldWorker) return false;
        else if(userStore.isRoot() || userStore.isClient()){
            return true
        }
        else{
            return hasPermission
        }
    }

    // if(full_report_query.isLoading || get_surveys_list.isLoading) return <LoadingState />

    return (
        <>
            {!isMobile ? <>
                <BreadCrumbs crumbs={crumbs} />
                <ProjectHeader />
            </> : null}

            <div className='px-4'>
                {surveyResponseStore.loading ? <LoadingState /> : null}
                    {allSurveys ? <FTRFilterPane {...fpProps} /> : null}
                {
                    allSurveyResponses ?
                        <>
                            {/*<div className="block text-lg font-medium  font-bold uppercase">{t("survey_summary")}</div>*/}
                            {/*<SurveySummaryView/>*/}
                            <RenderFilters />
                            {!_.isEmpty(allSurveyResponsesSummary) && <SurveySummary />}
                            {analytical_data && has_permission() && <AnalyticalSummary />}
                            {!isFieldWorker && <AllSurveys />}
                            {activeSurveyResponseId ? <SurveyResponseView /> : null}
                        </>
                        : <div className="font-bold py-4 text-2xl">{t("surveys_not_found", { ns: "analytical_summary" })}....</div>
                }
                <FieldTeamReportMaps />
                <SurveyActionView />
            </div>
        </>
    )
})
