import React, {Fragment, useEffect, useState, useRef} from "react";
import {observer} from "mobx-react-lite";
import {MessageView} from "./messageView";
import {toast} from "react-toastify";
import VoterReachOutListing from "./voter-reachout-listing";
import VoterReachOutMessageOverview from "./voter-reachout-message-overview";
import {useStore} from "../../../helpers/helpers";
import VoterReachOutHeader from "../voter-reachout-header";
import SearchBar from "../../../components/SearchBar";
import {DeleteModal, SideOverlay} from "../../../components";


export const VoterReachOut = observer((props: any) => {
        const [query, setQuery] = useState('');
        const [popOver, setPopOver] = useState(false);
        const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
        const [deleteItem, setDeleteItem] = useState()
        const rootStore = useStore();
        const {voterReachOutStore} = rootStore
        const {formType, isSideOverlayOpen, isMessageOverviewOpen, selectedOverViewItem} = voterReachOutStore
        const Sidelaymsgname = selectedOverViewItem?.ds?.name

        const getReachOutMessages = () => {
            const getMessageListPayload =
                {
                    "projectid": props['projectid'],
                    "query": query,
                    "channel": "",
                    "offset": 0,
                    "count": 100
                }
            voterReachOutStore.get_reachout_message(getMessageListPayload)
        }


        useEffect(() => {
            getReachOutMessages()
        }, [])

        const addNewReachOut = (messageType: any) => {
            voterReachOutStore.set_messageType(messageType)
            voterReachOutStore.reset_textMessageFormVal()
            voterReachOutStore.reset_voiceMessageFormVal()
            voterReachOutStore.reset_whatsappMessageFormVal()
            voterReachOutStore.set_isSideOverlay(true)
            voterReachOutStore.set_formType('new')
        }

        const handleOverlayClose = () => {
            voterReachOutStore.set_isSideOverlay(false)
            voterReachOutStore.set_formType('new')
        }

        const deleteHandler = async () => {
            const result = await voterReachOutStore.delete_reachout_message({id: deleteItem})
            if (result.status === 200) {
                toast.success("Deleted Successfully")
                getReachOutMessages()
            }
            setIsDeleteModalOpen(false)
        }

        const handleKeyDown = (event) => {
            if (event.key === "Enter") {
                event.preventDefault();
                getReachOutMessages()
            }
        }

        const handleSearchBarChange = (value) => {
            setQuery(value)
        }

        const handleMessageOverviewClose = () => {
            voterReachOutStore.set_IsMessageOverviewOpen(false)
        }

        const handlePopOverState = (bool) => {
            setPopOver(bool)
        }

        const onDeleteHandler = (deleteItem) => {
            setIsDeleteModalOpen(true)
            setDeleteItem(deleteItem)
        }

        function useOutsideAlerter(ref) {
            useEffect(() => {

                function handleClickOutside(event) {
                    if (ref.current && !ref.current.contains(event.target)) {
                        setPopOver(false)
                    }
                }

                document.addEventListener("click", handleClickOutside);
                return () => {
                    document.removeEventListener("click", handleClickOutside);
                };
            }, [ref]);
        }

        const addNewButtonRef = useRef(null)

        useOutsideAlerter(addNewButtonRef)


        return <>
            <div>
                <div className="p-4">
                    <VoterReachOutHeader
                        popOver={popOver}
                        setPopOver={handlePopOverState}
                        addNewReachOut={addNewReachOut}
                        addNewButtonRef={addNewButtonRef}
                    />
                    <SearchBar
                        query={query}
                        handleChange={handleSearchBarChange}
                        handleKeyDown={handleKeyDown}
                        getFilteredData={getReachOutMessages}
                        placeholder={"Search Messages..."}
                    />
                    <SideOverlay
                        children={<MessageView projectId={props['projectid']} getReachOutMessages={getReachOutMessages}/>}
                        title={formType === "new" ? 'Add New Message' : 'Edit Message'}
                        show={isSideOverlayOpen}
                        onClose={handleOverlayClose}
                    />
                    <SideOverlay
                        children={<VoterReachOutMessageOverview data={selectedOverViewItem}/>}
                        show={isMessageOverviewOpen}
                        onClose={handleMessageOverviewClose}
                        title={Sidelaymsgname}
                    />
                    <VoterReachOutListing
                        setPopOver={handlePopOverState}
                        deleteMessage={onDeleteHandler}
                    />
                    {isDeleteModalOpen &&
                        <DeleteModal
                            isOpen={isDeleteModalOpen}
                            desc={`Are you sure you want to delete ?`}
                            onDeleteClick={deleteHandler}
                            onCancelClick={() => setIsDeleteModalOpen(false)}
                        />
                    }
                </div>
            </div>
        </>
    }
)