import React, {useState} from "react";
import {useStore} from "../../helpers/helpers";
import {safe_get} from "../../report-visuals/report-utils";
import {FormButtons} from "../../components";
import {StatusFilter} from "../../components/cm-filters";
import {observer} from "mobx-react-lite";
import {C3BoothFilter, DateFilter} from "../constituency-command-center";
import moment from "moment/moment";
import {Button, Stack, Typography} from "@mui/material"
import {defaultIlFilters} from "../influential-leaders/il-types";
import {defaultIProjectlFilters} from "./project-work-models";

export const ProjectWorksFilters = observer((props) => {
    const rootStore = useStore();
    const {projectStore, projectWorksStore} = rootStore;
    const {filters} = projectWorksStore;
    const filterTypes = ["Village", "Status", "Date"];
    const [activeFilter, setActiveFilter] = useState(filterTypes[0]);
    const project_property = projectStore.getProjectProperty("project_works", {});
    const status = safe_get(project_property, "status_config", []);
    const village_key = safe_get(project_property, "more.village_selector", "piv_level2");

    const [projectWorksSelectedFilters, setProjectWorksSelectedFilters] = useState(filters);

    const onBoothSelection = (villages) => {
        setProjectWorksSelectedFilters({
            ...projectWorksSelectedFilters,
            villages: villages,
        });
    };

    const onDateSelection = (start_date, end_date = moment().format("YYYY-MM-DD")) => {
        console.log(start_date, end_date)
        setProjectWorksSelectedFilters({
            ...projectWorksSelectedFilters,
            start_date,
            end_date
        });
    };

    const handleFilterChange = (event, key) => {
        const {value, checked} = event.target;
        if (checked) {
            setProjectWorksSelectedFilters({
                ...projectWorksSelectedFilters,
                [key]: [...projectWorksSelectedFilters[key], value],
            });
        } else {
            setProjectWorksSelectedFilters({
                ...projectWorksSelectedFilters,
                [key]: projectWorksSelectedFilters[key].filter((e) => e !== value),
            });
        }
    };

    return (
        <div className={"flex flex-col gap-y-2"}>
            <Typography color={"success"} variant={"body2"}>Select Village/Booth, Status, and Date filters to view the desired data.
                Apply now to refine your search!</Typography>
            <div className="w-full h-full flex">
                <div className="flex flex-col gap-y-2 p-2 w-1/4 h-full border-black border-r-2 h-[80vh]">
                    {filterTypes.map((tab) => (
                        <p
                            key={tab}
                            onClick={() => {
                                setActiveFilter(tab);
                            }}
                        >
                        <span
                            className={
                                activeFilter === tab
                                    ? "text-purple-600 text-lg font-medium"
                                    : "text-lg font-medium"
                            }
                        >
                            {tab}
                        </span>
                        </p>
                    ))}
                </div>
                <div className="flex flex-1 flex-col justify-between p-2 w-4/4 h-full">
                    <div className="flex flex-col">
                        {activeFilter === "Village" && (
                            <C3BoothFilter
                                onBoothSelection={onBoothSelection}
                                value={projectWorksSelectedFilters.villages}
                            />
                        )}
                        {activeFilter === "Status" && (
                            <StatusFilter
                                data={status}
                                value={projectWorksSelectedFilters.status}
                                setValues={(event) => handleFilterChange(event, "status")}
                            />
                        )}
                        {activeFilter === "Date" && (
                            <div>
                                <DateFilter
                                    onDateChange={onDateSelection}
                                    startDate={projectWorksSelectedFilters.start_date}
                                    endDate={projectWorksSelectedFilters.end_date}
                                />
                                <Button
                                    variant={"contained"}
                                    startIcon={<i className={"fas fa-exchange-alt"} />}
                                    size={"small"}
                                    color={"warning"}
                                    className="btn btn-primary btn-sm"
                                    onClick={() => onDateSelection("", "")}
                                >Reset Date
                                </Button>
                            </div>
                        )}
                    </div>
                    <Stack direction={"row"} alignItems={"center"}>
                        <Button
                            color={"warning"}
                            variant={"contained"}
                            onClick={() => setProjectWorksSelectedFilters(defaultIProjectlFilters)}
                        >
                            Reset
                        </Button>
                    <FormButtons

                        onSave={() => {
                            projectWorksStore.setProjectFilterStatus(false);
                            projectWorksStore.updateFilters(projectWorksSelectedFilters);
                        }}
                        onCancel={() => {
                            projectWorksStore.setProjectFilterStatus(false);
                        }}
                    />
                    </Stack>
                </div>
            </div>
        </div>
    );
});
