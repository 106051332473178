import {observer} from "mobx-react-lite";
import {useStore} from "../../helpers/helpers";
import {useTranslation} from "react-i18next";
import React from "react";
import {SideOverlay} from "../../components";
import {SearchVoterList, VoterListWithRadioButton} from "../voter-list";

export const AddUser = observer((props:any) => {

    const rootStore = useStore();
    const { grievanceStore, voterListStore } = rootStore;
    const { add_user_from_voterlist } = grievanceStore;
    const {t} = useTranslation(["grievance_management", "beneficiary"]);

      const close_modal = () => {
        grievanceStore.update_grievance_store_property("add_user_from_voterlist", false);
    }

    const close_voterList = () => {
        voterListStore.update_active_voter({});
        close_modal()
    }


    return(
        <>
            {add_user_from_voterlist && <SideOverlay
                children={<>
                    <SearchVoterList />
                    <VoterListWithRadioButton onVoterSelection={close_modal} />
                </>}
                onClose={close_voterList}
                show={add_user_from_voterlist}
                title={t("search_voters", {ns: "beneficiary"})}
            />}
        </>
    )
})