import React from "react";
import {Checkbox, FormControl, FormControlLabel} from "@mui/material";
import {useTranslation} from "react-i18next";

export const DepartmentFilter = (props:any) => {

    const {t} = useTranslation("grievance_management");
    const {departmentOptions, value, setValues} = props;

    return (
        <div className={"flex flex-col"}>
            <p className={"text-lg text-blue-500"}>{t("Select Department")}</p>
            <div>
                {departmentOptions.map((dept, index) => (
                    <div key={index} className={"task-filter flex flex-row items-center"}>
                        <FormControl>
                            <FormControlLabel
                                label={dept}
                                control={
                                    <Checkbox
                                        checked={value.includes(dept)}
                                        value={dept}
                                        onChange={(event) => setValues(event)}
                                    />
                                }
                            />
                        </FormControl>
                    </div>
                ))}
            </div>
        </div>
    );
}