import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useStore } from "../helpers/helpers";
import _ from "lodash";
import { classNames, makeid_alpha, makeid_numeric } from "../helpers/utils";
import { toast } from "react-toastify";
import { safe_get } from "../report-visuals/report-utils";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  ProfileQuestion,
  profileQuestionOptions,
} from "./profile-question-options";
import { BreadCrumbs, LoadingState } from "../components";
import { Button } from "@mui/material";

const arrayMove = (array, from, to) => {
  if (to === from) return array;

  var target = array[from];
  var increment = to < from ? -1 : 1;

  for (var k = from; k != to; k += increment) {
    array[k] = array[k + increment];
  }
  array[to] = target;
  return array;
};

const renameObjKey = (oldObj, oldKey, newKey) => {
  const keys = Object.keys(oldObj);
  return keys.reduce((acc, val) => {
    if (val === oldKey) {
      acc[newKey] = oldObj[oldKey];
    } else {
      acc[val] = oldObj[val];
    }
    return acc;
  }, {});
};

const getLanguagesUniverse = () => {
  return [
    { lang: "English", code2: "en", code3: "en" },
    { lang: "Hindi", code2: "hi", code3: "him" },
    { lang: "Kannada", code2: "kn", code3: "kan" },
    { lang: "Kannada (Incorrect - Don't Choose)", code2: "ka", code3: "kan" },
    { lang: "Malayalam", code2: "ml", code3: "mal" },
    { lang: "Tamil", code2: "ta", code3: "tam" },
    { lang: "Bengali", code2: "bn", code3: "ben" },
    { lang: "Telugu", code2: "te", code3: "tel" },
    { lang: "Marathi", code2: "mr", code3: "mar" },
    { lang: "Gujarathi", code2: "gu", code3: "guj" },
    { lang: "Oriya", code2: "or", code3: "ori" },
  ];
};

const getLanguageDisplayStringByCode2 = (code2) => {
  try {
    const first = _.filter(getLanguagesUniverse(), { code2: code2 })[0];
    return first["lang"];
  } catch (e) {
    return "";
  }
};

const getLanguageDisplayStringByCode3 = (code3) => {
  try {
    const first = _.filter(getLanguagesUniverse(), { code3: code3 })[0];
    return first["lang"];
  } catch (e) {
    return "";
  }
};

export const QuestionPage = observer((props) => {
  const rootStore = useStore();
  const { userStore, projectStore } = rootStore;
  const [loading, setLoading] = useState(false);
  const [fullProject, setFullProject] = useState({});
  const [survey, setSurvey] = useState({});
  const [questions, setQuestions] = useState([]);
  const [activeQuestion, setActiveQuestion] = useState({});
  const [showMeta, setShowMeta] = useState(true);
  const [showPreview, setShowPreview] = useState(false);
  const [showActiveQuestion, setShowActiveQuestion] = useState(false);
  const [showQuestions, setShowQuestions] = useState(true);
  const [toggleJSONEditor, setToggleJSONEditor] = useState(false);
  const { projectid, surveyid } = useParams();
  const { surveyResponseStore } = useStore();
  // @ts-ignore
  const { required, skip_otp } = activeQuestion;
  const [isRequired, setIsRequired] = useState(
    safe_get(activeQuestion, "required", false)
  );
  const [skipCount, setSkipCount] = useState(
    safe_get(activeQuestion, "skip_count", false)
  );

  const style_section_header = "font-bold text-sm text-gray-800 uppercase ";
  const style_label_text = "font-bold text-sm text-gray-600";
  const style_label_explain = "my-1 text-xs italic text-gray-600";
  const style_input =
    "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline";

  const crumbs = [
    { name: "DashBoard", href: `/project/${projectid}`, current: false },
    {
      name: "Manage Project",
      href: "/project/" + projectid + "/manage",
      current: false,
    },
    { name: "Edit Survey", href: "#", current: true },
  ];

  const fetchProjectData = async () => {
    setLoading(true);
    const response = await projectStore.getFullProject(projectid);
    if (response.ok) {
      setFullProject(response.data);
      const firstMatch = _.filter(response.data["surveys"], {
        id: parseInt(surveyid),
      })[0];
      setSurvey(firstMatch);
      setQuestions(firstMatch["questions"]);
      if (firstMatch["questions"].length > 0) {
        setActiveQuestion(firstMatch["questions"][0]);
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchProjectData();
  }, [projectid]);

  useEffect(() => {
    if (!Object.keys(activeQuestion).includes("id")) return;

    const cloneQuestions = _.clone(questions);
    // @ts-ignore
    const { id } = activeQuestion;
    // @ts-ignore
    var index = _.findIndex(cloneQuestions, { id: id });
    // @ts-ignore
    cloneQuestions.splice(index, 1, activeQuestion);
    setQuestions(cloneQuestions);
    setIsRequired(safe_get(activeQuestion, "required", false));
    setSkipCount(safe_get(activeQuestion, "skip_count", false));
  }, [activeQuestion]);

  const getSelectedLanguages = () => {
    let languages = [];
    if (Object.keys(survey).includes("meta")) {
      // @ts-ignore
      languages = survey["meta"]["languages"];
    }
    return languages;
  };

  const moveDownQuestion = (targetQuestion) => {
    const { id } = targetQuestion;
    let cloneQuestions = _.clone(questions);
    // @ts-ignore
    const index = _.findIndex(cloneQuestions, { id: id });
    if (index == -1) return;
    if (index == questions.length - 1) return;
    cloneQuestions = arrayMove(cloneQuestions, index, index + 1);
    setQuestions(cloneQuestions);
  };

  const moveUpQuestion = (targetQuestion) => {
    const { id } = targetQuestion;
    let cloneQuestions = _.clone(questions);
    // @ts-ignore
    const index = _.findIndex(cloneQuestions, { id: id });
    if (index == -1) return;
    if (index == 0) return;
    cloneQuestions = arrayMove(cloneQuestions, index, index - 1);
    setQuestions(cloneQuestions);
  };

  const removeLanguageSupport = (changedLanguage) => {
    let updatedLanguages = _.clone(survey["meta"]["languages"]);
    updatedLanguages = _.pull(updatedLanguages, changedLanguage);
    setSurvey({
      ...survey,
      meta: {
        ...survey["meta"],
        languages: updatedLanguages,
      },
    });
    const cloneQuestions = _.clone(questions);
    for (let question of cloneQuestions) {
      delete question["text"][changedLanguage];
      delete question["options"][changedLanguage];
      if (Object.keys(question).includes("other_text")) {
        delete question["other_text"][changedLanguage];
      }
    }
    setQuestions(cloneQuestions);
  };

  const addLanguageSupport = (changedLanguage) => {
    let updatedLanguages = _.clone(survey["meta"]["languages"]);
    updatedLanguages.push(changedLanguage);
    setSurvey({
      ...survey,
      meta: {
        ...survey["meta"],
        languages: updatedLanguages,
      },
    });
    const cloneQuestions = _.clone(questions);
    for (let question of cloneQuestions) {
      // @ts-ignore
      question["text"][changedLanguage] = "";
      // @ts-ignore
      question["options"][changedLanguage] = [];
      if (Object.keys(question).includes("other_text")) {
        // @ts-ignore
        question["other_text"][changedLanguage] = "";
      }
    }
    setQuestions(cloneQuestions);
  };

  const saveSurvey = async () => {
    saveEditedJSON();
  };
  const handleSubmit = async (questions, isValidJson) => {
    setLoading(true);

    if (!isValidJson) {
      setLoading(false);
      return;
    }
    const payload = {
      id: survey["id"],
      name: survey["name"],
      projectid: survey["projectid"],
      archive: survey["archive"],
      version: 1,
      meta: survey["meta"],
      questions: questions,
    };
    await projectStore.editOrNewSurvey(payload);
    console.log("payload", payload);
    fetchProjectData();
    setLoading(false);
  };

  const assembleFinalPayload = () => {
    const payload = {
      id: survey["id"],
      name: survey["name"],
      projectid: survey["projectid"],
      archive: survey["archive"],
      version: 1,
      meta: survey["meta"],
      questions: questions,
    };
    const payload_str = JSON.stringify(payload);
    return payload;
  };

  const addNewQuestion = () => {
    const newid =
      makeid_numeric(4) + "-" + makeid_alpha(6) + "-" + makeid_numeric(4);

    const newQ = {
      name: "New Question " + makeid_numeric(1) + makeid_alpha(3),
      id: newid,
      type: "single-select-other",
      text: {},
      options: {},
      other_text: {},
      table: [tableModel],
    };
    const languages = getSelectedLanguages();
    for (let l of languages) {
      // @ts-ignore
      newQ["text"][l] = "Question Text";
      // @ts-ignore
      newQ["options"][l] = ["Option 1", "Option 2", "Option 3"];
      // @ts-ignore
      newQ["other_text"][l] = "Others";
    }
    const copiedQuestions = _.clone(questions);
    // @ts-ignore
    copiedQuestions.push(newQ);
    setQuestions(copiedQuestions);
  };

  const deleteQuestion = (question) => {
    const { id } = question;
    const cloneQuestions = _.clone(questions);
    // @ts-ignore
    _.remove(cloneQuestions, { id: id });
    setActiveQuestion({});
    setShowMeta(true);
    setShowActiveQuestion(false);
    // @ts-ignore
    setQuestions(cloneQuestions);
  };
  const [t] = useTranslation("survey_editor");
  const SurveyInfo = (innerprops) => {
    return (
      <div className="flex flex-row flex-nowrap items-center">
        <div className="flex flex-col">
          <div className="font-bold text-2xl text-base text-black-500">
            {survey["name"]}
          </div>
          {/*<div className='text-base'>({survey['slug']})</div>*/}
          <div className="">
            {t("project")}:
            <span className="mx-3 font-bold text-gray-500">
              {fullProject["name"]}
            </span>
          </div>
          <div className="">
            {t("question")}:
            <span className="mx-3 font-bold text-gray-500">
              {questions.length}
            </span>
          </div>
        </div>
        <div className="ml-auto">
          <Button
            variant={"contained"}
            onClick={saveSurvey}
            size={"small"}
            startIcon={<i className="fas fa-save" />}
          >
            {t("save")}
          </Button>
        </div>
      </div>
    );
  };

  const LeftSide = (innerprops) => {
    const defaultStyle = "cursor-pointer text-gray-700 my-2";
    const highlightStyle = "cursor-pointer font-bold text-blue-700 my-2";

    return (
      <div className="flex flex-col h-full">
        <div
          className={showMeta ? highlightStyle : defaultStyle}
          onClick={() => {
            setShowActiveQuestion(false);
            setShowMeta(true);
            setShowPreview(false);
          }}
        >
          <i className="mx-2 fa-solid fa-cog" />
          {t("survey_settings")}
        </div>

        <div
          className={showPreview ? highlightStyle : defaultStyle}
          onClick={() => {
            setShowActiveQuestion(false);
            setShowMeta(false);
            setShowPreview(true);
          }}
        >
          <i className="mx-2 fa-solid fa-eye" />
          {t("survey_preview")}
        </div>

        <div className="text-gray-700 font-bold uppercase flex flex flex-row items-center my-2">
          <i className="mx-2 fa-solid fa-clipboard-list" />
          {t("questions")}
          <div
            className="cursor-pointer mx-3 text-xl"
            onClick={() => {
              addNewQuestion();
            }}
          >
            <div title="Add New Question">
              <i className="fa-solid fa-plus-circle" />
            </div>
          </div>
          <div
            className="cursor-pointer mx-3 text-xl  ml-auto"
            title="Hide Questions"
            onClick={(evt) => {
              setShowQuestions(!showQuestions);
            }}
          >
            <i
              className={
                showQuestions
                  ? "fa-solid fa-chevron-up"
                  : "fa-solid fa-chevron-down"
              }
            />
          </div>
        </div>
        {showQuestions
          ? questions.map((x) => {
              const defaultQuestionStyle =
                "cursor-pointer text-gray-700 my-2 ml-5";
              const highlightQuestionStyle =
                "cursor-pointer font-bold text-blue-700 my-2 ml-5";
              let isActive = false;
              const { id, name } = x;
              if (showActiveQuestion) {
                isActive = activeQuestion["id"] === id;
              }
              let questionIconStyle = "fa-solid fa-question";
              const questionType = x["type"];
              if (
                questionType === "single-select" ||
                questionType === "single-select-other"
              )
                questionIconStyle = "far fa-dot-circle";
              else if (
                questionType === "multi-select" ||
                questionType === "multi-select-other"
              )
                questionIconStyle = "fa-solid fa-check-square";
              else if (questionType === "hierarchy-selector-2-level")
                questionIconStyle = "fa-solid fa-sitemap";
              else if (questionType === "simple-text")
                questionIconStyle = "fa-solid fa-keyboard";
              else if (questionType === "name-phone")
                questionIconStyle = "fa-solid fa-phone-alt";
              else if (questionType === "autocomplete")
                questionIconStyle = "far fa-keyboard";
              else if (questionType === "phone-whatsapp")
                questionIconStyle = "fa-solid fa-phone-alt";
              else if (questionType === "voter-shapes")
                questionIconStyle = "fa-solid fa-cubes";
              else if (questionType === "phone-whatsapp-otp-verification")
                questionIconStyle = "fa-solid fa-phone-alt";
              else if (questionType === "influential-person")
                questionIconStyle = "fa-solid fa-users-crown";
              else if (questionType === "people")
                questionIconStyle = "fa-solid fa-users";
              else if (questionType === "profile")
                questionIconStyle = "fa-solid fa-address-card";

              return (
                <div
                  className={
                    isActive ? highlightQuestionStyle : defaultQuestionStyle
                  }
                  onClick={() => {
                    setActiveQuestion(x);
                    setShowActiveQuestion(true);
                    setShowMeta(false);
                    setShowPreview(false);
                  }}
                >
                  <div className="flex flex-row flex-nowrap overflow-hidden overflow-ellipsis truncate items-center">
                    <i className={questionIconStyle + " mx-2"} />
                    <div className="">{name}</div>
                    <div className="ml-auto flex flex-row text-gray-500">
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          moveUpQuestion(x);
                        }}
                        className="cursor-pointer text-sm self-start"
                      >
                        <i className="fa-solid fa-arrow-up" />
                      </div>
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          moveDownQuestion(x);
                        }}
                        className="cursor-pointer text-sm self-end ml-1"
                      >
                        <i className="fa-solid fa-arrow-down" />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          : null}
      </div>
    );
  };
  const [isAudioEnabled, setIsAudioEnabled] = useState(true);
  const [isLocationCapture, setIsLocationCapture] = useState(false);
  const [hide_hierarchy_in_normal_flow, setHideHierarchyInNormalFlow] =
    useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const SurveySettings = () => {
    // @ts-ignore
    const isAudioEnabled = survey?.meta?.audio_capture;

    // @ts-ignore
    const isLocationCapture = survey?.meta?.location_capture;

    const hide_hierarchy_in_normal_flow = safe_get(
      survey,
      "meta.hide_hierarchy_in_normal_flow",
      false
    );

    const languages = getSelectedLanguages();

    const handleAudioToggle = () => {
      setIsAudioEnabled((prevIsAudioEnabled) => !prevIsAudioEnabled);
    };
    const handleLocationCaptureToggle = () => {
      setIsLocationCapture(!isLocationCapture);
    };
    const handleHideHierarchyToggle = () => {
      setHideHierarchyInNormalFlow(!hide_hierarchy_in_normal_flow);
    };

    return (
      <div className="mb-4" key="survey-settings-container ">
        <div className="font-bold text-xl text-base text-gray-700">
          {t("survey_settings")}
        </div>

        <div className="my-4">
          <div className={style_section_header}>{t("survey_name")}</div>
          <input
            className={style_input}
            key="survey-name"
            type="text"
            value={survey["name"]}
            onChange={(event) => {
              //event.preventDefault();
              //event.stopPropagation();
              const changed = event.target.value;
              setSurvey({
                ...survey,
                name: changed,
                slug: changed.toLowerCase().trim().replace(/ /g, "-"),
              });
            }}
          />
        </div>

        {SurveyLanguages()}

        <div className="my-4">
          <div
            className="flex flex-row items-center cursor-pointer"
            onClick={(evt) => {
              setSurvey({
                ...survey,
                meta: {
                  ...survey["meta"],
                  audio_capture: !isAudioEnabled,
                },
              });
            }}
          >
            <div className={style_section_header}>
              {t("audio_capture")}
              <input
                type="checkbox"
                className="ml-5 form-checkbox h-5 w-5 text-green-600"
                checked={isAudioEnabled}
                onChange={() => handleAudioToggle()}
              />
            </div>
          </div>
        </div>

        <div className="my-4">
          <div
            className="flex flex-row items-center cursor-pointer"
            onClick={(evt) => {
              setSurvey({
                ...survey,
                meta: {
                  ...survey["meta"],
                  location_capture: !isLocationCapture,
                },
              });
            }}
          >
            <div className={style_section_header}>{t("track_location")}</div>
            <input
              type="checkbox"
              className="ml-5 form-checkbox h-5 w-5 text-green-600"
              checked={isLocationCapture}
              onChange={handleLocationCaptureToggle}
            />
          </div>
        </div>

        <div className="my-4">
          <div
            className="flex flex-row items-center cursor-pointer"
            onClick={(evt) => {
              setSurvey({
                ...survey,
                meta: {
                  ...survey["meta"],
                  hide_hierarchy_in_normal_flow: !hide_hierarchy_in_normal_flow,
                },
              });
            }}
          >
            <div className={style_section_header}>{t("hide")}</div>
            <input
              type="checkbox"
              className="ml-5 form-checkbox h-5 w-5 text-green-600"
              checked={hide_hierarchy_in_normal_flow}
              onChange={handleHideHierarchyToggle}
            />
          </div>
        </div>

        <div className="my-4">
          <div className={style_section_header}>
            {t("introduction_message")}
          </div>

          {languages.map((x) => {
            const displayLang = getLanguageDisplayStringByCode2(x);

            return (
              <div className="my-2 ml-4">
                <div className="flex flex-row">
                  <div className={style_label_text}>{displayLang}</div>
                  <div
                    className="mx-2 cursor-pointer"
                    title="Translate to different languages"
                    onClick={async () => {
                      setLoading(true);
                      const src = survey["meta"]["intro_text"][x].split("\n");
                      let payload = await projectStore.getTranslation(
                        src,
                        languages
                      );
                      for (let x of Object.keys(payload)) {
                        payload[x] = payload[x].join("\n");
                      }
                      setSurvey({
                        ...survey,
                        meta: {
                          ...survey["meta"],
                          intro_text: payload,
                        },
                      });
                      setLoading(false);
                    }}
                  >
                    <i
                      className="fa-solid fa-language text-xl"
                      style={{ color: "#4F8BED" }}
                    />
                  </div>
                </div>

                <textarea
                  className="form-textarea p-2 mt-1 rounded-lg border-gray-400	 border-2 block w-full"
                  rows={7}
                  value={survey["meta"]["intro_text"][x]}
                  onChange={(event) => {
                    const changed = event.target.value;
                    const updated = _.clone(survey);
                    updated["meta"]["intro_text"][x] = changed;
                    setSurvey(updated);
                  }}
                ></textarea>
              </div>
            );
          })}
        </div>

        <hr className="my-2" />
      </div>
    );
  };
  const [type, setType] = useState("");
  const questionType = "YourQuestionType";
  const SurveyLanguages = () => {
    const allLanguagesMap = getLanguagesUniverse();
    const handleToggle = () => {
      setIsSelected(!isSelected);
    };

    return (
      <div className="my-4">
        <div className={style_section_header}>{t("languages")}</div>
        {allLanguagesMap.map((x) => {
          const { lang, code2, code3 } = x;
          const languages = getSelectedLanguages();

          // @ts-ignore
          const isSelected = languages.includes(code2);

          return (
            <div
              className="flex flex-row items-center cursor-pointer"
              onClick={(evt) => {
                isSelected
                  ? removeLanguageSupport(code2)
                  : addLanguageSupport(code2);
              }}
            >
              <input
                type="checkbox"
                className="ml-5 form-checkbox h-5 w-5 text-green-600"
                checked={isSelected}
                onChange={handleToggle}
              />
              <span className="ml-2 text-gray-700">{lang}</span>
            </div>
          );
        })}
      </div>
    );
  };

  const handleChange = () => {
    setType(questionType); // Set the type to the selected question type
  };
  const QuestionTypeOption = (questionType, typeName) => {
    // @ts-ignore
    const { name, type, text, id, slug } = activeQuestion;

    return (
      <div
        className="flex flex-row items-center cursor-pointer "
        onClick={(evt) => {
          setActiveQuestion({ ...activeQuestion, type: questionType });
        }}
      >
        <input
          type="radio"
          className="ml-5 form-checkbox h-5 w-5"
          checked={type === questionType}
          onChange={handleChange}
        />
        <span className="ml-2 text-gray-700">{typeName}</span>
      </div>
    );
  };

  const QuestionType = () => {
    // @ts-ignore
    const { name, type, text, id, slug } = activeQuestion;

    return (
      <div className="my-4 flex flex-col items-start">
        <div className={style_section_header}>{t("question_type")}</div>

        {QuestionTypeOption("profile", "Profile Questions")}
        {QuestionTypeOption("single-select", "Single Select (without others)")}
        {QuestionTypeOption(
          "single-select-other",
          "Single Select (with others)"
        )}
        {QuestionTypeOption("multi-select", "Multi Select (without others)")}
        {QuestionTypeOption("multi-select-other", "Multi Select (with others)")}
        {QuestionTypeOption("simple-text", "Text box")}
        {QuestionTypeOption("name-phone", "Name & Phone Number")}
        {QuestionTypeOption(
          "hierarchy-selector-2-level",
          "Hierarchy (2 Level)"
        )}
        {QuestionTypeOption("form6-partA", "Form 6 - Part-A")}
        {QuestionTypeOption("form6-partB", "Form 6 - Part-B")}
        {QuestionTypeOption("form6-partC", "Form 6 - Part-C")}
        {QuestionTypeOption("photo", "Photo")}
        {QuestionTypeOption("phone-whatsapp", "Phone Number & Whatsapp")}
        {QuestionTypeOption("caste", "Sub Caste")}
        {QuestionTypeOption(
          "auto-complete-with-others",
          "Auto Complete with Others"
        )}
        {QuestionTypeOption(
          "phone-whatsapp-otp-verification",
          "Phone Number & Whatsapp With OTP Verification"
        )}
        {QuestionTypeOption("voter-shapes", "Voter Shapes")}
        {QuestionTypeOption("influential-person", "Influential People")}
        {QuestionTypeOption("people", "People (Can add multiple people)")}
      </div>
    );
  };

  useEffect(() => {
    setActiveQuestion({ ...activeQuestion, required: isRequired });
  }, [isRequired]);

  useEffect(() => {
    setActiveQuestion({ ...activeQuestion, skip_count: skipCount });
  }, [skipCount]);

  const RequiredQuestion = () => {
    // @ts-ignore
    const { required } = activeQuestion;

    return (
      <div className="my-4 flex flex-row">
        <div className={style_section_header}>{t("required")}</div>
        <input
          type="checkbox"
          className="ml-5 form-checkbox h-5 w-5 text-green-600"
          checked={isRequired}
          onChange={(e) => setIsRequired(!isRequired)}
        />
      </div>
    );
  };

  const QuestionCount = () => {
    // @ts-ignore
    const { skip_count } = activeQuestion;

    return (
      <div className="my-4 flex flex-row">
        <div className={style_section_header}>{t("skip_count")}</div>
        <input
          type="checkbox"
          className="ml-5 form-checkbox h-5 w-5 text-green-600"
          checked={skipCount}
          onChange={(e) => setSkipCount(!skipCount)}
        />
      </div>
    );
  };

  const [skipOTP, setSkipOTP] = useState(skip_otp);

  useEffect(() => {
    setActiveQuestion({ ...activeQuestion, skip_otp: skipOTP });
  }, [skipOTP]);

  const otp_mandatory = () => {
    return (
      <form>
        <label id="otp">{t("verification")}</label>
        <input
          type="checkbox"
          className="ml-5 form-checkbox h-5 w-5 text-green-600"
          onChange={(e) => {
            setSkipOTP(!skipOTP);
          }}
          checked={skipOTP}
        />
      </form>
    );
  };

  const QuestionText = () => {
    if (!Object.keys(activeQuestion).includes("name")) return null;

    // @ts-ignore
    const { name, type, text, id, slug } = activeQuestion;
    const languages = getSelectedLanguages();

    return (
      <div className="my-4">
        <div className={style_section_header}>{t("question_text")}</div>
        <div className={style_label_explain}>{t("displayed")}.</div>

        {languages.map((x) => {
          const displayLang = getLanguageDisplayStringByCode2(x);
          return (
            <div className="my-2 ml-4">
              <div className="flex flex-row">
                <div className={style_label_text}>{displayLang}</div>
                <div
                  className="mx-2 cursor-pointer"
                  title="Translate to different languages"
                  onClick={async () => {
                    setLoading(true);
                    const src = activeQuestion["text"][x].split("\n");
                    let payload = await projectStore.getTranslation(
                      src,
                      languages
                    );
                    for (let x of Object.keys(payload)) {
                      payload[x] = payload[x].join("\n");
                    }
                    const updated = _.clone(activeQuestion);
                    updated["text"] = payload;
                    setActiveQuestion(updated);
                    setLoading(false);
                  }}
                >
                  <i
                    className="fa-solid fa-language text-xl"
                    style={{ color: "#4F8BED" }}
                  />
                </div>
              </div>

              <textarea
                className="form-textarea p-2 mt-1 rounded-lg border-gray-400 border-2 block w-full"
                rows={3}
                value={activeQuestion["text"][x]}
                onChange={(event) => {
                  const changed = event.target.value;
                  const updated = _.clone(activeQuestion);
                  updated["text"][x] = changed;
                  setActiveQuestion(updated);
                }}
              ></textarea>
            </div>
          );
        })}
      </div>
    );
  };

  const QuestionOptions = () => {
    // @ts-ignore
    const { options } = activeQuestion;
    const languages = getSelectedLanguages();

    return (
      <div className="my-4">
        <div className="flex flex-row items-center">
          <div className={style_section_header}>{t("options")}</div>

          <div
            className="cursor-pointer mx-3 text-xl"
            onClick={() => {
              const updated = _.clone(activeQuestion["options"]);
              for (let l of languages) {
                updated[l].push("New Option");
              }
              setActiveQuestion({
                ...activeQuestion,
                options: updated,
              });
            }}
            title="Add new Option"
          >
            <i className="fa-solid fa-plus-circle" />
          </div>
        </div>

        <div className="flex flex-row flex-wrap ml-4">
          {languages.map((x) => {
            const displayLang = getLanguageDisplayStringByCode2(x);
            const langOptions = options[x];

            return (
              <div className="w-full md:w-1/2 lg:w-1/3 mx-2 my-2">
                <div className="flex flex-row items-center">
                  <div className={style_label_text}>{displayLang}</div>
                  <div
                    className="mx-3 cursor-pointer flex flex-row items-center"
                    title="Translate to different languages"
                    onClick={async () => {
                      setLoading(true);
                      const updated = _.clone(activeQuestion["options"]);
                      const src = updated[x];
                      let payload = await projectStore.getTranslation(
                        src,
                        languages
                      );
                      setActiveQuestion({
                        ...activeQuestion,
                        options: payload,
                      });
                      setLoading(false);
                    }}
                  >
                    <i
                      className="fa-solid fa-language text-2xl"
                      style={{ color: "#4F8BED" }}
                    />
                  </div>
                </div>
                <div className="flex flex-col">
                  {langOptions.map((opt) => {
                    return (
                      <div className="w-full flex flex-col">
                        <div className="flex flex-row items-center">
                          <textarea
                            rows={2}
                            value={opt}
                            onChange={(event) => {
                              const changed = event.target.value;
                              const updated = _.clone(
                                activeQuestion["options"]
                              );
                              const index = _.indexOf(updated[x], opt);
                              updated[x].splice(index, 1, changed);
                              setActiveQuestion({
                                ...activeQuestion,
                                options: updated,
                              });
                            }}
                            className="form-textarea p-2 mt-1 rounded-lg border-gray-400 border-2 block w-full "
                          />
                          <div
                            className="cursor-pointer relative"
                            style={{ left: "-20px" }}
                            onClick={() => {
                              deleteOption(x, opt);
                            }}
                          >
                            <i className="fa-solid fa-trash-alt font-bold text-red-500 hover:text-red-800" />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const QuestionActions = () => {
    return (
      <div className="my-4">
        <div className="flex flex-row items-center">
          <div className={style_section_header}>{t("actions")}</div>
        </div>
        <div className="flex flex-row flex-wrap ml-6 my-3">
          <button
            className="bg-red-500 hover:bg-red-800 text-white py-1 px-4 rounded inline-flex items-center"
            onClick={() => {
              deleteQuestion(activeQuestion);
            }}
          >
            <i className="fa-solid fa-trash-alt font-bold mr-2" />
            <span>{t("delete")}</span>
          </button>
        </div>
      </div>
    );
  };

  const deleteOption = (language, optionText) => {
    // @ts-ignore
    const { options } = activeQuestion;
    const copyOptions = _.clone(options);
    copyOptions[language] = _.pull(copyOptions[language], optionText);
    setActiveQuestion({ ...activeQuestion, options: copyOptions });
  };

  const HierarchyTree = () => {
    const level1Keys = Object.keys(activeQuestion["options"]);
    const languages = getSelectedLanguages();

    if (!Object.keys(activeQuestion).includes("hierarchyLabels")) {
      const updated = _.clone(activeQuestion);
      updated["hierarchyLabels"] = {};
      for (let l of languages)
        updated["hierarchyLabels"][l] = ["Select Area", "Select Ward"];
      setActiveQuestion(updated);
    }

    return (
      <div>
        <div className="my-4">
          <div className="flex flex-row items-center">
            <div className={style_section_header}>{t("labels")}</div>
          </div>
          <div className="flex flex-row flex-wrap ml-4">
            {languages.map((x) => {
              const displayLang = getLanguageDisplayStringByCode2(x);
              let hierarchyLabels = [];
              if (Object.keys(activeQuestion).includes("hierarchyLabels")) {
                console.log(activeQuestion["hierarchyLabels"][x]);
                hierarchyLabels.push(activeQuestion["hierarchyLabels"][x]);
              }
              console.log(hierarchyLabels[0]);
              const level1Label = hierarchyLabels[0];
              const level2Label = hierarchyLabels[1];

              return (
                <div className="w-full md:w-1/2 lg:w-1/3 mx-2 my-2">
                  <div className="flex flex-row items-center">
                    <div className={style_label_text}>{displayLang}</div>
                    <div
                      className="mx-3 cursor-pointer flex flex-row items-center"
                      title="Translate to different languages"
                      onClick={async () => {
                        setLoading(true);
                        const updated = _.clone(
                          activeQuestion["hierarchyLabels"]
                        );
                        const src = updated[x];
                        let payload = await projectStore.getTranslation(
                          src,
                          languages
                        );
                        setActiveQuestion({
                          ...activeQuestion,
                          hierarchyLabels: payload,
                        });
                        setLoading(false);
                      }}
                    >
                      <i
                        className="fa-solid fa-language text-2xl"
                        style={{ color: "#4F8BED" }}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="w-full flex flex-col">
                      <div className="flex flex-row items-center">
                        <input
                          type="text"
                          value={level1Label}
                          onChange={(event) => {
                            const changed = event.target.value;
                            const updated = _.clone(
                              activeQuestion["hierarchyLabels"]
                            );
                            updated[x][0] = changed;
                            setActiveQuestion({
                              ...activeQuestion,
                              hierarchyLabels: updated,
                            });
                          }}
                          className="form-textarea p-2 mt-1 rounded-lg border-gray-400 border-2 block w-full"
                        />
                      </div>
                    </div>

                    <div className="w-full flex flex-col">
                      <div className="flex flex-row items-center">
                        <input
                          type="text"
                          value={level2Label}
                          onChange={(event) => {
                            const changed = event.target.value;
                            const updated = _.clone(
                              activeQuestion["hierarchyLabels"]
                            );
                            updated[x][1] = changed;
                            setActiveQuestion({
                              ...activeQuestion,
                              hierarchyLabels: updated,
                            });
                          }}
                          className="form-textarea p-2 mt-1 rounded-lg border-gray-400 border-2 block w-full"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="my-4">
          <div className="flex flex-row items-center">
            <div className={style_section_header}>{t("hierarchy")}</div>
            <div
              className="cursor-pointer mx-3 text-xl"
              onClick={() => {
                const updated = _.clone(activeQuestion["options"]);
                const newLevel1 =
                  "Level 1 (" + makeid_alpha(2) + makeid_numeric(2) + ")";
                updated[newLevel1] = [];
                setActiveQuestion({
                  ...activeQuestion,
                  options: updated,
                });
              }}
              title="Add new Level 1 Hierarchy"
            >
              <i className="fa-solid fa-plus-circle" />
            </div>
          </div>
          <div className="flex flex-row flex-wrap ml-4">
            {level1Keys.map((level1) => {
              const level2Values = activeQuestion["options"][level1];

              return (
                <div className="w-full md:w-1/2 lg:w-1/3 mx-2 my-2">
                  <div className="flex flex-row items-center">
                    <input
                      type="text"
                      value={level1}
                      onChange={(event) => {
                        const changed = event.target.value;
                        let updated = _.clone(activeQuestion["options"]);
                        updated = renameObjKey(updated, level1, changed);
                        setActiveQuestion({
                          ...activeQuestion,
                          options: updated,
                        });
                      }}
                      className="form-textarea font-bold p-2 mt-1 rounded-lg border-gray-400 border-2 block w-full"
                    />
                    <div
                      className="cursor-pointer relative"
                      title={"Add Level 2 node under " + level1}
                      style={{ left: "-50px" }}
                      onClick={() => {
                        const updated = _.clone(activeQuestion["options"]);
                        const newLevel2 =
                          "Level 2 (" +
                          makeid_alpha(2) +
                          makeid_numeric(2) +
                          ")";
                        updated[level1].push(newLevel2);
                        setActiveQuestion({
                          ...activeQuestion,
                          options: updated,
                        });
                      }}
                    >
                      <i className="fa-solid font-2xl fa-plus-circle font-bold text-green-500 hover:text-green-800" />
                    </div>

                    <div
                      className="cursor-pointer relative"
                      title={"Delete " + level1}
                      style={{ left: "-40px" }}
                      onClick={() => {
                        const updated = _.clone(activeQuestion["options"]);
                        delete updated[level1];
                        setActiveQuestion({
                          ...activeQuestion,
                          options: updated,
                        });
                      }}
                    >
                      <i className="fa-solid font-2xl fa-trash-alt font-bold text-red-500 hover:text-red-800" />
                    </div>
                  </div>
                  <div className="flex flex-row flex-wrap ml-6">
                    {level2Values.map((level2) => {
                      return (
                        <div className="w-full">
                          <div className="flex flex-row items-center">
                            <input
                              type="text"
                              value={level2}
                              onChange={(event) => {
                                const changed = event.target.value;
                                const updated = _.clone(
                                  activeQuestion["options"]
                                );
                                const index = _.indexOf(
                                  updated[level1],
                                  level2
                                );
                                updated[level1].splice(index, 1, changed);
                                setActiveQuestion({
                                  ...activeQuestion,
                                  options: updated,
                                });
                              }}
                              className="form-textarea p-2 mt-1 rounded-lg border-gray-400 border-2 block w-full"
                            />
                            <div
                              className="cursor-pointer relative"
                              title={"Delete " + level2}
                              style={{ left: "-40px" }}
                              onClick={() => {
                                const updated = _.clone(
                                  activeQuestion["options"]
                                );
                                updated[level1] = _.pull(
                                  updated[level1],
                                  level2
                                );
                                setActiveQuestion({
                                  ...activeQuestion,
                                  options: updated,
                                });
                              }}
                            >
                              <i className="fa-solid font-2xl fa-trash-alt font-bold text-red-500 hover:text-red-800" />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const OtherText = () => {
    // @ts-ignore
    const { other_text } = activeQuestion;
    const languages = getSelectedLanguages();

    return (
      <div className="my-4">
        <div className="flex flex-row items-center">
          <div className={style_section_header}>{t("others_option_text")}</div>
        </div>

        <div className="flex flex-row flex-wrap ml-4">
          {languages.map((x) => {
            const displayLang = getLanguageDisplayStringByCode2(x);
            const langOthers = other_text[x];

            return (
              <div className="w-full md:w-1/2 lg:w-1/3 mx-2 my-2">
                <div className="flex flex-row items-center">
                  <div className={style_label_text}>{displayLang}</div>
                  <div
                    className="mx-3 cursor-pointer flex flex-row items-center"
                    title={t("different_languages")}
                    onClick={async () => {
                      setLoading(true);
                      const updated = _.clone(activeQuestion["other_text"]);
                      const src = updated[x];
                      let payload = await projectStore.getTranslation(
                        [src],
                        languages
                      );
                      for (let x of Object.keys(payload)) {
                        payload[x] = payload[x][0].trim();
                      }
                      setActiveQuestion({
                        ...activeQuestion,
                        other_text: payload,
                      });
                      setLoading(false);
                    }}
                  >
                    <i
                      className="fa-solid fa-language text-2xl"
                      style={{ color: "#4F8BED" }}
                    />
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="w-full flex flex-col">
                    <input
                      type="text"
                      value={langOthers}
                      onChange={(event) => {
                        const changed = event.target.value;
                        const updated = _.clone(activeQuestion["other_text"]);
                        updated[x] = changed;
                        setActiveQuestion({
                          ...activeQuestion,
                          other_text: updated,
                        });
                      }}
                      className="form-textarea p-2 mt-1 rounded-lg border-gray-400 border-2 block w-full"
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const SurveyPreview = () => {
    return (
      <div className="print-parent section-to-print page-container">
        {questions.map((qt, qno) => {
          const texts = Object.values(qt["text"]);
          const type = qt["type"];
          const options = Object.values(qt["options"]);
          const isSelectQuestion =
            type === "single-select" ||
            type === "single-select-other" ||
            type === "multi-select" ||
            type === "multi-select-other";
          return (
            <>
              <div className="my-4">
                <div className="font-bold my-1">
                  {t("questions")} {qno + 1}
                </div>
                {texts.map((t: any) => (
                  <div className="my-1">{t}</div>
                ))}
                {options && isSelectQuestion ? (
                  <div className="flex flex-row flex-nowrap">
                    {options.map((eachLangOptions: any) => {
                      // @ts-ignore
                      const isRender =
                        eachLangOptions && eachLangOptions?.length > 0;
                      if (!isRender) return null;
                      const choices = eachLangOptions as string[];

                      return (
                        <ul className="list-disc ml-10 my-2">
                          {choices.map((choice) => {
                            return <li>{choice}</li>;
                          })}
                        </ul>
                      );
                    })}
                  </div>
                ) : null}
                <hr />
              </div>
            </>
          );
        })}
      </div>
    );
  };

  const tableModel = {
    id: makeid_numeric(4) + "-" + makeid_alpha(6) + "-" + makeid_numeric(4),
    key: "",
    display: "",
    type: "",
  };

  const tableTypes = [
    "--Select--",
    "Text",
    "Numbers",
    "Phone Number",
    "Percentage",
  ];

  const Table = () => {
    // @ts-ignore
    const { table } = activeQuestion;
    return (
      <>
        <div>
          <div className="flex flex-row items-center">
            <div className={style_section_header}>{t("table")}</div>
            <div
              className="cursor-pointer mx-3 text-xl"
              onClick={() => {
                const updated = _.clone(activeQuestion["table"]);
                updated.push(tableModel);
                setActiveQuestion({
                  ...activeQuestion,
                  table: updated,
                });
              }}
              title={t("add_level_1")}
            >
              <i className="fa-solid fa-plus-circle" />
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center flex-wrap ml-4">
          {table.map((table, index) => {
            return (
              <div key={table.id} className="w-full flex flex-col p-2">
                <div className="flex flex-row items-center">
                  <div className="form-control flex flex-col mx-4">
                    <label htmlFor="key">{t("key")}</label>
                    <input
                      className="form-textarea p-2 mt-1 rounded-lg border-gray-400 border-2 block w-full"
                      type="text"
                      name="key"
                      value={table.key}
                      onChange={(event) => {
                        const val = event.target.value;
                        const updated = _.clone(activeQuestion["table"]);
                        updated[index]["key"] = val;
                        setActiveQuestion({
                          ...activeQuestion,
                          table: updated,
                        });
                      }}
                    />
                  </div>
                  <div className="form-control flex flex-col mx-4">
                    <label htmlFor="cast">{t("cast")}</label>
                    <input
                      className="form-textarea p-2 mt-1 rounded-lg border-gray-400 border-2 block w-full"
                      type="text"
                      name="key"
                      value={table.display}
                      onChange={(event) => {
                        const val = event.target.value;
                        const updated = _.clone(activeQuestion["table"]);
                        updated[index]["display"] = val;
                        setActiveQuestion({
                          ...activeQuestion,
                          table: updated,
                        });
                      }}
                    />
                  </div>
                  <div className="form-control flex flex-col mx-4">
                    <label htmlFor="type">{t("type")}</label>
                    <select
                      id="type"
                      name="type"
                      className="p-2 mt-1 block w-full border-gray-400 border-2 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-lg"
                      value={table.type}
                      onChange={(event) => {
                        const val = event.target.value;
                        const updated = _.clone(activeQuestion["table"]);
                        updated[index]["type"] = val;
                        setActiveQuestion({
                          ...activeQuestion,
                          table: updated,
                        });
                      }}
                    >
                      {tableTypes.map((type) => (
                        <option key={type}>{type}</option>
                      ))}
                    </select>
                  </div>
                  <div
                    className="cursor-pointer relative mt-4"
                    // style={{left: '-20px'}}
                    onClick={() => {
                      deleteTableOption(table);
                    }}
                  >
                    <i className="fa-solid fa-trash-alt font-bold text-red-500 hover:text-red-800" />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  const deleteTableOption = (table) => {
    const cloneTable = _.clone(activeQuestion["table"]);
    _.remove(cloneTable, { id: table.id });

    setActiveQuestion({
      ...activeQuestion,
      table: cloneTable,
    });
  };

  const ActiveQuestionRenderer = () => {
    if (!Object.keys(activeQuestion).includes("name")) return null;

    // @ts-ignore
    const { name, type, text, id, slug } = activeQuestion;
    const showOptions = [
      "single-select",
      "single-select-other",
      "multi-select",
      "multi-select-other",
    ].includes(type);
    const showOther =
      type === "single-select-other" || type === "multi-select-other";
    const showTreeOptions = type === "hierarchy-selector-2-level";
    const showTableOptions = type === "table";
    const showProfileOptions = type === "profile";

    const get_profile_question_options = () => {
      const options = safe_get(activeQuestion, "options", {});
      if (!_.isEmpty(options)) {
        if (Object.keys(options).includes("name")) {
          return options;
        } else {
          return profileQuestionOptions;
        }
      }
    };

    return (
      <div className="mb-4">
        <div className="flex flex-row justify-between items-center">
          <div className="font-bold text-xl text-base text-gray-700">
            {t("question")}: {name}
          </div>
          <div className="flex flex-row justify-between items-center">
            <div
              className={classNames(
                toggleJSONEditor
                  ? "cursor-pointer"
                  : "pointer-events-none opacity-50",
                "text-purple-500 pr-4"
              )}
              onClick={() => {
                setToggleJSONEditor(!toggleJSONEditor);
              }}
            >
              <i className="fa-solid fa-question-circle text-2xl" />
            </div>
            <div
              className={classNames(
                !toggleJSONEditor
                  ? "cursor-pointer"
                  : "pointer-events-none opacity-50",
                "text-purple-500 pr-4"
              )}
              onClick={() => {
                setToggleJSONEditor(!toggleJSONEditor);
              }}
            >
              <i className="fa-solid fa-code text-2xl" />
            </div>
          </div>
        </div>

        {toggleJSONEditor ? (
          JSONeditor()
        ) : (
          <>
            <div className="my-4">
              <div className={style_section_header}>{t("question_name")}</div>
              <input
                className={style_input}
                key="question-name"
                type="text"
                value={name}
                onChange={(event) => {
                  const changed = event.target.value;
                  setActiveQuestion({
                    ...activeQuestion,
                    name: changed,
                  });
                }}
              />
              <div className={style_label_explain}>
                {t("inconsistent_analytics")}
              </div>
            </div>
            {QuestionText()}
            {QuestionType()}
            {RequiredQuestion()}
            {QuestionCount()}
            {activeQuestion["type"] === "phone-whatsapp-otp-verification" &&
              otp_mandatory()}
            {showOptions ? QuestionOptions() : null}
            {showOther ? OtherText() : null}
            {showTreeOptions ? HierarchyTree() : null}
            {showTableOptions ? Table() : null}
            {showProfileOptions ? (
              <ProfileQuestion
                options={get_profile_question_options()}
                optionChange={(data) => {
                  setActiveQuestion({ ...activeQuestion, options: data });
                }}
              />
            ) : null}
            {QuestionActions()}
          </>
        )}
      </div>
    );
  };

  const [json, setJson] = useState("{}");
  const [isValidJson, setIsValidJson] = useState(false);

  useEffect(() => {
    setJson(JSON.stringify(activeQuestion, null, 4));
  }, [activeQuestion, questions]);

  const saveEditedJSON = () => {
    const cloned_questions = _.clone(questions);
    const index = _.findIndex(cloned_questions, (q) => {
      return q.id === activeQuestion["id"];
    });
    try {
      cloned_questions[index] = JSON.parse(json);
      setQuestions(cloned_questions);
      setActiveQuestion(JSON.parse(json));
      setIsValidJson(true);
      handleSubmit(cloned_questions, true);
    } catch (e) {
      toast.error("invalid JSON");
      setIsValidJson(false);
    }
  };

  const JSONeditor = () => {
    return (
      <>
        <textarea
          className="w-full bg-white px-4 py-2"
          value={json}
          onPaste={(evt) => {
            const content = evt.clipboardData.getData("text/plain");
            try {
              const updated = JSON.parse(content);
              const cloned = _.clone(activeQuestion);
              cloned["args"] = updated;
              // updateReportWithActiveSection(cloned);
            } catch (e) {
              console.log(e);
            }
          }}
          onChange={(evt) => {
            try {
              const val = evt.target.value;
              setJson(val);
            } catch (e) {
              console.log(e);
            }
          }}
          style={{ height: "550px" }}
        />
      </>
    );
  };

  return (
    <>
      <BreadCrumbs crumbs={crumbs} />
      {loading ? (
        <LoadingState />
      ) : (
        <div className="survey_editor" key="editor-container">
          <div
            className="flex flex-row flex-nowrap w-full h-[95vh]"
            key="editor-left"
          >
            <div className="w-full md:w-1/3 lg:w-1/4 p-2 bg-white overflow-y-auto">
              <LeftSide />
            </div>

            <div
              className="w-full md:w-2/3 lg:w-3/4 p-3 bg-gray-50 overflow-y-auto"
              key="editor-right"
            >
              <SurveyInfo />
              <hr className="my-2" />
              {showMeta ? SurveySettings() : null}
              {showPreview ? SurveyPreview() : null}
              {showActiveQuestion ? ActiveQuestionRenderer() : null}
            </div>
          </div>
        </div>
      )}
    </>
  );
});
