import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import {classNames, htmlValidation} from "../../helpers/utils";
import {useStore} from "../../helpers/helpers";
import Autocomplete from '@mui/material/Autocomplete'
import TextField from "@mui/material/TextField";

export const FTRFilterPane = observer(props => {

    // @ts-ignore
    const {surveys, onSearch, tags} = props;
    const {surveyResponseStore} = useStore();
    const [query, setQuery] = useState('');

    return (

        <div className='flex flex-row  flex-wrap'>
            <div className="flex  w-9/12 lg:w-7/12 mb-2">
                <Autocomplete
                    freeSolo
                    className="w-full rounded z-0"
                    id="select-user"
                    style={{backgroundColor: "white"}}
                    options={tags}
                    onChange={(event, v) => {
                        htmlValidation(event);
                        // @ts-ignore
                        setQuery(v)
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Search..."
                            onChange={(event) => {
                                htmlValidation(event);
                                setQuery(event.target.value)
                            }}
                            inputProps={{
                                ...params.inputProps,
                                type: "search",
                            }}
                        />
                    )}
                />
                {/*<input type="search" value={query} onChange={(evt) => {*/}
                {/*    setQuery(evt.target.value);*/}
                {/*}} className="w-full h-10 pr-10 pl-4 rounded z-0 focus:shadow focus:outline-none"*/}
                {/*       placeholder="Search..."/>*/}

            </div>
            <div className='flex flex-row justify-around items-center w-3/12 lg:w-1/12 '>
                <div className="cursor-pointer"
                     onClick={() => {
                         onSearch(query)
                     }}>
                    <i className="fa fa-search text-gray-400 z-20 hover:text-purple-500 text-2xl"/>
                </div>
                <div className="cursor-pointer"
                     onClick={() => {
                         surveyResponseStore.set_search_highlights(!surveyResponseStore.searchHighlights)
                     }}>
                    <i key={`search_highlights_${surveyResponseStore.searchHighlights}`}
                       className={classNames(surveyResponseStore.searchHighlights ? "fa-solid fa-star text-yellow-300 " : "fa-regular fa-star", " text-2xl")}
                       title="Contains Important Highlights"/>
                </div>
            </div>
            <div className='w-full lg:w-4/12  '>
                <select
                    className='w-full rounded z-0 focus:shadow outline-none p-2 sm:p-4'
                    style={{backgroundColor: "white"}}
                    onChange={(eve) => {
                        const id = parseInt(eve.target.value)
                        surveyResponseStore.setActiveSurveyId(id)
                    }}>
                    {surveys.map(survey => {
                        if (!survey) return;
                        return <option className='p-2 text-md' value={survey.id}>{survey.name}</option>
                    })}
                </select>
            </div>
        </div>
    )
})