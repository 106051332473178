import React from "react";
import {useCurrentFSD} from "../../../../custom-hooks/useCurrentFSD";
import { useTranslation } from "react-i18next";

export const BoothGradingMethodology = (props:any) => {

    const config = useCurrentFSD();
    const {grading_config} = config;
    const {t} = useTranslation("booth_bank");

    return (
        <>
            <div className='mx-auto p-6'>
                <div>{t("swot_analysis_technique")}
                </div>
                <ul className='ml-6'>
                    <li className='list-disc'><strong>{t("strong")}</strong>:{t("simple_majority_of_above")}{grading_config["strength_th"] * 100}% {t("vote_share")}
                    </li>
                    <li className='list-disc'><strong>{t("weak")}</strong>: {t("less_than")} {grading_config["weak_th"] * 100}%
                        {t("vote_share")}
                    </li>
                    <li className='list-disc'><strong>{t("opportunity")}</strong>: {t("more_vote_share")}
                        
                    </li>
                    <li className='list-disc'><strong>{t("threat")}</strong>: {t("less_vote_share")}
                      
                    </li>
                    <li className='list-disc'><strong>{t("average")}</strong>: {t("equal_vote_share")}
                    </li>
                </ul>
                <div className='mt-4'>{t("do_not_convert")}
                </div>
            </div>
        </>
    )
}