import {observer} from "mobx-react-lite";
import React from 'react';
import {FormButtons, SideOverlay} from "../../../components";
import {FormProvider, useForm} from "react-hook-form";
import {RNInput} from "../../../components/form-components";
import {PWDefaultMedia} from "../pw-v2-types";
import {useStore} from "../../../helpers";


export const AddKeyContacts = observer ((props:any) => {

    const { projectWorkStoreV2 } = useStore()
    const { add_key_contacts} = projectWorkStoreV2;

    const onSubmit = (data) =>{
        console.log(data)
    }

    const methods = useForm({
        defaultValues: PWDefaultMedia,
    })

    const close_modal = () => {
        projectWorkStoreV2.update_project_work_property("add_key_contacts",false);
    }

    return (

        <SideOverlay
            onClose={close_modal}
            show={add_key_contacts}
            title={"Add Key Contacts"}
        >
            <FormProvider{...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}
                      className="bg-white px-4 py-2 rounded-sm flex flex-col gap-y-4">
                    <RNInput
                        label={"Name"}
                        name={"name"}
                    />
                    <RNInput
                        label={"Phone Number"}
                        name={"Alternate Phone Number"}
                        multiline
                        rows={3}
                        fullWidth
                    />
                    <RNInput
                        label={"Mail"}
                        name={"mail"}
                    />
                    <RNInput
                        label={"Address"}
                        name={"Address"}
                    />
                    <RNInput
                        label={"Designation"}
                        name={"designation"}
                    />
                    <RNInput
                        label={"Comments"}
                        name={"Comments"}
                        multiline
                        rows={3}
                        fullWidth
                    />
                    <FormButtons
                        onSave={() => {}}
                        onCancel={close_modal}
                    />
                </form>
            </FormProvider>
        </SideOverlay>
    )
})