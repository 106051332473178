import React from "react"
import { Field, ErrorMessage } from "formik"
import { TextError } from "./formik-controller"

function TextArea(props) {
    const { label, name, labelStyle, fieldStyle, height, ...rest } = props
    return (
        <>
            <label htmlFor={name} className={`${labelStyle} font-bold`}>{label}</label>
            <div className={`flex flex-row items-center h-${height}`} >
                <Field as="textarea" id={name} name={name} {...rest} className={fieldStyle} />
            </div>

            <div className="flex justify-self-end">
                <ErrorMessage name={name} component={TextError} />
            </div>
        </>
    )
}
export default TextArea