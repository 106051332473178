import React from "react";
import { zeroPad } from "../../../helpers/helpers";
import { FlexiStoreData_Abridged } from "../../../stores/fs/fst";
import { useNavigate, useParams } from "react-router-dom";

export const PCBankSearchCard = (props) => {
  console.log(props);
  const fsd: FlexiStoreData_Abridged = props.data;
  const navigate = useNavigate();
  const { fstid } = useParams();

  // @ts-ignore
  const { pcno, pcname, res, region, district } = fsd.summary;

  return (
    <div
      className="flex flex-row rounded-lg shadow-lg overflow-hidden cursor-pointer bg-white p-3"
      style={{
        borderLeft: `4px solid`,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      }}
      onClick={(evt) => {
        navigate(`/databank/repo/${fstid}/${fsd.key}/${fsd.id}`, {
          state: { renderkey: fsd.renderkey },
        });
      }}
    >
      <div
        className="rounded-full flex flex-col items-center justify-center bg-gray-200"
        style={{ width: 60, height: 60 }}
      >
        <i className={`text-3xl`} style={{ color: `green` }} />
        <div className="font-bold text-gray-800">PC</div>
        <div className="font-bold text-gray-800">
          {zeroPad(parseInt(pcno), 3)}
        </div>
      </div>
      <div className="flex-1 flex flex-col ml-2">
        <div className="font-bold text-gray-700">
          {pcname} ({res})
        </div>
        <div className="text-gray-600 text-md">
          {district}, {region}
        </div>
      </div>
    </div>
  );
};
