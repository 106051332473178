import React, {useMemo} from "react";
import {observer} from "mobx-react-lite";
import {useStore} from "../../../helpers/helpers";
import _ from "lodash";
import {Table} from "./table";
import {toJS} from "mobx";
import {TableHeader} from "./survey-summary";
import { useTranslation } from "react-i18next";

export const SurveysLevel1 = observer(props => {
    const {surveyResponseStore, userStore} = useStore();
    const {allSurveyResponsesSummary} = surveyResponseStore;
    const {t} = useTranslation("survey_management");

    const data = useMemo(() => toJS(allSurveyResponsesSummary)["by_piv_level1_qa"], [allSurveyResponsesSummary])

    const columns = useMemo(() => [
        {
            Header: t("level1"),
            accessor: 'piv_level1',
            Footer: t("total")
        }, {
            Header: t("valid"),
            accessor: 'valid',
            Footer: surveyResponseStore.get_total_surveys(data, "valid")
        }, {
            Header: t("invalid"),
            accessor: 'invalid',
            Footer: surveyResponseStore.get_total_surveys(data, "invalid")
        }, {
            Header: t("not_checked"),
            accessor: 'not-checked',
            Footer: surveyResponseStore.get_total_surveys(data, "not-checked")
        },
        {
            Header: t("total_surveys"),
            accessor: 'total',
            Footer: surveyResponseStore.get_total_surveys(data, "total")
        }
    ], [allSurveyResponsesSummary]);

    if(_.isEmpty(allSurveyResponsesSummary["by_piv_level1_qa"]) || userStore.is_field_worker()) return null;

    const row_click_handler = (x) => {
        console.log(x);
        surveyResponseStore.update_new_filters("piv_level1",x["piv_level1"],`Level 1: ${x['piv_level1']}`);
    }

    return(
        <div className="flex flex-col">
            <TableHeader  title={t("level")}/>
            <Table data={data} columns={columns} onRowClick={row_click_handler}/>
        </div>
    )
})