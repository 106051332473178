import {observer} from "mobx-react-lite";
import React from 'react';
import {FormButtons, SideOverlay} from "../../../components";
import {FormProvider, useForm} from "react-hook-form";
import {RNInput} from "../../../components/form-components";
import {PWDefaultMedia} from "../pw-v2-types";
import {useStore} from "../../../helpers";


export const AddEventV2 = observer ((props:any) => {

    const { projectWorkStoreV2 } = useStore()
    const { add_event_modal} = projectWorkStoreV2;


    const onSubmit = (data) =>{
        console.log(data)
    }
    const methods = useForm({
        defaultValues: PWDefaultMedia,
        mode: 'onSubmit'

    })

    const close_modal = () => {
        projectWorkStoreV2.update_project_work_property("add_event_modal",false);
    }

    return (
        <>
            <div>
                <SideOverlay
                    onClose={close_modal}
                    show={add_event_modal}
                    title={"Add Event"}
                >
                    <FormProvider{...methods}>
                        <form onSubmit={methods.handleSubmit(onSubmit)}
                              className="bg-white px-4 py-2 rounded-sm flex flex-col gap-y-4">
                            <RNInput
                                label={"Title"}
                                name={"title"}
                            />
                            <RNInput
                                label={"Date"}
                                name={"date"}
                                type={"date"}
                            />
                            <RNInput
                                label={"Description"}
                                name={"description"}
                                multiline
                                rows={3}
                                fullWidth
                            />
                            <FormButtons
                                onSave={() => {
                                }}
                                onCancel={close_modal}
                            />
                        </form>
                    </FormProvider>
                </SideOverlay>
            </div>
        </>
    )
})
