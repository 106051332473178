import {action, makeAutoObservable, observable} from "mobx";

export class VoterReachOutStore {

    messageType: string = ""
    isLoading: boolean = false;
    isSideOverlayOpen: boolean = false
    isEditOverlayOpen: boolean = false
    isWhatsappOverviewOpen: boolean = false
    isMessageOverviewOpen: boolean = false
    addCampaign: boolean = false;
    editCampaign: boolean = false;
    textMessageFormValue = {
        name: '',
        message: '',
        id: ''
    }
    TargetFormValue = {
        name: '',
        text: '',
        id: ''
    }
    voiceMessageFormValue = {
        name: '',
        id: '',
        message: '',
        attachements: []
    }
    whatsappMessageFormValue = {
        name: '',
        message: '',
        id: '',
        attachements: []
    }
   
    formType: string = "new"
    reachOutList = []
    reachOutTargetList = []
    reachOutCampaignList = []
    selectedOverViewItem = null

    rootStore: any;


    constructor(rootStore: any) {
        makeAutoObservable(this)
        this.rootStore = rootStore;
    }

    @action
    set_messageType(type) {
        this.messageType = type;
    }

    @action
    set_IsWhatsappOverviewOpen(type) {
        this.isWhatsappOverviewOpen = type
    }

    @action
    set_IsMessageOverviewOpen(type) {
        this.isMessageOverviewOpen = type
    }

    @action
    set_isSideOverlay(val) {
        this.isSideOverlayOpen = val
    }

    set_isEditOverlay(val) {
        this.isEditOverlayOpen = val
    }


    @action
    setEditCampaignStatus = (val) => {
        this.editCampaign = val
    }

    @action
    setAddCampaignStatus = (val) => {
        this.addCampaign = val
    }

    @action
    set_formType(val) {
        this.formType = val
    }

    @action
    set_selected_overview_item(val) {
        this.selectedOverViewItem = val
    }

    @action
    set_selected_edited_item(val) {
        this.TargetFormValue = {
            name: val.name,
            text: val.text,
            id: val.id
        }
    }

    @action
    set_textMessageFormValue(formVal) {
        this.textMessageFormValue = {
            name: formVal.name,
            message: formVal.text,
            id: formVal.id
        }
    }

    @action set_voiceMessageFormValue(formVal) {
        console.log('got here', formVal)
        this.voiceMessageFormValue = {
            name: formVal.name,
            id: formVal.id,
            message: formVal.text,
            attachements: formVal.attachements
        }
    }

    @action
    set_whatsappMessageFormValue(formVal) {
        console.log('got here', formVal)

        this.whatsappMessageFormValue = {
            name: formVal.name,
            message: formVal.text,
            id: formVal.id,
            attachements: formVal.attachements
        }
    }

    @action
    reset_TargetFormVal() {
        this.TargetFormValue = {
            name: '',
            text: '',
            id: ''
        }
    }

    @action
    reset_textMessageFormVal() {
        this.textMessageFormValue = {
            name: '',
            message: '',
            id: ''
        }
    }

    @action
    reset_voiceMessageFormVal() {
        this.voiceMessageFormValue = {
            name: '',
            id: '',
            message: '',
            attachements: [],
        }
    }

    @action
    reset_whatsappMessageFormVal() {
        this.whatsappMessageFormValue= {
            name: '',
            message: '',
            attachements: [],
            id: ''
        }
    }


    @action
    async get_reachout_message(payload) {
        this.isLoading = true
        try {
            const result = await this.rootStore.api.post("/reachout/messagesearch", payload)
            this.reachOutList = result.data
            this.isLoading = false
        } catch (e) {
            this.isLoading = false
        }
    }

    @action
    async get_reachout_target(payload) {
        this.isLoading = true
        try {
            const result = await this.rootStore.api.post("reachout/vrotsearch", payload)
            this.reachOutTargetList = result.data
            this.isLoading = false
        } catch (e) {
            this.isLoading = false
        }
    }

    @action
    async get_reachout_Campaign(payload) {
        this.isLoading = true
        try {
            const result = await this.rootStore.api.post("reachout/vrocsearch", payload)
            this.reachOutCampaignList = result.data
            this.isLoading = false
        } catch (e) {
            this.isLoading = false
        }
    }


    @action
    async details_reachout_target(payload) {
        this.isLoading = true
        try {
            const result = await this.rootStore.api.post("reachout/vrotdetails", payload)
            this.selectedOverViewItem = result.data
            this.set_selected_edited_item(result.data)
            this.isLoading = false
            return result
        } catch (e) {
            this.isLoading = false
        }
    }

    @action
    async details_reachout_Campaign(payload) {
        this.isLoading = true
        try {
            const result = await this.rootStore.api.post("reachout/vrocdetails", payload)
            this.selectedOverViewItem = result.data
            this.set_selected_edited_item(result.data)
            this.isLoading = false
            return result
        } catch (e) {
            this.isLoading = false
        }
    }

    @action
    async create_reachout_target(payload) {
        this.isLoading = true
        try {
            const result = await this.rootStore.api.post("reachout/vrotcreatestatic", payload)
            this.isLoading = false
            return result
        } catch (e) {
            this.isLoading = false
        }
    }

    @action
    async create_reachout_Campaign(payload) {
        this.isLoading = true
        try {
            const result = await this.rootStore.api.post("reachout/newvrocampaign", payload)
            this.isLoading = false
            return result
        } catch (e) {
            this.isLoading = false
        }
    }

    @action
    async create_reachout_message(payload) {
        this.isLoading = true
        try {
            const result = await this.rootStore.api.post("/reachout/messagecreate", payload)
            this.isLoading = false
            return result
        } catch (e) {
            this.isLoading = false
        }
    }

    @action
    async update_reachout_message(payload) {
        this.isLoading = true
        try {
            const result = await this.rootStore.api.post("/reachout/messageupdate", payload)
            this.isLoading = false
            this.formType = 'new'
            return result
        } catch (e) {
            this.isLoading = false
        }
    }

    @action
    async update_reachout_target(payload) {
        this.isLoading = true
        try {
            const result = await this.rootStore.api.post("/reachout/vrotupdatestatic", payload)
            this.isLoading = false
            return result
        } catch (e) {
            this.isLoading = false
        }
    }

    @action
    async delete_reachout_message(payload) {
        this.isLoading = true
        try {
            const result = await this.rootStore.api.post("/reachout/messagedelete", payload)
            this.isLoading = false
            this.formType = 'new'
            return result
        } catch (e) {
            this.isLoading = false
        }
    }

    @action
    async delete_reachout_target(payload) {
        this.isLoading = true
        try {
            const result = await this.rootStore.api.post("/reachout/vrotdelete", payload)
            this.isLoading = false
            return result
        } catch (e) {
            this.isLoading = false
        }
    }

    @action
    async delete_reachout_campaign(payload) {
        this.isLoading = true
        try {
            const result = await this.rootStore.api.post("/reachout/vrocdelete", payload)
            this.isLoading = false
            return result
        } catch (e) {
            this.isLoading = false
        }
    }

}