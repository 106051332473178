import React, {useEffect} from "react";
import {useStore} from "../helpers";
import {Navigate, Outlet, useLocation} from 'react-router-dom'
import {Header, UserAgreement} from "../components";
import {observer} from "mobx-react-lite";
import {AppDrawer} from "../components/AppDrawer";
import {makeid_alpha} from "../helpers";

// @ts-ignore
export const PrivateRoute = observer((props:any) => {
    const rootStore = useStore();
    const {userStore} = rootStore;
    const {isAuthorized} = userStore;

    const location = useLocation();

    const isCheckedIn = userStore.checkLogin();

    useEffect(() => {
        if(!isAuthorized) {
            userStore.logout();
        }
    }, [isAuthorized])

    return (
        <div>
            {isCheckedIn ? (
                    <>
                        {/*<Header/>*/}
                        <AppDrawer />
                        <main className={"bg-[#F5F8F9] min-h-[100vh]"}>
                            <Outlet/>
                            <UserAgreement key={makeid_alpha(4)}/>
                        </main>
                    </>
                ) :
                <Navigate to="/login" state={{path :location.pathname}} />
            }
        </div>
    );
})

// @ts-ignore
export const PublicRoute = ({component: Component, ...rest}) => {
    return <Component  {...rest} />;
};