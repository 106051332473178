import {Button} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";

export const FilterButton = ({filterOnClickHandler, ...props}) => {
    const { t } = useTranslation(["beneficiary", "voter_list"]);
    return (
        <Button
            variant={"contained"}
            endIcon={<i className="fas fa-filter"/>}
            onClick={filterOnClickHandler}
            {...props}
            className={"self-start"}
        >
            {t("Filter")}
        </Button>
    )
}