import React, { useState } from "react";
import { FlexiStoreData_Abridged } from "../../../stores/fs/fst";
import { instanceToPlain } from "class-transformer";
import JSONModal from "../../jsonModal";
import { htmlValidation, useStore } from "../../../helpers";
import { useTranslation } from "react-i18next";
import { LoadingState } from "../../../components";
import { useNavigate } from "react-router";
import {observer} from "mobx-react-lite";

export const BoothReportSearchCard = observer((props: any) => {

  const fsd: FlexiStoreData_Abridged = props.data;
  const { userStore, flexiStore } = useStore();
  const navigate = useNavigate();
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  console.log(props)
  const [t] = useTranslation(["booth_bank", "beneficiary"]);

  // @ts-ignore
  const { name, state } = fsd.summary;

  const editSummary = (evt) => {
    setLoading(true);
    htmlValidation(evt);
    setEdit(true);
    flexiStore.get_fsdata(fsd.id);
    setLoading(false);
  };

  //edit summary

  const handleclickpermissions = async (evt) => {
    htmlValidation(evt);
    // flexiStore.set_show_booth_report_persmissions(true);
  };

  const onConfigUpdate = async (updated_json) => {
    setLoading(true);
    flexiStore.update_current_fsd("summary", updated_json);
    await flexiStore.api_update_current_fsd();
    await flexiStore.get_search_fs_data();
    setEdit(false);
    setLoading(false);
  };

  const onConfigCancel = (updated_json) => {
    setEdit(false);
  };

  const clone_booth_report = async (evt, id) => {
    setLoading(true);
    htmlValidation(evt);
    const res = await flexiStore.clone_fsd(id);
    if (res.status === 200) {
      await flexiStore.get_search_fs_data();
    }
    setLoading(false);
  };

  return (
      <>
        {loading && <LoadingState />}
        <div
            key={`fsd_${fsd.id}`}
            className="flex flex-col rounded-lg shadow-lg overflow-hidden cursor-pointer bg-white p-2"
            style={{
              borderLeft: `4px solid`,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
        >
          <div className="flex-1 flex flex-col ml-2 justify-between">
            <div className="font-bold text-gray-500">{name}</div>
            <div className="font-bold text-gray-500">{state}</div>
          </div>
          <div className="flex flex-row flex-wrap space-x-4 bg-white p-3">
            <a
                className="text-blue-500 cursor-pointer"
                onClick={(evt) => {
                  editSummary(evt);
                }}
            >
              {t("edit", { ns: "beneficiary" })}
            </a>
            <a
                className="text-blue-500 cursor-pointer"
                onClick={(evt) => {
                  clone_booth_report(evt, fsd.id);
                }}
            >
              {t("clone")}
            </a>
            {/*<a className='text-blue-500 cursor-pointer'*/}
            {/*   onClick={(evt) => {*/}
            {/*       htmlValidation(evt);*/}
            {/*       navigate('permissions', {state: {fsdid: fsd.id}})*/}
            {/*       handleclickpermissions(evt)*/}
            {/*   }}>{t("Permissions", {ns: "beneficiary"})}*/}
            {/*</a>*/}

            {userStore.isRoot() ? (
                <a
                    className="text-blue-500 cursor-pointer"
                    onClick={async (evt) => {
                      htmlValidation(evt);
                      setLoading(true);
                      const res = await flexiStore.delete_fsd(fsd.id);
                      if (res.status === 200) {
                        await flexiStore.get_search_fs_data();
                      }
                      setLoading(false);
                    }}
                >
                  {t("delete", { ns: "beneficiary" })}
                </a>
            ) : null}
            <div className="text-gray-600" style={{ marginLeft: "auto" }}>
              {fsd.id}
            </div>
          </div>

          <JSONModal
              open={edit}
              data={JSON.stringify(instanceToPlain(fsd.summary), null, 4)}
              handleUpdate={onConfigUpdate}
              handleCancel={onConfigCancel}
          />
        </div>
      </>
  );
})
