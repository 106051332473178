import React from "react";
import {useTranslation} from "react-i18next";
import {BoothTree} from "../BoothTree";
import {Typography} from "@mui/material";
import {C3BoothFilter} from "../../features/constituency-command-center/filters";

export const VillageFilter = (props) => {

    const {t} = useTranslation("grievance_management");
    const {value, setValues} = props;

    return (
        <div className={"flex flex-col flex-1 "}>
            <Typography color={"primary"} variant={"subtitle1"}>Village/Booth</Typography>
            <div className="flex flex-row formik-select w-full">
                <C3BoothFilter
                    onBoothSelection={(data) => {
                        setValues(data)
                    }}
                    value={value}
                />
                {/*<BoothTree*/}
                {/*    onBoothSelection={(data) => {*/}
                {/*        setValues(data)*/}
                {/*    }}*/}
                {/*    selected_booth={value}*/}
                {/*    selector={villageSelector}*/}
                {/*    isMulti={true}*/}
                {/*/>*/}
            </div>
        </div>
    )
}