import React, { useState } from "react";
import { FilterHeader } from "../filterHeader";
import { useStore } from "../../../helpers/helpers";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

const age_bands = [
    {
        key: "18-23",
        val: "18,23"
    },
    {
        key: "24-35",
        val: "24,35"
    },
    {
        key: "36-50",
        val: "36,50"
    },
    {
        key: "51-75",
        val: "51,75"
    },
    {
        key: "76-100",
        val: "76,100"
    }
]

const AgeFilter = observer((props: any) => {
    const rootStore = useStore();
    const { voterListStore } = rootStore;
    const { voter_filters } = voterListStore;
    const { handleAgeFilter } = props;
    const [t] = useTranslation("voter_list");
    const [selectedOption, setSelectedOption] = useState(voter_filters?.age.toString());

    const handleOptionChange = (event) => {
        handleAgeFilter(event);
        if (event.name === "reset") {
            setSelectedOption("");
        } else {
            setSelectedOption(event.target.value);
        }
    };

    return (
        <>
            <div className="flex flex-col items-start">
                <FilterHeader title={t("age")} content={t("age_range")} />
                {age_bands.map(band => {
                    return (
                        <label className="flex flex-row items-center py-2">
                            <input
                                type="radio"
                                className="radio radio-accent px-2"
                                value={band.val}
                                checked={selectedOption === band.val}
                                onChange={handleOptionChange}
                            />
                            <span className="label-text px-2">{band.key}</span>
                        </label>
                    )
                })}
            </div>
            <button name="reset" className="btn btn-link flex flex-row self-end" onClick={handleOptionChange}>Clear</button>
        </>
    );
})

export default AgeFilter;
