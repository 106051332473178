import React, {useEffect, useState} from "react";
import {toJS} from "mobx";
import _ from "lodash";
import {RootStore} from "../../../../stores/root";
import {useStore} from "../../../../helpers";
import { useTranslation } from "react-i18next";

export const CasteMatrix = (innerprops) => {

    const rootStore: RootStore = useStore()
    const {flexiStore} = rootStore;
    const {lookup_key, display} = innerprops
    const [reg, set_reg] = useState([])
    const [cols, set_cols] = useState([])
    const [vals, set_vals] = useState({})
    const [t] = useTranslation(["caste_matrix", "field_payment"])

    useEffect(() => {
        if (flexiStore.external_resource && flexiStore.external_resource[lookup_key]) {
            const regions = Object.keys(flexiStore.external_resource[lookup_key]);

            const top_castes = [];
            let total_count = 0;
            flexiStore.external_resource["overall"].map(x => {
                if (x['level'] == 1) return;
                if (total_count > 10) return;
                top_castes.push(x['display']);
                total_count++;
            });

            const values_by_region = {};

            regions.map(region => {
                values_by_region[region] = {};
                const castes = toJS(flexiStore.external_resource[lookup_key][region]);

                top_castes.map(caste => {
                    try {
                        const match = _.find(castes, {"display": caste});
                        values_by_region[region][caste] = [match['voters_percent'], match['voters']];
                    } catch (e) {
                        values_by_region[region][caste] = ['', ''];
                    }
                });
            });

            set_cols(top_castes);
            set_reg(regions);
            set_vals(values_by_region);
        }
    }, []);


    return (
        <>
            <div className='font-bold text-lg mt-4 pb-2 uppercase'>{t("caste_by")} {display}</div>
            <table className='fsd-caste-details'>
                <thead>
                <tr className='font-bold bg-gray-200'>
                    <th className='text-left first'>{t("region" ,{ns:"field_payment"})}</th>
                    {cols.map((caste, index) => {
                        return (
                            <th key={index} className='text-right caste-header'>{caste}</th>
                        )
                    })}
                </tr>

                </thead>
                <tbody>
                {
                    reg.map((region, index) => {
                        return (
                            <tr key={index} className={`level-reg`}>
                                <th className='text-left first'>{region}</th>
                                {cols.map(caste => {
                                    return (
                                        <td className='text-right caste_value'>
                                            <div className={"region_percent"}>{vals[region][caste][0]}</div>
                                            <div className={"region_voters"}>{vals[region][caste][1]}</div>
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>

            <table className='fsd-caste-details mt-4'>
                <thead>
                    <tr className='font-bold bg-gray-200'>
                        <th className='text-left first'>{t("region" ,{ns:"field_payment"})}</th>
                        {cols.map(caste => {
                            return (
                                <th className='text-right caste-header'>{caste}</th>
                            )
                        })}
                    </tr>
                </thead>
                <tbody>
                {
                    reg.map(region => {
                        return (
                            <tr className={`level-reg`}>
                                <th className='text-left first'>{region}</th>
                                {cols.map(caste => {
                                    return (
                                        <td className='text-right caste_value'>
                                            <div className={"region_percent"}>{vals[region][caste][0]}</div>
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>
        </>
    )
}