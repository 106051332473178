import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {useStore} from "../../helpers/helpers";
import _ from "lodash";
import {QuestionAndAnswerResponseView} from "../survey-management";
import {LoadingState} from "../../components";

export const VoterRegistrationResponseView = observer((props: any) => {

    const {surveyResponseStore} = useStore();
    const {activeSurveyResponse, activeSurveyResponseId} = surveyResponseStore;
    const [loading, setLoading] = useState(false);

    const getSurveyResponseFromServer = async () => {
        setLoading(true)
        await surveyResponseStore.getActiveSurveyResponse()
        setLoading(false)
    }

    useEffect(() => {
        getSurveyResponseFromServer();
    }, [activeSurveyResponseId]);

    const onQuestionClick = (time, question) => {
        surveyResponseStore.set_active_question(question)
    }

    const SurveyResponseDetails = () => {
        if (!activeSurveyResponse) return null

        // @ts-ignore
        const {sr} = activeSurveyResponse
        const {response, meta} = sr
        const responses = response['response']
        const {surveyref, nav} = meta
        const {questions} = surveyref

        const RenderQuestionResponses = () => {
            const elements = []
            for (let ind = 0; ind < questions.length; ind++) {
                const q = questions[ind]
                const r = responses[ind]
                // @ts-ignore
                elements.push(<QuestionAndAnswerResponseView qno={ind} question={q} response={r} onClickHandler={onQuestionClick}/>)
            }
            return (
                <div className='flex flex-col'>{elements}</div>
            )
        }

        return (
            <>
                {
                    (questions.length == responses.length) ? <RenderQuestionResponses/> : null
                }
            </>
        )
    }

    return (
        <>
            {loading && <LoadingState />}
            {!(_.isEmpty(activeSurveyResponse)) && <SurveyResponseDetails/>}
        </>
    )
})