import React from "react";

const GetIcons = ({ icon_name }) => {
  let iconClass = "";
  switch (icon_name) {
    case "Gender":
      iconClass = "fa-solid fa-venus-mars";
      break;
    case "Age":
      iconClass = "fa-solid fa-cake-candles";
      break;
    case "Caste":
      iconClass = "fa-solid fa-person-praying";
      break;
    case "Education":
      iconClass = "fa-solid fa-book-open-reader";
      break;
    case "Economic Status":
      iconClass = "fa-solid fa-indian-rupee-sign";
      break;
    case "Occupation":
      iconClass = "fa-solid fa-suitcase";
      break;
    default:
      iconClass = "";
  }

  return iconClass ? <i className={iconClass} /> : null;
};

export default GetIcons;
