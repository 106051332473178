import React from "react"
import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from "@mui/material";
import {Controller} from "react-hook-form";

type Option = {
    label: string,
    value: string
}

type MSelectProps = {
    name: string,
    label: string,
    options: Array<Option | string>
}

export const MSelect = ({ name, control, label, options, rules, ...rest }) => {
    return(
        <>
            <Controller
                name={name}
                control={control}

                rules={rules}
                render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth margin="normal">
                        <InputLabel id={label}>{label}</InputLabel>
                        <Select className={'h-12'} variant={"filled"} {...rest} {...field} labelId={`select-${label}`} label={label}>
                            <MenuItem value=""><em>None</em></MenuItem>
                            {options.map((option, index) => <MenuItem key={index} value={option.value}>{option.label}</MenuItem>)}
                        </Select>
                        {error && <FormHelperText error>{error.message}</FormHelperText>}
                    </FormControl>
                )}
            />
        </>
    )
}