import React from "react"; // Import React to use JSX and React functionalities.
import ReactDOM from "react-dom/client"; // Import ReactDOM for rendering React components into the DOM.
import "./index.css"; // Import global CSS for styling.
import App from "./App"; // Import the root App component.
import reportWebVitals from "./reportWebVitals"; // Import utility to measure performance metrics.
import { BrowserRouter } from "react-router-dom"; // Import BrowserRouter for handling routing in the application.
import { RootStore } from "./stores/root"; // Import the RootStore class for managing global state using MobX.
import { StoreProvider } from "./helpers"; // Import custom provider to make the MobX store available throughout the app.
import "./i18n"; // Import i18n configuration for internationalization support.
import { ErrorBoundary } from "react-error-boundary"; // Import ErrorBoundary to catch JavaScript errors in child components.
import { ErrorBoundaryFallBack } from "./pages"; // Import fallback UI to be displayed when an error is caught by the ErrorBoundary.

const initialRoot = new RootStore(); // Initialize the RootStore for managing global state.

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement // Get the root element in the HTML to render the React app.
);
root.render(
  <>
    <ErrorBoundary
      FallbackComponent={ErrorBoundaryFallBack} // Component to display when an error is caught.
      onReset={(details) => {
        // Optional: Reset the state of your app or perform any necessary cleanup.
      }}
    >
      <StoreProvider value={initialRoot}>
        {/* Make the MobX store available to the entire app via StoreProvider. */}
        <BrowserRouter>
          {/* Wrap the app in BrowserRouter to enable routing. */}
          <App />
        </BrowserRouter>
      </StoreProvider>
    </ErrorBoundary>
  </>
);

// reportWebVitals is a function that allows you to measure the performance of your app.
// You can log the results to the console or send them to an analytics endpoint.
reportWebVitals();
