export const IFund = {
    id: "",
    title: "",
    description: "",
    amount: "",
    date: ""
}

export const IContact = {
    id: "",
    name: "",
    phone: "",
    address: "",
    comments: "",
    alternate_phone: "",
    email: "",
    designation:{}
}

export const IMedia = {
    id: "",
    date: "",
    type: "",
    title: "",
    mediapath: "",
    description: "",
    thumbnailpath: ""
}

export const IEvent = {
    id: "",
    date: "",
    title: ""
}

export const defaultIProjectlFilters = {
    villages: [],
    status: [],
    start_date: "",
    end_date: ""
}