import S3 from 'react-aws-s3';
import {toast} from "react-toastify";
import {makeid_alpa_numeric} from "./utils";
import {useState} from "react";
window.Buffer = window.Buffer || require("buffer").Buffer;

export const error_toast = () => {
    return toast.error("something went wrong, please try again later")
}

const getFileExtension = (filename) => {
    console.log({filename})
    return filename.split('.').pop();
};

const getFileName = (filename) => {
    const name = filename.split('.');
    return name[0];
};

const S3REGION = 'ap-south-1';
const S3ACCESSKEYID = "AKIARQALZPELOQDLPCOP";
const S3SECRETACCESSKEY = "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"


//TODO: It will support multiple file uploads for one event
export const useFileUpload = () => {

    const [isUploading, setIsUploading] = useState(false);
    const id = makeid_alpa_numeric(6)

    const uploadFileToS3 = async(path, file, bucket = "urn-assets") => {
        try {
            setIsUploading(true);
            const s3config = {
                bucketName: bucket,
                dirName: path,
                region: S3REGION,
                accessKeyId: S3ACCESSKEYID,
                secretAccessKey: S3SECRETACCESSKEY,
            }
            // @ts-ignore
            const ReactS3Client = new S3(s3config);
            const extension = getFileExtension(file.name);
            const newFileName = getFileName(file.name)+ '_' + id +'.' + extension;
            let res = undefined;
            await ReactS3Client.uploadFile(file, newFileName)
                .then(data => {
                    console.log('Uploaded', data);
                    toast.success("File uploaded successfully..");
                    res = data;
                    setIsUploading(false);
                })
                .catch(err => {
                    console.log('Failed', err);
                    setIsUploading(false)
                });
            return res;
        }catch (e) {
            toast.error("Something went wrong..")
            console.log("error", e);
            setIsUploading(false)
        }
    }
    return {isUploading, uploadFileToS3}
}

export const uploadFile = (path, file, bucket = "urn-assets") => {

    const s3config = {
        bucketName: bucket,
        dirName: path,
        region: S3REGION,
        accessKeyId: S3ACCESSKEYID,
        secretAccessKey: S3SECRETACCESSKEY,
    }

    try {
        // @ts-ignore
        const ReactS3Client = new S3(s3config);
        const id = makeid_alpa_numeric(6)
        const extension = getFileExtension(file.file.name);
        const newFileName = getFileName(file.file.name)+ '_' + id +'.' + extension;
        ReactS3Client.uploadFile(file.file, newFileName)
            .then(data => {
                console.log('Uploaded', data);
                toast.success("File uploaded successfully..")
            })
            .catch(err => {
                console.log('Failed', err);
            });

        return s3config.dirName + '/' + newFileName;

    } catch (e) {
        toast.error("Something went wrong..")
        console.log("error", e);
    }
}

export const upload_file = async (path, file, bucket = "urn-assets") => {
    const s3config = {
        bucketName: bucket,
        dirName: path,
        region: S3REGION,
        accessKeyId: S3ACCESSKEYID,
        secretAccessKey: S3SECRETACCESSKEY
    }

    try {
        const ReactS3Client = new S3(s3config);
        const extension = getFileExtension(file.name);
        const newFileName = getFileName(file.name)+ '.' + extension;
        const response = await ReactS3Client.uploadFile(file, newFileName).then(data => {
            console.log("file uploaded data",data)
        })
        if(response.status === 200) {
            return response
        } else {
            error_toast();
            return {}
        }

    } catch (e) {
        error_toast();
        console.log("error", e);
    }
}

export const uploadBlob = async(pathdir, filename, blob, bucket = "urn-assets") => {

    const s3config = {
        bucketName: bucket,
        dirName: pathdir,
        region: S3REGION,
        accessKeyId: S3ACCESSKEYID,
        secretAccessKey: S3SECRETACCESSKEY,
    }

    try {
        // @ts-ignore
        const ReactS3Client = new S3(s3config);
        return await ReactS3Client.uploadFile(blob, filename)
    } catch (e) {
        toast.error("Something went wrong..")
        console.log("error", e);
    }
}