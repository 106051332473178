import {observer} from "mobx-react-lite";
import React, {ReactNode, useEffect, useState} from "react"
import {useParams, useNavigate} from "react-router-dom";
import {useStore} from "../../helpers/helpers";
import {safe_get} from "../../report-visuals/report-utils";
import _ from "lodash"
import {remove_item_from_array, SubHeading, update_item_in_array} from "../../helpers/utils";
import {toast} from "react-toastify";
import {ProjectWorkEdit} from "./project-work-edit"
import {error_toast} from "../../helpers/file-upload";
import {useTranslation} from "react-i18next";
import {AddFunds, FundsCard} from "./funds";
import {AddContact, ContactCard} from "./contacts";
import {AddMedia, MediaCard} from "./media";
import {TimelineLog} from "../grievance-management";
import {
    BreadCrumbs,
    Carousel, DeleteButton,
    DeleteModal, EditButton,
    LoadingState,
    SideOverlay,
    ViewAttachment
} from "../../components";
import {IContact, IEvent, IFund, IMedia} from "./project-work-models";
import {useIsMobile} from "../../custom-hooks";
import moment from 'moment';
import {useQuery} from "@tanstack/react-query";
import {AddEvent, EventCard} from "./events";

type AddNewProps = {
    heading: string,
    title: string,
    onAddClick: (args) => void,
    children: ReactNode
}

const AddNew = (props: AddNewProps) => {

    const {heading, title, onAddClick} = props;

    return (
        <div className="flex flex-row justify-between items-center my-2">
            <div className="flex flex-row items-center px-2 ">
                {props.children}
                <SubHeading title={heading}/>
            </div>

            <button className="cursor-pointer" onClick={onAddClick}>
                <i className="fas fa-plus-circle text-purple-600 px-2 text-lg"/>
                <span>{title}</span>
            </button>
        </div>
    )
}

const PWCrumbs = (props) => {
    const {projectid} = useParams();
    const crumbs = [
        {name: "DashBoard", href: `/project/${projectid}`, current: false},
        {name: 'Project Works', href: '/project/' + projectid + '/project_works', current: false},
        {name: props.name, href: '#', current: true},
    ];
    return (
        <BreadCrumbs crumbs={crumbs}/>
    )
}

export const ProjectWorkDetails = observer((props: any) => {

    const isMobile = useIsMobile();
    const {fsdid} = useParams()
    const rootStore = useStore()
    const navigate = useNavigate();
    const {flexiStore, projectWorksStore} = rootStore
    const {t} = useTranslation("project_work");
    const {current_fsd, current_fsd_ready} = flexiStore

    const [loading, setLoading] = useState(false);
    const [activeFund, setActiveFund] = useState(IFund);
    const [activeEvent, setActiveEvent] = useState(IEvent);
    const [activeContact, setActiveContact] = useState(IContact);
    const [activeMedia, setActiveMedia] = useState(IMedia);
    const [projectDetails, setProjectDetails] = useState({
        summary: {},
        funds: [],
        contacts: [],
        media: [],
        date: [],
        events: [],
        timelineLogs: []
    })

    useEffect(() => {
        get_project_work_details();
    }, [])

    useEffect(() => {
        if (current_fsd_ready) {
            setProjectDetails({
                ...projectDetails,
                summary: safe_get(current_fsd, 'summary', {}),
                funds: safe_get(current_fsd.r1, "funds.spentbreakdown", []),
                contacts: safe_get(current_fsd.r1, "contacts", []),
                media: safe_get(current_fsd.r1, "richmedia", []),
                date: safe_get(current_fsd.r1, "dates", []),
                events: safe_get(current_fsd.r1, "events", []),
                timelineLogs: safe_get(current_fsd.r1, "timeline", [])
            })
        }
    }, [current_fsd, current_fsd_ready])

    const get_project_work_details = async () => {
        const res = await flexiStore.get_fsdata(fsdid);
        console.log(res)
        if (!res.ok) {
            throw new Error("Error")
        } else return res
    }

    const {isLoading, data, error, isError} = useQuery({
        queryKey: ['project-work-details', fsdid],
        queryFn: get_project_work_details,
        onError: err => {
            toast.error("Something went wrong, please try again")
        }
    })

    const onAddFundsModalCancel = () => {
        projectWorksStore.update_project_work_property("add_fund_modal", false);
    }
    const onAddDatesModalCancel = () => {
        projectWorksStore.update_project_work_property("add_date_modal", false);
    }

    const onAddContacstModalCancel = () => {
        projectWorksStore.update_project_work_property("add_contact_modal", false);
    }

    const onAddMediaModalCancel = () => {
        projectWorksStore.update_project_work_property("add_media_modal", false);
        projectWorksStore.update_project_work_property("edit_media_modal", false);
    }

    const onPWEditModalCancel = () => {
        projectWorksStore.update_project_work_property("edit_pw_modal", false);
        projectWorksStore.update_project_work_property("add_pw_modal", false);
    }

    const onTimelineModalCancel = () => {
        projectWorksStore.update_project_work_property("add_timeline_modal", false);
    }

    const editPW = () => {
        projectWorksStore.update_project_work_property("edit_pw_modal", true);
    }

    const deletePW = async () => {
        const res = await flexiStore.delete_fsd(current_fsd.id);
        if (res.status === 200) {
            projectWorksStore.update_project_work_property("delete_modal", false);
            navigate(-1);
        }
    }

    /****** Funds --- start *****/
    const addNewFund = (fund) => {
        const tmp = _.clone(current_fsd);
        const x = update_item_in_array(tmp["r1"]["funds"]["spentbreakdown"], "id", fund)
        tmp["r1"]["funds"]["spentbreakdown"] = x;
        flexiStore.update_current_fsd("r1", tmp["r1"])
        flexiStore.api_update_current_fsd();
    }

    const onFundDelete = async (id) => {
        const existing_funds = safe_get(current_fsd.r1, "funds.spentbreakdown", []);
        const updated_funds = remove_item_from_array(existing_funds, "id", id);
        setProjectDetails({...projectDetails, funds: updated_funds})
        // setFunds(updated_funds)
        const tmp = _.clone(current_fsd.r1)
        tmp["funds"]["spentbreakdown"] = updated_funds;
        flexiStore.update_current_fsd("r1", tmp)
        const res = await flexiStore.api_update_current_fsd();
        if (res.status === 200) {
            toast.success(t("fund_deleted")!)
        } else error_toast()
    }

    const onFundEdit = fund => {
        projectWorksStore.update_project_work_property("add_fund_modal", true);
        setActiveFund(fund);
    }
    /****** Funds --- end **********/

    /****** Contacts --- start *****/
    const addNewContact = async (contact) => {
        const tmp = _.clone(current_fsd);
        const x = update_item_in_array(tmp["r1"]["contacts"], "id", contact)
        tmp["r1"]["contacts"] = x;
        await flexiStore.update_current_fsd("r1", tmp["r1"])
        await flexiStore.api_update_current_fsd();
    }

    const onContactDelete = async (id) => {
        const existing_contacts = safe_get(current_fsd.r1, "contacts", []);
        const updated_values = remove_item_from_array(existing_contacts, "id", id);
        setProjectDetails({...projectDetails, contacts: updated_values})
        // setContacts(updated_values)
        const tmp = _.clone(current_fsd.r1)
        tmp["contacts"] = updated_values;
        flexiStore.update_current_fsd("r1", tmp)
        const res = await flexiStore.api_update_current_fsd();
        if (res.status === 200) {
            toast.success(t("contact_deleted")!)
        } else error_toast()
    }

    const onContactEdit = contact => {
        projectWorksStore.update_project_work_property("add_contact_modal", true);
        console.log(contact)
        setActiveContact(contact);
    }
    /****** Contacts --- end *****/

    /****** Media --- start *****/
    const onMediaDelete = async (id) => {
        const existing_media = safe_get(current_fsd.r1, "richmedia", []);
        const updated_values = remove_item_from_array(existing_media, "id", id);
        setProjectDetails({...projectDetails, media: updated_values})
        // setMedia(updated_values)
        const tmp = _.clone(current_fsd.r1)
        tmp["richmedia"] = updated_values;
        flexiStore.update_current_fsd("r1", tmp)
        const res = await flexiStore.api_update_current_fsd();
        if (res.status === 200) {
            toast.success(t("media_deleted")!)
        } else error_toast()
    }

    const onMediaEdit = media => {
        setActiveMedia(media);
        projectWorksStore.update_project_work_property("edit_media_modal", true);
    }
    /****** Media --- end *****/

    /****** Date --- starts *****/

    const onDateEdit = event => {
        projectWorksStore.update_project_work_property("add_date_modal", true);
        setActiveEvent(event);
    }

    const onDateDelete = async (id) => {
        const existing_events = safe_get(current_fsd.r1, "events", []);
        const updated_events = remove_item_from_array(existing_events, "id", id);
        setProjectDetails({...projectDetails, events: updated_events})
        const tmp = _.clone(current_fsd.r1)
        tmp["events"] = updated_events;
        flexiStore.update_current_fsd("r1", tmp)
        const res = await flexiStore.api_update_current_fsd();
        if (res.status === 200) {
            toast.success("Event Deleted"!)
        } else error_toast()
    }

    const addNewDate = (event) => {
        const tmp = _.clone(current_fsd);
        const x = update_item_in_array(safe_get(tmp["r1"], "events", []), "id", event)
        tmp["r1"]["events"] = x;
        flexiStore.update_current_fsd("r1", tmp["r1"])
        flexiStore.api_update_current_fsd();
    }

    const latitude = safe_get(projectDetails, "summary.latitude", 0)
    const longitude = safe_get(projectDetails, "summary.longitude", 0)
    return (
        <>
            <SideOverlay
                children={<AddFunds onAdd={addNewFund} fund={activeFund}/>}
                onClose={onAddFundsModalCancel}
                show={projectWorksStore.add_fund_modal}
                title={t("add_funds")}
            />

            <SideOverlay
                children={<AddContact onAdd={addNewContact} contact={activeContact}/>}
                onClose={onAddContacstModalCancel}
                show={projectWorksStore.add_contact_modal}
                title={t("add_contact")}
            />

            <SideOverlay
                children={<AddMedia
                    refreshMedia={(mediaList) => setProjectDetails({...projectDetails, media: mediaList})}
                    selected={activeMedia}/>}
                onClose={onAddMediaModalCancel}
                show={projectWorksStore.add_media_modal || projectWorksStore.edit_media_modal}
                title={t("add_media")}
            />

            <SideOverlay
                children={<AddEvent onAdd={addNewDate} event={activeEvent}/>}
                onClose={onAddDatesModalCancel}
                show={projectWorksStore.add_date_modal}
                title={"ADD DATE"}
            />

            <SideOverlay
                children={<ProjectWorkEdit onSave={() => get_project_work_details()}/>}
                onClose={onPWEditModalCancel}
                show={projectWorksStore.edit_pw_modal || projectWorksStore.add_pw_modal}
                title={t("project_works")}
            />

            {loading || isLoading && <LoadingState/>}
            {!isMobile ? <PWCrumbs name={safe_get(projectDetails, "summary.name", "Project Details")}/> : null}
            <div className='flex flex-wrap sm:flex-no-wrap px-4'>
                <div className='w-full lg:w-3/4 pr-4'>
                    <div className="flex justify-between">
                        <div
                            className='text-xl font-bold py-4'>{!_.isEmpty(current_fsd) ? current_fsd.summary['name'] : t("manage_project_works")}</div>
                        <div className='flex flex-row items-center gap-x-2'>
                            <DeleteButton
                                OnDeleteClickHandler={() => {
                                    projectWorksStore.update_project_work_property("delete_modal", true);
                                }}
                            />
                            <EditButton
                                OnEditClickHandler={editPW}
                            />
                        </div>
                    </div>
                    <div className="overflow-hidden rounded-lg shadow-lg">
                        {/*<img className="object-cover w-full max-h-96" src={cover_photo} alt="" loading={"lazy"}/>*/}
                        <div className="w-full max-h-[450px]" style={{width: "auto"}}>
                            {!loading && <ViewAttachment attachment={{
                                type: "image/jpg",
                                path: current_fsd?.summary["coverPhotoPath"]
                            }} label={false}
                            />}
                        </div>
                    </div>

                    <div className="flex flex-col pt-2">
                        <div className="text-black text-md font-bold">
                            Estimated Completion Date - {moment(projectDetails.summary["date"]).format('DD/MM/YYYY')}
                        </div>
                    </div>

                    <div className="py-4">
                        {/*<h4 className="font-semibold tracking-tight">{!_.isEmpty(current_fsd) ? current_fsd.summary['name'] : 'Project Name'}</h4>*/}
                        {latitude && longitude ?
                            <a href={`http://maps.google.com/maps?q=${latitude},${longitude}`} target={"_blank"}
                               className='flex items-center underline text-blue-500 cursor-pointer'>
                                <i className="fa-solid fa-location-dot"/>
                                <p className="leading-normal p-2">{!_.isEmpty(current_fsd) ? current_fsd.summary['location'] : 'Project Location'}</p>
                            </a> : null}
                        <div
                            className=''>{!_.isEmpty(current_fsd) ? current_fsd.summary['decription'] : 'Project Descripton'}</div>
                    </div>

                    <div className="rounded-lg shadow-md bg-white p-2 sm:p-4 my-2">
                        <AddNew
                            heading={"Media"}
                            title={t("add_media")}
                            onAddClick={e => {
                                projectWorksStore.update_project_work_property("add_media_modal", true);
                                setActiveMedia(IMedia)
                            }}
                        >
                            <i className="far fa-photo-video text-xl pr-4 text-purple-500"/>
                        </AddNew>
                        <div className="flex flex-col">
                            {
                                projectDetails.media && projectDetails.media.length > 0 ?
                                    <Carousel
                                        infinite={true}
                                        items={projectDetails.media.map((x) => {
                                            if (!x.id) return null;
                                            return <MediaCard key={x.id} media={x}
                                                              onMediaEdit={onMediaEdit}
                                                              onMediaDelete={onMediaDelete}/>
                                        })}/> : null
                            }
                        </div>

                    </div>
                    <div className="rounded-lg shadow-md bg-white p-2 sm:p-4 my-2">
                        <AddNew
                            heading={"Events"}
                            title={"Add Date"}
                            onAddClick={e => {
                                projectWorksStore.update_project_work_property("add_date_modal", true);
                                setActiveEvent(IEvent)
                            }}
                        >
                            <i className="fa-regular fa-calendar-pen text-xl pr-4 text-purple-500"/>
                        </AddNew>
                        <div className={"grid grid-cols-1 sm:grid-cols-3 gap-4"}>
                            {
                                projectDetails.events.length > 0 && projectDetails.events.map(event => <EventCard
                                    key={event.id} event={event} onEdit={onDateEdit}
                                    onDelete={onDateDelete}/>)
                            }
                        </div>
                    </div>
                    <div className="rounded-lg shadow-md bg-white p-2 sm:p-4 my-2">
                        <AddNew heading={"Funds"} title={t("add_fund")} onAddClick={e => {
                            projectWorksStore.update_project_work_property("add_fund_modal", true);
                            setActiveFund(IFund)
                        }}>
                            <i className="far fa-wallet text-xl pr-4 text-purple-500"/>
                        </AddNew>

                        <div className={"grid grid-cols-1 sm:grid-cols-3 gap-4"}>
                            {
                                projectDetails.funds.length > 0 && projectDetails.funds.map(fund => <FundsCard
                                    key={fund.id} fund={fund} onEdit={onFundEdit}
                                    onDelete={onFundDelete}/>)
                            }
                        </div>

                    </div>
                    <div className="rounded-lg shadow-md bg-white p-2 sm:p-4 my-2">
                        <AddNew heading={"Key Contacts"} title={t("add_contact")} onAddClick={e => {
                            projectWorksStore.update_project_work_property("add_contact_modal", true);
                            setActiveContact(IContact)
                        }}>
                            <i className="fas fa-id-card text-xl pr-4 text-purple-500"/>
                        </AddNew>

                        <div className={"grid grid-cols-1 sm:grid-cols-3 gap-4"}>
                            {
                                projectDetails.contacts.length > 0 && projectDetails.contacts.map(contact =>
                                    <ContactCard key={contact.id} contact={contact} onDelete={onContactDelete}
                                                 onEdit={onContactEdit}/>)
                            }
                        </div>
                    </div>
                    {projectWorksStore.isDeleteMode &&
                        <DeleteModal
                            onDeleteClick={deletePW}
                            onCancelClick={() => {
                                projectWorksStore.update_project_work_property("delete_modal", false);
                            }}
                            isOpen={projectWorksStore.isDeleteMode}
                            desc={t("want_to_delete")}
                        />
                    }
                </div>
                <div className='w-full lg:w-1/4 max-h-screen'>

                    <div className="rounded-lg shadow-md bg-white p-2 sm:p-4 my-2">
                        <div className="flex flex-row items-center px-2 ">
                            <i className="fas fa-clock text-xl pr-4 text-purple-500"/>
                            <SubHeading title={"Timeline"}/>
                        </div>
                        <div className="flex flex-col">
                            {/*{timelineLog && <TimelineComponent timelinelog={timelineLog} />}*/}
                            {projectDetails.timelineLogs &&
                                <TimelineLog timeline_log={projectDetails.timelineLogs} mode={"VERTICAL"}/>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
})