import React, {useEffect, useRef, useState} from 'react'
import {observer} from "mobx-react-lite";
import {useStore} from "../../helpers/helpers";
import {AudioPlayer} from "./audio-player";
import _ from "lodash"
import {toJS} from "mobx";
import {safe_get} from "../../report-visuals/report-utils";
import {Disclosure} from '@headlessui/react'
import {ChevronDownIcon} from '@heroicons/react/24/outline'
import {classNames} from "../../helpers/utils";
import {QuestionAndAnswerResponseView} from "./question-and-answer-view";
import { useTranslation } from 'react-i18next';
import {VoterCard} from "../manage-voters";
import {LoadingState} from "../../components";
import { RootStore } from '../../stores/root';

export const SurveyResponseView = observer(props => {
   
    const rootStore = useStore();
    const {surveyResponseStore, userStore} = useStore()
    const {user} = userStore
    const {activeSurveyResponse, activeSurveyResponseId} = surveyResponseStore
    const {sr} = activeSurveyResponse

    const [loading, setLoading] = useState(false);
    const {t} = useTranslation("survey_management");
    /*
    Adding for Chikamagalur Project.
    Krishna Kiran NIC should not know about audio and GPS collection. Block him from seeing that.
    */
    const blacklist_user_ids = [9343]

    const [qaComments, setQaComments] = useState('');

    const audioPlayer = useRef(null)

    const userPayload = {
        "id": user.id,
        "name": user.name,
        "phonenum": user.phonenum,
        "comments": qaComments
    }

    const enableUser = (user.role === "root" || user.role === "client" || user.role === "qaworker" || user.role === "surveyworker")

    const getSurveyResponseFromServer = async () => {
        setLoading(true)
        await surveyResponseStore.getActiveSurveyResponse()
        setLoading(false)
    }

    useEffect(() => {
        getSurveyResponseFromServer()
    }, [surveyResponseStore.activeSurveyResponseId]);

    const onQuestionClick = (time, question) => {
        if (audioPlayer.current != null) {
            audioPlayer.current.seek(time)
            surveyResponseStore.set_active_question(question)
        }
    }

    const SurveyResponseDetails = () => {
        if (!activeSurveyResponse) return null
        const {response, meta, voterref} = sr
        const responses = response['response']
        const {surveyref, nav} = meta
        const {questions} = surveyref

        const renderQuestionResponses = () => {
            const elements = []
            for (let ind = 0; ind < questions.length; ind++) {
                const q = questions[ind]
                const r = responses[ind]

                const matches = _.filter(nav, {"to": nav[ind + 1]["to"]})
                if (matches.length == 0) return
                const match = matches[0]
                let offset = match["ts"] - nav[0]["ts"]
                offset /= 1000

                // @ts-ignore
                elements.push(<QuestionAndAnswerResponseView qno={ind} question={q} seek={offset} response={r} onClickHandler={onQuestionClick}/>)
            }
            return (
                <div className='flex flex-col'>{elements}</div>
            )
        }

        return (
            <>
                {
                    (questions.length == responses.length) ? renderQuestionResponses() : null
                }
            </>
        )
    }

    const renderCurrentQAStatus = () => {
        let statuses = []
        if (Object.keys(sr).includes("qa")) {
            const {qa} = sr
            statuses = qa["status"]
        } else return false

        return <div className="flex flex-col mt-4 lg:w-1/2 w-full">
            {statuses ?
                statuses.map((x, index) => {
                    const {user, status, ts} = x
                    const comment = safe_get(x, "user.comments", "")
                    const {name, phonenum} = user
                    let ts_date = new Date(ts)

                    return (
                        <>
                            <div className="flex flex-row grid grid-cols-5 gap-2">
                                <div>{name}</div>
                                <div>{status ? "Valid" : "Invalid"}</div>
                                {/*<div>{status }</div>*/}
                                <div>{ts_date.toDateString()}</div>
                                <div>{ts_date.toLocaleTimeString()}</div>
                                <div>{comment}</div>
                            </div>
                        </>

                    )
                }) : <div>{t("not_validated_yet")}...</div>
            }
        </div>
    }

    const pushQAStatus = (clientid, status, user, ts) => {

        const {qa} = sr
        if (!Object.keys(qa).includes("status")) {
            qa["status"] = []
        }
        qa["status"].push({status, user, ts})
    }

    const actionMarkQAStatus = () => {
        return (
            <div className="flex flex-col mt-4 lg:w-1/3 w-full">
                <div className="text-md font-bold text-indigo-500">{t("mark_quality_validation")}</div>

                <div className="flex flex-col justify-between">
                    <label className="pb-2 font-bold">{t("reason")}: </label>
                    <textarea className="p-2" value={qaComments} onChange={e => {
                        setQaComments(e.target.value)
                    }}/>
                </div>

                <div className="grid grid-cols-2 gap-2 lg:grid-cols-4 my-2">
                    <button onClick={async () => {
                        await surveyResponseStore.survey_qa_report(activeSurveyResponseId, true, userPayload, Date.now())
                        pushQAStatus(activeSurveyResponseId, true, userPayload, Date.now())
                        setQaComments("")
                    }}
                            className={classNames(qaComments == "" ? "pointer-events-none opacity-50" : "pointer-events-auto", "rounded-lg shadow-lg bg-green-600 p-2")}>
                        <div className="text-white">{t("valid")}</div>
                    </button>

                    <button onClick={async () => {
                        await surveyResponseStore.survey_qa_report(activeSurveyResponseId, false, userPayload, Date.now())
                        pushQAStatus(activeSurveyResponseId, false, userPayload, Date.now())
                        setQaComments("")
                    }}
                            className={classNames(qaComments == "" ? "pointer-events-none opacity-50" : "pointer-events-auto", "rounded-lg shadow-lg bg-red-600 p-2")}>
                        <div className="text-white">{t("invalid")}</div>
                    </button>

                    {/*<button onClick={async () => {*/}
                    {/*    await surveyResponseStore.survey_qa_report(activeSurveyResponseId, "onhold", userPayload, Date.now())*/}
                    {/*    pushQAStatus(activeSurveyResponseId, "onhold", userPayload, Date.now())*/}
                    {/*    setQaComments("")*/}
                    {/*}}*/}
                    {/*        className={classNames(qaComments == "" ? "pointer-events-none opacity-50" : "pointer-events-auto", "rounded-lg shadow-lg bg-yellow-300 p-2")}>*/}
                    {/*    <div className="text-white">On Hold</div>*/}
                    {/*</button>*/}
                </div>

            </div>
        )
    }

    const question_and_answer_accordion = () => {
        if (!activeSurveyResponse) return null;
        const {voterref} = sr;
         return (
            <div className="bg-gray-50">
                <div className="mx-auto p-4">
                    <dl className="divide-y divide-gray-200">
                        <Disclosure as="div">
                            {({open}) => (
                                <>
                                    <dt className="text-lg">
                                        <Disclosure.Button
                                            className="text-left w-full flex justify-between items-center text-gray-400">
                                                        <span
                                                            className="block text-base font-medium text-indigo-500 font-bold uppercase">{t("question_answers")}
                                                            </span>
                                            <ChevronDownIcon
                                                className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                                                aria-hidden="true"
                                            />
                                        </Disclosure.Button>
                                    </dt>
                                    <Disclosure.Panel as="dd" className="mt-2">
                                       <div className={"grid grid-cols-4 gap-2"}>
                                           {(!_.isEmpty(voterref)) && <VoterCard voter={{profile: voterref}}/>}
                                       </div>
                                        {<SurveyResponseDetails/>}
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>
                    </dl>
                </div>
            </div>
        )
    }

    const surveyor_info = () => {
        return (
            <div className="bg-gray-50">
                <div className="mx-auto p-4">
                    <dl className="divide-y divide-gray-200">
                        <Disclosure as="div">
                            {({open}) => (
                                <>
                                    <dt className="text-lg">
                                        <Disclosure.Button
                                            className="text-left w-full flex justify-between items-center text-gray-400">
                                            <span
                                                className="block text-base font-medium text-indigo-500 font-bold uppercase">{t("surveyor_info")}</span>
                                            <ChevronDownIcon
                                                className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                                                aria-hidden="true"
                                            />
                                        </Disclosure.Button>
                                    </dt>
                                    <Disclosure.Panel as="dd" className="mt-2">
                                        <p><b>{t("user_id")}:</b> {sr.userid}</p>
                                        <p><b>{t("name")}:</b> {sr.username}</p>
                                        <p><b>{t("phone")}:</b> {sr.phonenum}</p>
                                        <p><b>{t("survey_date")}:</b> {sr.surveydate}</p>
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>
                    </dl>
                </div>
            </div>
        )
    }

    if (blacklist_user_ids.includes(toJS(user)['id'])) {
        return (
            <div className=''>
                {loading ? <LoadingState/> : null}
                <label className="block text-lg font-medium text-indigo-500 font-bold uppercase">{t("survey_details")}
                    ({surveyResponseStore.activeSurveyResponseId})</label>
                {enableUser ?
                    <>
                        {activeSurveyResponse["sr"] ? <>
                            {question_and_answer_accordion()}
                            {/*{surveyor_info()}*/}
                        </> : null}
                    </>
                    : null}
            </div>
        )
    }

    return (
        <div className=''>
            {loading ? <LoadingState/> : null}
            <label className="block text-lg font-medium text-indigo-500 font-bold uppercase">{t("survey_details")}
                ({surveyResponseStore.activeSurveyResponseId})</label>
            {enableUser ?
                <>
                    {activeSurveyResponse["s3uri"] ? <AudioPlayer data={activeSurveyResponse} ref={audioPlayer}/> :
                        <div className="p-4">{t("no_audio_available")}</div>}
                    {activeSurveyResponse["sr"] ? <>
                        {question_and_answer_accordion()}
                        {/*{surveyor_info()}*/}
                        <label className="block text-base font-medium text-indigo-500 font-bold uppercase">{t("qA_check")}</label>
                        {renderCurrentQAStatus()}
                    </> : null}

                    {actionMarkQAStatus()}
                </>
                : null}
        </div>
    )
})