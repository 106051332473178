import React from "react";

export const Tables = ({table}) => {
    if (!table) return <p>Data not available</p>
    return (
        <table className="m-3 fsd-caste-details mt-4 border-2 border-black">
            {table.map((row, i) => {
                return (
                    <thead key={i}>
                    <tr>
                        {row && row.length > 0 && row.map((col, j) => {
                            return j === 0 || col.class === "header col" ?
                                <th key={j} className="font-bold bg-gray-200 text-left first">
                                    {col.value}
                                </th>
                            :
                                <td key={j} className="text-right caste_value border-2 border-black">
                                    {col.type === "value" ? col.value.toFixed(2) : col.value}
                                </td>
                            ;
                        })}
                    </tr>
                    </thead>
                );
            })}
        </table>
    );
};