import {observer} from "mobx-react-lite";
import React, {useEffect, useState} from "react";
import {useStore} from "../../../../helpers/helpers";
import {safe_get} from "../../../../report-visuals/report-utils";
import {cooked_grader, grader} from "../booth-reports-utils";
import {LeafletMap} from "../../../../components/LeafletMap";
import _ from "lodash";

import {useCurrentFSD} from "../../../../custom-hooks/useCurrentFSD";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import {toJS} from "mobx";
import {BoothsByGrade} from "./BoothsByGrade";
import { useTranslation } from "react-i18next";

const THREAT = {'display': 'THREAT', 'fgcol': '#fff', 'bgcol': '#5B2C6F'};
const STRONG = {'display': 'STRONG', 'fgcol': '#fff', 'bgcol': '#1E8449'};
const AVERAGE = {'display': 'AVERAGE', 'fgcol': '#fff', 'bgcol': '#3498DB'};
const WEAK = {'display': 'WEAK', 'fgcol': '#fff', 'bgcol': '#B03A2E'};
const OPPORTUNITY = {'display': 'OPPORTUNITY', 'fgcol': '#fff', 'bgcol': '#D4AC0D'};
const INPROGRESS = {'display': 'IN PROGRESS', 'fgcol': '#fff', 'bgcol': '#CCD1D1'};

export const AnalyticalDashBoard = observer((props: any) => {

    const {flexiStore} = useStore();
    const config = useCurrentFSD();
    const {
        booth_details,
        ac_name,
        title,
        booth_key_prefix,
        filter_booths,
        filter_booths_whitelist,
        booth_report_maps,
        show_swot,
        header,
        hide_maps
    } = config;


    const acname = ac_name ? ac_name : title;
    return (
        <>
            {
                flexiStore.current_fsd.r3 && Object.keys(booth_details).sort().map(booth_key => {
                    const {left, name, booth, right} = booth_details[booth_key]
                    const gp = safe_get(booth_details[booth_key], "gp", "");
                    //const ds = group_by_booth[`${booth_key_prefix}${booth_key}`]
                    let ds = flexiStore.current_fsd.r6[`${booth_key_prefix}${booth_key}`]

                    if (!ds) return null;

                    if (filter_booths) {
                        const check_filter_key = booth_key.replace(booth_key_prefix, '');
                        if (!filter_booths_whitelist.includes(check_filter_key)) return null;
                    }

                    // For grading
                    const {display, fgcol, bgcol} = grading(ds);
                    const maps = safe_get(booth_report_maps, booth_key, {})
                    const hasmaps = Object.keys(maps).length > 0
                    let geojson = safe_get(maps, 'geojson', {});
                    const bounds = safe_get(maps, 'bounds', [])
                    const {t} = useTranslation("booth_bank");

                    return (
                        <>
                            <div className='page-container bg-white mx-auto mb-4'>
                                <div
                                    className={`grid grid-cols-3 gap-4 bg-blue-100 text-center font-bold text-xl`}>
                                    <div className='flex flex-col items-center'>
                                        <div className='ml-2 py-4'>{acname}<br/>
                                            <span>{left}</span> <br/>
                                            {gp && <span>{t("gp")}: {gp}</span>}
                                        </div>
                                    </div>

                                    <div
                                        className='ml-2 py-4 flex items-center justify-center'>{right}</div>
                                    {show_swot && <div
                                        className='text-center text-white py-2 ml-16 flex justify-center items-center'
                                        style={{backgroundColor: bgcol, color: fgcol}}>{display}</div>}
                                </div>
                                {/*<div className='text-center bg-gray-100 py-1'>{name}</div>*/}
                                <div className='text-center bg-gray-100 py-1' dangerouslySetInnerHTML={{__html: name}}/>
                                {!hide_maps && <div className='text-center  pb-1' style={{
                                    fontFamily: "sans-serif",
                                    textAlign: "center",
                                    height: "8cm"
                                }}>
                                    {hasmaps ? (
                                        <LeafletMap bounds={bounds} geojson={geojson} map_height={"8cm"} mode="leaflet"/>
                                    ) : null}
                                </div>}
                                {header && <div className="font-bold text-lg uppercase px-4 pt-2">{header} :-</div>}

                                <>
                                    <ResponsiveMasonry
                                        columnsCountBreakPoints={{350: 1, 750: 2, 1200: 2}}
                                        className="ml-8 px-6">
                                        <Masonry gutter={"10px"}>
                                            <ADTable title={"Assembly 2018"} rows={ds['a2018']} headers={['Party','Voters', 'Voters%']}/>
                                            <ADTable title={"Parliament 2019"} rows={ds['p2019']} headers={['Party','Voters', 'Voters%']}/>
                                            <ADTable title={"Assembly 2013"} rows={ds['a2013']} headers={['Party','Voters', 'Voters%']}/>
                                            <ADTable title={"Parliament 2014"} rows={ds['p2014']} headers={['Party','Voters', 'Voters%']}/>
                                            <ADTable title={"Caste"} rows={ds['caste']} headers={['Caste','Voters', 'Voters%']}/>
                                            <ADTable title={"Voters Data"} rows={ds['vd']} headers={['Title','Voters', 'Voters%']}/>
                                        </Masonry>
                                    </ResponsiveMasonry>
                                </>
                            </div>
                        </>
                    )
                })
            }
        </>
    )
})


const ADTable = (props) => {
    const {title, headers, rows} = props

    return (
        <>
            <div className='font-bold text-lg text-purple-600 pt-2'>{title}</div>
            <table className='text-sm w-full mt-0 mb-0 py-1'>
                <tr className='border-t-2 border-b-2 border-gray-400 font-bold'>
                    <td className='text-left w-1/2'>{headers[0]}</td>
                    <td className='text-right w-1/4'>{headers[1]}</td>
                    <td className='text-right w-1/4'>{headers[2]}</td>
                </tr>
                {rows.map((row, index) => {
                    return (
                        <tr className=''>
                            <td className='text-left w-1/2' dangerouslySetInnerHTML={{__html: row[0]}}/>
                            <td className='text-right w-1/4'>{row[1]}</td>
                            <td className='text-right w-1/4'>{Math.round(row[2]*10000)/100}%</td>
                        </tr>
                    )
                })}
            </table>
        </>
    )
}

export const grading = (ds) => {
    try{
        const transformed = ds["a2018"]
        const dict = {}

        const data = [];

        transformed.map(x=>{
            data.push({"party":x[0], "votes":  x[2]})
            // dict['party'] = x[0]
            // dict['votes'] = x[2]
        })

        if(data.length <= 0) return INPROGRESS;

        console.log(data)

        data.sort(function (a, b) {
            return b['votes'] - a['votes'];
        })
        const our_party = 'INC'
        const key_to_extract = 'party'

        const keys_extracted = data.map(x=>x["party"])

        let our_value = 0
        try {
            our_value = _.find(data, o => {
                return o['party'] === our_party
            })
            our_value = our_value["votes"]
        } catch (e) {
            //our key itself is not there!!!
            //Discovered in Mar 2022 - Arsikere Project
            //When our presence itself is not there, we will return as Weak
            return WEAK
        }

        const our_index = keys_extracted.indexOf(our_party)
        //const our_index = _.findIndex(keys_extracted, our_party)
        const opp_index = (our_index == 0) ? 1 : 0
        const opp_key = keys_extracted[opp_index]
        let opp_value = _.find(data, o => {
            return o[key_to_extract] === opp_key
        })
        opp_value = opp_value["votes"]

        const {strength_th, weak_th, delta_th} = {strength_th: 0.5, weak_th:0.2, delta_th:0.01}
        const diff = (our_value - opp_value);

        //if (opp_value >= 0.50) return WEAK;

        if (our_value >= strength_th) {
            if (diff <= delta_th) return AVERAGE;
            return STRONG;
        }
        if (our_value < weak_th) return WEAK;

        // const diff = (opp_value - our_value);
        if ((diff >= 0) && (diff < delta_th)) return AVERAGE;
        if (our_value > opp_value) return OPPORTUNITY;
        return THREAT;
    }catch (e) {
        return INPROGRESS;
    }
}