import {observer} from "mobx-react-lite";
import React, {useEffect, useState} from "react";
import {useStore} from "../../helpers/helpers";
import 'react-dropdown-tree-select/dist/styles.css'
import {safe_get} from "../../report-visuals/report-utils";
import {toJS} from "mobx";
import _ from "lodash";
import {useTranslation} from "react-i18next";

export const SurveyPlanning = observer((props: any) => {
        const {projectStore, flexiStore} = useStore()
        const [loaded, set_loaded] = useState(false)
        const [total_booths, set_total_booths] = useState(0)
        const [hierarchy, set_hierarchy] = useState({})
        const [booth_details, set_booth_details] = useState({})
        const [t]=useTranslation("field_payment");

        const load_data = async () => {
            const ac_props = projectStore.getProjectProperty('ac_props')
            await flexiStore.load_s3_json_resource('urn-fsd', safe_get(ac_props, 'hierarchy', ''), '')
            set_hierarchy(_.clone(toJS(flexiStore.external_resource)))

            let total = 0;
            Object.keys(toJS(flexiStore.external_resource)).map((region) => {
                total += flexiStore.external_resource[region].length
            })
            set_total_booths(total)

            await flexiStore.load_s3_json_resource('urn-fsd', safe_get(ac_props, 'poll_booths', ''), '')
            set_booth_details(_.clone(toJS(flexiStore.external_resource)))

            set_loaded(true)
        }


        useEffect(() => {
            load_data()
        }, [])

        if (!loaded) return (
            <div className='italic'>{t("configure")}.</div>
        )

        const BoothCount = (innerprops) => {
            return (
                <>
                    <div className='font-bold text-lg py-2 text-gray-600'>{t("booths_by_region")}</div>
                    <table className="divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                        <tr>
                            <th scope="col" className="py-3.5 pl-6 pr-3 text-left text-sm font-semibold text-gray-900">
                                {t("region")}
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                {t("booths")}
                            </th>
                        </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                        {Object.keys(hierarchy).map((region, index) => {
                            const booths = hierarchy[region]
                            const booth_count = booths.length
                            //set_total(booth_count + total)
                            return (
                                <tr key={`${region}${index}.map.iterator`}>
                                    <td className="whitespace-nowrap py-4 pl-6 pr-3 text-sm font-medium text-gray-900">{region}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500  text-right">{booth_count}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                        <tfoot className="bg-gray-50">
                        <tr key={`total.map.iterator`}>
                            <td className="whitespace-nowrap py-4 pl-6 pr-3 text-sm font-bold font-medium text-gray-900">{t("total")}</td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm font-bold text-gray-500 text-right">{total_booths}</td>
                        </tr>
                        </tfoot>
                    </table>
                </>
            )
        }

        // const BoothDetails = (innerprops) => {
        //     const tableRef = useRef(null);
        //     const {onDownload} = useDownloadExcel({
        //         currentTableRef: tableRef.current,
        //         filename: 'Booth Details',
        //         sheet: 'Booth Details'
        //     })
        //
        //     return (
        //         <>
        //             <div className='flex flex-row items-center'>
        //                 <div className='font-bold text-lg py-2 text-gray-600'>Booth Details</div>
        //                 <div className='cursor-pointer mx-4' onClick={onDownload} title='Download Booth Details'>
        //                     <i className="fa-solid fa-download font-lg text-blue-500"/>
        //                 </div>
        //             </div>
        //
        {/*            <div className=''>*/}
        {/*                <table className="bg-white divide-y divide-gray-300" ref={tableRef}>*/}
        {/*                    <tr className="bg-gray-50">*/}
        {/*                        <th scope="col" className="py-3.5 pl-6 pr-3 text-left text-sm font-semibold text-gray-900">*/}
        {/*                            Region*/}
        {/*                        </th>*/}
        {/*                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">*/}
        //                             Booth #
        //                         </th>
        //                         <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
        //                             Village
        //                         </th>
        //                         <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
        //                             Booth Name
        {/*                        </th>*/}
        {/*                    </tr>*/}
        {/*                    {Object.keys(hierarchy).map((region, index) => {*/}
        {/*                        const booths = hierarchy[region]*/}

        {/*                        return (*/}
        {/*                            <>*/}
        {/*                                {*/}
        {/*                                    booths.map((booth, index) => {*/}
        {/*                                        if (booth === 'Booth #000') return null;*/}
        //                                         const b3 = booth.replace('Booth #', '')
        //                                         const name = safe_get(booth_details, `${b3}.name`, '')
        {/*                                        const village = get_booth_village(name)*/}

        {/*                                        return (*/}
        {/*                                            <tr key={`${region}${index}.details.map.iterator`}>*/}
        //                                                 <td className="whitespace-nowrap py-4 pl-6 pr-3 text-sm font-medium text-gray-900">{region}</td>
        //                                                 <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ">{booth}</td>
        //                                                 <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ">{village}</td>
        //                                                 <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ">{name}</td>
        //                                             </tr>
        //                                         )
        //                                     })
        //                                 }
        //                             </>)
        //                     })}
        //                 </table>
        //             </div>
        //         </>
        //     )
        // }

        return (
            <>
                <div className='p-4'>
                    <BoothCount/>
                    {/*<BoothDetails/>*/}
                </div>
            </>
        )
    }
)