import React, {useEffect, useState} from "react"
import TrieSearch from 'trie-search';

export const useTrieSearch = ({data, searchQuery, searchTearms, indexField}) => {

    const trieStore = new TrieSearch(searchTearms, {
        splitOnRegEx: /[\s\/()]/,
        min: 2,
        ignoreCase: true,
        indexField: indexField,
    })

    const [filteredProjects, setFilteredProjects] = useState([]);

    useEffect(() => {
        trieStore.addAll(data);
        if (searchQuery.trim() === '') {
            setFilteredProjects(data);
        } else {
            setFilteredProjects(trieStore.get(searchQuery));
        }
    }, [data, searchQuery])

    return filteredProjects
}