import {observer} from "mobx-react-lite";
import React from 'react'
import {htmlValidation, makeid_alpha} from "../../helpers/utils";
import StarRatingComponent from 'react-star-rating-component';

export const C3StarRating = observer((props: any) => {

    const {value, onStarClickHandler, isEditable} = props

    const onStarClickHalfStar = (nextValue, prevValue, name, e) => {
        htmlValidation(e)
        if (isEditable) {
            const xPos = (e.pageX - e.currentTarget.getBoundingClientRect().left) / e.currentTarget.offsetWidth;
            if (xPos <= 0.5) {
                nextValue -= 0.5;
            }
            onStarClickHandler(nextValue)
        }
    }

    return <>
        <div style={{fontSize: 24}}>
            <StarRatingComponent
                name="rating"
                starColor="#ffb400"
                emptyStarColor="#ffb400"
                value={value}
                onStarClick={onStarClickHalfStar}
                renderStarIcon={(index, value) => {
                    return (
                        <span>
                            <i
                                key={makeid_alpha(6)}
                                className={index <= value ? 'fas fa-star' : 'far fa-star'}/>
                        </span>
                    );
                }}
                renderStarIconHalf={() => {
                    return <span key={makeid_alpha(6)}><i className="fas fa-star-half-stroke"/></span>
                }}/>
        </div>
    </>
})

