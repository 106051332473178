import { Switch } from "@headlessui/react";
import { classNames, useStore } from "../../../helpers";
import { LeafletACMap, LoadingState } from "../../../components";
import React, { useEffect, useMemo, useState } from "react";
import { toJS } from "mobx";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router";

export const FieldTeamReportMaps = observer(() => {
  const { projectid } = useParams();
  const [enabled, setEnabled] = useState(false);
  const { flexiStore, projectStore, surveyResponseStore } = useStore();
  const { allSurveyResponses } = surveyResponseStore;
  const { t } = useTranslation("survey_management");
  const data = useMemo(() => toJS(allSurveyResponses), [allSurveyResponses]);

  const map_config = {
    height: 850,
    show_booth_numbers: true,
  };

  useEffect(() => {
    const getProps = async () => {
      const path = await projectStore.getProjectProperty("ac_map", "");
      await flexiStore.load_s3_json_resource("urn-fsd", path, "");
    };
    getProps();
  }, [projectid]);

  if (flexiStore.loading_external_resource) return <LoadingState />;

  return (
    <>
      <div className="flex items-center py-2">
        <label className="font-bold">{t("show_map")} :</label>
        <Switch
          checked={enabled}
          onChange={setEnabled}
          className={classNames(
            enabled ? "bg-indigo-600" : "bg-gray-200",
            "mx-3 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          )}
        >
          <span className="sr-only">{t("use_setting")}</span>
          <span
            aria-hidden="true"
            className={classNames(
              enabled ? "translate-x-5" : "translate-x-0",
              "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
            )}
          />
        </Switch>
      </div>
      {/*{enabled ? <Maps isMarkerShown={true} data={data}/> : null}*/}
      {enabled ? (
        <LeafletACMap
          geojson={flexiStore.external_resource}
          config={map_config}
          data={data}
        />
      ) : null}
    </>
  );
});
