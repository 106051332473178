import {observer} from "mobx-react-lite";
import React, {useState} from "react";
import 'react-dropdown-tree-select/dist/styles.css'



export const FSDMore = observer((props: any) => {

        return (
            <>
                <div className=''>
                   
                   More

                </div>
            </>
        )
    }
)