import React, {useEffect, useState} from "react"
import {observer} from "mobx-react-lite";
import {useStore} from "../../helpers/helpers";
import data from "./ux_dasbhoard_payload.json"
import {classNames, htmlValidation} from "../../helpers/utils";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import {useTrieSearch, useHasAnalyticalPermission} from "../../custom-hooks";
import {LoadingState} from "../../components";
import {AllSurveys, RenderFilters, SurveySummary} from "../../features/survey-management/survey-summary";
import {AnalyticalSummary, SurveyActionView, SurveyResponseView} from "../../features/survey-management";

type projectType = {
    ac3: string,
    acname: string,
    projname: string,
    projid: number,
    esindex: string,
    survey_id: number
}

export const RJAnalyticalSummary = observer((props: any) => {

    const rootStore = useStore();
    const {surveyResponseStore} = rootStore;
    const {analytical_data, new_filters, activeSurveyResponseId, allSurveyResponses, allSurveyResponsesSummary,} = surveyResponseStore;
    const [t] = useTranslation(["survey_management", "new_voter_registration"]);
    const [project, setProject] = useState<projectType>(data[0]);
    const hasPermission = useHasAnalyticalPermission();

    //TODO: Trie Search
    const [searchQuery, setSearchQuery] = useState<string>("")
    const searchTearms = ['acname', 'projname', 'ac3']
    const indexField = 'ac3'

    const filtered = useTrieSearch({data, searchQuery, searchTearms, indexField})

    const get_field_team_report = async () => {
        const payload = {
            "projectid": project.projid,
            "surveyid": project.survey_id,
            "search": "",
            "only_highlights": false,
        }
        await surveyResponseStore.getFieldReport(payload)
    }

    useEffect(() => {
        get_field_team_report();
    }, [project.projid,new_filters ])

    const projectClickHandler = (evt, item) => {
        htmlValidation(evt);
        setProject(item);
    }

    return (

        <section className="flex flex-row max-h-[100vh]">
            <div className={"flex flex-col gap-y-2 w-full md:w-1/4 lg:w-1/4 py-2 border-r-2 border-gray-300 px-2 overflow-hidden"}>
                <input
                    value={searchQuery}
                    onChange={(evt) => {
                        setSearchQuery(evt.target.value);
                    }}
                    className="sticky top-0 border-2 border-gray-300 bg-white rounded-lg text-sm focus:outline-none h-12 w-1/2 sm:w-full sm:h-16 p-4 "
                    type="search" name="search" placeholder="Search projects"
                />
                <div className={"grid grid-cols-1 gap-2 overflow-auto"}>
                    {filtered.map(item => {
                        const isActive = project.projid === item.projid
                        return (
                            <div
                                key={item.projid}
                                className={classNames(isActive ? "bg-yellow-100" :" bg-gray-100", "p-2  border border-1 border-purple-600 cursor-pointer flexBoxRow")}
                                onClick={e => projectClickHandler(e, item)}
                            >
                                <p className={"font-bold text-purple-600 text-lg"}>{item.projname}</p>
                                {isActive ?
                                    <i className={"fas fa-check-circle text-green-600"}/> : null}
                            </div>
                        )
                    })}
                </div>

            </div>
            <div className={"w-full md:w-3/4 lg:w-3/4 px-2 overflow-x-hidden"}>
                <div className="sticky top-0 p-4 bg-white rounded-md shadow-lg">
                    <p className={"text-2xl font-bold text-purple-600 "}>{project.projname}</p>
                    <RenderFilters />
                </div>

                <div className='px-4'>

                    {surveyResponseStore.loading ? <LoadingState/> : null}
                    {
                        allSurveyResponses ?
                            <>
                                {analytical_data && hasPermission && <AnalyticalSummary/>}
                                {!_.isEmpty(allSurveyResponsesSummary) && <SurveySummary/>}
                                {hasPermission && <AllSurveys/>}
                                {activeSurveyResponseId ? <SurveyResponseView/> : null}
                                <SurveyActionView/>
                            </>
                            : <div
                                className="font-bold p-4 text-2xl">{t("surveys_not_found", {ns: "new_voter_registration"})}....</div>
                    }
                </div>
            </div>
        </section>


    )
})