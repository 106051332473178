import React, {useEffect} from 'react';
import {observer} from "mobx-react-lite";
import {input_style, label_style} from "../../beneficiary-management";
import {FormButtons} from "../../../components";
import {flex_row} from "../beneficiary-schemes";
import {useForm} from "react-hook-form";
import {useStore} from "../../../helpers/helpers";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {safe_get} from "../../../report-visuals/report-utils";




const AddBeneficiaryPriority = observer((props: any) => {

    const { register, handleSubmit, control, setValue, formState: { errors } } = useForm();
    const {beneficiaryStore} = useStore();
    const {projectid} = useParams();
    const {activePriority} = beneficiaryStore;
    const queryClient = useQueryClient();
    const priorityId = safe_get(activePriority,"id", null)

    useEffect(() => {
        if (priorityId) {
            setValue("name", safe_get(activePriority?.priority, "name", ""));
            setValue("type", safe_get(activePriority?.priority, "type", ""));
            setValue("display1", safe_get(activePriority?.priority, "display1", ""));
            setValue("display2", safe_get(activePriority?.priority, "display2", ""));
            setValue("default_display", safe_get(activePriority?.priority, "default_display", ""));
        }
    }, [activePriority, priorityId, setValue]);


    
    const {mutate} = useMutation({
        mutationFn: async (data: any) => {
            const payload = {
                projectid: parseInt(projectid),
                priority: data,
                more: {}
            }
            await beneficiaryStore.add_beneficiary_priority(payload)
        },
        onSuccess: async () => {
            setTimeout(async () => {
                await queryClient.invalidateQueries(["beneficiaray-priorities", projectid])
            }, 1000);
            toast.success("Priority added Successfuly")
            resetModal();
        },
        onError: async() => {
            toast.error("Something went wrong");
        }
    });

    const editPriority = async (payload: any) => {
        return await beneficiaryStore.edit_beneficiary_priority(payload);
    }

    const { mutate: editMutate } = useMutation({
        mutationFn: editPriority,
        onSuccess: async () => {
            setTimeout(async () => {
                await queryClient.invalidateQueries(["beneficiaray-schemes", projectid])
            }, 1000);
            toast.success("Updated Successfully")
            resetModal();
        },
        onError: error => {
            toast.error("Something went wrong")
        }
    })



    const onSubmit = async (data) => {
           const final = {...data};

        if (priorityId) {
            editMutate({
                ...activePriority, priority: {
                    ...activePriority.priority,
                    name: data.name,
                    type: data.type,
                    display1: data.display1,
                    display2: data.display2,
                    default_display: data.default_display
                }
            });
        } else {
            mutate(final);
        }
   }

    const resetModal = () => {
        beneficiaryStore.setAddPriorities(false);
        beneficiaryStore.setActivePriority(null);

    }


    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className="w-full h-screen overflow-y-auto flex flex-col gap-y-4 ">
                <div className={flex_row}>
                    <label htmlFor="name" className={label_style}>Name</label>
                    <input type="text" id="name" {...register('name', {required: true})}
                           className={input_style}/>
                    {errors.name && <span className="text-red-500 text-sm">Name is required</span>}
                </div>

                <div className={flex_row}>
                    <label htmlFor="type" className={label_style}>Type</label>
                    <input type="text" id="type" {...register('type', {required: true})}
                           className={input_style}/>
                    {errors.type && <span className="text-red-500 text-sm">Type is required</span>}
                </div>

                <div className={flex_row}>
                    <label htmlFor="display1" className={label_style}>Display 1</label>
                    <input type="text" id="display1" {...register('display1')}
                           className={input_style}/>
                </div>

                <div className={flex_row}>
                    <label htmlFor="display2" className={label_style}>Display 2</label>
                    <input type="text" id="display2" {...register('display2')}
                           className={input_style}/>
                </div>

                <div className="flex flex-col">
                    <label className="label_style">Default Display</label>
                    <div className="flex pt-4 pb-4">
                        <label className="inline-flex items-center mt-1 mr-4">
                            <input
                                type="radio"
                                id="radio1"
                                value="true"
                                {...register('default_display', {required: true})}
                                className="form-radio h-4 w-4 text-indigo-600"
                            />
                            <span className="ml-2 text-gray-700">True</span>
                        </label>
                        <label className="inline-flex items-center mt-1 ml-4">
                            <input
                                type="radio"
                                id="radio2"
                                value="false"
                                {...register('default_display', {required: true})}
                                className="form-radio h-4 w-4 text-indigo-600"
                            />
                            <span className="ml-2 text-gray-700">False</span>
                        </label>
                    </div>
                </div>

                <FormButtons
                    onSave={() => {
                    }}
                    onCancel={resetModal}
                    type={"submit"}
                />
            </form>
        </>
    );
});

export default AddBeneficiaryPriority;