import {useEffect, useState} from "react";
import TrieSearch from 'trie-search';

//TODO: This hook is used to filter the user based on search query

export const useFsdUsersSearch = ({users, searchQuery} : any) => {

    const [trieStore, setTrieStore] = useState(null);
    const [filteredUsers, setFilteredUsers] = useState([]);

    useEffect(() => {
        const newTs = new TrieSearch(['id', 'name', 'phonenum', 'role'], {
            splitOnRegEx: /[\s\/()]/,
            min: 2,
            ignoreCase: true,
            indexField: 'id',
        });
        newTs.addAll(users);
        setTrieStore(newTs);

        if (searchQuery.trim() === '') {
            setFilteredUsers(users);
        } else {
            setFilteredUsers(newTs.get(searchQuery));
        }
    }, [users])

    useEffect(() => {
        if (searchQuery.trim() === '') {
            setFilteredUsers(users);
        } else {
            setFilteredUsers(trieStore.get(searchQuery));
        }
    }, [searchQuery])

    return {filteredUsers}
}