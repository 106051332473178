import {observer} from "mobx-react-lite";
import React, {useRef} from "react";
import {useStore} from "../../helpers/helpers";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import {SideOverlay} from "../../components";
import {SearchVoterList, VoterListWithCheckBox} from "../voter-list";


export const AddVoters = observer((props: any) => {

    const rootStore = useStore();
    const {grievanceStore, voterListStore} = rootStore;
    const {t} = useTranslation(["grievance_management", "beneficiary"]);

    const voters = useRef(props["existing_voters"]);

    const onSelection = voter => {
        let selected_voters = Object.assign([], voters.current)

        if (_.findIndex(selected_voters, {id: voter.id}) === -1) {
            selected_voters.push(voter)
        } else {
            selected_voters.splice(_.findIndex(selected_voters, {id: voter.id}), 1)
        }
        voters.current = selected_voters
    }

    const close_voterList = () => {
        // grievanceStore.udpate_show_gvoter_list(false);
        grievanceStore.update_grievance_store_property("show_gvoter_list", false)
        voterListStore.update_active_voter({});
    }

    const onSave = () => {
        grievanceStore.update_grievance_store_property("show_gvoter_list", false)
        //grievanceStore.udpate_show_gvoter_list(false);
        // grievanceStore.udpate_slelected_voters(voters.current);
        grievanceStore.update_grievance_store_property("selected_voters", voters.current);
    }

    console.log(voters.current)

    return (
        <>
            {grievanceStore.show_gvoter_list &&
            <SideOverlay
                children={<>
                    <SearchVoterList/>
                    <VoterListWithCheckBox
                        onSelection={onSelection}
                        onSave={onSave}
                        onCancel={close_voterList}
                        selected={voters.current}
                    />
                </>}
                onClose={close_voterList}
                show={grievanceStore.show_gvoter_list}
                title={t("search_voters", {ns: "beneficiary"})}
            />}
        </>
    )
})