import React, {useLayoutEffect, useRef} from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import {ColumnSeries, ValueAxis, XYChart, LabelBullet, CategoryAxis, Legend} from "@amcharts/amcharts4/charts";
am4core.useTheme(am4themes_animated);

const HorizontalStackedBarChart = ({ data, seriesColors, id }) => {

    const bar_chart = useRef(null);
    const chart_div = `chart_${id}`;

    useLayoutEffect(() => {

        // Create the chart instance
        const chart = am4core.create(chart_div, XYChart);
        chart.data = data;

        // Create axes
        const categoryAxis = chart.yAxes.push(new CategoryAxis());
        categoryAxis.dataFields.category = id;
        categoryAxis.renderer.inversed = true; // Invert axis to create a horizontal chart
        categoryAxis.renderer.grid.template.disabled = true;

        const valueAxis = chart.xAxes.push(new ValueAxis());
        valueAxis.renderer.minWidth = 10;
        valueAxis.renderer.grid.template.disabled = true;
        valueAxis.renderer.labels.template.disabled = false;
        // Create series and set custom colors
        const seriesNames = Object.keys(seriesColors);
        seriesNames.forEach((fieldName) => {
            const series = chart.series.push(new ColumnSeries());
            series.dataFields.valueX = fieldName;
            series.dataFields.categoryY = `${id}`;
            series.name = fieldName;
            series.columns.template.tooltipText = `{name}: [bold]{valueX}[/]`;
            series.stacked = true;
            series.fill = am4core.color(seriesColors[fieldName]);
        });

        // Add legend
        chart.legend = new Legend();
    }, [data]);

    return <div id={chart_div} style={{ width: '100%', height: '600px' }}/>;

};

export default HorizontalStackedBarChart;
