import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import {useStore} from "../../../helpers/helpers";
import {makeid_alpa_numeric} from "../../../helpers/utils";
import { useTranslation } from 'react-i18next';
import {ErrorMeaasge, input_style, label_style, ProfileFormField} from "../../beneficiary-management";
import {FormButtons} from "../../../components/cm-components";

type EventModel = {
    date: string,
    title: string,
}

type AddEventProps = {
    onAdd: Function,
    event: EventModel
}
export const AddEvent = (props: AddEventProps) => {

    const {onAdd, event} = props;
    const {projectWorksStore} = useStore()
    const [addEvent, setaddEvent] = useState(event);
    const [desc, setDesc] = useState('')
    const {t} = useTranslation("project_work");
    const {register, handleSubmit, formState: {errors}} = useForm({defaultValues: addEvent, mode: 'onSubmit', reValidateMode: 'onBlur'});

    const addEventChangeHandler = (val, filed_name) => {
        setaddEvent({...addEvent, [filed_name]: val});
    }

    const descChange = (event) => {
        setDesc(event.target.value)
    }
    const onSubmit = async (values) => {
        if(addEvent["id"] !== ""){
            onAdd({...addEvent})
        } else {
            onAdd({...addEvent, id: makeid_alpa_numeric(6)})
        }
        projectWorksStore.update_project_work_property("add_date_modal", false);
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <ProfileFormField
                        register={register}
                        field_name="title"
                        value={addEvent.title}
                        label={t("title")}
                        onChangeHandler={addEventChangeHandler}
                        required={true}
                        error={errors}
                        error_msg={t("title_required")}
                    />
                    <div className="mb-2 flex flex-row items-center justify-between">
                        <label htmlFor="date" className={label_style}>{t("date")}</label>
                        <input
                            type="date"
                            {...register("date", {required: true})}
                            name="date"
                            className={input_style}
                            value={addEvent.date}
                            onChange={event => {
                                setaddEvent({...addEvent, date: event.target.value})
                            }}
                        />
                    </div>
                    <ErrorMeaasge val={errors["date"]} error={"Date is required"}/>
                    <label htmlFor="additional"
                           className="block text-sm font-medium text-gray-700 pt-4">{t("description")}</label>
                    <textarea
                        className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md border p-2"
                        rows={3}
                        value={desc}
                        onChange={descChange}
                    >
                        </textarea>
                </div>

                <FormButtons
                    onSave={() => {
                    }}
                    onCancel={(e) => {
                        projectWorksStore.update_project_work_property("add_date_modal", false);
                    }}
                />
            </form>
        </>
    )
}