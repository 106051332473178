import {Button} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";

export const EditButton = ({OnEditClickHandler, ...props}) => {
    const { t } = useTranslation(["beneficiary", "voter_list"]);
    return (
        <Button
            onClick={OnEditClickHandler}
            endIcon={<i className={`fa-solid fa-pen`} aria-hidden="true" />}
            color={"primary"}
            size={"small"}
            variant={"contained"}
        >
            {t("edit")}
        </Button>
    )
}