import React, {useEffect, useState} from "react";
import {useStore} from "../../helpers";
import {LeafletACMap, LoadingState} from "../../components";

export const DVGMap = (props) => {

    const rootStore = useStore();
    const {flexiStore} = rootStore;
    const [resuri, set_resuri] = useState('');
    const [resource, setResource] = useState({});
    const [loading, setLoading] = useState(false)

    const resolve_uri = async () => {
        setLoading(true)
        try {
            const res = await flexiStore.rootStore.get_s3_url("urn-fsd", "ka-ac-bank/VK_Supportmap.geojson");
            if(res) set_resuri(res)
        } catch (e) {
            console.log(e)
        }
        setLoading(false)
    }

    const load_geojson = async () => {
        setLoading(true)
        try {
            if(resuri !== ""){
                const res = await rootStore.get_json_resource(resuri);
                if(res) setResource(res)
            }
        } catch (e) {
            console.log(e)
        }
        setLoading(false)
    }

    useEffect(() => {
        resolve_uri()
    }, [])

    useEffect(() => {
        load_geojson();
    }, [resuri])

    const map_config = {
        height: "100vh",
        show_booth_numbers: true
    }

    if(loading) return <LoadingState />

    return(
        <>
            <p className={"text-bold text-blue-500 text-center text-3xl p-4"}>Vinay Kumar Supprot</p>
            <LeafletACMap geojson={resource} config={map_config} />
        </>
    )
}