

export const addSchemeInitialState = {
    required_docs: []
};

export function addSchemeReducer(state = addSchemeInitialState, action) {
    switch (action.type) {
        case 'ADD_FIELD':
            return {
                ...state,
                required_docs: [...state.required_docs, { name: '', required: false }]
            };
        case 'DELETE_FIELD':
            return {
                ...state,
                required_docs: state.required_docs.filter((_, index) => index !== action.index)
            };
        case 'UPDATE_FIELD':
            return {
                ...state,
                required_docs: state.required_docs.map((field, index) => {
                    if (index === action.index) {
                        return { ...field, [action.fieldName]: action.fieldValue };
                    }
                    return field;
                })
            };
        case 'SET_FIELDS':
            return {
                ...state,
                required_docs: action.payload
            };
        default:
            return state;
    }
}
