import React from 'react';
import {useForm, Controller, useFieldArray} from 'react-hook-form';
import {Button, Stack, TextField, Typography} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Autocomplete from '@mui/lab/Autocomplete';

const constituencies = [
    "1 Sadulshahar",
    "2 Ganganagar",
    "3 Karanpur",
    "4 Suratgarh",
    "5 Raisinghnagar (Sc)",
    "6 Anupgarh (Sc)",
    "7 Sangaria",
    "8 Hanumgarh",
    "9 Pilibanga (Sc)",
    "10	Nohar",
    "11	Bhadra",
    "12	Khajuwala (Sc)",
    "13	Bikaner West",
    "14	Bikaner East",
    "15	Kolayat",
    "16	Lunkaransar",
    "17	Dungargarh",
    "18	Nokha",
    "19	Sadulpur",
    "20	Taranagar",
    "21	Darsarshahar",
    "22	Churu",
    "23	Ratangarh",
    "24	Sujangarh (Sc)",
    "25	Pilani (Sc)",
    "26	Surajgarh",
    "27	Jhunjhunu",
    "28	Mandawa",
    "29	Nawalgarh",
    "30	Udaipurwati",
    "31	Khetri",
    "32	Fatehpur",
    "33	Lachmangarh",
    "34	Dhod (Sc)",
    "35	Sikar",
    "36	Dantaramgarh",
    "37	Khandela",
    "38	Neem Ka Thana",
    "39	Srimadhopur",
    "40	Kotputli",
    "41	Viratnagar",
    "42	Shahpura",
    "43	Chomu",
    "44	Phulera",
    "45	Dudu (Sc)",
    "46	Jhotwara",
    "47	Amber",
    "48	Jamwa Ramgarh (St)",
    "49	Hawamahal",
    "50	Vidhyadhar Nagar",
    "51	Civil Lines",
    "52	Kishanpole",
    "53	Adarsh Nagar",
    "54	Malaviya Nagar",
    "55	Sanganer",
    "56	Bagru (Sc)",
    "57	Bassi (St)",
    "58	Chaksu (Sc)",
    "59	Tijara",
    "60	Kishangarh Bas",
    "61	Mundawar",
    "62	Behror",
    "63	Bansur",
    "64	Thanagazi",
    "65	Alwar Rural (Sc)",
    "66	Alwar Urban",
    "67	Ramgarh",
    "68	Rajgarh Laxmangarh (Sc)",
    "69	Kathumar (Sc)",
    "70	Kaman",
    "71	Nagar",
    "72	Deeg Kumher",
    "73	Bharatpur",
    "74	Nadbai",
    "75	Weir (Sc)",
    "76	Bayana (Sc)",
    "77	Baseri (Sc)",
    "78	Bari",
    "79	Dholpur",
    "80	Rajakhera",
    "81	Todabhim (St)",
    "82	Hindaun (Sc)",
    "83	Karauli",
    "84	Sapotra(St)",
    "85	Bandikui",
    "86	Mahuwa",
    "87	Sikrai (Sc)",
    "88	Dausa",
    "89	Lalsot (St)",
    "90	Gangapur",
    "91	Bamanwas (St)",
    "92	Sawai Madhopur",
    "93	Khandar (Sc)",
    "94	Malpura",
    "95	Niwai (Sc)",
    "96	Tonk",
    "97	Deoli - Uniara",
    "98	Kishangarh",
    "99	Pushkar",
    "100 Ajmer North",
    "101 Ajmer South (Sc)",
    "102 Nasirabad",
    "103 Beawar",
    "104 Masuda",
    "105 Kekri",
    "106 Ladnun",
    "107 Deedwana",
    "108 Jayal (Sc)",
    "109 Nagaur",
    "110 Khinsvar",
    "111 Merta (Sc)",
    "112 Degana",
    "113 Makrana",
    "114 Parbatsar",
    "115 Nawan",
    "116 Jaitaran",
    "117 Sojat (Sc)",
    "118 Pali",
    "119 Marwar Junction",
    "120 Bali",
    "121 Sumerpur",
    "122 Phalodi",
    "123 Lohawat",
    "124 Shergarh",
    "125 Osian",
    "126 Bhopalgarh (Sc)",
    "127 Sardarpura",
    "128 Jodhpur",
    "129 Soorsagar",
    "130 Luni",
    "131 Bhilara (Sc)",
    "132 Jaisalmer",
    "133 Pokaran",
    "134 Sheo",
    "135 Barmer",
    "136 Baytoo",
    "137 Pachpadra",
    "138 Siwana",
    "139 Gudamalani",
    "140 Chohtan (Sc)",
    "141 Ahore",
    "142 Jalore (Sc)",
    "143 Bhinmal",
    "144 Sanchore",
    "145 Raniwara",
    "146 Sirohi",
    "147 Pindwara Abu (St)",
    "148 Reodar (Sc)",
    "149 Gogunda (St)",
    "150 Jhadol (St)",
    "151 Kherwara (St)",
    "152 Udaipur Rural (St)",
    "153 Udaipur",
    "154 Mavli",
    "155 Vallabnagar",
    "156 Salumber (St)",
    "157 Dhariawad (St)",
    "158 Dungarpur (St)",
    "159 Aspur (St)",
    "160 Sagwara (St)",
    "161 Chorasi (St)",
    "162 Ghatol (St)",
    "163 Garhi (St)",
    "164 Bhanswara (St)",
    "165 Bagidora (St)",
    "166 Kushalgarh (St)",
    "167 Kapasan (Sc)",
    "168 Begun",
    "169 Chittorgarh",
    "170 Nimbahera",
    "171 Bari Sadri",
    "172 Pratapgarh (St)",
    "173 Bhim",
    "174 Kumbhalgarh",
    "175 Rajsamand",
    "176 Nathdwara",
    "177 Asind",
    "178 Mandal",
    "179 Sahara",
    "180 Bhilwara",
    "181 Shahpura (Sc)",
    "182 Jahazpur",
    "183 Mandalgarh",
    "184 Hindoli",
    "185 Keshoraipatan (Sc)",
    "186 Bundi",
    "187 Pipalda",
    "188 Sangod",
    "189 Kota North",
    "190 Kota South",
    "191 Ladpura",
    "192 Ramganj Mandi (Sc)",
    "193 Anta",
    "194 Kishanganj (St)",
    "195 Bharan - Atru (Sc)",
    "196 Chhabra",
    "197 Dag (Sc)",
    "198 Jhalrapatan",
    "199 Khanpur",
    "200 Manohar Thana",
]

const userOptions = [
    {id: 1, label: 'User 1'},
    {id: 2, label: 'User 2'},
    {id: 3, label: 'User 3'},
];

const booths = Array.from({length: 300}, (_, index) => {
    const number = (index + 1).toString().padStart(3, '0');
    return {label: `Booth ${number}`, value: number};
});

const Form = ({index, control, remove}) => {
    return (
        <Stack direction={"row"} gap={4} my={2} justifyContent={"center"} alignItems={"center"}>
            <Stack direction={"row"} gap={3}>
                <Controller
                    control={control}
                    name={`forms.${index}.user`}
                    render={({field, fieldState}) => (
                        <Stack gap={2}>
                            <Autocomplete
                                sx={{
                                    minWidth: 300
                                }}
                                options={userOptions}
                                getOptionLabel={(option) => option.label}
                                renderInput={(params) => <TextField {...params} {...field} label="User" variant="outlined"/>}
                                onChange={(_, newValue) => field.onChange(newValue ? newValue.id : '')}
                            />
                            {fieldState.error && <Typography variant="body2" color="error">{fieldState.error.message}</Typography>}
                        </Stack>
                    )}
                    rules={
                        {
                            required: "Select user"
                        }
                    }
                />
                <Controller
                    control={control}
                    name={`forms.${index}.ac`}
                    render={({field, fieldState}) => (
                        <Stack gap={2}>
                            {/*<TextField {...field} label="AC" variant="outlined"/>*/}
                            <Autocomplete
                                sx={{
                                    minWidth: 300
                                }}
                                options={constituencies.map(c => {
                                    return {
                                        value: parseInt(c.split(" ")[0]),
                                        label: c
                                    }
                                })}
                                getOptionLabel={(option) => option.label}
                                renderInput={(params) => <TextField {...params} {...field} label="AC" variant="outlined"/>}
                                onChange={(_, newValue) => field.onChange(newValue ? newValue.value : '')}
                            />
                            {fieldState.error && <Typography variant="body2" color="error">{fieldState.error.message}</Typography>}
                        </Stack>
                    )}
                    rules={{required: "AC number is required"}}
                />
                <Controller
                    control={control}
                    name={`forms.${index}.booth`}
                    render={({field, fieldState}) => (
                        <Stack gap={2}>
                            <Autocomplete
                                multiple={true}
                                defaultValue={[]}
                                sx={{
                                    minWidth: 300
                                }}
                                options={booths}
                                getOptionLabel={(option) => option.label}
                                renderInput={(params) => <TextField {...params} {...field} label="Booth #" variant="outlined"/>}
                                onChange={(_, newValue) => field?.onChange(newValue ? newValue.map(option => option["value"]):[])}
                                value={booths.filter(option => field?.value?.includes(option.value))}
                            />
                            {fieldState.error && <Typography variant="body2" color="error">{fieldState.error.message}</Typography>}
                        </Stack>
                    )}
                    rules={
                        {
                            required: "Booth number is required",
                        }
                    }
                />
                <Controller
                    control={control}
                    name={`forms.${index}.count`}
                    render={({field, fieldState}) => (
                        <Stack gap={2}>
                            <TextField {...field} sx={{maxWidth: 150}} label="Count" variant="outlined" type="number" onChange={(event) => field.onChange(+event.target.value)}/>
                            {fieldState.error && <Typography variant="body2" color="error">{fieldState.error.message}</Typography>}
                        </Stack>
                    )}
                    rules={{required: "Count is required"}}
                />

            </Stack>
            <IconButton
                onClick={() => remove(index)}
                color="error"
                size={"small"}
            > <i className={"fas fa-trash"}/>
            </IconButton>
        </Stack>
    );
};

export const RJTaskAssign = () => {

    const {control, handleSubmit, formState: {errors}} = useForm({
        defaultValues: {
            forms: [
                {user: 0, ac: 0, booth: [], count: 0}
            ],
        },
    });

    const {fields, append, remove} = useFieldArray({
        control,
        name: 'forms',
    });

    const onSubmit = (data) => {
        console.log(data);
    };

    return (
        <div className="max-w-7xl mx-auto flex flex-col items-center py-8">
            <Typography variant={"h4"} my={4} color={"secondary"}>Rajasthan Task Assigner</Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
                {fields.map((field, index) => (
                    <Form
                        key={field.id}
                        index={index}
                        control={control}
                        remove={remove}
                    />
                ))}
                <Stack direction={"row"} gap={4} justifyContent={"flex-end"}>
                    <Button
                        type="button"
                        onClick={() => append({user: 0, ac: 0, booth: [], count: 0})}
                        variant="outlined"
                    >Add Task
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        color="success"
                    >Submit
                    </Button>
                </Stack>
            </form>
        </div>
    );
};
