import {useMutation, useQueryClient} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {useStore} from "../../../helpers";
import { useParams } from "react-router-dom";

export const useAddProjectWork = () => {

    const {projectWorkStoreV2} = useStore();
    const { projectid } = useParams();
    const queryClient = useQueryClient();

    const { mutate:addProjectWork, isLoading } = useMutation({
        mutationFn: async (data: any) => {
            const payload = {
                projectid: parseInt(projectid),
                features: {},
                summary: data,
                more: {}
            }
            await projectWorkStoreV2.add_project_work(payload)
        },
        onSuccess: async () => {
            setTimeout(async () => {
                await queryClient.invalidateQueries(["project-works", projectid])
            }, 1000);
            toast.success("Project added Successfully")
            projectWorkStoreV2.update_add_project_modal(false);
        },
        onError: async () => {
            toast.error("Something went wrong");
        }
    });

    return {
        addProjectWork, isLoading
    }

}