import React from "react";
import {LoadingState, ProjectHeader, SideOverlay} from "../../components";
import {useStore} from "../../helpers";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react-lite";
import {RNSearchBar} from "../influential-leaders";
import {Button} from "@mui/material";
import {AddProjectWork} from "./add-project-work-v2";
import {ProjectWorkCards} from "./project-work-cards";
import {PWHomeCrumbs} from "./project-work-bread-crumbs";
import {useProjectWorksList} from "./pw-hooks";

export const NewProjectWorksHome = observer((props: any) => {

    const {projectWorkStoreV2} = useStore();
    const {projectWorksList} = projectWorkStoreV2;
    const {t} = useTranslation("project_work");
    const {isLoading} = useProjectWorksList();

    const addNewProject = async () => {
        projectWorkStoreV2.update_add_project_modal(true);
    }

    if(isLoading) return <LoadingState />

    return (
        <>
            <PWHomeCrumbs />
            <ProjectHeader/>
            <div className='p-4'>
                <div className="flex align-center gap-x-4">
                    <div className='flex flex-row justify-between items-center mb-4 flex-1'>
                        <h2 className='font-bold text-3xl text-indigo-500 pr-14'>{t("project_works")}</h2>
                        <div className={"flex items-start justify-between gap-x-4"}>
                            <RNSearchBar onSearch={(val) => projectWorkStoreV2.update_project_work_property("searchQuery", val)}/>
                            <Button
                                variant={"contained"}
                                startIcon={<i className="fas fa-plus-circle"/>}
                                onClick={addNewProject}
                            >
                                {t("add_project_work")}
                            </Button>
                        </div>
                    </div>
                </div>
                <AddProjectWork/>
                {projectWorksList.length > 0 && <ProjectWorkCards/>}
            </div>
        </>
    );
});
