import {action, observable} from "mobx";

export class ElectionDayDashboardStore {
    //@observable electionDayDashboardData = {};

    rootStore: any;

    @observable electionDayDashboardData = {
        "ac_wise": {
            "total_voters": 266008,
            "support_strength": {
                "BJP": 6019,
                "Others": 652,
                "INC": 551,
                "JDS": 36,
                "AIMIM": 53,
                "AAP": 10
            },
            "gotv_party": {
                "Not voted": 265910,
                "BJP": 95,
                "INC": 3
            },
            "gotv_voted": {
                "Not voted": 265910,
                "voted": 98
            },
            "piv_level_1": [
                "16 - Rajeshwari Salagatti",
                "21 - Anand Yavagala",
                "33 - Satish Hanagal",
                "32 - Satish Hanagal",
                "23 - Sanjay Kapatakara",
                "34 - Akshata Roogi",
                "27 - Sunitha",
                "24 - Shivanna Badavannavara",
                "14 - Shubahs Shinde",
                "28 - Chandrashekara Managundi",
                "29 - Mallikarjuna Navanagar",
                "20 - Geetha Patil",
                "26 - Neelavva  Yalappa Aravalada",
                "19-Jyoti Patil",
                "13 - Suresh Bedare",
                "18-Shivu Heremath",
                "10 - Chandrakala Kotabagi",
                "31 - Eeranna Pujar",
                "17 - Malleshi Basappa Mammigatti",
                "12 - Vijayananda Shetty",
                "30 - Ramanna Badiger",
                "22 - Parvativva Hittalamani",
                "15 - Vishnu Korlahalli",
                "11 - Manjunath Battanna",
                "25 - Parashuram Sakare"
            ]
        },
        "piv_level1_wise": {
            "16 - Rajeshwari Salagatti": {
                "part": [
                    "083",
                    "086",
                    "081",
                    "084",
                    "041",
                    "039",
                    "080",
                    "082",
                    "079",
                    "040",
                    "044",
                    "087",
                    "042",
                    "085"
                ],
                "total_voters": 11609,
                "support_strength": {
                    "BJP": 3,
                    "Others": 1
                },
                "gotv_party": {
                    "Not voted": 11609
                },
                "gotv_voted": {
                    "Not voted": 11609
                },
                "piv_level_1": "16 - Rajeshwari Salagatti"
            },
            "21 - Anand Yavagala": {
                "part": [
                    "156",
                    "151",
                    "159",
                    "187",
                    "146",
                    "150",
                    "148",
                    "149",
                    "147"
                ],
                "total_voters": 8475,
                "support_strength": {
                    "BJP": 291,
                    "Others": 2,
                    "INC": 8,
                    "JDS": 1
                },
                "gotv_party": {
                    "Not voted": 8475
                },
                "gotv_voted": {
                    "Not voted": 8475
                },
                "piv_level_1": "21 - Anand Yavagala"
            },
            "33 - Satish Hanagal": {
                "part": [
                    "254",
                    "251",
                    "248",
                    "250",
                    "249",
                    "247",
                    "253",
                    "246",
                    "252"
                ],
                "total_voters": 9230,
                "support_strength": {
                    "BJP": 125,
                    "JDS": 6,
                    "Others": 4,
                    "INC": 1
                },
                "gotv_party": {
                    "Not voted": 9230
                },
                "gotv_voted": {
                    "Not voted": 9230
                },
                "piv_level_1": "33 - Satish Hanagal"
            },
            "32 - Satish Hanagal": {
                "part": [
                    "218",
                    "241",
                    "220",
                    "242",
                    "239",
                    "219",
                    "217",
                    "216",
                    "245",
                    "244",
                    "243",
                    "240",
                    "238"
                ],
                "total_voters": 13448,
                "support_strength": {
                    "BJP": 215,
                    "Others": 24,
                    "AIMIM": 1,
                    "INC": 9,
                    "JDS": 1
                },
                "gotv_party": {
                    "Not voted": 13448
                },
                "gotv_voted": {
                    "Not voted": 13448
                },
                "piv_level_1": "32 - Satish Hanagal"
            },
            "23 - Sanjay Kapatakara": {
                "part": [
                    "097",
                    "096",
                    "098",
                    "095",
                    "099",
                    "102"
                ],
                "total_voters": 6781,
                "support_strength": {
                    "BJP": 5
                },
                "gotv_party": {
                    "Not voted": 6781
                },
                "gotv_voted": {
                    "Not voted": 6781
                },
                "piv_level_1": "23 - Sanjay Kapatakara"
            },
            "34 - Akshata Roogi": {
                "part": [
                    "258",
                    "255",
                    "256",
                    "262",
                    "260",
                    "263",
                    "264",
                    "257",
                    "265",
                    "266",
                    "259",
                    "261"
                ],
                "total_voters": 13680,
                "support_strength": {
                    "BJP": 49,
                    "AAP": 1,
                    "AIMIM": 1,
                    "Others": 2
                },
                "gotv_party": {
                    "Not voted": 13680
                },
                "gotv_voted": {
                    "Not voted": 13680
                },
                "piv_level_1": "34 - Akshata Roogi"
            },
            "27 - Sunitha": {
                "part": [
                    "131",
                    "130",
                    "123",
                    "126",
                    "124",
                    "133",
                    "128",
                    "122",
                    "125",
                    "127",
                    "129",
                    "132"
                ],
                "total_voters": 13324,
                "support_strength": {
                    "BJP": 179,
                    "INC": 22,
                    "Others": 29,
                    "JDS": 1
                },
                "gotv_party": {
                    "Not voted": 13324
                },
                "gotv_voted": {
                    "Not voted": 13324
                },
                "piv_level_1": "27 - Sunitha"
            },
            "24 - Shivanna Badavannavara": {
                "part": [
                    "106",
                    "108",
                    "100",
                    "110",
                    "111",
                    "105",
                    "103",
                    "107",
                    "101",
                    "104",
                    "109"
                ],
                "total_voters": 9992,
                "support_strength": {
                    "BJP": 113,
                    "INC": 41,
                    "Others": 37,
                    "JDS": 2
                },
                "gotv_party": {
                    "Not voted": 9992
                },
                "gotv_voted": {
                    "Not voted": 9992
                },
                "piv_level_1": "24 - Shivanna Badavannavara"
            },
            "Demo_Ward": {
                "part": [],
                "total_voters": 0,
                "support_strength": {},
                "gotv_party": {},
                "gotv_voted": {}
            },
            "14 - Shubahs Shinde": {
                "part": [
                    "026",
                    "033",
                    "031",
                    "037",
                    "036",
                    "030",
                    "032",
                    "027",
                    "034",
                    "029",
                    "025",
                    "035",
                    "038",
                    "024",
                    "028"
                ],
                "total_voters": 15377,
                "support_strength": {
                    "BJP": 5,
                    "Others": 1
                },
                "gotv_party": {
                    "Not voted": 15377
                },
                "gotv_voted": {
                    "Not voted": 15377
                },
                "piv_level_1": "14 - Shubahs Shinde"
            },
            "28 - Chandrashekara Managundi": {
                "part": [
                    "207",
                    "205",
                    "204",
                    "203",
                    "206"
                ],
                "total_voters": 4272,
                "support_strength": {
                    "BJP": 337,
                    "INC": 41,
                    "Others": 1,
                    "JDS": 3
                },
                "gotv_party": {
                    "Not voted": 4272
                },
                "gotv_voted": {
                    "Not voted": 4272
                },
                "piv_level_1": "28 - Chandrashekara Managundi"
            },
            "29 - Mallikarjuna Navanagar": {
                "part": [
                    "120",
                    "119",
                    "118",
                    "117",
                    "113",
                    "121",
                    "112",
                    "116",
                    "114",
                    "115"
                ],
                "total_voters": 10349,
                "support_strength": {
                    "BJP": 722,
                    "Others": 90,
                    "INC": 137,
                    "AIMIM": 2,
                    "AAP": 1
                },
                "gotv_party": {
                    "Not voted": 10349
                },
                "gotv_voted": {
                    "Not voted": 10349
                },
                "piv_level_1": "29 - Mallikarjuna Navanagar"
            },
            "20 - Geetha Patil": {
                "part": [
                    "185",
                    "192",
                    "189",
                    "184",
                    "183",
                    "188",
                    "186",
                    "155",
                    "154",
                    "191",
                    "190",
                    "198",
                    "193"
                ],
                "total_voters": 12583,
                "support_strength": {
                    "BJP": 93,
                    "INC": 20,
                    "Others": 16,
                    "JDS": 1,
                    "AAP": 1
                },
                "gotv_party": {
                    "Not voted": 12583
                },
                "gotv_voted": {
                    "Not voted": 12583
                },
                "piv_level_1": "20 - Geetha Patil"
            },
            "26 - Neelavva  Yalappa Aravalada": {
                "part": [
                    "135",
                    "137",
                    "141",
                    "136",
                    "138",
                    "134",
                    "140",
                    "201",
                    "139",
                    "142",
                    "202"
                ],
                "total_voters": 11585,
                "support_strength": {
                    "BJP": 139,
                    "Others": 2,
                    "INC": 3
                },
                "gotv_party": {
                    "Not voted": 11585
                },
                "gotv_voted": {
                    "Not voted": 11585
                },
                "piv_level_1": "26 - Neelavva  Yalappa Aravalada"
            },
            "19-Jyoti Patil": {
                "part": [
                    "180",
                    "179",
                    "168",
                    "176",
                    "177",
                    "181",
                    "182",
                    "169",
                    "178"
                ],
                "total_voters": 7587,
                "support_strength": {
                    "BJP": 230,
                    "Others": 58,
                    "INC": 31,
                    "JDS": 1,
                    "AAP": 1
                },
                "gotv_party": {
                    "Not voted": 7587
                },
                "gotv_voted": {
                    "Not voted": 7587
                },
                "piv_level_1": "19-Jyoti Patil"
            },
            "13 - Suresh Bedare": {
                "part": [
                    "018",
                    "016",
                    "013",
                    "023",
                    "021",
                    "046",
                    "076",
                    "045",
                    "022",
                    "015",
                    "019",
                    "043",
                    "014",
                    "020"
                ],
                "total_voters": 11025,
                "support_strength": {
                    "BJP": 6
                },
                "gotv_party": {
                    "Not voted": 11025
                },
                "gotv_voted": {
                    "Not voted": 11025
                },
                "piv_level_1": "13 - Suresh Bedare"
            },
            "18-Shivu Heremath": {
                "part": [
                    "067",
                    "066",
                    "075",
                    "072",
                    "174",
                    "175",
                    "071",
                    "073"
                ],
                "total_voters": 8404,
                "support_strength": {
                    "BJP": 165,
                    "Others": 44,
                    "INC": 4,
                    "AIMIM": 1
                },
                "gotv_party": {
                    "Not voted": 8404
                },
                "gotv_voted": {
                    "Not voted": 8404
                },
                "piv_level_1": "18-Shivu Heremath"
            },
            "10 - Chandrakala Kotabagi": {
                "part": [
                    "001",
                    "003",
                    "061",
                    "002",
                    "056",
                    "062",
                    "057",
                    "063",
                    "065",
                    "060",
                    "059",
                    "064",
                    "058"
                ],
                "total_voters": 13072,
                "support_strength": {
                    "BJP": 936,
                    "Others": 47,
                    "INC": 92,
                    "JDS": 5,
                    "AAP": 1
                },
                "gotv_party": {
                    "Not voted": 13072
                },
                "gotv_voted": {
                    "Not voted": 13072
                },
                "piv_level_1": "10 - Chandrakala Kotabagi"
            },
            "31 - Eeranna Pujar": {
                "part": [
                    "237",
                    "236",
                    "228",
                    "231",
                    "227",
                    "234",
                    "232",
                    "230",
                    "235",
                    "229",
                    "233"
                ],
                "total_voters": 11366,
                "support_strength": {
                    "BJP": 813,
                    "AIMIM": 41,
                    "Others": 91,
                    "INC": 37,
                    "AAP": 2,
                    "JDS": 1
                },
                "gotv_party": {
                    "Not voted": 11268,
                    "BJP": 95,
                    "INC": 3
                },
                "gotv_voted": {
                    "Not voted": 11268,
                    "voted": 98
                },
                "piv_level_1": "31 - Eeranna Pujar"
            },
            "17 - Malleshi Basappa Mammigatti": {
                "part": [
                    "164",
                    "167",
                    "157",
                    "163",
                    "158",
                    "160",
                    "165",
                    "088",
                    "166",
                    "161",
                    "162"
                ],
                "total_voters": 12467,
                "support_strength": {
                    "Others": 143,
                    "BJP": 349,
                    "INC": 86,
                    "JDS": 9,
                    "AIMIM": 6,
                    "AAP": 1
                },
                "gotv_party": {
                    "Not voted": 12467
                },
                "gotv_voted": {
                    "Not voted": 12467
                },
                "piv_level_1": "17 - Malleshi Basappa Mammigatti"
            },
            "12 - Vijayananda Shetty": {
                "part": [
                    "052",
                    "053",
                    "054",
                    "055",
                    "049",
                    "051",
                    "050",
                    "048",
                    "047"
                ],
                "total_voters": 8426,
                "support_strength": {
                    "BJP": 48,
                    "INC": 3,
                    "Others": 10
                },
                "gotv_party": {
                    "Not voted": 8426
                },
                "gotv_voted": {
                    "Not voted": 8426
                },
                "piv_level_1": "12 - Vijayananda Shetty"
            },
            "30 - Ramanna Badiger": {
                "part": [
                    "208",
                    "213",
                    "209",
                    "226",
                    "212",
                    "221",
                    "225",
                    "222",
                    "215",
                    "210",
                    "214",
                    "223",
                    "211",
                    "224"
                ],
                "total_voters": 15283,
                "support_strength": {
                    "BJP": 1105,
                    "INC": 12,
                    "Others": 42,
                    "JDS": 4,
                    "AAP": 1,
                    "AIMIM": 1
                },
                "gotv_party": {
                    "Not voted": 15283
                },
                "gotv_voted": {
                    "Not voted": 15283
                },
                "piv_level_1": "30 - Ramanna Badiger"
            },
            "22 - Parvativva Hittalamani": {
                "part": [
                    "094",
                    "093",
                    "089",
                    "092",
                    "152",
                    "091",
                    "153",
                    "090"
                ],
                "total_voters": 8033,
                "support_strength": {
                    "BJP": 7,
                    "Others": 1,
                    "AAP": 1
                },
                "gotv_party": {
                    "Not voted": 8033
                },
                "gotv_voted": {
                    "Not voted": 8033
                },
                "piv_level_1": "22 - Parvativva Hittalamani"
            },
            "15 - Vishnu Korlahalli": {
                "part": [
                    "170",
                    "070",
                    "171",
                    "173",
                    "172",
                    "068",
                    "077",
                    "074",
                    "078",
                    "069",
                    "164_01"
                ],
                "total_voters": 9908,
                "support_strength": {
                    "BJP": 42,
                    "JDS": 1,
                    "Others": 4,
                    "INC": 2
                },
                "gotv_party": {
                    "Not voted": 9908
                },
                "gotv_voted": {
                    "Not voted": 9908
                },
                "piv_level_1": "15 - Vishnu Korlahalli"
            },
            "11 - Manjunath Battanna": {
                "part": [
                    "007",
                    "005",
                    "009",
                    "008",
                    "006",
                    "010",
                    "017",
                    "012",
                    "004",
                    "011"
                ],
                "total_voters": 9416,
                "support_strength": {
                    "BJP": 10,
                    "Others": 1
                },
                "gotv_party": {
                    "Not voted": 9416
                },
                "gotv_voted": {
                    "Not voted": 9416
                },
                "piv_level_1": "11 - Manjunath Battanna"
            },
            "25 - Parashuram Sakare": {
                "part": [
                    "143",
                    "200",
                    "194",
                    "145",
                    "195",
                    "196",
                    "144",
                    "199",
                    "197"
                ],
                "total_voters": 10316,
                "support_strength": {
                    "BJP": 32,
                    "INC": 2,
                    "Others": 2
                },
                "gotv_party": {
                    "Not voted": 10316
                },
                "gotv_voted": {
                    "Not voted": 10316
                },
                "piv_level_1": "25 - Parashuram Sakare"
            }
        },
        "booth_wise": {
            "131": {
                "part": "131",
                "total_voters": 1616,
                "support_strength": {
                    "BJP": 105,
                    "INC": 16,
                    "Others": 5
                },
                "gotv_party": {
                    "Not voted": 1616
                },
                "gotv_voted": {
                    "Not voted": 1616
                },
                "piv_level_1": "27 - Sunitha"
            },
            "170": {
                "part": "170",
                "total_voters": 1508,
                "support_strength": {
                    "BJP": 7
                },
                "gotv_party": {
                    "Not voted": 1508
                },
                "gotv_voted": {
                    "Not voted": 1508
                },
                "piv_level_1": "15 - Vishnu Korlahalli"
            },
            "258": {
                "part": "258",
                "total_voters": 1491,
                "support_strength": {
                    "BJP": 3
                },
                "gotv_party": {
                    "Not voted": 1491
                },
                "gotv_voted": {
                    "Not voted": 1491
                },
                "piv_level_1": "34 - Akshata Roogi"
            },
            "130": {
                "part": "130",
                "total_voters": 1485,
                "support_strength": {
                    "BJP": 4,
                    "INC": 1
                },
                "gotv_party": {
                    "Not voted": 1485
                },
                "gotv_voted": {
                    "Not voted": 1485
                },
                "piv_level_1": "27 - Sunitha"
            },
            "097": {
                "part": "097",
                "total_voters": 1456,
                "support_strength": {
                    "BJP": 1
                },
                "gotv_party": {
                    "Not voted": 1456
                },
                "gotv_voted": {
                    "Not voted": 1456
                },
                "piv_level_1": "23 - Sanjay Kapatakara"
            },
            "143": {
                "part": "143",
                "total_voters": 1443,
                "support_strength": {
                    "BJP": 4
                },
                "gotv_party": {
                    "Not voted": 1443
                },
                "gotv_voted": {
                    "Not voted": 1443
                },
                "piv_level_1": "25 - Parashuram Sakare"
            },
            "135": {
                "part": "135",
                "total_voters": 1436,
                "support_strength": {
                    "BJP": 43,
                    "Others": 1
                },
                "gotv_party": {
                    "Not voted": 1436
                },
                "gotv_voted": {
                    "Not voted": 1436
                },
                "piv_level_1": "26 - Neelavva  Yalappa Aravalada"
            },
            "123": {
                "part": "123",
                "total_voters": 1431,
                "support_strength": {
                    "BJP": 6,
                    "INC": 2,
                    "Others": 1
                },
                "gotv_party": {
                    "Not voted": 1431
                },
                "gotv_voted": {
                    "Not voted": 1431
                },
                "piv_level_1": "27 - Sunitha"
            },
            "200": {
                "part": "200",
                "total_voters": 1430,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 1430
                },
                "gotv_voted": {
                    "Not voted": 1430
                },
                "piv_level_1": "25 - Parashuram Sakare"
            },
            "208": {
                "part": "208",
                "total_voters": 1415,
                "support_strength": {
                    "BJP": 82,
                    "INC": 1,
                    "Others": 1
                },
                "gotv_party": {
                    "Not voted": 1415
                },
                "gotv_voted": {
                    "Not voted": 1415
                },
                "piv_level_1": "30 - Ramanna Badiger"
            },
            "213": {
                "part": "213",
                "total_voters": 1411,
                "support_strength": {
                    "BJP": 95,
                    "Others": 13,
                    "INC": 3
                },
                "gotv_party": {
                    "Not voted": 1411
                },
                "gotv_voted": {
                    "Not voted": 1411
                },
                "piv_level_1": "30 - Ramanna Badiger"
            },
            "194": {
                "part": "194",
                "total_voters": 1405,
                "support_strength": {
                    "BJP": 3
                },
                "gotv_party": {
                    "Not voted": 1405
                },
                "gotv_voted": {
                    "Not voted": 1405
                },
                "piv_level_1": "25 - Parashuram Sakare"
            },
            "255": {
                "part": "255",
                "total_voters": 1404,
                "support_strength": {
                    "BJP": 4
                },
                "gotv_party": {
                    "Not voted": 1404
                },
                "gotv_voted": {
                    "Not voted": 1404
                },
                "piv_level_1": "34 - Akshata Roogi"
            },
            "209": {
                "part": "209",
                "total_voters": 1398,
                "support_strength": {
                    "BJP": 143,
                    "INC": 2,
                    "JDS": 2
                },
                "gotv_party": {
                    "Not voted": 1398
                },
                "gotv_voted": {
                    "Not voted": 1398
                },
                "piv_level_1": "30 - Ramanna Badiger"
            },
            "094": {
                "part": "094",
                "total_voters": 1397,
                "support_strength": {
                    "BJP": 2
                },
                "gotv_party": {
                    "Not voted": 1397
                },
                "gotv_voted": {
                    "Not voted": 1397
                },
                "piv_level_1": "22 - Parvativva Hittalamani"
            },
            "001": {
                "part": "001",
                "total_voters": 1390,
                "support_strength": {
                    "BJP": 255,
                    "Others": 8,
                    "INC": 3,
                    "JDS": 2
                },
                "gotv_party": {
                    "Not voted": 1390
                },
                "gotv_voted": {
                    "Not voted": 1390
                },
                "piv_level_1": "10 - Chandrakala Kotabagi"
            },
            "237": {
                "part": "237",
                "total_voters": 1388,
                "support_strength": {
                    "BJP": 54,
                    "AIMIM": 10,
                    "Others": 9,
                    "INC": 5
                },
                "gotv_party": {
                    "Not voted": 1388
                },
                "gotv_voted": {
                    "Not voted": 1388
                },
                "piv_level_1": "31 - Eeranna Pujar"
            },
            "003": {
                "part": "003",
                "total_voters": 1384,
                "support_strength": {
                    "BJP": 215,
                    "INC": 10,
                    "Others": 3,
                    "JDS": 2
                },
                "gotv_party": {
                    "Not voted": 1384
                },
                "gotv_voted": {
                    "Not voted": 1384
                },
                "piv_level_1": "10 - Chandrakala Kotabagi"
            },
            "164": {
                "part": "164",
                "total_voters": 1384,
                "support_strength": {
                    "Others": 2
                },
                "gotv_party": {
                    "Not voted": 1384
                },
                "gotv_voted": {
                    "Not voted": 1384
                },
                "piv_level_1": "17 - Malleshi Basappa Mammigatti"
            },
            "137": {
                "part": "137",
                "total_voters": 1381,
                "support_strength": {
                    "BJP": 3
                },
                "gotv_party": {
                    "Not voted": 1381
                },
                "gotv_voted": {
                    "Not voted": 1381
                },
                "piv_level_1": "26 - Neelavva  Yalappa Aravalada"
            },
            "026": {
                "part": "026",
                "total_voters": 1379,
                "support_strength": {
                    "BJP": 2
                },
                "gotv_party": {
                    "Not voted": 1379
                },
                "gotv_voted": {
                    "Not voted": 1379
                },
                "piv_level_1": "14 - Shubahs Shinde"
            },
            "145": {
                "part": "145",
                "total_voters": 1379,
                "support_strength": {
                    "BJP": 8
                },
                "gotv_party": {
                    "Not voted": 1379
                },
                "gotv_voted": {
                    "Not voted": 1379
                },
                "piv_level_1": "25 - Parashuram Sakare"
            },
            "067": {
                "part": "067",
                "total_voters": 1369,
                "support_strength": {
                    "BJP": 42,
                    "Others": 5
                },
                "gotv_party": {
                    "Not voted": 1369
                },
                "gotv_voted": {
                    "Not voted": 1369
                },
                "piv_level_1": "18-Shivu Heremath"
            },
            "061": {
                "part": "061",
                "total_voters": 1368,
                "support_strength": {
                    "BJP": 74,
                    "INC": 18,
                    "Others": 13
                },
                "gotv_party": {
                    "Not voted": 1368
                },
                "gotv_voted": {
                    "Not voted": 1368
                },
                "piv_level_1": "10 - Chandrakala Kotabagi"
            },
            "120": {
                "part": "120",
                "total_voters": 1364,
                "support_strength": {
                    "BJP": 16,
                    "Others": 2
                },
                "gotv_party": {
                    "Not voted": 1364
                },
                "gotv_voted": {
                    "Not voted": 1364
                },
                "piv_level_1": "29 - Mallikarjuna Navanagar"
            },
            "218": {
                "part": "218",
                "total_voters": 1364,
                "support_strength": {
                    "BJP": 10
                },
                "gotv_party": {
                    "Not voted": 1364
                },
                "gotv_voted": {
                    "Not voted": 1364
                },
                "piv_level_1": "32 - Satish Hanagal"
            },
            "070": {
                "part": "070",
                "total_voters": 1345,
                "support_strength": {
                    "BJP": 17
                },
                "gotv_party": {
                    "Not voted": 1345
                },
                "gotv_voted": {
                    "Not voted": 1345
                },
                "piv_level_1": "15 - Vishnu Korlahalli"
            },
            "254": {
                "part": "254",
                "total_voters": 1331,
                "support_strength": {
                    "BJP": 1
                },
                "gotv_party": {
                    "Not voted": 1331
                },
                "gotv_voted": {
                    "Not voted": 1331
                },
                "piv_level_1": "33 - Satish Hanagal"
            },
            "185": {
                "part": "185",
                "total_voters": 1329,
                "support_strength": {
                    "BJP": 8,
                    "INC": 2,
                    "Others": 2
                },
                "gotv_party": {
                    "Not voted": 1329
                },
                "gotv_voted": {
                    "Not voted": 1329
                },
                "piv_level_1": "20 - Geetha Patil"
            },
            "167": {
                "part": "167",
                "total_voters": 1322,
                "support_strength": {
                    "BJP": 84,
                    "Others": 25,
                    "INC": 9
                },
                "gotv_party": {
                    "Not voted": 1322
                },
                "gotv_voted": {
                    "Not voted": 1322
                },
                "piv_level_1": "17 - Malleshi Basappa Mammigatti"
            },
            "033": {
                "part": "033",
                "total_voters": 1320,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 1320
                },
                "gotv_voted": {
                    "Not voted": 1320
                },
                "piv_level_1": "14 - Shubahs Shinde"
            },
            "141": {
                "part": "141",
                "total_voters": 1315,
                "support_strength": {
                    "BJP": 12
                },
                "gotv_party": {
                    "Not voted": 1315
                },
                "gotv_voted": {
                    "Not voted": 1315
                },
                "piv_level_1": "26 - Neelavva  Yalappa Aravalada"
            },
            "156": {
                "part": "156",
                "total_voters": 1314,
                "support_strength": {
                    "BJP": 33,
                    "Others": 2
                },
                "gotv_party": {
                    "Not voted": 1314
                },
                "gotv_voted": {
                    "Not voted": 1314
                },
                "piv_level_1": "21 - Anand Yavagala"
            },
            "226": {
                "part": "226",
                "total_voters": 1309,
                "support_strength": {
                    "BJP": 77,
                    "Others": 5
                },
                "gotv_party": {
                    "Not voted": 1309
                },
                "gotv_voted": {
                    "Not voted": 1309
                },
                "piv_level_1": "30 - Ramanna Badiger"
            },
            "136": {
                "part": "136",
                "total_voters": 1306,
                "support_strength": {
                    "BJP": 23,
                    "INC": 2
                },
                "gotv_party": {
                    "Not voted": 1306
                },
                "gotv_voted": {
                    "Not voted": 1306
                },
                "piv_level_1": "26 - Neelavva  Yalappa Aravalada"
            },
            "031": {
                "part": "031",
                "total_voters": 1295,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 1295
                },
                "gotv_voted": {
                    "Not voted": 1295
                },
                "piv_level_1": "14 - Shubahs Shinde"
            },
            "256": {
                "part": "256",
                "total_voters": 1294,
                "support_strength": {
                    "BJP": 4
                },
                "gotv_party": {
                    "Not voted": 1294
                },
                "gotv_voted": {
                    "Not voted": 1294
                },
                "piv_level_1": "34 - Akshata Roogi"
            },
            "236": {
                "part": "236",
                "total_voters": 1292,
                "support_strength": {
                    "BJP": 57,
                    "Others": 10,
                    "INC": 4,
                    "AIMIM": 3
                },
                "gotv_party": {
                    "Not voted": 1292
                },
                "gotv_voted": {
                    "Not voted": 1292
                },
                "piv_level_1": "31 - Eeranna Pujar"
            },
            "212": {
                "part": "212",
                "total_voters": 1286,
                "support_strength": {
                    "BJP": 129,
                    "Others": 3,
                    "INC": 2
                },
                "gotv_party": {
                    "Not voted": 1286
                },
                "gotv_voted": {
                    "Not voted": 1286
                },
                "piv_level_1": "30 - Ramanna Badiger"
            },
            "262": {
                "part": "262",
                "total_voters": 1281,
                "support_strength": {
                    "BJP": 7
                },
                "gotv_party": {
                    "Not voted": 1281
                },
                "gotv_voted": {
                    "Not voted": 1281
                },
                "piv_level_1": "34 - Akshata Roogi"
            },
            "018": {
                "part": "018",
                "total_voters": 1278,
                "support_strength": {
                    "BJP": 1
                },
                "gotv_party": {
                    "Not voted": 1278
                },
                "gotv_voted": {
                    "Not voted": 1278
                },
                "piv_level_1": "13 - Suresh Bedare"
            },
            "241": {
                "part": "241",
                "total_voters": 1275,
                "support_strength": {
                    "BJP": 2
                },
                "gotv_party": {
                    "Not voted": 1275
                },
                "gotv_voted": {
                    "Not voted": 1275
                },
                "piv_level_1": "32 - Satish Hanagal"
            },
            "119": {
                "part": "119",
                "total_voters": 1272,
                "support_strength": {
                    "BJP": 65,
                    "INC": 31,
                    "Others": 10,
                    "AIMIM": 2
                },
                "gotv_party": {
                    "Not voted": 1272
                },
                "gotv_voted": {
                    "Not voted": 1272
                },
                "piv_level_1": "29 - Mallikarjuna Navanagar"
            },
            "251": {
                "part": "251",
                "total_voters": 1261,
                "support_strength": {
                    "BJP": 10,
                    "JDS": 4
                },
                "gotv_party": {
                    "Not voted": 1261
                },
                "gotv_voted": {
                    "Not voted": 1261
                },
                "piv_level_1": "33 - Satish Hanagal"
            },
            "171": {
                "part": "171",
                "total_voters": 1259,
                "support_strength": {
                    "JDS": 1,
                    "Others": 1
                },
                "gotv_party": {
                    "Not voted": 1259
                },
                "gotv_voted": {
                    "Not voted": 1259
                },
                "piv_level_1": "15 - Vishnu Korlahalli"
            },
            "052": {
                "part": "052",
                "total_voters": 1257,
                "support_strength": {
                    "BJP": 14
                },
                "gotv_party": {
                    "Not voted": 1257
                },
                "gotv_voted": {
                    "Not voted": 1257
                },
                "piv_level_1": "12 - Vijayananda Shetty"
            },
            "151": {
                "part": "151",
                "total_voters": 1257,
                "support_strength": {
                    "BJP": 92
                },
                "gotv_party": {
                    "Not voted": 1257
                },
                "gotv_voted": {
                    "Not voted": 1257
                },
                "piv_level_1": "21 - Anand Yavagala"
            },
            "106": {
                "part": "106",
                "total_voters": 1251,
                "support_strength": {
                    "BJP": 2,
                    "INC": 1,
                    "Others": 1
                },
                "gotv_party": {
                    "Not voted": 1251
                },
                "gotv_voted": {
                    "Not voted": 1251
                },
                "piv_level_1": "24 - Shivanna Badavannavara"
            },
            "096": {
                "part": "096",
                "total_voters": 1248,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 1248
                },
                "gotv_voted": {
                    "Not voted": 1248
                },
                "piv_level_1": "23 - Sanjay Kapatakara"
            },
            "192": {
                "part": "192",
                "total_voters": 1245,
                "support_strength": {
                    "BJP": 4,
                    "Others": 1
                },
                "gotv_party": {
                    "Not voted": 1245
                },
                "gotv_voted": {
                    "Not voted": 1245
                },
                "piv_level_1": "20 - Geetha Patil"
            },
            "260": {
                "part": "260",
                "total_voters": 1241,
                "support_strength": {
                    "BJP": 2
                },
                "gotv_party": {
                    "Not voted": 1241
                },
                "gotv_voted": {
                    "Not voted": 1241
                },
                "piv_level_1": "34 - Akshata Roogi"
            },
            "195": {
                "part": "195",
                "total_voters": 1237,
                "support_strength": {
                    "INC": 1,
                    "Others": 1
                },
                "gotv_party": {
                    "Not voted": 1237
                },
                "gotv_voted": {
                    "Not voted": 1237
                },
                "piv_level_1": "25 - Parashuram Sakare"
            },
            "053": {
                "part": "053",
                "total_voters": 1235,
                "support_strength": {
                    "BJP": 1
                },
                "gotv_party": {
                    "Not voted": 1235
                },
                "gotv_voted": {
                    "Not voted": 1235
                },
                "piv_level_1": "12 - Vijayananda Shetty"
            },
            "002": {
                "part": "002",
                "total_voters": 1234,
                "support_strength": {
                    "BJP": 140,
                    "INC": 24,
                    "Others": 6
                },
                "gotv_party": {
                    "Not voted": 1234
                },
                "gotv_voted": {
                    "Not voted": 1234
                },
                "piv_level_1": "10 - Chandrakala Kotabagi"
            },
            "037": {
                "part": "037",
                "total_voters": 1233,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 1233
                },
                "gotv_voted": {
                    "Not voted": 1233
                },
                "piv_level_1": "14 - Shubahs Shinde"
            },
            "220": {
                "part": "220",
                "total_voters": 1229,
                "support_strength": {
                    "BJP": 31,
                    "Others": 4,
                    "AIMIM": 1
                },
                "gotv_party": {
                    "Not voted": 1229
                },
                "gotv_voted": {
                    "Not voted": 1229
                },
                "piv_level_1": "32 - Satish Hanagal"
            },
            "138": {
                "part": "138",
                "total_voters": 1226,
                "support_strength": {
                    "BJP": 17
                },
                "gotv_party": {
                    "Not voted": 1226
                },
                "gotv_voted": {
                    "Not voted": 1226
                },
                "piv_level_1": "26 - Neelavva  Yalappa Aravalada"
            },
            "093": {
                "part": "093",
                "total_voters": 1221,
                "support_strength": {
                    "BJP": 1,
                    "Others": 1
                },
                "gotv_party": {
                    "Not voted": 1221
                },
                "gotv_voted": {
                    "Not voted": 1221
                },
                "piv_level_1": "22 - Parvativva Hittalamani"
            },
            "007": {
                "part": "007",
                "total_voters": 1218,
                "support_strength": {
                    "BJP": 1
                },
                "gotv_party": {
                    "Not voted": 1218
                },
                "gotv_voted": {
                    "Not voted": 1218
                },
                "piv_level_1": "11 - Manjunath Battanna"
            },
            "118": {
                "part": "118",
                "total_voters": 1217,
                "support_strength": {
                    "BJP": 97,
                    "INC": 31,
                    "Others": 12
                },
                "gotv_party": {
                    "Not voted": 1217
                },
                "gotv_voted": {
                    "Not voted": 1217
                },
                "piv_level_1": "29 - Mallikarjuna Navanagar"
            },
            "157": {
                "part": "157",
                "total_voters": 1213,
                "support_strength": {
                    "BJP": 72,
                    "INC": 8,
                    "Others": 6
                },
                "gotv_party": {
                    "Not voted": 1213
                },
                "gotv_voted": {
                    "Not voted": 1213
                },
                "piv_level_1": "17 - Malleshi Basappa Mammigatti"
            },
            "228": {
                "part": "228",
                "total_voters": 1213,
                "support_strength": {
                    "BJP": 77,
                    "INC": 3,
                    "Others": 2
                },
                "gotv_party": {
                    "Not voted": 1213
                },
                "gotv_voted": {
                    "Not voted": 1213
                },
                "piv_level_1": "31 - Eeranna Pujar"
            },
            "126": {
                "part": "126",
                "total_voters": 1211,
                "support_strength": {
                    "BJP": 14,
                    "Others": 2
                },
                "gotv_party": {
                    "Not voted": 1211
                },
                "gotv_voted": {
                    "Not voted": 1211
                },
                "piv_level_1": "27 - Sunitha"
            },
            "108": {
                "part": "108",
                "total_voters": 1200,
                "support_strength": {
                    "INC": 21,
                    "BJP": 18
                },
                "gotv_party": {
                    "Not voted": 1200
                },
                "gotv_voted": {
                    "Not voted": 1200
                },
                "piv_level_1": "24 - Shivanna Badavannavara"
            },
            "054": {
                "part": "054",
                "total_voters": 1199,
                "support_strength": {
                    "BJP": 3
                },
                "gotv_party": {
                    "Not voted": 1199
                },
                "gotv_voted": {
                    "Not voted": 1199
                },
                "piv_level_1": "12 - Vijayananda Shetty"
            },
            "036": {
                "part": "036",
                "total_voters": 1198,
                "support_strength": {
                    "Others": 1
                },
                "gotv_party": {
                    "Not voted": 1198
                },
                "gotv_voted": {
                    "Not voted": 1198
                },
                "piv_level_1": "14 - Shubahs Shinde"
            },
            "124": {
                "part": "124",
                "total_voters": 1195,
                "support_strength": {
                    "BJP": 14,
                    "Others": 4
                },
                "gotv_party": {
                    "Not voted": 1195
                },
                "gotv_voted": {
                    "Not voted": 1195
                },
                "piv_level_1": "27 - Sunitha"
            },
            "221": {
                "part": "221",
                "total_voters": 1194,
                "support_strength": {
                    "BJP": 164
                },
                "gotv_party": {
                    "Not voted": 1194
                },
                "gotv_voted": {
                    "Not voted": 1194
                },
                "piv_level_1": "30 - Ramanna Badiger"
            },
            "083": {
                "part": "083",
                "total_voters": 1186,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 1186
                },
                "gotv_voted": {
                    "Not voted": 1186
                },
                "piv_level_1": "16 - Rajeshwari Salagatti"
            },
            "248": {
                "part": "248",
                "total_voters": 1186,
                "support_strength": {
                    "BJP": 11,
                    "Others": 1
                },
                "gotv_party": {
                    "Not voted": 1186
                },
                "gotv_voted": {
                    "Not voted": 1186
                },
                "piv_level_1": "33 - Satish Hanagal"
            },
            "089": {
                "part": "089",
                "total_voters": 1185,
                "support_strength": {
                    "BJP": 2
                },
                "gotv_party": {
                    "Not voted": 1185
                },
                "gotv_voted": {
                    "Not voted": 1185
                },
                "piv_level_1": "22 - Parvativva Hittalamani"
            },
            "163": {
                "part": "163",
                "total_voters": 1184,
                "support_strength": {
                    "BJP": 33,
                    "Others": 16,
                    "INC": 10,
                    "JDS": 1
                },
                "gotv_party": {
                    "Not voted": 1184
                },
                "gotv_voted": {
                    "Not voted": 1184
                },
                "piv_level_1": "17 - Malleshi Basappa Mammigatti"
            },
            "263": {
                "part": "263",
                "total_voters": 1184,
                "support_strength": {
                    "BJP": 9
                },
                "gotv_party": {
                    "Not voted": 1184
                },
                "gotv_voted": {
                    "Not voted": 1184
                },
                "piv_level_1": "34 - Akshata Roogi"
            },
            "231": {
                "part": "231",
                "total_voters": 1173,
                "support_strength": {
                    "BJP": 74,
                    "INC": 3
                },
                "gotv_party": {
                    "Not voted": 1173
                },
                "gotv_voted": {
                    "Not voted": 1173
                },
                "piv_level_1": "31 - Eeranna Pujar"
            },
            "158": {
                "part": "158",
                "total_voters": 1167,
                "support_strength": {
                    "BJP": 20,
                    "INC": 3,
                    "Others": 2,
                    "JDS": 1
                },
                "gotv_party": {
                    "Not voted": 1167
                },
                "gotv_voted": {
                    "Not voted": 1167
                },
                "piv_level_1": "17 - Malleshi Basappa Mammigatti"
            },
            "133": {
                "part": "133",
                "total_voters": 1165,
                "support_strength": {
                    "Others": 2
                },
                "gotv_party": {
                    "Not voted": 1165
                },
                "gotv_voted": {
                    "Not voted": 1165
                },
                "piv_level_1": "27 - Sunitha"
            },
            "005": {
                "part": "005",
                "total_voters": 1161,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 1161
                },
                "gotv_voted": {
                    "Not voted": 1161
                },
                "piv_level_1": "11 - Manjunath Battanna"
            },
            "225": {
                "part": "225",
                "total_voters": 1151,
                "support_strength": {
                    "BJP": 11
                },
                "gotv_party": {
                    "Not voted": 1151
                },
                "gotv_voted": {
                    "Not voted": 1151
                },
                "piv_level_1": "30 - Ramanna Badiger"
            },
            "100": {
                "part": "100",
                "total_voters": 1147,
                "support_strength": {
                    "BJP": 55,
                    "Others": 13,
                    "INC": 12,
                    "JDS": 2
                },
                "gotv_party": {
                    "Not voted": 1147
                },
                "gotv_voted": {
                    "Not voted": 1147
                },
                "piv_level_1": "24 - Shivanna Badavannavara"
            },
            "264": {
                "part": "264",
                "total_voters": 1147,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 1147
                },
                "gotv_voted": {
                    "Not voted": 1147
                },
                "piv_level_1": "34 - Akshata Roogi"
            },
            "196": {
                "part": "196",
                "total_voters": 1130,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 1130
                },
                "gotv_voted": {
                    "Not voted": 1130
                },
                "piv_level_1": "25 - Parashuram Sakare"
            },
            "117": {
                "part": "117",
                "total_voters": 1129,
                "support_strength": {
                    "BJP": 71,
                    "INC": 15,
                    "Others": 10
                },
                "gotv_party": {
                    "Not voted": 1129
                },
                "gotv_voted": {
                    "Not voted": 1129
                },
                "piv_level_1": "29 - Mallikarjuna Navanagar"
            },
            "160": {
                "part": "160",
                "total_voters": 1129,
                "support_strength": {
                    "BJP": 84,
                    "Others": 17,
                    "INC": 10,
                    "AIMIM": 1
                },
                "gotv_party": {
                    "Not voted": 1129
                },
                "gotv_voted": {
                    "Not voted": 1129
                },
                "piv_level_1": "17 - Malleshi Basappa Mammigatti"
            },
            "144": {
                "part": "144",
                "total_voters": 1128,
                "support_strength": {
                    "BJP": 16,
                    "INC": 1
                },
                "gotv_party": {
                    "Not voted": 1128
                },
                "gotv_voted": {
                    "Not voted": 1128
                },
                "piv_level_1": "25 - Parashuram Sakare"
            },
            "056": {
                "part": "056",
                "total_voters": 1126,
                "support_strength": {
                    "BJP": 1,
                    "Others": 1
                },
                "gotv_party": {
                    "Not voted": 1126
                },
                "gotv_voted": {
                    "Not voted": 1126
                },
                "piv_level_1": "10 - Chandrakala Kotabagi"
            },
            "227": {
                "part": "227",
                "total_voters": 1120,
                "support_strength": {
                    "BJP": 86,
                    "AIMIM": 1
                },
                "gotv_party": {
                    "Not voted": 1120
                },
                "gotv_voted": {
                    "Not voted": 1120
                },
                "piv_level_1": "31 - Eeranna Pujar"
            },
            "159": {
                "part": "159",
                "total_voters": 1118,
                "support_strength": {
                    "BJP": 100,
                    "INC": 5,
                    "JDS": 1
                },
                "gotv_party": {
                    "Not voted": 1118
                },
                "gotv_voted": {
                    "Not voted": 1118
                },
                "piv_level_1": "21 - Anand Yavagala"
            },
            "250": {
                "part": "250",
                "total_voters": 1118,
                "support_strength": {
                    "BJP": 25,
                    "JDS": 1,
                    "Others": 1
                },
                "gotv_party": {
                    "Not voted": 1118
                },
                "gotv_voted": {
                    "Not voted": 1118
                },
                "piv_level_1": "33 - Satish Hanagal"
            },
            "098": {
                "part": "098",
                "total_voters": 1117,
                "support_strength": {
                    "BJP": 1
                },
                "gotv_party": {
                    "Not voted": 1117
                },
                "gotv_voted": {
                    "Not voted": 1117
                },
                "piv_level_1": "23 - Sanjay Kapatakara"
            },
            "189": {
                "part": "189",
                "total_voters": 1114,
                "support_strength": {
                    "INC": 2,
                    "BJP": 1
                },
                "gotv_party": {
                    "Not voted": 1114
                },
                "gotv_voted": {
                    "Not voted": 1114
                },
                "piv_level_1": "20 - Geetha Patil"
            },
            "110": {
                "part": "110",
                "total_voters": 1106,
                "support_strength": {
                    "BJP": 1,
                    "Others": 1
                },
                "gotv_party": {
                    "Not voted": 1106
                },
                "gotv_voted": {
                    "Not voted": 1106
                },
                "piv_level_1": "24 - Shivanna Badavannavara"
            },
            "242": {
                "part": "242",
                "total_voters": 1102,
                "support_strength": {
                    "BJP": 1
                },
                "gotv_party": {
                    "Not voted": 1102
                },
                "gotv_voted": {
                    "Not voted": 1102
                },
                "piv_level_1": "32 - Satish Hanagal"
            },
            "239": {
                "part": "239",
                "total_voters": 1097,
                "support_strength": {
                    "BJP": 3
                },
                "gotv_party": {
                    "Not voted": 1097
                },
                "gotv_voted": {
                    "Not voted": 1097
                },
                "piv_level_1": "32 - Satish Hanagal"
            },
            "180": {
                "part": "180",
                "total_voters": 1093,
                "support_strength": {
                    "BJP": 24,
                    "Others": 8,
                    "INC": 2
                },
                "gotv_party": {
                    "Not voted": 1093
                },
                "gotv_voted": {
                    "Not voted": 1093
                },
                "piv_level_1": "19-Jyoti Patil"
            },
            "009": {
                "part": "009",
                "total_voters": 1091,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 1091
                },
                "gotv_voted": {
                    "Not voted": 1091
                },
                "piv_level_1": "11 - Manjunath Battanna"
            },
            "066": {
                "part": "066",
                "total_voters": 1088,
                "support_strength": {
                    "BJP": 11,
                    "INC": 1,
                    "Others": 1
                },
                "gotv_party": {
                    "Not voted": 1088
                },
                "gotv_voted": {
                    "Not voted": 1088
                },
                "piv_level_1": "18-Shivu Heremath"
            },
            "184": {
                "part": "184",
                "total_voters": 1085,
                "support_strength": {
                    "BJP": 12,
                    "INC": 2,
                    "JDS": 1,
                    "Others": 1
                },
                "gotv_party": {
                    "Not voted": 1085
                },
                "gotv_voted": {
                    "Not voted": 1085
                },
                "piv_level_1": "20 - Geetha Patil"
            },
            "219": {
                "part": "219",
                "total_voters": 1081,
                "support_strength": {
                    "BJP": 18,
                    "Others": 2
                },
                "gotv_party": {
                    "Not voted": 1081
                },
                "gotv_voted": {
                    "Not voted": 1081
                },
                "piv_level_1": "32 - Satish Hanagal"
            },
            "165": {
                "part": "165",
                "total_voters": 1073,
                "support_strength": {
                    "BJP": 16,
                    "INC": 13,
                    "Others": 9,
                    "AAP": 1,
                    "AIMIM": 1
                },
                "gotv_party": {
                    "Not voted": 1073
                },
                "gotv_voted": {
                    "Not voted": 1073
                },
                "piv_level_1": "17 - Malleshi Basappa Mammigatti"
            },
            "075": {
                "part": "075",
                "total_voters": 1070,
                "support_strength": {
                    "BJP": 1
                },
                "gotv_party": {
                    "Not voted": 1070
                },
                "gotv_voted": {
                    "Not voted": 1070
                },
                "piv_level_1": "18-Shivu Heremath"
            },
            "062": {
                "part": "062",
                "total_voters": 1069,
                "support_strength": {
                    "BJP": 61,
                    "INC": 11,
                    "Others": 6
                },
                "gotv_party": {
                    "Not voted": 1069
                },
                "gotv_voted": {
                    "Not voted": 1069
                },
                "piv_level_1": "10 - Chandrakala Kotabagi"
            },
            "088": {
                "part": "088",
                "total_voters": 1063,
                "support_strength": {
                    "Others": 13,
                    "INC": 5,
                    "BJP": 3,
                    "JDS": 3
                },
                "gotv_party": {
                    "Not voted": 1063
                },
                "gotv_voted": {
                    "Not voted": 1063
                },
                "piv_level_1": "17 - Malleshi Basappa Mammigatti"
            },
            "179": {
                "part": "179",
                "total_voters": 1063,
                "support_strength": {
                    "BJP": 35,
                    "INC": 6,
                    "Others": 6
                },
                "gotv_party": {
                    "Not voted": 1063
                },
                "gotv_voted": {
                    "Not voted": 1063
                },
                "piv_level_1": "19-Jyoti Patil"
            },
            "183": {
                "part": "183",
                "total_voters": 1063,
                "support_strength": {
                    "BJP": 8,
                    "Others": 2,
                    "INC": 1
                },
                "gotv_party": {
                    "Not voted": 1063
                },
                "gotv_voted": {
                    "Not voted": 1063
                },
                "piv_level_1": "20 - Geetha Patil"
            },
            "111": {
                "part": "111",
                "total_voters": 1062,
                "support_strength": {
                    "INC": 2,
                    "BJP": 1
                },
                "gotv_party": {
                    "Not voted": 1062
                },
                "gotv_voted": {
                    "Not voted": 1062
                },
                "piv_level_1": "24 - Shivanna Badavannavara"
            },
            "257": {
                "part": "257",
                "total_voters": 1061,
                "support_strength": {
                    "BJP": 15,
                    "AAP": 1,
                    "AIMIM": 1,
                    "Others": 1
                },
                "gotv_party": {
                    "Not voted": 1061
                },
                "gotv_voted": {
                    "Not voted": 1061
                },
                "piv_level_1": "34 - Akshata Roogi"
            },
            "265": {
                "part": "265",
                "total_voters": 1060,
                "support_strength": {
                    "Others": 1
                },
                "gotv_party": {
                    "Not voted": 1060
                },
                "gotv_voted": {
                    "Not voted": 1060
                },
                "piv_level_1": "34 - Akshata Roogi"
            },
            "134": {
                "part": "134",
                "total_voters": 1059,
                "support_strength": {
                    "BJP": 10
                },
                "gotv_party": {
                    "Not voted": 1059
                },
                "gotv_voted": {
                    "Not voted": 1059
                },
                "piv_level_1": "26 - Neelavva  Yalappa Aravalada"
            },
            "217": {
                "part": "217",
                "total_voters": 1058,
                "support_strength": {
                    "BJP": 20,
                    "Others": 4,
                    "INC": 1
                },
                "gotv_party": {
                    "Not voted": 1058
                },
                "gotv_voted": {
                    "Not voted": 1058
                },
                "piv_level_1": "32 - Satish Hanagal"
            },
            "095": {
                "part": "095",
                "total_voters": 1057,
                "support_strength": {
                    "BJP": 1
                },
                "gotv_party": {
                    "Not voted": 1057
                },
                "gotv_voted": {
                    "Not voted": 1057
                },
                "piv_level_1": "23 - Sanjay Kapatakara"
            },
            "113": {
                "part": "113",
                "total_voters": 1054,
                "support_strength": {
                    "BJP": 81,
                    "Others": 12,
                    "INC": 10
                },
                "gotv_party": {
                    "Not voted": 1054
                },
                "gotv_voted": {
                    "Not voted": 1054
                },
                "piv_level_1": "29 - Mallikarjuna Navanagar"
            },
            "173": {
                "part": "173",
                "total_voters": 1054,
                "support_strength": {
                    "INC": 1
                },
                "gotv_party": {
                    "Not voted": 1054
                },
                "gotv_voted": {
                    "Not voted": 1054
                },
                "piv_level_1": "15 - Vishnu Korlahalli"
            },
            "099": {
                "part": "099",
                "total_voters": 1049,
                "support_strength": {
                    "BJP": 1
                },
                "gotv_party": {
                    "Not voted": 1049
                },
                "gotv_voted": {
                    "Not voted": 1049
                },
                "piv_level_1": "23 - Sanjay Kapatakara"
            },
            "121": {
                "part": "121",
                "total_voters": 1044,
                "support_strength": {
                    "BJP": 12,
                    "Others": 7,
                    "INC": 2
                },
                "gotv_party": {
                    "Not voted": 1044
                },
                "gotv_voted": {
                    "Not voted": 1044
                },
                "piv_level_1": "29 - Mallikarjuna Navanagar"
            },
            "128": {
                "part": "128",
                "total_voters": 1044,
                "support_strength": {
                    "BJP": 8,
                    "INC": 1,
                    "Others": 1
                },
                "gotv_party": {
                    "Not voted": 1044
                },
                "gotv_voted": {
                    "Not voted": 1044
                },
                "piv_level_1": "27 - Sunitha"
            },
            "122": {
                "part": "122",
                "total_voters": 1043,
                "support_strength": {
                    "BJP": 7,
                    "Others": 5,
                    "JDS": 1
                },
                "gotv_party": {
                    "Not voted": 1043
                },
                "gotv_voted": {
                    "Not voted": 1043
                },
                "piv_level_1": "27 - Sunitha"
            },
            "249": {
                "part": "249",
                "total_voters": 1042,
                "support_strength": {
                    "BJP": 5,
                    "Others": 2
                },
                "gotv_party": {
                    "Not voted": 1042
                },
                "gotv_voted": {
                    "Not voted": 1042
                },
                "piv_level_1": "33 - Satish Hanagal"
            },
            "168": {
                "part": "168",
                "total_voters": 1037,
                "support_strength": {
                    "BJP": 31,
                    "INC": 3,
                    "Others": 3
                },
                "gotv_party": {
                    "Not voted": 1037
                },
                "gotv_voted": {
                    "Not voted": 1037
                },
                "piv_level_1": "19-Jyoti Patil"
            },
            "216": {
                "part": "216",
                "total_voters": 1034,
                "support_strength": {
                    "BJP": 16
                },
                "gotv_party": {
                    "Not voted": 1034
                },
                "gotv_voted": {
                    "Not voted": 1034
                },
                "piv_level_1": "32 - Satish Hanagal"
            },
            "086": {
                "part": "086",
                "total_voters": 1033,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 1033
                },
                "gotv_voted": {
                    "Not voted": 1033
                },
                "piv_level_1": "16 - Rajeshwari Salagatti"
            },
            "030": {
                "part": "030",
                "total_voters": 1027,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 1027
                },
                "gotv_voted": {
                    "Not voted": 1027
                },
                "piv_level_1": "14 - Shubahs Shinde"
            },
            "072": {
                "part": "072",
                "total_voters": 1027,
                "support_strength": {
                    "BJP": 22,
                    "Others": 12,
                    "INC": 1
                },
                "gotv_party": {
                    "Not voted": 1027
                },
                "gotv_voted": {
                    "Not voted": 1027
                },
                "piv_level_1": "18-Shivu Heremath"
            },
            "174": {
                "part": "174",
                "total_voters": 1022,
                "support_strength": {
                    "BJP": 6,
                    "Others": 5
                },
                "gotv_party": {
                    "Not voted": 1022
                },
                "gotv_voted": {
                    "Not voted": 1022
                },
                "piv_level_1": "18-Shivu Heremath"
            },
            "016": {
                "part": "016",
                "total_voters": 1016,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 1016
                },
                "gotv_voted": {
                    "Not voted": 1016
                },
                "piv_level_1": "13 - Suresh Bedare"
            },
            "166": {
                "part": "166",
                "total_voters": 1016,
                "support_strength": {
                    "INC": 27,
                    "BJP": 16,
                    "Others": 11,
                    "AIMIM": 3,
                    "JDS": 2
                },
                "gotv_party": {
                    "Not voted": 1016
                },
                "gotv_voted": {
                    "Not voted": 1016
                },
                "piv_level_1": "17 - Malleshi Basappa Mammigatti"
            },
            "092": {
                "part": "092",
                "total_voters": 1008,
                "support_strength": {
                    "BJP": 1
                },
                "gotv_party": {
                    "Not voted": 1008
                },
                "gotv_voted": {
                    "Not voted": 1008
                },
                "piv_level_1": "22 - Parvativva Hittalamani"
            },
            "032": {
                "part": "032",
                "total_voters": 1003,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 1003
                },
                "gotv_voted": {
                    "Not voted": 1003
                },
                "piv_level_1": "14 - Shubahs Shinde"
            },
            "161": {
                "part": "161",
                "total_voters": 1003,
                "support_strength": {
                    "Others": 22,
                    "BJP": 8,
                    "AIMIM": 1,
                    "INC": 1
                },
                "gotv_party": {
                    "Not voted": 1003
                },
                "gotv_voted": {
                    "Not voted": 1003
                },
                "piv_level_1": "17 - Malleshi Basappa Mammigatti"
            },
            "188": {
                "part": "188",
                "total_voters": 1001,
                "support_strength": {
                    "BJP": 1,
                    "INC": 1
                },
                "gotv_party": {
                    "Not voted": 1001
                },
                "gotv_voted": {
                    "Not voted": 1001
                },
                "piv_level_1": "20 - Geetha Patil"
            },
            "207": {
                "part": "207",
                "total_voters": 1001,
                "support_strength": {
                    "BJP": 4,
                    "INC": 1,
                    "Others": 1
                },
                "gotv_party": {
                    "Not voted": 1001
                },
                "gotv_voted": {
                    "Not voted": 1001
                },
                "piv_level_1": "28 - Chandrashekara Managundi"
            },
            "013": {
                "part": "013",
                "total_voters": 998,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 998
                },
                "gotv_voted": {
                    "Not voted": 998
                },
                "piv_level_1": "13 - Suresh Bedare"
            },
            "027": {
                "part": "027",
                "total_voters": 996,
                "support_strength": {
                    "BJP": 2
                },
                "gotv_party": {
                    "Not voted": 996
                },
                "gotv_voted": {
                    "Not voted": 996
                },
                "piv_level_1": "14 - Shubahs Shinde"
            },
            "175": {
                "part": "175",
                "total_voters": 994,
                "support_strength": {
                    "BJP": 12,
                    "Others": 5,
                    "AIMIM": 1,
                    "INC": 1
                },
                "gotv_party": {
                    "Not voted": 994
                },
                "gotv_voted": {
                    "Not voted": 994
                },
                "piv_level_1": "18-Shivu Heremath"
            },
            "234": {
                "part": "234",
                "total_voters": 994,
                "support_strength": {
                    "BJP": 88,
                    "Others": 21,
                    "AIMIM": 6,
                    "INC": 3
                },
                "gotv_party": {
                    "Not voted": 994
                },
                "gotv_voted": {
                    "Not voted": 994
                },
                "piv_level_1": "31 - Eeranna Pujar"
            },
            "172": {
                "part": "172",
                "total_voters": 992,
                "support_strength": {
                    "Others": 1
                },
                "gotv_party": {
                    "Not voted": 992
                },
                "gotv_voted": {
                    "Not voted": 992
                },
                "piv_level_1": "15 - Vishnu Korlahalli"
            },
            "222": {
                "part": "222",
                "total_voters": 989,
                "support_strength": {
                    "BJP": 16
                },
                "gotv_party": {
                    "Not voted": 989
                },
                "gotv_voted": {
                    "Not voted": 989
                },
                "piv_level_1": "30 - Ramanna Badiger"
            },
            "112": {
                "part": "112",
                "total_voters": 985,
                "support_strength": {
                    "BJP": 133,
                    "INC": 13,
                    "Others": 8
                },
                "gotv_party": {
                    "Not voted": 985
                },
                "gotv_voted": {
                    "Not voted": 985
                },
                "piv_level_1": "29 - Mallikarjuna Navanagar"
            },
            "008": {
                "part": "008",
                "total_voters": 977,
                "support_strength": {
                    "BJP": 2
                },
                "gotv_party": {
                    "Not voted": 977
                },
                "gotv_voted": {
                    "Not voted": 977
                },
                "piv_level_1": "11 - Manjunath Battanna"
            },
            "081": {
                "part": "081",
                "total_voters": 974,
                "support_strength": {
                    "BJP": 1
                },
                "gotv_party": {
                    "Not voted": 974
                },
                "gotv_voted": {
                    "Not voted": 974
                },
                "piv_level_1": "16 - Rajeshwari Salagatti"
            },
            "023": {
                "part": "023",
                "total_voters": 973,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 973
                },
                "gotv_voted": {
                    "Not voted": 973
                },
                "piv_level_1": "13 - Suresh Bedare"
            },
            "245": {
                "part": "245",
                "total_voters": 970,
                "support_strength": {
                    "BJP": 39,
                    "Others": 6,
                    "INC": 2
                },
                "gotv_party": {
                    "Not voted": 970
                },
                "gotv_voted": {
                    "Not voted": 970
                },
                "piv_level_1": "32 - Satish Hanagal"
            },
            "186": {
                "part": "186",
                "total_voters": 964,
                "support_strength": {
                    "BJP": 6,
                    "INC": 2
                },
                "gotv_party": {
                    "Not voted": 964
                },
                "gotv_voted": {
                    "Not voted": 964
                },
                "piv_level_1": "20 - Geetha Patil"
            },
            "021": {
                "part": "021",
                "total_voters": 956,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 956
                },
                "gotv_voted": {
                    "Not voted": 956
                },
                "piv_level_1": "13 - Suresh Bedare"
            },
            "084": {
                "part": "084",
                "total_voters": 955,
                "support_strength": {
                    "BJP": 1
                },
                "gotv_party": {
                    "Not voted": 955
                },
                "gotv_voted": {
                    "Not voted": 955
                },
                "piv_level_1": "16 - Rajeshwari Salagatti"
            },
            "105": {
                "part": "105",
                "total_voters": 950,
                "support_strength": {
                    "BJP": 11,
                    "Others": 6,
                    "INC": 1
                },
                "gotv_party": {
                    "Not voted": 950
                },
                "gotv_voted": {
                    "Not voted": 950
                },
                "piv_level_1": "24 - Shivanna Badavannavara"
            },
            "034": {
                "part": "034",
                "total_voters": 943,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 943
                },
                "gotv_voted": {
                    "Not voted": 943
                },
                "piv_level_1": "14 - Shubahs Shinde"
            },
            "006": {
                "part": "006",
                "total_voters": 938,
                "support_strength": {
                    "BJP": 3,
                    "Others": 1
                },
                "gotv_party": {
                    "Not voted": 938
                },
                "gotv_voted": {
                    "Not voted": 938
                },
                "piv_level_1": "11 - Manjunath Battanna"
            },
            "046": {
                "part": "046",
                "total_voters": 938,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 938
                },
                "gotv_voted": {
                    "Not voted": 938
                },
                "piv_level_1": "13 - Suresh Bedare"
            },
            "205": {
                "part": "205",
                "total_voters": 936,
                "support_strength": {
                    "BJP": 162,
                    "INC": 8,
                    "JDS": 1
                },
                "gotv_party": {
                    "Not voted": 936
                },
                "gotv_voted": {
                    "Not voted": 936
                },
                "piv_level_1": "28 - Chandrashekara Managundi"
            },
            "247": {
                "part": "247",
                "total_voters": 933,
                "support_strength": {
                    "BJP": 61
                },
                "gotv_party": {
                    "Not voted": 933
                },
                "gotv_voted": {
                    "Not voted": 933
                },
                "piv_level_1": "33 - Satish Hanagal"
            },
            "155": {
                "part": "155",
                "total_voters": 932,
                "support_strength": {
                    "INC": 2,
                    "BJP": 1
                },
                "gotv_party": {
                    "Not voted": 932
                },
                "gotv_voted": {
                    "Not voted": 932
                },
                "piv_level_1": "20 - Geetha Patil"
            },
            "187": {
                "part": "187",
                "total_voters": 932,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 932
                },
                "gotv_voted": {
                    "Not voted": 932
                },
                "piv_level_1": "21 - Anand Yavagala"
            },
            "068": {
                "part": "068",
                "total_voters": 931,
                "support_strength": {
                    "BJP": 9
                },
                "gotv_party": {
                    "Not voted": 931
                },
                "gotv_voted": {
                    "Not voted": 931
                },
                "piv_level_1": "15 - Vishnu Korlahalli"
            },
            "215": {
                "part": "215",
                "total_voters": 931,
                "support_strength": {
                    "BJP": 70,
                    "Others": 5,
                    "INC": 3,
                    "AAP": 1,
                    "JDS": 1
                },
                "gotv_party": {
                    "Not voted": 931
                },
                "gotv_voted": {
                    "Not voted": 931
                },
                "piv_level_1": "30 - Ramanna Badiger"
            },
            "057": {
                "part": "057",
                "total_voters": 929,
                "support_strength": {
                    "BJP": 2
                },
                "gotv_party": {
                    "Not voted": 929
                },
                "gotv_voted": {
                    "Not voted": 929
                },
                "piv_level_1": "10 - Chandrakala Kotabagi"
            },
            "232": {
                "part": "232",
                "total_voters": 929,
                "support_strength": {
                    "BJP": 99,
                    "Others": 19,
                    "AIMIM": 9,
                    "INC": 4
                },
                "gotv_party": {
                    "Not voted": 831,
                    "BJP": 95,
                    "INC": 3
                },
                "gotv_voted": {
                    "Not voted": 831,
                    "voted": 98
                },
                "piv_level_1": "31 - Eeranna Pujar"
            },
            "230": {
                "part": "230",
                "total_voters": 927,
                "support_strength": {
                    "BJP": 52,
                    "INC": 5,
                    "Others": 2
                },
                "gotv_party": {
                    "Not voted": 927
                },
                "gotv_voted": {
                    "Not voted": 927
                },
                "piv_level_1": "31 - Eeranna Pujar"
            },
            "071": {
                "part": "071",
                "total_voters": 926,
                "support_strength": {
                    "BJP": 14,
                    "Others": 4
                },
                "gotv_party": {
                    "Not voted": 926
                },
                "gotv_voted": {
                    "Not voted": 926
                },
                "piv_level_1": "18-Shivu Heremath"
            },
            "041": {
                "part": "041",
                "total_voters": 921,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 921
                },
                "gotv_voted": {
                    "Not voted": 921
                },
                "piv_level_1": "16 - Rajeshwari Salagatti"
            },
            "029": {
                "part": "029",
                "total_voters": 918,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 918
                },
                "gotv_voted": {
                    "Not voted": 918
                },
                "piv_level_1": "14 - Shubahs Shinde"
            },
            "210": {
                "part": "210",
                "total_voters": 915,
                "support_strength": {
                    "BJP": 65,
                    "Others": 3
                },
                "gotv_party": {
                    "Not voted": 915
                },
                "gotv_voted": {
                    "Not voted": 915
                },
                "piv_level_1": "30 - Ramanna Badiger"
            },
            "162": {
                "part": "162",
                "total_voters": 913,
                "support_strength": {
                    "Others": 20,
                    "BJP": 13,
                    "JDS": 2
                },
                "gotv_party": {
                    "Not voted": 913
                },
                "gotv_voted": {
                    "Not voted": 913
                },
                "piv_level_1": "17 - Malleshi Basappa Mammigatti"
            },
            "063": {
                "part": "063",
                "total_voters": 911,
                "support_strength": {
                    "BJP": 10,
                    "Others": 1
                },
                "gotv_party": {
                    "Not voted": 911
                },
                "gotv_voted": {
                    "Not voted": 911
                },
                "piv_level_1": "10 - Chandrakala Kotabagi"
            },
            "253": {
                "part": "253",
                "total_voters": 910,
                "support_strength": {
                    "BJP": 1,
                    "INC": 1,
                    "JDS": 1
                },
                "gotv_party": {
                    "Not voted": 910
                },
                "gotv_voted": {
                    "Not voted": 910
                },
                "piv_level_1": "33 - Satish Hanagal"
            },
            "152": {
                "part": "152",
                "total_voters": 909,
                "support_strength": {
                    "BJP": 1
                },
                "gotv_party": {
                    "Not voted": 909
                },
                "gotv_voted": {
                    "Not voted": 909
                },
                "piv_level_1": "22 - Parvativva Hittalamani"
            },
            "073": {
                "part": "073",
                "total_voters": 908,
                "support_strength": {
                    "BJP": 57,
                    "Others": 12,
                    "INC": 1
                },
                "gotv_party": {
                    "Not voted": 908
                },
                "gotv_voted": {
                    "Not voted": 908
                },
                "piv_level_1": "18-Shivu Heremath"
            },
            "244": {
                "part": "244",
                "total_voters": 904,
                "support_strength": {
                    "BJP": 32,
                    "INC": 3,
                    "Others": 2
                },
                "gotv_party": {
                    "Not voted": 904
                },
                "gotv_voted": {
                    "Not voted": 904
                },
                "piv_level_1": "32 - Satish Hanagal"
            },
            "243": {
                "part": "243",
                "total_voters": 903,
                "support_strength": {
                    "BJP": 43,
                    "Others": 6,
                    "INC": 3
                },
                "gotv_party": {
                    "Not voted": 903
                },
                "gotv_voted": {
                    "Not voted": 903
                },
                "piv_level_1": "32 - Satish Hanagal"
            },
            "266": {
                "part": "266",
                "total_voters": 900,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 900
                },
                "gotv_voted": {
                    "Not voted": 900
                },
                "piv_level_1": "34 - Akshata Roogi"
            },
            "125": {
                "part": "125",
                "total_voters": 899,
                "support_strength": {
                    "BJP": 9,
                    "Others": 2,
                    "INC": 1
                },
                "gotv_party": {
                    "Not voted": 899
                },
                "gotv_voted": {
                    "Not voted": 899
                },
                "piv_level_1": "27 - Sunitha"
            },
            "214": {
                "part": "214",
                "total_voters": 891,
                "support_strength": {
                    "BJP": 80,
                    "Others": 5,
                    "JDS": 1
                },
                "gotv_party": {
                    "Not voted": 891
                },
                "gotv_voted": {
                    "Not voted": 891
                },
                "piv_level_1": "30 - Ramanna Badiger"
            },
            "010": {
                "part": "010",
                "total_voters": 890,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 890
                },
                "gotv_voted": {
                    "Not voted": 890
                },
                "piv_level_1": "11 - Manjunath Battanna"
            },
            "176": {
                "part": "176",
                "total_voters": 889,
                "support_strength": {
                    "BJP": 29,
                    "Others": 12,
                    "INC": 1
                },
                "gotv_party": {
                    "Not voted": 889
                },
                "gotv_voted": {
                    "Not voted": 889
                },
                "piv_level_1": "19-Jyoti Patil"
            },
            "055": {
                "part": "055",
                "total_voters": 888,
                "support_strength": {
                    "BJP": 1
                },
                "gotv_party": {
                    "Not voted": 888
                },
                "gotv_voted": {
                    "Not voted": 888
                },
                "piv_level_1": "12 - Vijayananda Shetty"
            },
            "146": {
                "part": "146",
                "total_voters": 886,
                "support_strength": {
                    "BJP": 2
                },
                "gotv_party": {
                    "Not voted": 886
                },
                "gotv_voted": {
                    "Not voted": 886
                },
                "piv_level_1": "21 - Anand Yavagala"
            },
            "150": {
                "part": "150",
                "total_voters": 886,
                "support_strength": {
                    "BJP": 4
                },
                "gotv_party": {
                    "Not voted": 886
                },
                "gotv_voted": {
                    "Not voted": 886
                },
                "piv_level_1": "21 - Anand Yavagala"
            },
            "065": {
                "part": "065",
                "total_voters": 885,
                "support_strength": {
                    "BJP": 98,
                    "INC": 17,
                    "Others": 8,
                    "JDS": 1
                },
                "gotv_party": {
                    "Not voted": 885
                },
                "gotv_voted": {
                    "Not voted": 885
                },
                "piv_level_1": "10 - Chandrakala Kotabagi"
            },
            "025": {
                "part": "025",
                "total_voters": 880,
                "support_strength": {
                    "BJP": 1
                },
                "gotv_party": {
                    "Not voted": 880
                },
                "gotv_voted": {
                    "Not voted": 880
                },
                "piv_level_1": "14 - Shubahs Shinde"
            },
            "039": {
                "part": "039",
                "total_voters": 880,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 880
                },
                "gotv_voted": {
                    "Not voted": 880
                },
                "piv_level_1": "16 - Rajeshwari Salagatti"
            },
            "049": {
                "part": "049",
                "total_voters": 873,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 873
                },
                "gotv_voted": {
                    "Not voted": 873
                },
                "piv_level_1": "12 - Vijayananda Shetty"
            },
            "035": {
                "part": "035",
                "total_voters": 871,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 871
                },
                "gotv_voted": {
                    "Not voted": 871
                },
                "piv_level_1": "14 - Shubahs Shinde"
            },
            "154": {
                "part": "154",
                "total_voters": 869,
                "support_strength": {
                    "BJP": 6,
                    "Others": 1
                },
                "gotv_party": {
                    "Not voted": 869
                },
                "gotv_voted": {
                    "Not voted": 869
                },
                "piv_level_1": "20 - Geetha Patil"
            },
            "038": {
                "part": "038",
                "total_voters": 864,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 864
                },
                "gotv_voted": {
                    "Not voted": 864
                },
                "piv_level_1": "14 - Shubahs Shinde"
            },
            "051": {
                "part": "051",
                "total_voters": 861,
                "support_strength": {
                    "BJP": 1,
                    "INC": 1
                },
                "gotv_party": {
                    "Not voted": 861
                },
                "gotv_voted": {
                    "Not voted": 861
                },
                "piv_level_1": "12 - Vijayananda Shetty"
            },
            "080": {
                "part": "080",
                "total_voters": 860,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 860
                },
                "gotv_voted": {
                    "Not voted": 860
                },
                "piv_level_1": "16 - Rajeshwari Salagatti"
            },
            "102": {
                "part": "102",
                "total_voters": 854,
                "support_strength": {
                    "BJP": 1
                },
                "gotv_party": {
                    "Not voted": 854
                },
                "gotv_voted": {
                    "Not voted": 854
                },
                "piv_level_1": "23 - Sanjay Kapatakara"
            },
            "091": {
                "part": "091",
                "total_voters": 849,
                "support_strength": {
                    "AAP": 1
                },
                "gotv_party": {
                    "Not voted": 849
                },
                "gotv_voted": {
                    "Not voted": 849
                },
                "piv_level_1": "22 - Parvativva Hittalamani"
            },
            "077": {
                "part": "077",
                "total_voters": 848,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 848
                },
                "gotv_voted": {
                    "Not voted": 848
                },
                "piv_level_1": "15 - Vishnu Korlahalli"
            },
            "103": {
                "part": "103",
                "total_voters": 847,
                "support_strength": {
                    "Others": 3
                },
                "gotv_party": {
                    "Not voted": 847
                },
                "gotv_voted": {
                    "Not voted": 847
                },
                "piv_level_1": "24 - Shivanna Badavannavara"
            },
            "116": {
                "part": "116",
                "total_voters": 843,
                "support_strength": {
                    "BJP": 91,
                    "Others": 13,
                    "INC": 11
                },
                "gotv_party": {
                    "Not voted": 843
                },
                "gotv_voted": {
                    "Not voted": 843
                },
                "piv_level_1": "29 - Mallikarjuna Navanagar"
            },
            "223": {
                "part": "223",
                "total_voters": 841,
                "support_strength": {
                    "BJP": 18
                },
                "gotv_party": {
                    "Not voted": 841
                },
                "gotv_voted": {
                    "Not voted": 841
                },
                "piv_level_1": "30 - Ramanna Badiger"
            },
            "140": {
                "part": "140",
                "total_voters": 840,
                "support_strength": {
                    "BJP": 1
                },
                "gotv_party": {
                    "Not voted": 840
                },
                "gotv_voted": {
                    "Not voted": 840
                },
                "piv_level_1": "26 - Neelavva  Yalappa Aravalada"
            },
            "204": {
                "part": "204",
                "total_voters": 837,
                "support_strength": {
                    "BJP": 120,
                    "INC": 10,
                    "JDS": 2
                },
                "gotv_party": {
                    "Not voted": 837
                },
                "gotv_voted": {
                    "Not voted": 837
                },
                "piv_level_1": "28 - Chandrashekara Managundi"
            },
            "017": {
                "part": "017",
                "total_voters": 836,
                "support_strength": {
                    "BJP": 2
                },
                "gotv_party": {
                    "Not voted": 836
                },
                "gotv_voted": {
                    "Not voted": 836
                },
                "piv_level_1": "11 - Manjunath Battanna"
            },
            "127": {
                "part": "127",
                "total_voters": 836,
                "support_strength": {
                    "BJP": 5,
                    "Others": 5
                },
                "gotv_party": {
                    "Not voted": 836
                },
                "gotv_voted": {
                    "Not voted": 836
                },
                "piv_level_1": "27 - Sunitha"
            },
            "235": {
                "part": "235",
                "total_voters": 834,
                "support_strength": {
                    "BJP": 87,
                    "Others": 19,
                    "AIMIM": 8,
                    "INC": 3,
                    "AAP": 1
                },
                "gotv_party": {
                    "Not voted": 834
                },
                "gotv_voted": {
                    "Not voted": 834
                },
                "piv_level_1": "31 - Eeranna Pujar"
            },
            "259": {
                "part": "259",
                "total_voters": 828,
                "support_strength": {
                    "BJP": 4
                },
                "gotv_party": {
                    "Not voted": 828
                },
                "gotv_voted": {
                    "Not voted": 828
                },
                "piv_level_1": "34 - Akshata Roogi"
            },
            "060": {
                "part": "060",
                "total_voters": 825,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 825
                },
                "gotv_voted": {
                    "Not voted": 825
                },
                "piv_level_1": "10 - Chandrakala Kotabagi"
            },
            "240": {
                "part": "240",
                "total_voters": 825,
                "support_strength": {
                    "JDS": 1
                },
                "gotv_party": {
                    "Not voted": 825
                },
                "gotv_voted": {
                    "Not voted": 825
                },
                "piv_level_1": "32 - Satish Hanagal"
            },
            "082": {
                "part": "082",
                "total_voters": 824,
                "support_strength": {
                    "BJP": 1
                },
                "gotv_party": {
                    "Not voted": 824
                },
                "gotv_voted": {
                    "Not voted": 824
                },
                "piv_level_1": "16 - Rajeshwari Salagatti"
            },
            "177": {
                "part": "177",
                "total_voters": 824,
                "support_strength": {
                    "BJP": 24,
                    "Others": 10,
                    "INC": 3
                },
                "gotv_party": {
                    "Not voted": 824
                },
                "gotv_voted": {
                    "Not voted": 824
                },
                "piv_level_1": "19-Jyoti Patil"
            },
            "012": {
                "part": "012",
                "total_voters": 823,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 823
                },
                "gotv_voted": {
                    "Not voted": 823
                },
                "piv_level_1": "11 - Manjunath Battanna"
            },
            "201": {
                "part": "201",
                "total_voters": 822,
                "support_strength": {
                    "Others": 1
                },
                "gotv_party": {
                    "Not voted": 822
                },
                "gotv_voted": {
                    "Not voted": 822
                },
                "piv_level_1": "26 - Neelavva  Yalappa Aravalada"
            },
            "148": {
                "part": "148",
                "total_voters": 821,
                "support_strength": {
                    "BJP": 25
                },
                "gotv_party": {
                    "Not voted": 821
                },
                "gotv_voted": {
                    "Not voted": 821
                },
                "piv_level_1": "21 - Anand Yavagala"
            },
            "211": {
                "part": "211",
                "total_voters": 821,
                "support_strength": {
                    "BJP": 110,
                    "Others": 5,
                    "INC": 1
                },
                "gotv_party": {
                    "Not voted": 821
                },
                "gotv_voted": {
                    "Not voted": 821
                },
                "piv_level_1": "30 - Ramanna Badiger"
            },
            "129": {
                "part": "129",
                "total_voters": 817,
                "support_strength": {
                    "BJP": 5,
                    "Others": 2,
                    "INC": 1
                },
                "gotv_party": {
                    "Not voted": 817
                },
                "gotv_voted": {
                    "Not voted": 817
                },
                "piv_level_1": "27 - Sunitha"
            },
            "076": {
                "part": "076",
                "total_voters": 815,
                "support_strength": {
                    "BJP": 1
                },
                "gotv_party": {
                    "Not voted": 815
                },
                "gotv_voted": {
                    "Not voted": 815
                },
                "piv_level_1": "13 - Suresh Bedare"
            },
            "050": {
                "part": "050",
                "total_voters": 814,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 814
                },
                "gotv_voted": {
                    "Not voted": 814
                },
                "piv_level_1": "12 - Vijayananda Shetty"
            },
            "191": {
                "part": "191",
                "total_voters": 808,
                "support_strength": {
                    "BJP": 33,
                    "INC": 5,
                    "Others": 4
                },
                "gotv_party": {
                    "Not voted": 808
                },
                "gotv_voted": {
                    "Not voted": 808
                },
                "piv_level_1": "20 - Geetha Patil"
            },
            "045": {
                "part": "045",
                "total_voters": 802,
                "support_strength": {
                    "BJP": 1
                },
                "gotv_party": {
                    "Not voted": 802
                },
                "gotv_voted": {
                    "Not voted": 802
                },
                "piv_level_1": "13 - Suresh Bedare"
            },
            "190": {
                "part": "190",
                "total_voters": 797,
                "support_strength": {
                    "BJP": 11,
                    "INC": 2,
                    "Others": 2,
                    "AAP": 1
                },
                "gotv_party": {
                    "Not voted": 797
                },
                "gotv_voted": {
                    "Not voted": 797
                },
                "piv_level_1": "20 - Geetha Patil"
            },
            "203": {
                "part": "203",
                "total_voters": 790,
                "support_strength": {
                    "BJP": 47,
                    "INC": 20
                },
                "gotv_party": {
                    "Not voted": 790
                },
                "gotv_voted": {
                    "Not voted": 790
                },
                "piv_level_1": "28 - Chandrashekara Managundi"
            },
            "022": {
                "part": "022",
                "total_voters": 789,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 789
                },
                "gotv_voted": {
                    "Not voted": 789
                },
                "piv_level_1": "13 - Suresh Bedare"
            },
            "261": {
                "part": "261",
                "total_voters": 789,
                "support_strength": {
                    "BJP": 1
                },
                "gotv_party": {
                    "Not voted": 789
                },
                "gotv_voted": {
                    "Not voted": 789
                },
                "piv_level_1": "34 - Akshata Roogi"
            },
            "181": {
                "part": "181",
                "total_voters": 788,
                "support_strength": {
                    "BJP": 7,
                    "Others": 3
                },
                "gotv_party": {
                    "Not voted": 788
                },
                "gotv_voted": {
                    "Not voted": 788
                },
                "piv_level_1": "19-Jyoti Patil"
            },
            "107": {
                "part": "107",
                "total_voters": 784,
                "support_strength": {
                    "BJP": 4,
                    "Others": 2
                },
                "gotv_party": {
                    "Not voted": 784
                },
                "gotv_voted": {
                    "Not voted": 784
                },
                "piv_level_1": "24 - Shivanna Badavannavara"
            },
            "139": {
                "part": "139",
                "total_voters": 782,
                "support_strength": {
                    "BJP": 26
                },
                "gotv_party": {
                    "Not voted": 782
                },
                "gotv_voted": {
                    "Not voted": 782
                },
                "piv_level_1": "26 - Neelavva  Yalappa Aravalada"
            },
            "079": {
                "part": "079",
                "total_voters": 774,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 774
                },
                "gotv_voted": {
                    "Not voted": 774
                },
                "piv_level_1": "16 - Rajeshwari Salagatti"
            },
            "153": {
                "part": "153",
                "total_voters": 772,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 772
                },
                "gotv_voted": {
                    "Not voted": 772
                },
                "piv_level_1": "22 - Parvativva Hittalamani"
            },
            "246": {
                "part": "246",
                "total_voters": 772,
                "support_strength": {
                    "BJP": 8
                },
                "gotv_party": {
                    "Not voted": 772
                },
                "gotv_voted": {
                    "Not voted": 772
                },
                "piv_level_1": "33 - Satish Hanagal"
            },
            "004": {
                "part": "004",
                "total_voters": 765,
                "support_strength": {
                    "BJP": 2
                },
                "gotv_party": {
                    "Not voted": 765
                },
                "gotv_voted": {
                    "Not voted": 765
                },
                "piv_level_1": "11 - Manjunath Battanna"
            },
            "114": {
                "part": "114",
                "total_voters": 763,
                "support_strength": {
                    "BJP": 86,
                    "Others": 12,
                    "INC": 10,
                    "AAP": 1
                },
                "gotv_party": {
                    "Not voted": 763
                },
                "gotv_voted": {
                    "Not voted": 763
                },
                "piv_level_1": "29 - Mallikarjuna Navanagar"
            },
            "229": {
                "part": "229",
                "total_voters": 763,
                "support_strength": {
                    "BJP": 47,
                    "INC": 4,
                    "AAP": 1,
                    "JDS": 1,
                    "Others": 1
                },
                "gotv_party": {
                    "Not voted": 763
                },
                "gotv_voted": {
                    "Not voted": 763
                },
                "piv_level_1": "31 - Eeranna Pujar"
            },
            "059": {
                "part": "059",
                "total_voters": 760,
                "support_strength": {
                    "BJP": 3
                },
                "gotv_party": {
                    "Not voted": 760
                },
                "gotv_voted": {
                    "Not voted": 760
                },
                "piv_level_1": "10 - Chandrakala Kotabagi"
            },
            "074": {
                "part": "074",
                "total_voters": 760,
                "support_strength": {
                    "BJP": 3,
                    "Others": 1
                },
                "gotv_party": {
                    "Not voted": 760
                },
                "gotv_voted": {
                    "Not voted": 760
                },
                "piv_level_1": "15 - Vishnu Korlahalli"
            },
            "142": {
                "part": "142",
                "total_voters": 747,
                "support_strength": {
                    "BJP": 4,
                    "INC": 1
                },
                "gotv_party": {
                    "Not voted": 747
                },
                "gotv_voted": {
                    "Not voted": 747
                },
                "piv_level_1": "26 - Neelavva  Yalappa Aravalada"
            },
            "024": {
                "part": "024",
                "total_voters": 738,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 738
                },
                "gotv_voted": {
                    "Not voted": 738
                },
                "piv_level_1": "14 - Shubahs Shinde"
            },
            "182": {
                "part": "182",
                "total_voters": 734,
                "support_strength": {
                    "BJP": 17,
                    "Others": 9,
                    "INC": 4,
                    "JDS": 1
                },
                "gotv_party": {
                    "Not voted": 734
                },
                "gotv_voted": {
                    "Not voted": 734
                },
                "piv_level_1": "19-Jyoti Patil"
            },
            "233": {
                "part": "233",
                "total_voters": 733,
                "support_strength": {
                    "BJP": 92,
                    "Others": 8,
                    "AIMIM": 4,
                    "INC": 3
                },
                "gotv_party": {
                    "Not voted": 733
                },
                "gotv_voted": {
                    "Not voted": 733
                },
                "piv_level_1": "31 - Eeranna Pujar"
            },
            "040": {
                "part": "040",
                "total_voters": 732,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 732
                },
                "gotv_voted": {
                    "Not voted": 732
                },
                "piv_level_1": "16 - Rajeshwari Salagatti"
            },
            "224": {
                "part": "224",
                "total_voters": 731,
                "support_strength": {
                    "BJP": 45,
                    "Others": 2,
                    "AIMIM": 1
                },
                "gotv_party": {
                    "Not voted": 731
                },
                "gotv_voted": {
                    "Not voted": 731
                },
                "piv_level_1": "30 - Ramanna Badiger"
            },
            "198": {
                "part": "198",
                "total_voters": 727,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 727
                },
                "gotv_voted": {
                    "Not voted": 727
                },
                "piv_level_1": "20 - Geetha Patil"
            },
            "044": {
                "part": "044",
                "total_voters": 721,
                "support_strength": {
                    "Others": 1
                },
                "gotv_party": {
                    "Not voted": 721
                },
                "gotv_voted": {
                    "Not voted": 721
                },
                "piv_level_1": "16 - Rajeshwari Salagatti"
            },
            "011": {
                "part": "011",
                "total_voters": 717,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 717
                },
                "gotv_voted": {
                    "Not voted": 717
                },
                "piv_level_1": "11 - Manjunath Battanna"
            },
            "028": {
                "part": "028",
                "total_voters": 712,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 712
                },
                "gotv_voted": {
                    "Not voted": 712
                },
                "piv_level_1": "14 - Shubahs Shinde"
            },
            "206": {
                "part": "206",
                "total_voters": 708,
                "support_strength": {
                    "BJP": 4,
                    "INC": 2
                },
                "gotv_party": {
                    "Not voted": 708
                },
                "gotv_voted": {
                    "Not voted": 708
                },
                "piv_level_1": "28 - Chandrashekara Managundi"
            },
            "090": {
                "part": "090",
                "total_voters": 692,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 692
                },
                "gotv_voted": {
                    "Not voted": 692
                },
                "piv_level_1": "22 - Parvativva Hittalamani"
            },
            "101": {
                "part": "101",
                "total_voters": 692,
                "support_strength": {
                    "BJP": 16,
                    "Others": 8,
                    "INC": 4
                },
                "gotv_party": {
                    "Not voted": 692
                },
                "gotv_voted": {
                    "Not voted": 692
                },
                "piv_level_1": "24 - Shivanna Badavannavara"
            },
            "104": {
                "part": "104",
                "total_voters": 681,
                "support_strength": {
                    "BJP": 5,
                    "Others": 3
                },
                "gotv_party": {
                    "Not voted": 681
                },
                "gotv_voted": {
                    "Not voted": 681
                },
                "piv_level_1": "24 - Shivanna Badavannavara"
            },
            "115": {
                "part": "115",
                "total_voters": 678,
                "support_strength": {
                    "BJP": 70,
                    "INC": 14,
                    "Others": 4
                },
                "gotv_party": {
                    "Not voted": 678
                },
                "gotv_voted": {
                    "Not voted": 678
                },
                "piv_level_1": "29 - Mallikarjuna Navanagar"
            },
            "252": {
                "part": "252",
                "total_voters": 677,
                "support_strength": {
                    "BJP": 3
                },
                "gotv_party": {
                    "Not voted": 677
                },
                "gotv_voted": {
                    "Not voted": 677
                },
                "piv_level_1": "33 - Satish Hanagal"
            },
            "202": {
                "part": "202",
                "total_voters": 671,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 671
                },
                "gotv_voted": {
                    "Not voted": 671
                },
                "piv_level_1": "26 - Neelavva  Yalappa Aravalada"
            },
            "078": {
                "part": "078",
                "total_voters": 669,
                "support_strength": {
                    "BJP": 2,
                    "Others": 1
                },
                "gotv_party": {
                    "Not voted": 669
                },
                "gotv_voted": {
                    "Not voted": 669
                },
                "piv_level_1": "15 - Vishnu Korlahalli"
            },
            "149": {
                "part": "149",
                "total_voters": 668,
                "support_strength": {
                    "BJP": 32,
                    "INC": 2
                },
                "gotv_party": {
                    "Not voted": 668
                },
                "gotv_voted": {
                    "Not voted": 668
                },
                "piv_level_1": "21 - Anand Yavagala"
            },
            "048": {
                "part": "048",
                "total_voters": 663,
                "support_strength": {
                    "BJP": 20,
                    "Others": 3,
                    "INC": 2
                },
                "gotv_party": {
                    "Not voted": 663
                },
                "gotv_voted": {
                    "Not voted": 663
                },
                "piv_level_1": "12 - Vijayananda Shetty"
            },
            "169": {
                "part": "169",
                "total_voters": 655,
                "support_strength": {
                    "BJP": 7,
                    "INC": 1,
                    "Others": 1
                },
                "gotv_party": {
                    "Not voted": 655
                },
                "gotv_voted": {
                    "Not voted": 655
                },
                "piv_level_1": "19-Jyoti Patil"
            },
            "193": {
                "part": "193",
                "total_voters": 649,
                "support_strength": {
                    "Others": 3,
                    "BJP": 2,
                    "INC": 1
                },
                "gotv_party": {
                    "Not voted": 649
                },
                "gotv_voted": {
                    "Not voted": 649
                },
                "piv_level_1": "20 - Geetha Patil"
            },
            "047": {
                "part": "047",
                "total_voters": 636,
                "support_strength": {
                    "BJP": 8,
                    "Others": 7
                },
                "gotv_party": {
                    "Not voted": 636
                },
                "gotv_voted": {
                    "Not voted": 636
                },
                "piv_level_1": "12 - Vijayananda Shetty"
            },
            "015": {
                "part": "015",
                "total_voters": 619,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 619
                },
                "gotv_voted": {
                    "Not voted": 619
                },
                "piv_level_1": "13 - Suresh Bedare"
            },
            "087": {
                "part": "087",
                "total_voters": 618,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 618
                },
                "gotv_voted": {
                    "Not voted": 618
                },
                "piv_level_1": "16 - Rajeshwari Salagatti"
            },
            "064": {
                "part": "064",
                "total_voters": 614,
                "support_strength": {
                    "BJP": 75,
                    "INC": 9,
                    "AAP": 1,
                    "Others": 1
                },
                "gotv_party": {
                    "Not voted": 614
                },
                "gotv_voted": {
                    "Not voted": 614
                },
                "piv_level_1": "10 - Chandrakala Kotabagi"
            },
            "042": {
                "part": "042",
                "total_voters": 608,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 608
                },
                "gotv_voted": {
                    "Not voted": 608
                },
                "piv_level_1": "16 - Rajeshwari Salagatti"
            },
            "238": {
                "part": "238",
                "total_voters": 606,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 606
                },
                "gotv_voted": {
                    "Not voted": 606
                },
                "piv_level_1": "32 - Satish Hanagal"
            },
            "147": {
                "part": "147",
                "total_voters": 593,
                "support_strength": {
                    "BJP": 3,
                    "INC": 1
                },
                "gotv_party": {
                    "Not voted": 593
                },
                "gotv_voted": {
                    "Not voted": 593
                },
                "piv_level_1": "21 - Anand Yavagala"
            },
            "199": {
                "part": "199",
                "total_voters": 586,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 586
                },
                "gotv_voted": {
                    "Not voted": 586
                },
                "piv_level_1": "25 - Parashuram Sakare"
            },
            "132": {
                "part": "132",
                "total_voters": 582,
                "support_strength": {
                    "BJP": 2
                },
                "gotv_party": {
                    "Not voted": 582
                },
                "gotv_voted": {
                    "Not voted": 582
                },
                "piv_level_1": "27 - Sunitha"
            },
            "197": {
                "part": "197",
                "total_voters": 578,
                "support_strength": {
                    "BJP": 1,
                    "Others": 1
                },
                "gotv_party": {
                    "Not voted": 578
                },
                "gotv_voted": {
                    "Not voted": 578
                },
                "piv_level_1": "25 - Parashuram Sakare"
            },
            "058": {
                "part": "058",
                "total_voters": 577,
                "support_strength": {
                    "BJP": 2
                },
                "gotv_party": {
                    "Not voted": 577
                },
                "gotv_voted": {
                    "Not voted": 577
                },
                "piv_level_1": "10 - Chandrakala Kotabagi"
            },
            "019": {
                "part": "019",
                "total_voters": 565,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 565
                },
                "gotv_voted": {
                    "Not voted": 565
                },
                "piv_level_1": "13 - Suresh Bedare"
            },
            "069": {
                "part": "069",
                "total_voters": 540,
                "support_strength": {
                    "BJP": 4,
                    "INC": 1
                },
                "gotv_party": {
                    "Not voted": 540
                },
                "gotv_voted": {
                    "Not voted": 540
                },
                "piv_level_1": "15 - Vishnu Korlahalli"
            },
            "085": {
                "part": "085",
                "total_voters": 523,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 523
                },
                "gotv_voted": {
                    "Not voted": 523
                },
                "piv_level_1": "16 - Rajeshwari Salagatti"
            },
            "178": {
                "part": "178",
                "total_voters": 504,
                "support_strength": {
                    "BJP": 56,
                    "INC": 11,
                    "Others": 6,
                    "AAP": 1
                },
                "gotv_party": {
                    "Not voted": 504
                },
                "gotv_voted": {
                    "Not voted": 504
                },
                "piv_level_1": "19-Jyoti Patil"
            },
            "043": {
                "part": "043",
                "total_voters": 490,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 490
                },
                "gotv_voted": {
                    "Not voted": 490
                },
                "piv_level_1": "13 - Suresh Bedare"
            },
            "014": {
                "part": "014",
                "total_voters": 470,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 470
                },
                "gotv_voted": {
                    "Not voted": 470
                },
                "piv_level_1": "13 - Suresh Bedare"
            },
            "020": {
                "part": "020",
                "total_voters": 316,
                "support_strength": {
                    "BJP": 3
                },
                "gotv_party": {
                    "Not voted": 316
                },
                "gotv_voted": {
                    "Not voted": 316
                },
                "piv_level_1": "13 - Suresh Bedare"
            },
            "109": {
                "part": "109",
                "total_voters": 272,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 272
                },
                "gotv_voted": {
                    "Not voted": 272
                },
                "piv_level_1": "24 - Shivanna Badavannavara"
            },
            "164_01": {
                "part": "164_01",
                "total_voters": 2,
                "support_strength": {},
                "gotv_party": {
                    "Not voted": 2
                },
                "gotv_voted": {
                    "Not voted": 2
                },
                "piv_level_1": "15 - Vishnu Korlahalli"
            }
        }
    };
    @observable showDataInModal: boolean = false;
    @observable dataInModal = {};
    @observable nameInModal = '';

    constructor(rootStore: any) {
        this.rootStore = rootStore;
    }

    @action
    getElectionDayDashboardData = async (projectid) => {
        /*const payload = {projectid};
        const res = await this.rootStore.api.post('voters/gotvdashboard', payload)
        this.electionDayDashboardData = res;
        console.log(res)*/
    }

    @action
    setShowDataInModal(show) {
        this.showDataInModal = show
    }

    @action
    setDataInModal(data) {
        this.dataInModal = data
    }

    @action
    setNameInModal(name) {
        this.nameInModal = name
    }
}