import React from "react";
import {DashBoard} from "./dashboard-template";

export const MHMap = () => {

    const frame = `<iframe title="mh_map" width="100%" height="100%" src="https://app.powerbi.com/view?r=eyJrIjoiNzcxNjFhMjYtNGY0Yy00ZWM5LTlkOGUtMmFiYmE2MGRkZjBlIiwidCI6IjAzZjhjZWI2LTdhMGUtNDcwMy05ZGE0LWUyYTBkYmE1NmM4YyJ9" frameborder="0" allowFullScreen="true"></iframe>`

    return (
        <>
            <DashBoard passCode={"maharashtradesha"} iframe={frame} />
        </>
    )
}