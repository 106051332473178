import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import {useStore} from "../../helpers/helpers";
import {safe_get} from "../../report-visuals/report-utils";
import {FormButtons} from "../../components";
import {DepartmentFilter, PriorityFilter, StatusFilter, VillageFilter} from "../../components/cm-filters";
import {defaultIProjectlFilters} from "../project-works";
import {Button, Stack} from "@mui/material";

const defaultFilters = {
    dept: [],
    piv_level_2: [],
    current_status: [],
    priority: [],
    reported_by: []
}

export const GrievanceFilter = observer((props: any) => {

    const rootStore = useStore();
    const {grievanceStore, projectStore} = rootStore;
    const filterTypes = ["Department", "Village", "Status", "Priority"];
    const [activeFilter, setActiveFilter] = useState(filterTypes[0]);

    const grievance_propertty = projectStore.getProjectProperty("grievances", {})
    const categories = safe_get(grievance_propertty, "categories", {});
    const statusOptions = safe_get(grievance_propertty, "status_config", []);
    const priority = safe_get(grievance_propertty, "priority", []);
    const departmentOptions = Object.keys(categories);
    const village_key = safe_get(grievance_propertty, "more.village_selector", "piv_level1")

    const [selectedGrievanceFilters, setSelectedGrievanceFilters] = useState(grievanceStore.filters)

    const handleFilterChange = (event, key) => {
        const {value, checked} = event.target;
        if (checked) {
            setSelectedGrievanceFilters({
                ...selectedGrievanceFilters,
                [key]: [...selectedGrievanceFilters[key], value]
            });
        } else {
            setSelectedGrievanceFilters({
                ...selectedGrievanceFilters,
                [key]: selectedGrievanceFilters[key].filter((e) => e !== value)
            })
        }
    }

    const handleVillageChange = (villages) => {
        setSelectedGrievanceFilters({...selectedGrievanceFilters, piv_level_2:villages})
    }

    const onFiltersSaveHandler = () => {
        grievanceStore.setFilterStatus(false);
        grievanceStore.updateFilters(selectedGrievanceFilters);
    }


    return (
        <div className="w-full h-full flex">
            <div className="flex flex-col gap-y-2 p-2 w-1/4 h-full border-black border-r-2">
                {filterTypes.map((tab) => (
                    <p onClick={() => {
                        setActiveFilter(tab)
                    }}>
                        <span
                            className={activeFilter == tab ? "text-purple-600 text-lg font-medium" : "text-lg font-medium"}>{tab}</span>
                    </p>
                ))}
            </div>
            <div className="flex flex-1 flex-col justify-between p-2 w-4/4 h-full w-full overflow-y-auto">
                <div className={"flex"}>
                    {activeFilter == "Department" &&
                        <DepartmentFilter
                            departmentOptions={departmentOptions}
                            value={selectedGrievanceFilters.dept}
                            setValues={(event) => handleFilterChange(event, "dept")}
                        />
                    }

                    {activeFilter == "Village" &&
                        <VillageFilter
                            villageSelector={village_key}
                            value={selectedGrievanceFilters.piv_level_2}
                            setValues={(villages) => handleVillageChange(villages)}
                        />
                    }

                    {activeFilter == "Status" &&
                        <StatusFilter
                            data={statusOptions}
                            value={selectedGrievanceFilters.current_status}
                            setValues={(event) => handleFilterChange(event, "current_status")}
                        />
                    }

                    {activeFilter == "Priority" &&
                        <PriorityFilter
                            data={priority}
                            value={selectedGrievanceFilters.priority}
                            setValues={(event) => handleFilterChange(event, "priority")}
                        />
                    }
                </div>
                <div className={"flex self-end"}>
                    <Stack direction={"row"} alignItems={"center"}>
                    <Button
                        color={"warning"}
                        variant={"contained"}
                        onClick={() => setSelectedGrievanceFilters(defaultFilters)}
                    >
                        Reset
                    </Button>
                    <FormButtons
                        type={"submit"}
                        onSave={onFiltersSaveHandler}
                        onCancel={() => {
                            grievanceStore.setFilterStatus(false);
                        }}
                    />
                    </Stack>
                </div>
            </div>
        </div>
    );
})

    