import React from "react";
import {DashBoard} from "./dashboard-template";

export const KaHistoryDashBoard = () => {

    const frame = `<iframe title="ka_ng_ask" width="100%" height="100%" src="https://app.powerbi.com/view?r=eyJrIjoiMDQwN2JmNjktNzkwOC00Y2I0LTliZmMtOWMwNDhlY2EyNjhlIiwidCI6IjAzZjhjZWI2LTdhMGUtNDcwMy05ZGE0LWUyYTBkYmE1NmM4YyJ9" frameborder="0" allowFullScreen="true"></iframe>`

    return (
        <>
            <DashBoard passCode={"Results123"} iframe={frame} />
        </>
    )
}