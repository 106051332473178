import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { useParams } from "react-router";
import { useStore } from "../../helpers/helpers";
import { useIsMobile, useRole } from "../../custom-hooks";
import { useTranslation } from "react-i18next";
import { LoadingState, ProjectHeader } from "../../components";
import { UserTable } from "./users-table";
import { observer } from "mobx-react-lite";

export const UsersHome = observer((props: any) => {

    const { projectid } = useParams();
    const rootStore = useStore();
    const { projectStore, userStore } = useStore();
    const role = useRole();
    const isMobile = useIsMobile();
    const { t } = useTranslation(["user_management", "survey_management"]);

    const get_full_project = () => {

    }

    const get_users = async () => {
        const payload = {
            projectid: parseInt(projectid),
            tags: [],
            query: '',
            limit: 500,
            offset: 0,
        };
        return await projectStore.getAllUsers(payload);
    }

    const { data, isLoading, isError, error, refetch } = useQuery({ queryKey: ["users"], queryFn: get_users })

    console.log(data)

    if (isLoading) return <LoadingState />
    if (isError) return <p>Something went worng</p>;

    return (
        <>
            <ProjectHeader />
            <UserTable users={data.data?.users} />
        </>
    )
})