import React from "react";
import {Routes, Route} from "react-router-dom";
import {PrivateRoute} from "./helpers";
import {
    DashboardPage,
    Login,
    ManageProject,
    ProjectDashboardPage,
    QuestionPage,
    SettingsPage,
} from "../pages";
import {
    AdHocHome,
    ActiveProjectsFTRSummary,
    RJTaskAssign,
    RJAnalyticalSummary,
    SocialMediaTracker,
    BYRBJP,
    DavanagereDashBoard,
    ShimogaDashboard,
    PadmanabhaNagarDashBoard,
    HoskoteDashBoard,
    HCPDashBoard,
    RNSales,
    KaHistoryDashBoard,
    KaraikudiDashBoard,
    IndiaContactsDashBoard, MHMap,
} from "../pages/ad-hoc-features";
import {
    ACBankTimeLine,
    AddTimeLine,
    TimeLineView,
} from "../flexistore/renderkeys/ac-bank/timeline";
import {CasteSummary} from "../flexistore/renderkeys/ac-bank/caste-details";
import {ACBankInfluentialLeaders} from "../flexistore/renderkeys/ac-bank/influential-leaders";
import {
    ACBankConsituencyMap,
    ACBankElectionResults,
} from "../flexistore/renderkeys/ac-bank";
import {
    ReportEditPage,
    ReportsLanding,
    ReportViewPage,
} from "../pages/reports";
import {
    FSDCreation,
    FSDListPage,
    FSDViewPage,
    FSTAigenTools,
    FSTListPage,
} from "../flexistore/pages-root";
import {UserManagement} from "../features/user-management";
import {TaskManager} from "../features/task-manager";
import {
    BeneficiaryManagemet,
    BeneficiaryView,
} from "../features/beneficiary-management";
import {
    C3Beneficiaries,
    C3Demographics,
    C3Grievance,
    C3Historical,
    C3Home,
    C3PartyWorkers,
} from "../features/constituency-command-center";
import {ElectionDayDashboard} from "../features/election-day-dashboard";
import {FieldPayment} from "../features/field_payment";
import {
    GrievanceManagement,
    ViewGrievance,
} from "../features/grievance-management";
import {ManageVotersdex} from "../features/manage-voters";
import {
    ProjectWorkDetails,
    ProjectWorkEdit,
    ProjectWorks,
} from "../features/project-works";
import {SurveyDetails} from "../features/survey-management";
import {VoterReachOut} from "../features/voter-reachout/voter-reachout-Messages";
import {VoterReachOutIndex} from "../features/voter-reachout";
import {VoterRegistrationTracker} from "../features/new-voter-registration";
import {ACBankPermissions} from "../flexistore/renderkeys/ac-bank/permissions/ac-bank-permissions";
import {Enquiries} from "../components";
import {UsersHome} from "../features/user-management-new";
import {DigitalRepository, RenderFile} from "../features/digital-repository";
import {DVGMap} from "../pages/ad-hoc-features/davanagere-map";
import {HombaleDashBoard} from "../pages/ad-hoc-features/dash-boards/Hombale";
import BeneficiarySettings from "../features/beneficiary-management/beneficiary-settings";
import BeneficiaryManagementV2 from "../features/beneficiary-management-v2/beneficiary-management-v2";
import {NewProjectWorksHome} from "../features/project-works-v2";
import {InfluentialLeadersHome} from "../features/influential-leaders";
import DeleteUserPage from "../pages/DeleteUserPage";
import {ProjectWorkInfo} from "../features/project-works-v2/project-work-info";

export const MainRoutes = () => {
    return (
        <Routes>
            <Route path={"/login"} element={<Login/>}/>
            <Route path={"/delete_user_info"} element={<DeleteUserPage/>}/>
            <Route path={"/enquiries"} element={<Enquiries/>}/>
            <Route path={"/landing/BYRBJP"} element={<BYRBJP/>}/>
            <Route path={"/dashboard/davanagere"} element={<DavanagereDashBoard/>}/>
            <Route path={"/dashboard/hoskote"} element={<HoskoteDashBoard/>}/>

            <Route path={"/dashboard/shimoga"} element={<ShimogaDashboard/>}/>
            <Route path={"/dashboard/hoskote"} element={<HoskoteDashBoard/>}/>
            <Route path={"/dashboard/hombale"} element={<HombaleDashBoard/>}/>
            <Route path={"/dashboard/rnsales"} element={<RNSales/>}/>
            <Route path={"/dashboard/hcp"} element={<HCPDashBoard/>}/>
            <Route path={"/dashboard/KAHistory"} element={<KaHistoryDashBoard/>}/>
            <Route path={"/dashboard/karaikudi"} element={<KaraikudiDashBoard/>}/>
            <Route
                path={"/dashboard/indiacontacts"}
                element={<IndiaContactsDashBoard/>}
            />
            <Route path={"/maps/vk"} element={<DVGMap/>}/>

            <Route
                path={"/dashboard/padmanabhanagar"}
                element={<PadmanabhaNagarDashBoard/>}
            />
            <Route
                path={"/dashboard/mh_map"}
                element={<MHMap/>}
            />

            <Route path={"/"} element={<PrivateRoute/>}>
                <Route path="/settings" element={<SettingsPage/>}/>
                <Route path="/tools" element={<AdHocHome/>}/>
                <Route path="/tools/bulktaskassign" element={<RJTaskAssign/>}/>
                <Route
                    path="/tools/fieldsummary"
                    element={<ActiveProjectsFTRSummary/>}
                />
                <Route
                    path="/tools/analyticalreport"
                    element={<RJAnalyticalSummary/>}
                />
                <Route
                    path="/tools/socialmediatracker"
                    element={<SocialMediaTracker/>}
                />
                <Route path="/" element={<DashboardPage/>}>
                    <Route
                        path="/project/:projectid"
                        element={<ProjectDashboardPage/>}
                    />
                    <Route
                        path="/project/:projectid/manage"
                        element={<ManageProject/>}
                    />
                    <Route
                        path="/project/:projectid/manage/survey/:surveyid/edit"
                        element={<QuestionPage/>}
                    />
                    <Route
                        path="/project/:projectid/usermanagement"
                        element={<UserManagement/>}
                    />
                    <Route
                        path="/project/:projectid/newusermanagement"
                        element={<UsersHome/>}
                    />
                    <Route
                        path="/project/:projectid/fieldteamreport"
                        element={<SurveyDetails/>}
                    />
                    <Route
                        path="/project/:projectid/taskmanager"
                        element={<TaskManager/>}
                    />
                    <Route
                        path="/project/:projectid/voter_registration_tracker"
                        element={<VoterRegistrationTracker/>}
                    />
                    <Route
                        path="/project/:projectid/digital_repository"
                        element={<DigitalRepository/>}
                    />
                    <Route
                        path="/project/:projectid/beneficiary_v2"
                        element={<BeneficiaryManagementV2/>}
                    />
                    <Route
                        path="/project/:projectid/influential_leaders"
                        element={<InfluentialLeadersHome/>}
                    />

                    {/*<Route path=":projectid/beneficiary_management" element={<BeneficiaryManagemet/>}/>*/}
                </Route>
                <Route
                    path="/project/:projectid/projectworks_v2"
                    element={<NewProjectWorksHome/>}
                />
                {/*<Route*/}
                {/*    path="/project/:projectid/projectworks_v2"*/}
                {/*    element={<NewProjectWorks />}*/}
                {/*/>*/}
                <Route
                    element={<ProjectWorkInfo/>}
                    path="/project/:projectid/projectworks_v2/:activeProjectId"
                />
                <Route
                    path="/project/:projectid/project_works"
                    element={<ProjectWorks/>}
                />
                <Route
                    element={<ProjectWorkDetails/>}
                    path="/project/:projectid/project_works/:fsdid"
                />
                <Route
                    element={<ProjectWorkEdit/>}
                    path="/project/:projectid/project_works/:fsdid/edit"
                />
                <Route
                    path="/project/:projectid/grievance_management"
                    element={<GrievanceManagement/>}
                />
                <Route
                    path="/project/:projectid/grievance_management/:fsdid/view"
                    element={<ViewGrievance/>}
                />
                <Route
                    path="/project/:projectid/beneficiary_management"
                    element={<BeneficiaryManagemet/>}
                />
                <Route
                    path="/project/:projectid/beneficiary_management/:beneficid/view"
                    element={<BeneficiaryView/>}
                />
                <Route
                    path="/project/:projectid/beneficiary_management/admin"
                    element={<BeneficiarySettings/>}
                />
                <Route
                    path="/project/:projectid/election_day_dashboard"
                    element={<ElectionDayDashboard/>}
                />
                <Route
                    path="/project/:projectid/voter_reach_out/messages"
                    element={<VoterReachOut/>}
                />
                <Route
                    path="/project/:projectid/voter_reach_out/"
                    element={<VoterReachOutIndex/>}
                />
                `
                <Route path="/aigentools" element={<FSTAigenTools/>}/>
                <Route path="/aigentools/crete/:aigentId" element={<FSDCreation/>}/>
                <Route path="/databank" element={<FSTListPage/>}/>
                <Route path="/databank/repo/:fstid/:key" element={<FSDListPage/>}/>
                <Route path="/databank/repo/:fstid/:key/:id" element={<FSDViewPage/>}>
                    <Route path="caste_summary" element={<CasteSummary/>}/>
                    <Route path="constituency_map" element={<ACBankConsituencyMap/>}/>
                    <Route path="past_results" element={<ACBankElectionResults/>}/>
                    <Route path="influential_people" element={<ACBankInfluentialLeaders/>}/>
                    <Route path="timeline" element={<ACBankTimeLine/>}/>
                    <Route path="permissions" element={<ACBankPermissions/>}/>
                </Route>
                <Route
                    path="/databank/repo/:key/permissions"
                    element={<ACBankPermissions/>}
                />
                <Route
                    path="/databank/repo/:key/:id/timeline/new"
                    element={<AddTimeLine/>}
                />
                <Route
                    path="/databank/repo/:key/:id/timeline/view"
                    element={<TimeLineView/>}
                />
                <Route path="/reports" element={<ReportsLanding/>}/>
                <Route path="/reports/view/:reportid" element={<ReportViewPage/>}/>
                <Route path="/report/share/:token" element={<ReportViewPage/>}/>
                <Route path="/reports/edit/:reportid" element={<ReportEditPage/>}/>
                <Route
                    path="/project/:projectid/fieldpayment"
                    element={<FieldPayment/>}
                />
                <Route path="/project/:projectid/c3" element={<C3Home/>}>
                    <Route index element={<C3Demographics/>}/>
                    <Route path="demographics" element={<C3Demographics/>}/>
                    <Route path="historical" element={<C3Historical/>}/>
                    <Route path="beneficiaries" element={<C3Beneficiaries/>}/>
                    <Route path="partyworkers" element={<C3PartyWorkers/>}/>
                    <Route path="grievance" element={<C3Grievance/>}/>
                </Route>
                <Route path="/project/:projectid/c3/:suffix" element={<C3Home/>}/>
                <Route
                    path="/project/:projectid/manage-voters/"
                    element={<ManageVotersdex/>}
                />
                <Route
                    path="/project/:projectid/election_day_dashboard/"
                    element={<ElectionDayDashboard/>}
                />
                <Route path="/view-file/:key" element={<RenderFile/>}/>
            </Route>
        </Routes>
    );
};
