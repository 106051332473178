import React, { useState } from "react"
import { Field, ErrorMessage } from "formik"
import { TextError } from "./formik-controller"

function Select(props) {
    const { label, name, labelStyle, fieldStyle, options, ...rest } = props;
    return (
        <>
            <div className="flex flex-row items-center justify-center mb-2 formik-select">
                <label htmlFor={name} className={`${labelStyle} font-bold`}>{label}</label>
                <Field as="select" id={name} name={name} className={fieldStyle} 
                    {...rest}>
                    {options.map(option => {
                        return (
                            <option key={option.value} value={option.value}>
                                {option.key}
                            </option>
                        )
                    })}
                </Field>
            </div>
            <div className="flex justify-self-end">
                <ErrorMessage name={name} component={TextError} />
            </div>
        </>
    )
}

export default Select