import React, {useState} from "react";
import {
    BreadCrumbs,
    FSDPagination,
    HeaderWithAddButton,
    LoadingState,
    ProjectHeader, SideOverlay
} from "../../components";
import {Link, useParams} from "react-router-dom";
import {useStore} from "../../helpers/helpers";
import {useQuery} from "@tanstack/react-query";
import {FunnelIcon} from "@heroicons/react/24/outline";
import {Button, Stack} from "@mui/material";
import {AddBeneficiary, BeneficiariesTable, excel_col_def} from "../beneficiary-management";
import {FilterOverLay, SearchVoterList, VoterListWithRadioButton} from "../voter-list";
import {BeneficiaryFilters} from "../beneficiary-management/beneficiaryFilters";
import {useGetFullProject, useIsMobile} from "../../custom-hooks";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react-lite";
import {RNSearchBar} from "../influential-leaders";

const Crumbs = (props) => {
    const {projectid} = useParams();

    const crumbs = [
        {name: "DashBoard", href: `/project/${projectid}`, current: true},
        {name: 'Beneficiary Management', href: `/project/${projectid}/beneficiary_v2`, current: true},
    ];
    return (
        <BreadCrumbs crumbs={crumbs}/>
    )
}
export const BeneficiaryManagementV2 = observer(props => {

    const {t} = useTranslation(["beneficiary", "voter_list"]);
    const {projectid} = useParams();
    const isMobile = useIsMobile();
    const rootStore = useStore();
    const [query, setQuery] = useState("");
    const {beneficiaryStore, flexiStore, voterListStore, userStore} = rootStore;
    const {add_beneficiary_modal, BeneficiariesFilter, filters} = beneficiaryStore;
    const {showVoterlist, FilterStatus} = voterListStore;
    const {fsd_search_page_size, fsd_search_current_page} = flexiStore;
    const [total, setTotal] = useState(0);
    const totalPages = Math.ceil(total / fsd_search_page_size);
    const {isLoading: fullProjectLoading} = useGetFullProject()


    const handlePerPageChange = (event) => {
        flexiStore.update_flexistore_property("fsd_search_page_size", parseInt(event.target.value))
    };

    const onVoterSelection = (voter) => {
        beneficiaryStore.update_add_beneficiary_modal(true);
        voterListStore.setShowVoterlist(false);
    }


    const fetchBeneficiries = async () => {
        flexiStore.set_fsd_search_query(query);
        return await flexiStore.fetch_fsdlist("beneficiary", parseInt(projectid), filters);
    };

    const {data, isLoading, error, isError, isSuccess, refetch} = useQuery({
            queryKey: ["beneficiaray", projectid, fsd_search_current_page, fsd_search_page_size, filters],
            queryFn: fetchBeneficiries,
            onSuccess: data => {
                beneficiaryStore.set_beneficiries(data["data"]["results"])
                setTotal(data["data"]["total"])
            }
        }
    )

    const addNewBeneficiaryHandler = async () => {
        beneficiaryStore.update_add_beneficiary_modal(true);
        await beneficiaryStore.set_active_beneficiary({});
        await voterListStore.update_active_voter({})
        await beneficiaryStore.resetActiveFamily()
        await beneficiaryStore.resetActiveRequest()
    }

    const close_modal = async () => {
        beneficiaryStore.update_add_beneficiary_modal(false)
        await voterListStore.update_active_voter({});
    }

    const close_Filter_modal = () => {
        voterListStore.setFilterStatus(false)
        voterListStore.setShowVoterlist(true)
        voterListStore.setFilterVal(null);
        voterListStore.setSelectedBooths([]);
    }

    const close_benificieryFilter = () => {
        beneficiaryStore.setBeneficiarieFilter(false);
    }

    const close_voterList = () => {
        voterListStore.setShowVoterlist(false);
        voterListStore.update_active_voter({});
    }

    /********** Search methods -- start***********/
    const handleSearchBarChange = (value) => {
        setQuery(value)
    }

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            refetch();
        }
    }
    /********** Search methods -- end***********/
    // const downloadable_format = beneficiaryStore.beneficiaries.length >= 0 && beneficiaryStore.beneficiaries.map(beneficiary => beneficiary["summary"].profile)

    if (isLoading || fullProjectLoading) return <LoadingState/>

    // @ts-ignore
    return (
        <>
            {!isMobile &&
                <>
                    <Crumbs/>
                    <ProjectHeader/>
                </>
            }
            <div className='p-4 overflow-hidden primary-text'>
                <div className="flex align-center items-start gap-x-2">
                    <div className='flex flex-row justify-between items-center mb-4 flex-1'>
                        <h2 className='font-bold text-3xl text-indigo-500 pr-14'>{t("b_m")}</h2>
                        <div className={"flex items-start justify-between gap-x-4"}>
                            <RNSearchBar onSearch={handleSearchBarChange}/>
                            <Button
                                variant={"contained"}
                                startIcon={<i className="fas fa-plus-circle"/>}
                                onClick={addNewBeneficiaryHandler}
                            >
                                {t("n_b")}
                            </Button>
                        </div>
                    </div>
                    <Button
                        variant={"contained"}
                        endIcon={<i className="fas fa-filter"/>}
                        onClick={() => {
                            beneficiaryStore.setBeneficiarieFilter(true);
                        }}
                    >
                        {t("Filter")}
                    </Button>

                    {(userStore.isRoot() || userStore.isClient()) &&
                        <Link to={`/project/${projectid}/beneficiary_management/admin`} className="flex ml-4">
                            <i className={"fa-solid fa-gear text-4xl"}/>
                        </Link>
                    }
                </div>

                <Stack spacing={2} className='w-full' direction={"row"} alignItems={"center"}
                       justifyContent={"space-between"}>
                    <FSDPagination key={"Beneficiary"} totalPages={totalPages}/>
                    {/*<DownloadAsExcelSheet data={downloadable_format} cols={excel_col_def} name={`S`} />*/}
                </Stack>

                <SideOverlay
                    onClose={close_modal}
                    show={add_beneficiary_modal}
                    title={t("add_new_beneficiary")}
                >
                    <AddBeneficiary/>
                </SideOverlay>

                <SideOverlay
                    onClose={close_voterList}
                    show={showVoterlist}
                    title={t("search_voters")}
                >
                    <SearchVoterList/>
                    <VoterListWithRadioButton onVoterSelection={onVoterSelection}/>
                </SideOverlay>

                <SideOverlay
                    onClose={close_Filter_modal}
                    show={FilterStatus}
                    title={t("filter_votersearch")}
                >
                    <FilterOverLay/>
                </SideOverlay>

                <SideOverlay
                    onClose={close_benificieryFilter}
                    show={BeneficiariesFilter}
                    title={t("Beneficiary Filters")}
                >
                    <BeneficiaryFilters/>
                </SideOverlay>

                <BeneficiariesTable/>
            </div>
        </>
    );
});


export default BeneficiaryManagementV2