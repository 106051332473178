import {useParams} from "react-router-dom";
import {BreadCrumbs} from "../../../components";
import React from "react";

export const ACBankCrumbs = ({fst}) => {

    const {key, fstid} = useParams();
    console.log(fst)

    const crumbs = [
        {name: 'DataBank', href: '/databank', current: false},
        {name: fst, href: '/databank/repo/' + `${fstid}/` + `${key}`, current: false},
        {name:"AC Bank", href: '#', current: true}
    ];

    return <BreadCrumbs crumbs={crumbs}/>
}