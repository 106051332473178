/***** Rename Keys ********/

export const renameKeys = (obj, oldKey, newKey) => {
    const newObj = {};
    for(let xKey of Object.keys(obj)){
        if(xKey === oldKey){
            newObj[newKey] = obj[xKey];
        }else{
            newObj[xKey] = obj[xKey];
        }
    }
    return newObj;
};