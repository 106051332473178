import { useEffect, useState, Fragment, useRef } from "react";
import React from 'react'
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import TrieSearch from 'trie-search';
import { Dialog, Transition } from '@headlessui/react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { classNames, htmlValidation, useStore } from "../helpers";
import { useTranslation } from "react-i18next"
import {clone} from "lodash";
import {theme} from "./DashboardPage";
import {DeleteModal, LoadingState} from "../components";
import {useIsMobile} from "../custom-hooks";
import {Button} from "@mui/material";
import {useQuery} from "@tanstack/react-query";

export const Projects = observer(() => {

    const rootStore = useStore();
    const { userStore, projectStore, voterListStore, digitalArchiveStore } = rootStore;
    const [loading, setLoading] = useState(false);
    const [projects, setProjects] = useState([]);
    const navigate = useNavigate();
    const [t] = useTranslation("")
    const [trieStore, setTrieStore] = useState(new TrieSearch(['id', 'name', 'description', 'dates'], {
        splitOnRegEx: /[\s\/()]/,
        min: 2,
        ignoreCase: true,
        indexField: 'id',
    }));
    const [filteredProjects, setFilteredProjects] = useState([]);
    const [searchQuery, setSearchQUery] = useState('');
    const [open, setOpen] = useState(false)
    const cancelButtonRef = useRef(null)



    const fetchData = async () => {
        // setLoading(true);
        // const response = await userStore.getProjects();
        // setProjects(response);
        // setLoading(false);
        return await userStore.getProjects();
    };

    const {data, isLoading, refetch} = useQuery({
        queryKey: ["projects-list"],
        queryFn:fetchData,
        onSuccess: data => {
            setProjects(data)
        }
    })

    useEffect(() => {
        const newTs = new TrieSearch(['id', 'name', 'description', 'dates'], {
            splitOnRegEx: /[\s\/()]/,
            min: 3,
            ignoreCase: true,
            indexField: 'id',
        });
        newTs.addAll(projects);
        setTrieStore(newTs);

        if (searchQuery.trim() === '') {
            setFilteredProjects(projects);
        } else {
            setFilteredProjects(newTs.get(searchQuery));
        }
    }, [projects])

    useEffect(() => {
        if (searchQuery.trim() === '') {
            setFilteredProjects(projects);
        } else {
            setFilteredProjects(trieStore.get(searchQuery));
        }
    }, [searchQuery])

    const defaultProjectValues = {
        name: "",
        dates: "",
        description: ""
    }
    const isMobile = useIsMobile();
    const [editMode, setEditMode] = useState(false)
    const [projectDetails, setProjectDetails] = useState(defaultProjectValues)
    const isFormEmpty = Object.values(projectDetails).includes("");

    const _addNewProject = async () => {
        if (editMode === false) {
            const response = await projectStore.addNewProject(projectDetails);
            if (response.status === 200) {
                toast.success("New project added successfully")
            } else {
                toast.error("unable to add project")
            }
        } else {

            const res = await projectStore.editProject(projectDetails)
            if (res.status === 200) {
                toast.success("Project edited successfully")
            } else toast.error("Unable to save")
        }
        setOpen(false);
        refetch();
    };

    const deleteProject = async (evt, id) => {
        htmlValidation(evt)
        const res = await projectStore.deleteProject(projectDetails, id);
        if (res.status === 200) {
            toast.warn("Project deleted successfully")
        } else toast.error("Something went wrong")
        setOnDeleteProjectClick(false)
        refetch();
    }

    const cancelCB = (evt) => {
        htmlValidation(evt)
        setOnDeleteProjectClick(false)
    }

    const [onDeleteProjectClick, setOnDeleteProjectClick] = useState(false)
    const [activeProjectId, setActiveProjectId] = useState('');

    const handle_project_change = async(project) => {
        const parts = window.location.pathname.split('/')
        const idx = parts.indexOf('project') + 1;
        parts[idx] = project["id"]
        const newPath = parts.join("/")
        navigate(newPath)
        await localStorage.setItem("isSigned", "no")
        // await localStorage.setItem('__active_project', JSON.stringify(project));
        // await projectStore.getFullProject(project["id"]);
        rootStore.update_switch_project(false);
        await digitalArchiveStore.update_folder_breadcrumbs([]);
        await digitalArchiveStore.update_DAStoreProp("activeParentId", "")


        //TODO: update project id in voter filters
        const clone_voter_filters = clone(voterListStore.voter_filters);
        clone_voter_filters["projectid"] = parseInt(project["id"])
        await voterListStore.update_filters(clone_voter_filters);
    }

    const PrimaryComponent = () => {
        return (
            <>
                <div className="flex flex flex-row flex-wrap sm:flex-nowrap items-center justify-between gap-x-2 cursor-pointer text-gray-500 text-xl">
                    <input
                        value={searchQuery}
                        onChange={(evt) => {
                            setSearchQUery(evt.target.value);
                        }}
                        className="border-2 border-gray-300 bg-white h-8 sm:h-12 px-5 rounded-lg focus:outline-none "
                        type="search" name="search" placeholder="Search Projects"
                    />
                    {userStore.isRoot() ?
                        <Button
                            variant={"contained"}
                            onClick={() => {
                                setProjectDetails(defaultProjectValues);
                                setEditMode(false);
                                setOpen(true);
                            }}
                            endIcon={<i className="fas fa-plus-circle"/>}
                        >
                           Add New Project
                        </Button> : null}
                </div>

                <div className="mt-5 flex flex-col">
                    {
                        filteredProjects.map(project => {
                            return (
                                <div key={project['id']}
                                    className="flex flex-col rounded-lg shadow-lg overflow-hidden my-2 group"
                                    style={{
                                        borderLeft: `4px solid ${theme}`,
                                        borderTopLeftRadius: 0,
                                        borderBottomLeftRadius: 0
                                    }}
                                >
                                    <div className="flex-1 bg-white p-2 flex flex-row justify-between items-center cursor-pointer" onClick={() => handle_project_change(project)}>
                                        <div className="text-lg text-gray-900 flex-1">{project["name"]}</div>
                                        {userStore.isRoot() ?
                                            <div className={classNames(!isMobile ? "invisible group-hover:visible":"", "flex flex-row flex-wrap space-x-4 bg-white px-3 text-sm " )}>
                                                <a className={`text-blue-500 cursor-pointer transition-opacity duration-800`}
                                                   onClick={(evt) => {
                                                       htmlValidation(evt)
                                                       const newProjDetails = {
                                                           id: project.id,
                                                           name: project.name,
                                                           dates: project.dates,
                                                           description: project.description
                                                       };
                                                       setProjectDetails(newProjDetails);
                                                       setEditMode(true);
                                                       setOpen(true);
                                                   }}>Edit</a>
                                                <a className={`text-blue-500 cursor-pointer transition-opacity duration-800 `}
                                                   onClick={(evt) => {
                                                       htmlValidation(evt)
                                                       setOnDeleteProjectClick(true)
                                                       setActiveProjectId(project['id'])
                                                   }}>Delete</a>
                                            </div> :
                                            null
                                        }
                                        <div className="text-gray-400 text-sm ml-auto text-right">{project["id"]}</div>
                                    </div>
                                    {/*<div className="flex-1 bg-white p-3 flex flex-col justify-between cursor-pointer"
                                         onClick={() => {
                                             navigate('project/' + project['id'])
                                             localStorage.setItem('active_project', JSON.stringify(project));
                                         }}>
                                        <div className="flex flex-row">
                                            <div className="pr-2 text-2xl flex flex-col">
                                                <i className="far fa-landmark" />
                                                <div
                                                    className="text-sm text-gray-700 my-2 rounded-full bg-white flex items-center justify-center font-mono"
                                                    style={{
                                                        height: 30,
                                                        width: 30,
                                                        border: '1px solid black'
                                                    }}>{project['id']}
                                                </div>
                                            </div>
                                            <div>
                                                <p className="text-xl font-semibold text-gray-900">{project["name"]}</p>
                                                <p className="mt-2 text-base text-gray-500">{project["dates"]}</p>
                                                <p className="mt-2 text-base text-gray-500">{project["description"]}</p>
                                            </div>
                                        </div>
                                    </div>*/}

                                </div>
                            )
                        })
                    }
                    {onDeleteProjectClick && <DeleteModal onDeleteClick={deleteProject}
                        onCancelClick={cancelCB}
                        isOpen={onDeleteProjectClick} id={activeProjectId}
                        desc='Do you want to Delete this Project ?' />}
                </div>
            </>
        );
    }

    const addNewProjectModal = () => {
        const { name, description, dates } = projectDetails

        return (
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    static
                    className="fixed z-10 inset-0 overflow-y-auto"
                    initialFocus={cancelButtonRef}
                    open={open}
                    onClose={setOpen}
                >
                    <div
                        className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div
                                className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                <div className="p-4">
                                    <div className="text-xl font-semibold text-gray-900 pb-4">Add New Project</div>
                                    <label className="block text-sm font-medium text-gray-700 pt-4"
                                        htmlFor="newProjectName">Name</label>
                                    <input type="text"
                                        className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md border-solid border p-2"
                                        value={name}
                                        onChange={event => {
                                            const name = event.target.value
                                            setProjectDetails({ ...projectDetails, name })
                                        }}
                                    />

                                    <label htmlFor="newProjectTitl"
                                        className="block text-sm font-medium text-gray-700 pt-4">Sub-Title</label>
                                    <input type="text"
                                        className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md border p-2"
                                        value={dates}
                                        onChange={event => {
                                            const dates = event.target.value
                                            setProjectDetails({ ...projectDetails, dates })
                                        }}
                                    />
                                    <label htmlFor="newProjectDescription"
                                        className="block text-sm font-medium text-gray-700 pt-4">Description</label>
                                    <textarea
                                        className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md border p-2"
                                        rows={3}
                                        value={description}
                                        onChange={event => {
                                            const description = event.target.value
                                            setProjectDetails({ ...projectDetails, description })
                                        }}
                                    >
                                    </textarea>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 flex flex-row-reverse justify-start">

                                    <button
                                        type="button"
                                        className={classNames(isFormEmpty ? "pointer-events-none opacity-50" : "",
                                            "ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500")}
                                        onClick={(evt) => {
                                            _addNewProject()
                                        }}
                                        disabled={Object.values(projectDetails).includes("")}
                                    >
                                        {!editMode ? "Save" : "update"}
                                    </button>
                                    <button
                                        type="button"
                                        className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
                                        onClick={() => setOpen(false)}
                                        ref={cancelButtonRef}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        )

    }

    return (
        <>
            {loading || isLoading ? (<LoadingState />) : (
                <>
                    {PrimaryComponent()}
                    {addNewProjectModal()}
                </>
            )}
        </>
    );
})