import React from 'react'

type ProgressBarProps = {
    title: string,
    progress: number,
    bgcolor?: string
    color?: string,
    height: number
}

const ProgressBar = (props: ProgressBarProps) => {

    const {bgcolor='whitesmoke',progress,height,color,title} = props;

    const Parentdiv = {
        height: height,
        width: '100%',
        backgroundColor: bgcolor,
        borderRadius: 40,
        marginTop: 10,
        marginBottom: 10
    }

    const Childdiv: any = {
        height: '100%',
        width: `${progress}%`,
        backgroundColor: color,
        borderRadius:40,
        textAlign: 'right'
    }

    const progresstext = {
        padding: 5,
        color: 'black',
        fontWeight: 900
    }

    return (
        <div className='flex flex-col my-2'>
            <div className="flex flex-row">
                <div>{title}</div>
                <div className="ml-auto font-semibold">{progress}%</div>
            </div>
            <div style={{marginTop: -8}}>
                <div style={Parentdiv}>
                    <div style={Childdiv}>
                        <span style={progresstext}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProgressBar;
