import React, {useState} from "react";
import {useStore} from "../../../../helpers/helpers";
import {observer} from "mobx-react-lite";
import {DeleteModal} from "../../../../components/DeleteModal";

const Info = props => {

    const {text, icon} = props

    return(
        <div className="flex flex-row items-center py-2">
            <i className={`px-4 text-blue-500 ${icon}`}/>
            <div className="font-semibold">{text}</div>
        </div>
    )
}

export const InfluentialLeadersTable = observer( (props:any) => {
    const rootStore = useStore()
    const {flexiStore} = rootStore;

    const [deleteGroupModal, setDeleteGroupModal] = useState(false)

    const delete_leader = async (e, id) => {
        const response = await flexiStore.delete_influential_leader(id);
        if (response.status === 200) {
            flexiStore.get_influential_leaders();
        }
        setDeleteGroupModal(false)
    }

    const cancelCB = (e) => {
        setDeleteGroupModal(false)
    }

    return (
        <>
            <div className="grid grid-cols-1 lg:grid-cols-4 xl:grid-cols-5 gap-6">
                {
                    flexiStore.influential_leaders.map(leader => {
                        const {info, id} = leader;
                        return (
                            <>
                                <div className="rounded-lg shadow-lg flex flex-col p-4 bg-white">
                                    <div className="flex flex-col">
                                       <div className="flex flex-row justify-between items-center">
                                           <p className="font-bold text-xl capitalize text-purple-600 my-2">{info.name}</p>
                                           <div className="flex flex-row">
                                               <div className='cursor-pointer mx-2 text-blue-600'
                                                    title='Edit Leader'
                                                    onClick={(e) => {
                                                        flexiStore.set_add_leader_modal(true);
                                                        flexiStore.set_ileader_edit_mode(true);
                                                        flexiStore.set_active_leader(leader);
                                                    }}><i className="fa-solid fa-pen-circle text-xl"></i>
                                               </div>
                                               <div className='cursor-pointer mx-2 text-red-500'
                                                    title='Delete Leader'
                                                    onClick={() => {
                                                        flexiStore.set_active_leader(leader);
                                                        setDeleteGroupModal(true);
                                                    }}><i className="fa-solid fa-circle-trash text-xl"></i></div>
                                           </div>
                                       </div>
                                        {info.phone && <Info text={info.phone} icon="fa-solid fa-phone"/>}
                                        {info.position && <Info text={info.position} icon="fa-solid fa-chair"/>}
                                    </div>
                                    <div className="flex flex-col">
                                        {info.caste && <Info text={info.caste} icon="fa-solid fa-hands-praying"/>}
                                        {info.alliance && <Info text={info.alliance} icon="fa-solid fa-flag-swallowtail"/>}
                                        {info.comments && <Info text={info.comments} icon="fa-solid fa-comment"/>}
                                    </div>

                                </div>
                            </>
                        )
                    })
                }
                <div>
                    {deleteGroupModal ? <DeleteModal onDeleteClick={delete_leader} id={flexiStore.active_influential_leader["id"]}
                                                     onCancelClick={cancelCB} isOpen={deleteGroupModal}
                                                     desc={`Do you want to delete ${flexiStore.active_influential_leader["info"]["name"]} ?`}/> : null}
                </div>
            </div>
        </>
    )
})