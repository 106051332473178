import React, {useEffect, useMemo, useState} from "react";
import {observer} from "mobx-react-lite";
import _ from "lodash";
import {Table} from "./table";
import {toJS} from "mobx";
import {safe_get} from "../../../report-visuals/report-utils";
import {tConvert, useStore} from "../../../helpers";
import {TableHeader} from "./survey-summary";
import { useTranslation } from "react-i18next";

export const AllSurveys = observer(props => {
    const {surveyResponseStore, projectStore, flexiStore} = useStore();
    const {allSurveyResponses} = surveyResponseStore;

    const data = useMemo(() => toJS(allSurveyResponses), [allSurveyResponses])
    const [selectedSurveyId, setSelectedSurveyId] = useState(surveyResponseStore.activeSurveyResponseId);
    const [t] = useTranslation(["analytical_summary", "survey_management"])

    const columns = useMemo(() => [
        {
            Header: t("summary"),
            accessor: 'hashighlights',
            Cell: props => {
                const row = props.row.original;
                const hasaudiofile = safe_get(row, "hasaudiofile", false);
                const hashighlights = safe_get(row, "hashighlights", false);
                const hasCoordinates = parseFloat(row["lat"]) && parseFloat(row["long"]);
                const ai_flags = safe_get(row, "ai_flags", []);
                let location_flag = {};
                try{
                    if(ai_flags.length > 0){
                        location_flag =  _.find(ai_flags, flag =>  flag.flag_type === "red")
                    }
                } catch (e) {
                    console.log(e)
                }

                const is_red_flag = safe_get(location_flag, "flag_type", "");
                const title = safe_get(location_flag, "reason", "");

                return(
                    <div className='flex flex-row'>
                        {hashighlights === true ? <i className="fa-solid fa-star text-yellow-300 text-2xl"
                                                     title="Contains Important Highlights"/> : null}
                        {hasaudiofile === false ? <i className="fa-solid fa-microphone-slash text-red-300 text-2xl"
                                                     title="Audio not yet synced"/> : null}
                        {hasCoordinates === 0 ? <div className='' title="No Location">
                            <img src="/images/map-marker-slash.png" style={{width: 30}}/>
                        </div> : null}
                        {is_red_flag === "red" && <i className="fa-solid text-red-600 text-xl px-2 fa-flag-pennant" title={title}/>}
                    </div>
                )
            }
        },
        {
            Header: t("surveyor_name"),
            accessor: 'surveyor_name',
        },
        {
            Header: t("survey_id"),
            accessor: 'id',
            Cell: props => {
                const value = props.cell.value;
                const bg_color = value === toJS(surveyResponseStore.activeSurveyResponseId) ? "" :"";
                return (
                    <div className={`flex flex-row items-center ${bg_color}`}>
                        {value}
                    </div>

                )
            }
        },
        {
            Header: t("date"),
            accessor: 'dt_month_date'
        },
        {
            Header: t("start_time"),
            accessor: 'dt_start_time',
            Cell: props => {
                const value = tConvert(props.cell.value);
                return(
                    <div>
                        {value}
                    </div>
                )
            }
        },
        {
            Header: t("end_time"),
            accessor: 'dt_end_time',
            Cell: props => {
                const value = tConvert(props.cell.value);
                return(
                    <div>
                        {value}
                    </div>
                )
            }
        },
        {
            Header: t("duration_sec"),
            accessor: 'dt_duration_in_seconds',
            Cell: props => {
                const value = props.cell.value;
                return(
                    <div>
                        {Math.floor(value / 60) + ":" + (value % 60 ? value % 60 : '00')}
                    </div>
                )
            }
        },
        {
            Header: t("qa_status"),
            accessor: 'qa_status',
            Cell: props => {
                    const value = props.cell.value;
                return(
                    <div>
                        {value === "valid" ?
                            <i className="fa-solid fa-check-circle text-green-600 text-2xl" title="valid" /> : null}
                        {value === "invalid" ? <i className="fa-solid fa-exclamation-circle text-red-600 text-2xl"
                                                         title="in-valid" /> : null}
                        {value === "not-checked" ?
                            <i className="fa-solid fa-minus-circle text-yellow-500 text-2xl"
                               title="not-checked" /> : null}
                        {value === "onhold" ? <i className="fa-solid fa-pause-circle text-indigo-500 text-2xl"
                                                        title="not-checked" /> : null}
                    </div>
                )
            }
        }
    ], [allSurveyResponses, surveyResponseStore.activeSurveyResponseId]);

    useEffect(() => {
        const _path = projectStore.getProjectProperty("ac_map", "");
        flexiStore.load_s3_json_resource("urn-fsd", _path, "");
    }, []);

    if(_.isEmpty(allSurveyResponses)) return <div>{t("no_data_found")}</div>;

    const row_click_handler = (x) => {
        surveyResponseStore.setActiveSurveyResponseId(x.id);
        setSelectedSurveyId(x.id);
    }

    return(
        <div className="flex flex-col my-4 all_surveys">
            <TableHeader title=""/>
            <Table data={data} columns={columns} pagesize={20} onRowClick={row_click_handler} hideFooter={true} highlightRow={true} activeSurveyResponseId={selectedSurveyId}/>
        </div>
    )
})