import React from 'react';
import {useIsMobile} from "../../../custom-hooks";
import {safe_get} from "../../../report-visuals/report-utils";
import {Avatar} from "@mui/material";


export type contact = {
    id: string,
    name: string,
    phone: string,
    alternate_phone: string,
    email: string,
    address: string,
    comments: string,
    designation: any
}
type contactProps = {
    contact : contact,
    onEdit: Function,
    onDelete: Function
}

export const ContactCard = (props: contactProps) => {

    const {contact, onEdit, onDelete} = props;
    const isMobile = useIsMobile();

    return(
        <div className="rounded-lg shadw-lg bg-green-50 p-2 sm:p-4 mx-2 group">
            <div className="flex flex-col">
                <div className="flex flex-row justify-between items-center">
                    <div className="flex flex-row items-center">
                        <Avatar>
                            <i className={`${safe_get(contact, "designation.icon", "fa-solid fa-user")}`} />
                        </Avatar>
                        <h2 className="px-2">{contact.name}</h2>
                    </div>
                    <div className="flex flex-row items-center justify-between">
                        <i className={`fas fa-pencil text-gray-600 pr-2 cursor-pointer transition-opacity duration-800 ${!isMobile ? "invisible group-hover:visible" :"" }`} onClick={ e => onEdit(contact)}/>
                        <i className={`fas fa-trash text-red-600 pl-2 cursor-pointer transition-opacity duration-800 ${!isMobile ? "invisible group-hover:visible" :"" } `} onClick={e => onDelete(contact.id)}/>
                    </div>
                </div>
                <div className="flex flex-row items-center">
                    <i className="fas fa-phone"/>
                    <p className="px-2">{contact.phone}</p>
                </div>
                <div className="flex flex-row items-center">
                    <i className="fas fa-map-marker"/>
                    <p className="px-2">{contact.address}</p>
                </div>
                <div className="flex flex-row items-center">
                   <i className="fas fa-comment"/>
                    <p className="px-2">{contact.comments}</p>
                </div>
            </div>
        </div>
    )
}