import _ from "lodash";
import React from "react";
import {reduce_for_visual, safe_get} from "../../../report-visuals/report-utils";
import {GridCustomCellRenderer} from "../../../report-visuals/grid-custom-cell-renderer";
import {tab} from "@testing-library/user-event/dist/tab";

const THREAT = {'display': 'THREAT', 'fgcol': '#fff', 'bgcol': '#5B2C6F'};
const STRONG = {'display': 'STRONG', 'fgcol': '#fff', 'bgcol': '#1E8449'};
const AVERAGE = {'display': 'AVERAGE', 'fgcol': '#fff', 'bgcol': '#3498DB'};
const WEAK = {'display': 'WEAK', 'fgcol': '#fff', 'bgcol': '#B03A2E'};
const OPPORTUNITY = {'display': 'OPPORTUNITY', 'fgcol': '#fff', 'bgcol': '#D4AC0D'};
const INPROGRESS = {'display': 'IN PROGRESS', 'fgcol': '#fff', 'bgcol': '#CCD1D1'};

export const grade_filter = (key: string, grArr) => {
    return _.filter(grArr, x => {
        return x["grade"]["display"] === key
    })
}

export const SectionTitle = (props) => {
    const {text} = props;
    return (
        <div className='mx-auto pl-6 font-bold text-2xl my-2 pt-4 text-blue-600'>{text}</div>
    )
}

export const build_reducer = (col_key) => {
    return [
        {
            "op": "emit",
            "cols": [
                col_key,
                "id"
            ],
            "out": [
                col_key,
                "id"
            ]
        },
        {
            "op": "blacklist",
            "filter_key": col_key,
            "filter_val": ''
        },
        {
            "op": "count",
            "cols": [
                col_key,
            ],
            "out": [
                col_key,
                "surveys"
            ],
            "sumcol": col_key,
            "setcol": "surveys"
        },
        {
            "op": "percent_grand_total",
            "cols": [
                col_key,
                "surveys"
            ],
            "out": [
                col_key,
                "surveys"
            ],
            "sumcol": "surveys",
            "setcol": "surveys"
        }
    ]
}

export const build_arr_reducer = (col_key) => {
    return [
        {
            "op": "emit",
            "cols": [
                col_key,
                "id"
            ],
            "out": [
                col_key,
                "id"
            ],
            "unpack": true,
            "unpack_col": col_key
        },
        {
            "op": "blacklist",
            "filter_key": col_key,
            "filter_val": ''
        },
        {
            "op": "count",
            "cols": [
                col_key,
            ],
            "out": [
                col_key,
                "surveys"
            ],
            "sumcol": col_key,
            "setcol": "surveys"
        },
        {
            "op": "percent_grand_total",
            "cols": [
                col_key,
                "surveys"
            ],
            "out": [
                col_key,
                "surveys"
            ],
            "sumcol": "surveys",
            "setcol": "surveys"
        }
    ]
}

export const PageSection = (props) => {
    const {text} = props;
    return (
        <div className='page-container bg-white mx-auto p-6 flex flex-row items-center'>
            <div className={'font-bold text-3xl ml-6'}>{text}</div>
        </div>
    )
}

export const RenderACName = ({title, sub_title}) => {
    return(
        <div className='page-container bg-white mx-auto p-6 flex flex-column items-center'>
            <div>
                <div className={'font-bold text-3xl ml-6 uppercase'}>{title}</div>
                <div className={'text-2xl ml-6 text-gray-500 uppercase'}>{sub_title}</div>
            </div>
        </div>
    )
}

export const F20Table = (props) => {

    const {ds, title, headers} = props
    return (
        <>
            <div className={'font-bold text-indigo-700'}>{title}</div>

            <table className='text-sm w-full'>
                <tr className='border-t-2 border-b-2 border-gray-400 font-bold'>
                    {
                        headers.map((x, index) => {
                            return (
                                <td key={index} className=''>{x}</td>
                            )
                        })
                    }
                </tr>

                {
                    ds.map((row, index) => {
                        return (
                            <tr key={index} style={{borderBottom: '1px solid #AAB7B8'}}>
                                {
                                    row.map(x => {
                                        return (
                                            <td key={x}>{x}</td>
                                        )
                                    })
                                }
                            </tr>
                        )
                    })
                }
            </table>
        </>
    )
}

export const BoothReportSimpleTable = (props) => {
    const {widget, ds, booth_key, width} = props
    const table_type = safe_get(widget, "type", "simple-table")
    const headers = safe_get(widget, "headers", [])
    const colkey = safe_get(widget, "colkey", "")
    const top = safe_get(widget, 'restrict_top', -1);
    let transformed = props['transformed']
    const CustomRenderer = new GridCustomCellRenderer()
    const MAX_ROWS = 10

    transformed.length = Math.min(transformed.length, MAX_ROWS)
    if (top > 0) {
        transformed = transformed.slice(0, top)
    }

    if(table_type === "matrix") return null

    return (
        <>
            <table className='text-sm w-full'>
                <tr className='border-t-2 border-b-2 border-gray-400 font-bold'>
                    <td className='text-left'>{headers[0]}</td>
                    <td className='text-right'>{headers[1]}</td>
                </tr>
                {transformed.map((x, index) => {
                    return (
                        <tr key={index} style={{borderBottom: '1px solid #AAB7B8'}}>
                            <td>{x[colkey]}</td>
                            <td className='ml-2 text-right'>{CustomRenderer.percent_renderer({value: x["surveys"]})}</td>
                        </tr>
                    )
                })}
            </table>
        </>
    )
}

const BoothReportMatrixTable = (props) => {

    const {widget, ds, booth_key, width} = props
    let headers = []
    const rows = safe_get(ds, "rows", [])

    try {
        headers = safe_get(ds, "headers", [])
    } catch (e) {
        console.log(headers)
    }

    if(headers.length <= 0) return null;
    return (
        <>
            <table className='text-sm w-full'>
                <tr className='border-t-2 border-b-2 border-gray-400 font-bold td-highlight'>
                    {
                        headers && headers.length > 0 && headers?.map((header, index) =>  {
                            return <td  key={index} className='text-left td-highlight'>{header}</td>
                        })
                    }
                </tr>
                {rows && rows.length > 0 && rows?.map((row, index) => {
                    return (
                        <tr key={index}  style={{borderBottom: '1px solid #AAB7B8'}}>
                            {row.map((x, index) => {
                                return <td className={"td-highlight td-center"} key={index}>{x}</td>
                            } )}
                        </tr>
                    )
                })}
            </table>
        </>
    )
}

export default React.memo(BoothReportMatrixTable)

export const grader = (booth_key, config, booth_group) => {

    const {grading_config, booth_key_prefix, report_min_interviews} = config;

    try {
        const {key_to_extract, our_party} = grading_config
        const ds = booth_group[`${booth_key_prefix}${booth_key}`]
        if (!ds) return INPROGRESS;
        if (ds.length < report_min_interviews) return INPROGRESS;
        const reducer = build_reducer(key_to_extract)
        const transformed = _.orderBy(reduce_for_visual(ds, reducer, {}), ['surveys'], ['desc'])

        const keys_extracted = transformed.map(x => x[key_to_extract])
        let our_value = 0
        try {
            our_value = _.find(transformed, o => {
                return o[key_to_extract] === our_party
            })
            our_value = our_value["surveys"]
        } catch (e) {
            //our key itself is not there!!!
            //Discovered in Mar 2022 - Arsikere Project
            //When our presence itself is not there, we will return as Weak
            return WEAK
        }

        const our_index = keys_extracted.indexOf(our_party)
        const opp_index = (our_index == 0) ? 1 : 0
        //const our_index = _.findIndex(keys_extracted, our_party)
        const opp_key = keys_extracted[opp_index]
        let opp_value = _.find(transformed, o => {
            return o[key_to_extract] === opp_key
        })
        opp_value = safe_get(opp_value,"surveys", 0)

        const {strength_th, weak_th, delta_th} = grading_config
        const diff = (our_value - opp_value)

        //if (opp_value >= 0.50) return WEAK;

        if (our_value >= strength_th) {
            if (diff < 0) return THREAT;
            if (diff <= delta_th) return AVERAGE;
            return STRONG;
        }
        if (our_value < weak_th) return WEAK;

        // const diff = (opp_value - our_value);
        if ((diff >= 0) && (diff < delta_th)) return AVERAGE;
        if (our_value > opp_value) return OPPORTUNITY;
        return THREAT;
    } catch (e) {
        console.log(e)
        return INPROGRESS;
    }
}

export const cooked_grader = (booth_key, r3, config) => {

    const { booth_key_prefix, grading_config } = config;
    try {
        const {key_to_extract, our_party} = grading_config
        let ds = r3[`${booth_key_prefix}${booth_key}`]
        if (!ds) return INPROGRESS;

        const transformed = ds[key_to_extract]
        transformed.sort(function (a, b) {
            return b['surveys'] - a['surveys'];
        });

        const keys_extracted = transformed.map(x => x[key_to_extract])
        let our_value = 0
        try {
            our_value = _.find(transformed, o => {
                return o[key_to_extract] === our_party
            })
            our_value = our_value["surveys"]
        } catch (e) {
            //our key itself is not there!!!
            //Discovered in Mar 2022 - Arsikere Project
            //When our presence itself is not there, we will return as Weak
            return WEAK
        }

        const our_index = keys_extracted.indexOf(our_party)
        //const our_index = _.findIndex(keys_extracted, our_party)
        const opp_index = (our_index == 0) ? 1 : 0
        const opp_key = keys_extracted[opp_index]
        let opp_value = _.find(transformed, o => {
            return o[key_to_extract] === opp_key
        })
        opp_value = opp_value["surveys"]

        const {strength_th, weak_th, delta_th} = grading_config
        const diff = (our_value - opp_value)

        //if (opp_value >= 0.50) return WEAK;

        if (our_value >= strength_th) {
            if (diff < 0) return THREAT;
            if (diff <= delta_th) return AVERAGE;
            return STRONG;
        }
        if (our_value < weak_th) return WEAK;

        // const diff = (opp_value - our_value);
        if ((diff >= 0) && (diff < delta_th)) return AVERAGE;
        if (our_value > opp_value) return OPPORTUNITY;
        return THREAT;
    } catch (e) {
        return INPROGRESS;
    }
}