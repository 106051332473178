import {FunnelIcon} from "@heroicons/react/24/outline";
import React, {useState, useEffect, useReducer} from "react";
import SearchBar from "../../components/SearchBar";
import {useStore} from "../../helpers/helpers";
import {observer} from "mobx-react-lite";
import {error_toast} from "../../helpers/file-upload";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router";
import {clone} from "lodash"
import {Button, Pagination, Stack,} from "@mui/material";
import {FilterButton, LoadingState} from "../../components";

export const filter_button_style = `inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium 
rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`

const initialState = {
    loading: true,
    voterList: [],
    totalVoters: 0,
    error: ""
}

const reducer = (state, action) => {
    switch (action.type) {
        case "START" :
            return {
                ...state,
                loading: true,
                error: ''
            }
        case "SUCCESS":
            return {
                loading: false,
                voterList: action.payload.results,
                totalVoters: action.payload.total,
                error: ""
            }
        case "FAILURE":
            return {
                loading: false,
                voterList: [],
                totalVoters: 0,
                error: "Something went wrong"
            }
        default:
            return state
    }
}

//TODO: This compoenent is responsible for only to fetch voter-list based on search criteria and filters.
//TODO: It will not accept any props

export const SearchVoterList = observer((props: any) => {

    //TODO: useReducer for grouping related state variables in a single object, and to reduce the state transitions
    const [state, dispatch] = useReducer(reducer, initialState);
    const rootStore = useStore();
    const params = useParams();
    const {voterListStore, userStore} = rootStore;
    const { voter_filters } = voterListStore;
    const { relation, projectid, page, house, genders, df, booths, age, name, serialno, voterid , offset, count} = voter_filters;
    const [searchQuery, setQuery] = useState("");
    const { t } = useTranslation(["voter_list", "manage_voters"]);
    const clone_voter_filters = clone(voterListStore.voter_filters)

    const [pageNumber, setPageNumber] = useState(1);
    const totalPages = Math.ceil(state.totalVoters / 100);

    const handlePageChange = async(event: React.ChangeEvent<unknown>, value: number) => {
        setPageNumber(value);
        clone_voter_filters["offset"] = (value - 1) * count
        await voterListStore.update_filters(clone_voter_filters);
    };

    /*TODO: Passing individual filter to the useEffect to cause re-render
    * TODO: It will not re-render if you just pass voter_filters
    * */
    useEffect(() => {
        getVoterList();
    }, [relation, projectid, page, house, genders, df, booths, age, name, serialno, voterid, offset]);

    const getVoterList = async () => {

        dispatch({ type: 'START' });
        clone_voter_filters["projectid"] = parseInt(params["projectid"])
        await voterListStore.update_filters(clone_voter_filters);

        try {
            const res = await voterListStore.getVoterSearch();
            userStore.setUserDetailsMode(false);

            if (res.status !== 200) {
                error_toast();
                dispatch({type: "FAILURE"})
            } else {
                dispatch({type: "SUCCESS", payload: res.data})
            }
        } catch (e) {
            dispatch({type: "FAILURE"})
        }
    };

    const handleSearchBarChange = (value) => {
        setQuery(value);
    };

    const handleSearch = async() => {
        clone_voter_filters["name"] = searchQuery
        await voterListStore.update_filters(clone_voter_filters);
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            handleSearch();
        }
    };

    return (
        <>
            {state["loading"] && <LoadingState/>}
            <div className={"flex flex-col sm:flex-row items-center justify-between"}>
                <h2 className="font-bold text-2xl text-indigo-500 w-full sm:flex-1">Voter Management</h2>
                <div className="flex flex-row items-center justify-between sm:justify-end sm:flex-wrap w-full">
                    <SearchBar
                        query={searchQuery}
                        handleChange={handleSearchBarChange}
                        handleKeyDown={handleKeyDown}
                        getFilteredData={handleSearch}
                        placeholder={"Search By Name..."}
                    />
                    <div className="flex flex-row items-center gap-x-2">
                        <FilterButton
                            filterOnClickHandler={() => {
                                voterListStore.setShowVoterlist(false);
                                voterListStore.setFilterStatus(true);
                            }}
                        />
                        <Button
                            variant={"contained"}
                            onClick={async() => {
                                voterListStore.reset_filters(parseInt(params.projectid));
                                await voterListStore.getVoterSearch();
                            }}
                            endIcon={<i className="fas fa-retweet"/>}
                        >
                            {t("reset_search")}
                        </Button>
                    </div>
                </div>
            </div>

            <Stack spacing={2} className='w-full' direction={"row"} alignItems={"center"} >
                <div className="font-bold text-gray-500">Page: {pageNumber}</div>
                <Pagination color={"primary"} size={"medium"} variant={"outlined"} count={totalPages} page={pageNumber} onChange={handlePageChange} />
            </Stack>
            {state["totalVoters"] > 0 ?
                <>
                    <div className="flex flex-row items-center justify-between mt-2">
                        <div className="flex flex-col">
                            <p className="mt-1 font-bold flex items-center text-md text-purple-600 mr-2 flex-nowrap">
                                <span className="text-base" style={{whiteSpace: "break-spaces"}}>
                                    {t("showing")} <span
                                    className="text-gray-400">{state.voterList?.length}</span> of {state.totalVoters}
                                </span>
                            </p>
                        </div>
                    </div>
                </> : <div className="italic text-gray-500 text-md py-4">{t("voter_data_not_found")}</div>}
        </>
    );
})
