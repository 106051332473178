import {observer} from "mobx-react-lite";
import React, {useEffect, useState} from "react";
import {safe_get} from "../report-visuals/report-utils";
import {Avatar} from "@material-ui/core";
import {useStore, uploadFile} from "../helpers";
import {toast} from "react-toastify";
import {FormButtons, UploadButton} from "./cm-components";

type profileType = {
    name: string;
    phonenum: string;
    role: string;
    user_id: string;
    profileimage: string;
    address: string;
    city: string;
};

export const UpdateProfile = observer((props: any) => {

    const [profile, setProfile] = useState<profileType>({
        name: "",
        phonenum: "",
        role: "",
        user_id: "",
        profileimage: "",
        address: "",
        city: "",
    });
    const rootStore = useStore();
    const {userStore} = rootStore

    const [user, setUser] = useState({})
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const activeUser = JSON.parse(localStorage.getItem("__userprofile"));
        setUser(activeUser)

        setProfile({
            name: safe_get(activeUser, "name", ""),
            phonenum: safe_get(activeUser, "phonenum", ""),
            role: safe_get(activeUser, "role", ""),
            user_id: safe_get(activeUser, "id", ""),
            profileimage: safe_get(activeUser, "profileimageuri", ""),
            address: safe_get(activeUser["profile"], "address", ""),
            city: safe_get(activeUser["profile"], "city", ""),
        });
    }, []);

    //TODO: Get profile
    const handleChange = (e, field) => {
        setProfile({...profile, [field]: e.target.value});
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFile({...file, file})
        // Show a preview of the selected image
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setProfile({...profile, profileimage: imageUrl});
        }
    };

    const handleUpload = async () => {
        if (file) {
            const path = `user-profile/${safe_get(user, "id", -1)}`;
            try {
                const response = await uploadFile(path, file, "urn-assets");
                if (response) {
                    setProfile({...profile, profileimage: response});
                } else {
                    // Handle the case where imageUrl is empty
                    console.error('Image URL is empty');
                                }
            } catch (error) {
                console.error('Upload error:', error);
            }
        }
    };

    const handleSave = async () => {
        userStore.set_profile_update(false);
        setLoading(true);
        try {
            const updatedProfile = {
                profile: {
                    name: profile.name,
                    address: profile.address,
                    city: profile.city,
                    profileimage: profile.profileimage,
                    userid: profile.user_id,
                },
                deviceinfo: {
                    model: "",
                    deviceId: "",
                    carrier: "",
                    maker: ""
                }
            };

            const response = await userStore.updateProfile(updatedProfile);

            if (response.status === 200) {
                toast.success('Profile updated successfully!');

                const secondResponse = await userStore.getProfile();
                if (secondResponse.status === 200) {
                    localStorage.setItem("__userprofile", JSON.stringify(secondResponse.data))
                } else {
                    toast.error('Failed to update profile. Please try again.');
                }
            } else {
                toast.error('Failed to update profile. Please try again.');
            }
        } catch (error) {
            setLoading(false);
        }

    };


    const labelStyle = "block text-sm font-bold text-gray-500 pt-2";
    const inputStyle = "w-screen max-w-lg block shadow-sm focus:ring-indigo-100 focus:border-indigo-100 border-gray-300 rounded-md border-solid border p-2.5";

    return (
        <div
            className="inline-block align-bottom rounded-lg text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div className="flex justify-center items-center">
                <Avatar
                    alt="Profile image"
                    src={profile.profileimage}
                    style={{height: '100px', width: '100px'}}
                >
                </Avatar>
            </div>

            <div>
                <label className={labelStyle}>
                    Name :
                </label>
                <input
                    type="text"
                    className={inputStyle}
                    value={profile.name}
                    onChange={(e) => handleChange(e, "name")}
                />
            </div>

            <div>
                <label className={labelStyle}>
                    Address :
                </label>
                <input
                    type="text"
                    className={inputStyle}
                    value={profile.address}
                    onChange={(e) => handleChange(e, "address")}
                />
            </div>

            <div>
                <label className={labelStyle}>
                    City :
                </label>
                <input
                    type="text"
                    className={inputStyle}
                    value={profile.city}
                    onChange={(e) => handleChange(e, "city")}
                />
            </div>

            <div>
                <label htmlFor="phone" className={labelStyle}>
                    Phone Number :
                </label>
                <input disabled
                       type="text"
                       className={inputStyle}
                       value={profile.phonenum}
                       onChange={(e) => handleChange(e, "phonenum")}
                />
            </div>

            <div>
                <label className={labelStyle}>
                    User Id :
                </label>
                <input disabled
                       type="text"
                       className={inputStyle}
                       value={profile.user_id}
                       onChange={(e) => handleChange(e, "id")}
                />
            </div>

            <div>
                <label className={labelStyle}>
                    Role :
                </label>
                <input disabled
                       type="text"
                       className={inputStyle}
                       value={profile.role}
                       onChange={(e) => handleChange(e, "role")}
                />
            </div>

            <div className="flex flex-row items-center justify-between">
                <div  className="flex flex-col">
                    <label htmlFor="attachments" className={labelStyle}>
                        Upload Photo :
                    </label>
                    <input
                        type="file"
                        name="fileInputName"
                        accept="image/*"
                        id="attachments"
                        className="py-2 block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        onChange={handleFileChange}
                    />
                </div>
                <UploadButton uploadHandler={handleUpload} />
            </div>

            <div className="bg-gray px-4 py-3 flex flex-row-reverse justify-start">
                <FormButtons
                    onSave={handleSave}
                    onCancel={(e) => {
                        userStore.set_profile_update(false)
                    }}
                />
            </div>
        </div>
    )
});
          

