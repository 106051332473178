import React from "react";
import {
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails,
} from "@mui/material";
import {Tables} from "./mh-tables";

export const FieldSamplesData = ({data, samples}) => {

    const {tables, titles} = data;

    console.log({data})

    return (
        <div className="py-4 px-4 sm:px-0">
            <Typography className={"pb-4"} variant={"h6"} color={"primary"}>Total number of samples
                collected: {samples}</Typography>
            {Object.entries(titles).map(([key, value], index) => {
                return (
                    <div className={"mb-4"} key={index}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<i className="fa-solid fa-arrow-down"/>}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <Typography>
                                    <h3>{value as string}</h3>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Tables table={tables[key]}/>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                );
            })}
        </div>
    );
};
