import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import Autocomplete from "@mui/material/Autocomplete";
import {classNames, htmlValidation} from "../../helpers/utils";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {useStore} from "../../helpers/helpers";
import {useTranslation} from "react-i18next";
import {FormButtons} from "../../components/cm-components";
import {RNCheckBoxTree} from "../../components";
import {safe_get} from "../../report-visuals/report-utils";
import Select from "react-select";
import {useParams} from "react-router-dom";
import _ from "lodash"

const filterTypes = ["Surveys", "User", "Level1", "Status"];

const StatusList = [
    {
        label: "Not Started",
        value: "not-started",
    },
    {
        label: "Completed",
        value: "completed",
    },
    {
        label: "In Progress",
        value: "in-progress"
    }
]

export const TaskFilters = observer((props) => {

    const rootStore = useStore();
    const {taskManagerStore, projectStore} = rootStore;
    const {users} = taskManagerStore;
    const {task_filters} = taskManagerStore
    const [activeFilter, setActiveFilter] = useState(filterTypes[0]);
    const {projectid} = useParams();

    const [surveys, setSurveys] = useState([])
    const [selectedSurveys, setSelectedSurveys] = useState(safe_get(task_filters, "surveys", []));

    const [selectedLevel1, setselectedLevel1] = useState(safe_get(task_filters, "pivLevel1", []));
    const [selectedStatus, setSelectedStatus] = useState(safe_get(task_filters, "status", []));

    useEffect(() => {
        const active_project = JSON.parse(localStorage.getItem("__active_project"))
        setSurveys(safe_get(active_project, "surveys", []))
    }, [projectid])


    const retriveUsers = () => {

        const users_by_id = [];
        const ex = safe_get(task_filters, "userList", []);

        if (ex.length < 0) return [];

        ex.forEach((item, index) => {
            const t = users.find(x => x.id === item);
            console.log(t);
            users_by_id.push(t)
        })

        return users_by_id

    }

    const [selectedUsers, setSelectedUsers] = useState(retriveUsers());

    const hierarchy = projectStore.getProjectProperty("hierarchy", {});

    const toggleOption = (option) => {
        setSelectedStatus((prevSelected) => {
            if (prevSelected.includes(option)) {
                return prevSelected.filter((id) => id !== option);
            } else {
                return [...prevSelected, option];
            }
        });
    };

    const apply_filters = () => {
        taskManagerStore.update_task_filters({
            userList: selectedUsers.map(x => x["id"]),
            pivLevel1: selectedLevel1,
            status: selectedStatus,
            surveys: selectedSurveys
        })
        taskManagerStore.update_task_filter_modal(false)
    }

    const checkForStatus = (val: string) => {
        return selectedStatus?.length > 0 ? selectedStatus.includes(val) : false
    }

    const [t] = useTranslation("task_manager");
    return (
        <>
            <div className="w-full h-full flex">
                <div className=" w-1/4 h-full">
                    {filterTypes.map((tab) => (
                        <p className={classNames(activeFilter == tab ? "text-purple-600 bg-white " : "", "text-lg py-2")}
                           onClick={() => {
                               setActiveFilter(tab)
                           }}>
                            <span className={"px-4"}>{tab}</span>
                        </p>
                    ))}
                </div>
                <div className="p-2 w-3/4 h-full bg-white">
                    {
                        activeFilter === "Surveys" && <div>
                            <p className={"text-lg text-blue-500 py-4"}>{t("selecting_survey")}</p>
                            <Select
                                name={"Select Survey"}
                                defaultValue={selectedSurveys}
                                options={surveys.map(survey => {
                                    return {
                                        label: survey.name,
                                        value: survey.id
                                    }
                                })}
                                onChange={(val, action) => {
                                    const ids = val.map(v => v.value)
                                    setSelectedSurveys(val);
                                }}
                                placeholder={"Survey"}
                                className="w-full h-auto rounded z-999 focus:shadow outline-none basic-multi-select "
                                classNamePrefix="select"
                                isMulti
                                required={true}
                            />
                        </div>
                    }

                    {activeFilter === "User" && <div>
                        <p className={"text-lg text-blue-500 py-4"}>{t("customize_data_display")}</p>
                        <Autocomplete
                            id="select-user"
                            multiple
                            value={selectedUsers}
                            filterSelectedOptions
                            limitTags={2}
                            getOptionLabel={(option) => option["name"]}
                            isOptionEqualToValue={(option, value) => option.name === value.name}
                            options={users.map(user => {
                                return {
                                    name: `${user["name"]}`,
                                    phone: `${user["phonenum"]}`,
                                    id: user["id"],
                                    profileimageuri: user["profileimageuri"]
                                }
                            })}
                            onChange={(event, v) => {
                                setSelectedUsers([...v]);
                            }}
                            renderOption={(props, option, {selected}) => {
                                return (
                                    <Box
                                        key={option["id"]}
                                        component="li"
                                        sx={{"& > img": {mr: 2, flexShrink: 0}}}
                                        {...props}
                                    >
                                        <img
                                            loading="lazy"
                                            width="40"
                                            src={option["profileimageuri"]}
                                        />
                                        <div>
                                            <p>{option["name"]}</p>
                                            <p>{option["phonenum"]}</p>
                                        </div>
                                    </Box>
                                )
                            }}

                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select User"
                                    onChange={(event) => {
                                        htmlValidation(event);
                                        console.log("event", event.target.value);
                                    }}
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: "users"
                                    }}
                                />
                            )}
                        />
                    </div>}
                    {activeFilter === "Level1" && <div>
                        <p className={"text-lg text-blue-500 py-4"}>{t("selecting_level1")}</p>
                        <RNCheckBoxTree
                            data={hierarchy}
                            on_node_selection={(nodes) => {
                                setselectedLevel1(nodes)
                            }}
                            checked_nodes={selectedLevel1}
                        />
                    </div>}

                    {
                        activeFilter === "Status" && <div>
                            <p className={"text-lg text-blue-500 py-4"}>{t("selecting_task_status")}</p>
                            <div>
                                {StatusList.map((status, index) => {
                                    return (
                                        <div key={index} className={"task-filter flex flex-row items-center"}>
                                            <label className="py-4"
                                                   id={`custom-checkbox-${status.value}`}>{status.label}</label>
                                            <input
                                                type="checkbox"
                                                className="ml-5 h-5 w-5 text-green-600"
                                                value={status.value}
                                                onChange={() => {
                                                    toggleOption(status.value)
                                                }}
                                                checked={checkForStatus(status.value)}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    }

                    <FormButtons
                        onSave={apply_filters}
                        onCancel={() => taskManagerStore.update_task_filter_modal(false)}
                        type={"button"}
                    />
                </div>
            </div>
        </>
    )
})