import React, {Fragment, useEffect, useRef, useState} from 'react'
import {observer} from "mobx-react-lite";
import {useStore} from "../../helpers/helpers";
import Modal from 'react-modal';
import _ from "lodash";
import {ChevronDownIcon, EllipsisVerticalIcon} from "@heroicons/react/24/solid";
import CreatableSelect from 'react-select/creatable';
import {Popover, Transition} from '@headlessui/react';
import Nestable from 'react-nestable';
import {classNames, makeid_alpa_numeric, maketoken} from "../../helpers/utils";
import {EditorState} from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {upload_file, uploadFile} from "../../helpers/file-upload";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router";
import {BrihaspathiRichEditor} from "../../features/editor/editor-root";
import {default_node} from "../../flexistore/renderkeys/ac-bank/timeline";
import {safe_get} from "../../report-visuals/report-utils";
import {BreadCrumbs, LoadingState} from "../../components";
import 'handsontable/dist/handsontable.full.min.css';
import Handsontable from 'handsontable/base';
import { registerAllModules } from 'handsontable/registry';
import { HotTable } from '@handsontable/react';
import JSONModal from "../../flexistore/jsonModal";
import {instanceToPlain} from "class-transformer";
registerAllModules();



Modal.setAppElement('#root');
// @ts-ignore
Modal.defaultStyles.content.inset = '150px';

export const ReportEditPage = observer(props => {
    const [t]=useTranslation("ReportEdit");
    const crumbs = [
        {name: t("reports"), href: '/reports', current: false},
        {name: t("edit_report"), href: '#', current: true},
    ];
    const tabs = [
        {name: t("properties"), key: 'meta', current: true},
        {name: t("content"), key: 'content', current: false},
        {name: t("set"), key: 'dataset', current: false},
        {name: t("access"), key: 'acl', current: false},
    ]

    const rootStore = useStore();
    const {reportStore, flexiStore} = rootStore;

    let nestableRender;

    const [report, setReport] = useState({});
    const [allTags, setAllTags] = useState([]);
    const [currentTab, setCurrentTab] = useState(tabs[0]);
    const {reportid} = useParams();
    const [error, setError] = useState(false);

    const [fetched, setFetched] = useState(false);
    const [loading, setLoading] = useState(false);
    const defaultSection = {'id': 'default', type: 'no-render', args: {}, settings: {}};

    const [activeSection, setActiveSection] = useState(defaultSection);
    const [activeDataSet, setActiveDataSet] = useState('empty-dataset');
    const [currentSectionNodeAddress, setCurrentSectionNodeAddress] = useState([]);
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
    const [showHTML, setShowHTML] = useState(false)
    const [json, setJson] = useState('{}');
    const hotTableRef = useRef(null);

    useEffect(() => {
        const {id, type, args, settings} = activeSection;
        if (type !== 'narrative') return;
        const nid = args["nid"];
        let html = stateToHTML(editorState.getCurrentContent());
        // let html = report['narratives'][nid]
        const cloned = _.clone(report)
        cloned['narratives'][nid] = html;
        setReport(cloned);
    }, [editorState]);

    useEffect(() => {
        fetchReport();
        fetchAllTags();
    }, []);

    useEffect(() => {
        if (activeSection.id !== 'default')
            setCurrentSectionNodeAddress(detectCurrentNodeAddress(activeSection.id));
    }, [report]);

    const resetActiveDataset = () => {
        setActiveDataSet('empty-dataset');
    };

    const fetchReport = async () => {
        setLoading(true);
        const response = await reportStore.viewReport(parseInt(reportid));
        if (response.status !== 200) {
            setError(true);
            setLoading(false);
            return;
        }
        setReport(response.data);
        setFetched(true);
        setLoading(false);
    };

    const fetchAllTags = async () => {
        const response = await reportStore.fetchAllTags();
        if (response.status !== 200) {
            setError(true);
            setLoading(false);
            return;
        }
        setAllTags(response.data);
    };

    const detectCurrentNodeAddress = (asid) => {
        //asid: action section id
        let address = [];

        const arrayClone = (src) => {
            return JSON.parse(JSON.stringify(src));
        }

        const scanNode = (node, currentPath) => {
            if (node['id'] === asid) {
                address = arrayClone(currentPath);
                return true
            }
            const hasChildren = Object.keys(node).includes('children');
            if (!hasChildren) return false;
            const children = node['children'];

            const newPath = arrayClone(currentPath);
            newPath.push(-1);

            for (let ind = 0; ind < children.length; ind++) {
                newPath[newPath.length - 1]++;
                const child = children[ind];
                const matchFound = scanNode(child, newPath);
                if (matchFound) return true;
            }
            return false;
        };

        let currentPath = [];
        // @ts-ignore
        currentPath.push(-1);
        for (let ind = 0; ind < report['sections'].length; ind++) {
            // @ts-ignore
            currentPath[currentPath.length - 1] = currentPath[currentPath.length - 1] + 1;
            const node = report['sections'][ind];
            const matchFound = scanNode(node, currentPath);
            if (matchFound) break;
        }

        //console.log(address);
        return address;
    }

    const updateReportWithActiveSection = (cloned) => {
        const reportCloned = _.clone(report);
        let current = reportCloned['sections'];
        for (let i = 0; i < currentSectionNodeAddress.length; i++) {
            const trueIndex = currentSectionNodeAddress[i];
            if (i == 0) {
                current = current[trueIndex];
            } else {
                current = current['children'][trueIndex];
            }
        }

        current.args = cloned.args;
        current.settings = cloned.settings;

        setReport(reportCloned);
        setActiveSection(cloned);
    }

    const deleteActiveSection = () => {
        const reportCloned = _.clone(report);
        let current = reportCloned['sections'];
        let parent = reportCloned['sections'];
        let i = 0;
        let trueIndex = -1;
        for (i = 0; i < currentSectionNodeAddress.length; i++) {
            trueIndex = currentSectionNodeAddress[i];
            if (i == 0) {
                parent = current;
                current = current[trueIndex];
            } else {
                parent = current;
                current = current['children'][trueIndex];
            }
        }

        const hasChildren = Object.keys(current).includes('children');

        if (hasChildren && current["children"].length > 0) {
            if (i === 0 || i === 1) {
                parent.push.apply(current["children"]);
            } else {
                parent["children"].push.apply(current["children"]);
            }
        }

        if (i === 0 || i == 1) {
            parent.splice(trueIndex, 1);
        } else {
            parent["children"].splice(trueIndex, 1);
        }

        setReport(reportCloned);
        setActiveSection(defaultSection);
    }

    const deleteActiveDataset = () => {
        const hasActiveDataSet = (activeDataSet !== 'empty-dataset');
        if (!hasActiveDataSet) return;
        const reportCloned = _.clone(report);

        delete reportCloned['dataset'][activeDataSet];
        setReport(reportCloned);
        resetActiveDataset();
    }

    const CtrlS_detector = async (event) => {
        if (event.ctrlKey === true && event.keyCode === 83) {
            event.preventDefault();
            event.stopPropagation();
            await saveReport();
        }
    };

    const hasShareToken = () => {
        if (!fetched) return false;
        if (report['sharetoken'] && report['sharetoken'].trim() !== '') return true;
        return false;
    };

    const generateShareToken = () => {
        //dont generate again
        if (report['sharetoken'] && report['sharetoken'].trim() !== '') return;

        const token = `rn-${maketoken(3)}-${maketoken(6)}-${maketoken(3)}-${maketoken(9)}`
        setReport({...report, 'sharetoken': token});
    };

    const getReportSharePath = () => {
        const loc = window.location.href;
        const parts = loc.split("/");
        const protocol = parts[0];
        const domain = parts[2];
        return `${protocol}//${domain}/report/share`;
    };

    const [isValidJson, setIsValidJson] = useState(false);

    const saveReport = async () => {
        if (loading || !fetched) return;
        setLoading(true);

        //Update
        saveEditedJSON();
        saveTableData();
        if(!isValidJson) {
            setLoading(false);
            return ;
        }
        const response = await reportStore.updateReport(report);
        if (response.status !== 200) {
            setError(true);
            setLoading(false);
            return;
        }
        setError(false);
        setReport(response.data);
        setFetched(true);
        setLoading(false);
    };

    const saveEditedJSON = () => {
        const clone = _.clone(activeSection)
        try{
            clone["args"] = JSON.parse(json)
            updateReportWithActiveSection(clone);
            setIsValidJson(true)
        }
        catch(e) {
            toast.error("invalid JSON")
            setIsValidJson(false)
        }
    }

    const saveTableData = () => {
        const hot = hotTableRef.current.hotInstance;
        const table_data = hot.getData();
        console.log(table_data);

        const clone = _.clone(activeSection)
        try{
            clone["args"]["data"] = table_data
            updateReportWithActiveSection(clone);
            setIsValidJson(true)
        }
        catch(e) {
            toast.error("Something went wrong")
            setIsValidJson(false)
        }
    }

    const addDataSet_S3 = () => {
        const new_ds_id = makeid_alpa_numeric(5);
        const updated_dataset = _.clone(report['dataset']);

        const new_ds = {
            id: new_ds_id,
            type: 's3',
            args: {
                filetype: 'json'
            },
            settings: {
                embed: true,
                uploaded: false,
                name: 'DS ' + new_ds_id,
                key: new_ds_id,
            }
        };

        updated_dataset[new_ds_id] = new_ds;

        setReport({...report, "dataset": updated_dataset});
    }

    const addDataSet_ES = () => {
        const new_ds_id = makeid_alpa_numeric(5);
        const updated_dataset = _.clone(report['dataset']);

        updated_dataset[new_ds_id] = {
            id: new_ds_id,
            type: 'es_query',
            args: {
                index: '',
                query: {}
            },
            settings: {
                embed: true,
                uploaded: false,
                name: 'DS ' + new_ds_id,
                key: new_ds_id,
            }
        };

        setReport({...report, "dataset": updated_dataset});
    }

    const addNarrative = () => {
        const new_narrative_id = makeid_alpa_numeric(5);
        const new_narratives = _.clone(report['narratives']);
        new_narratives[new_narrative_id] = '';

        const section = {
            id: makeid_alpa_numeric(5),
            type: 'narrative',
            args: {
                nid: new_narrative_id,
            },
            settings: {
                flow: 'horizontal',
                title: 'New content section'
            }
        };
        const new_sections = _.clone(report['sections']);
        new_sections.push(section);
        setReport({...report, "sections": new_sections, "narratives": new_narratives});
    };

    const addImage = () => {
        const section = {
            id: makeid_alpa_numeric(5),
            type: 'image',
            args: {
                src: 's3',
                path: '',
                uploaded: false,
                s3path: ''
            },
            settings: {
                flow: 'horizontal',
                title: 'New image',
                width: '',
                height: '',
                showTitle: true
            }
        };
        const new_sections = _.clone(report['sections']);
        new_sections.push(section);
        setReport({...report, "sections": new_sections});
    };

    const addWidget = () => {
        const section = {
            id: makeid_alpa_numeric(5),
            type: 'widget',
            args: {},
            settings: {
                flow: 'horizontal',
                title: 'New visual'
            }
        };
        const new_sections = _.clone(report['sections']);
        new_sections.push(section);
        setReport({...report, "sections": new_sections});
    };

    const addSection = () => {
        const section = {
            id: makeid_alpa_numeric(5),
            type: 'section',
            args: {
                displayTitle: ''
            },
            settings: {
                flow: 'horizontal',
                title: 'New Section',
                syncNameTitle: true,
                rootClasses: ''
            },
            children: []
        };
        const new_sections = _.clone(report['sections']);
        new_sections.push(section);
        setReport({...report, "sections": new_sections});
        console.log(report);
    };

    const addDataTable = () => {
        const section = {
            id: makeid_alpa_numeric(5),
            type: 'data-table',
            args: {
                data: [[1, 2, 3, 4, 5]]
            },
            settings: {
                title: t("new_data_table"),
                rows: 5,
                columns: 5
            }
        };
        const new_sections = _.clone(report['sections']);
        new_sections.push(section);
        setReport({...report, "sections": new_sections});
        console.log("Add data table")
    };

    const Actions = (innerprops) => {
        return (
            <div className="ml-auto">
                <button className='px-4 py-2 rounded-md bg-indigo-700 text-white' onClick={saveReport}>Save</button>
            </div>
        )
    };

    const Error = (innerprops) => {
        return (
            <div className="text-red-600 italic">
                {t("error_occured")}
            </div>
        )
    };

    const TabBar = (innerprops) => {
        return (
            <div>
                <div className="sm:hidden">
                    <label htmlFor="tabs" className="sr-only">
                        {t("select_a_tab")}
                    </label>
                    <select
                        id="tabs"
                        name="tabs"
                        className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        value={currentTab.key}
                        onChange={(evt) => {
                            const changedTab = _.filter(tabs, {key: evt.target.value})[0];
                            setCurrentTab(changedTab);
                        }}
                    >
                        {tabs.map((tab) => (
                            <option className='cursor-pointer'
                                    key={tab.key}
                                    value={tab.key}
                                    onClick={(evt) => {
                                        setCurrentTab(tab);
                                    }}
                            >{tab.name}</option>
                        ))}
                    </select>
                </div>
                <div className="hidden sm:block">
                    <div className="border-b-2 border-gray-300">
                        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                            {tabs.map((tab) => (
                                <a
                                    key={tab.key}
                                    className={classNames(
                                        (tab.key === currentTab.key)
                                            ? 'border-indigo-500 text-indigo-600'
                                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                        'cursor-pointer whitespace-nowrap py-2 px-1 border-b-2 text-base'
                                    )}
                                    onClick={(evt) => {
                                        setCurrentTab(tab)
                                    }}
                                    aria-current={tab.key === currentTab.key ? 'page' : undefined}
                                >
                                    {tab.name}
                                </a>
                            ))}
                        </nav>
                    </div>
                </div>
            </div>
        )
    };

    const PropertiesTab = () => {
        return (
            <>
                <div className="mt-5">
                    <label className="block text-sm font-medium text-gray-700 font-bold">
                        {t("report_name")}
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                        <input
                            type="text"
                            value={report['name']}
                            onChange={(event) => {
                                setReport({...report, 'name': event.target.value});
                            }}
                            className="h-8 px-2 rounded-md shadow-md py-2 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                            placeholder="Report name..."
                        />
                    </div>
                </div>

                <div className="mt-5">
                    <label className="block text-sm font-medium text-gray-700 font-bold">
                        {t("report_description")}
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                        <textarea
                            rows={5}
                            value={report['description']}
                            onChange={(event) => {
                                setReport({...report, 'description': event.target.value});
                            }}
                            className="px-2 rounded-md shadow-md py-2 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                            placeholder="Report name..."
                        />
                    </div>
                </div>

                <div className="mt-5">
                    <label className="block text-sm font-medium text-gray-700 font-bold">
                        {t("tags")}
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                        <CreatableSelect

                            isClearable
                            isMulti
                            onChange={(val, action) => {
                                const updatedTags = val.map(x => {
                                    return x['value'];
                                });
                                setReport({...report, 'tags': updatedTags});
                            }}
                            value={report['tags'].map(x => {
                                return {"value": x, "label": x};
                            })}
                            options={allTags.map(x => {
                                return {"value": x, "label": x};
                            })}
                            placeholder="Select tags..."
                            className="w-full shadow-md h-10 rounded-md z-0 focus:shadow outline-none basic-multi-select  border-gray-300"
                            classNamePrefix="select"
                        />
                    </div>
                </div>

            </>
        );
    };

    const ContentLeftSection = () => {
        const sectionTypes = [
            {name: t("section"), description: t("add_sections"), key: "section"},
            {name: t("narrative"), description: t("add_descriptive"), key: "narrative"},
            {name: t("image"), description: t("upload_an_image"), key: "image"},
            {name: t("visualization"), description: t("add_data"), key: "widget"},
            {name: t("data_table"), description: t("edit_image_tables"), key: "data-table"}
        ];

        const renderSectionNavItem = (props) => {

            const section = props['item'];
            const {id, type, settings} = section;
            const {title} = settings;
            const left = 20 * props['depth'];

            const isActive = (id === activeSection.id);
            let sectionClass = isActive ? 'cursor-pointer font-medium text-indigo-800 font-bold' : 'cursor-pointer font-medium text-gray-800';

            return (
                <>
                    <div className='py-1 px-1 flex flex-row flex-nowrap items-center cursor-pointer' key={id}
                         style={{marginLeft: left}}
                         onClick={(evt) => {
                             setCurrentSectionNodeAddress(detectCurrentNodeAddress(section.id));
                             setActiveSection(section);
                             setJson(JSON.stringify(section["args"], null, 4))
                             if (section['type'] === 'narrative') {
                                 // initEditorState(section);
                             }
                         }}>
                        <div className='mr-2 text-gray-500' style={{fontSize: '16pt'}}>
                            {type === 'section' ? <i className="fa-solid fa-puzzle-piece"/> : null}
                            {type === 'narrative' ? <i className="fa-solid fa-align-left"/> : null}
                            {type === 'image' ? <i className="fa-solid fa-image"/> : null}
                            {type === 'widget' ? <i className="fa-solid fa-chart-bar"/> : null}
                            {type === 'data_table' ? <i className="fa-solid fa-table"/> : null}
                        </div>
                        <div className={sectionClass}
                             style={{
                                 "lineHeight": "24px",
                                 "maxHeight": "24px",
                                 "display": "block",
                                 "textOverflow": "ellipsis",
                                 "overflowWrap": "break-word",
                                 "overflow": "hidden"
                             }}>{title}</div>
                        <div className='ml-auto cursor-move'><EllipsisVerticalIcon className="h-5 w-5 text-gray-600"/></div>
                    </div>
                </>
            )
        };

        return (
            <>
                <div className='flex flex-row'>
                    <div className='font-bold uppercase '>{t("sections")}</div>
                    <div className='ml-auto'>
                        <Popover className="relative">
                            {({open}) => (
                                <>
                                    <Popover.Button
                                        className={classNames(
                                            open ? 'text-gray-900' : 'text-gray-500',
                                            'group rounded-md inline-flex items-center text-base font-medium hover:text-gray-900'
                                        )}
                                    >
                                        <span>{t("add")}</span>
                                        <ChevronDownIcon
                                            className={classNames(open ? 'text-gray-600' : 'text-gray-400', 'ml-2 h-5 w-5 group-hover:text-gray-500')}
                                            aria-hidden="true"
                                        />
                                    </Popover.Button>

                                    <Transition

                                        show={open}
                                        as={Fragment}
                                        enter="transition ease-out duration-200"
                                        enterFrom="opacity-0 translate-y-1"
                                        enterTo="opacity-100 translate-y-0"
                                        leave="transition ease-in duration-150"
                                        leaveFrom="opacity-100 translate-y-0"
                                        leaveTo="opacity-0 translate-y-1"
                                    >
                                        <Popover.Panel
                                            static
                                            className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-xs sm:px-0"
                                            style={{zIndex: 999}}
                                        >
                                            <div
                                                className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                                <div className="relative grid gap-6 bg-white px-3 py-2 sm:gap-8 sm:p-3">
                                                    {sectionTypes.map((item) => (
                                                        <div
                                                            key={item.name}
                                                            onMouseDown={(evt) => {
                                                                console.log('Adding section ', item.key);
                                                                if (item.key === 'narrative') addNarrative();
                                                                if (item.key === 'image') addImage();
                                                                if (item.key === 'widget') addWidget();
                                                                if (item.key === 'section') addSection();
                                                                if (item.key === 'data-table') addDataTable();
                                                            }}
                                                            className="-m-3 p-3 block rounded-sm cursor-pointer hover:bg-blue-50 transition ease-in-out duration-150"
                                                        >
                                                            <p className="text-base font-bold text-gray-900">{item.name}</p>
                                                            <p className="text-sm text-gray-500">{item.description}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </Popover.Panel>
                                    </Transition>
                                </>
                            )}
                        </Popover>
                    </div>
                </div>
                <Nestable
                    items={report['sections']}
                    renderItem={renderSectionNavItem}
                    onChange={(keys) => {
                        setReport({...report, "sections": keys.items});
                    }}
                    confirmChange={(keys) => {
                        if (!keys.destinationParent) return true;
                        return keys.destinationParent.type === 'section';
                    }}
                    ref={el => nestableRender = el}
                />
            </>
        );
    }

    const DatasetLeftSection = () => {
        const datasetTypes = [
            {name: 'S3 Resource', description: 'Comprehensive data file uploaded to S3', key: 's3'},
            {name: 'Elastic Search Query', description: 'ES Search query', key: 'es_query'}
        ];

        return (
            <>
                <div className='flex flex-row'>
                    <div className='font-bold uppercase '>{t("datasets")}</div>
                    <div className='ml-auto'>
                        <Popover className="relative">
                            {({open}) => (
                                <>
                                    <Popover.Button
                                        className={classNames(
                                            open ? 'text-gray-900' : 'text-gray-500',
                                            'group rounded-md inline-flex items-center text-base font-medium hover:text-gray-900'
                                        )}
                                    >
                                        <span>{t("add")}</span>
                                        <ChevronDownIcon
                                            className={classNames(open ? 'text-gray-600' : 'text-gray-400', 'ml-2 h-5 w-5 group-hover:text-gray-500')}
                                            aria-hidden="true"
                                        />
                                    </Popover.Button>

                                    <Transition
                                        show={open}
                                        as={Fragment}
                                        enter="transition ease-out duration-200"
                                        enterFrom="opacity-0 translate-y-1"
                                        enterTo="opacity-100 translate-y-0"
                                        leave="transition ease-in duration-150"
                                        leaveFrom="opacity-100 translate-y-0"
                                        leaveTo="opacity-0 translate-y-1"
                                    >
                                        <Popover.Panel
                                            static
                                            className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-xs sm:px-0"
                                        >
                                            <div
                                                className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                                <div className="relative grid gap-6 bg-white px-3 py-2 sm:gap-8 sm:p-3">
                                                    {datasetTypes.map((item) => (
                                                        <div
                                                            key={item.name}
                                                            onMouseDown={(evt) => {
                                                                console.log('Adding section ', item.key);
                                                                if (item.key === 's3') addDataSet_S3();
                                                                if (item.key === 'es_query') addDataSet_ES();
                                                            }}
                                                            className="-m-3 p-3 block rounded-sm cursor-pointer hover:bg-blue-50 transition ease-in-out duration-150"
                                                        >
                                                            <p className="text-base font-bold text-gray-900">{item.name}</p>
                                                            <p className="text-sm text-gray-500">{item.description}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </Popover.Panel>
                                    </Transition>
                                </>
                            )}
                        </Popover>
                    </div>
                </div>
                <div>
                    {
                        Object.values(report['dataset']).map((ds) => {
                            // @ts-ignore
                            const {id, type, args, settings} = ds;
                            const {name, key} = settings;

                            const isActive = (id === activeDataSet);
                            let sectionClass = isActive ? 'cursor-pointer font-medium text-indigo-800 font-bold' : 'cursor-pointer font-medium text-gray-800';

                            return (
                                <>
                                    <div className='py-1 px-1 flex flex-row flex-nowrap items-center' key={id}>
                                        <div className='mr-2 text-gray-500' style={{fontSize: '16pt'}}>
                                            {type === 's3' ? (<i className="fab fa-js"></i>) : null}
                                            {type === 'es_query' ? (<i className="fab fa-chromecast"></i>) : null}
                                        </div>
                                        <div className={sectionClass}
                                             style={{
                                                 "lineHeight": "24px",
                                                 "maxHeight": "24px",
                                                 "display": "block",
                                                 "textOverflow": "ellipsis",
                                                 "overflowWrap": "break-word",
                                                 "overflow": "hidden"
                                             }}
                                             onClick={(evt) => {
                                                 setActiveDataSet(id);
                                             }}>{name}
                                        </div>
                                    </div>
                                </>
                            )
                        })
                    }

                </div>
            </>
        );
    }

    const render_content_header = () => {
        const {id, type, args, settings} = activeSection;
        // @ts-ignore
        const {title} = settings;
        return (
            <>
                <div className='flex flex-row no-wrap items-center'>
                    <div className='border-b-2 w-full border-gray-200 font-bold text-lg h-12 px-4 py-2'>{title}</div>
                    <div className='ml-auto'>
                        <div className='text-red-500 font-2xl cursor-pointer' title={t("delete_section")}
                             onClick={() => {
                                 deleteActiveSection();
                             }}
                        >
                            <i className="fa-solid fa-trash"/>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    const render_content_section = () => {
        const {id, type, args, settings} = activeSection;

        return (
            <>
                <div className='h-full'>
                    {render_content_header()}
                    <div className='px-4 py-2 '>
                        <div className="mt-1">
                            <label className="block text-sm font-medium text-gray-700 font-bold">
                                {t("section_name")}
                            </label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                                <input
                                    type="text"
                                    value={activeSection['settings']['title']}
                                    onChange={(event) => {
                                        const cloned = _.clone(activeSection);
                                        cloned['settings']['title'] = event.target.value;
                                        if (cloned['settings']['syncNameTitle']) {
                                            cloned['args']['displayTitle'] = event.target.value;
                                        }
                                        updateReportWithActiveSection(cloned);
                                    }}
                                    className="h-8 px-2 rounded-md shadow-md py-2 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                    placeholder="Section name..."
                                />
                            </div>
                        </div>


                        <div className="mt-5">
                            <label className="block text-sm font-medium text-gray-700 font-bold">
                                {t("display_title")}
                            </label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                                <input
                                    type="text"
                                    disabled={activeSection['settings']['syncNameTitle']}
                                    value={activeSection['args']['displayTitle']}
                                    onChange={(event) => {
                                        if (!activeSection['settings']['syncNameTitle']) {
                                            const cloned = _.clone(activeSection);
                                            cloned['args']['displayTitle'] = event.target.value;
                                            updateReportWithActiveSection(cloned);
                                        }
                                    }}
                                    style={{backgroundColor: (activeSection['settings']['syncNameTitle'] ? '#F4F6F6' : '#FFF')}}
                                    className="h-8 px-2 rounded-md shadow-md py-2 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                    placeholder="Section name..."
                                />
                            </div>
                            <div className="mt-1 flex  flex-row items-center">
                                <input type="checkbox"
                                       className='w-4 h-4'
                                       checked={activeSection['settings']['syncNameTitle']}
                                       onChange={() => {
                                           const cloned = _.clone(activeSection);
                                           cloned['settings']['syncNameTitle'] = !cloned['settings']['syncNameTitle'];
                                           if (cloned['settings']['syncNameTitle']) {
                                               cloned['args']['displayTitle'] = cloned['settings']['title'];
                                           }
                                           updateReportWithActiveSection(cloned);
                                       }}/>
                                &nbsp;&nbsp;{t("same_as_section_name")}
                            </div>

                            <label className="mt-2 block text-sm font-medium text-gray-700 font-bold">
                                {t("styles")}
                            </label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                                <input
                                    type="text"
                                    value={activeSection['settings']['rootClasses'] ? activeSection['settings']['rootClasses'] : ''}
                                    onChange={(event) => {
                                        const cloned = _.clone(activeSection);
                                        cloned['settings']['rootClasses'] = event.target.value;
                                        updateReportWithActiveSection(cloned);
                                    }}
                                    className="white-bg h-8 px-2 rounded-md shadow-md py-2 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                    placeholder="Section Styles..."
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </>
        );
    }

    const render_content_narrative = () => {

        const {id, type, args, settings} = activeSection;
        if (type !== 'narrative') return;
        const nid = args["nid"];

        const narratives = safe_get(report, "narratives", {})
        const active_narrative = safe_get(narratives, `${nid}`, {})

        return (
            <>
                <div className='h-full'>
                    {render_content_header()}
                    <div className='px-4 py-2 '>
                        <div className="mt-1">
                            <label className="block text-sm font-medium text-gray-700 font-bold">
                                {t("section_name")}
                            </label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                                <input
                                    type="text"
                                    value={activeSection['settings']['title']}
                                    onChange={(event) => {
                                        const cloned = _.clone(activeSection);
                                        cloned['settings']['title'] = event.target.value;
                                        if (cloned['settings']['syncNameTitle']) {
                                            cloned['args']['displayTitle'] = event.target.value;
                                        }
                                        updateReportWithActiveSection(cloned);
                                    }}
                                    className="h-8 px-2 rounded-md shadow-md py-2 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                    placeholder="Section name..."
                                />
                            </div>
                        </div>
                        <div className="mt-5">
                            <label className="mb-1 block text-sm font-medium text-gray-700 font-bold">
                                {t("section_content")}
                            </label>
                        </div>
                        <div className="py-4 flex flex-col  ">
                            <BrihaspathiRichEditor
                                editorData={_.isEmpty(active_narrative) ? default_node : active_narrative}
                                editorOnChange={async(data) => {
                                    const cloned = _.clone(report)
                                    cloned['narratives'][nid] = data
                                    setReport(cloned);
                                    const response = await reportStore.updateReport(cloned);
                                    if (response.status !== 200) {
                                        setError(true);
                                        setLoading(false);
                                        return;
                                    } else {
                                        toast.success("Report Saved Successfully.")
                                    }
                                }}
                                editable={true}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }

    const [tableConfigModal, setTableConfigModal] = useState(false)
    const RenderTable = (props) => {
        return(
            <div className='px-4 py-2 '>
                <div className="mt-1">
                   <div className={'flex flex-row items-center justify-between my-3'}>
                       <label className="block text-sm font-medium text-gray-700 font-bold">
                           {t("section_name")}
                       </label>
                       <button onClick={() => setTableConfigModal(true)} className="btn btn-sm">Table Configuration</button>
                   </div>
                    <div className="mt-1 flex rounded-md shadow-sm">
                        <input
                            type="text"
                            value={activeSection['settings']['title']}
                            onChange={(event) => {
                                const cloned = _.clone(activeSection);
                                cloned['settings']['title'] = event.target.value;
                                updateReportWithActiveSection(cloned);
                            }}
                            className="h-8 px-2 rounded-md shadow-md py-2 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                            placeholder="Section name..."
                        />
                    </div>
                </div>
               <div className={"my-4"}>
                   <HotTable
                       ref={hotTableRef}
                       data={safe_get(activeSection, "args.data", [])}
                       rowHeaders={true}
                       height="auto"
                       autoWrapRow={true}
                       autoWrapCol={true}
                       contextMenu
                       manualColumnResize={true}
                       manualRowResize ={true}
                       nestedHeaders={safe_get(activeSection, "settings.config.headers", [])}
                       columns={safe_get(activeSection, "settings.config.columns", [])}
                       mergeCells
                       licenseKey="non-commercial-and-evaluation" // for non-commercial use only
                   />
               </div>
            </div>
        )
    }

    const render_content_image = () => {
        const {id, type, args, settings} = activeSection;

        return (
            <>
                <div className='h-full'>
                    {render_content_header()}
                    <div className='px-4 py-2 '>
                        <div className="mt-1">
                            <label className="block text-sm font-medium text-gray-700 font-bold">
                                {t("section_name")}
                            </label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                                <input
                                    type="text"
                                    value={activeSection['settings']['title']}
                                    onChange={(event) => {
                                        const cloned = _.clone(activeSection);
                                        cloned['settings']['title'] = event.target.value;
                                        if (cloned['settings']['syncNameTitle']) {
                                            cloned['args']['displayTitle'] = event.target.value;
                                        }
                                        updateReportWithActiveSection(cloned);
                                    }}
                                    className="h-8 px-2 rounded-md shadow-md py-2 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                    placeholder="Section name..."
                                />
                            </div>
                            <div className="mt-1 flex  flex-row items-center text-sm">
                                <input type="checkbox"
                                       className='w-4 h-4'
                                       checked={activeSection['settings']['showTitle']}
                                       onChange={() => {
                                           const cloned = _.clone(activeSection);
                                           cloned['settings']['showTitle'] = !cloned['settings']['showTitle'];
                                           updateReportWithActiveSection(cloned);
                                       }}/>
                                &nbsp;&nbsp;{t("show_title_below_the_image")}
                            </div>
                        </div>
                        <div className='flex flex-column'>
                            <div className='mt-5 mr-4'>
                                <label className="block text-sm font-medium text-gray-700 font-bold">
                                    {t("width")}
                                </label>

                                <input type="number"
                                       className="mt-1 h-8 px-2 rounded-md shadow-md py-2 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                       value={activeSection["settings"]["width"]}
                                       onChange={(event) => {
                                           const cloned = _.clone(activeSection);
                                           cloned["settings"]["width"] = event.target.value;
                                           updateReportWithActiveSection(cloned);
                                       }}/>
                            </div>
                            <div className='mt-5'>
                                <label className="block text-sm font-medium text-gray-700 font-bold">
                                    {t("height")}
                                </label>

                                <input type="number"
                                       className="mt-1 h-8 px-2 rounded-md shadow-md py-2 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                       value={activeSection["settings"]["height"]}
                                       onChange={(event) => {
                                           const cloned = _.clone(activeSection);
                                           cloned["settings"]["height"] = event.target.value;
                                           updateReportWithActiveSection(cloned);
                                       }}/>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <label className="block text-sm font-medium text-gray-700 font-bold">
                                {t("s3_uri")}
                            </label>

                            <input type="text"
                                   className="mt-1 h-8 px-2 rounded-md shadow-md py-2 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                   value={activeSection["args"]["s3path"]}
                                   onChange={(event) => {
                                       const cloned = _.clone(activeSection);
                                       cloned["args"]["s3path"] = event.target.value;
                                       updateReportWithActiveSection(cloned);
                                   }}/>
                        </div>

                        <div className="mt-5">
                            <label className="block text-sm font-medium text-gray-700 font-bold">
                                {t("upload_file")}
                            </label>

                            <input type="file"
                                   placeholder='Choose file to upload'
                                   onChange={(event) => {

                                       try {
                                           const path = 'report/rep_' + reportid
                                           const file = event.target.files[0];

                                           console.log("file", file)
                                           const s3path = upload_file(path, file, "urn-report")

                                           const cloned = _.clone(activeSection);
                                           cloned["args"]["s3path"] = s3path;
                                           cloned["settings"]["uploaded"] = true;
                                           updateReportWithActiveSection(cloned);
                                       } catch (e) {
                                           console.log(e)
                                       }
                                   }}/>
                        </div>
                    </div>
                </div>

            </>
        );
    }

    const render_content_widget = () => {
        const {id, type, args, settings} = activeSection;

        return (
            <>
                <div className='h-full'>
                    {render_content_header()}
                    <div className='px-4 py-2 '>
                        <div className="mt-1">
                            <label className="block text-sm font-medium text-gray-700 font-bold">
                                {t("section_name")}
                            </label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                                <input
                                    type="text"
                                    value={activeSection['settings']['title']}
                                    onChange={(event) => {
                                        const cloned = _.clone(activeSection);
                                        cloned['settings']['title'] = event.target.value;
                                        if (cloned['settings']['syncNameTitle']) {
                                            cloned['args']['displayTitle'] = event.target.value;
                                        }
                                        updateReportWithActiveSection(cloned);
                                    }}
                                    className="h-8 px-2 rounded-md shadow-md py-2 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                    placeholder="Section name..."
                                />
                            </div>
                        </div>


                        <div className="mt-5 w-full bg-gray-50 px-4 py-2">
                            <label className="mb-1 block text-sm font-medium text-gray-700 font-bold">
                                {t("visualization_settings")}
                            </label>
                            <textarea className='w-full bg-gray-50 px-4 py-2'
                                      value={json}
                                      onPaste={(evt) => {
                                          const content = evt.clipboardData.getData('text/plain');
                                          try {
                                              const updated = JSON.parse(content);
                                              const cloned = _.clone(activeSection)
                                              cloned["args"] = updated
                                              updateReportWithActiveSection(cloned);
                                          } catch (e) {
                                              console.log(e);
                                          }
                                      }}
                                      onChange={(evt) => {
                                          try {
                                              const val = evt.target.value
                                              setJson(val)
                                          } catch (e) {
                                              console.log(e)
                                          }
                                      }}
                                      style={{height: '550px'}}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }

    const ContentTab = () => {
        const hasActiveSection = (activeSection.id !== defaultSection.id);

        const renderActiveSection = () => {
            const {id, type} = activeSection;

            return (
                <>
                    {type === 'section' ? render_content_section() : null}
                    {type === 'narrative' ? render_content_narrative() : null}
                    {type === 'image' ? render_content_image() : null}
                    {type === 'widget' ? render_content_widget() : null}
                    {type === 'data-table' ? <RenderTable key={id}/> : null}
                </>
            );
        };

        const placeholderSection = () => {
            return (
                <>
                    <div className='text-lg italic text-gray-500 px-8 py-8'>
                        {t("authoring_report")}
                    </div>
                </>
            );
        };

        return (
            <>
                <div className="flex flex-row flex-nowrap w-full" key="content-left">
                    <div className="w-full md:w-1/3 lg:w-1/4 p-2 border-r-2	border-gray-300"
                         style={{minHeight: 'calc(100vh - 160px)'}}>
                        {ContentLeftSection()}
                    </div>
                    <div className="w-full md:w-2/3 lg:w-3/4" key="content-right">
                        {hasActiveSection ? renderActiveSection() : placeholderSection()}
                    </div>
                </div>
            </>
        )
    };

    const DatasetTab = () => {
        const hasActiveDataSet = (activeDataSet !== 'empty-dataset');

        const renderActiveDataset = () => {
            if (!hasActiveDataSet) return null;

            const ds = report['dataset'][activeDataSet];

            // @ts-ignore
            const {id, type, args, settings} = ds;
            const {name, key} = settings;

            return (
                <>
                    <div className='flex flex-row no-wrap items-center'>
                        <div className='border-b-2 w-full border-gray-200 font-bold text-lg h-12 px-4 py-2'>{name}</div>
                        <div className='ml-auto'>
                            <div className='text-red-500 font-2xl cursor-pointer' title={t("delete_section")}
                                 onClick={() => {
                                     deleteActiveDataset();
                                 }}
                            >
                                <i className="fa-solid fa-trash"/>
                            </div>
                        </div>
                    </div>
                    <div className='px-4 py-2 '>
                        <div className="mt-1">
                            <label className="block text-sm font-medium text-gray-700 font-bold">
                                {t("dataset_name")}
                            </label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                                <input
                                    type="text"
                                    value={report['dataset'][activeDataSet]["settings"]["name"]}
                                    onChange={(event) => {
                                        const cloned = _.clone(report['dataset']);
                                        cloned[activeDataSet]["settings"]["name"] = event.target.value;
                                        setReport({...report, "dataset": cloned});
                                    }}
                                    className="h-8 px-2 rounded-md shadow-md py-2 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                    placeholder="Section name..."
                                />
                            </div>
                        </div>


                        <div className="mt-5">
                            <label className="block text-sm font-medium text-gray-700 font-bold">
                                {t("dataset_key")}
                            </label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                                <input
                                    type="text"
                                    value={report['dataset'][activeDataSet]["settings"]["key"]}
                                    onChange={(event) => {
                                        const cloned = _.clone(report['dataset']);
                                        cloned[activeDataSet]["settings"]["key"] = event.target.value;
                                        setReport({...report, "dataset": cloned});
                                    }}
                                    className="h-8 px-2 rounded-md shadow-md py-2 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                    placeholder="Section name..."
                                />
                            </div>
                        </div>

                        {type === 's3' ? renderActiveDataset_S3() : null}
                        {type === 'es_query' ? renderActiveDataset_ES() : null}


                    </div>
                </>
            );
        };


        const renderActiveDataset_S3 = () => {
            if (!hasActiveDataSet) return null;

            const ds = report['dataset'][activeDataSet];

            // @ts-ignore
            const {id, type, args, settings} = ds;
            const {name, key, uploaded, embed} = settings;

            return (
                <>
                    <div className="mt-5 flex flex-row items-center">
                        <label className="text-sm font-medium text-gray-700 font-bold">
                            {t("embed_document")}
                        </label>

                        <input type="checkbox"
                               className='w-4 h-4 ml-4'
                               checked={report['dataset'][activeDataSet]["settings"]["embed"]}
                               onChange={(event) => {
                                   const cloned = _.clone(report['dataset']);
                                   cloned[activeDataSet]["settings"]["embed"] = !cloned[activeDataSet]["settings"]["embed"];
                                   setReport({...report, "dataset": cloned});
                               }}/>
                    </div>

                    <div className='mt-5'>
                        <label className="block text-sm font-medium text-gray-700 font-bold">
                            {t("s3_uri")}
                        </label>

                        <input type="text"
                               className="mt-1 h-8 px-2 rounded-md shadow-md py-2 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                               value={report['dataset'][activeDataSet]["args"]["s3path"]}
                               onChange={(event) => {
                                   const cloned = _.clone(report['dataset']);
                                   cloned[activeDataSet]["args"]["s3path"] = event.target.value;
                                   setReport({...report, "dataset": cloned});
                               }}/>
                    </div>


                    <div className="mt-5">
                        <label className="block text-sm font-medium text-gray-700 font-bold">
                            {t("upload_file")}
                        </label>

                        <input type="file"
                               placeholder='Choose file to upload'
                               onChange={(event) => {

                                   try {
                                       const path = 'rnreports/rep_' + props['reportid']
                                       const file = event.target.files[0];
                                       const s3path = uploadFile(path, file)

                                       const cloned = _.clone(report['dataset']);
                                       cloned[activeDataSet]["args"]["s3path"] = s3path;
                                       setReport({...report, "dataset": cloned});
                                   } catch (e) {
                                       console.log(e)
                                   }

                                   // const getFileExtension = (filename) => {
                                   //     const extension = filename.split('.').pop();
                                   //     return extension;
                                   // };
                                   //
                                   // try {
                                   //     // @ts-ignore
                                   //     const file = event.target.files[0];
                                   //     const ReactS3Client = new S3(s3config);
                                   //     const extension = getFileExtension(file.name);
                                   //     const newFileName = id + '.' + extension;
                                   //     ReactS3Client
                                   //         .uploadFile(file, newFileName)
                                   //         .then(data => {
                                   //             console.log('Uploaded', data);
                                   //         })
                                   //         .catch(err => {
                                   //             console.log('Failed', err);
                                   //         });
                                   //
                                   //     const s3path = s3config.dirName + '/' + newFileName;
                                   //
                                   //     const cloned = _.clone(report['dataset']);
                                   //     cloned[activeDataSet]["args"]["s3path"] = s3path;
                                   //     setReport({...report, "dataset": cloned});
                                   //
                                   // } catch (e) {
                                   //
                                   // }
                               }}/>
                    </div>
                </>
            );
        }

        const renderActiveDataset_ES = () => {
            if (!hasActiveDataSet) return null;

            const ds = report['dataset'][activeDataSet];

            // @ts-ignore
            const {id, type, args, settings} = ds;
            const {name, key} = settings;

            return (
                <>
                    <div className="mt-5">
                        <label className="block text-sm font-medium text-gray-700 font-bold">
                            {t("index")}
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                            <input
                                type="text"
                                value={report['dataset'][activeDataSet]["args"]["index"]}
                                onChange={(event) => {
                                    const cloned = _.clone(report['dataset']);
                                    cloned[activeDataSet]["args"]["index"] = event.target.value;
                                    setReport({...report, "dataset": cloned});
                                }}
                                className="h-8 px-2 rounded-md shadow-md py-2 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                placeholder="Index..."
                            />
                        </div>
                    </div>

                    <div className="mt-5">
                        <label className="block text-sm font-medium text-gray-700 font-bold">
                            {t("elastic_search_query")}
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                            <textarea
                                value={JSON.stringify(report['dataset'][activeDataSet]["args"]["query"],null, 4)}
                                style={{height: '550px'}}
                                onPaste={(event) => {
                                    const content = event.clipboardData.getData('text/plain');
                                    try {
                                        const updated = JSON.parse(content);
                                        const cloned = _.clone(report['dataset'])
                                        cloned[activeDataSet]["args"]["query"] = updated
                                        setReport({...report, "dataset": cloned});
                                    } catch (e) {
                                        //partial update in progress, dont do anything yet
                                        console.log(e);
                                    }
                                }}
                                className="px-2 rounded-md shadow-md py-2 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                placeholder="Query..."
                            />
                        </div>
                    </div>
                </>
            );
        }

        const placeholderDataset = () => {
            return (
                <>
                    <div className='text-lg italic text-gray-500 px-8 py-8'>
                        {t("select_dataset")}
                    </div>
                </>
            );
        };

        return (
            <>
                <div className="flex flex-row flex-nowrap w-full" key="content-left">
                    <div className="w-full md:w-1/3 lg:w-1/4 p-2 border-r-2	border-gray-300"
                         style={{minHeight: 'calc(100vh - 160px)'}}>
                        {DatasetLeftSection()}
                    </div>
                    <div className="w-full md:w-2/3 lg:w-3/4" key="content-right">
                        {hasActiveDataSet ? renderActiveDataset() : placeholderDataset()}
                    </div>
                </div>
            </>
        )
    };

    const UserAccessTab = () => {
        return (
            <>
                <div className="mt-5">
                    <div className='flex flex-row block items-center'>
                        <label className="text-sm font-medium text-gray-700 font-bold">
                            {t("report_Share_url")}
                        </label>
                        {
                            (!(report['sharetoken'] && report['sharetoken'].trim() !== '')) ? (
                                <>
                                    <a onClick={() => {
                                        generateShareToken()
                                    }} className='ml-5 cursor-pointer font-medium text-blue-600'>
                                        {t("generate_share_link")}
                                    </a>
                                </>
                            ) : null
                        }
                    </div>
                    {
                        ((report['sharetoken'] && report['sharetoken'].trim() !== '')) ? (
                            <>
                                <div className="mt-1 flex">
                                    <input
                                        type="text"
                                        disabled
                                        value={`${getReportSharePath()}/${report['sharetoken']}`}
                                        className="h-8 px-2 rounded-md bg-gray-50 shadow-md py-2 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                        placeholder="Report Share URL"
                                    />
                                </div>
                                <div className="mt-1 flex text-sm italic">
                                    {t("share_reports")}
                                </div>
                            </>
                        ) : null
                    }
                </div>

            </>
        )
    };

    return (
        <>

            <BreadCrumbs crumbs={crumbs}/>
            <div className='p-4' onKeyDownCapture={CtrlS_detector} tabIndex={0}
                 style={{minHeight: 'calc(100vh - 45px)'}}>
                <div className='flex flex-row flex-nowrap items-center'>
                    {fetched ? (
                        <div className='font-bold text-3xl text-gray-800'>{report['name']}</div>
                    ) : (
                        <div className='title'>{t("edit_report")}</div>
                    )}
                    {loading ? (<LoadingState/>) : null}
                    {<Actions/>}
                </div>

                <JSONModal
                    key={activeSection["id"]}
                    open={tableConfigModal}
                    data={JSON.stringify(instanceToPlain(safe_get(activeSection, "settings.config", {})), null, 4)}
                    handleUpdate={(json) => {
                        const clx = _.clone(activeSection)
                        clx["settings"]["config"] = json;
                        setActiveSection(clx);
                        setTableConfigModal(false)
                    }}
                    handleCancel={() => setTableConfigModal(false)}
                />

                {(error) ? (<Error/>) : null}
                {fetched ? (<TabBar/>) : null}
                {(fetched && currentTab.key === 'meta') ? (PropertiesTab()) : null}
                {(fetched && currentTab.key === 'content') ? (ContentTab()) : null}
                {(fetched && currentTab.key === 'dataset') ? (DatasetTab()) : null}
                {(fetched && currentTab.key === 'acl') ? (UserAccessTab()) : null}
            </div>
        </>
    );

});
