import React, {useState} from 'react';
import {observer} from "mobx-react-lite";
import {useStore} from "../../../helpers/helpers";
import {safe_get} from "../../../report-visuals/report-utils";
import _ from "lodash";
import {makeid_alpa_numeric} from "../../../helpers/utils";
import {useParams} from "react-router-dom";
import {BreadCrumbs} from "../../../components/Breadcrumbs";
import {RootStore} from "../../../stores/root";
import moment from "moment";
import { useTranslation } from 'react-i18next';


export const CMXView = observer((props) => {

    const params = useParams();
    const {t} = useTranslation("cmx")

    const crumbs = [
        {name: 'DataBank', href: '/databank', current: false},
        {name: 'Repositories', href: '/repo/' + `${params.key}`, current: false},
        {name: `CMX`, href: '#', current: true}
    ];

    const rootStore: RootStore = useStore()
    const {flexiStore} = rootStore

    // @ts-ignore
    const {name, components, desired_ratio, expected_date} = flexiStore.current_fsd.summary
    const desired = {}
    components.forEach((key, i) => desired[key] = desired_ratio[i]);


    const get_new_batch = () => {
        const batch_id = makeid_alpa_numeric(6)
        const proportions = {}
        components.map((comp, ind) => {
            proportions[comp] = 0
        })

        const log_entry = {
            type: 'batch_start',
            ts: moment().toISOString(),
            component: '',
            before: 0,
            after: 0
        }

        return {
            id: batch_id,
            ts: moment().toISOString(),
            ratios: proportions,
            logs: [log_entry]
        }
    }


    const [current_batch, set_current_batch] = useState(get_new_batch())


    const reset_batch = () => {
        set_current_batch(get_new_batch())
    }

    const finish_batch = async() => {
        const tmp = _.clone(current_batch)
        const log_entry = {
            type: 'batch_finish',
            ts: moment().toISOString(),
            component: '',
            before: 0,
            after: 0
        }
        tmp["logs"].push(log_entry)

        const updated_json = flexiStore.current_fsd.r1
        updated_json['batches'] = safe_get(updated_json, 'batches', [])
        updated_json['batches'].push(tmp)
        flexiStore.update_current_fsd("r1", updated_json)
        await flexiStore.api_update_current_fsd()
        reset_batch()
    }

    const clear_batch_history = async() => {
        const updated_json = flexiStore.current_fsd.r1
        updated_json['batches'] = []
        flexiStore.update_current_fsd("r1", updated_json)
        await flexiStore.api_update_current_fsd()
        reset_batch()
    }

    const update_component_proportion = (component, count) => {
        const tmp = _.clone(current_batch)

        const log_entry = {
            type: 'component_update',
            ts: moment().toISOString(),
            component: component,
            before: tmp["ratios"][component],
            after: tmp["ratios"][component] + count
        }

        tmp["ratios"][component] += count
        tmp["logs"].push(log_entry)
        set_current_batch(tmp)
    }

    const Header = () => {
        return (<header className="bg-gray-50 shadow">
            <div className="max-w-8xl mx-auto py-2 px-2 sm:px-6 lg:px-8">
                <h1 className="text-xl font-bold leading-tight text-gray-900 uppercase">{t("concrete_tracker")}</h1>
                <h1 className="text-xl font-medium leading-tight text-gray-900">{name}</h1>
                <h1 className="text-lg leading-tight text-gray-500">{expected_date}</h1>
            </div>
        </header>)
    }

    const DesiredRatios = () => {
        return (
            <>
                <div className='uppercase font-bold text-indigo-600 text-xl'>{t("pre_determined_ratio")}</div>
                <div className='flex flex-row flex-wrap lg:w-1/2 xl:w-1/3 space-x-4 px-2 py-2'>
                    {components.map((comp, ind) => {
                        return (
                            <div className='flex flex-col'>
                                <div className='font-medium'>{components[ind]}</div>
                                <div
                                    className='flex mx-auto justify-center	items-center rounded-full bg-yellow-200 text-center font-bold'
                                    style={{width: 32, height: 32}}>{desired_ratio[ind]}</div>
                            </div>
                        )
                    })}
                </div>
            </>
        )
    }

    const BatchTracker = (innerprops) => {
        return (
            <>
                <div className='uppercase font-bold text-indigo-600 text-xl pt-4'>{t("batch_tracker")}</div>
                {Object.keys(current_batch.ratios).map(component => {
                    const current_count = current_batch.ratios[component]
                    const expected = desired[component]
                    const excess = current_count > expected
                    const status_string = excess ? `${current_count - expected} excess added` : `${expected - current_count} remaining`

                    return (
                        <div className='py-4'>
                            <div className='flex flex-row'>
                                <div className='uppercase'>{component}</div>
                                <div className='ml-auto font-bold'>{current_count}</div>
                            </div>
                            <div className='flex flex-row flex-nowrap w-full items-center'>
                                <div className="text-3xl text-red-500 cursor-pointer"
                                     onClick={() => update_component_proportion(component, -1)}><i
                                    className="fa-solid fa-circle-minus"></i></div>
                                <div className={'flex-grow'}>
                                    <progress className="progress progress-info w-56" value={Math.round(current_count / expected * 100)} max="100"></progress>
                                    {/*<ProgressBar*/}
                                    {/*    isLabelVisible={false}*/}
                                    {/*    completed={Math.round(current_count / expected * 100)}*/}
                                    {/*/>*/}
                                </div>
                                <div className="text-3xl text-green-500 cursor-pointer"
                                     onClick={() => update_component_proportion(component, +1)}><i
                                    className="fa-solid fa-circle-plus"></i></div>
                            </div>
                            <div className='italic'>{status_string}</div>
                        </div>
                    )
                })}

                <div className='flex flex-row space-x-4 justify-center py-4'>
                    <button type="button" onClick={reset_batch}
                            className="inline-flex items-center px-5 py-2 border border-transparent text-base font-medium rounded-full shadow-sm text-white bg-red-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                       {t("reset_batch")}
                    </button>

                    <button type="button" onClick={finish_batch}
                            className="inline-flex items-center px-5 py-2 border border-transparent text-base font-medium rounded-full shadow-sm text-white bg-green-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                       {t("finish_batch")}
                    </button>

                </div>

            </>
        )
    }

    const BatchHistory = (innerprops) => {
        const batches = safe_get(flexiStore.current_fsd.r1, 'batches', [])

        const empty_batch = (
            <div>{t("empty_batch")}</div>
        )

        return (
            <>
                <div className='uppercase font-bold text-indigo-600 text-xl pt-4'>{t("batch_history")}</div>
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">

                    <table className="min-w-full border-separate" style={{borderSpacing: 1}}>
                        <thead className="bg-gray-50">
                        <tr>
                            <th scope="col"
                                className="sticky uppercase top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8">{t("batch")}
                                #
                            </th>
                            <th scope="col"
                                className="sticky uppercase top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8">{t("date")}
                            </th>
                            <th scope="col"
                                className="sticky uppercase top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8">{t("start")}
                            </th>
                            <th scope="col"
                                className="sticky uppercase top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8">{t("end")}
                            </th>
                            <th scope="col"
                                className="sticky uppercase top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8">{t("ratio")}
                            </th>
                        </tr>
                        </thead>
                        <tbody className="bg-white">
                        {batches.map((x, ind) => {

                            //Limit showing last 1000 batches
                            if(ind > 1000) return null;

                            const batch = batches[batches.length - ind - 1]
                            const batch_display_no = batches.length - ind
                            const {id, ratios, logs} = batch
                            if (logs.length < 3) return empty_batch;

                            const start = moment(logs[1]['ts']).format('hh:mm:ss A')
                            const end = moment(logs[logs.length - 1]['ts']).format('hh:mm:ss A')

                            const ratio_display = []

                            components.map((comp, ind) => {
                                ratio_display.push(`${ratios[comp]}`)
                            })

                            return (
                                <tr key={id}>
                                    <td className="whitespace-nowrap py-4 px-2 text-sm font-medium text-gray-900 pl-6">
                                        {batch_display_no}
                                    </td>
                                    <td className="py-4 px-2 text-sm text-gray-500">{moment(logs[1]['ts']).format('DD/MM')}</td>
                                    <td className="py-4 px-2 text-sm text-gray-500">{start}</td>
                                    <td className="py-4 px-2 text-sm text-gray-500">{end}</td>
                                    <td className="py-4 px-2 text-sm font-medium">{ratio_display.join(' : ')}</td>
                                </tr>
                            )

                        })}
                        </tbody>
                    </table>
                </div>
                <div className='flex flex-row space-x-4 justify-center py-4'>
                    <button type="button" onClick={clear_batch_history}
                            className="inline-flex items-center px-5 py-2 border border-transparent text-base font-medium rounded-full shadow-sm text-white bg-red-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        {t("clear_batch_history")}
                    </button>

                </div>
            </>
        )
    }

    return (
        <>
            <BreadCrumbs crumbs={crumbs}/>
            <Header/>
            <main className='px-4 py-2'>
                <DesiredRatios/>
                <BatchTracker/>
                <BatchHistory/>
            </main>


        </>
    )
})