import {action, makeAutoObservable} from "mobx";

type filterTypes = {
    dept: string[],
    piv_level_2: string[],
    current_status: string[],
    priority: string[],
    reported_by: Array<string>
}

const defaultFilters = {
    dept: [],
    piv_level_2: [],
    current_status: [],
    priority: [],
    reported_by: []
}

export class GrievanceStore {
    rootStore: any;
    grievanceList;
    isShowGrievanceForm: boolean = false;
    clonedGrievance;
    isLoading = false;
    grievance_edit_mode: boolean = false;
    isViewGrievance: boolean = false;
    selectedGrievance
    isDeleteMode: boolean = false;
    isAddNewEnginner: boolean = false;
    selectedDepartment = "";
    show_gvoter_list: boolean = false;
    selected_voters = [];
    add_user_from_voterlist: boolean = false;
    showGrievanceList : boolean = false;
    FilterStatus: boolean = false;

    filters:filterTypes = defaultFilters;


    constructor(rootStore: any) {
        makeAutoObservable(this)
        this.rootStore = rootStore;
    }

    @action
    updateFilters(filters:filterTypes){
       this.filters = filters;       
    }
    @action
    resetFilters(){
        this.filters = defaultFilters;
    }

    @action
    setGrievanceList(status) {
        this.showGrievanceList  = status
    }

    @action
    setFilterStatus(status) {
        this.FilterStatus = status
    }


    @action
    update_grievance_store_property(key, value) {
        switch (key) {
            case "selectedDepartment" :
                this.selectedDepartment = value;
                break;
            case "isDeleteMode":
                this.isDeleteMode = value;
                break;
            case "isAddNewEnginner":
                this.isAddNewEnginner = value;
                break;
            case "show_gvoter_list":
                this.show_gvoter_list = value;
                break;
            case "selected_voters":
                this.selected_voters = value;
                break;
            case "isLoading":
                this.isLoading = value;
                break;
            case "grievance_edit_mode":
                this.grievance_edit_mode = value;
                break;
            case "isViewGrievance":
                this.isViewGrievance = value;
                break;
            case "clonedGrievance":
                this.clonedGrievance = value;
                break;
            case "grievanceList":
                this.grievanceList = value;
                break;
            case "isShowGrievanceForm":
                this.isShowGrievanceForm = value;
                break;
            case "selectedGrievance":
                this.selectedGrievance = value;
                break;
            case "add_user_from_voterlist":
                this.add_user_from_voterlist = value;
                break;
        }
    }

    @action
    async add_engineer(payload) {
        this.isLoading = true
        try {
            const res = await this.rootStore.api.post("/project/editprojectproperties", payload)
            this.isLoading = false
            return res

        } catch (e) {
            this.isLoading = false
        }
    }
}