import React from "react"
import {Button} from "@mui/material";

export const UploadButton = ({uploadHandler, ...props}) => {

    return(
        <Button
            variant={"contained"}
            size={"small"}
            onClick={uploadHandler}
            {...props}
            startIcon={<i className="fas fa-cloud-upload-alt"/>}
            color={"secondary"}
        >
            Upload
        </Button>
    )
}