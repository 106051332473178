import _ from "lodash";
import React from "react";

export const GradeGrouping = props => {
    const {data, title} = props;
    const booths = _.sortBy(data, ['booth']);

    return (
        <div className='my-2'>
            <div className='rounded-lg shadow-lg px-4 py-2 mb-2 w-max bg-green-600 font-bold text-white'
                 style={{backgroundColor: `${data[0]["grade"]["bgcol"]}`}}>{title} ({data.length})
            </div>
            <div className='grid grid-cols-4 gap-2'>
                {booths.map((x, index) => {
                    return (
                        <div className={"px-2"} key={index}>{x["booth"]}</div>
                    )
                })}
            </div>
        </div>
    )
}