import { action, makeAutoObservable, toJS } from "mobx";
import { UserStore } from "./user-store";
import { ApisauceInstance, create } from "apisauce";
import { ProjectStore } from "./project-store";
import { FlexiStore } from "./flexi-store";
import { AiGentoolsStore } from "./ai-gentools-store";
import { TaskManagerStore } from "./task-manager-store";
import { ReportStore } from "./report-store";
import { safe_get } from "../report-visuals/report-utils";
import { SurveyResponseStore } from "./survey-response-store";
import { VoterListStore } from "./voter-list-store";
import { BeneficiaryStore } from "./beneficiary-store";
import { VoterReachOutStore } from "./voter-reachout-store";
import { ElectionDayDashboardStore } from "./election-day-dashboard-store";
import { C3Store } from "./c3-store";
import { GrievanceStore } from "./grievance-management-store";
import { ProjectWorksStore } from "./project-works";
import { FieldIntelligenceStore } from "./field-intelligence";
import { DigitalArchiveStore } from "./digital-archive";
import { InfluentialLeadersStore } from "./influential-leaders";
import { ProjectWorkStoreV2 } from "./project-worksv2";
import { QuestionStore } from "./question-store/QuestionStore";
const BASE_URL = "https://unifiedbe.rajneethi.com/api/v1/";
// const BASE_URL = 'http://192.168.1.206:8000/api/v1/';
// const BASE_URL = 'https://9adf-2409-40f4-3055-576c-2099-abcb-4b01-6427.ngrok-free.app/';

const API_HEADER =
  "620bcdb9-4120-411c-8e15-50c0e8d55404-11eb-adc1-0242ac120002";

export class RootStore {
  appMode: Boolean;
  api: ApisauceInstance | undefined;
  userStore: UserStore;
  projectStore: ProjectStore;
  reportStore: ReportStore;
  flexiStore: FlexiStore;
  surveyResponseStore: SurveyResponseStore;
  taskManagerStore: TaskManagerStore;
  voterListStore: VoterListStore;
  beneficiaryStore: BeneficiaryStore;
  grievanceStore: GrievanceStore;
  voterReachOutStore: VoterReachOutStore;
  electionDayDashboardStore: ElectionDayDashboardStore;
  c3Store: C3Store;
  aiGentoolStore: AiGentoolsStore;
  fieldIntelligenceStore: FieldIntelligenceStore;
  digitalArchiveStore: DigitalArchiveStore;
  influentialLeadersStore: InfluentialLeadersStore;
  projectWorkStoreV2: ProjectWorkStoreV2;

  switch_url: boolean;
  base_url: string | null;
  switch_project: boolean = false;
  appDrawerToggle: boolean = false;

  projectWorksStore: ProjectWorksStore;
  languageChange: boolean = false;
  questionStore: QuestionStore;

  constructor() {
    makeAutoObservable(this);
    this.appMode = false;
    this.userStore = new UserStore(this);
    this.userStore.checkLogin();
    this.base_url = localStorage.getItem("base_url")
      ? localStorage.getItem("base_url")
      : BASE_URL;
    this.setup_api_interceptors();
    this.projectStore = new ProjectStore(this);
    this.reportStore = new ReportStore(this);
    // this.mediaStore = new MediaStore(this);
    this.surveyResponseStore = new SurveyResponseStore(this);
    this.voterReachOutStore = new VoterReachOutStore(this);
    // this.boothSelectionStore = new BoothSelectionStore(this)
    this.grievanceStore = new GrievanceStore(this);
    this.appModeInitialize();
    this.flexiStore = new FlexiStore(this);
    this.beneficiaryStore = new BeneficiaryStore(this);
    this.voterListStore = new VoterListStore(this);
    this.taskManagerStore = new TaskManagerStore(this);
    this.projectWorksStore = new ProjectWorksStore(this);
    this.electionDayDashboardStore = new ElectionDayDashboardStore(this);
    this.c3Store = new C3Store(this);
    this.switch_url = false;
    this.aiGentoolStore = new AiGentoolsStore(this);
    this.fieldIntelligenceStore = new FieldIntelligenceStore(this);
    this.digitalArchiveStore = new DigitalArchiveStore(this);
    this.influentialLeadersStore = new InfluentialLeadersStore(this);
    this.projectWorkStoreV2 = new ProjectWorkStoreV2(this);
    this.questionStore = new QuestionStore();
  }

  setup_api_interceptors() {
    const apiInstance = create({
      // @ts-ignore
      baseURL: this.base_url,
    });

    apiInstance.axiosInstance.interceptors.request.use(
      (config) => {
        config.headers = this.getHeaders();
        //config.timeout = API_REQUEST_TIMEOUT;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    apiInstance.axiosInstance.interceptors.response.use(
      (config) => {
        config.headers["Cache-Control"] = "public, max-age=31536000";
        // console.log({config})
        if (config.status === 486) {
          this.userStore.isAuthorized = false;
        }
        // console.log({config})
        return config;
      },
      (error) => {
        console.log(error.response);
        if (error.response && error.response.status === 486) {
          // this.setIsAuthorised(false)
          this.userStore.logout();
        }
        return Promise.reject(error);
      }
    );

    this.api = apiInstance;
  }

  @action
  setIsAuthorised(val: boolean) {
    this.userStore.isAuthorized = val;
    this.userStore.logout();
  }

  @action
  getHeaders() {
    const headers = {};
    // @ts-ignore
    headers["X-API-Secret"] = API_HEADER;
    // headers['Access-Control-Allow-Origin'] = '*';
    if (this.userStore.isAuthorized) {
      // @ts-ignore
      headers["X-Token"] = this.userStore.getToken();
    }
    return headers;
  }

  @action
  set_switch_url(mode: boolean) {
    this.switch_url = mode;
  }

  appModeInitialize() {
    const appmode_storage = window.localStorage.getItem("__appmode");
    if (appmode_storage && appmode_storage === "true") {
      this.appMode = true;
    }
  }

  @action
  fireAndForgetAction = async (apipath: string) => {
    if (this.userStore.isRoot()) {
      // @ts-ignore
      const response = await this.api.post(apipath, {});
      if (response.status == 200) return true;
    }
    return false;
  };

  @action
  change_base_url(url) {
    this.base_url = url;
    this.api.setBaseURL(url);
    localStorage.setItem("base_url", url);
  }

  @action
  updateHeaders = (_updated_headers) => {
    this.api.headers = _updated_headers;
  };

  getActiveProjectID() {
    return safe_get(toJS(this.projectStore.activeProject), "id", -1);
  }

  @action
  async get_s3_url(bucket: string, path: string) {
    const response = await this.api.post("utils/presignurl", { bucket, path });
    // console.log("response.data['uri'];", response.data['uri'])
    if (response.status == 200) {
      return response.data["uri"];
    }
    return null;
  }

  @action
  async get_json_resource(json_url_path: string, default_res = {}) {
    try {
      const response = await fetch(json_url_path);
      if (response.status == 200) {
        return await response.json();
      }
      return default_res;
    } catch (e) {
      return default_res;
    }
  }

  @action
  update_switch_project(val) {
    this.switch_project = val;
  }

  @action
  update_language_Change(val) {
    this.languageChange = val;
  }

  @action
  update_appDrawerToggle(val) {
    this.appDrawerToggle = val;
  }

  async get_enquiries() {
    return this.api.post("rn/getrncontact");
  }
}
