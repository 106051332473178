import React from "react";
import {BreadCrumbs} from "../../components";

export const FSDListCrumbs = ({name}) => {

    const crumbs = [
        {name: "DataBank", href: "/databank", current: false},
        {name, href: "#", current: true},
    ];

    return <BreadCrumbs crumbs={crumbs}/>
}