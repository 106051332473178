import React, {useState} from 'react'
import {observer} from "mobx-react-lite"
import CreatableSelect from 'react-select/creatable';
import _ from "lodash";
import {useStore} from "../../helpers/helpers";
import { PlusIcon, MinusIcon} from "@heroicons/react/24/solid";
import {toast} from "react-toastify";
import {classNames} from "../../helpers/utils";
import {safe_get} from "../../report-visuals/report-utils";
import { useTranslation } from 'react-i18next';
import {LoadingState} from "../../components";

export const AudioRegions = observer((props: any) => {

    const {surveyResponseStore} = useStore()
    const {tags} = surveyResponseStore
    const {regionClick, regionUpdate, regionDelete} = props
    const _regions = surveyResponseStore.regions
    const [audioOffset, setAudioOffset] = useState(0);
    const [activeRegion, setActiveRegion] = useState({});
    const [activeRegionId, setActiveRegionId] = useState('');
    const [t]= useTranslation(["survey_management", "beneficiary"]);

    console.log("AudioRegions Re-Render");

    const ButtonGroup = (props) => {
        const {region} = props;
        return (
            <span className="relative z-0 inline-flex shadow-sm rounded-md my-2">
                  <button
                      type="button"
                      className={classNames(audioOffset === 0.05 && activeRegionId === region['id'] ? "bg-indigo-600 text-white hover:bg-indigo-500" : "bg-white-600 text-gray-700", "relative inline-flex items-center px-4 py-2 rounded-l-md border border-1 border-gray-300 text-sm font-medium focus:z-10")}
                      onClick={() => {
                          setAudioOffset(0.05)
                          setActiveRegionId(region['id'])
                      }}
                  >
                    0.05s
                  </button>
                  <button
                      type="button"
                      className={classNames(audioOffset === 0.10 && activeRegionId === region['id'] ? "bg-indigo-600 text-white hover:bg-indigo-500" : "bg-white-600 text-gray-700", "-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium focus:z-10")}
                      onClick={() => {
                          setAudioOffset(0.10)
                          setActiveRegionId(region['id'])
                      }}
                  >
                    0.10s
                  </button>
                  <button
                      type="button"
                      className={classNames(audioOffset === 0.20 && activeRegionId === region['id'] ? "bg-indigo-600 text-white hover:bg-indigo-500" : "bg-white-600 text-gray-700", "-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium focus:z-10")}
                      onClick={() => {
                          setAudioOffset(0.20)
                          setActiveRegionId(region['id'])
                      }}
                  >
                    0.20s
                  </button>
            </span>
        )
    }

    const regionClickCB = (region) => {
        regionClick(region)
    }

    const regionUpdateCB = (region) => {
        regionUpdate(region)
    }

    function secondsToHms(d) {
        d = Number(d);
        // var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.round(d % 3600 % 60);

        // var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
        var mDisplay = m > 0 ? (m < 10 ? `0${m}` : m ) : "00";
        var sDisplay = s > 0 ? (s < 10 ? `0${s}` : s ) : "00";
        return mDisplay + ':' + sDisplay;
    }

    return (
        <div className='w-full h-auto '>
            {surveyResponseStore.loading && <LoadingState />}
            <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-2 gap-2">
                {
                    _regions.map((region, index) => {
                        if(_.isEmpty(region.data)) return ;
                         const tag_val = safe_get(region["data"], "tags", [])
                        return (
                            <div key={region.id} className='rounded-lg shadow-lg bg-white my-4 px-4 py-2'>
                                <div className='flex flex-row items-center justify-between flex-wrap'>
                                    <div className='font-bold text-lg text-indigo-600'>{t("region")} {index + 1}</div>
                                    <button
                                        className='px-4 py-2 rounded-md shadow-md font-bold bg-indigo-600 text-white'
                                        onClick={() => regionClickCB(region)}>{t("play")}
                                    </button>
                                    <button className='px-4 py-2 rounded-md shadow-md font-bold bg-red-600 text-white'
                                            onClick={() => {
                                                surveyResponseStore.deleteRegion(region)
                                                regionDelete(region)
                                                surveyResponseStore.updateRegionsToServer()
                                                toast.warning("Audio clip deleted....")
                                            }
                                            }>{t("delete")}
                                    </button>
                                    {<ButtonGroup region={region}/>}
                                </div>

                                <div className='flex flex-row items-center justify-between'>
                                    <div className='py-4'>
                                        <label className='mx-2 font-bold text-md'>{t("start")}</label>
                                        <span className="relative z-0 inline-flex shadow-sm rounded-md">
                                          <button
                                              type="button"
                                              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                              onClick={(event) => {
                                                  const val = region.start + audioOffset
                                                  const tmp = _.clone(region)
                                                  tmp['start'] = val
                                                  surveyResponseStore.updateRegion(tmp)
                                                  regionUpdateCB(tmp)
                                              }}
                                          >
                                            <span className="sr-only">{t("previous")}</span>
                                            <PlusIcon className="h-5 w-5" aria-hidden="true"/>
                                          </button>
                                            <input
                                                className='-ml-px w-20 relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'
                                                type='text'
                                                value={secondsToHms(region.start)}
                                            />
                                          <button
                                              type="button"
                                              className="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                              onClick={(event) => {
                                                  const val = region.start - audioOffset
                                                  const tmp = _.clone(region)
                                                  tmp['start'] = val
                                                  surveyResponseStore.updateRegion(tmp)
                                                  regionUpdateCB(tmp)
                                              }}
                                          >
                                            <span className="sr-only">{t("next")}</span>
                                            <MinusIcon className="h-5 w-5" aria-hidden="true"/>
                                          </button>
                                        </span>
                                    </div>
                                    <div className='py-4'>
                                        <label className='mx-2 font-bold text-md'>{t("end")}</label>
                                        <span className="relative z-0 inline-flex shadow-sm rounded-md">
                                          <button
                                              type="button"
                                              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                              onClick={(event) => {
                                                  const val = region.end + audioOffset
                                                  const tmp = _.clone(region)
                                                  tmp['end'] = val
                                                  surveyResponseStore.updateRegion(tmp)
                                              }}
                                          >
                                            <span className="sr-only">{t("previous")}</span>
                                            <PlusIcon className="h-5 w-5" aria-hidden="true"/>
                                          </button>
                                            <input
                                                className='-ml-px w-20 relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'
                                                type='text'
                                                value={secondsToHms(region.end)}
                                            />
                                          <button
                                              type="button"
                                              className="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                              onClick={(event) => {
                                                  const val = region.end - audioOffset
                                                  const tmp = _.clone(region)
                                                  tmp['end'] = val
                                                  surveyResponseStore.updateRegion(tmp)
                                              }}
                                          >
                                            <span className="sr-only">{t("next")}</span>
                                            <MinusIcon className="h-5 w-5" aria-hidden="true"/>
                                          </button>
                                        </span>
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <label className="block font-bold text-md text-gray-700">{t("tags")}</label>
                                    <div className="mt-1 flex">
                                        <CreatableSelect
                                            isClearable
                                            isMulti
                                            defaultValue={tag_val.length && tag_val.map(x => {
                                                return {"value": x, "label": x};
                                            })}
                                            options={tags.map(x => {
                                                return {"value": x, "label": x};
                                            })}
                                            onChange={(val, action) => {
                                                const updatedTags = val.map(x => {
                                                    return x['value'];
                                                });
                                                const tmp = _.clone(region)
                                                tmp['data']['tags'] = updatedTags
                                                setActiveRegion(tmp)
                                            }}
                                            placeholder="Select tags..."
                                            className="w-full shadow-md h-auto rounded-md z-0 focus:shadow outline-none basic-multi-select  border-gray-300"
                                            classNamePrefix="select"
                                        />
                                    </div>
                                    <div className='mt-2'>
                                        <label className='font-bold text-md'>{t("notes")}</label>
                                        <textarea className='w-full bg-gray-50 px-4 py-2 h-24'
                                                  value={region.data.notes}
                                                  onChange={(event) => {
                                                      const val = event.target.value
                                                      const tmp = _.clone(region)
                                                      tmp['data']['notes'] = val
                                                      setActiveRegion(tmp)
                                                  }}
                                        />
                                    </div>
                                    <button
                                        className='px-4 py-2 rounded-md shadow-md font-bold bg-indigo-600 text-white self-end'
                                        onClick={() => {
                                            surveyResponseStore.set_loading(true)
                                            surveyResponseStore.updateRegion(activeRegion)
                                            surveyResponseStore.updateRegionsToServer()
                                            toast.success("Audio clip saved..")
                                            surveyResponseStore.set_loading(false)
                                        }}>{t("save",{ns:"beneficiary"})}
                                    </button>
                                </div>
                            </div>
                        )
                    })
                }

            </div>
        </div>
    )
})