import React from "react";
import {observer} from "mobx-react-lite";
import {useStore} from "../../helpers/helpers";
import {Circle, Marker, Tooltip, useMapEvents} from "react-leaflet";
import {makeid_task_location} from "../../helpers/utils";
import L from "leaflet";

export const MarkLocations = observer((props: any) => {

    const rootStore = useStore();
    const {taskManagerStore} = rootStore;
    const {marked_locations} = taskManagerStore;

    useMapEvents({
        click(e) {
            try {
                const {lat, lng} = e.latlng;
                const id = makeid_task_location();
                const location = {lat: lat, lng: lng, id: id, radius: 30};
                taskManagerStore.add_marked_locations(location)
            } catch (e) {
                console.error(e)
            }
        }
    })

    const custom_icon = new L.DivIcon({
        className: 'booth-icon',
        html: `<i class="fa-regular fa-location-crosshairs text-2xl" style="color: #8E44AD"/>`
    });

    return (
        <>
            {
                (marked_locations && marked_locations.length > 0) && marked_locations.map(pos => {
                    return (
                        <>
                            <Marker icon={custom_icon} title={pos.id ? pos.id : "Default"} key={pos.id} position={pos}>
                                <Tooltip direction="bottom" offset={[0, 20]} opacity={1} permanent>
                                    {pos.id}
                                </Tooltip>
                            </Marker>
                            <Circle
                                center={pos}
                                pathOptions={{fillColor: '#5499C7'}}
                                radius={pos.radius ? pos.radius : 30}>
                            </Circle>
                        </>

                    )
                })
            }
        </>
    )
})