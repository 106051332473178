import {useStore} from "../helpers/helpers";
import {safe_get} from "../report-visuals/report-utils";

export const useCurrentFSD = () => {

    const {flexiStore} = useStore();
    const {current_fsd} = flexiStore;
    const {r1, r2, r4} = current_fsd;

    const booth_details = safe_get(r4, "booth_details", [])
    const index_name = safe_get(current_fsd.summary, "index_name", '')
    const report_questions = safe_get(r1, "config.questions", [])
    const report_min_interviews = safe_get(r1, "config.min_interviews", 18)
    const report_booth_key = safe_get(r1, "config.booth_key", 'piv_level2')
    const booth_key_prefix = safe_get(r1, "config.booth_key_prefix", 'Booth #')
    const grading_config = safe_get(r1, "config.grading_config", {})
    const skip_transform = safe_get(r1, "config.skip_transform", false)
    const filter_booths = safe_get(r1, "config.filter_booths", false)
    const filter_booths_whitelist = safe_get(r1, "config.filter_booths_whitelist", [])
    const booth_report_maps = safe_get(r2, "boothmaps", {})
    const f20_enabled = safe_get(r1["config"], "f20.enabled", false);
    const ac_name = safe_get(r1, "config.ac_name", '');
    const title = safe_get(r1, "config.title", '');
    const sub_title = safe_get(r1, "config.sub_title", 'Booth Report');
    const qlty_sections = safe_get(r1, "config.qlty_sections", []);
    const show_swot = safe_get(r1, "config.show_swot", true);
    const header = safe_get(r1, "config.header", "");
    const hide_maps = safe_get(r1, "config.hide_booth_maps", false)
    const analytical_dashboard = safe_get(r1, "config.analytical_dashboard", false)

    return {
        ac_name,title,sub_title,booth_details,index_name,report_questions,report_min_interviews,report_booth_key,booth_key_prefix,grading_config,
        skip_transform,filter_booths,filter_booths_whitelist,booth_report_maps,f20_enabled, qlty_sections, show_swot, header, hide_maps, analytical_dashboard
    }
}