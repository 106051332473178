import React, {useMemo, useState} from 'react';
import {observer} from "mobx-react-lite";
import {useQuery} from "@tanstack/react-query";
import {useStore} from "../../helpers/helpers";
import {useNavigate} from "react-router-dom"
import {Stack, Typography} from "@mui/material";
import {CMTable, LoadingState} from "../../components";

var myDate = "05 Aug 2023"

export const ActiveProjectsFTRSummary = observer(props => {

    const rootStore = useStore();
    const navigate = useNavigate();
    const [dates, setDates] = useState({
        start_date:new Date(myDate).toISOString().split('T')[0],
        end_date: new Date().toISOString().split('T')[0]
    })

    const fetch_summary = async() => {
        return rootStore.api.post("project/fetchAllprojectsSurveySummary", {
            from_date:dates.start_date,
            to_date: dates.end_date
        })
    }

    const {data, isLoading, isError, error, refetch, isSuccess} = useQuery(['field-summary'], fetch_summary);

    const columns = useMemo(() => [
        {
            Header: "Project ID",
            accessor: 'project_id',
        },
        {
            Header: "Name",
            accessor: 'project',
        },
        {
            Header: "Valid",
            accessor: 'valid',
        },
        {
            Header: "Invalid",
            accessor: 'invalid',
        },
        {
            Header: "Not Checked",
            accessor: 'not-checked',
        },
        {
            Header: "Total",
            accessor: 'total',
        }
    ], [])

    const handleStartDateChange = (event) => {
        setDates({...dates, start_date: new Date(event.target.value).toISOString().split('T')[0]})
    };

    const handleEndDateChange = (event) => {
        setDates({...dates, end_date: new Date(event.target.value).toISOString().split('T')[0]})
    };


    if(isLoading) return <LoadingState />


    return(
        <div className="max-w-7xl mx-auto">
            <Stack direction={"row"} justifyContent={"space-between"} className="sticky top-0 bg-white">
                <Typography variant={"h3"}>Active Projects Field Summary</Typography>
                <Stack direction={"row"} gap={4}>
                  <Stack>
                      <label htmlFor="start-date">Start Date:</label>
                      <input
                          type="date"
                          id="start-date"
                          value={dates.start_date}
                          onChange={handleStartDateChange}
                      />
                  </Stack>
                    <Stack>
                        <label htmlFor="end-date">End Date:</label>
                        <input
                            type="date"
                            id="end-date"
                            value={dates.end_date}
                            onChange={handleEndDateChange}
                        />
                    </Stack>

                    <button className="btn" onClick={() => refetch()}>Search</button>
                </Stack>
            </Stack>
            {isSuccess && <CMTable
                columns={columns}
                data={data["data"]["all_project_survey_summary_by_date"]}
                onRowClick={(x) => {
                    navigate(`/project/${x.project_id}/fieldteamreport`)
                }}
                pagesize={30}
            />}
        </div>
    )
})