import { useContext, KeyboardEvent, useRef, useEffect } from "react";
import { StoreContext } from "./store-provider";
import { RootStore } from "../stores/root";

export const onEnterPress = (cb: any) => {
  return (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      cb();
    }
  };
};

// @ts-ignore
export const useStore = (): RootStore => useContext(StoreContext);

export const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const zeroPad = (num: number, numZeros: number) => {
  var n = Math.abs(num);
  var zeros = Math.max(0, numZeros - Math.floor(n).toString().length );
  var zeroString = Math.pow(10,zeros).toString().substr(1);
  if( num < 0 ) {
    zeroString = '-' + zeroString;
  }

  return zeroString+n;
}
