import React, {useEffect, useState} from "react";
import {ACBankDAO} from "./acbank_dao";
import {useStore} from "../../../helpers";
import {RootStore} from "../../../stores/root";
import {observer} from "mobx-react-lite";
import Select from "react-select";
import {useTranslation} from "react-i18next";
import {LeafletACMap, LoadingState} from "../../../components";

export const ACBankConsituencyMap = observer((props) => {

    const rootStore: RootStore = useStore();
    const {flexiStore} = rootStore;
    const dao = new ACBankDAO(rootStore);
    const {t} = useTranslation("ac_bank");
    const maps_keys = Object.keys(dao.fsd.r1["maps"]);
    const [active_map, set_active_map] = useState({
        label: maps_keys[0],
        value: maps_keys[0],
    });

    useEffect(() => {
        const getMap = async () => {
            await dao.get_current_map(active_map.value);
        };
        getMap();
    }, [active_map]);

    if (flexiStore.loading_external_resource) return <LoadingState/>;
    if (!flexiStore.external_resource) return <div className="italic">{t("unable_to_render_map")}.</div>;

    const map_config = {
        height: 850,
        show_booth_numbers: true,
    };

    return (
        <>
            <div className="px-4">
                <div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 gap-4 mb-4">
                    <div className="font-bold text-2xl uppercase pt-2">
                        {t("constituency_maps")}
                    </div>
                    <div className="flex-1" style={{zIndex: 99999}}>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            placeholder={"Select Map..."}
                            name="map"
                            defaultValue={active_map}
                            options={maps_keys.map((key) => {
                                return {
                                    value: key,
                                    label: key,
                                };
                            })}
                            onChange={(e) => {
                                set_active_map(e);
                            }}
                        />
                    </div>
                </div>
                <LeafletACMap
                    geojson={flexiStore.external_resource}
                    config={map_config}
                />
            </div>
            <img
                src="https://www.rajneethi.com/img/rn_logo_small.png"
                style={{
                    zIndex: 99999999,
                    width: "120px",
                    position: "fixed",
                    bottom: "40px",
                    opacity: "0.70",
                    left: "calc(100vw - 200px)",
                }}
            />
        </>
    );
});
