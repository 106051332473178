import React from 'react';
import { useTranslation } from 'react-i18next';
import {safe_get} from "../../report-visuals/report-utils";

export const ElectionResults = props => {

    const { name, ds } = props;
    const [t] = useTranslation(["shared", "beneficiary"]);


    if (!ds || !ds.candidates ) {
        return <div>Invalid election data</div>;
    }

    const { candidates, electors, votes, poll_percent, margin, margin_percent } = ds;
    const winner = candidates[0];
    const runner = candidates[1];

    return (
    <div className="pt-4">
        <div className='flex flex-row flex-wrap justify-around lg:justify-between lg:w-1/2'>
            <div className='flex flex-row flex-nowrap'><strong>{t("electorate")}:</strong>&nbsp;&nbsp;{electors}</div>
            <div className='flex flex-row flex-nowrap'><strong>{t("polled")}:</strong>&nbsp;&nbsp;{votes}</div>
            <div className='flex flex-row flex-nowrap'><strong>{t("polling_percentage")}:</strong>&nbsp;&nbsp;{poll_percent}%</div>
        </div>

        <div className='flex flex-col'>
            <div className='flex flex-row flex-wrap text-lg'><strong className=''>{t("winner")}:</strong>&nbsp;&nbsp;{winner['name']} ({winner['party_short']}{winner['party']})</div>
            <div className='flex flex-row flex-wrap'><strong className=''>{t("margin")}:</strong>&nbsp;&nbsp;{margin} {t("votes")} ({margin_percent}%)</div>
        </div>

        <div className="border-b border-gray-200 sm:rounded-lg mt-2">
            <table className="table-auto">
                <thead className="bg-gray-50 text-lg font-bold">
                <tr>
                    <th
                        scope="col"
                        className="px-1 py-3 text-sm text-left font-bold text-gray-500 uppercase tracking-wider"
                    >
                        {t("position")}
                    </th>
                    <th
                        scope="col"
                        className="px-2 py-3 text-sm text-left font-bold text-gray-500 uppercase tracking-wider"
                    >
                        {t("name", {ns:"beneficiary"})}
                    </th>
                    <th
                        scope="col"
                        className="px-2 py-3 text-sm text-left font-bold text-gray-500 uppercase tracking-wider"
                    >
                        {t("party")}
                    </th>
                    <th
                        scope="col"
                        className="px-2 py-3 text-sm text-right font-bold text-gray-500 uppercase tracking-wider"
                    >
                        {t("votes")}
                    </th>
                </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200 overflow-x-auto">
                {candidates.map((candidate) => {
                    const votes_percentage = "votes_percentage" in candidate ? safe_get(candidate, "votes_percentage", "") :  safe_get(candidate, "vote_percent", "")
                    return (
                        <tr key={candidate.position}>
                            <td className="px-1 py-4  text-center">{candidate.position}</td>
                            <td className="px-2 py-4 ">{candidate.name}</td>
                            <td className="px-2 py-4 ">{candidate.party}</td>
                            <td className="px-2 py-4  text-right">{candidate.votes} ({Math.round(votes_percentage * 10)/10}%)</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    </div>
   )
}


