import React, {useMemo} from "react";
import {observer} from "mobx-react-lite";
import {useStore} from "../../../helpers/helpers";
import _ from "lodash";
import {Table} from "./table";
import {toJS} from "mobx";
import {TableHeader} from "./survey-summary";
import { useTranslation } from "react-i18next";

export const SurveysByDateHL = observer(props => {
    const {surveyResponseStore, userStore} = useStore();
    const {allSurveyResponsesSummary} = surveyResponseStore;
    const [t] = useTranslation(["survey_management" , "analytical_summary"])

    const data = useMemo(() => toJS(allSurveyResponsesSummary)["by_date_hl"], [allSurveyResponsesSummary]);

    const columns = useMemo(() => [
        {
            Header: t("date",{ns:"analytical_summary"}),
            accessor: 'dt_month_date',
            Footer: t("total")
        },
        {
            Header: t("highlights"),
            accessor: 'has-hl',
            Footer: surveyResponseStore.get_total_surveys(data, "has-hl")
        },
        {
            Header: t("no_highlights"),
            accessor: 'no-hl',
            Footer: surveyResponseStore.get_total_surveys(data, "no-hl")
        }, {
            Header: t("not_checked"),
            accessor: 'not-checked',
            Footer: surveyResponseStore.get_total_surveys(data, "not-checked")
        }, {
            Header: t("total"),
            accessor: 'total',
            Footer: surveyResponseStore.get_total_surveys(data, "total")
        }
    ], [allSurveyResponsesSummary]);

    if (_.isEmpty(allSurveyResponsesSummary["by_date_hl"]) || userStore.is_field_worker()) return null;

    const row_click_handler = (x) => {
        surveyResponseStore.update_new_filters("dt_month_date", x["dt_month_date"], `Date: ${x['dt_month_date']}`);
    }

    return (
        <div className="flex flex-col">
            <TableHeader title={t("date_highlights")}/>
            <Table data={data} columns={columns} onRowClick={row_click_handler}/>
        </div>
    )
})
