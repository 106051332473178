import {observer} from "mobx-react-lite";
import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import {useStore} from "../../helpers/helpers";
import {toJS} from "mobx";
import {get_color, reduce_for_visual, safe_get} from "../report-utils";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import _ from "lodash";

am4core.useTheme(am4themes_animated);

export const StackedBarChartForReport = observer((props: any) => {

    const rootStore = useStore();
    const {reportStore} = rootStore;
    const {currentReportData} = reportStore;

    const {section, depth, visualType} = props;
    const {id, type, settings, args} = section;
    const {title} = settings;

    const [dt, setdt] = useState([]);
    const [transformed, setTransformed] = useState(false);
    const [loading, setLoading] = useState(false);

    const bar_chart = useRef(null);

    const {reducers, ds, headers, display} = args;


    const _args = toJS(args);
    const rootStyles = safe_get(_args, "display.rootStyles", {"width": "100%", "height": "auto"});
    const rootClasses = safe_get(_args, "display.rootStyles", '');

    const showTitle = safe_get(_args, "display.showtitle", true);
    const titleLocation = safe_get(_args, "display.titlelocation", "barchart-center");

    const labelField = safe_get(_args, "headers.label", '');
    const valueField = safe_get(_args, "headers.value", '');
    const chart_div = `chart_${id}_container`;

    useEffect(() => {
        refresh_data()
    }, []);

    const refresh_data = () => {
        setLoading(true);
        let current = reduce_for_visual(toJS(currentReportData[ds]), reducers, [])
        setdt(current);
        setTransformed(true);
        setLoading(false);
    };

    useLayoutEffect(() => {
        let chart_data = dt.map((row) => {
            return {
                'label': safe_get(row, labelField, ''),
                'value': safe_get(row, valueField, 0)
            };
        });
        chart_data = _.orderBy(chart_data, ['value'], ['asc']);

        console.log("chart_data", chart_data)

        const colorsList = chart_data.map((x, ind) => {
            return get_color(ind);
        })

        let chart = am4core.create(chart_div, am4charts.XYChart);
        chart.hiddenState.properties.opacity = 0;

        //set outline color for series
        chart.plotContainer.stroke =  am4core.color('#fffff');
        chart.plotContainer.strokeWidth = 0;

        //set chart data
        //chart.data = chart_data;

        // Add data
        chart.data = [{
            "year": "2016",
            "europe": 2.5,
            "namerica": 2.5,
            "asia": 2.1,
            "lamerica": 0.3,
            "meast": 0.2,
            "africa": 0.1
        }, {
            "year": "2017",
            "europe": 2.6,
            "namerica": 2.7,
            "asia": 2.2,
            "lamerica": 0.3,
            "meast": 0.3,
            "africa": 0.1
        }, {
            "year": "2018",
            "europe": 2.8,
            "namerica": 2.9,
            "asia": 2.4,
            "lamerica": 0.3,
            "meast": 0.3,
            "africa": 0.1
        }];

        const defaultGraphSettings = {
            "labels": {
                "xaxis": false,
                "yaxis": false
            },
            "legend": true,
            "tooltip": false,
            "title": {
                "location": "donut-center",
                "text": "Title of the chart",
                "fontSize": 30,
            },
            "fill": "#8c48f5",
            "columnWidth": "50",
            "fontSize": 16
        };

        const graphSettings = safe_get(args, "graphSettings", defaultGraphSettings);

        // Create axes
        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "year";
        categoryAxis.renderer.grid.template.location = 0;

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.inside = true;
        valueAxis.renderer.labels.template.disabled = true;
        valueAxis.min = 0;

        // Create series
        function createSeries(field, name) {

            // Set up series
            let series = chart.series.push(new am4charts.ColumnSeries());
            series.name = name;
            series.dataFields.valueY = field;
            series.dataFields.categoryX = "year";
            series.sequencedInterpolation = true;

            // Make it stacked
            series.stacked = true;

            // Configure columns
            series.columns.template.width = am4core.percent(50);
            series.columns.template.tooltipText = "[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}";

            // Add label
            let labelBullet = series.bullets.push(new am4charts.LabelBullet());
            labelBullet.label.text = "{valueY}";
            labelBullet.locationY = 0.5;
            labelBullet.label.hideOversized = true;

            return series;
        }

        createSeries("europe", "Europe");
        createSeries("namerica", "North America");
        createSeries("asia", "Asia-Pacific");
        createSeries("lamerica", "Latin America");
        createSeries("meast", "Middle-East");
        createSeries("africa", "Africa");

        // Legend
        chart.legend = new am4charts.Legend();
        bar_chart.current = chart;

    }, [transformed]);

    return (
        <>
            <div
                style={rootStyles}
                className={`flex flex-col ${rootClasses}`}
            >
                <div key={`chart_${id}_title`}>{title}</div>
                <div key={`${chart_div}`} id={`${chart_div}`} className='w-full h-full'/>
            </div>
        </>
    );

});