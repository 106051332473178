import {observer} from "mobx-react-lite";
import React from "react";
import {useStore} from "../../../../helpers/helpers";
import {SimpleTable} from "../../../../report-visuals/visual-types/simple-table";
import {Donut3D} from "../../../../report-visuals/visual-types/donut-3d";
import {BarChart} from "../../../../report-visuals/visual-types/bar-chart";
import {StackedBarChartForReport} from "../../../../report-visuals/visual-types/stacked-bar-chart";
import Moment from "react-moment";
import {safe_get} from "../../../../report-visuals/report-utils";
import {GeoMap} from "../../../../report-visuals/visual-types/geomap";
import {classNames} from "../../../../helpers/utils";
import HotTable from "@handsontable/react";

export const ReportViewRegularTemplate = observer(props => {

    const rootStore = useStore();
    const {reportStore} = rootStore;
    const rep = reportStore.getCurrentReport();
    const sections = rep['sections'];
    const narratives = rep['narratives'];
    const date = rep['generated'];
    const should_show_generated_date = safe_get(rep, "more.settings.show_generated_ts", false);

    const HeaderSection = (innerprops) => {
        const {section, depth} = innerprops;
        const {id, type, settings, args} = section;
        const { displayTitle } = args
        const {title, rootClasses} = settings;
        let children = [];
        if (section['children']) {
            children = section['children'];
        }

        const rootStyles = rootClasses;

        return (
            <>
                <section key={`sec_header_${id}`} className='mt-5'>
                    {(depth === 0) && displayTitle.length > 0 ? (<h1 className='font-bold text-indigo-600 my-2 text-center'>{displayTitle}</h1>) : null}
                    <div className={classNames(`${rootStyles}`, 'section-container mt-2')}>
                        {(depth === 1) ? (<h2 className='font-bold'>{displayTitle}</h2>) : null}
                        {(depth === 2) ? (<h3 className='font-bold'>{displayTitle}</h3>) : null}
                        {(depth === 3) ? (<h4 className='font-bold'>{displayTitle}</h4>) : null}
                        {(depth === 4) ? (<h5 className='font-bold'>{displayTitle}</h5>) : null}
                        {(depth > 4) ? (<h6 className='font-bold'>{displayTitle}</h6>) : null}
                        {children.map(child => {
                            return (<GenericSection section={child} depth={depth + 1}/>)
                        })}
                    </div>
                </section>
            </>
        );
    }


    const NarrativeSection = (innerprops) => {
        const {section, depth} = innerprops;
        const {id, type, settings, args} = section;
        const {nid} = args;
        const content: string = narratives[nid].toString();

        return (
            <>
                <div className='narrative' dangerouslySetInnerHTML={{__html: content}}/>
            </>
        );

    }

    const Visualization = (innerprops) => {
        const {section, depth} = innerprops;
        const {id, type, settings, args} = section;
        if (!args['visualtype']) return null;
        const {visualtype} = args;
        const {title} = settings;

        return (
            <>
                {(visualtype === 'simple-table') ? (<SimpleTable section={section} depth={depth}/>) : null}
                {(visualtype === 'donut-3d') ? (<Donut3D section={section} depth={depth}/>) : null}
                {(visualtype === 'vertical-bar-chart') ? (
                    <BarChart visualType={visualtype} section={section} depth={depth}/>) : null}
                {(visualtype === 'horizontal-bar-chart') ? (
                    <BarChart visualType={visualtype} section={section} depth={depth}/>) : null}
                {(visualtype === 'stacked-bar-chart') ? (<StackedBarChartForReport section={section} depth={depth}/>) : null}
                {(visualtype === 'geo-map') ? (<GeoMap section={section} depth={depth}/>) : null}
            </>
        );

    }

    const ImageSection = (innerprops) => {
        const {section, depth} = innerprops;
        const {id, type, settings, args} = section;
        const {s3path, s3path_uri} = args
        const {width, height, title, showTitle} = settings

        return (
            <>
                <div>
                    <img className='mx-auto mt-4' src={s3path_uri} width={width} height={height}/>
                    {showTitle && <p className='font-bold text-center'>{title}</p>}
                </div>
            </>
        );

    }

    const GenericSection = (innerprops) => {
        const {section, depth} = innerprops;
        const {type} = section;
        return (
            <>
                {(type === 'section') ? (<HeaderSection section={section} depth={depth}/>) : null}
                {(type === 'narrative') ? (<NarrativeSection section={section} depth={depth}/>) : null}
                {(type === 'widget') ? (<Visualization section={section} depth={depth}/>) : null}
                {(type === 'image') ? (<ImageSection section={section} depth={depth}/>) : null}
                {(type === 'data-table') ? (<RenderTable section={section} depth={depth}/>) : null}
            </>
        );
    }

    const RenderTable = (props:any) => {
        const {section} = props;
        return(
            <>
                <div className={"font-bold text-3xl py-4"}>{safe_get(section, "settings.title", "")}</div>
                <HotTable
                    data={safe_get(section, "args.data", [])}
                    readOnly={true}
                    nestedHeaders={safe_get(section, "settings.config.headers", [])}
                    // columns={safe_get(section, "settings.config.columns", [])}
                />
            </>
        )
    }

    return (
        <>
            <main
                className='main-report-container max-w-7xl mx-auto mt-2 p-4 pb-5 pt-2 sm:p-6 lg:p-8 bg-white shadow-lg rounded-lg'>
                {should_show_generated_date ?
                    <Moment date={date} format="DD MMM hh:mm" className='text-gray-500 text-sm'/> : null}
                {sections.map(section => {
                    return (<GenericSection section={section} depth={0}/>)
                })}
            </main>
        </>
    );

});
