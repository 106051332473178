import {observer} from "mobx-react-lite";
import React from "react"
import {useStore} from "../../helpers/helpers";
import {C3StarRating} from "./c3StarRating";
import {useTranslation} from "react-i18next";

export const defaultC3ActivePersonObj = {
    'id': '',
    'name': '',
    'phone': '',
    'comments': '',
    'profession': '',
    'rating': 0,
    'booth': '',
}

export const C3PartyWorkersAddEdit = observer((props: any) => {
    const {c3Store} = useStore()
    const {party_worker_active} = c3Store
    const [t]= useTranslation("constituency_command_center");
    const c3PartyWorkerAddEditTextInputChangeHandler = (attr, val) => {
        c3Store.set_party_worker_active({...party_worker_active, [attr]: val})
    }

    const c3PartyWorkerAddEditTextInput = (label, attr) => {
        return <>
            <div className='mb-2 flex flex-col'>
                <label className='text-sm'>{label}</label>
                <input type='text'
                       value={party_worker_active[attr]}
                       className='border p-2 rounded shadow'
                       onChange={(event) => c3PartyWorkerAddEditTextInputChangeHandler(attr, event.target.value)}/>
            </div>
        </>
    }

    const c3PartyWorkerAddEditTextAreaInput = (label, attr) => {
        return <>
            <div className='mb-2 flex flex-col'>
                <label className='text-sm'>{label}</label>
                <textarea rows={4} cols={50}
                          value={party_worker_active[attr]}
                          className='border p-2 rounded shadow'
                          onChange={(event) => c3PartyWorkerAddEditTextInputChangeHandler(attr, event.target.value)}/>
            </div>
        </>
    }

    const saveC3PartyWorkerAddedEditedData = () => {
        if (c3Store.party_worker_overlay_mode === 'edit') {
            c3Store.edit_save_party_worker_active()
        } else if (c3Store.party_worker_overlay_mode === 'add') {
            c3Store.add_commit_party_worker_active()
        }
        c3Store.toggle_party_worker_edit_overlay()
        c3Store.update_party_worker_overlay_mode('')
        c3Store.set_party_worker_active(defaultC3ActivePersonObj)
    }

    const onStarClickHalfStar = (nextValue, prevValue, name, e) => {
        const xPos = (e.pageX - e.currentTarget.getBoundingClientRect().left) / e.currentTarget.offsetWidth;

        if (xPos <= 0.5) {
            nextValue -= 0.5;
        }

        console.log('name: %s, nextValue: %s, prevValue: %s', name, nextValue, prevValue);
        c3PartyWorkerAddEditTextInputChangeHandler('rating', nextValue)
    }

    const boothDataDropdown = (label) => {
        return <div className='mb-2 flex flex-col'>
            <label className='text-sm'>{label}</label>
            <select className='p-2 border rounded shadow' name="dropdown" id="dropdown"
                    onChange={(event) => c3PartyWorkerAddEditTextInputChangeHandler('booth', event.target.value)}>
                <option value=''>{t("select_booth")}</option>
                {c3Store.boothDataForDropdown.map((booth) => {
                    let key = Object.keys(booth)[0]
                    let value = booth[key]
                    return <>
                        <option key={key} value={value}>{key}
                        </option>
                    </>
                })}
            </select>
        </div>
    }

    return <div>
        {c3PartyWorkerAddEditTextInput('Name', 'name')}
        {c3PartyWorkerAddEditTextInput('Phone', 'phone')}
        {c3PartyWorkerAddEditTextAreaInput('Comments', 'comments')}
        {c3PartyWorkerAddEditTextInput('Profession', 'profession')}
        <label className='text-sm'>{t("rating")}</label>
        <C3StarRating value={parseFloat(party_worker_active['rating'])} isEditable={true}
                      onStarClickHandler={(nextValue) => c3PartyWorkerAddEditTextInputChangeHandler('rating', nextValue)}/>
        {c3Store.boothDataForDropdown ? boothDataDropdown('Booth') : ''}
        <button className='bg-indigo-300 border rounded shadow px-4 py-2 mt-2 ml-auto'
                onClick={saveC3PartyWorkerAddedEditedData}>{t("save")}
        </button>
    </div>
})