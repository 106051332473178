import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
import { useStore } from "../../helpers/helpers";
import { useNavigate } from "react-router-dom";
import Modal from 'react-modal';
import { useTranslation } from "react-i18next";
import Select from "react-select";
import {BreadCrumbs} from "../../components";

Modal.setAppElement('#root');

const crumbs = [
    { name: 'Aigentools', href: '#', current: true }
];

export const FSTAigenTools = observer(props => {
    const rootStore = useStore();
    const { aiGentoolStore } = rootStore;
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const { t } = useTranslation("pages_root");
    const [selectedTags, setSelectedTags] = useState([]);
    const [allTags, setAllTags] = useState([]);
    const [query, setQuery] = useState('');


    const columns = useMemo(() => [
        {
            Header: "ID",
            accessor: 'id',
        },
        {
            Header: "Title",
            accessor: 'meta.title',
        },
        {
            Header: "Created",
            accessor: 'created',
        },
        {
            Header: "Modified",
            accessor: 'modified',
        },
        {
            Header: "Published",
            accessor: 'published',
        }
    ], [data])

    const handleSearch = (query) => {
        get_tool_search()
    }
    const get_tool_search = async () => {
        const res = await aiGentoolStore.get_tool_search(query);
        console.log(res.data);
        if (res.status === 200) {
            // @ts-ignore
            setData(res.data);
            console.log(res.data);
        }
    }



    const AigentsView = (innerprops) => {
        return (
            <>
                <div className="mt-5 max-w-none mx-auto grid gap-5 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2">
                    {
                        data.map(x => {
                            return aigent_report(x);
                        })
                    }

                </div>
            </>
        )
    };

    const viewGent = (event, aigent: any) => {
    }

    const editAigent = (event, aigent: any) => {

    }

    const deleteAigent = (event, aigent: any) => {

    }

    const createAigent=(event, aigent:any) =>{
        navigate('/aigentools/crete/' + aigent.id);
    }

    useEffect(() => {
        get_tool_search();
    }, [query])

    const aigent_report = (aigent) => {
        const { created, id, modified, published, access, type, tags, meta } = aigent
        return (
            <>
                <div key={`report_${id}`}
                    className="flex flex-col rounded-lg shadow-lg overflow-hidden"
                    style={{ borderLeft: '4px solid #4338CA', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                >
                    <div className="flex-1 bg-white p-3 flex flex-col justify-between cursor-pointer"
                        onClick={(evt) => viewGent(evt, aigent)}>
                        <div className="flex-1">
                            <p className="text-xl font-semi bold text-gray-900">{meta.title}</p>
                            <p className="text-xl font-semi bold text-gray-900">{id}</p>
                            <p className="mt-2 text-base text-gray-500"> <span className="text font-semi bold text-gray-800">Created: </span>{created}</p>
                            <p className="mt-2 text-base text-gray-500"><span className="text font-semi bold text-gray-800">Modified: </span> {modified}</p>

                        </div>
                    </div>


                    <div className='flex flex-row flex-wrap space-x-4 bg-white p-3'>
                        <a className='text-blue-500 cursor-pointer'
                            onClick={(evt) => editAigent(evt, aigent)}>{t("edit")}</a>
                        <a className='text-blue-500 cursor-pointer'
                            onClick={(evt) => createAigent(evt, aigent)}>{t("create")}</a>
                        <a className='text-blue-500 cursor-pointer'
                            onClick={(evt) => deleteAigent(evt, aigent)}>{t("delete")}</a>

                    </div>
                </div>

            </>
        );
    }


    return (
        <>
            <BreadCrumbs crumbs={crumbs} />

            <div className="p-4">
                <div className="flex flex-row items-center">
                    <div className="title">{t("bruhaspathi_title")}</div>
                </div>
                <div className="text-gray-600 pb-2">{t("bruhaspathi_description")}</div>
                <div className='py-4 flex flex-row flex-wrap space-y-2 lg:space-y-0'>
                    <div className="flex flex-row w-full lg:w-1/2">
                        <input type="search" value={query} onChange={(evt) => {
                            setQuery(evt.target.value);
                        }} className="w-full h-10 pr-8 pl-5 rounded z-0 focus:shadow focus:outline-none"
                            placeholder="Search Tools Title  ..." />
                        <div className="relative" style={{ top: 10, right: 30 }}>
                            <i className="fa fa-search text-gray-400 z-20 hover:text-gray-500 text-2xl cursor-pointer"
                                onClick={e => {
                                    handleSearch(query)
                                }} />

                        </div>
                    </div>
                    <div className=' w-full lg:w-1/2'>
                        <Select
                            isMulti
                            name="colors"
                            //@ts-ignore
                            options={allTags.map(x => {
                                return { "value": x, "label": x };
                            })}
                            onChange={(val, action) => {
                                // @ts-ignore
                                setSelectedTags(val);
                            }}
                            placeholder="Filter by tags..."
                            className="w-full h-10 rounded z-0 focus:shadow outline-none basic-multi-select"
                            classNamePrefix="select"
                        />
                    </div>
                </div>

                <AigentsView />

                {/* <CMTable columns={columns} data={data} onRowClick={() => {
                }} pagesize={30} /> */}

            </div>

        </>
    );
});
