import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {useStore} from "../../helpers/helpers";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {LoadingState, Toggle} from "../../components";
import {useQuery} from "@tanstack/react-query";
import {useParams} from "react-router";
import _ from "lodash"

export const Permissions = observer((props: any) => {

    const {userStore} = useStore()
    const [t] = useTranslation("user_management");
    const [roles, setRoles] = useState([]);
    const [activeToggle, setActiveToggle] = useState('');
    const {projectid} = useParams();
    const [permissions, setPermissions] = useState({});

    const getRolesDefs = async () => {
        return await userStore.getUserRolesDefinitions();
    }

    const rolesQuery = useQuery(
        {
            queryKey: ["RolesDefs"],
            queryFn: getRolesDefs,
            onSuccess: data => {
                setRoles(data?.data["roles"]);
            },
            cacheTime: 10 * (60 * 1000)
        }
    )

    const get_user_details = async () => {
        return await userStore.get_user_permissions_and_parts({
            projectid: parseInt(projectid),
            userid: parseInt(userStore.activeUser['id']),
        });
    }

    const {data, isLoading: permissionsLoading,} = useQuery(
        {
            queryKey: ["user_permissions", userStore.activeUser['id']],
            queryFn: get_user_details,
            onSuccess: data => {
                setPermissions(data?.data.permissions)
            }
        }
    )

    useEffect(() => {
        if (activeToggle != "") {
            setPermissions({...permissions, [activeToggle]: !permissions[activeToggle]})
        }
    }, [activeToggle]);

    const updatePermissions = async () => {
        const res = await userStore.updateUserPermissions({
            "projectid": parseInt(projectid),
            "userid": userStore.activeUser['id'],
            "permissions": permissions
        })

        if (res.status === 200) {
            toast.success("Permissions updated...");
            userStore.setUserDetailsMode(false);
        }
    }

    if (permissionsLoading) return <LoadingState/>

    return (
        <div className="flex flex-col justify-between bg-white px-4 py-2 my-4 rounded-lg">
            {roles && !_.isEmpty(permissions)? <div className="flex flex-col">
                <div className="font-bold text-purple-600">{t("permissions")}</div>
                {roles.map(role => {
                    return (
                        <div key={role.key} className="flex items-center justify-between py-2">
                            <div className='flex flex-col'>
                                <label className='font-md font-bold'>{role.name} </label>
                                <p className='font-xs text-gray-600'>
                                    {role.description}
                                </p>
                            </div>
                            <Toggle
                                val={permissions[role.key]}
                                onToggle={() => {
                                setActiveToggle(role.key)
                            }}/>
                        </div>
                    )
                })}
            </div> : null}
            <button className='px-4 flex py-2 rounded-md bg-indigo-700 text-white self-end'
                    onClick={updatePermissions}>{t("assign_permissions")}
            </button>
        </div>
    )
})