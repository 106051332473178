import React, { useRef, useState, useEffect } from "react";
import Select from 'react-select';
import { observer } from "mobx-react-lite";
import { uploadFile } from "../../helpers/file-upload";
import { file_icon, htmlValidation, makeid_alpa_numeric, remove_item_from_array, TextError } from "../../helpers/utils";
import { useStore } from "../../helpers/helpers";
import { useParams } from "react-router-dom";
import { safe_get } from "../../report-visuals/report-utils";
import {roles, voterType} from "./beneficiary-utils";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import {SearchVoterList, VoterListWithCheckBox} from "../voter-list";
import {FormButtons, SearchVotersButton, SideOverlay, TailwindModal, UploadButton} from "../../components";
import {Button, IconButton} from "@mui/material";

const genders = ["Male", "Female", "Others"]

export const AddFamilyMembers = observer((props: any) => {

    const { onFamilyMemberAddHandler } = props;
    const { t } = useTranslation("beneficiary");

    const params = useParams();
    const projectid = params.projectid;
    const { beneficiaryStore, voterListStore } = useStore();
    const { activeFamilyMember, addFamilyFromVoterListModal } = beneficiaryStore;
    const [familyMember, setFamilyMember] = useState<voterType>(activeFamilyMember);
    const [isFormSubmit, setFormSubmit] = useState(false)
    const [file, setFile] = useState({});
    const [buffer, setBuffer] = useState([]);
    const [inputKey, setInputKey] = useState('');

    useEffect(() => {
        setBuffer(safe_get(activeFamilyMember, "profile_photo", []))
        setFamilyMember(activeFamilyMember)
    }, [activeFamilyMember])

    const reset = () => {
        beneficiaryStore.resetActiveFamily()
        beneficiaryStore.resetActiveRequest()
    }

    const saveFamilyMember = () => {
        setFormSubmit(true)

        if (familyMember.profile.name_eng !== "" && familyMember.phone !== "") {

            const id = familyMember.id === "" ? makeid_alpa_numeric(8) : familyMember.id

            let Attachements = []
            if (buffer.length > 0) {
                for (let ele of buffer) {
                    Attachements.push({ path: ele.path, id: ele.id, type: ele.type })
                }
            }

            onFamilyMemberAddHandler({
                ...familyMember, profile_photo: Attachements
                , id: id
            });

            setFormSubmit(false)
            reset();
        }
    }

    const delete_file = name => {
        const updated_values = remove_item_from_array(buffer, "path", name)
        setBuffer(updated_values)
    }

    const path = `project-${projectid}/beneficiary/family`

    const label_style= "block text-sm font-bold text-gray-500 pt-2"
    const input_style = "max-w-lg block w-full shadow-sm focus:ring-indigo-100 focus:border-indigo-100 border-gray-300 rounded-md border-solid border p-1.5"

    const uploadFileToS3 = e => {
        htmlValidation(e)
        if (!file["file"]) return false;
        const id = makeid_alpa_numeric(8)

        const s3Path = uploadFile(path, file, "urn-assets");
        if (s3Path) {
            setFile({})
            const randomString = Math.random().toString(6);
            setInputKey(randomString);
        }
        const file_type = file["file"]["type"]
        setBuffer([{ path: s3Path, type: file_type, id: id }])
    }

    const searchVotersHandler = async() => {
        await beneficiaryStore.update_add_familymember_modal(false)
        await beneficiaryStore.update_addFamilyFromVoterListModal(true)
    }

    const X = () => {
        // @ts-ignore
        return (
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="py-4 px-6">
                    <div className="flex flex-row justify-between items-center">
                        <div className="text-xl font-semibold fp-text">{t("add_family_members")}</div>
                        <SearchVotersButton searchVotersHandler={searchVotersHandler}/>
                    </div>

                    <div>
                        <label htmlFor="name" className={label_style}>{t("name")}</label>
                        <input
                            type="text"
                            className={input_style}
                            value={familyMember["profile"]["name_eng"]}
                            onChange={event => setFamilyMember({ ...familyMember, profile: { ...familyMember.profile, name_eng: event.target.value } })}
                        />
                        {isFormSubmit && familyMember.profile.name_eng === "" && TextError(t("name_required"))}
                    </div>

                    <div className={"grid grid-cols-1 sm:grid-cols-2 gap-2"}>
                        <div>
                            <label htmlFor="phone" className={label_style}>{t("phone_number")}</label>
                            <input
                                type="text"
                                pattern="\d*"
                                className={input_style}
                                value={familyMember.phone}
                                maxLength={10}
                                onChange={event => {
                                    setFamilyMember({ ...familyMember, phone: event.target.value })
                                }}
                            />
                            {isFormSubmit && familyMember.phone === "" && TextError(t("number_required"))}
                        </div>
                        <div>
                            <label htmlFor="gender" className={label_style}>{t("gender")}</label>
                            <Select
                                name="gender"
                                value={{label: familyMember.profile.gender, value: familyMember.profile.gender}}
                                //@ts-ignore
                                options={genders.map(gender => {
                                    return { label: gender, value: gender }
                                })}
                                onChange={(val, action) => {
                                    setFamilyMember({ ...familyMember, profile: { ...familyMember.profile, gender: val["value"] } })
                                }}
                                className="w-full h-auto rounded z-999 focus:shadow outline-none basic-multi-select"
                                placeholder="Gender"
                                classNamePrefix="select"
                            />
                            {isFormSubmit && familyMember.profile.gender === "" && TextError(t("gender_required"))}
                        </div>
                    </div>


                    <div className={"grid grid-cols-1 sm:grid-cols-2 gap-2"}>
                        <div>
                            <label htmlFor="voterid" className={label_style}>{t("voter_iD")}</label>
                            <input
                                type="text"
                                className={input_style}
                                value={familyMember.id}

                                // disabled={true}
                                onChange={event => {
                                    const val = event.target.value
                                    setFamilyMember({ ...familyMember, id: val, profile: { ...familyMember.profile, voterid: val } })
                                }}
                            />
                            {isFormSubmit && familyMember.id === "" && TextError(t("voter_required"))}
                        </div>

                        <div>
                            <label htmlFor="aadharid" className={label_style}>Aadhar ID</label>
                            <input
                                type="text"
                                className={input_style}
                                value={familyMember.aadhar}
                                maxLength={12}
                                onChange={event => {
                                    const val = event.target.value
                                    setFamilyMember({ ...familyMember,aadhar: val, profile: { ...familyMember.profile, aadharid: val } })
                                }}
                            />
                            {isFormSubmit && familyMember.aadhar === "" && TextError(t("aadhar_required"))}
                        </div>
                    </div>

                    <div className={"grid grid-cols-1 sm:grid-cols-2 gap-2"}>
                        <div>
                            <label htmlFor="date" className={label_style}>Date Of Birth</label>
                            <input
                                type="date"
                                className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md border-solid border p-1.5"
                                value={familyMember.profile.dob}
                                onChange={event => {
                                    setFamilyMember({ ...familyMember, profile: { ...familyMember.profile, dob: event.target.value } })
                                }}
                            />
                            {isFormSubmit && familyMember.dob === "" && TextError(t("date_of_birth_required"))}
                        </div>

                        <div>
                            <label htmlFor="age" className={label_style}>Age</label>
                            <input
                                type="number"
                                className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md border-solid border p-2"
                                value={familyMember.profile.age}
                                onChange={event => {
                                    setFamilyMember({ ...familyMember, profile: { ...familyMember.profile, age: parseInt(event.target.value) } });
                                }}

                            />
                            {isFormSubmit && familyMember.phone === "" && TextError(t("age_required"))}
                        </div>
                    </div>

                    <div className={"grid grid-cols-1 sm:grid-cols-2 gap-2"}>
                        <div>
                            <label htmlFor="relative" className={label_style}>{t("Relative")}</label>
                            <input
                                type="text"
                                className={input_style}
                                value={familyMember.profile.relation_name_eng}
                                onChange={event => {
                                    setFamilyMember({ ...familyMember, profile: { ...familyMember.profile, relation_name_eng: event.target.value } })
                                }}
                            />
                            {/*{isFormSubmit && familyMember.profile.relationship_reg === "" && TextError(t("role_required"))}*/}
                        </div>

                        <div>
                            <label htmlFor="relationship" className={label_style}>{t("role")}</label>
                            <Select
                                value={{ label: familyMember.profile.relationship_eng, value: familyMember.profile.relationship_eng }}
                                name="relationship"
                                options={roles}
                                onChange={(val, action) => setFamilyMember({ ...familyMember, profile: { ...familyMember.profile, relationship_eng: val.value } })}
                                className="w-full h-auto rounded z-999 focus:shadow outline-none basic-multi-select"
                                placeholder="Role"
                                classNamePrefix="select"
                            />
                            {isFormSubmit && familyMember.profile.relationship_reg === "" && TextError(t("role_required"))}
                        </div>
                    </div>



                    <div>
                        <label htmlFor="additional" className={label_style}>{t("additional_information")}</label>
                        <textarea
                            className={input_style}
                            rows={3}
                            value={familyMember.profile.additional}
                            // onChange={event =>dispatch({type: "additional", value:event.target.value}) }
                            onChange={event => setFamilyMember({ ...familyMember, profile: { ...familyMember.profile, additional: event.target.value } })}
                        >
                        </textarea>
                    </div>

                    <div className="p-2 flex flex-row justify-between items-end flex-wrap ml-1">
                        <div className="flex flex-col">
                            <label htmlFor="project-name" className={label_style}>{t("upload_photo")}</label>
                            <input
                                key={inputKey}
                                type="file"
                                name="attachments"
                                id="attachments"
                                className="py-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                onChange={e => {
                                    const file = e.target.files[0];
                                    setFile({ ...file, file })
                                }}
                            />
                        </div>
                        <UploadButton uploadHandler={uploadFileToS3} />
                    </div>
                    <div className="flex flex-col justify-between items-center">
                        {buffer.length > 0 && (
                        <div className="font-bold">{t("uploaded_documents")}</div>)}
                        {buffer && buffer.map((x, index) => {
                            const file_name = x.path.split('/')[3]
                            return (
                                <div key={index} className="flex flex-row items-center px-3 flex justify-between lg:w-7/12 ">
                                    {file_icon(x.type.split('/')[0])}
                                    <p className="pl-2 lg:mr-5">{x.path.split('/')[3]}</p>
                                    <i className="fa-solid fa-trash-can text-red-600 px-4"
                                       onClick={() => {
                                           delete_file(x.path)
                                       }} />
                                </div>
                            )
                        })}
                    </div>
                </div>
                <FormButtons
                    onSave={saveFamilyMember}
                    onCancel={(e) => {
                        beneficiaryStore.update_add_familymember_modal(false)
                        reset();
                        setFormSubmit(false)
                    }}
                />
            </div>
        )
    }

    const family_members = useRef([]);

    const onSelection = voter => {

        let family = Object.assign([], family_members.current)

        if (_.findIndex(family, { id: voter.id }) === -1) {
            family.push(voter)
        } else {
            family.splice(_.findIndex(family, { id: voter.id }), 1)
        }
        family_members.current = family
    }

    const onCancel = () => {
        beneficiaryStore.update_addFamilyFromVoterListModal(false)
    }

    const onSave = async () => {
        beneficiaryStore.update_addFamilyFromVoterListModal(false)
        await voterListStore.update_family_members(family_members.current);
    }

    const addNewFamilyMemberHandler = () => {
        beneficiaryStore.update_add_familymember_modal(true)
        beneficiaryStore.resetActiveFamily()
        beneficiaryStore.resetActiveRequest()
    }

    return (
        <>
            <div className="flex flex-row items-center justify-between">
                <div className="text-indigo-500 font-bold text-xl fp-text">{t("add_family_members")}</div>
                <IconButton
                    color={"primary"}
                    size={"large"}
                    aria-label="add new request"
                    onClick={addNewFamilyMemberHandler}
                >
                    <i className={"fas fa-plus-circle"}/>
                </IconButton>
            </div>
            <SideOverlay
                onClose={() => {
                    beneficiaryStore.update_addFamilyFromVoterListModal(false)
                }}
                show={addFamilyFromVoterListModal}
                title={t("search_voters")}
            >
                <SearchVoterList/>
                <VoterListWithCheckBox
                    onSelection={onSelection}
                    onSave={onSave}
                    onCancel={onCancel}
                    checked={family_members.current}
                />
            </SideOverlay>
            <TailwindModal isOpen={beneficiaryStore.add_familymember_modal} children={X} />
        </>
    )
})