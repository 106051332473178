import {observer} from "mobx-react-lite";
import React, {useEffect, useState} from "react";
import Modal from 'react-modal';
import Moment from 'react-moment';
import Select from 'react-select';
import {Outlet} from "react-router-dom"
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {ACBankPermissions} from "../../flexistore/renderkeys/ac-bank/permissions/ac-bank-permissions";
import {useStore,htmlValidation} from "../../helpers";
import {BreadCrumbs, DeleteModal, LoadingState, SideOverlay} from "../../components";

Modal.setAppElement('#root');
// @ts-ignore
Modal.defaultStyles.content.inset = '150px';

export const ReportsLanding = observer(props => {
    const [t]=useTranslation("ReportEdit");
    const crumbs = [
        { name: t("reports"),href: '#', current: true },
    ];
    const rootStore = useStore();
    const {userStore, reportStore, projectStore,flexiStore} = rootStore;
    const [showSideOverlay, setShowSideOverlay] = useState(false);

    const [query, setQuery] = useState('');
    const [limit, setLimit] = useState(100);
    const [offset, setOffset] = useState(0);
    const [selectedTags, setSelectedTags] = useState([]);

    const [allTags, setAllTags] = useState([]);
    const [reports, setReports] = useState([]);
    const [total, setTotal] = useState(0);

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const fetchReports = async () => {
        setLoading(true);
        const response = await reportStore.getReportsForUser(query, selectedTags.map(x => {
            return x['value'];
        }), limit, offset);
        if (response.status !== 200) {
            setError(true);
            setLoading(false);
            navigate('/reports');
            return;
        }
        setAllTags(response.data.tags);
        setTotal(response.data.total);
        setReports(response.data.reports);
        setLoading(false);
    };

    useEffect(() => {
        fetchReports();
    }, [query, selectedTags, offset, limit]);

    const editReport = (event, report: any) => {
        event.preventDefault();
        event.stopPropagation();
        navigate('/reports/edit/' + report.id);
    }

    const cloneReport = async (event, report: any) => {
        setLoading(true);
        event.preventDefault();
        event.stopPropagation();
        const response = await reportStore.cloneReport(report.id);
        if (response.status !== 200) {
            setError(true);
            setLoading(false);
            return;
        }
        fetchReports();
    }

    const deleteReport = async (event, report: any) => {
        setLoading(true);
        const response = await reportStore.deleteReport(report.id);
        if (response.status !== 200) {
            setError(true);
            setLoading(false);
            return;
        }
        fetchReports();
    }

    const viewReport = (event, report: any) => {
        navigate('/reports/view/' + report.id);
    }
    const [deleteReportModal, setDeleteReportModal] = useState(false)
    const [activeReportId, setActiveReportId] = useState(null)

    const cancelCB = (event) => {
        setDeleteReportModal(false)
    }

    // const handlePermissionsClick = (evt, report) => {
    //     setLoading(true);
    //     htmlValidation(evt);
    //     setActiveReportId(report['id']);
    //     setShowSideOverlay(true);
    //     flexiStore.add_fsd_permissions();
    //     setLoading(false);
    // };

    const handlePermissionsClick = async (evt, id) => {

        const payload = {
            fsdid: parseInt(id),
            fstid: 5,
        };
        try {
            setLoading(true);
            htmlValidation(evt);
            // setActiveReportId(report['id']);
            setShowSideOverlay(true);
            await flexiStore.get_fsd_permissions(payload);
            setLoading(false);
        } catch (error) {
            console.error("Error handling permissions:", error);
            setLoading(false);
            // Handle errors as needed
        }
    };

    const handleCloseModal = () => {
        setShowSideOverlay(false);
    };

    const RenderReports = (props) => {
        const {report} = props;
        const {created, description, id, name, tags, edit} = report
        return (
            <>
                <div key={`report_${id}`}
                     className="flex flex-col rounded-lg shadow-lg overflow-hidden"
                     style={{borderLeft: '4px solid #4338CA', borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
                >
                    <div className="flex-1 bg-white p-3 flex flex-col justify-between cursor-pointer"
                         onClick={(evt) => viewReport(evt, report)}>
                        <div className="flex-1">
                            <p className="text-xl font-semibold text-gray-900">{name}</p>
                            <Moment date={created} format="DD MMM hh:mm" className='text-gray-500 text-sm'/>
                            <p className="mt-2 text-base text-gray-500">{t("description")}</p>
                            <div className={'flex flex-row flex-wrap'}>
                                {
                                    tags.map((x, index) => {
                                        return (
                                            <div key={index} className='mt-2 mr-2 px-2 py-1 bg-blue-100 text-xs rounded-lg'>{x}</div>
                                        )
                                    })
                                }
                            </div>

                        </div>
                    </div>
                    {edit ? (
                        <div className='flex flex-row flex-wrap space-x-4 bg-white p-3'>
                            <a className='text-blue-500 cursor-pointer' onClick={(evt) => editReport(evt, report)}>
                                {t("edit")}
                            </a>
                            <a className='text-blue-500 cursor-pointer' onClick={(evt) => cloneReport(evt, report)}>
                                {t("clone")}
                            </a>
                            <a className='text-blue-500 cursor-pointer' onClick={(evt) => handlePermissionsClick(evt, report['id'])}>
                                {t("permissions")}
                            </a>
                            {userStore.isRoot() ? (
                                <a className='text-blue-500 cursor-pointer' onClick={(evt) => {
                                    setDeleteReportModal(true);
                                    setActiveReportId(report['id']);
                                }}>
                                    {t("delete")}
                                </a>
                            ) : null}
                        </div>
                    ) : null}
                    <DeleteModal
                        isOpen={deleteReportModal}
                        onDeleteClick={deleteReport}
                        onCancelClick={cancelCB}
                        id={activeReportId}
                        desc={t("delete_this_report")}
                    />
                </div>

                <SideOverlay
                    children={<ACBankPermissions />}
                    onClose={handleCloseModal}
                    show={showSideOverlay}
                    title={"Permissions"}
                />
            </>
        );
    }

    const addEmptyReportAndNav = async () => {
        setLoading(true);
        const response = await reportStore.createNewReport();
        if (response.status !== 200) {
            setError(true);
            setLoading(false);
            return;
        }
        setLoading(false);
        navigate('/report/edit/' + response.data.id);
    };

    const AddReport = (innerprops) => {
        return (
            <div className="ml-auto">
                <button className='px-2 py-2 rounded-md bg-indigo-700 text-white' onClick={addEmptyReportAndNav}>
                    {t("create_new_report")}
                </button>
            </div>
        )
    };

    const Error = (innerprops) => {
        return (
            <div className="text-red-600 italic">
                {t("error_occured")}
            </div>
        )
    };

    const ReportsView = (innerprops) => {
        return (
            <>
                <div className='italic'>{t("showing")} {offset + 1} to {reports.length + offset} of {total} {t("reports")}.</div>
                <div className="mt-5 max-w-none mx-auto grid gap-5 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2">
                    {
                        reports.map(x => {
                            return <RenderReports key={x.id} report={x} />;
                        })
                    }

                </div>
            </>
        )
    };

    const FilterPane = () => {
        return (
            <div className='py-4 flex flex-row flex-wrap space-y-2 lg:space-y-0'>
                <div className="flex flex-row w-full lg:w-1/2">
                    <input type="search" value={query} onChange={(evt) => {
                        setQuery(evt.target.value);
                    }} className="w-full h-10 pr-8 pl-5 rounded z-0 focus:shadow focus:outline-none"
                           placeholder="Search reports..."/>
                    <div className="relative" style={{top: 10, right: 30}}>
                        <i className="fa fa-search text-gray-400 z-20 hover:text-gray-500"/>
                    </div>
                </div>
                <div className=' w-full lg:w-1/2'>
                    <Select
                        defaultValue={selectedTags}
                        isMulti
                        name="colors"
                        //@ts-ignore
                        options={allTags.map(x => {
                            return {"value": x, "label": x};
                        })}
                        onChange={(val, action) => {
                            // @ts-ignore
                            setSelectedTags(val);
                        }}
                        placeholder="Filter by tags..."
                        className="w-full h-10 rounded z-0 focus:shadow outline-none basic-multi-select"
                        classNamePrefix="select"
                    />
                </div>
            </div>
        )
    };

    // @ts-ignore
    return (
        <>
            <BreadCrumbs crumbs={crumbs}/>
            <div className='p-4'>
                <div className='flex flex-row flex-nowrap items-center'>
                    <div className='title'>{t("reports")}</div>
                    {loading ? (<LoadingState/>) : null}
                    {userStore.isRoot() ? (<AddReport/>) : null}
                </div>
                {FilterPane()}
                {(error) ? (<Error/>) : (<ReportsView/>)}
            </div>
            <Outlet />
        </>
    );

});
