import { observer } from "mobx-react-lite"; // Makes the component reactive to MobX state changes.
import React, { useEffect, useMemo, useRef, useState } from "react"; // React hooks for managing state, effects, refs, and memoization.
import { useStore } from "../helpers"; // Custom hook to access the MobX store.
import { useNavigate, useParams, Outlet } from "react-router-dom"; // React Router hooks for navigation and accessing route parameters.
import { safe_get } from "../report-visuals/report-utils"; // Utility function for safely accessing nested object properties.
import { useTranslation } from "react-i18next"; // Hook for internationalization (i18n) to handle translations.
import _ from "lodash"; // Utility library for handling various operations like sorting.
import {
  BreadCrumbs,
  LoadingState,
  ProjectHeader, SideOverlay, UpdateProfile,
} from "../components"; // Importing various reusable components.
import { useGetFullProject } from "../custom-hooks";

// Default configuration for web features, indicating which features are enabled or disabled.
// This is used as a fallback for existing projects that may not have this property.
const default_web_features = {
  beneficiary: "disabled",
  grievance: "enabled",
  project_works: "enabled",
  c3: "enabled",
  manage_voters: "enabled",
  voter_reach_out: "enabled",
  election_day_dashboard: "enabled",
  voter_registration_tracker: "enabled",
  field_payments: "enabled",
  field_team_report: "enabled",
  manage_project: "enabled",
  user_management: "enabled",
  taskmanager: "enabled",
  digital_repository: "enabled",
  beneficiary_v2: "enabled",
  influential_leaders: "disabled",
};

// Main component definition using MobX observer to make it reactive.
export const ProjectDashboardPage = observer((props) => {
  // Access the root store using a custom hook.
  const rootStore = useStore();

  // React Router hooks for navigation and route parameters.
  const navigate = useNavigate();
  const { projectid } = useParams();

  // Destructuring stores from the root store.
  const {
    userStore,
    projectStore,
    surveyResponseStore,
    digitalArchiveStore,
    flexiStore,
  } = rootStore;

  // Get the role of the current user.
  const role = userStore.user["role"];

  // Translation hook with namespace "ProjectDashboard".
  const [t] = useTranslation("ProjectDashboard");

  // useRef hooks for holding mutable values that persist across renders without causing re-renders.
  const track_voter_record_config = useRef({});
  const show_voter_reg_card = useRef(false);
  const view_task_permission = useRef(false);

  // Permissions for different project features.
  const view_beneficiary_permission = projectStore.getPermission(
    "manage_fs_beneficiary",
    false
  );
  const view_grievance_permission = projectStore.getPermission(
    "manage_fs_grievance",
    false
  );
  const view_projectworks_permission = projectStore.getPermission(
    "manage_fs_projectworks",
    false
  );

  // Get project-specific web features or fallback to default features.
  const webFeatures = projectStore.getProjectProperty(
    "web_features",
    default_web_features
  );

  // Custom hook to fetch the full project data.
  const { isLoading } = useGetFullProject();

  // Determine if the current user is a root user or a client.
  const isRootOrClient = userStore.isRoot() || userStore.isClient();

  // Breadcrumbs configuration for navigation.
  const crumbs = [
    { name: "DashBoard", href: `/project/${projectid}`, current: true },
  ];

  // useEffect hook to handle side effects when the component is mounted or when the project ID changes.
  useEffect(() => {
    if (!isLoading) {
      // Fetch configuration for tracking voter records and set related states.
      track_voter_record_config.current = projectStore.getProjectProperty(
        "track_voter_reg_eci",
        {}
      );
      show_voter_reg_card.current = safe_get(
        track_voter_record_config,
        "enabled",
        false
      );
      view_task_permission.current = projectStore.getPermission(
        "view_tasks",
        false
      );

      // Reset filters and digital archive store properties.
      surveyResponseStore.reset_new_filters();
      digitalArchiveStore.update_DAStoreProp("activeParentId", "");
    }

    // Reset page number in flexiStore whenever the project changes.
    flexiStore.reset_fsd_search();
  }, [projectid]); // Dependency array ensures this effect runs when projectid changes.

  // useMemo hook to memoize the menu list generation based on project features.
  const populateMenuList = useMemo(() => {
    // Initialize sections for project management, field work, and management.
    const pm = { id: 1, heading: t("project_management"), subItems: [] };
    const fw = { id: 2, heading: t("field_work"), subItems: [] };
    const cm = { id: 3, heading: t("management"), subItems: [] };

    // Loop through each feature in webFeatures and populate the menu sections based on the feature status.
    for (let key of Object.keys(webFeatures)) {
      const val = webFeatures[key];
      if (val === "enabled") {
        switch (key) {
          case "beneficiary":
            if (view_beneficiary_permission || isRootOrClient) {
              cm.subItems.push({
                icon: "fa-solid fa-hands-helping text-green-600",
                name: t("beneficiary"),
                path: "/project/" + projectid + "/beneficiary_management",
              });
            }
            break;
          case "grievance":
            if (view_grievance_permission || isRootOrClient) {
              cm.subItems.push({
                icon: "fas fa-list-ul text-teal-600",
                name: t("grievance"),
                path: "/project/" + projectid + "/grievance_management",
              });
            }
            break;
          case "project_works":
            if (isRootOrClient || view_projectworks_permission) {
              cm.subItems.push({
                icon: "fa-solid fa-diagram-project text-teal-600",
                name: t("project"),
                path: "/project/" + projectid + "/project_works",
              });
            }
            break;
          case "c3":
            if (isRootOrClient) {
              cm.subItems.push({
                icon: "fa-solid fa-grid-horizontal",
                name: t("command"),
                path: "/project/" + projectid + "/c3",
              });
            }
            break;
          case "manage_voters":
            if (isRootOrClient) {
              cm.subItems.push({
                icon: "fas fa-poll-people text-teal-600",
                name: t("manage"),
                path: "/project/" + projectid + "/manage-voters",
              });
            }
            break;
          case "voter_reach_out":
            if (isRootOrClient) {
              cm.subItems.push({
                icon: "fa-solid fa-comments text-teal-600",
                name: t("reach"),
                path: "/project/" + projectid + "/voter_reach_out",
              });
            }
            break;
          case "election_day_dashboard":
            if (isRootOrClient) {
              cm.subItems.push({
                icon: "fa-solid fa-table text-teal-600",
                name: t("election"),
                path: "/project/" + projectid + "/election_day_dashboard",
              });
            }
            break;
          case "voter_registration_tracker":
            if (isRootOrClient) {
              cm.subItems.push({
                icon: "fa-solid fa-person-booth text-teal-600",
                name: t("tracker"),
                path: "/project/" + projectid + "/voter_registration_tracker",
              });
            }
            break;
          case "digital_repository":
            if (isRootOrClient) {
              cm.subItems.push({
                icon: "fa-solid fa-person-booth text-teal-600",
                name: t("Digital Repository"),
                path: "/project/" + projectid + "/digital_repository",
              });
            }
            break;
          case "booth_selection":
            if (isRootOrClient) {
              fw.subItems.push({
                icon: "fa-solid fa-person-booth text-teal-600",
                name: "Booth Selection",
                path: "/project/" + projectid + "/booth_selection",
              });
            }
            break;
          case "field_payments":
            const WHITELIST_FOR_USERS = [
              9669, 9174, 74, 1, 92, 10693, 292, 11717, 11780,
            ];
            const has_permission = WHITELIST_FOR_USERS.includes(
              userStore.user.id
            );
            if (has_permission) {
              fw.subItems.push({
                icon: "fas fa-wallet",
                name: t("field_pay"),
                path: "/project/" + projectid + "/fieldpayment",
              });
            }
            break;
          case "field_team_report":
            fw.subItems.push({
              icon: "fas fa-file-chart-line text-blue-600",
              name: t("team_report"),
              path: "/project/" + projectid + "/fieldteamreport",
            });
            break;
          case "taskmanager":
            // Adding Taskmanager card if the user has permission to view tasks or is root/client.
            if (
              view_task_permission.current ||
              role === "root" ||
              role === "client"
            ) {
              fw.subItems.push({
                icon: "fa-solid fa-list-check text-blue-600",
                name: t("task"),
                path: "/project/" + projectid + "/taskmanager",
              });
            }
            break;
          case "manage_project":
            // Only root users can access project management.
            if (userStore.isRoot()) {
              pm.subItems.push({
                icon: "fa-solid fa-cogs text-indigo-600",
                name: t("manage_project"),
                path: `/project/${projectid}/manage`,
              });
            }
            break;
          case "user_management":
            if (userStore.isRoot() || userStore.isClient()) {
              pm.subItems.push({
                icon: "fa-solid fa-users text-yellow-600",
                name: t("user_manage"),
                path: "/project/" + projectid + "/usermanagement",
              });
            }
            break;
          case "beneficiary_v2":
            if (userStore.isRoot() || userStore.isClient()) {
              cm.subItems.push({
                icon: "fa-solid fa-users text-yellow-600",
                name: "Beneficiary V2",
                path: "/project/" + projectid + "/beneficiary_v2",
              });
            }
            break;
          case "project_works_v2":
            if (userStore.isRoot() || userStore.isClient()) {
              cm.subItems.push({
                icon: "fa-solid fa-users text-yellow-600",
                name: "PW V2",
                path: "/project/" + projectid + "/projectworks_v2",
              });
            }
            break;
          case "influential_leaders":
            if (userStore.isRoot() || userStore.isClient()) {
              cm.subItems.push({
                icon: "fas fa-user-friends text-orange-600",
                name: "Influential Leaders",
                path: "/project/" + projectid + "/influential_leaders",
              });
            }
            break;
          // Additional cases can be added here as needed.
        }
      }
    }
    return [cm, fw, pm]; // Return the populated menu list.
  }, [webFeatures, projectid]); // Dependencies ensure this memoization runs when webFeatures or projectid changes.

  // Display a loading state if the project data is still being fetched.
  if (isLoading) return <LoadingState />;

  return (
    <>
      {/* Render the breadcrumbs for navigation */}
      <BreadCrumbs crumbs={crumbs} />

      {/* Render the project header */}
      <ProjectHeader />

      {/* Main content section */}
      <div className="px-4 flex flex-col gap-y-2 sm:gap-y-6 ">
        {/* Render the sorted menu list */}
        {!isLoading &&
          _.sortBy(populateMenuList, (item) => item.id).map(
            (featureHead, index) => {
              const { heading, subItems } = featureHead;
              if (subItems.length == 0) {
                return null; // Skip rendering if there are no sub-items.
              }
              return (
                <div className="flex flex-col">
                  <h2 className="title px-2 ">{heading}</h2>
                  <div
                    className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 gap-2"
                    key={index}
                  >
                    {subItems.map((item, index) => (
                      <div
                        key={index}
                        className="p-2 cursor-pointer"
                        onClick={(e) => navigate(item.path)}
                      >
                        <div className="p-4 flex flex-nowrap flex-row shadow-md rounded-lg bg-white hover:shadow-xl items-center">
                          <div className="rounded-full bg-blue-200 text-4xl h-20 w-20 flex items-center justify-center">
                            <i className={item.icon} />
                          </div>
                          <h3 className="ml-4 font-bold">{item.name}</h3>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              );
            }
          )}
      </div>

      <SideOverlay
          children={<UpdateProfile/>}
          onClose={() =>  userStore.set_profile_update(false)}
          show={userStore.profile_update}
          title={"Update Profile"}
      />

      {/* Render any nested routes */}
      <Outlet />
    </>
  );
});
