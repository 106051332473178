import React from "react";
import {useTranslation} from "react-i18next";
import {Checkbox, FormControl, FormControlLabel, Typography} from "@mui/material";

export const StatusFilter = (props: any) => {

    const {t} = useTranslation("grievance_management");
    const {data, value, setValues} = props;

    return (
        <div className={"flex flex-col gap-y-2"}>
            <Typography color={"primary"}>Filter by status to see data based on current conditions.</Typography>
            {data.map((statusData, index) => (
                <div key={index} className={"task-filter flex flex-row items-center"}>
                    <FormControl>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={value.includes(statusData.status)}
                                    value={statusData.status}
                                    onChange={(event) => setValues(event)}
                                />
                            }
                            label={statusData.status}
                        />
                    </FormControl>
                </div>
            ))}
        </div>
    )
}