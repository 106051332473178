import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {useParams} from "react-router-dom";
import {useStore} from "../../helpers/helpers";
import {file_icon, htmlValidation, makeid_alpa_numeric, TextError} from "../../helpers/utils";
import {uploadFile} from "../../helpers/file-upload";
import {useTranslation} from "react-i18next";
import Select from "react-select";
import moment from "moment";
import _ from "lodash"
import {safe_get} from "../../report-visuals/report-utils";
import {label_style} from "../beneficiary-management";
import {BoothTree, FormButtons, LoadingState, UploadButton} from "../../components";
import {Divider} from "@mui/material";

const project_statuses = ["Created", "Ongoing", "Pending", "Completed"]
const input_style = "text-sm max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md border-solid border p-2"

type projectWorkInfoType = {
    date: string,
    name: string,
    decription: string,
    location: string,
    village: string,
    status: string,
    coverPhotoPath: string,
    latitude: number,
    longitude: number
}

export const ProjectWorkEdit = observer((props: any) => {

    const { flexiStore, userStore} = useStore()
    const rootStore = useStore();
    const {current_fsd} = flexiStore;
    const {summary} = current_fsd
    const [isSubmit, setIsSubmit] = useState(false)
    const [inputKey, setInputKey] = useState('');
    const [file, setFile] = useState({});
    const [title, setTitle] = useState('');
    const params = useParams();
    const {t} = useTranslation("project_work");
    const projectid = params.projectid;
    const path = `project-${projectid}/projectworks/media`;
    const [loading, setLoading] = useState(false)
    const {projectStore, projectWorksStore} = rootStore;
    const project_property = projectStore.getProjectProperty("project_works", {});
    const status_config = safe_get(project_property, "status_config", []);

    const [projectWorkInfo, setProjectWorkInfo] = useState<projectWorkInfoType>({
        name: safe_get(summary, "name", ""),
        decription: safe_get(summary, "decription", ""),
        location: safe_get(summary, "location", ""),
        village: safe_get(summary, "village", []),
        status: safe_get(summary, "status", ""),
        date: safe_get(summary,"date", ""),
        coverPhotoPath: safe_get(summary, "coverPhotoPath", ""),
        latitude: safe_get(summary, "latitude", 0),
        longitude: safe_get(summary, "longitude", 0)
    })

    const handleInputChange = (event) => {
        setProjectWorkInfo({...projectWorkInfo, [event.target.name]: event.target.value})
    }

    const loggedin_user = { //current user info
        "name": userStore.user.profile.name,
        "phone": userStore.user.phonenum,
        "address": userStore.user.profile.address,
        "profileImage": userStore.user.profileimageuri
    }

    const time_stamp = moment().format('DD/MM/YYYY HH:mm:ss')

    const savePW = async () => {
        setIsSubmit(true)
        setLoading(true)
        const new_log = {
            "id": makeid_alpa_numeric(3),
            "status": projectWorkInfo.status,
            // "req_title": request.type,
            // "comments": !idx ? `Request has been updated - ${request.additional}` :`Request has been created - ${request.additional}`,
            "ts": time_stamp,
            "who": loggedin_user
        }

        const r1_clone = _.clone(current_fsd.r1);
        r1_clone["timeline"] = [...r1_clone["timeline"], new_log]

        flexiStore.update_current_fsd("r1", {...r1_clone})
        flexiStore.update_current_fsd("summary", {...projectWorkInfo})
        flexiStore.update_current_fsd("projectid", parseInt(projectid))
        await flexiStore.api_update_current_fsd()
        props.onSave();
        setIsSubmit(false)
        projectWorksStore.update_project_work_property("add_pw_modal", false);
        projectWorksStore.update_project_work_property("edit_pw_modal", false);
        setLoading(false)
    }

    const delete_file = () => {
        setProjectWorkInfo({...projectWorkInfo, coverPhotoPath: ""})
    }

    useEffect(() => {
        if (projectWorksStore.edit_pw_modal) {
            setTitle('Edit Project Works');
        } else if (projectWorksStore.add_pw_modal) {
            setTitle('Add Project Works')
        }
    }, [])

    const upload_cover_photo = async (e) => {
        setLoading(true)
        htmlValidation(e);
        if (!file["file"]) return false;
        const s3Path = await uploadFile(path, file, "urn-assets");
        if (s3Path) {
            // setFile({});
            const randomString = Math.random().toString(6);
            setInputKey(randomString);
            await projectWorksStore.update_project_work_property("cover_photo", URL.createObjectURL(file['file']));
        }
        setProjectWorkInfo({...projectWorkInfo, coverPhotoPath: s3Path});
        setLoading(false)
    }

    const image_change_handler = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile && (selectedFile.type === 'image/jpeg' || selectedFile.type === 'image/png')) {
            setFile({...file, file: selectedFile});
        } else {
            alert('Please select a valid JPG or PNG file.');
        }
    }

    const cancelHandler = () => {
        projectWorksStore.update_project_work_property("add_pw_modal", false);
        projectWorksStore.update_project_work_property("edit_pw_modal", false);
    }

    const projectRequestStatus = status_config.map(x => {
        return { "value": x["status"], "label": x["status"] };
    });

    return (
        <>
            {loading ? <LoadingState/> : null}
            <div className="inline-block align-bottom rounded-lg text-left overflow-hidden transform transition-all sm:align-middle sm:max-w-lg sm:w-full">
                <div className={'flex flex-col gap-y-4'}>
                    <div className="text-xl font-semibold text-gray-900">{title}</div>
                    <div>
                        <label htmlFor="name" className={label_style}>{t("name")}</label>
                        <input type="text"
                               name={"name"}
                               className={input_style}
                               value={projectWorkInfo.name}
                               onChange={handleInputChange}
                        />
                    </div>
                    {isSubmit && projectWorkInfo.name === "" && TextError('Name is Required')}
                    <div>
                        <label htmlFor="additional" className={label_style}>{t("description")}</label>
                        <textarea
                            className={input_style}
                            rows={3}
                            name={"decription"}
                            value={projectWorkInfo.decription}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="status" className={label_style}>{t("status")}</label>
                        <Select
                            value={{label: projectWorkInfo.status, value: projectWorkInfo.status}}
                            name="status"
                            options={projectRequestStatus}
                            onChange={(val, action) => {
                                setProjectWorkInfo({...projectWorkInfo, status: val?.value})
                            }}
                            className="w-full h-auto rounded z-999 focus:shadow outline-none basic-multi-select text-sm"
                            placeholder={t("status")}
                            classNamePrefix="select"
                        />
                    </div>

                    <div>
                        <label className={label_style}>Estimated Completion date</label>
                        <input
                            type="date"
                            className={input_style}
                            value={projectWorkInfo.date}
                            onChange={(e) => {
                                setProjectWorkInfo({...projectWorkInfo, date: e.target.value})
                            }}
                        />
                    </div>

                    <div>
                        <label htmlFor="village" className={label_style}>Village/Booth</label>
                        <BoothTree
                            onBoothSelection={(data) => {
                                setProjectWorkInfo({...projectWorkInfo, village: data})
                            }}
                            selected_booth={projectWorkInfo.village}
                            selector={"display"}
                            isMulti={true}
                        />
                    </div>
                    <div>
                        <label htmlFor="location"
                               className={label_style}>{t("location")}</label>
                        <textarea
                            className={input_style}
                            rows={2}
                            name={"location"}
                            value={projectWorkInfo.location}
                            onChange={handleInputChange}
                        />
                    </div>
                    {isSubmit && projectWorkInfo.location === "" && TextError('Location is Required')}
                    {isSubmit && projectWorkInfo.status === "" && TextError('Status is Required')}

                    <div>
                        <p className={label_style}>Location Co-Ordinates</p>
                        <div className="flex flex-row items-center justify-between">
                            <div>
                                <label htmlFor="latitude" className={label_style}>Latitude</label>
                                <input type="number"
                                       name={"latitude"}
                                       className={input_style}
                                       value={projectWorkInfo.latitude}
                                       onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label htmlFor="longitude" className={label_style}>Longitude</label>
                                <input type="number"
                                       name={"longitude"}
                                       className={input_style}
                                       value={projectWorkInfo.longitude}
                                       onChange={handleInputChange}
                                />
                            </div>
                        </div>
                    </div>
                    <Divider />
                    <div className="flex flex-row justify-between items-end flex-wrap ml-1">
                        <div className="flex flex-col">
                            <label className={label_style}>{t("upload_cover_photo")}</label>
                            <input
                                key={inputKey}
                                type="file"
                                name={"coverPhotoPath"}
                                accept=".jpg, .jpeg, .png"
                                className="py-2 block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                onChange={image_change_handler}
                            />
                        </div>
                        <UploadButton
                            uploadHandler={upload_cover_photo}
                        />
                    </div>

                    {projectWorkInfo['coverPhotoPath'] !== '' &&
                        <div className="flex flex-col justify-between items-start">
                            {/*<div className="font-bold">{t("uploaded_cover_photo")}</div>*/}
                            <div className="flex flex-col gap-y-2">
                                {!_.isEmpty(file) ? <img
                                    src={URL.createObjectURL(safe_get(file, "file", null))}
                                    alt="Cover Photo"
                                    className={'py-2'}
                                /> : null}
                                <div className="flex flex-row items-center justify-between ">
                                    <p className="lg:mr-5">
                                        <span
                                            className={'px-2'}>{file_icon('image')}</span>{projectWorkInfo['coverPhotoPath']?.split('/')[3]}
                                    </p>
                                    <i className="fa-solid fa-trash-can text-red-600 px-4" onClick={delete_file}/>
                                </div>
                            </div>
                        </div>}
                    <Divider />
                    <FormButtons
                        onSave={savePW}
                        onCancel={cancelHandler}
                        type={'button'}
                    />
                </div>
            </div>
        </>
    )
})