import React from "react";
import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import { truncateText } from "../../helpers/utils";
import CreateANDEditOptionsDialog from "./CreateANDEditOptionsDialog";

const ProfileQuestionOptions = ({ data, title, onUpdate, id, onDelete }) => {
  const [showControls, setShowControls] = useState(false);
  const [showModal, setShowModal] = useState(false);
  return (
    <li
      className="odd:bg-gray-200 even:bg-indigo-200 even:border-indigo-300 odd:border-gray-300 border-1  rounded-md p-2 text-slate-700 font-extrabold "
      onMouseOver={() => setShowControls(true)}
      onMouseLeave={() => setShowControls(false)}
      title={data}
    >
      <CreateANDEditOptionsDialog
        currentValue={data}
        type={"Update"}
        id={id}
        title={title}
        onSubmit={onUpdate}
        show={showModal}
        setShow={setShowModal}
      />
      <div className="flex flex-wrap justify-between items-center">
        <div>{truncateText(data)}</div>
        <div>
          {showControls ? (
            <div className="h-1 flex">
              <IconButton
                size="small"
                aria-label="edit"
                onClick={() => setShowModal(!showModal)}
              >
                <i className="text-yellow-500 fa-solid fa-pen-to-square"></i>
              </IconButton>
              <IconButton
                size="small"
                aria-label="edit"
                onClick={() => onDelete(data)}
              >
                <i className="text-red-500 fa-solid fa-trash"></i>
              </IconButton>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </li>
  );
};

export default ProfileQuestionOptions;
