import React, {useEffect, useMemo, useState} from "react";
import {observer} from "mobx-react-lite";
import {useStore} from "../../helpers/helpers";
import {useTranslation} from "react-i18next";
import {DownloadAsExcelSheet, LoadingState} from "../../components";
import {Table} from "../survey-management/survey-summary";


const excel_col_def = [
    {
        "label": "Name",
        "key": 'qa_name'
    },
    {
        "label": "Project",
        "key": 'project',
    },
    {
        "label": "Date",
        "key": 'dt',
    },
    {
        "label": "Valid",
        "key": 'valid',
    },
    {
        "label": "Invalid",
        "key": 'invalid',
    },
    {
        "label": "Total",
        "key": 'total',
    }
]



export const PaymentQCStatus = observer((props) => {

    const[t]=useTranslation("field_payment");
    const {api} = useStore()
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        get_qc_status();
    }, []);

    const get_qc_status = async () => {
        try {
            setLoading(true)
            const response = await api.post('projectshelf/qcprogress', {})
            // @ts-ignore
            setData(response["data"])
            setLoading(false)
        } catch (e) {
            console.log(e);
            setLoading(false)
        }
    }

    const columns = useMemo(() => [
        {
            Header: t("name"),
            accessor: 'surveyor_name',
            Cell: (row) => {
                return (
                    <div>{row.row.original["qa_user"]["name"]}</div>
                )
            }
        },
        {
            Header: t("project"),
            accessor: 'project',
        },
        {
            Header: t("date"),
            accessor: 'dt',
        },
        {
            Header:  t("valid"),
            accessor: 'valid',
        },
        {
            Header:  t("invalid"),
            accessor: 'invalid',
        },
        {
            Header:  t("total"),
            accessor: 'total',
        },
    ], []);

    return (
        <div className="p-4 sm:w-1/2 w-full">
            {loading && <LoadingState/>}
            {data && data.length >= 0 ? <>
                <div className="flex flex-row items-center">
                    <p className="font-bold px-2 text-gray-600">{t("download_as_excel")}</p>
                    <DownloadAsExcelSheet data={data} cols={excel_col_def} name={t("qc_status")} classes="w-96 mb-4"/>
                </div>
                <Table data={data} columns={columns} onRowClick={() => {
                }} pagesize={30} hideFooter={true}/>
            </> : <p>{t("no_data_found")}..</p>}
        </div>
    )
})