import React, {Fragment} from "react";
import {observer} from "mobx-react-lite";
import {Popover, Transition} from "@headlessui/react";
import {classNames} from "../../helpers/utils";
import {ChevronDownIcon} from "@heroicons/react/24/outline";
import {useStore} from "../../helpers/helpers";
import {useTranslation} from "react-i18next";

const datasetTypes = [
    {name: 'Survey', description: 'Bulk create for all Booths', key: 'bulk'},
    {name: 'Survey', description: 'Create for specific Booth', key: 'single'}
];

export const TaskCreateMenu = observer(props => {

    const rootStore = useStore();
    const [t]=useTranslation("task_manager")
    const {taskManagerStore} = rootStore;

    return(
        <div className='ml-auto'>
            <Popover className="relative">
                {({open}) => (
                    <>
                        <Popover.Button
                            className={classNames(
                                open ? 'text-gray-900' : 'text-gray-500',
                                'group rounded-md border-2 bg-white inline-flex items-center text-base font-medium hover:text-gray-900 p-2'
                            )}
                        >
                            <span>{t("create_task")}</span>
                            <ChevronDownIcon
                                className={classNames(open ? 'text-gray-600' : 'text-gray-400', 'ml-2 h-5 w-5 group-hover:text-gray-500')}
                                aria-hidden="true"
                            />
                        </Popover.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                        >
                            <Popover.Panel
                                static
                                className="absolute z-10 transform -translate-x-[57%] mt-3 px-2 w-screen max-w-xs sm:px-0"
                            >
                                <div
                                    className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                    <div className="relative grid gap-6 bg-white px-3 py-2 sm:gap-8 sm:p-3">
                                        {datasetTypes.map((item) => (
                                            <div
                                                key={item.key}
                                                onClick={(evt) => {
                                                    taskManagerStore.set_task_add_modal(true);
                                                    taskManagerStore.set_task_type(item.key)
                                                    // setSelectedTaskType(item.key)
                                                }}
                                                className="-m-3 p-3 block rounded-sm cursor-pointer hover:bg-blue-50 transition ease-in-out duration-150"
                                            >
                                                <p className="text-base font-bold text-gray-900">{item.name}</p>
                                                <p className="text-sm text-gray-500">{item.description}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Popover.Panel>
                        </Transition>
                    </>
                )}
            </Popover>
        </div>
    )
})