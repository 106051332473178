import {observer} from "mobx-react-lite";
import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useWindowSize} from '@withvoid/melting-pot'
import {useStore} from "../../helpers/helpers";
import {safe_get} from "../../report-visuals/report-utils";
import {ElectionDayCard} from "./election-day-card";
import {ElectionDayCardSmall} from "./election-day-card-small";
import { useTranslation } from "react-i18next";
import RNAccordion from "../../components/Accordion";
import {BreadCrumbs, LoadingState, ProjectHeader, TailwindModal} from "../../components";

type AccordionTitleProps = {
    title: string,
    isOpen: boolean,
    onClickHandler: Function
}

export const AccordionTitle = (props: AccordionTitleProps) => {

    const {title, onClickHandler, isOpen} = props;

    return (
        <div onClick={() => onClickHandler(!isOpen)}
             className='flex flex-row bg-white rounded-lg shadow-lg items-center mt-2'>
            <div className='font-bold items-center m-4 text-purple-600'>{title}</div>
            <div className='ml-auto p-4'>
                {isOpen ?
                    <i className="fa-sharp fa-solid fa-chevron-up"/>
                    : <i className="fa-solid fa-chevron-down"/>}
            </div>
        </div>
    )
}

export const ElectionDayDashboard = observer((props: any) => {

    const {projectid} = useParams()
    const {electionDayDashboardStore, projectStore} = useStore()
    const {width} = useWindowSize()
    const {t} = useTranslation("election_dashboard")
    const isMobile = width <= 766;

    const crumbs = [
        { name: "DashBoard", href: `/project/${projectid}`, current: true},
        {name: 'Election Day Dashboard', href: '/project/' + projectid + '/election_day_dashboard', current: true},
    ];
    const [loading, setLoading] = useState(false)
    const [ourParty, setOurParty] = useState('')
    const [isDataLoaded, setIsDataLoaded] = useState(false)
    const [isBoothWiseExpanded, setIsBoothWiseExpanded] = useState(false)
    const [isPivLevelWiseExpanded, setIsPivLevelWiseExpanded] = useState(false)
    const [selectedBooth, setSelectedBooth] = useState<string | null>(null);

    const openModal = (booth: string) => {
        setSelectedBooth(booth);
        electionDayDashboardStore.setShowDataInModal(true);
    };

    const closeModal = () => {
        setSelectedBooth(null);
        electionDayDashboardStore.setShowDataInModal(false);
    };


    useEffect(() => {
        fetchElectionDayDashboardData();
    }, []);

    const fetchElectionDayDashboardData = async () => {
        setLoading(true)
        await electionDayDashboardStore.getElectionDayDashboardData(projectid)
        const gotvconfig = projectStore.getProjectProperty('gotv_config')
        const ourparty = safe_get(gotvconfig, 'our_party', '')
        setOurParty(ourparty)
        setIsDataLoaded(true)
        setLoading(false)
    }

    const expandCard = () => {
        return (
            <div
                className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-1/2 w-full">
                <ElectionDayCard
                    ourParty={ourParty}
                    data={electionDayDashboardStore.dataInModal}/>
                <div className="bg-gray-50 px-4 py-3 flex flex-row-reverse justify-start">
                    <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
                        onClick={closeModal}
                    >
                        {t("ok")}
                    </button>
                </div>
            </div>
        )
    }

    return (
        <>
            {electionDayDashboardStore.showDataInModal && <TailwindModal
                isOpen={electionDayDashboardStore.showDataInModal}
                children={expandCard()}
            />}
            {loading && <LoadingState/>}
            {!isMobile ?
                <>
                    <BreadCrumbs crumbs={crumbs}/>
                    <ProjectHeader/>
                </> : null}

            <div>
                <div className='font-bold mt-4 text-lg pl-8'>{t("election_day_dashboard")}</div>
                {isDataLoaded ?
                    <div className='p-2 lg:p-4'>
                        <ElectionDayCard
                            styles={"w-11/12 lg:w-2/6 shadow-lg"}
                            ourParty={ourParty}
                            data={safe_get(electionDayDashboardStore.electionDayDashboardData, 'ac_wise', {})}
                        />
                        <div className='flex flex-col'>
                            <RNAccordion  title={t("booth_wise_data")}>
                                <div className='bg-gray-100 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-2 mt-2'>
                                    {Object.keys(safe_get(electionDayDashboardStore.electionDayDashboardData, 'booth_wise', {})).map((booth) => (
                                        <div key={booth} onClick={() => openModal(booth)}>
                                            <ElectionDayCardSmall
                                                ourParty={ourParty}
                                                name={`Booth# ${booth}`}
                                                data={electionDayDashboardStore.electionDayDashboardData['booth_wise'][booth]}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </RNAccordion>
                            {/*<AccordionTitle*/}
                            {/*    title={t("booth_wise_data")}*/}
                            {/*    isOpen={isBoothWiseExpanded}*/}
                            {/*    onClickHandler={() => setIsBoothWiseExpanded(!isBoothWiseExpanded)}*/}
                            {/*/>*/}
                            {/*<div>*/}
                            {/*    {isBoothWiseExpanded &&*/}
                            {/*        <div*/}
                            {/*            className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-2 mt-2'>*/}
                            {/*            {Object.keys(safe_get(electionDayDashboardStore.electionDayDashboardData, 'booth_wise', {})).map((booth) => (*/}
                            {/*                <ElectionDayCardSmall*/}
                            {/*                    ourParty={ourParty}*/}
                            {/*                    name={`Booth# ${booth}`}*/}
                            {/*                    data={electionDayDashboardStore.electionDayDashboardData['booth_wise'][booth]}*/}
                            {/*                />*/}
                            {/*            ))}*/}
                            {/*        </div>*/}
                            {/*    }*/}
                            {/*</div>*/}
                        </div>
                        <div className='flex flex-col mt-4'>
                            <RNAccordion title={t("piv_level_wise_data")}>
                                <div
                                    className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-1 mt-2 bg-gray-100 '>
                                    {Object.keys(safe_get(electionDayDashboardStore.electionDayDashboardData, 'piv_level1_wise', {})).map((pivlevel) => (
                                        <div key={pivlevel} onClick={() => openModal(pivlevel)}>
                                        <ElectionDayCardSmall
                                            ourParty={ourParty}
                                            name={pivlevel}
                                            data={electionDayDashboardStore.electionDayDashboardData['piv_level1_wise'][pivlevel]}
                                        />
                                        </div>
                                    ))}
                                </div>
                            </RNAccordion>
                            {/*<AccordionTitle*/}
                            {/*    title={t("piv_level_wise_data")}*/}
                            {/*    isOpen={isPivLevelWiseExpanded}*/}
                            {/*    onClickHandler={() => setIsPivLevelWiseExpanded(!isPivLevelWiseExpanded)}*/}
                            {/*/>*/}
                            {/*<div>*/}
                            {/*    {isPivLevelWiseExpanded &&*/}
                            {/*        <div*/}
                            {/*            className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-1 mt-2'>*/}
                            {/*            {Object.keys(safe_get(electionDayDashboardStore.electionDayDashboardData, 'piv_level1_wise', {})).map((pivlevel) => (*/}
                            {/*                <ElectionDayCardSmall*/}
                            {/*                    ourParty={ourParty}*/}
                            {/*                    name={pivlevel}*/}
                            {/*                    data={electionDayDashboardStore.electionDayDashboardData['piv_level1_wise'][pivlevel]}*/}
                            {/*                />*/}
                            {/*            ))}*/}
                            {/*        </div>*/}
                            {/*    }*/}
                            {/*</div>*/}
                        </div>
                    </div> : ''}
            </div>
        </>
    )
})