import { observer } from "mobx-react-lite";
import React from "react";
import { useStore } from "../../../helpers/helpers";
import { htmlValidation } from "../../../helpers/utils";

const VoterReachOutListCampaign = observer((props: any) => {
  const { voterReachOutStore } = useStore();
  const { reachOutCampaignList } = voterReachOutStore;
  const { deleteMessage, setPopOver } = props;

  const detailsReachOutCampaign = async (id, mode) => {
    const detailsCampaignPayload = {
      id: id,
    };
    const response = await voterReachOutStore.details_reachout_Campaign(
      detailsCampaignPayload
    );
    if (response.status === 200) {
      mode == "edit" && voterReachOutStore.set_isEditOverlay(true);
    }
  };

  return (
    <div>
      <div className="-mx-4 mt-8 overflow-x-auto shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="py-3.5 pl-4 pr-3 text-left sm:pl-6">
                Name
              </th>
              <th scope="col" className="px-3 py-3.5 text-left sm:table-cell">
                Status
              </th>
              <th scope="col" className="px-3 py-3.5 text-left">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {reachOutCampaignList.length > 0 &&
              reachOutCampaignList.map((item) => {
                return (
                  <tr
                    className="cursor-pointer"
                    key={item.id}
                    onClick={async (e) => {
                      htmlValidation(e);
                      setPopOver(false);
                      detailsReachOutCampaign(item.id, "view");
                      voterReachOutStore.set_IsMessageOverviewOpen(true);
                      voterReachOutStore.set_selected_overview_item(item);
                    }}
                  >
                    <td className="whitespace-nowrap px-3 py-4 sm:table-cell">
                      {item.name}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 sm:table-cell">
                      {item.status}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 sm:table-cell">
                      <span
                        className="text-red-600 pl-2"
                        onClick={(e) => {
                          htmlValidation(e);
                          deleteMessage(item.id);
                        }}
                      >
                        <i className="fa-solid fa-trash" />
                      </span>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
});
export default VoterReachOutListCampaign;
