import {action, makeAutoObservable, toJS} from "mobx";
import _ from "lodash";

export class ReportStore {
    user: any = null;

    rootStore: any;

    currentReport: any;
    currentReportData: any;
    currentReportFilters: any;

    constructor(rootStore: any) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
        this.currentReport = {};
        this.currentReportData = {};
        this.currentReportFilters = {};
    }

    @action
    getReportsForUser = async (query: string, tags: Array<string>, limit: number, offset: number) => {
        const payload = {query, tags, limit, offset};
        this.rootStore.userStore.checkLogin();
        return await this.rootStore.api.post('reports/getreports', payload);
    };

    @action
    createNewReport = async () => {
        return await this.rootStore.api.post('reports/createnewreport', null);
    };

    @action
    fetchAllTags = async () => {
        return await this.rootStore.api.post('reports/alltags', null);
    };

    @action
    cloneReport = async (reportid) => {
        return await this.rootStore.api.post('reports/clonereport', {reportid});
    };

    @action
    updateReport = async (payload) => {
        return await this.rootStore.api.post('reports/updatereport', payload);
    };

    @action
    deleteReport = async (reportid) => {
        return await this.rootStore.api.post('reports/deletereport', {reportid});
    };

    @action
    viewReport = async (reportid) => {
        return await this.rootStore.api.post('reports/viewreport', {reportid});
    };

    @action
    viewReportWithData = async (reportid) => {
        return await this.rootStore.api.post('reports/viewreportwithdata', {reportid});
    };

    @action
    viewReportTokenWithData = async (token) => {
        return await this.rootStore.api.post('reports/viewreporttokenwithdata', {token});
    };

    @action
    setCurrentReport = (payload) => {
        this.currentReport = payload;
    };

    @action
    setCurrentReportData = (payload) => {
        this.currentReportData = payload;
    }

    @action
    getCurrentReport = () => {
        return this.currentReport;
    };

    @action
    updateFilter = (filterKey, filterValue) => {
        const newFilters = _.clone(this.currentReportFilters);
        newFilters[filterKey] = filterValue;
        this.currentReportFilters = newFilters;
        console.log('Filters:', toJS(this.currentReportFilters));
        return this.currentReportFilters;
    }

    @action
    getSubscribedFilters = (keysToOmit) => {
        const newFilters = _.clone(this.currentReportFilters);
        for (const omitKey of keysToOmit) {
            delete newFilters[omitKey];
        }
        return newFilters;
    }

    @action
    clearAllActiveFilters = () => {
        this.currentReportFilters = {};
        return this.currentReportFilters;
    }
}

