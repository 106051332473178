import React from "react";
import {DashBoard} from "./dashboard-template";

export const HombaleDashBoard = () => {

    const frame = `<iframe title="Hombale_KGF2" width="100%" height="100%" src="https://app.powerbi.com/view?r=eyJrIjoiYmEwOTk0NDItNGRlYy00OTRiLThhZTctZWQ1MjBhMTAyNmExIiwidCI6IjAzZjhjZWI2LTdhMGUtNDcwMy05ZGE0LWUyYTBkYmE1NmM4YyJ9" frameborder="0" allowFullScreen="true"></iframe>`

    return (
        <>
            <DashBoard passCode={"Hombale"} iframe={frame} />
        </>
    )
}