import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {
    BoothTree,
    FormButtons,
    LoadingState,
    SideOverlay,
    UploadButton,
} from "../../components";
import {useForm, Controller} from "react-hook-form";
import {
    TextField,
    FormControlLabel,
    Radio,
    RadioGroup,
    FormControl,
    FormLabel,
    Stack,
    Typography,
    Divider,
    FormHelperText,
} from "@mui/material";
import {safe_get} from "../../report-visuals/report-utils";
import {MSelect} from "./form-components";
import {label_style} from "../beneficiary-management";
import {toast} from "react-toastify";
import {useParams} from "react-router";
import {defaultILValues} from "./il-types";
import {Field, Input, VortexSpinner} from "../digital-repository";
import {useFileUpload, useStore, htmlValidation} from "../../helpers";
import {isEmpty} from "lodash";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {ILRoles} from "./il-roles";
import {toJS} from "mobx";
import {C3StarRating} from "../constituency-command-center";

const transformYesOrNo = (val) => {
    if (typeof val === "string") {
        return val.toLowerCase() === "yes";
    } else {
        return val;
    }
};

export const phoneNumberValidation = {
    maxLength: {
        value: 10,
        message: "Phone Number must be at most 10 digits long",
    },
    minLength: {value: 10, message: "Phone Number must be 10 digits long"},
    pattern: {
        value: /^[6-9]\d{9}$/,
        message: "Phone Number must starts with 9,8,7 or 6",
    },
    required: "Enter phone number",
};

export const AddInfluentialLeader = observer((props) => {
    const rootStore = useStore();
    const {projectid} = useParams();
    const {influentialLeadersStore} = rootStore;
    const {activeInfluentialLeader, addNewILModal} = influentialLeadersStore;
    const il = toJS(activeInfluentialLeader);
    const {uploadFileToS3, isUploading} = useFileUpload();
    const [file, setFile] = useState([]);
    const [buffer, setBuffer] = useState([]);
    const path = `project-${projectid}/influential-leaders`;

    const [village, setVillage] = useState({});
    const [rating, setRating] = useState(null);

    const profileOptions = rootStore.projectStore.getProjectProperty("profile_questions", {});
    const casteOptions = safe_get(profileOptions, "caste", []);
    const occupationOptions = safe_get(profileOptions, "occupation", []);
    const il_property = rootStore.projectStore.getProjectProperty("influential_leaders", []);

    const {
        handleSubmit,
        control,
        watch,
        formState: {errors, isSubmitted},
        reset,
        setValue,
    } = useForm({defaultValues: defaultILValues});

    const isVolunteer = watch("is_volunteer");

    const queryClient = useQueryClient();

    useEffect(() => {
        //TODO: set activeInfluentialLeader values in edit mode
        reset(activeInfluentialLeader);
        setValue("is_volunteer", safe_get(activeInfluentialLeader, "is_volunteer", false) ? "yes" : "no");
        setValue("voter_card_linked", safe_get(activeInfluentialLeader, "voter_card_linked", false) ? "yes": "no");
        setVillage(safe_get(il, "node", {}));
        setBuffer([safe_get(il, "profile.photo_path", {})]);
        setRating(safe_get(il, "profile.rating", 0));
    }, [activeInfluentialLeader]);

    const resetForm = () => {
        influentialLeadersStore.updateIlStoreProperty(
            "activeInfluentialLeader",
            defaultILValues
        );
    };

    const closeModal = () => {
        influentialLeadersStore.updateIlStoreProperty("addNewILModal", false);
        resetForm();
    };

    const onAddInfluentialLeader = async (payload) => {
        const res = await influentialLeadersStore.createInfluentialLeader(payload);
        if (!res.ok) {
            throw new Error("Something went wrong...");
        } else {
            resetForm();
            return res;
        }
    };

    const onEditInfluentialLeader = async (payload) => {
        const res = await influentialLeadersStore.updateInfluentialLeader(payload);
        if (!res.ok) {
            throw new Error("Something went wrong...");
        } else {
            return res;
        }
    };

    const {mutate, isLoading: isAdding} = useMutation({
        mutationFn: onAddInfluentialLeader,
        onSuccess: () => onMutationSuccess("Influential Leader Added Successfully"),
        onError: ({message}) => {
            toast.error(message);
        },
    });

    const {mutate: editInfluentialLeader, isLoading: isEditing} = useMutation({
        mutationFn: onEditInfluentialLeader,
        onSuccess: async () =>
            onMutationSuccess("Influential Leader updated Successfully"),
        onError: ({message}) => {
            toast.error(message);
        },
    });

    const onMutationSuccess = (msg) => {
        setTimeout(async () => {
            await queryClient.invalidateQueries([
                "influential-leaders",
                projectid,
                influentialLeadersStore.ilFilters,
            ]);
        }, 1000);
        toast.success(msg);
    };

    const onSubmit = async (data) => {
        const path = safe_get(buffer[0], "path", "");

        //TODO: Custom validation for profile photo and village selection.
        if (isEmpty(village)) {
            toast.error("Please select village/booth");
            return;
        } else if (!path) {
            toast.error("Please select and upload profile picture");
            return;
        }
        const payload = {
            ...data,
            projectid: parseInt(projectid),
            profile: {
                ...data.profile,
                rating,
                photo_path: {
                    ...data.profile.photo_path,
                    path: buffer[0]["path"],
                    type: buffer[0]["type"],
                },
            },
            voter_card_linked: transformYesOrNo(data.voter_card_linked),
            is_volunteer: transformYesOrNo(data.is_volunteer),
            node: village,
        };

        if (safe_get(activeInfluentialLeader, "id", null)) {
            editInfluentialLeader(payload);
        } else {
            mutate(payload);
        }
        closeModal();
    };

    const profilePhotoUploadHandler = async (e) => {
        htmlValidation(e);
        if (isEmpty(file)) {
            toast.error("Please select the file...");
            return;
        }
        Array.from(file).forEach((fileToUpload, index) => {
            //Todo: timeout for better user experience while uploading multiple files.
            setTimeout(async () => {
                const uploadedData = await uploadFileToS3(
                    path,
                    fileToUpload,
                    "urn-assets"
                );

                if (uploadedData?.status === 204) {
                    setBuffer([
                        {
                            path: uploadedData.key,
                            type: fileToUpload.type,
                        },
                    ]);
                }
            }, 1000 * index);
        });
    };

    if (isAdding || isEditing) return <LoadingState/>;

    return (
        <>
            <SideOverlay
                onClose={closeModal}
                show={addNewILModal}
                title={"Add New Influential Leader"}
            >
                <form
                    className={"bg-white px-4 py-2 rounded-md"}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Stack gap={2} direction={"column"}>
                        <Stack>
                            <Typography color={"primary"} variant={"h5"}>
                                Profile
                            </Typography>
                            <Controller
                                name={"profile.name"}
                                control={control}
                                rules={{
                                    required: "Name is Required",
                                }}
                                render={({field, fieldState: {error}}) => (
                                    <>
                                        <TextField
                                            {...field}
                                            label="Name"
                                            variant="filled"
                                            fullWidth
                                            margin="normal"
                                            size={"small"}
                                        />
                                        {error && (
                                            <FormHelperText error>{error?.message}</FormHelperText>
                                        )}
                                    </>
                                )}
                            />
                            <Controller
                                name={"profile.phonenumber"}
                                control={control}
                                rules={phoneNumberValidation}
                                render={({field, fieldState: {error}}) => (
                                    <>
                                        <TextField
                                            {...field}
                                            label="Phone Number"
                                            variant="filled"
                                            fullWidth
                                            margin="normal"
                                            size={"small"}
                                        />
                                        {error && (
                                            <>
                                                {error.type === "pattern" ? (
                                                    <FormHelperText error>
                                                        {error?.message}
                                                    </FormHelperText>
                                                ) : (
                                                    <FormHelperText error>
                                                        {error?.message}
                                                    </FormHelperText>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            />
                            <Controller
                                name={"profile.whatsapp_number"}
                                control={control}
                                rules={phoneNumberValidation}
                                render={({field, fieldState: {error}}) => (
                                    <>
                                        <TextField
                                            {...field}
                                            label="Whatsapp Number"
                                            variant="filled"
                                            fullWidth
                                            margin="normal"
                                            size={"small"}
                                        />
                                        {error && (
                                            <>
                                                {error.type === "pattern" ? (
                                                    <FormHelperText error>
                                                        {error?.message}
                                                    </FormHelperText>
                                                ) : (
                                                    <FormHelperText error>
                                                        {error?.message}
                                                    </FormHelperText>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            />
                            <Controller
                                name={"profile.age"}
                                control={control}
                                rules={{
                                    maxLength: {value: 2, message: "Age must be 2 digits long"},
                                    minLength: {value: 2, message: "Age must be 2 digits long"},
                                    required: "Age is required",
                                }}
                                render={({field, fieldState: {error}}) => (
                                    <>
                                        <TextField
                                            type={"number"}
                                            {...field}
                                            label="Age"
                                            variant="filled"
                                            fullWidth
                                            margin="normal"
                                            size={"small"}
                                        />
                                        {error && (
                                            <FormHelperText error>{error?.message}</FormHelperText>
                                        )}
                                    </>
                                )}
                            />
                            <Stack direction={"row"} alignItems={"center"}>
                                <Typography color={"primary"} className={"py-2"}>
                                    Rating:
                                </Typography>
                                <div className={"px-2"}>
                                    <C3StarRating
                                        value={parseFloat(rating)}
                                        isEditable={true}
                                        onStarClickHandler={(nextValue) => {
                                            setRating(nextValue);
                                        }}
                                    />
                                </div>
                            </Stack>

                            <div className="flex flex-col justify-center mb-2">
                                <label className={label_style}>Village/Booth</label>
                                <BoothTree
                                    onBoothSelection={(data) => setVillage(data)}
                                    selected_booth={safe_get(activeInfluentialLeader, "node", {})}
                                    isMulti={false}
                                    selector={"piv_level2"}
                                />
                            </div>
                            <Field htmlFor={"file"} label="Picture" error={""}>
                                <div className={"flex flex-row justify-between items-center"}>
                                    <Controller
                                        control={control}
                                        name={"profile.photo_path.actual"}
                                        rules={{
                                            required: "Please select the file",
                                        }}
                                        render={({
                                                     field: {value, onChange, onBlur, ...field},
                                                     fieldState: {error},
                                                 }) => {
                                            return (
                                                <>
                                                    <Input
                                                        type="file"
                                                        id="file"
                                                        accept={"image/*"}
                                                        onBlur={onBlur}
                                                        {...field}
                                                        onChange={(event) => {
                                                            const files = event.target.files;
                                                            setFile(Array.from(files));
                                                            onChange(files);
                                                        }}
                                                    />
                                                    {error && (
                                                        <FormHelperText error>
                                                            {error?.message}
                                                        </FormHelperText>
                                                    )}
                                                </>
                                            );
                                        }}
                                    />
                                    <UploadButton
                                        uploadHandler={(e) => profilePhotoUploadHandler(e)}
                                    />
                                    <VortexSpinner visible={isUploading}/>
                                </div>
                                <p>
                                    {
                                        activeInfluentialLeader.profile?.photo_path["path"]?.split(
                                            "/"
                                        )[2]
                                    }
                                </p>
                            </Field>
                        </Stack>
                        <Divider/>
                        <Stack>
                            <Typography color={"primary"} variant={"h5"}>
                                Influence
                            </Typography>
                            <MSelect
                                name="influence.caste"
                                control={control}
                                options={casteOptions}
                                label={"Caste"}
                                rules={{
                                    required: "Please select Caste",
                                }}
                                defaultValue={undefined}
                            />
                            <Controller
                                name={"influence.voters"}
                                control={control}
                                rules={{
                                    maxLength: {
                                        value: 10,
                                        message: "Whatsapp Number must be at most 10 digits long",
                                    },
                                }}
                                render={({field, fieldState: {error}}) => (
                                    <>
                                        <TextField
                                            {...field}
                                            label="No.of voters he can influence "
                                            variant="filled"
                                            fullWidth
                                            margin="normal"
                                            size={"small"}
                                        />
                                        {error && (
                                            <FormHelperText error>{error?.message}</FormHelperText>
                                        )}
                                    </>
                                )}
                            />
                        </Stack>
                        <Divider/>
                        <Stack>
                            <Typography color={"primary"} variant={"h5"}>
                                Affiliation
                            </Typography>
                            <MSelect
                                name="affiliation.party"
                                control={control}
                                options={safe_get(il_property, "party_support", []).map(
                                    (option) => {
                                        return {label: option, value: option};
                                    }
                                )}
                                label={"Party Affiliation"}
                                rules={{
                                    required: "Please select Party Affiliation",
                                }}
                                defaultValue={undefined}
                            />
                            <Controller
                                name={"affiliation.candidate"}
                                control={control}
                                rules={{}}
                                render={({field, fieldState: {error}}) => (
                                    <>
                                        <TextField
                                            {...field}
                                            label="Candidate Name"
                                            variant="filled"
                                            fullWidth
                                            margin="normal"
                                            size={"small"}
                                        />
                                        {error && (
                                            <FormHelperText error>{error?.message}</FormHelperText>
                                        )}
                                    </>
                                )}
                            />
                            <MSelect
                                name="affiliation.strength_of_support"
                                control={control}
                                options={safe_get(il_property, "support_strength", []).map(
                                    (option) => {
                                        return {label: option, value: option};
                                    }
                                )}
                                label={"Strength Of Support"}
                                rules={{
                                    required: "Please select Strength of Support",
                                }}
                                defaultValue={undefined}
                            />
                        </Stack>
                        <Divider/>
                        <Stack>
                            <Typography color={"primary"} variant={"h5"}>
                                Additional Information
                            </Typography>
                            <MSelect
                                name="more.primary_occupation"
                                control={control}
                                options={occupationOptions}
                                label={"Occupation"}
                                rules={{
                                    required: "Please select Occupation",
                                }}
                                defaultValue={undefined}
                            />

                            <Controller
                                name={"more.local_elections_contested"}
                                control={control}
                                rules={{}}
                                render={({field, fieldState: {error}}) => (
                                    <>
                                        <TextField
                                            {...field}
                                            label="Does he contested in Local Elections ?"
                                            variant="filled"
                                            fullWidth
                                            margin="normal"
                                            size={"small"}
                                        />
                                        {error && (
                                            <FormHelperText error>{error?.message}</FormHelperText>
                                        )}
                                    </>
                                )}
                            />
                            <Controller
                                name={"more.additional_information"}
                                control={control}
                                rules={{}}
                                render={({field, fieldState: {error}}) => (
                                    <>
                                        <TextField
                                            {...field}
                                            multiline
                                            minRows={4}
                                            maxRows={8}
                                            label="Additional Information"
                                            variant="filled"
                                            fullWidth
                                            margin="normal"
                                            size={"small"}
                                        />
                                        {error && (
                                            <FormHelperText error>{error?.message}</FormHelperText>
                                        )}
                                    </>
                                )}
                            />
                            <FormControl component="fieldset" margin="normal">
                                <FormLabel component="legend">Voter Card Linked ?</FormLabel>
                                <Controller
                                    name="voter_card_linked"
                                    control={control}
                                    render={({field}) => (
                                        <RadioGroup row {...field}>
                                            <FormControlLabel
                                                value={"yes"}
                                                control={<Radio/>}
                                                label="Yes"
                                            />
                                            <FormControlLabel
                                                value={"no"}
                                                control={<Radio/>}
                                                label="No"
                                            />
                                        </RadioGroup>
                                    )}
                                />
                            </FormControl>
                            <FormControl component="fieldset" margin="normal">
                                <FormLabel component="legend">Is he party worker ?</FormLabel>
                                <Controller
                                    name="is_volunteer"
                                    control={control}
                                    render={({field}) => (
                                        <RadioGroup row {...field}>
                                            <FormControlLabel
                                                value="yes"
                                                control={<Radio/>}
                                                label="Yes"
                                            />
                                            <FormControlLabel
                                                value="no"
                                                control={<Radio/>}
                                                label="No"
                                            />
                                        </RadioGroup>
                                    )}
                                />
                            </FormControl>
                            {transformYesOrNo(isVolunteer) ? (
                                <>
                                    <Stack gap={2}>
                                        <Typography color={"primary"} variant={"h5"}>
                                            Party Worker
                                        </Typography>
                                        <Controller
                                            name={"volunteer.position"}
                                            control={control}
                                            render={({field}) => (
                                                <TextField
                                                    {...field}
                                                    label="Position"
                                                    variant="filled"
                                                    fullWidth
                                                    margin="normal"
                                                    size={"small"}
                                                />
                                            )}
                                        />
                                        <ILRoles
                                            control={control}
                                            defaultValue={safe_get(
                                                activeInfluentialLeader,
                                                "volunteer.roles",
                                                []
                                            )}
                                            options={safe_get(il_property, "party_roles", [])}
                                            onRoleUpdate={(val) => {
                                                console.log(val);
                                            }}
                                        />
                                    </Stack>
                                </>
                            ) : null}
                        </Stack>
                        <FormButtons onSave={() => {
                        }} onCancel={closeModal}/>
                    </Stack>
                </form>
            </SideOverlay>
        </>
    );
});
