import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { BreadCrumbs, LoadingState, ProjectHeader, TabView } from "../../components";
import { ChatBubbleBottomCenterIcon, ClipboardDocumentCheckIcon } from "@heroicons/react/24/solid";
import { useStore } from "../../helpers/helpers";
import BeneficiaryScheme from "../beneficiary-management-v2/beneficiary-schemes/beneficiary-scheme";
import { useQuery } from "@tanstack/react-query";
import BeneficiaryStatus from "../beneficiary-management-v2/beneficiary-status/beneficiary-status";
import BeneficiaryPriority from "../beneficiary-management-v2/beneficiary-priorities/beneficiary-priority";

const Crumbs = () => {
    const { projectid } = useParams();

    const crumbs = [
        { name: "Dashboard", href: `/project/${projectid}`, current: false },
        { name: 'Beneficiary Management', href: `/project/${projectid}/beneficiary_v2`, current: false },
        { name: 'Settings', href: '#', current: true },
    ];
    return (
        <BreadCrumbs crumbs={crumbs} />
    );
}

const tabs = [
    { id: 1, name: "Schemes", icon: ClipboardDocumentCheckIcon },
    { id: 2, name: "Status", icon: ChatBubbleBottomCenterIcon },
    { id: 3, name: "Priority", icon: ChatBubbleBottomCenterIcon },
];

const BeneficiarySettings = () => {
    const rootStore = useStore();
    const { projectid } = useParams();
    const { beneficiaryStore } = rootStore;
    const [activeTab, setActiveTab] = useState("Schemes");

    const onTabChange = (tab) => {
        setActiveTab(tab);
    };

    const fetchBeneficiarySchemes = async () => {
        return await beneficiaryStore.fetch_beneficiarytypes(projectid);
    };

    const { isLoading, error, isSuccess } = useQuery({
        queryKey: ["beneficiary-schemes", projectid],
        queryFn: fetchBeneficiarySchemes,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            if (data?.data?.length > 0) {
                beneficiaryStore.setSchemesList(data.data);
            }
        },
    });

    if (isLoading) return <LoadingState />;

    return (
        <>
            <Crumbs />
            <ProjectHeader />

            <h3 className="font-bold px-4 text-2xl text-indigo-500 w-full">Beneficiary Settings</h3>

            <div className="p-4">
                <TabView tabs={tabs} onChange={onTabChange} />
                {activeTab === "Schemes" && <BeneficiaryScheme />}
                {activeTab === "Status" && <BeneficiaryStatus />}
                {activeTab === "Priority" && <BeneficiaryPriority/>}
            </div>
        </>
    );
}

export default BeneficiarySettings;
