import { observer } from "mobx-react-lite";
import React, { useState, useEffect, useMemo } from "react";
import { useStore } from "../helpers/helpers";
import { CMTable } from "./CMTable";

export const Enquiries = observer(props => {

    const [data, setData] = useState([]);
    const rootStore = useStore();

    useEffect(() => {
        const get_enq = async () => {
            const res = await rootStore.get_enquiries();
            if (res.status === 200) {
                // @ts-ignore
                setData(res.data);
                console.log(res.data)
            }
        }
        get_enq();
    }, [])

    const columns = useMemo(() => [
        {
            Header: "Date",
            accessor: 'timestamp',
        },
        {
            Header: "Name",
            accessor: 'name',
        },
        {
            Header: "Phone Number",
            accessor: 'phoneno',
        },
        {
            Header: "e-Mail",
            accessor: 'email',
        },
        {
            Header: "Address",
            accessor: 'location',
        },
        {
            Header: "Message",
            accessor: 'message',
        },
        {
            Header: "IP Address",
            accessor: 'ip',
        },
        {
            Header: "IP Location",
            accessor: 'reversedip',
            Cell: props => {
                const value = props.cell.value
                return (
                    <>
                        {value.city}, {value.region}
                    </>
                )
            }

        },
        {
            Header: "Browser",
            accessor: 'browser',
        }
    ], [data])

    return (
        <div className="mx-4">
            <div className="flex flex-col items-center" >
                <img style={{ width: "250px", height: "200px", margin: "0 auto" }} src="https://www.rajneethi.com/img/Rajneethi Full Logo PNG.png" />
                <div className="text-2xl font-bold text-purple-600 uppercase py-4">Rajneethi Enquiry List</div>
            </div>
            <CMTable columns={columns} data={data} onRowClick={() => { }} pagesize={30} />
        </div>
    );
});
