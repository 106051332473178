import _ from "lodash";
import {safe_get} from "./report-utils";

export class ReportTransformers {

    filter(data, args) {
        if (Object.keys(args).length === 0) return data;

        const customFilter = (o) => {
            let criteriaMet = true;
            for (const filterKey of Object.keys(args)) {
                const filterValue = args[filterKey];
                const targetValue = o[filterKey];
                if (filterValue.includes(targetValue)) {
                    criteriaMet = criteriaMet && true;
                } else {
                    return false
                }
            }
            return criteriaMet;
        };

        return _.filter(data, customFilter);

    }

    blacklist(data, args) {
        const {filter_key, filter_val} = args

        if (Object.keys(args).length === 0) return data;

        const filtered = []
        for(let tuple of data){
            try{
                if(tuple[filter_key] === filter_val){
                    //do nothing
                }else{
                    filtered.push(tuple)
                }
            }catch (e) {
                //Some exception happened. May be key is not present. Assume the row is still valid.
                filtered.push(tuple)
            }
        }
        return filtered
    }

    emit(data, args) {
        const {op, cols, out} = args;
        const has_to_unpack = safe_get(args, 'unpack', false);
        const unpack_col = safe_get(args, 'unpack_col', '');
        const transformed = [];
        if(!_.isEmpty(data)){
            for (const intuple of data) {
                const outtuple = {};
                for (let i = 0; i < cols.length; i++) {
                    const inkey = cols[i];
                    const outkey = out[i];
                    outtuple[outkey] = intuple[inkey];
                }

                if(has_to_unpack){
                    for(let x of outtuple[unpack_col]){
                        const partial_tuple = _.clone(outtuple);
                        delete partial_tuple[unpack_col];
                        partial_tuple[unpack_col] = x;
                        transformed.push(partial_tuple);
                    }
                }else{
                    transformed.push(outtuple);
                }
            }
            return transformed;
        }
        return [];
    }

    /*
    unpack(data, args) {
        const {key} = args;
        const transformed = [];
        for (const intuple of data) {
            const mapped_key = intuple[key]
            if (Array.isArray(intuple[key])) {
                const val = intuple[key]
                for(const arr_val in intuple[key]){

                }
            } else {
                transformed.push(intuple)
            }

            const outtuple = {};
            for (let i = 0; i < cols.length; i++) {
                const inkey = cols[i];
                const outkey = out[i];
                outtuple[outkey] = intuple[inkey];
            }
            transformed.push(outtuple);
        }
        return transformed;
    }
    */

    count(data, args) {
        const {op, cols, out} = args;
        const SEPARATOR = '_|_';

        const transformed = {};
        for (const intuple of data) {
            const key = cols.map(x => intuple[x]).join(SEPARATOR);
            if (!Object.keys(transformed).includes(key)) {
                transformed[key] = {tuple: intuple, count: 0};

            }
            transformed[key]['count']++;
        }

        const countColumnName = out[out.length - 1];

        const unpacked = []
        for (let v of Object.values(transformed)) {
            const outtuple = v['tuple'];
            outtuple[countColumnName] = v['count'];
            unpacked.push(outtuple);
        }
        return unpacked;
    }

    sum(data, args) {
        const {op, cols, out, sumcol, setcol} = args;
        const SEPARATOR = '_|_';

        const transformed = {};
        for (const intuple of data) {
            const key = cols.map(x => {
                return (x === sumcol) ? '' : intuple[x];
            }).join(SEPARATOR);
            if (!Object.keys(transformed).includes(key)) {
                transformed[key] = {tuple: intuple, total: 0};
            }
            transformed[key]['total'] += intuple[sumcol];
        }

        const countColumnName = out[out.length - 1];

        const unpacked = []
        for (let v of Object.values(transformed)) {
            const outtuple = v['tuple'];
            outtuple[setcol] = v['total'];
            unpacked.push(outtuple);
        }
        return unpacked;
    }



    percent_grand_total(data, args) {
        const {op, cols, out, sumcol, setcol} = args;
        const SEPARATOR = '_|_';

        let net_total = 0;

        for (const intuple of data) {
            net_total += intuple[sumcol];
        }

        const unpacked = []
        for (const intuple of data) {
            const outtuple = intuple;
            outtuple[setcol] = (outtuple[sumcol] / net_total)
            unpacked.push(outtuple)
        }
        return unpacked
    }

}