import React from "react"
import Input from "./Input"
import Select from "./select"
import TextArea from "./textarea"

export function TextError(props) {
    return (
        <div className="error text-sm text-red-600">
            <i className="text-sm fa-solid fa-triangle-exclamation text-red-600 text-sm pr-2" />
            {props.children}
        </div>
    )
}
function FormikController(props) {
    const { control, ...rest } = props
    switch (control) {
      case "input":
        return <Input {...rest} />
      case "textArea":
        return <TextArea {...rest} />
      case "select":
        return <Select {...rest} />
    //   case "radio":
    //     return <RadioButtons {...rest} />
    //   case "checkbox":
    //     return <CheckBoxes {...rest} />
        // case "date":
        //   return <DateControl />
      

      default:
        return null
    }
  }
  export default FormikController