import CheckboxTree from "react-checkbox-tree";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheckSquare, faSquare, faChevronRight, faChevronDown, faPlusSquare, faMinusSquare, faFolder, faFolderOpen, faFile } from "@fortawesome/free-solid-svg-icons";

library.add(faCheckSquare, faSquare, faChevronRight, faChevronDown, faPlusSquare, faMinusSquare, faFolder, faFolderOpen, faFile);

type RNCheckBoxTreeProps = {
    data: any[],
    checked_nodes: any[],
    on_node_selection: (nodes: any[]) => void
}

export const RNCheckBoxTree = (props: RNCheckBoxTreeProps) => {
    const [expanded, setExpanded] = useState<string[]>([]);
    const [filteredData, setFilteredData] = useState<any[]>(props.data);
    const [searchQuery, setSearchQuery] = useState<string>("");

    const { checked_nodes, data, on_node_selection } = props;

    useEffect(() => {
        setFilteredData(filterNodes(data, searchQuery));
    }, [data, searchQuery]);

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const query = event.target.value;
        setSearchQuery(query);
    };

    const filterNodes = (nodes: any[], query: string): any[] => {
        return nodes.filter(node => {
            if (node.label.toLowerCase().includes(query.toLowerCase())) {
                return true;
            }
            if (node.children) {
                const filteredChildren = filterNodes(node.children, query);
                if (filteredChildren.length > 0) {
                    node.children = filteredChildren;
                    return true;
                }
            }
            return false;
        });
    };

    return (
        <>
            <input
                type="text"
                placeholder="Search..."
                value={searchQuery}
                onChange={handleSearch}
                className="border p-2 mb-4 w-full"
            />
            <CheckboxTree
                nodes={filteredData}
                checked={checked_nodes}
                expanded={expanded}
                checkModel={"leaf"}
                onCheck={(check) => {
                    on_node_selection(check);
                }}
                onExpand={(expand) => {
                    setExpanded(expand);
                }}
                icons={{
                    check: <FontAwesomeIcon icon="check-square" />,
                    uncheck: <FontAwesomeIcon icon="square" />,
                    halfCheck: <FontAwesomeIcon icon="check-square" className="rct-icon rct-icon-half-check" />,
                    expandClose: <FontAwesomeIcon icon="chevron-right" className="rct-icon rct-icon-expand-close" />,
                    expandOpen: <FontAwesomeIcon icon="chevron-down" className="rct-icon rct-icon-expand-open" />,
                    expandAll: <FontAwesomeIcon icon="plus-square" className="rct-icon rct-icon-expand-all" />,
                    collapseAll: <FontAwesomeIcon icon="minus-square" className="rct-icon rct-icon-collapse-all" />,
                    parentClose: <FontAwesomeIcon icon="folder" className="rct-icon rct-icon-parent-close" />,
                    parentOpen: <FontAwesomeIcon icon="folder-open" className="rct-icon rct-icon-parent-open" />,
                    leaf: <FontAwesomeIcon icon="file" className="rct-icon rct-icon-leaf-close" />,
                }}
            />
        </>
    );
};
